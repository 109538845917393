/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "@mui/material/Menu";

/*COMPONENT*/
import Modal from "../common_modal/Modal";
import BI_REORDER_GROUP from "./BI_REORDER_GROUP";

/*REDUX*/
import {
  set_value_bi,
  delete_group_card,
  edit_group_config,
  edit_inside_object,
} from "../../App/actions/biActions";

/*PICTURE*/

/*FUNCTION*/

/*DATA*/
import dict from "../../Data/dict.json";
import setting_bi_constant from "../../Data/setting_bi_constant.json";

/*CONST*/

class BI_EDIT_GROUP extends Component {
  state = {
    anchor: null,
    modal_menu: false,
    modal_delete: false,
    modal_reorder: false,
  };

  on_change = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.props.edit_inside_object({
      key: "setting_add_object",
      id_key: name,
      value: value,
    });
  };

  toggle_delete = () => {
    this.setState({
      modal_delete: !this.state.modal_delete,
      modal_menu: false,
    });
  };

  toggle_reorder = () => {
    this.setState({
      modal_reorder: !this.state.modal_reorder,
      modal_menu: false,
    });
  };

  on_delete = () => {
    const { group_card_add_object, bi_object, project_object_selected } =
      this.props.bi;
    const group_card_id = group_card_add_object?._id;
    const _id = bi_object?._id;
    const body = {
      _id,
      project_id: project_object_selected._id,
      group_card_id,
    };
    this.props.delete_group_card(body);
    this.setState({ modal_delete: false });
    this.props.set_value_bi({
      key: "modal_edit_group_card",
      value: false,
    });
    this.props.set_value_bi({
      key: "setting_add_object",
      value: {
        ...setting_bi_constant,
      },
    });
  };

  on_edit = () => {
    this.props.set_value_bi({
      key: "modal_add_group_card",
      value: !this.props.bi.modal_add_group_card,
    });
    this.setState({
      modal_menu: false,
    });
  };

  toggle_menu_card = (e) => {
    this.setState(
      { modal_menu: !this.state.modal_menu, anchor: e.currentTarget },
      () => {
        const group_card_id = e?.target?.id;
        if (group_card_id) {
          const { bi_object, project_object_selected } = this.props.bi;
          const setting_list = bi_object?.setting_list || [];
          const setting_object = setting_list.find(
            (item) => item?.project_id === project_object_selected?._id
          );
          const group_card_list = setting_object?.group_card_list || [];
          const group_card_object = group_card_list.find(
            (item) => item._id === group_card_id
          );
          if (group_card_object) {
            this.props.set_value_bi({
              key: "group_card_add_object",
              value: { ...group_card_object },
            });
          }
        }
      }
    );
  };

  on_save = () => {
    const { bi_object, project_object_selected, setting_add_object } =
      this.props.bi;
    const { group_card_mode, group_card_show_other, default_group } =
      setting_add_object;
    const body = {
      _id: bi_object._id,
      project_id: project_object_selected?._id,
      group_card_mode,
      group_card_show_other,
      default_group,
    };
    this.props.edit_group_config(body);
    this.props.set_value_bi({
      key: "modal_edit_group_card",
      value: false,
    });
    this.props.set_value_bi({
      key: "group_card_id_selected",
      value: default_group,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { modal_menu, anchor, modal_delete, modal_reorder } = this.state;

    //global props
    const { loading_item_array, setting_add_object } = this.props.bi;

    //content
    const group_card_order = setting_add_object?.group_card_order || [];
    const group_card_mode = setting_add_object?.group_card_mode || "button";
    const group_card_show_other =
      setting_add_object?.group_card_show_other || "show";
    let group_card_list = setting_add_object?.group_card_list || [];
    const default_group = setting_add_object?.default_group;

    if (group_card_list.length > 0) {
      group_card_list = group_card_order.map((group_id) => {
        return group_card_list.find((element) => element?._id === group_id);
      });
    }

    const mode_array = [
      {
        name: dict?.["Button (only show when button is clicked)"]?.[language],
        key: "button background_blue",
      },
      {
        name: dict?.["Expand (show all and group)"]?.[language],
        key: "expand",
      },
      {
        name: dict?.["Options (only show if option is selected)"]?.[language],
        key: "selection",
      },
    ];

    const show_array = [
      {
        name: dict?.["Show all-button"]?.[language],
        key: "show",
      },
      {
        name: dict?.["Hide all-button"]?.[language],
        key: "hide",
      },
    ];

    const modal_menu_content = modal_menu && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_menu}
        onClose={this.toggle_menu_card}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        style={{ zIndex: 2000 }}
      >
        <div style={{ padding: "3px 10px 3px 10px" }}>
          <button
            className="button button_white margin_bottom outline_black"
            onClick={this.on_edit}
          >
            {dict?.["Edit"]?.[language]}
          </button>
          <br />
          <button
            className="button button_white margin_bottom outline_black"
            onClick={this.toggle_delete}
          >
            {dict?.["Delete"]?.[language]}
          </button>
          <br />
          <button
            className="button button_white outline_black"
            onClick={this.toggle_reorder}
          >
            Reorder
          </button>
        </div>
      </Menu>
    );

    const modal_delete_content = modal_delete && (
      <Modal
        modalSize="small"
        isOpen={modal_delete}
        onClose={this.toggle_delete}
      >
        <div className="box-body" id="box-body">
          <p className="text_medium margin_bottom">
            {dict?.["Confirm delete data group?"]?.[language]}
          </p>
          <br />
          <button
            className="button background_red margin_top"
            onClick={this.on_delete}
          >
            {dict?.["Delete"]?.[language]}
          </button>
        </div>
      </Modal>
    );

    const modal_reorder_content = modal_reorder && (
      <Modal
        modalSize="small"
        isOpen={modal_reorder}
        onClose={this.toggle_reorder}
      >
        <div className="box-body" id="box-body">
          <BI_REORDER_GROUP toggle_reorder={this.toggle_reorder} />
        </div>
      </Modal>
    );

    return (
      <main className="max_height">
        {modal_menu_content}
        {modal_delete_content}
        {modal_reorder_content}
        <h1 className="text_bold margin_bottom">
          {dict?.["Data group settings"]?.[language]}
        </h1>
        <section className="container_light margin_bottom">
          <p className="text_medium">
            {dict?.["Grouping display mode"]?.[language]}
          </p>
          <select
            name="group_card_mode"
            value={group_card_mode}
            onChange={this.on_change}
          >
            {mode_array.map((item, idx) => {
              return (
                <option key={idx} value={item.key}>
                  {item.name}
                </option>
              );
            })}
          </select>
          <br />
          <br />
          <p className="text_medium">
            {dict?.["Show all data button"]?.[language]}
          </p>
          <select
            name="group_card_show_other"
            value={group_card_show_other}
            onChange={this.on_change}
          >
            {show_array.map((item, idx) => {
              return (
                <option key={idx} value={item.key}>
                  {item.name}
                </option>
              );
            })}
          </select>
          <br />
          <br />
          <p className="text_medium">
            {dict?.["Select the default group"]?.[language]}
          </p>
          <select
            name="default_group"
            value={default_group}
            onChange={this.on_change}
          >
            <option value="">
              {dict?.["Select the default group"]?.[language]}
            </option>
            {group_card_list.map((item, idx) => {
              return (
                <option key={idx} value={item?._id}>
                  {item?.name}
                </option>
              );
            })}
          </select>
          <br />
          <br />
          {loading_item_array.includes("edit_group_config") ? (
            <div className="button background_blue">
              {dict?.["Saving"]?.[language]}...
            </div>
          ) : (
            <button className="button background_blue" onClick={this.on_save}>
              {dict?.["Save"]?.[language]}
            </button>
          )}
        </section>
        {group_card_list.map((item, idx) => {
          return (
            <div
              key={idx}
              className="container_light margin_right margin_bottom outline_black"
              style={{ width: "150px", display: "inline-block" }}
            >
              <table className="full_width">
                <tbody>
                  <tr>
                    <td>
                      <p className="text_small inline">{item?.name}</p>
                    </td>
                    <td className="text_right">
                      <button
                        onClick={this.toggle_menu_card}
                        className="button background_white outline_black"
                        id={item?._id}
                      >
                        ⫶
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          );
        })}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
});

export default connect(mapStateToProps, {
  set_value_bi,
  delete_group_card,
  edit_group_config,
  edit_inside_object,
})(BI_EDIT_GROUP);
