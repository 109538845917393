/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import io from "socket.io-client";

/*COMPONENT*/

/*REDUX*/
import { getLayer_Id } from "../../App/actions/layerActions";

/*PICTURE*/

/*FUNCTION*/

/*DATA*/

/*CONST*/
const SOCKET_URL_MAPID = "https://socket.mapid.io";
// const SOCKET_URL_MAPID = "http://localhsot:5000";

class NOTIFICATION extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newData: [],
    };
    this.max = 99;
    this.socket = io.connect(SOCKET_URL_MAPID, {
      secure: true,
      reconnection: true,
      rejectUnauthorized: false,
    });
  }

  componentDidMount() {
    const { geo_layer } = this.props;

    // Listener untuk updateFeature di awal saat komponen mount
    this.socket.on(`updateFeature_${geo_layer._id}`, this.handleUpdateFeature);
  }

  componentDidUpdate(prevProps) {
    const { geo_layer } = this.props;

    // Hanya men-trigger logika ketika geo_layer berubah
    if (prevProps.geo_layer._id !== geo_layer._id) {
      // Hapus listener untuk layer sebelumnya
      this.socket.off(
        `updateFeature_${prevProps.geo_layer._id}`,
        this.handleUpdateFeature
      );

      // Set state newData jadi kosong dan tambahkan listener baru
      this.setState({
        newData: [],
      });

      // Tambahkan listener baru untuk layer yang sekarang
      this.socket.on(
        `updateFeature_${geo_layer._id}`,
        this.handleUpdateFeature
      );
    }
  }

  componentWillUnmount() {
    const { geo_layer } = this.props;

    // Hapus listener ketika komponen di-unmount
    this.socket.off(`updateFeature_${geo_layer._id}`, this.handleUpdateFeature);
  }

  // Handler untuk updateFeature event
  handleUpdateFeature = (data) => {
    const { user } = this.props.auth;
    const { geo_layer } = this.props;
    const link = geo_layer.link;

    const body = {
      link: link,
      geo_layer_id: geo_layer._id,
      geo_project_id: geo_layer.geo_project._id,
      user_id: user._id,
    };

    // Memanggil getLayer_Id hanya sekali ketika ada perubahan
    this.props.getLayer_Id(body);

    this.setState((prevState) => {
      const isSameData = prevState.newData.some((dt) => dt === data);

      if (!isSameData) {
        return { newData: [...prevState.newData, data] };
      }
      return { newData: prevState.newData };
    });
  };

  render() {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {this.state.newData.length > 0 ? (
          <p
            style={{
              backgroundColor: "#f9f9f9",
              border: "1px solid #ccc",
              borderRadius: "15px",
              margin: "5px",
              padding: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              display: "inline-block",
            }}
          >
            <span
              style={{
                backgroundColor: "#007bff",
                color: "#fff",
                borderRadius: "50%",
                width: "30px",
                height: "30px",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "12px",
                marginRight: "10px",
              }}
            >
              {this.state.newData.length > 0 ? this.state.newData.length : null}
              {this.state.newData.length >= this.state.max ? "+" : null}
            </span>
            Data Baru
          </p>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, { getLayer_Id })(NOTIFICATION);
