/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactDOMServer from "react-dom/server";
import maplibregl from "maplibre-gl";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";

/*REDUX FUNCTION*/
import { set_value_sini } from "../../App/actions/sini_v2";
import { set_value_layer } from "../../App/actions/layerActions";
import { get_sini_data } from "../../App/actions/get_sini_data";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";
import uuid from "../../App/validation/uuid";
import domain_list from "../../Data/domain_list";

/*NON IMPORT*/

class LAYER_GPS extends Component {
  state = {
    modal_sini_trigger: false,
  };

  componentDidUpdate(prevProps) {
    const uuid_before = prevProps.layer.gps_object?.uuid;
    const uuid_after = this.props.layer.gps_object?.uuid;
    const basemap_used_after = this.props.properties.basemap_used;
    const basemap_used_before = prevProps.properties.basemap_used;
    if (basemap_used_after !== basemap_used_before) {
      const { map_object } = this.props.layer;
      if (map_object !== null) {
        if (!map_object.isStyleLoaded()) {
          map_object.once("styledata", () => {
            this.on_render();
          });
        } else {
          this.on_render();
        }
      }
    }
    if (uuid_before !== uuid_after) {
      const { map_object } = this.props.layer;
      if (map_object !== null) {
        if (!map_object.isStyleLoaded()) {
          map_object.once("styledata", () => {
            this.on_render();
            this.on_fly();
          });
        } else {
          this.on_render();
          this.on_fly();
        }
      }
    }
  }

  toggle_sini_trigger = () => {
    this.setState({
      modal_sini_trigger: !this.state.modal_sini_trigger,
    });
  };

  trigger_sini = () => {
    this.on_remove_marker();
    const { domain } = this.props.auth;
    const { gps_object } = this.props.layer;
    const show_survey_1 = domain_list?.[domain]?.show_survey_1;
    const longitude = gps_object?.longitude;
    const latitude = gps_object?.latitude;
    const params = {
      request_id: uuid(),
      long: longitude,
      lat: latitude,
      km_rad: 1,
      sini_data_mode: "sini_general",
      show_survey_1,
    };
    this.props.get_sini_data(params);
    this.props.set_value_sini({
      key: "sini_menu_active",
      value: "",
    });
    this.props.set_value_sini({
      key: "is_sini_visible",
      value: true,
    });
    this.props.set_value_layer({
      key: "statistic_mode",
      value: "sini",
    });
  };

  on_remove_marker = () => {
    this.setState({ modal_sini_trigger: false });
    const { map_object } = this.props.layer;
    if (map_object) {
      if (map_object.getLayer("geojson_gps")) {
        map_object.removeLayer("geojson_gps");
      }
      if (map_object.getSource("geojson_gps")) {
        map_object.removeSource("geojson_gps");
      }
    }
    const marker_element = document.getElementById("gps_map");
    if (marker_element) {
      marker_element.remove();
    }
  };

  on_render = () => {
    const language = localStorage?.language || "ina";
    const { map_object, gps_object } = this.props.layer;
    const longitude = gps_object?.longitude;
    const latitude = gps_object?.latitude;
    if (map_object && longitude && latitude) {
      let marker_element = document.getElementById("gps_map");
      if (!marker_element) {
        marker_element = document.createElement("div");
      }
      marker_element.id = "gps_map";
      marker_element.style.position = "absolute";
      marker_element.style.transform = "translate(-50%, -100%)";
      const marker_jsx = (
        <section
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <button
            className="badge_small background_black cursor_pointer"
            id="gps_title"
            aria-label="GPS title untuk menampilkan status"
          >
            {dict?.["You're here"]?.[language]}
          </button>
          <div
            style={{ width: "2px", height: "25px", backgroundColor: "black" }}
          />
        </section>
      );
      const marker_html = ReactDOMServer.renderToString(marker_jsx);
      marker_element.innerHTML = marker_html;
      const lngLat = new maplibregl.LngLat(longitude, latitude);
      const point = map_object.project(lngLat);
      marker_element.style.left = `${point.x}px`;
      marker_element.style.top = `${point.y}px`;
      map_object.getContainer().appendChild(marker_element);
      const gps_title = document.getElementById("gps_title");
      if (gps_title) {
        gps_title.addEventListener("click", this.toggle_sini_trigger);
      }
      map_object.on("move", () => {
        const newPoint = map_object.project(lngLat);
        marker_element.style.left = `${newPoint.x}px`;
        marker_element.style.top = `${newPoint.y}px`;
      });
      const geojson_gps = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [longitude, latitude],
            },
            properties: {},
          },
        ],
      };
      if (!map_object.getSource("geojson_gps")) {
        map_object.addSource("geojson_gps", {
          type: "geojson",
          data: geojson_gps,
        });
      } else {
        map_object.getSource("geojson_gps").setData(geojson_gps);
      }
      if (!map_object.getLayer("geojson_gps")) {
        map_object.addLayer({
          id: "geojson_gps",
          source: "geojson_gps",
          type: "circle",
          paint: {
            "circle-radius": 5,
            "circle-color": "#0ca5eb",
            "circle-stroke-width": 2,
            "circle-stroke-color": "#ffffff",
          },
          layout: { visibility: "visible" },
        });
      }
    }
  };

  on_fly = () => {
    const { map_object, gps_object } = this.props.layer;
    const longitude = gps_object?.longitude;
    const latitude = gps_object?.latitude;
    if (map_object && longitude && latitude) {
      map_object.flyTo({
        center: [longitude, latitude],
        speed: 1,
        zoom: 18,
      });
    }
  };

  render() {
    const { modal_sini_trigger } = this.state;
    const { isAuthenticated } = this.props.auth;
    const modal_sini_trigger_content = modal_sini_trigger && (
      <Modal
        modalSize="small"
        isOpen={modal_sini_trigger}
        onClose={this.toggle_sini_trigger}
      >
        <div className="box-body" id="box-body">
          <section className="text_center">
            {isAuthenticated && (
              <>
                <button
                  onClick={this.trigger_sini}
                  className="button_big button_white margin_bottom outline_black"
                >
                  Coba SINI di sini
                </button>
                <br />
                <br />
              </>
            )}
            <button
              className="button_big background_red margin_bottom"
              onClick={this.on_remove_marker}
            >
              CLOSE
            </button>
          </section>
        </div>
      </Modal>
    );
    return <main>{modal_sini_trigger_content}</main>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  get_sini_data,
  set_value_sini,
  set_value_layer,
})(LAYER_GPS);
