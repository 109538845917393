const capitalize_first_letter = function (string) {
  string = string.toLowerCase();
  const arr = string.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const output = arr.join(" ");
  return output;
};
export default capitalize_first_letter;
