const initialState = {
  loading: false,
  item_loading: "",
  play_public: {},
  play_private: {},
  play_list_private: [],
  play_list_public: [],
  image_object: {
    field_key: "",
    url: "",
  },
  error_list: [],
  tag_list: [],
};
export default function playReducer(state = initialState, action) {
  switch (action.type) {
    case "get_tag_list":
      return {
        ...state,
        tag_list: action.payload,
      };
    case "get_list_private_play":
      return {
        ...state,
        play_list_private: action.payload,
      };
    case "get_list_public_play":
      return {
        ...state,
        play_list_public: action.payload,
      };
    case "upload_image":
      return {
        ...state,
        image_object: action.payload,
      };
    case "create_empty":
      return {
        ...state,
        play_private: action.payload,
      };
    case "play_error":
      return {
        ...state,
        error_list: [action.payload, ...state.error_list],
      };
    case "delete_play":
      return {
        ...state,
        play_list_private: delete_play(state.play_list_private, action.payload),
      };
    case "edit_all_fields_play":
      return {
        ...state,
        play_private: edit_all_fields(state.play_private, action.payload),
      };
    case "edit_play":
      return {
        ...state,
        play_private: edit_play(state.play_private, action.payload),
      };
    case "check_link":
      return {
        ...state,
        play_private: check_link(state.play_private, action.payload),
        error_list: [],
      };
    case "check_link_error":
      return {
        ...state,
        play_private: check_link_error(state.play_private, action.payload),
      };
    case "delete_field_play":
      return {
        ...state,
        play_private: delete_field(state.play_private, action.payload),
      };
    case "push_field_play":
      return {
        ...state,
        play_private: push_field(state.play_private, action.payload),
      };
    case "edit_geometry":
      return {
        ...state,
        play_private: edit_geometry(state.play_private, action.payload),
      };
    case "edit_all_properties":
      return {
        ...state,
        play_private: edit_all_properties(state.play_private, action.payload),
      };
    case "push_feature_play":
      return {
        ...state,
        play_private: push_feature_play(state.play_private, action.payload),
      };
    case "delete_feature_play":
      return {
        ...state,
        play_private: delete_feature_play(state.play_private, action.payload),
      };
    case "get_play_public":
      return {
        ...state,
        play_public: action.payload,
      };
    case "get_play_private":
      return {
        ...state,
        play_private: action.payload,
      };
    case "set_loading":
      return {
        ...state,
        loading: true,
        item_loading: action.payload,
      };
    case "clear_loading":
      return {
        ...state,
        loading: false,
        item_loading: "",
      };
    default:
      return state;
  }
}

const push_feature_play = (play_private, payload) => {
  let { geo_layer_id, feature } = payload;
  let play_private_clone = { ...play_private };
  let slide_list = play_private_clone.slide_list;
  let slide = slide_list.find((e) => e?.layer?._id === geo_layer_id);
  let index = slide_list.findIndex((e) => e?.layer?._id === geo_layer_id);
  let layer = slide.layer;
  let geojson = layer?.geojson
    ? layer?.geojson
    : { type: "FeatureCollection", features: [] };
  geojson.features.push(feature);
  layer.geojson = geojson;
  slide.layer = layer;
  slide_list.splice(index, 1, slide);
  play_private_clone.slide_list = slide_list;
  return play_private_clone;
};

const delete_feature_play = (play_private, payload) => {
  const { geo_layer_id, feature_key } = payload;
  let play_private_clone = { ...play_private };
  let slide_list = play_private_clone.slide_list;
  let slide = slide_list.find((e) => e?.layer?._id === geo_layer_id);
  let index = slide_list.findIndex((e) => e?.layer?._id === geo_layer_id);
  let layer = slide.layer;
  let geojson = layer?.geojson
    ? layer?.geojson
    : { type: "FeatureCollection", features: [] };
  let features = geojson.features.filter(
    (feature_element) => feature_element.key !== feature_key
  );
  geojson.features = features;
  layer.geojson = geojson;
  slide.layer = layer;
  slide_list.splice(index, 1, slide);
  play_private_clone.slide_list = slide_list;
  return play_private_clone;
};

const edit_all_properties = (play_private, payload) => {
  const { geo_layer_id, feature_key, properties } = payload;
  let play_private_clone = { ...play_private };
  let slide_list = play_private_clone.slide_list;
  let slide = slide_list.find((e) => e?.layer?._id === geo_layer_id);
  let index = slide_list.findIndex((e) => e?.layer?._id === geo_layer_id);
  let layer = slide.layer;
  let geojson = layer?.geojson
    ? layer?.geojson
    : { type: "FeatureCollection", features: [] };
  let features = geojson.features;
  let feature = features.find((e) => e?.key === feature_key);
  let feature_index = features.findIndex((e) => e?.key === feature_key);
  feature.properties = properties;
  features.splice(feature_index, 1, feature);
  geojson.features = features;
  layer.geojson = geojson;
  slide.layer = layer;
  slide_list.splice(index, 1, slide);
  play_private_clone.slide_list = slide_list;
  return play_private_clone;
};

const edit_geometry = (play_private, payload) => {
  const { geo_layer_id, feature_key, geometry } = payload;
  let play_private_clone = { ...play_private };
  let slide_list = play_private_clone.slide_list;
  let slide = slide_list.find((e) => e?.layer?._id === geo_layer_id);
  let index = slide_list.findIndex((e) => e?.layer?._id === geo_layer_id);
  let layer = slide.layer;
  let geojson = layer?.geojson
    ? layer?.geojson
    : { type: "FeatureCollection", features: [] };
  let features = geojson.features;
  let feature = features.find((e) => e?.key === feature_key);
  let feature_index = features.findIndex((e) => e?.key === feature_key);
  feature.geometry = geometry;
  features.splice(feature_index, 1, feature);
  geojson.features = features;
  layer.geojson = geojson;
  slide.layer = layer;
  slide_list.splice(index, 1, slide);
  play_private_clone.slide_list = slide_list;
  return play_private_clone;
};

const push_field = (play_private, payload) => {
  const { geo_layer_id, field } = payload;
  let play_private_clone = { ...play_private };
  let slide_list = play_private_clone.slide_list;
  let slide = slide_list.find((e) => e?.layer?._id === geo_layer_id);
  let index = slide_list.findIndex((e) => e?.layer?._id === geo_layer_id);
  let layer = slide.layer;
  let fields = layer?.fields ? layer?.fields : [];
  fields.push(field);
  layer.fields = fields;
  slide.layer = layer;
  slide_list.splice(index, 1, slide);
  play_private_clone.slide_list = slide_list;
  return play_private_clone;
};

const delete_field = (play_private, payload) => {
  const { geo_layer_id, field_key } = payload;
  let play_private_clone = { ...play_private };
  let slide_list = play_private_clone.slide_list;
  let slide = slide_list.find((e) => e?.layer?._id === geo_layer_id);
  let index = slide_list.findIndex((e) => e?.layer?._id === geo_layer_id);
  let layer = slide.layer;
  let fields = layer?.fields ? layer?.fields : [];
  fields = fields.filter((f) => f.key !== field_key);
  layer.fields = fields;
  slide.layer = layer;
  slide_list.splice(index, 1, slide);
  play_private_clone.slide_list = slide_list;
  return play_private_clone;
};

const edit_all_fields = (play_private, payload) => {
  const { geo_layer_id, fields } = payload;
  let play_private_clone = { ...play_private };
  let slide_list = play_private_clone.slide_list;
  let slide = slide_list.find((e) => e?.layer?._id === geo_layer_id);
  let index = slide_list.findIndex((e) => e?.layer?._id === geo_layer_id);
  let layer = slide.layer;
  layer.fields = fields;
  slide.layer = layer;
  slide_list.splice(index, 1, slide);
  play_private_clone.slide_list = slide_list;
  return play_private_clone;
};

const check_link = (play_private, payload) => {
  const { link } = payload;
  let play_private_clone = { ...play_private };
  play_private_clone.link = link;
  return play_private_clone;
};

const check_link_error = (play_private, payload) => {
  const link = payload;
  let play_private_clone = { ...play_private };
  play_private_clone.link = link;
  return play_private_clone;
};

const edit_play = (play_private, payload) => {
  const { title, caption, tag_list, delay, basemap, is_public } = payload;
  let play_private_clone = {
    ...play_private,
    title,
    caption,
    tag_list,
    delay,
    basemap,
    is_public,
  };
  return play_private_clone;
};

const delete_play = (play_list_private, payload) => {
  const { play_id } = payload;
  let play_list_private_clone = play_list_private
    .slice()
    .filter((p) => p._id !== play_id);
  return play_list_private_clone;
};
