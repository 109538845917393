/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_sini } from "../../App/actions/sini_v2";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class SINI_POINT_CONFIG extends Component {
  state = {};

  on_change = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.props.set_value_sini({
      key: name,
      value: value,
    });
  };

  on_toggle = (e) => {
    const name = e.target.name;
    this.props.set_value_sini({
      key: name,
      value: !this.props.sini[name],
    });
  };

  renderScale = (min, max, step) => {
    const scaleNumbers = [];
    for (let i = min; i <= max; i += step) {
      let number = i;
      if (!Number.isInteger(i)) {
        number = i.toFixed(1);
      }
      scaleNumbers.push(number);
    }
    return (
      <div className="scale_range text_small">
        {scaleNumbers.map((number) => (
          <span key={number} className="scale_range_number">
            {number}
          </span>
        ))}
      </div>
    );
  };

  render() {
    //local storage

    //local state

    //global props
    const {
      // sini_show_poi_marker,
      sini_circle_radius,
      // sini_logo_size,
    } = this.props.sini;

    //content

    return (
      <main>
        <section className="margin_bottom">
          <p className="text_small">
            sini_circle_radius ({sini_circle_radius})
          </p>
          <input
            name="sini_circle_radius"
            value={sini_circle_radius}
            onChange={this.on_change}
            min="0"
            max="5"
            step="0.01"
            type="range"
            data-mapid="sliderRadiusCircle"
          />
          {this.renderScale(0, 20, 1)}
        </section>
        {/* <section className="margin_bottom">
          <p className="text_small">sini_logo_size ({sini_logo_size})</p>
          <input
            name="sini_logo_size"
            value={sini_logo_size}
            onChange={this.on_change}
            min="5"
            max="100"
            step="1"
            type="range"
            data-mapid="sliderLogo"
          />
          {this.renderScale(5, 100, 10)}
        </section>
        <section className="margin_bottom">
          <p className="text_small">sini_show_poi_marker</p>
          <br />
          <button
            className={
              sini_show_poi_marker ? "checklist_active" : "checklist_not"
            }
            name="sini_show_poi_marker"
            onClick={this.on_toggle}
            data-mapid="clickCentang"
          />
        </section> */}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, { set_value_sini })(SINI_POINT_CONFIG);
