import axios from "axios";
import imageCompression from "browser-image-compression";
import mapidLogo from "../../Assets/png_jpg/logo_circle.png";
import badge from "../../Assets/png_jpg/badge_notification.png";
import his from "./history";
import { urlB64ToUint8Array } from "../validation/worker-stuff";
import checkBrowser from "../validation/check-browser";
import checkDevice from "../validation/check-device";
import { getGroups } from "./groupActions";
import { geoServerBaseUrl } from "./baseUrl";
import { getUserPaymentList } from "./paymentActions";
import { get_history_project } from "./projectActions";
import { snackbar } from "./snackbarActions";
import { verify_license_user, verify_license_group } from "./license_actions";
import domain_list from "../../Data/domain_list";

const SERVER_URL = geoServerBaseUrl;
const ALPHA_SERVER_URL = "https://alphaserver.mapid.io";
// const BUN_KAI = "http://localhost:4006"
// const BUN_KAI = "https://server-kim.kai.id";

// const bun_server = "http://localhost:4004";
const bun_server = "https://geoserver.mapid.io";

/*PROTOYPE*/

//set_value_user
export const set_value_user = (key, value) => {
  return {
    type: "set_value_user",
    payload: { key, value },
  };
};

//push_notification_config
export const push_notification_config = () => async (dispatch) => {
  try {
    if (!("serviceWorker" in navigator)) throw Error("Browser not supported");
    let reg;
    const swreg = await navigator.serviceWorker.ready();
    // const sub = await swreg.pushManager
    await swreg.pushManager
      .getSubscription()
      .then(function (sub) {
        if (sub === null) {
          var vapidPublicKey =
            "BJweY8HYxau5mgEUWF7F-k0E3S_bLn1px6v9cI28_Cp0lLASaE51OJbnuQpwVlOw1Xx4bva9i4mYTwdJbSLYcb8";
          var convertedKey = urlB64ToUint8Array(vapidPublicKey);
          return reg.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: convertedKey,
          });
        } else {
          //We already have a subscription
        }
      })
      //kirim ke DB
      .then(function (newSub) {
        const newSubClone = { ...newSub };
        const browser = checkBrowser();
        const device = checkDevice();
        newSub.browser = browser;
        newSub.device = device;
        newSub.name = "Nama device";
        return fetch(SERVER_URL + "/users/sub", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            accesstoken: localStorage.token_mapid,
          },
          body: JSON.stringify(newSubClone),
        });
      })
      .then(function (res) {
        if (res.ok) {
          displayNotif();
        }
      })
      .catch(function (err) { });
  } catch (error) { }
};

//push_notification_config
export const push_notification_config_old = () => async (dispatch) => {
  try {
    if (!("serviceWorker" in navigator)) {
      return;
    }
    let reg;
    navigator.serviceWorker.ready
      .then(function (swreg) {
        reg = swreg;
        return swreg.pushManager.getSubscription();
      })
      .then(function (sub) {
        if (sub === null) {
          var vapidPublicKey =
            "BJweY8HYxau5mgEUWF7F-k0E3S_bLn1px6v9cI28_Cp0lLASaE51OJbnuQpwVlOw1Xx4bva9i4mYTwdJbSLYcb8";
          var convertedKey = urlB64ToUint8Array(vapidPublicKey);
          return reg.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: convertedKey,
          });
        } else {
          //We already have a subscription
        }
      })
      //kirim ke DB
      .then(function (newSub) {
        const newSubClone = { ...newSub };
        const browser = checkBrowser();
        const device = checkDevice();
        newSub.browser = browser;
        newSub.device = device;
        newSub.name = "Nama device";
        return fetch(SERVER_URL + "/users/sub", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            accesstoken: localStorage.token_mapid,
          },
          body: JSON.stringify(newSubClone),
        });
      })
      .then(function (res) {
        if (res.ok) {
          displayNotif();
        }
      })
      .catch(function (err) { });
  } catch (error) { }
};

export const initApi = (body) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("init_api"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(SERVER_URL + "/users/init_api", body, config);
    dispatch({
      type: "INIT_API",
      payload: res.data,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

// forget password
export const forget_password = (body) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("forget_password"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(SERVER_URL + "/mail/new_password", body, config);
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

// Email forget password
export const email_forget_password = (body) => async (dispatch) => {
  try {
    dispatch(setAuthLoading());
    const res = await axios.post(SERVER_URL + "/mail/forgot_password", body);
    dispatch(clearLoading());
    return res;
  } catch (e) {
    dispatch(clearLoading());
    return e.response;
  }
};

// Whatsapp forget password
export const whatsapp_forget_password = (body) => async (dispatch) => {
  try {
    dispatch(setAuthLoading());
    // const res = await axios.post(SERVER_URL + "/users/forgot_password", body);
    const res = await axios.post(
      ALPHA_SERVER_URL + "/users/forgot_password",
      body
    );
    dispatch(clearLoading());
    return res;
  } catch (e) {
    dispatch(clearLoading());
    return e.response;
  }
};

// code forget Password
export const code_forget = (body) => async (dispatch) => {
  try {
    dispatch(setAuthLoading());
    await axios.post(SERVER_URL + "/mail/code_forgot_password", body);
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

// Email Verification
export const email_verification = (body) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("email_verification"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(SERVER_URL + "/mail/verification", body, config);
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

// whatsapp verification
export const whatsapp_verification = (body) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("whatsapp_verification"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(
      ALPHA_SERVER_URL + "/users/verify_otp",
      body,
      config
    );
    dispatch(clearLoading());
    if (res.status === 200) {
      dispatch({ type: "edit_whatsapp_confirm" });
    }
    if (res.status === 404) {
      dispatch({ type: "failed_whatsapp_confirm" });
    }
    return res;
  } catch (e) {
    dispatch(clearLoading());
    return e?.response || { status: 400 };
  }
};

// send otp wa
export const send_otp_wa = (body) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("send_otp_wa"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(ALPHA_SERVER_URL + "/users/send_otp", body, config);
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

// edit no wa
export const edit_no_wa = (body) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("edit_no_wa"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(ALPHA_SERVER_URL + "/users/edit_whatsapp", body, config);
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//verivikasi code
export const code_verification = (body) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("code_verification"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(SERVER_URL + "/mail/code", body, config);
    dispatch(clearLoading());
    if (res.status === 200) {
      dispatch({ type: "edit_email_confirm" });
    }
    if (res.status === 404) {
      dispatch({ type: "failed_email_confirm" });
    }
  } catch (e) {
    dispatch(clearLoading());
  }
};

//Edit text profile modal
export const edit_text_profile_2 = (body) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("edit_text_profile"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    // await axios.post(SERVER_URL + "/users/edit_text_profile_2", body, config);
    await axios.post(
      ALPHA_SERVER_URL + "/users/edit_text_profile_2",
      body,
      config
    );

    dispatch({
      type: "edit_text_profile",
      payload: body,
    });
    dispatch(clearLoading());
    his.push("/user_profile");
    dispatch(
      snackbar({
        is_open: true,
        status: "success",
        message: "Your profile was successfully updated",
      })
    );
  } catch (e) {
    dispatch(clearLoading());
    dispatch(
      snackbar({
        is_open: true,
        status: "error",
        message: e?.response?.data?.name || "Failed to update",
      })
    );
  }
};

//Edit text profile
export const edit_text_profile = (body) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("edit_text_profile"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(SERVER_URL + "/users/edit_text_profile", body, config);
    dispatch({
      type: "edit_text_profile",
      payload: body,
    });
    dispatch(clearLoading());
    // his.push("/user_profile");
  } catch (e) {
    dispatch(clearLoading());
  }
};

//Edit photo profile
export const upload_photo_profile = (item) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("upload_photo_profile"));
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const config_header_upload = {
      headers: {
        accesstoken: localStorage.token_mapid,
        "Content-Type": "multipart/form-data",
      },
    };
    //Original file
    const { objParams } = item;
    const file = objParams.file;
    //Compressed file big
    const options_compression_big = {
      maxSizeMB: 1.5,
      maxWidthOrHeight: 1500,
      useWebWorker: true,
    };
    const file_name_big = objParams.file_name_big;
    const file_big = await imageCompression(file, options_compression_big);
    const formData = new FormData();
    formData.append("original_file_name", `${file_name_big}`);
    formData.append("file", file_big);
    const resultBigUpload = await axios.post(
      SERVER_URL + "/upload",
      formData,
      config_header_upload
    );
    const url = resultBigUpload.data.data.Location;
    // Compressed file small
    const options_compression_small = {
      maxSizeMB: 0.01,
      maxWidthOrHeight: 50,
      useWebWorker: true,
    };
    const file_small = await imageCompression(file, options_compression_small);
    const file_name_small = objParams.file_name_small;
    const formDataSmall = new FormData();
    formDataSmall.append("original_file_name", `${file_name_small}`);
    formDataSmall.append("file", file_small);
    const resultSmallUpload = await axios.post(
      SERVER_URL + "/upload",
      formDataSmall,
      config_header_upload
    );
    const url_compressed = resultSmallUpload.data.data.Location;

    //Generate body & axios to API
    const body = {
      profile_picture: {
        url,
        name: file_name_big,
        url_compressed,
        name_compressed: file_name_small,
      },
    };
    await axios.post(
      SERVER_URL + "/users/edit_photo_profile",
      body,
      config_header
    );
    dispatch(clearLoading());
    dispatch({
      type: "UPDATE_PP",
      payload: body,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

/*NON API*/
export const setModeList = (mode_list) => {
  localStorage.setItem("mode_list", mode_list);
  return {
    type: "SET_MODE_LIST",
    payload: mode_list,
  };
};

export const setLanguage = (language) => {
  localStorage.setItem("language", language);
  return {
    type: "SET_LANGUAGE",
    payload: language,
  };
};

export const openModal = (name) => {
  return {
    type: "OPEN_MODAL",
    payload: name,
  };
};

export const closeModal = () => {
  return {
    type: "CLOSE_MODAL",
  };
};

export const setAuthLoading = () => {
  return {
    type: "AUTH_LOADING",
  };
};

export const setLoadingDetail = () => {
  return {
    type: "AUTH_LOADING",
  };
};

export const setLoadingProcess = (itemLoading) => {
  return {
    type: "AUTH_LOADING",
    payload: itemLoading,
  };
};

export const clearLoading = () => {
  return {
    type: "CLEAR_AUTH_LOADING",
  };
};
//Set logged in user
export const setCurrentUser = (user) => {
  return {
    type: "SET_CURRENT_USER",
    payload: user,
  };
};

// Set logged in user
export const setCurrentToken = (decoded) => {
  return {
    type: "SET_CURRENT_TOKEN",
    payload: decoded,
  };
};

// Clear errors
export const clearErrors = () => {
  return {
    type: "CLEAR_ERRORS",
  };
};

export const setPathname = (pathname) => {
  return {
    type: "SET_PATHNAME",
    payload: pathname,
  };
};

//send link forget password
export const sendLink = (body) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("send_link"));
    await axios.post(SERVER_URL + "/users/send_link_forget_pass", body);
    dispatch({
      type: "SEND_LINK",
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//verify link
export const verifyLink = (body) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("verify_link"));
    await axios.post(SERVER_URL + "/users/verify_link", body);
    dispatch({
      type: "VERIFY_LINK",
      payload: "success",
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch({
      type: "VERIFY_LINK",
      payload: "failed",
    });
    dispatch(clearLoading());
  }
};

//send new password and verify link
export const sendPassword = (body) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("setting_password"));
    await axios.post(SERVER_URL + "/users/setting_password", body);
    dispatch({
      type: "VERIFY_LINK",
      payload: "send",
    });
    dispatch(clearLoading());
    his.push("/login");
  } catch (e) {
    dispatch({
      type: "GET_ERRORS",
      payload: e?.response?.data,
    });
    dispatch({
      type: "VERIFY_LINK",
      payload: "failed",
    });
    dispatch(clearLoading());
  }
};

//0. Incriment map access every view map
export const incMapAccess = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken:
          localStorage && localStorage?.token_mapid
            ? localStorage?.token_mapid
            : "",
      },
    };
    const res = await axios.get(SERVER_URL + "/users/inc_map_access", config);
    dispatch({
      type: "INC_MAP_ACCESS",
      payload: res.data,
    });
  } catch (e) { }
};

//0. Get map access
export const getMapAccessMonthly = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken:
          localStorage && localStorage?.token_mapid
            ? localStorage?.token_mapid
            : "",
      },
    };
    const res = await axios.get(
      SERVER_URL + "/users/get_map_access_monthly",
      config
    );
    dispatch({
      type: "get_map_access_monthly",
      payload: res.data,
    });
  } catch (e) { }
};

//0. Get map access
export const getMapAccess = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken:
          localStorage && localStorage?.token_mapid
            ? localStorage?.token_mapid
            : "",
      },
    };
    const res = await axios.get(SERVER_URL + "/users/get_map_access", config);
    dispatch({
      type: "GET_MAP_ACCESS",
      payload: res.data,
    });
  } catch (e) { }
};

//1. Register User
export const registerUser = (body, redeem_code) => async (dispatch) => {
  // step 1 : register
  let register_res;
  try {
    dispatch(setAuthLoading());
    // register_res = await axios.post(SERVER_URL + "/users/register", body);
    register_res = await axios.post(ALPHA_SERVER_URL + "/users/register", body);
    // register_res = await axios.post("localhost:4004" + "/users/register", body);
  } catch (error) {
    dispatch(clearLoading());
    dispatch({
      type: "GET_ERRORS",
      payload: error?.response?.data || {},
    });
    return;
  }

  // step 2: redeem code
  try {
    if (register_res?.status === 200 && redeem_code) {
      const userData = {
        name: register_res?.data?.name,
        password: body?.password,
      };
      const login_res = await axios.post(
        bun_server + "/auth_bun/login",
        userData
      );

      const config = {
        headers: {
          accesstoken: login_res?.data?.token,
        },
      };
      await axios.post(
        SERVER_URL + "/redeem/use",
        { code: redeem_code },
        config
      );
    }
  } catch (error) {
    dispatch(clearLoading());
    dispatch({
      type: "GET_ERRORS",
      payload: error?.response?.data || {},
    });
  }

  // step 3: login
  try {
    dispatch(clearLoading());
    dispatch({
      type: "SET_REGISTER_SUCCESS",
    });
    dispatch({
      type: "GET_ERRORS",
      payload: {},
    });

    const { name, password } = body;
    const body_new = { name, password };
    await dispatch(loginUser(body_new));
  } catch (error) {
    dispatch(clearLoading());
    dispatch({
      type: "GET_ERRORS",
      payload: error?.response?.data || {},
    });
  }
};

export const setErrors = (body) => async (dispatch) => {
  dispatch({
    type: "GET_ERRORS",
    payload: body || {},
  });
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem("token_mapid");
  // Remove auth header for future requests
  // Set current user to {} which will set isAuthenticated to false
  dispatch({
    type: "GET_PROJECT_LIST",
    payload: [],
  });
  dispatch({
    type: "set_value_license",
    payload: {
      key: "license_user_status",
      value: {},
    },
  });
  dispatch({
    type: "set_value_license",
    payload: {
      key: "license_group_status",
      value: {},
    },
  });
  dispatch({
    type: "set_value_license",
    payload: {
      key: "group_family",
      value: {},
    },
  });
  dispatch(setCurrentUser({}));
};

//Display Notif First
export const displayNotif = () => {
  if ("serviceWorker" in navigator) {
    var options = {
      body: "Anda akan menerima notifikasi dari MAPID",
      icon: mapidLogo,
      // image: image,
      dir: "ltr",
      lang: "en-US", //BCP 47
      vibrate: [100, 50, 200],
      badge: badge,
      tag: "MAPID",
      renotify: true,
      actions: [{ action: "ok", title: "Okay", icon: mapidLogo }],
    };
    navigator.serviceWorker.ready.then(function (swreg) {
      swreg.showNotification("MAPID", options);
    });
  }
};

//1. Search Users
export const searchUser = (search) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.token_mapid,
    },
  };

  axios
    .get(SERVER_URL + `/groups/search_user/${search}`, config)
    .then((res) => {
      dispatch({
        type: "SEARCH_USERS",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: "SEARCH_USERS",
        payload: [],
      });
    });
};

export const resetSearchUser = () => (dispatch) => {
  dispatch({
    type: "SEARCH_USERS",
    payload: [],
  });
};


// check username
export const usernameCheck = (name) => async (dispatch) => {
  try {
    const res = await axios.get(bun_server + `/auth_bun/username/${name}`);
    const message = res?.data?.is_available && "Already Exist"
    if (message) {
      dispatch({
        type: "GET_ERRORS",
        payload: { name: message } || {},
      });
    }

  } catch (error) { }
};


//1. Login
export const loginUser = (userData) => async (dispatch) => {
  try {
    dispatch({
      type: "VERIFY_LINK",
      payload: "pending",
    });
    dispatch(setLoadingProcess("sign_in"));
    let isBrantas = window.location.hostname === "bbwsbrantas.mapid.io";
    let isNav = function (fromisNav) {
      if (fromisNav) {
        return null;
      } else {
        if (isBrantas) {
          return his.push("/view/5f5082bffa0607423946a06b");
        } else if (
          [
            "bbwsciliwungcisadane.mapid.io",
            "kabalai.mapid.io",
            "localhost",
            "ptpi.mapid.io",
            "danamas.mapid.io",
            "befa.mapid.io",
          ].includes(window.location.hostname)
        ) {
          return his.push("/dashboard");
        } else {
          return his.push("/");
        }
      }
    };
    //bun_server

    const res = await axios.post(bun_server + "/auth_bun/login", userData);

    localStorage.setItem("token_mapid", res.data.token);
    localStorage.setItem("mapid_user", JSON.stringify(res.data.user));
    dispatch(setCurrentUser(res.data.user));
    dispatch(setCurrentToken(res.data.token));
    dispatch(getGroups(res.data.user._id));
    dispatch(getUserPaymentList());
    dispatch(get_quota_access());
    dispatch(get_history_project());
    dispatch(clearLoading());
    dispatch(clearErrors());
    dispatch(verify_license_user());
    dispatch(verify_license_group());
    isNav(userData.isNav);
  } catch (error) {
    dispatch({
      type: "GET_ERRORS",
      payload: error && error.response ? error.response.data : {},
    });
    dispatch(clearLoading());
  }
};

// Login KAI
export const loginUserKAI = (userData) => async (dispatch) => {
  try {
    const domain_w_port = window.location.host;
    const is_domain_kai = domain_list[domain_w_port]?.is_domain_kai;
    if (!is_domain_kai) throw new Error("you don't have access");

    let kai_host = "https://server-kim.kai.id";

    if (domain_w_port === "kim-alpha.kai.id") {
      kai_host = "https://server-alpha-kim.kai.id";
    } else if (domain_w_port === "kim-beta.kai.id") {
      kai_host = "https://server-beta-kim.kai.id";
    }
    const res = await axios.post(`${kai_host}/auth_kai/login`, userData);

    // step 3: login
    dispatch(clearLoading());
    dispatch({
      type: "SET_REGISTER_SUCCESS",
    });
    dispatch({
      type: "GET_ERRORS",
      payload: {},
    });

    if (res) {
      localStorage.setItem("token_mapid", res.data.token);
      localStorage.setItem("mapid_user", JSON.stringify(res.data.user));
      dispatch(setCurrentUser(res.data.user));
      dispatch(setCurrentToken(res.data.token));
      dispatch(getGroups(res.data.user._id));
      dispatch(getUserPaymentList());
      dispatch(get_quota_access());
      dispatch(get_history_project());
      dispatch(clearLoading());
      dispatch(clearErrors());
      dispatch(verify_license_user());
      dispatch(verify_license_group());
      return his.push("/dashboard");
    }
  } catch (error) {
    dispatch({
      type: "GET_ERRORS",
      payload: error && error.response ? error.response.data : {},
    });
    dispatch(clearLoading());
  }
};

//2. Get user from token
export const getUserFromToken = (token) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: token,
      },
    };
    const res = await axios.get(SERVER_URL + "/users", config);
    const user = res?.data || {};

    dispatch(setCurrentUser(user));
    localStorage.setItem("mapid_user", JSON.stringify(user));
  } catch (error) {
    dispatch({
      type: "GET_ERRORS",
      payload: error && error?.response ? error?.response?.data : {},
    });
  }
};

//3. Edit user profile
export const updateProfile = (user_data) => (dispatch) => {
  dispatch(setAuthLoading());
  const config_axos = {
    headers: {
      accesstoken: localStorage.token_mapid,
    },
  };
  axios
    .post(SERVER_URL + "/users/profile", user_data, config_axos)
    .then((res) => {
      dispatch({
        type: "UPDATE_USER_PROFILE",
        payload: res.data,
      });
      his.push("/user_profile");
      dispatch(clearLoading());
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//4. upload profile picture
export const uploadPicture = (picture_data) => {
  const config_axos = {
    headers: {
      accesstoken: localStorage.token_mapid,
    },
  };
  axios
    .post(SERVER_URL + "/users/profile", picture_data, config_axos)
    .then((res) => {
      his.push("/user");
    })
    .catch((err) => { });
};

//5. Sub Notification
export const subNotification = (sub_object) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.token_mapid,
    },
  };
  axios
    .post(SERVER_URL + "/users/sub", JSON.stringify(sub_object), config)
    .then((res) => {
      dispatch({
        type: "SUB_NOTIF",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: "GET_ERRORS",
        payload: err && err.response ? err.response.data : {},
      });
    });
};

//Push uploaded_pictures
export const pushUploadedPicture = (item) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("uploaded_picture"));
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const config_header_upload = {
      headers: {
        accesstoken: localStorage.token_mapid,
        "Content-Type": "multipart/form-data",
      },
    };
    const options_compression = {
      maxSizeMB: 1.5,
      maxWidthOrHeight: 1500,
      useWebWorker: true,
    };
    const { objParams } = item;
    //Original file
    const file = objParams.file;
    //Compressed file
    const compressedFile = await imageCompression(file, options_compression);
    const file_name_origin = objParams.file_name_origin;
    const formData = new FormData();
    formData.append("original_file_name", `${file_name_origin}`);
    formData.append("file", compressedFile);
    const responseUpload = await axios.post(
      SERVER_URL + "/upload",
      formData,
      config_header_upload
    );
    const picture_url = responseUpload.data.data.Location;
    const body = { url: picture_url, name: file_name_origin };
    const axios_response = await axios.post(
      SERVER_URL + "/users/push_uploaded_picture",
      body,
      config_header
    );
    dispatch(clearLoading());
    dispatch({
      type: "PUSH_UPLOADED_PICTURE",
      payload: axios_response.data,
    });
    dispatch({
      type: "SELECT_PICTURE",
      payload: picture_url,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

//Upload KTP
export const uploadKTM = (item) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("upload_ktm"));
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const config_header_upload = {
      headers: {
        accesstoken: localStorage.token_mapid,
        "Content-Type": "multipart/form-data",
      },
    };
    const options_compression = {
      maxSizeMB: 1.5,
      maxWidthOrHeight: 1500,
      useWebWorker: true,
    };
    const { objParams } = item;
    //Original file
    const file = objParams.file;
    //Compressed file
    const compressedFile = await imageCompression(file, options_compression);
    const file_name = objParams.file_name;
    // const file_name_origin = objParams.file_name_origin;
    const formData = new FormData();
    formData.append("original_file_name", `${file_name}`);
    formData.append("file", compressedFile);
    const resultBigUpload = await axios.post(
      SERVER_URL + "/upload",
      formData,
      config_header_upload
    );
    const picture_url = resultBigUpload.data.data.Location;
    const body = { ktm: picture_url };
    const axios_response = await axios.post(
      SERVER_URL + "/users/edit_ktm",
      body,
      config_header
    );
    dispatch(clearLoading());
    dispatch({
      type: "UPLOAD_KTM",
      payload: axios_response.data,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

//Edit email
export const editEmail = (body) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("edit_email"));
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(SERVER_URL + "/users/edit_email", body, config_header);
    dispatch(clearLoading());
    dispatch({
      type: "EDIT_EMAIL",
      payload: body,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

//Trigger email confirmation
export const triggerEmailConfirmation = () => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("trigger_email_confirmation)"));
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const body = {
      test: "test",
    };
    const response = await axios.post(
      SERVER_URL + "/users/trigger_email_confirmation",
      body,
      config_header
    );
    dispatch(clearLoading());
    dispatch({
      type: "TRIGGER_EMAIL_CONFIRMATION",
      payload: response.data,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

//Apply as student
export const applyAsStudent = () => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("apply_student"));
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const body = { test: "oke" };
    const response = await axios.post(
      SERVER_URL + "/users/apply_student",
      body,
      config_header
    );
    dispatch(clearLoading());
    dispatch({
      type: "APPLY_STUDENT",
      payload: response.data,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

//Email uuid check
export const emailMatch = (body) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("email_match)"));
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const response = await axios.post(
      SERVER_URL + "/users/email_match",
      body,
      config_header
    );
    dispatch(clearLoading());
    dispatch({
      type: "EMAIL_MATCH",
      payload: response.data,
    });
    his.push("/get_student");
  } catch (e) {
    dispatch(clearLoading());
  }
};

//Get notif list personal
export const getNotificationList = () => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("get_notification_list"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      SERVER_URL + `/notifications/get_by_user_personal`,
      config
    );
    dispatch(clearLoading());
    dispatch({
      type: "GET_NOTIFICATION_LIST",
      payload: res.data,
    });
  } catch (error) {
    dispatch(clearLoading());
  }
};

//Get notif by project
export const getNotifByProject = (project_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      SERVER_URL + `/notifications/get_notif_by_project/${project_id}`,
      config
    );
    dispatch({
      type: "GET_NOTIF_BY_PROJECT",
      payload: res.data,
    });
  } catch (error) {
    dispatch(clearLoading());
  }
};

//Get bank_data
export const getBankData = () => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("getBankData"));
    const config = {
      headers: {
        accesstoken: localStorage?.token_mapid,
      },
    };
    const res = await axios.get(SERVER_URL + `/users/bank_data`, config);
    dispatch(clearLoading());
    dispatch({
      type: "GET_BANK_DATA",
      payload: res.data,
    });
  } catch (error) {
    dispatch(clearLoading());
  }
};

//3. Get current bank_data
export const getCurrentBankData = () => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("current_bank_data"));
    const config = {
      headers: {
        accesstoken: localStorage?.token_mapid,
      },
    };
    const res = await axios.get(
      SERVER_URL + `/users/current_bank_data`,
      config
    );
    dispatch(clearLoading());
    dispatch({
      type: "GET_CURRENT_BANK_DATA",
      payload: res.data,
    });
  } catch (error) {
    dispatch(clearLoading());
  }
};

//12. Add bank data
export const addBank = (body) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("addBank"));
    const config = {
      headers: {
        accesstoken: localStorage?.token_mapid,
      },
    };
    await axios.post(SERVER_URL + `/users/add_bank_data`, body, config);
    dispatch({
      type: "ADD_BANK_DATA",
      payload: body,
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//13. Set bank data
export const setCurrentBankData = (body) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("set_bank_data"));
    const config = {
      headers: {
        accesstoken: localStorage?.token_mapid,
      },
    };
    await axios.post(SERVER_URL + `/users/set_bank_data`, body, config);
    dispatch({
      type: "SET_CURRENT_BANK_DATA",
      payload: body,
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//Get tutorial
export const getTutorialList = () => async (dispatch) => {
  try {
    const res = await axios.get(SERVER_URL + `/users/get_tutorial_list`);
    dispatch({
      type: "GET_TUTORIAL_LIST",
      payload: res.data,
    });
  } catch (error) { }
};

//Get banner
export const getBanner = () => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("get_banner"));
    const res = await axios.get(SERVER_URL + `/users/get_banner`);
    dispatch(clearLoading());
    dispatch({
      type: "GET_BANNER",
      payload: res.data,
    });
  } catch (error) {
    dispatch(clearLoading());
  }
};

export const get_quota_access = () => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("get_quota_access"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      bun_server + "/moneys_bun/get_quota_access",
      config
    );
    dispatch({
      type: "set_value_user",
      payload: { key: "quota_access", value: res.data },
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

export const get_quota_access_sini_ai = () => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("get_quota_access_sini_ai"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      bun_server + "/moneys_bun/get_quota_access_sini_ai",
      config
    );
    dispatch({
      type: "set_value_user",
      payload: { key: "quota_access_sini_ai", value: res.data },
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

export const get_quota_ai_chat = () => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("get_quota_ai_chat"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      bun_server + "/moneys_bun/get_quota_ai_chat",
      config
    );
    dispatch({
      type: "set_value_user",
      payload: { key: "quota_ai_chat", value: res.data },
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

export const set_modal_quota_access = (body) => async (dispatch) => {
  try {
    dispatch({
      type: "set_modal_quota_access",
      payload: body,
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};
