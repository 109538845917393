import React from "react";
import LandingOTP from "../../Pages/auth/LandingOTP";

const ModalEmailConfirm = ({ isOpen, onClose }) => {
  // let background;
  // // let logo;
  // if (
  //   domain === "bbwsciliwungcisadane.mapid.io" ||
  //   domain === "kabalai.mapid.io" ||
  //   mode_balai === "cilicis"
  // ) {
  //   background = "";
  //   // logo = logoCilicis;
  // } else if (
  //   domain === "bbwsbrantas.mapid.io" ||
  //   mode_balai === "brantas" ||
  //   !isNaN(parseInt(domain))
  // ) {
  //   background = "";
  //   // logo = logoBrantas;
  // } else if (domain === "bwsmalukuutara.mapid.io") {
  //   background = "";
  //   // logo = logoBrantas;
  // } else if (
  //   domain === "ditjenikma.mapid.io" ||
  //   domain === "ditjenikma-kemenperin.mapid.io"
  // ) {
  //   background = "";
  // } else if (domain === "mrt.mapid.io") {
  //   background = "";
  //   // logo = logoMrt;
  // } else if (domain === "hud.mapid.io") {
  //   background = "";
  //   // logo = logoHud;
  // } else if (domain === "danamas.mapid.io") {
  //   background = "";
  //   // logo = logo_danamas;
  // } else if (domain === "desaku.mapid.io") {
  //   background = "";
  //   // logo = logo_kemendagri;
  // } else if (
  //   domain === "ditjenikma.mapid.io" ||
  //   domain === "ditjenikma-kemenperin.mapid.io"
  // ) {
  //   background = "";
  //   // logo = logoKemenperin;
  // } else {
  //   background = background_mapid;
  //   // logo = logoMapid;
  // }

  return <>{isOpen ? <LandingOTP onClose={onClose} /> : null}</>;
};

export default ModalEmailConfirm;
