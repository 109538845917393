import React, { Component } from "react";
import { connect } from "react-redux";
import DeleteIconFill from "../common_icon/DeleteIconFill";
import { delete_child_cell } from "../../App/actions/crudNestedTable";

class NumberAndAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActionButtonShown: false,
      modal_delete_confirmation: false,
    };
  }

  toggleActionButtonShown = (value) => {
    this.setState({ isActionButtonShown: value });
  };

  toggle_modal_delete_confirmation = () => {
    const { modal_delete_confirmation } = this.state;
    this.setState({
      modal_delete_confirmation: !modal_delete_confirmation,
    });
  };

  handle_delete_row = async () => {
    const { geo_layer_id, feature_key, child_uuid, component_id } = this.props;

    const body = {
      geo_layer_id,
      feature_key,
      child_uuid,
    };

    await this.props.delete_child_cell(body, component_id);
  };

  render() {
    return (
      <section
        style={{
          width: "100%",
          height: "100%",
          // backgroundColor: this.props.backgroundColor,
        }}
        onMouseEnter={() => {
          this.toggleActionButtonShown(true);
        }}
        onMouseLeave={() => {
          this.toggleActionButtonShown(false);
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            height: "100%",
            alignItems: "center",
          }}
        >
          {this.state.isActionButtonShown && (
            <div
              style={{
                display: "flex",
              }}
            >
              <button
                onClick={(e) => {
                  this.handle_delete_row();
                }}
                style={{
                  fill: "#ca0b00",
                  marginRight: 5,
                }}
              >
                <DeleteIconFill width={30} height={30} />
              </button>
            </div>
          )}
          {!this.state.isActionButtonShown && (
            <div>{this.props.params.id + 1}.</div>
          )}
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
});

export default connect(mapStateToProps, {
  delete_child_cell,
})(NumberAndAction);
