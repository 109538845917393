//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component
import NavigationLanding from "../../Components/layout/NavigationLanding";
import LandingAuth from "../../Components/layout/LandingAuth";

//Redux function
import { openModal, setLanguage } from "../../App/actions/authActions";

//General Function
import domain_list from "../../Data/domain_list";
import WhatsappConfirm from "../../Components/layout/WhatsappConfirm";

class LandingOTP extends Component {
  state = {
    width: window.innerWidth,
  };

  componentDidMount() {}

  onSetLanguage = (language) => {
    this.props.setLanguage(language);
  };

  render() {
    const domain = this.props.auth.domain;
    const { onClose } = this.props;

    return (
      <>
        <LandingAuth
          nav={<NavigationLanding />}
          title={domain_list?.[domain]?.title}
          subtitle={domain_list?.[domain]?.sub_title}
          content={<WhatsappConfirm onClose={onClose} />}
          from="otp"
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { openModal, setLanguage })(LandingOTP);
