import uuid from "./uuid";
import { color_setup, geometry_setup } from "./geojson_properties";
import is_uuid from "./is_uuid";
import fields_default_props from "../../Data/fields_defaut.json"
import is_not_empty from "./is_not_empty";

/**
 * Keterangan:
 * 
 * Function ini digunakan untuk membuat field baru.
 */
export const generateFields = (columns, type = "Point") => {

  //GENERATE FIELDS
  let fields_ori = columns;

  fields_ori = fields_ori?.map((key) => {
    let isHide = false;
    if (key === "color") {
      isHide = true;
    }
    const new_uuid = uuid();
    return {
      uuid: new_uuid,
      key: key,
      name: key,
      type: "text",
      array_templates: [],
      isHighlight: false,
      date: Date.now(),
      isHide,
    };
  });

  const fields_default = select_field_from_default_props(
    type
  );
  let fields = fields_default.concat(fields_ori);

  fields = fields?.filter(
    (field, index) =>
      fields.findIndex((field_element) => field_element?.key === field?.key) ===
      index
  );

  return fields;
};

export const generateFieldsUseDataType = (columns, type = "Point") => {

  //GENERATE FIELDS
  let fields_ori = structuredClone(columns);

  fields_ori = columns?.map((item) => {
    let isHide = false;
    if (item?.fields === "color") {
      isHide = true;
    }
    const new_uuid = uuid();
    return {
      uuid: new_uuid,
      key: item?.fields,
      name: item?.fields,
      type: item?.dataType,
      array_templates: [],
      isHighlight: false,
      date: Date.now(),
      isHide,
    };
  });

  const fields_default = select_field_from_default_props(
    type
  );
  let fields = fields_default.concat(fields_ori);

  fields = fields?.filter(
    (field, index) =>
      fields.findIndex((field_element) => field_element?.key === field?.key) ===
      index
  );

  return fields;
};

/**
 * Keterangan:
 * 
 * Function ini akan mereturn properties (standard MAPID aja) yang harus ada pada setiap geometry.
 */
export const select_field_from_default_props = (type) => {
  if (type === "Point" || type === "MultiPoint") {
    return fields_default_props.Point;
  } else if (type === "LineString" || type === "MultiLineString") {
    return fields_default_props.LineString;
  } else {
    return fields_default_props.Polygon;
  }
};

/** 
 *  Keterangan:
 * 
 *  Function ini mengubah features dari file untuk diubah ke geojson. Apabila file berasal dari csv atau xlsx, maka function ini akan menyesuikan geometry latitude dan longitude yang ada pada file tersebut.
 *  
*/
const generateGeojson = ({
  fileName,
  file,
  colorStatus,
  user_id,
  geo_project_id,
  fields,
  // fields_default,
  folder,
  latitude,
  longitude,
}) => {
  //   GENERATE GEOJSON
  let geojson = {};
  let geometry_type;

  const lat_key = fields?.find((field) => field?.name === latitude)?.uuid;
  const long_key = fields?.find((field) => field?.name === longitude)?.uuid;
  const features = file?.map((feature, idx) => {
    if (feature?.properties) {
      Object.keys(feature?.properties).forEach((key) => {
        const field = fields?.find((f) => f?.key === key) || undefined;
        if (field) {
          if (is_not_empty(feature?.["properties"]?.[key])) {
            if (field.type === "number") {
              feature["properties"][field?.uuid] = Number(feature?.["properties"]?.[key]) || 0;
            } else {
              feature["properties"][field?.uuid] = feature?.["properties"]?.[key]
            }
            delete feature["properties"][key];
          } else {
            if (field.type === "number") {
              feature["properties"][field?.uuid] = 0;
            } else {
              feature["properties"][field?.uuid] = "";
            }
          }
        }
      });
    } else {
      Object.keys(feature).forEach((key) => {
        const field = fields?.find((f) => f?.key === key) || undefined;
        if (field) {
          feature[field?.uuid] = feature?.[key];
          delete feature[key];
        }
      });
    }

    geometry_type = feature?.geometry?.type || "Point";

    const prop_default = color_setup({
      feature,
      geometry_type,
      colorStatus,
      idx,
    });

    const prop_ori = feature?.properties || feature;
    const properties = Object.assign(prop_default, prop_ori);

    const coordinates = geometry_setup({
      feature,
      geometry_type,
      lat_key,
      long_key,
    });

    const final = {
      key: uuid(),
      type: "Feature",
      properties,
      geometry: {
        type: geometry_type,
        coordinates: coordinates,
      },
      formStatus: {
        status: "upload",
        message: "",
        revision_list: [],
      },
      isHide: false,
      user: user_id,
      idx
    };

    return final;
  });

  const properties_style = select_field_from_default_props(
    geometry_type
  );
  fields = uuid_to_key(fields);

  geojson.features = features;
  geojson.type = "FeatureCollection";
  const body = {
    //basic layer
    name: fileName,
    description: fileName,
    user_id,
    geo_project_id,
    folder: folder,
    //di dalam geojson
    type: geometry_type,
    fields,
    // properties: fields_default,
    properties: properties_style,
    geojson,
  };
  return body;
};

/**
 * Keterangan:
 * 
 * Function ini bakal buatin loee bikin fields & geojson
 *  
 * */
export const generate_geo_layer = (
  { columns, is_use_data_type, type = "Point" },
  {
    fileName,
    file,
    colorStatus = "#1a8bc0",
    user_id,
    geo_project_id,
    folder,
    latitude,
    longitude,
  }
) => {
  let fields = [];
  if (is_use_data_type) {
    fields = generateFieldsUseDataType(columns, type);
  } else {
    fields = generateFields(columns, type);
  }
  const geojson = generateGeojson({
    fileName,
    file,
    colorStatus,
    user_id,
    geo_project_id,
    fields,
    folder,
    latitude,
    longitude,
  });

  return geojson;
};

const uuid_to_key = (fields) => {
  return fields?.map((field) => {
    const { key, ...rest } = field || {};
    if (rest?.uuid) {
      return {
        ...rest,
        key: rest?.uuid,
      };
    }
    return field;
  });
};

// key tetap original (tidak menggunakan uuid)
export const standardize_geojson_features = ({
  file,
  colorStatus,
  latitude,
  longitude,
  user_id,
  status = "geojson",
}) => {
  let geometry_type;
  const features = [];
  if (Array.isArray(file)) {
    for (const [idx, feature] of file?.entries()) {
      geometry_type = feature?.geometry?.type || "Point";

      const prop_default = color_setup({
        feature,
        geometry_type,
        colorStatus,
        idx,
      });

      const prop_ori = feature?.properties || feature;
      const properties = Object.assign(prop_default, prop_ori);

      const coordinates = geometry_setup({
        feature,
        geometry_type,
        lat_key: latitude,
        long_key: longitude,
      });

      features.push({
        key: uuid(),
        type: "Feature",
        properties: properties,
        geometry: {
          type: geometry_type,
          coordinates: coordinates,
        },
        formStatus: {
          status: "upload",
          message: "",
          revision_list: [],
        },
        isHide: false,
        user: user_id,
      });
    }
  }

  if (status === "raw") {
    let geojson = {};
    geojson.features = features;
    geojson.type = "FeatureCollection";
    return geojson;
  }
  return features;
};

//  fields (list of string) to fields (standard format)
export const standardize_fields_key_as_uuid = (array_of_string) => {
  return array_of_string.map((field_name) =>
    standardize_field_key_as_uuid(field_name)
  );
};

export const standardize_field_key_as_uuid = (field_name) => {
  const uuid_value = uuid();
  return {
    ...get_default_field_properties(),
    uuid: uuid_value,
    key: uuid_value,
    name: field_name || `untitled_${Date.now()}`,
  };
};

export const standardize_fields_key_as_name = (array_of_string) => {
  return array_of_string.map((field_name) =>
    standardize_field_key_as_name(field_name)
  );
};

export const standardize_field_key_as_name = (field_name) => {
  const uuid_value = uuid();
  return {
    ...get_default_field_properties(),
    uuid: uuid_value,
    key: field_name,
    name: field_name,
  };
};

const get_default_field_properties = () => {
  return {
    description: "",
    type: "text",
    array_templates: [],
    isHighlight: false,
    isRequired: true,
    isAllowOtherAnswer: true,
    date: Date.now(),
    isHide: false,
    tag: "",
    min: 0,
    max: 10,
    step: 1,
    poi_type: "", //FASILITAS PENDIDIKAN || TEMPAT IBADAH
    poi_rad: 1, //radius counting
    unit: "",
    reference_list: [],
    reference_key: "",
    formula: [],
    reference_list_2d: {
      row_name: "",
      column_name: "",
      headers: [],
      features: [],
    },
    conditional_statement_list: [],
  };
};

export const match_the_fields_and_features = (fields, features) => {
  let result = [];

  for (let feature of features) {
    for (let key in feature?.properties) {
      const field = fields.find(
        (f) => f.upload_field_name === key || f.name === key
      );
      if (field) {
        if (field?.current_field_key) {
          feature["properties"][field.current_field_key] =
            feature?.["properties"]?.[key];
        } else {
          feature["properties"][field.key] = feature?.["properties"]?.[key];
        }

        /*  hapus key original supaya tidak duplikasi dengan key uuid
              contoh properties:
              {
                "1ac5c5c2-621e-4100-a38b-27e14ee81091": "Yanto"  |
                "Nama": "Yanto"                                  |    <= hapus yg ini
              }
              */
        if (is_uuid(field?.current_field_key) || is_uuid(field?.key)) {
          delete feature["properties"][key];
        }
      }
    }
    result.push(feature);
  }
  return result;
};
