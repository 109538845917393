/*LIBRARY*/
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FormControlLabel, Radio, RadioGroup, Checkbox } from "@mui/material";

/*COMPONENT*/

/*REDUX*/
import { get_all_exist_projects } from "../../App/actions/recoveryProjectActions";

/*PICTURE*/

/*FUNCTION*/
import useLanguage from "../common_hooks/useLanguage";

/*DATA*/

/*CONST*/

function RestoreLayerPage1({
  width,
  selected,
  push_selected,
  set_restore_page,
  restore_selected_project,
  set_restore_selected_project,
  recently_created_projects,
}) {
  const { dict, language } = useLanguage();
  const width_box = width < 900 ? "w_full h_200" : "w_300 h_300";
  const width_box_2 = width < 900 ? "w_full h_125" : "w_300 h_225";
  const width_box_small = width < 900 ? "w_full" : "w_100";
  const title_font_size = width < 900 ? "font_16" : "font_30";

  const [projects, set_projects] = useState([]);
  const [created_projects, set_created_projects] = useState(
    recently_created_projects
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(get_all_exist_projects()).then((result) => {
      set_projects(result);
    });
    set_created_projects(recently_created_projects);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handle_radio_button = (e) => {
    set_restore_selected_project(projects?.[e?.target?.value]);
  };

  return (
    <div className="w_full center_perfect padding_10">
      <div className="w_full">
        <label className={`${title_font_size} bold`}>
          {dict["Recover Layers"][language]}
        </label>
        <section
          className={`flex gap_20 ${width < 900 ? "flex_col" : "flex_row"}`}
        >
          <div
            className={`border rounded_5 ${width_box} padding_5 scroll_auto`}
          >
            <label className="underline bold">
              {dict["Selected Layers"][language]}
            </label>
            <section className="marginTop_10">
              {selected?.map((item, idx) => (
                <div key={idx}>
                  <Checkbox
                    onClick={() => {
                      push_selected(item);
                    }}
                    checked={true}
                    style={{ padding: 0 }}
                  />
                  <label className="truncate">{item?.name}</label>
                </div>
              ))}
            </section>
          </div>
          <div className={`border rounded_5 ${width_box} padding_5`}>
            <label className="underline bold">
              {dict["Save to Project"][language]}
            </label>
            <section
              className={`marginTop_10 scroll_y_auto ${width_box_2}`}
              style={{ overflowX: "hidden" }}
            >
              <RadioGroup
                aria-labelledby="project-radio-buttons-group-label"
                value={
                  projects?.findIndex(
                    (item) => restore_selected_project?._id === item?._id
                  ) || 0
                }
                onChange={handle_radio_button}
              >
                {projects?.map((item, idx) => (
                  <div key={idx}>
                    <FormControlLabel
                      value={idx}
                      control={
                        <Radio style={{ paddingTop: 0, paddingBottom: 0 }} />
                      }
                      label={
                        <div className="w_full">
                          <label className="truncate w_auto">
                            {item?.name}
                          </label>
                          {created_projects?.includes(item?._id) && (
                            <label className="marginLeft_5 w_100 bg_blue rounded_5 padding_x_5 text_white">
                              !Baru
                            </label>
                          )}
                        </div>
                      }
                    />
                  </div>
                ))}
              </RadioGroup>
            </section>
            <section>
              <label className="paddingLeft_10 paddingRight_10">
                {dict["Or"][language]}
              </label>
              <button
                onClick={() => set_restore_page(3)}
                className="button_block background_blue"
              >
                {dict["Create Project"][language]}
              </button>
            </section>
          </div>
          <div className={`rounded_5 ${width_box_small} flex flex_col gap_10`}>
            <button
              onClick={() => set_restore_page(2)}
              className="bg_green button_inactive padding_y_5 padding_x_10 rounded_5 text_white"
            >
              {dict["Recover"][language]}
            </button>
            <button
              onClick={() => set_restore_page(1)}
              className="bg_darkGrey button_inactive padding_y_5 padding_x_10 rounded_5 text_white"
            >
              {dict["Cancel"][language]}
            </button>
          </div>
        </section>
      </div>
    </div>
  );
}

export default RestoreLayerPage1;
