//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux function
import { editFieldForm } from "../../App/actions/layerActions";

//Picture Asset

//General Function
import dict from "../../Data/dict.json";
import isEmpty from "../../App/validation/is-empty";

class EditTerm extends Component {
  state = {
    field: {},
    field_inside_term: {},
    fields: [],
  };

  componentDidMount() {
    this.setState({ field: this.props.field, fields: this.props.fields });
  }

  handle_add_term = () => {
    let { field_inside_term } = this.state;
    const term_array = field_inside_term.term_array;
    let field = this.state.field;
    let uuid = field_inside_term.uuid;
    let term_array_origin = field?.term ? field.term : [];
    const term = {
      uuid,
      value: term_array,
    };
    term_array_origin.push(term);
    field.term = term_array_origin;
    field.term_mode = "and";
    const body = {
      geo_layer_id: this.props.geo_layer_id,
      field,
    };
    this.props.editFieldForm(body);
  };

  handle_change = (e) => {
    e.preventDefault();
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    // name, description, type, min, max, step
    if (["name", "description", "type", "min", "max", "step"].includes(name)) {
      const placeholder = target.placeholder;
      let geo_layer = this.state.geo_layer;
      let fields = this.state.geo_layer.geo_layer.fields;
      fields[placeholder][name] = value;
      geo_layer.geo_layer.fields = fields;
      this.setState({
        geo_layer,
      });
    } else if (["uuid_inside_term"].includes(name)) {
      let fields = this.state.fields;
      const field_inside_term = value
        ? fields.filter((e) => e.uuid === value)[0]
        : fields[0];
      this.setState({ field_inside_term });
    } else if (["term_mode"].includes(name)) {
      let { field } = this.state;
      field.term_mode = value;
      const body = {
        geo_layer_id: this.props.geo_layer_id,
        field,
      };
      this.props.editFieldForm(body);
    }
  };

  handle_delete_term = (idx) => {
    let { field } = this.state;
    let term_array_origin = field?.term ? field.term : [];
    term_array_origin.splice(idx, 1);
    field.term = term_array_origin;
    const body = {
      geo_layer_id: this.props.geo_layer_id,
      field,
    };
    this.props.editFieldForm(body);
  };

  on_remove_term = (name) => {
    let { field_inside_term } = this.state;
    let term_array = field_inside_term.term_array
      ? field_inside_term.term_array
      : [];
    term_array = term_array.filter((v) => v !== name);
    field_inside_term.term_array = term_array;
    this.setState({ field_inside_term });
  };

  on_click_term = (name) => {
    let { field_inside_term } = this.state;
    let term_array = field_inside_term.term_array
      ? field_inside_term.term_array
      : [];
    term_array.push(name);
    field_inside_term.term_array = term_array;
    this.setState({ field_inside_term });
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";

    const { field, fields } = this.state;

    const term_mode = field?.term_mode ? field.term_mode : "and";
    const term = field?.term ? field.term : [];

    const fields_temp = fields.filter((e) => e.uuid !== field.uuid);
    const field_inside_term = !isEmpty(this?.state?.field_inside_term)
      ? this?.state?.field_inside_term
      : fields_temp[0];
    let array_templates_term = !isEmpty(this?.state?.field_inside_term)
      ? this?.state?.field_inside_term?.array_templates
      : [];
    array_templates_term = array_templates_term.map((e) => e.name);
    const term_array = !isEmpty(this?.state?.field_inside_term?.term_array)
      ? this?.state?.field_inside_term?.term_array
      : [];

    let content = null;

    const mode_array = [
      {
        name: dict["And"][language],
        value: "and",
      },
      {
        name: dict["Or"][language],
        value: "or",
      },
    ];

    content = (
      <>
        <section className="text_bold">
          {dict["Term"][language]} : {field.name}
        </section>
        <section className="text_inferior" style={{ marginBottom: "20px" }}>
          {
            dict[
              "Add conditions for this question to appear. If the conditions below are not met then the question will be hidden, use this to construct dynamic chained questions."
            ][language]
          }
        </section>
        <section>
          Mode:
          <select
            name="term_mode"
            id="term_mode"
            value={term_mode}
            onChange={this.handle_change}
            style={{ maxWidth: "100px", display: "inline-block" }}
          >
            {mode_array.map((mode, idx) => {
              return (
                <option value={mode.value} key={idx}>
                  {mode.name}
                </option>
              );
            })}
          </select>
        </section>
        <section style={{ marginBottom: "20px" }}>
          {term.length === 0 ? (
            <p>{dict["There are no conditions created yet"][language]}</p>
          ) : (
            <table style={{ width: "100%" }} className="table_lite">
              <thead>
                <tr>
                  <th>{dict["Question"][language]}</th>
                  <th>{dict["Answer"][language]}</th>
                </tr>
              </thead>
              <tbody>
                {term.map((t, idx) => {
                  const field = fields.filter((f) => f.uuid === t.uuid)[0];
                  return (
                    <tr key={idx}>
                      <td>
                        {field?.name
                          ? field.name
                          : `(${dict["Question has been deleted"][language]})`}
                        <br />
                        <button
                          onClick={this.handle_delete_term.bind(this, idx)}
                          className="button_very_small"
                          id="deleteBright"
                        >
                          {dict["Delete"][language]}
                        </button>
                      </td>
                      <td>{t.value}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </section>
        <section className="two_container_equal">
          <div>
            <select
              name="uuid_inside_term"
              id="uuid_inside_term"
              value={
                field_inside_term?.uuid
                  ? field_inside_term.uuid
                  : fields[0]?.uuid
              }
              onChange={this.handle_change}
            >
              {fields_temp.map((e, idx) => {
                return (
                  <option value={e.uuid} key={idx}>
                    {e.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div>
            {array_templates_term.length === 0 ? (
              <div>
                {
                  dict[
                    "There is no template for this question, first add template in edit menu"
                  ][language]
                }
              </div>
            ) : (
              array_templates_term.map((name, idx_c) => {
                return (
                  <main key={idx_c} style={{ marginBottom: "5px" }}>
                    {term_array.includes(name) ? (
                      <section
                        className="multi_check"
                        id="active_strong"
                        onClick={this.on_remove_term.bind(this, name)}
                      >
                        ✓
                      </section>
                    ) : (
                      <section
                        className="multi_check"
                        id="not_active"
                        onClick={this.on_click_term.bind(this, name)}
                      />
                    )}
                    <section htmlFor={name} style={{ display: "inline-block" }}>
                      {name}
                    </section>
                  </main>
                );
              })
            )}
          </div>
        </section>
        <section
          className="button background_blue"
          onClick={this.handle_add_term}
        >
          {dict["Add condition"][language]}
        </section>
      </>
    );
    return content;
  }
}
const mapStateToProps = (state) => ({
  geo_layer_id: state?.layer?.geo_layer_wo_geojson?.geo_layer?._id,
});
export default connect(mapStateToProps, {
  editFieldForm,
})(EditTerm);
