//General Function
import dict from "../../Data/dict.json";

const get_objectives = (language) => {
  return [
    {
      "value": "Create and Share Interactive Maps",
      "title": dict[
        "Create and Share Interactive Maps"
      ][language
      ]
    },
    {
      "value": "Develop Spatial Applications",
      "title": dict[
        "Develop Spatial Applications"
      ][language
      ]
    },
    {
      "value": "Create and Run Data Processing and Analytical Workflows",
      "title": dict[
        "Create and Run Data Processing and Analytical Workflows"
      ][language
      ]
    },
    {
      "value": "Solve Spatial Data Science Use Cases",
      "title": dict[
        "Solve Spatial Data Science Use Cases"
      ][language
      ]
    },
    {
      "value": "Access Third-party Data",
      "title": dict[
        "Access Third-party Data"
      ][language
      ]
    },
    {
      "value": "Education and Training",
      "title": dict[
        "Education and Training"
      ][language
      ]
    },
    {
      "value": "Strategic Partnerships",
      "title": dict[
        "Strategic Partnerships"
      ][language
      ]
    },
    {
      "value": "Other",
      "title": dict[
        "Other"
      ][language
      ]
    }
  ]
}

export default get_objectives;