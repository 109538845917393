/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import ChartTemplate from "../viewer_chart/ChartTemplate";
import Modal from "../common_modal/Modal";
import PROYEKSI_INFO from "./PROYEKSI_INFO";
import CAPEX_INFO from "./CAPEX_INFO";
import RUANG_INFO from "./RUANG_INFO";

/*REDUX FUNCTION*/
import { set_value_properties } from "../../App/actions/propertiesActions";

/*PICTURE ASSET*/

/*GENERAL*/

/*NON IMPORT*/
const width_small = 55;
const retail = 2605.4833838805; //2,605.4833838805

function count_average_ratio(array) {
  let sum = 0;
  for (let i = 0; i < array.length - 1; i++) {
    sum += array[i] / array[i + 1];
  }
  return sum / (array.length - 1);
}

class TimeseriesResume extends Component {
  state = {
    chart_width: 90,
    modal_info: false,
    info_content: "", //proyeksi || ruang || capex
  };

  componentDidUpdate(prevProps) {
    if (this.props.map.status_action !== prevProps.map.status_action) {
      this.generate_capex();
    }
  }

  toggle_info = (info_content) => {
    this.setState({ modal_info: !this.state.modal_info });
    if (info_content) {
      this.setState({ info_content });
    }
  };

  generate_capex = () => {
    const { folder_kai_timeseries } = this.props.properties;
    let variable_object = {
      avg_passenger_month: 0,
      avg_passenger_day: 0,
      avg_ratio_max_passenger: 0,
    };

    const year_array = folder_kai_timeseries?.year_array || [];
    const max_penumpang_array =
      folder_kai_timeseries?.max_penumpang_array || [];
    const max_passenger_array =
      folder_kai_timeseries?.max_passenger_array || [];
    const max_train_array = folder_kai_timeseries?.max_train_array || [];

    let content_array = [
      {
        name: "Total potensi penumpukan penumpang per tahun",
        entity: "penumpang",
        calc_avg: true,
        calc_avg_month: true,
        calc_avg_day: true,
        calc_ratio: false,
        month_variable_name: "avg_passenger_month",
        day_variable_name: "avg_passenger_day",
        chart_js_data: {
          labels: year_array,
          datasets: [
            {
              label: "Total potensi penumpukan penumpang per tahun",
              labels: year_array,
              data: max_passenger_array,
              backgroundColor: "#373784",
            },
          ],
        },
      },
      {
        name: "Potensi Jadwal KA yang berdekatan",
        entity: "kereta",
        calc_avg: true,
        calc_avg_month: true,
        calc_avg_day: false,
        calc_ratio: false,
        month_variable_name: "avg_train_month",
        day_variable_name: "avg_train_day",
        chart_js_data: {
          labels: year_array,
          datasets: [
            {
              label: "Potensi Jadwal KA yang berdekatan",
              labels: year_array,
              data: max_train_array,
              backgroundColor: "#373784",
            },
          ],
        },
      },
      {
        name: "Total Penumpang Maksimal per Tahun",
        calc_avg: false,
        calc_ratio: true,
        avg_ratio_variable_name: "avg_ratio_max_passenger",
        chart_js_data: {
          labels: year_array,
          datasets: [
            {
              label: "Total Penumpang Maksimal per Tahun",
              labels: year_array,
              data: max_penumpang_array,
              backgroundColor: "#373784",
            },
          ],
        },
      },
    ];

    content_array.forEach((item) => {
      if (item.calc_avg) {
        const array = item?.chart_js_data?.datasets?.[0]?.data || [];
        const value_sum_number = array.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        let average_month = 0;
        let average_day = 0;
        if (array.length > 0) {
          average_month = (value_sum_number / array.length).toFixed(2);
          average_day = (average_month / 30).toFixed(2);
          variable_object[item.month_variable_name] = average_month;
          variable_object[item.day_variable_name] = average_day;
        }
        item.value_sum_number = value_sum_number;
        item.average_month = average_month;
        item.average_day = average_day;
      }
      if (item.calc_ratio) {
        const array = item?.chart_js_data?.datasets?.[0]?.data || [];
        const average_ratio = count_average_ratio(array).toFixed(2);
        const average_ratio_percent = (average_ratio * 100).toFixed(2);
        variable_object[item.avg_ratio_variable_name] = average_ratio;
        item.average_ratio = average_ratio;
        item.average_ratio_percent = average_ratio_percent;
      }
    });

    //proses formulasi nilai projection hanya dilakukan setelah variabel lengkap terlebih dahulu
    if (
      year_array?.length > 0 &&
      variable_object?.avg_passenger_month &&
      variable_object?.avg_passenger_day &&
      variable_object?.avg_ratio_max_passenger
    ) {
      const { avg_passenger_day, avg_ratio_max_passenger } = variable_object;

      const p_5_day = (
        avg_passenger_day * Math.pow(avg_ratio_max_passenger, 5)
      ).toFixed(0);

      const waiting_room = p_5_day * 0.8;
      const toilet_room = (waiting_room * 4) / 100;
      const prayer_room = (waiting_room * 5) / 100;
      const office_room = 196;
      const circulation =
        ((waiting_room + toilet_room + prayer_room + office_room) * 30) / 100;
      const retail = 2605.4833838805; //2,605.4833838805
      const building_area =
        waiting_room +
        toilet_room +
        prayer_room +
        office_room +
        circulation +
        retail;

      const capex_value = parseInt(retail * 9_500_000);

      this.props.set_value_properties({
        key: "capex_value",
        value: capex_value,
      });

      this.props.set_value_properties({
        key: "building_area",
        value: building_area,
      });
    }
  };

  on_set_chart_width = (chart_width) => {
    this.setState({ chart_width });
  };

  on_close_resume = () => {
    this.props.set_value_properties({
      key: "folder_kai_timeseries",
      value: {},
    });
  };

  render() {
    //local storage

    //local state
    const { chart_width, modal_info, info_content } = this.state;

    //global props
    const { geo_layer_list } = this.props.layer;
    const { folder_kai_timeseries } = this.props.properties;

    //content
    let variable_object = {
      avg_passenger_month: 0,
      avg_passenger_day: 0,
      avg_ratio_max_passenger: 0,
    };

    let p_5_day;
    let p_5_month;
    let p_10_month;
    let p_10_day;
    let building_area;
    let capex_value;
    let room_list = [];

    const layer_list = geo_layer_list.filter(
      (element) =>
        element?.folder === folder_kai_timeseries?._id &&
        element?.geo_layer?.timeseries_object?.timeseries_mode === "kai"
    );

    const year_array = folder_kai_timeseries?.year_array || [];

    const max_penumpang_array =
      folder_kai_timeseries?.max_penumpang_array || [];
    const max_passenger_array =
      folder_kai_timeseries?.max_passenger_array || [];
    const max_train_array = folder_kai_timeseries?.max_train_array || [];

    let content_array = [
      {
        name: "Total potensi penumpukan penumpang per tahun",
        entity: "penumpang",
        calc_avg: true,
        calc_avg_month: true,
        calc_avg_day: true,
        calc_ratio: false,
        month_variable_name: "avg_passenger_month",
        day_variable_name: "avg_passenger_day",
        chart_js_data: {
          labels: year_array,
          datasets: [
            {
              label: "Total potensi penumpukan penumpang per tahun",
              labels: year_array,
              data: max_passenger_array,
              backgroundColor: "#373784",
            },
          ],
        },
      },
      {
        name: "Potensi Jadwal KA yang berdekatan",
        entity: "kereta",
        calc_avg: true,
        calc_avg_month: true,
        calc_avg_day: false,
        calc_ratio: false,
        month_variable_name: "avg_train_month",
        day_variable_name: "avg_train_day",
        chart_js_data: {
          labels: year_array,
          datasets: [
            {
              label: "Potensi Jadwal KA yang berdekatan",
              labels: year_array,
              data: max_train_array,
              backgroundColor: "#373784",
            },
          ],
        },
      },
      {
        name: "Total Penumpang Maksimal per Tahun",
        calc_avg: false,
        calc_ratio: true,
        avg_ratio_variable_name: "avg_ratio_max_passenger",
        chart_js_data: {
          labels: year_array,
          datasets: [
            {
              label: "Total Penumpang Maksimal per Tahun",
              labels: year_array,
              data: max_penumpang_array,
              backgroundColor: "#373784",
            },
          ],
        },
      },
    ];

    content_array = content_array.map((item) => {
      if (item.calc_avg) {
        const array = item?.chart_js_data?.datasets?.[0]?.data || [];
        const value_sum_number = array.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        let average_month = 0;
        let average_day = 0;
        if (array.length > 0) {
          average_month = (value_sum_number / array.length).toFixed(2);
          average_day = (average_month / 30).toFixed(2);
          variable_object[item.month_variable_name] = average_month;
          variable_object[item.day_variable_name] = average_day;
        }
        item.value_sum_number = value_sum_number;
        item.average_month = average_month;
        item.average_day = average_day;
      }
      if (item.calc_ratio) {
        const array = item?.chart_js_data?.datasets?.[0]?.data || [];
        const average_ratio = count_average_ratio(array).toFixed(2);
        const average_ratio_percent = (average_ratio * 100).toFixed(2);
        variable_object[item.avg_ratio_variable_name] = average_ratio;

        item.average_ratio = average_ratio;
        item.average_ratio_percent = average_ratio_percent;
      }
      return item;
    });

    const width_chart = chart_width * year_array?.length; //width_small.5 pas layer, 100 lebih panjang

    let projection_content;

    //proses formulasi nilai projection hanya dilakukan setelah variabel lengkap terlebih dahulu
    if (
      year_array?.length > 0 &&
      variable_object?.avg_passenger_month &&
      variable_object?.avg_passenger_day &&
      variable_object?.avg_ratio_max_passenger
    ) {
      const {
        avg_passenger_month,
        avg_passenger_day,
        avg_ratio_max_passenger,
      } = variable_object;
      p_5_month = (
        avg_passenger_month * Math.pow(avg_ratio_max_passenger, 5)
      ).toFixed(0);
      p_5_day = (
        avg_passenger_day * Math.pow(avg_ratio_max_passenger, 5)
      ).toFixed(0);

      p_10_month = (
        avg_passenger_month * Math.pow(avg_ratio_max_passenger, 10)
      ).toFixed(0);
      p_10_day = (
        avg_passenger_day * Math.pow(avg_ratio_max_passenger, 10)
      ).toFixed(0);

      const waiting_room = p_5_day * 0.8;
      const toilet_room = (waiting_room * 4) / 100;
      const prayer_room = (waiting_room * 5) / 100;
      const office_room = 196;
      const circulation =
        ((waiting_room + toilet_room + prayer_room + office_room) * 30) / 100;

      building_area =
        waiting_room +
        toilet_room +
        prayer_room +
        office_room +
        circulation +
        retail;
      const parking_lot = (building_area * 25) / 100;
      capex_value = retail * 9_500_000;

      room_list = [
        {
          name: "PROYEKSI JUMLAH PENUMPANG LIMA TAHUN PER HARI",
          formula: "p_5_day",
          value: p_5_day,
        },
        {
          name: "RUANG TUNGGU",
          formula: "p_5_day * 0,8",
          value: waiting_room,
        },
        {
          name: "TOILET",
          formula: "RUANG TUNGGU * 4%",
          value: toilet_room,
        },
        {
          name: "MUSHOLA",
          formula: "RUANG TUNGGU * 5%",
          value: prayer_room,
        },
        {
          name: "RUANG KANTOR",
          formula: "196 m^2 (konstan)",
          value: office_room,
        },
        {
          name: "SIRKULASI",
          formula: "(RUANG TUNGGU + TOILET + MUSHOLA + KANTOR) * 30%",
          value: circulation,
        },
        {
          name: "RETAIL",
          formula: "2605 m^2 (konstan)",
          value: retail,
        },
        {
          name: "LUAS BANGUNAN",
          formula:
            "RUANG TUNGGU + TOILET + MUSHOLA + KANTOR + SIRKULASI + RETAIL",
          value: building_area,
        },
        {
          name: "LAHAN PARKIR",
          formula: "LUAS BANGUNAN * 25%",
          value: parking_lot,
        },
      ];

      projection_content = (
        <main>
          <h1 className="text_bold margin_bottom">
            Analisis Proyeksi Penumpang dalam 5 Tahun dan 10 Tahun{" "}
            <span
              className="badge_pill_small background_black inline cursor_pointer"
              onClick={this.toggle_info.bind(this, "proyeksi")}
            >
              i
            </span>
          </h1>
          <section className="container_light margin_bottom">
            <h1 className="badge_pill margin_bottom" id="orange">
              Proyeksi penumpang 5 tahun per bulan
            </h1>
            <p className="text_bold">
              {new Intl.NumberFormat("id-ID", {
                style: "decimal",
              }).format(p_5_month)}
            </p>
            <hr />
            <h1 className="badge_pill margin_bottom" id="orange">
              Proyeksi penumpang 5 tahun per hari
            </h1>
            <p className="text_bold">
              {new Intl.NumberFormat("id-ID", {
                style: "decimal",
              }).format(p_5_day)}
            </p>
            <hr />
            <h1 className="badge_pill margin_bottom" id="orange">
              Proyeksi penumpang 10 tahun perbulan
            </h1>
            <p className="text_bold">
              {new Intl.NumberFormat("id-ID", {
                style: "decimal",
              }).format(p_10_month)}
            </p>
            <hr />
            <h1 className="badge_pill margin_bottom" id="orange">
              Proyeksi penumpang 10 tahun per hari
            </h1>
            <p className="text_bold">
              {new Intl.NumberFormat("id-ID", {
                style: "decimal",
              }).format(p_10_day)}
            </p>
          </section>

          <br />
          <br />

          <h1 className="text_bold margin_bottom">
            Proyeksi Luas Bangunan{" "}
            <span
              className="badge_pill_small background_black inline cursor_pointer"
              onClick={this.toggle_info.bind(this, "ruang")}
            >
              i
            </span>
          </h1>
          <section className="container_light margin_bottom">
            <table className="table full_width">
              <thead>
                <tr>
                  <th>NAMA RUANG</th>
                  <th>
                    NILAI (m<sup>2</sup>)
                  </th>
                </tr>
              </thead>
              <tbody>
                {room_list.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{item.name}</td>
                      <td className="text_right">
                        {new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(parseInt(item.value))}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </section>

          <br />
          <br />

          <h1 className="text_bold margin_bottom">
            CAPEX PENATAAN{" "}
            <span
              className="badge_pill_small background_black inline cursor_pointer"
              onClick={this.toggle_info.bind(this, "capex")}
            >
              i
            </span>
          </h1>
          <section className="container_light margin_bottom">
            <p className="text_bold">
              {new Intl.NumberFormat("id-ID", {
                style: "decimal",
              }).format(parseInt(capex_value))}
            </p>
          </section>
        </main>
      );
    }

    const modal_info_content = modal_info && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={modal_info}
        onClose={this.toggle_info}
      >
        <div className="box-body">
          <main className="max_height">
            {info_content === "proyeksi" ? (
              <PROYEKSI_INFO
                variable_object={variable_object}
                p_5_month={p_5_month}
                p_5_day={p_5_day}
                p_10_month={p_10_month}
                p_10_day={p_10_day}
              />
            ) : info_content === "capex" ? (
              <CAPEX_INFO
                building_area={building_area}
                capex_value={capex_value}
                retail={retail}
              />
            ) : (
              <RUANG_INFO room_list={room_list} />
            )}
          </main>
        </div>
      </Modal>
    );

    return (
      <div>
        {modal_info_content}
        <button
          className="button margin_bottom background_blue"
          id="red"
          onClick={this.on_close_resume}
        >
          Tutup
        </button>
        <h3 className="text_bold margin_bottom">Pengambilan data</h3>
        <section
          className="container_light margin_bottom"
          style={{ maxHeight: "200px", overflowY: "auto" }}
        >
          <table className="table">
            <tbody>
              {layer_list.map((item, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      <p className="text_small">{item?.geo_layer?.name}</p>
                    </td>
                    <td>
                      {item?.geo_layer?.geojson?.features?.length > 0 ? (
                        <button className="badge_pill" id="orange">
                          Terambil
                        </button>
                      ) : (
                        <button className="badge_pill" id="grey">
                          Belum
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </section>
        <br />
        <br />
        <h3 className="text_bold margin_bottom">Analisis Proyeksi Penumpang</h3>
        <h2 className="badge_pill margin_bottom" id="orange">
          Passenger
        </h2>
        <button
          className="button margin_bottom margin_right"
          id="dark_blue"
          onClick={this.on_set_chart_width.bind(this, 90)}
        >
          {"<->"}
        </button>
        <button
          className="button button margin_bottom margin_right"
          id="dark_blue"
          onClick={this.on_set_chart_width.bind(this, width_small)}
        >
          {"><"}
        </button>

        {content_array.map((item, idx) => {
          let average_content;
          if (item.calc_avg) {
            average_content = (
              <div className="container_light margin_bottom">
                <table>
                  <tbody>
                    <tr>
                      <td>Rata-rata penumpukan {item.entity} perbulan</td>
                      <td>=</td>
                      <td>
                        {new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(parseInt(item?.average_month))}
                      </td>
                    </tr>
                    {item.calc_avg_day && (
                      <>
                        <tr>
                          <td>Rata-rata penumpukan {item.entity} perhari</td>
                          <td>=</td>
                          <td>
                            {new Intl.NumberFormat("id-ID", {
                              style: "decimal",
                            }).format(parseInt(item?.average_day))}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            );
          }

          let ratio_content;

          if (item.calc_ratio) {
            ratio_content = (
              <section className="container_light margin_bottom">
                <table>
                  <tbody>
                    <tr>
                      <td>Presentase rasio</td>
                      <td>
                        =
                        {new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(item?.average_ratio)}
                      </td>
                    </tr>
                    <tr>
                      <td>Presentase rasio dalam persen</td>
                      <td>
                        =
                        {new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(item?.average_ratio_percent)}
                        %
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
            );
          }

          return (
            <section key={idx}>
              <div className="container_light margin_bottom">
                <h2 className="badge_pill" id="orange">
                  {item.name}
                </h2>
                <br />
                <div style={{ overflowX: "scroll" }}>
                  <ChartTemplate
                    type="bar"
                    width={600}
                    height={200}
                    style={{ width: `${width_chart}px` }}
                    options={{
                      hoverBackgroundColor: "#f38026",
                      animation: false,
                      maintainAspectRatio: false,
                      responsive: false,
                      legend: {
                        display: false,
                      },
                      indexAxis: "x",
                      title: {
                        display: false,
                      },
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                    data={item.chart_js_data}
                  />
                </div>
              </div>
              {average_content}
              {ratio_content}
              <br />
            </section>
          );
        })}
        {projection_content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  layer: state.layer,
  properties: state.properties,
  map: state.map,
});

export default connect(mapStateToProps, { set_value_properties })(
  TimeseriesResume
);
