import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";
import {
  generateFields,
  // generateGeojson
} from "../validation/generateGeoJson";
import { color_setup } from "../validation/geojson_properties";
import convert_to_api_attributes from "../validation/open_api_helper";

const SERVER_URL = geoServerBaseUrl;

export const create_layer_custom_api = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("createLayer"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    let final_body = { ...body };
    const res = await axios.post(
      SERVER_URL + "/open_api/create",
      final_body,
      config
    );
    let geo_layer = res.data;
    geo_layer.viewStatus = true;
    geo_layer.flyStatus = true;
    dispatch(clearLoading());
    dispatch({
      type: "CREATE_LAYER",
      payload: geo_layer,
    });
    if (body.is_show_and_fly) {
      const geo_layer_id = geo_layer?._id;
      dispatch(get_layer_detail_custom_api(geo_layer_id));
    }
    dispatch({
      type: "status_action",
    });
    // dispatch({
    //     type: "set_value_layer",
    //     payload: {
    //         key: "current_progress_create_layer",
    //         value: 100,
    //     },
    // });
  } catch (e) {
    dispatch(clearLoading());
  }
};

export const get_layer_detail_custom_api =
  (geo_layer_id) => async (dispatch) => {
    try {
      dispatch(setLoading("loading..."));
      let config = {
        headers: {
          accesstoken: localStorage.token_mapid,
        },
        timeout: 10000,
      };

      let res = await axios.get(
        SERVER_URL +
        "/open_api/get_detail_layer_custom_endpoint/" +
        geo_layer_id,
        config
      );

      const { api_endpoint, body, headers, http_method, params } =
        res?.data?.read_open_api || {};

      let geojson = {
        type: "FeatureCollection",
        features: [],
      };

      config = {
        headers: convert_to_api_attributes(headers),
        timeout: 10000,
      };

      if (http_method === "get") {
        const string_params =
          params?.length > 0
            ? params.map((param, index) => {
              if (index === 0) {
                return `${param?.key ? `?${param?.key}` : ""}${param?.value ? `=${param?.value}` : ""
                  }`;
              } else {
                return `${param?.key ? `&${param?.key}` : ""}${param?.value ? `=${param?.value}` : ""
                  }`;
              }
            })
            : "";

        const complete_url = api_endpoint + string_params;
        let res_geojson = await axios.get(complete_url, config);
        geojson = res_geojson?.data;
      } else if (http_method === "post") {
        let res_geojson = await axios.post(api_endpoint, body, config);
        geojson = res_geojson?.data;
      }

      let fields = new Set();
      let new_features = [];
      geojson?.features.forEach((feature, idx) => {
        Object.keys(feature?.properties).forEach((key) => {
          fields.add(key);
        });

        const geometry_type = feature?.geometry?.type;
        const prop_default = color_setup({
          feature,
          geometry_type,
          idx,
        });

        const prop_ori = feature?.properties || {};
        const properties = Object.assign(prop_default, prop_ori);

        new_features.push({
          ...feature,
          properties,
        });
      });

      fields = generateFields(Array.from(fields));

      geojson.features = new_features;

      dispatch({
        type: "REPLACE_GEOJSON",
        payload: { geo_layer_id, geojson: geojson },
      });

      dispatch({
        type: "push_field_bulk",
        payload: {
          geo_layer_id,
          fields: fields || [],
        },
      });

      dispatch({
        type: "set_layer_id",
        payload: geo_layer_id,
      });

      dispatch({
        type: "view_layer",
        payload: {
          geo_layer_id,
          value: true,
        },
      });

      dispatch({
        type: "fly_action",
      });

      dispatch(clearLoading());
      dispatch({
        type: "status_action",
      });

      return res;
    } catch (error) {
      console.log(error);
      dispatch(clearLoading());
      return error;
    }
  };

// get layer
// export const get_layer_detail_custom_api =
//   (geo_layer_id) => async (dispatch) => {
//     try {
//       dispatch(setLoading("loading..."));
//       const config = {
//         headers: {
//           accesstoken: localStorage.token_mapid,
//         },
//       };

//       let res = await axios.get(
//         SERVER_URL +
//         "/open_api/get_detail_layer_custom_endpoint/" +
//         geo_layer_id,
//         config
//       );

//       let fields = new Set();
//       let new_features = [];
//       res?.data?.features.forEach((feature, idx) => {
//         Object.keys(feature?.properties).forEach((key) => {
//           fields.add(key);
//         });

//         const geometry_type = feature?.geometry?.type;
//         const prop_default = color_setup({
//           feature,
//           geometry_type,
//           idx,
//         });

//         const prop_ori = feature?.properties || {};
//         const properties = Object.assign(prop_default, prop_ori);

//         new_features.push({
//           ...feature,
//           properties,
//         });
//       });

//       const geojson = {
//         type: "FeatureCollection",
//         features: new_features,
//       };

//       fields = generateFields(Array.from(fields));

//       dispatch({
//         type: "REPLACE_GEOJSON",
//         payload: { geo_layer_id, geojson: geojson },
//       });

//       dispatch({
//         type: "push_field_bulk",
//         payload: {
//           geo_layer_id,
//           fields: fields || [],
//         },
//       });

//       dispatch({
//         type: "set_layer_id",
//         payload: geo_layer_id,
//       });

//       dispatch({
//         type: "view_layer",
//         payload: {
//           geo_layer_id,
//           value: true,
//         },
//       });

//       dispatch({
//         type: "fly_action",
//       });

//       dispatch(clearLoading());
//       // dispatch({
//       //     type: "status_action",
//       // });

//       return res;
//     } catch (error) {
//       console.log(error);
//       dispatch(clearLoading());
//       return error;
//     }
//   };

export const request_direct_to_user_api = (props) => async (dispatch) => {
  try {
    const { http_method, api_endpoint, headers, params, body } = props;

    const config = {
      headers,
      timeout: 10000,
    };

    let res;

    dispatch(setLoading("Loading..."));

    if (http_method === "get") {
      // const url_params = api_params_to_url(params);
      const complete_url = api_endpoint + params;

      res = await axios.get(complete_url, config);
    } else if (http_method === "post") {
      res = await axios.post(api_endpoint, body, config);
    }

    dispatch(clearLoading());
    return res;
  } catch (error) {
    dispatch(clearLoading());

    if (error?.response) {
      return {
        status: error.request?.status,
        data: {
          message: error.response,
        },
      };
    } else if (error?.request) {
      return {
        status: error.request?.status || 404,
        data: {
          message: error.request?.response || "Server not found",
        },
      };
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }

    return error;
  }
};

// testing endpoint client
export const request_by_client_api = (props) => async (dispatch) => {
  try {
    const { http_method, api_endpoint, headers, params, body } = props;
    const request_body = { http_method, api_endpoint, headers, params, body };
    const config = {
      headers,
    };

    dispatch(setLoading("Loading..."));
    const res = await axios.post(
      SERVER_URL + "/open_api/get_custom_api",
      request_body,
      config
    );
    dispatch(clearLoading());
    return res;
  } catch (error) {
    dispatch(clearLoading());

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
      if (error.request?.status) {
        return {
          status: 404,
          data: {
            message: "Server not found",
          },
        };
      } else {
        return {
          status: error.request?.status,
          data: {
            message: "Something error",
          },
        };
      }
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }

    return error;
  }
};

export const setLoading = (itemLoading) => {
  return {
    type: "SET_LOADING_PROCESS_LAYER",
    payload: itemLoading,
  };
};

export const clearLoading = () => {
  return {
    type: "CLEAR_LOADING_LAYER",
  };
};
