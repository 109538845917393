/*
UJI FISIK

"MIN_LUAS",
"MAX_LUAS",
"MIN_RASIO",
"MAX_RASIO",
"MIN_LEBAR_JALAN",

UJI SUPPLY DEMAND

"INDIKATOR_JML_PENDUDUK",
"JML_PENDUDUK",
"JML_MIN_POI",
"POI_PENDUKUNG",
"MAKS_RESISTEN",
"POI_RESISTEN_1KM",
"REKOMENDASI_PEMBANGUNAN"

UJI FINANSIAL

"BIAYA_PEMBANGUNAN_PER_M_2",
"PENDAPATAN_PER_M_2_MIN",
"PENDAPATAN_PER_M_2_MAX",
"BIOP_PER_M_2_MIN",
"BIOP_PER_M_2_MAX",

OTHER

"JENIS",
"TIPE_BANGUNAN",  
"HARGA_SEWA_PER_M_2_PER_TAHUN",
"OKUPANSI_PER_TAHUN",
"DISEWAKAN_DIGUNAKAN",
"FASUM",  
"RASIO_BIOP_MIN",
"RASIO_BIOP_MAX",
*/

export const calc_fs = (body) => async (dispatch) => {
  try {
    const {
      landvalue_value,
      luas_tanah,
      luas_bangunan,
      project_duration_years,
      discount_rate_1_percent,
      revenue_growth_percent,
      opex_growth_percent,
      working_capital_idr,
      discount_rate_2_percent,
      economic_life_years,
      tax_percent,
      capex_tanah_input,
      skema_terminal_value,
    } = body;
    let rows_filtered_3 = body?.rows_filtered_3 || [];

    rows_filtered_3 = rows_filtered_3.map((item) => {
      //step 1 menghitung capex
      const capex_tanah =
        capex_tanah_input ||
        parseFloat(luas_tanah) * parseFloat(landvalue_value);
      const capex_bangunan =
        parseFloat(luas_bangunan) * parseFloat(item?.BIAYA_PEMBANGUNAN_PER_M_2);
      const capex_total = capex_tanah + capex_bangunan;
      const depresiasi = capex_total / economic_life_years;
      // untuk kebutuhan tambahan skema TV
      const additional_years = 1;
      let tv_percent = 0;
      let tv_rp = 0;
      const total_invest = parseInt(capex_total + working_capital_idr);

      let cf_n_10 = 0;
      const r_1 =
        parseFloat(item?.PENDAPATAN_PER_M_2_MAX) * parseFloat(luas_bangunan);
      const b_1 =
        parseFloat(item?.BIOP_PER_M_2_MIN) * parseFloat(luas_bangunan);

      item.depresiasi = depresiasi;
      item.r_1 = r_1;
      item.b_1 = b_1;

      // variabel yang dibutuhkan untuk menghitung apc
      let a = 0;

      //step 2 menghitung NPV
      let finance_array = [];
      for (
        let n = 1;
        n <= Number(project_duration_years) + additional_years;
        n++
      ) {
        //n mulai dari satu
        //n berakhir di project_duration_years, misal 10 tahun
        /*
        1. Pendapatan (R)
        Rn = R1 + (n-1) * a
        Rn = R1 + (n-1) * (revenue_growth_percent/100) * R1
        a = (revenue_growth_percent/100) * R1
        */
        //  gwroth + r_n
        const r_n = r_1 + (n - 1) * (revenue_growth_percent / 100) * r_1;

        /*
        2. Biaya Operasional (B)
        Bn = B1 + (n-1) * b
        Bn = B1 + (n-1) * (opex_growth_percent/100) * B1 
        b = (opex_growth_percent/100) * B1
        */
        const b_n = b_1 + (n - 1) * (opex_growth_percent / 100) * b_1;

        /*
        3. Earning After Tax (EAT)
        EATn = Rn - Bn - depresiasi - ( (tax_percent/100) * (Rn - Bn - depresiasi) )
        
        */
        const eat_n =
          r_n -
          b_n -
          depresiasi -
          (tax_percent / 100) * (r_n - b_n - depresiasi);

        /*
        4. Cash flow (CF)
        CFn = EATn + depresiasi 
        CFn = EATn + capex_total/economic_life_years 
        */
        const cf_n = eat_n + depresiasi;

        /*
        5. Present Value (PV)
        PVn = CFn * DRn
        PVn = CFn * ( 1 / (( 1 + discount_rate_1_percent/100 ) ** n_year ) )
        */
        const drn = 1 / (1 + discount_rate_1_percent / 100) ** n;
        const drn_2 = 1 / (1 + discount_rate_2_percent / 100) ** n;
        const pv_n = cf_n * (1 / (1 + discount_rate_1_percent / 100) ** n);

        // pv 2
        const pv_n_2 = cf_n * (1 / (1 + discount_rate_2_percent / 100) ** n);

        // In = capex_total - (n-1) * b;
        const In = capex_total - (n - 1) * depresiasi;

        let cf_n_change = 0;
        if (n > 1 && n <= project_duration_years) {
          const previous_cf_n = finance_array[n - 2].cf_n;
          cf_n_change = ((cf_n - previous_cf_n) / previous_cf_n) * 100;
        }

        let last3YearsChanges = finance_array
          .slice(-3) // Mengambil 3 index terakhir sebelum additional_years
          .map((item) => item.cf_n_change)
          .filter((change) => change !== 0);

        if (n > project_duration_years && skema_terminal_value) {
          const averageChange = parseFloat(
            last3YearsChanges.reduce((sum, change) => sum + change, 0) /
              last3YearsChanges.length
          );
          tv_percent = (discount_rate_1_percent - averageChange) / 100;
          tv_rp = eat_n / tv_percent;
        }

        if (n === project_duration_years) {
          cf_n_10 = skema_terminal_value
            ? cf_n + tv_rp + working_capital_idr
            : cf_n;
        }
        // const pv_n = cf_n * (1 / (1 + discount_rate_1_percent / 100) ** n);

        /*
        6. Accumulated PV Cash Flow\
        APC(n) = a + b
        a = APC(n-1)
            APC(n0) = - total investasi
        b = PV(n)
        */
        let apc = 0;
        if (n === 1) {
          apc = -total_invest + pv_n;
        } else {
          apc = a + pv_n;
        }
        a = apc;

        finance_array.push({
          r_1,
          b_1,
          r_n,
          b_n,
          eat_n,
          cf_n,
          pv_n,
          pv_n_2,
          In,
          cf_n_change,
          tv_percent,
          tv_rp,
          apc,
          drn,
          drn_2,
          cf_n_10,
        });
      }

      /*
      6. Net Present Value (PV)
      NPV = Σ PV - Σ investasi 
      NPV = Σ PV - (capex_total + working_capital_idr)
      */
      const new_finance_array = finance_array.slice(0, -2);
      const lastTvRp = finance_array[finance_array.length - 1]?.tv_rp || 0;
      const lastCfN = finance_array[finance_array.length - 1]?.cf_n_10 || 0;

      let drn_value = finance_array[finance_array.length - 2]?.drn;

      const last_year_pv_value = parseFloat((lastTvRp + lastCfN) * drn_value);

      const sum_pv = new_finance_array.reduce(
        (sum, item) => sum + item.pv_n,
        0
      );
      const data = sum_pv + last_year_pv_value;

      const npv = Math.round(-capex_total + data - working_capital_idr);

      //step 3 menghitung IRR
      /*
      7. IRR
      IRR = (NPV1 / (NPV1 - NPV2)) * (I2 - I1)
      */
      const new_finance_array_2 = finance_array.slice(0, -2);
      const lastTvRp_2 = finance_array[finance_array.length - 1]?.tv_rp || 0;
      const lastCfN_2 = finance_array[finance_array.length - 1]?.cf_n_10 || 0;

      let drn_value_2 = finance_array[finance_array.length - 2]?.drn_2;

      const pv_value_2 = parseFloat((lastTvRp_2 + lastCfN_2) * drn_value_2);

      const sum_pv_2 = new_finance_array_2.reduce(
        (sum, item) => sum + item.pv_n_2,
        0
      );
      const data_2 = sum_pv_2 + pv_value_2;

      const npv_2 = Math.round(-capex_total + data_2);
      const dr1 = discount_rate_1_percent;
      const dr2 = discount_rate_2_percent;
      const irr = (npv / (npv - npv_2)) * (dr2 - dr1);

      //step 4 menghitung ARR
      // ARR = EAT / (AoI + working_capital_idr) * 0.01;
      const sum_in = finance_array.reduce((sum, item) => sum + item.In, 0);
      const aoi = sum_in / finance_array.length;
      const arr = (finance_array[0].eat_n / (aoi + working_capital_idr)) * 100;

      //step 5 menghitung Payback Period

      /*
      Rumus PP (REVISI)
      PP = (n-1) + (x/y)
      n = jumlah tahun proyeksi (def: 10 tahun)
      x = Akumulasi PV Cash Flow tahun n-1 = APC
      APC(n) = a + b
      a = APC(n-1)
      APC(n0) = -total investasi
      b = PV(n)
      y = PV tahun terakhir
      */

      const apc_n_min_1 = finance_array[finance_array.length - 3]?.apc;
      const last_apc = apc_n_min_1 + last_year_pv_value;

      finance_array[finance_array.length - 2].apc = last_apc;
      finance_array[finance_array.length - 2].pv_n = last_year_pv_value;

      let year_of_positive_apc = 0;
      let x = 0;
      let y = 0;
      for (let i = 0; i < finance_array.length; i++) {
        if (finance_array[i]?.apc > 0) {
          year_of_positive_apc = i + 1; // Store the year of positive APC
          x = Math.abs(finance_array[i - 1]?.apc) || 0; // Use optional chaining for safety
          y = finance_array[i]?.pv_n; // Store pv_n
          break; // Exit the loop after finding the first positive APC
        }
      }

      // const y = finance_array[finance_array.length - 2].pv_n;
      // const x = finance_array[finance_array.length - 3].apc;

      let payback_periode = year_of_positive_apc - 1 + x / y || 0;
      payback_periode = parseFloat(payback_periode.toFixed(2));

      //step 6 menghitung Profitability Index

      // PI = Total PV Cashflow (PV) / Total Investasi
      // step 1
      let total_pv = finance_array
        .slice(0, -2)
        .map((finance) => {
          return finance.pv_n;
        })
        .reduce((acc, curr) => acc + curr, 0);

      total_pv += last_year_pv_value;

      let profitability_index = total_pv / total_invest;
      profitability_index = parseFloat(profitability_index.toFixed(2));

      return {
        ...item,
        capex_tanah,
        capex_bangunan,
        capex_total,
        finance_array,
        sum_pv,
        npv,
        npv_2,
        irr,
        arr,
        payback_periode,
        profitability_index,
        aoi,
        last_year_pv_value,
      };
    });

    // urutkan berdasarkan rank npv,irr,pp
    rows_filtered_3 = rankSort(rows_filtered_3);
    dispatch({
      type: "set_value_hbu",
      payload: {
        key: "rows_filtered_3",
        value: rows_filtered_3,
      },
    });
    dispatch({
      type: "calc_update",
    });
  } catch (error) {}
};

function rankSort(data) {
  const rankedAttributes = [
    "npv",
    "irr",
    "payback_periode",
    "profitability_index",
  ]; // Atribut yang diperhitungkan
  const atributKecilKeBesar = ["payback_periode"]; //semakin kecil semakin baik, jadi urutan ascending (kecil ke besar)

  // Step 1: Hitung rank untuk setiap atribut
  let rankedData = data.map((item) => ({
    ...item,
    rank: {}, // Tempat menyimpan ranking tiap atribut
    total_rank: 0, // Akan dihitung nanti
  }));

  rankedAttributes.forEach((attr) => {
    let sorted;

    if (atributKecilKeBesar.includes(attr)) {
      // PP semakin kecil semakin baik, jadi urutan ascending (kecil ke besar)
      sorted = [...rankedData].sort((a, b) => a[attr] - b[attr]);
    } else {
      // NPV & IRR semakin besar semakin baik, jadi urutan descending (besar ke kecil)
      sorted = [...rankedData].sort((a, b) => b[attr] - a[attr]);
    }

    // Beri ranking berdasarkan posisi
    sorted.forEach((item, index) => {
      item.rank[attr] = index + 1; // Rank dimulai dari 1
    });
  });

  // Step 2: Hitung total rank untuk setiap objek
  rankedData.forEach((item) => {
    item.total_rank = Object.values(item.rank).reduce(
      (sum, rank) => sum + rank,
      0
    );
  });

  // Step 3: Urutkan berdasarkan total_rank (semakin kecil semakin tinggi prioritasnya)
  rankedData.sort((a, b) => a.total_rank - b.total_rank);

  // Hapus properti rank sebelum return
  return rankedData.map(({ rank, ...rest }) => rest);
}

export const conclude_hbu_legal = ({
  luas_rencana_pembangunan,
  max_lantai_tanah_kosong,
  max_lantai_bangunan,
  peraturan_bangunan,
  luas_tanah,
  luas_bangunan,
  data_bangun = {},
}) => {
  let {
    peruntukan,
    // gsb = 0,
    kdb,
    klb,
    // ktb_tanah_kosong = 0,
    ktb_bangunan,
  } = data_bangun;

  if (!luas_rencana_pembangunan && klb) {
    luas_rencana_pembangunan = luas_tanah * klb;
  }

  if (!max_lantai_tanah_kosong) {
    const k35 = (kdb / 100) * luas_tanah;
    max_lantai_tanah_kosong = Math.floor(luas_rencana_pembangunan / k35);
  }

  if (!luas_bangunan && klb) {
    luas_bangunan = luas_tanah * klb;
  }

  if (!max_lantai_bangunan) {
    const h55 = ktb_bangunan * luas_tanah;
    max_lantai_bangunan = Math.floor(h55 / luas_bangunan);
  }

  let kesimpulan = peruntukan
    ? `Properti berdasarkan peruntukannya sebagai ${peruntukan}.`
    : "";
  let peraturan_bangunan_str = "";
  if (peraturan_bangunan?.length > 0) {
    peraturan_bangunan_str = ` ${peraturan_bangunan.join(", ")}`;
    kesimpulan += `${
      peruntukan ? `${peruntukan?.replace(".", "")}dan ` : ""
    }Mempertimbangkan Ketersediaan${peraturan_bangunan_str}.`;
  }
  let kesimpulan_tanah_kosong = luas_rencana_pembangunan
    ? ` Maka Pembangunan dapat Dilakukan Seluas ${luas_rencana_pembangunan} m2${
        max_lantai_tanah_kosong
          ? ` dengan Maksimal Ketinggian ${max_lantai_tanah_kosong} Lantai.`
          : "."
      }`
    : "";
  let kesimpulan_bangunan = luas_bangunan
    ? ` Maka Pembangunan dapat Dilakukan Seluas ${luas_bangunan} m2${
        max_lantai_bangunan
          ? ` dengan Maksimal Ketinggian ${max_lantai_bangunan} Lantai.`
          : "."
      }`
    : "";

  const tak_ada_kesimpulan =
    "Untuk menampilkan kesimpulan uji legal, lengkapi peruntukan (zoning) dengan memeriksa Peraturan Walikota daerah masing-masing tentang Rencana Detail Tata Ruang (RDTR) dan Peraturan Zonasi atau kunjungi Gistaru.";
  if (kesimpulan || kesimpulan_tanah_kosong) {
    kesimpulan_tanah_kosong = `${kesimpulan}${
      kesimpulan
        ? kesimpulan_tanah_kosong
        : kesimpulan_tanah_kosong.replace(" Maka", "")
    }`;
  } else {
    kesimpulan_tanah_kosong = tak_ada_kesimpulan;
  }
  if (kesimpulan || kesimpulan_bangunan) {
    kesimpulan_bangunan = `${kesimpulan}${
      kesimpulan
        ? kesimpulan_bangunan
        : kesimpulan_bangunan.replace(" Maka", "")
    }`;
  } else {
    kesimpulan_bangunan = tak_ada_kesimpulan;
  }

  return {
    kesimpulan_tanah_kosong,
    kesimpulan_bangunan,
    luas_rencana_pembangunan_new: luas_rencana_pembangunan,
    max_lantai_tanah_kosong_new: max_lantai_tanah_kosong,
    luas_bangunan_new: luas_bangunan,
    max_lantai_bangunan_new: max_lantai_bangunan,
  };
};

export const conclude_hbu_fisik_bangunan = ({
  luas_bangunan,
  jumlah_lantai,
  tahun_dibangun,
  kondisi_bangunan,
}) => {
  const luas_bangunan_str = `Bangunan memiliki luas total ${luas_bangunan} m2`;
  const jumlah_lantai_str = jumlah_lantai
    ? ` dengan jumlah ${jumlah_lantai} lantai.`
    : ".";
  const tahun_dibangun_str = `Dibangun pada tahun ${tahun_dibangun}`;
  const kondisi_bangunan_str = kondisi_bangunan
    ? ` memiliki kondisi ${kondisi_bangunan}.`
    : ".";

  let result = "";
  if (luas_bangunan) {
    result += luas_bangunan_str;
    result += jumlah_lantai_str;
  }
  if (tahun_dibangun) {
    result += tahun_dibangun_str;
    result += kondisi_bangunan_str;
  }

  return result;
};

export const conclude_hbu_fisik_tanah_kosong = ({
  luas_tanah,
  bentuk_tanah,
  lokasi_dalam_market_area,
  transportasi_umum,
  kemungkinan_bencana,
}) => {
  const luas_tanah_str = `Tanah memiliki luas ${luas_tanah} m2`;
  const bentuk_tanah_str = ` berbentuk ${bentuk_tanah}`;
  const lokasi_dalam_market_area_str = ` berlokasi dalam Market Area ${lokasi_dalam_market_area} lantai`;
  const kemungkinan_bencana_str = ` dengan Kemungkinan Bencana ${kemungkinan_bencana}`;
  const transportasi_umum_str = ` dan ${transportasi_umum} transportasi umum`;

  let result = "";
  if (luas_tanah) {
    result += luas_tanah_str;
    if (bentuk_tanah) result += bentuk_tanah_str;
    if (lokasi_dalam_market_area) result += lokasi_dalam_market_area_str;
    if (kemungkinan_bencana) result += kemungkinan_bencana_str;
    if (transportasi_umum) result += transportasi_umum_str;
  }
  result += result ? "." : "-";
  return result;
};

export const conclude_max_productivity = ({
  luas_tanah,
  bentuk_tanah,
  lokasi_dalam_market_area,
  transportasi_umum,
  kemungkinan_bencana,
}) => {
  const luas_tanah_str = `Tanah memiliki luas ${luas_tanah} m2`;
  const bentuk_tanah_str = ` berbentuk ${bentuk_tanah}`;
  const lokasi_dalam_market_area_str = ` berlokasi dalam Market Area ${lokasi_dalam_market_area} lantai`;
  const kemungkinan_bencana_str = ` dengan Kemungkinan Bencana ${kemungkinan_bencana}`;
  const transportasi_umum_str = ` dan ${transportasi_umum} transportasi umum`;

  let result = "";
  if (luas_tanah) {
    result += luas_tanah_str;
    if (bentuk_tanah) result += bentuk_tanah_str;
    if (lokasi_dalam_market_area) result += lokasi_dalam_market_area_str;
    if (kemungkinan_bencana) result += kemungkinan_bencana_str;
    if (transportasi_umum) result += transportasi_umum_str;
  }
  result += result ? "." : "-";
  return result;
};
