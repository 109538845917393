import React, { Component } from 'react';  
  
class BarDiagram extends Component {  
  // Default props for the component  
  static defaultProps = {  
    data: [], // Array of objects with 'label' and 'value' properties  
    width: 400, // Width of the bar diagram  
    height: 300, // Height of the bar diagram  
    margin: 20, // Margin around the bar diagram  
    colors: ['#3498db', '#e74c3c', '#2ecc71', '#f39c12', '#9b59b6', '#1abc9c', '#34495e'], // Default colors for bars  
  };  
  
  // Method to calculate the height of each bar based on the data value  
  calculateBarHeight = (value, maxValue) => {  
    const { height, margin } = this.props;  
    return ((value / maxValue) * (height - margin * 2));  
  };  
  
  // Method to find the maximum value in the data array  
  findMaxValue = () => {  
    const { data } = this.props;  
    return Math.max(...data.map(item => item.value));  
  };  
  
  render() {  
    const { data, height, margin=20, colors } = this.props;  
    const maxValue = this.findMaxValue();  
  
    return (  
      <div style={{  
        width: "100%",  
        height: height + 50, // Additional height for legend  
        padding: margin,    
        overflow: 'hidden',
        display: "flex",
        flexDirection: "column",
        gap: "40px"
      }}>  
        {/* Bar Diagram */}  
        <div style={{  
          width: '100%',  
          height: height,  
          position: 'relative',
          display: "flex",
          gap: "10px"
        }}>  
          {data.map((item, index) => {  
            const barHeight = this.calculateBarHeight(item.value, maxValue);  
            const barColor = colors[index % colors.length]; // Cycle through colors  
            return (  
              <div key={index} style={{  
                width: `100px`, // Each bar takes up an equal percentage of the width  
                height: barHeight,  
                backgroundColor: barColor,  
                position: 'absolute',  
                bottom: 0,  
                left: `${(100 / data.length) * index}%`, // Position each bar next to the previous one  
                transition: 'height 0.3s ease', // Smooth transition for height changes  
              }}>  
                <div style={{  
                  position: 'absolute',  
                  top: '5px', // Position the value label above the bar  
                  left: '50%',  
                  transform: 'translateX(-50%)', // Center the label  
                  fontSize: '12px',  
                  color: '#fff',  
                  fontWeight: 'bold',  
                }}>  
                  {item.value}  
                </div>  
              </div>  
            );  
          })}  
        </div>  
  
        {/* Legend */}  
        <div style={{  
          marginTop: '10px',  
          display: 'flex',  
          justifyContent: 'center',  
          alignItems: 'center',  
          flexWrap: 'wrap',  
        }}>  
          {data.map((item, index) => {  
            const barColor = colors[index % colors.length]; // Cycle through colors  
            return (  
              <div key={index} style={{  
                display: 'flex',  
                alignItems: 'center',  
                marginRight: '20px',  
                marginBottom: '5px',  
              }}>  
                <div style={{  
                  width: '15px',  
                  height: '15px',  
                  backgroundColor: barColor,  
                  marginRight: '5px',  
                  borderRadius: '3px',  
                }}></div>  
                <span style={{  
                  fontSize: '14px',  
                  color: '#333',  
                }}>  
                  {item.label}  
                </span>  
              </div>  
            );  
          })}  
        </div>  
      </div>  
    );  
  }  
}  
  
export default BarDiagram;  
