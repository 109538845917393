/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import Modal from "../common_modal/Modal";

/*REDUX*/
import { delete_data_form } from "../../App/actions/layerNewActions";

/*PICTURE*/

/*FUNCTION*/

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class DeleteDataFormOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_delete_confirmation: false,
    };
  }

  toggleModalDeleteConfirmation = (value) => {
    this.setState({
      modal_delete_confirmation: value || !this.state.modal_delete_confirmation,
    });
  };

  handleDeleteComparisonData = (geo_layer_id) => {
    const body = {
      geo_layer_id,
    };
    this.props.delete_data_form(body);
  };

  render() {
    const language = localStorage?.language || "ina";
    const { name, geo_layer_id } = this.props;
    const { modal_delete_confirmation } = this.state;
    const delete_comparison_data_content = modal_delete_confirmation && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_delete_confirmation}
        onClose={this.toggleModalDeleteConfirmation}
      >
        <div className="h_50 relative">
          <p>
            {dict["Are you sure want to delete this comparison?"][language]}
          </p>
          <div
            onClick={() => this.handleDeleteComparisonData(geo_layer_id)}
            className="button background_red absolute bottom"
          >
            Delete
          </div>
        </div>
      </Modal>
    );
    return (
      <div
        className="w_200 h_100 bg_white rounded_5"
        style={{ padding: "10px" }}
      >
        <div className="font_14 h_50">
          <label>{name}</label>
        </div>
        <div className="h_50 relative">
          <div
            onClick={() => this.toggleModalDeleteConfirmation(true)}
            className="button background_red absolute bottom"
          >
            Delete
          </div>
        </div>
        {delete_comparison_data_content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
});

export default connect(mapStateToProps, {
  delete_data_form,
})(DeleteDataFormOverlay);
