const get_biop_kai = (geo_layer) => {
  const timeseries_mode = geo_layer?.timeseries_object?.timeseries_mode;
  let chart_js_data_biop = {};
  if (timeseries_mode === "kai_biop") {
    const timeseries_object = geo_layer?.timeseries_object || {};

    const {
      year_array, // [ 2022, 2023, 2024, 2025, 2026, 2027]
      field_name_tahun,
      field_name_biop,
    } = timeseries_object;

    const fields = geo_layer?.fields || [];
    const features = geo_layer?.geojson?.features || [];

    const field_key_tahun = fields.find(
      (item) => item.name === field_name_tahun
    )?.key; //komponen sumbu x
    const field_key_biop = fields.find(
      (item) => item.name === field_name_biop
    )?.key; //dipakai sumbu y 1

    let data = [];
    features.forEach((feature) => {
      const value = feature?.properties?.[field_key_biop]; //get value
      const tahun_value = feature?.properties?.[field_key_tahun]; //get tahun_value
      const tahun_index = year_array.findIndex(
        (item) => Number(item) === Number(tahun_value)
      ); //get tahun_index
      data[tahun_index] = Number(value); //set each value to year_index position
    });

    //mencari nilai maksimum maksimum dari data_array
    const y_max = Math.max(...data);

    //mendapatkan index dari nilai maksimum
    const index_max = data.findIndex(
      (value) => Number(value) === Number(y_max)
    );

    //mendapatkan nilai dari sumbu x yang sumbu y nya maksimum
    const x_max = year_array[index_max];

    chart_js_data_biop = {
      labels: year_array,
      y_max,
      x_max,
      index_max,
      datasets: [
        {
          label: "BiOp",
          labels: year_array,
          data: data,
          backgroundColor: "#373784", //warna dark_blue KAI
        },
      ],
    };
  }

  return chart_js_data_biop;
};

export default get_biop_kai;
