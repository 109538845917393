/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import bbox from "@turf/bbox";
import area from "@turf/area";
import geojson_geometries_lookup from "geojson-geometries-lookup";
import points_within_polygon from "@turf/points-within-polygon";

/*COMPONENT*/
import Modal from "../common_modal/Modal";
import ProgressBar from "../common_spinner/ProgressBar";
import InputAreaSingle from "./InputAreaSingle";
import InputAreaHirarki from "./InputAreaHirarki";
import INPUT_TIPE_SHORT from "./INPUT_TIPE_SHORT";
import INPUT_TIPE_FULL from "./INPUT_TIPE_FULL";
import INPUT_DEMOGRAPHY from "./INPUT_DEMOGRAPHY";

/*REDUX*/
import { get_insight_v2 } from "../../App/actions/insight_v2";
import { set_value_layer } from "../../App/actions/layerActions";
import { importLayer } from "../../App/actions/layerNewActions";
import { set_value_user } from "../../App/actions/authActions";
import { set_value_properties } from "../../App/actions/propertiesActions";
import {
  set_value_insight,
  insight_update,
  push_value_insight,
} from "../../App/actions/insight_v2";

/*PICTURE*/
import expand from "../../Assets/svg/expand.svg";

/*FUNCTION*/
import run_scoring from "../../Functions/insight/run_scoring";
import format_date_formal from "../../App/validation/format_date_formal";

/*DATA*/
import uuid from "../../App/validation/uuid";
import dict from "../../Data/dict.json";

/*CONST*/
const timeout_miliseconds = 100;
const item_per_looping = 25;

class INPUT_SIMPLE extends Component {
  state = {
    mode_input: "single_input", //hirarki || single_input
    modal_payment: false,
    modal_choose_poi: false,
  };

  componentDidUpdate(prevProps) {
    const auto_step_before = prevProps.insight_reducer.auto_step;
    const auto_step_after = this.props.insight_reducer.auto_step;
    if (auto_step_after !== auto_step_before) {
      if (auto_step_after === 0) {
        this.props.set_value_insight({
          key: "current_progress_poi",
          value: 0,
        });
        this.props.set_value_insight({
          key: "current_grid_group",
          value: 0,
        });
        this.props.set_value_insight({
          key: "current_save_feature",
          value: 0,
        });
      } else if (auto_step_after === 1) {
        this.generate_square();
      } else if (auto_step_after === 2) {
        this.on_run_scoring();
      } else if (auto_step_after === 3) {
        this.on_save();
      }
    }
  }

  on_save = () => {
    this.props.set_value_insight({
      key: "sidebar_content",
      value: "home",
    });

    const {
      array_formula_insight,
      array_group_insight,
      config_final_score,
      provinsi,
      kota,
      kecamatan,
      kelurahan,
      tipe_1_array,
      tipe_2_array,
      tipe_3_array,
      tipe_level,
      tipe_1_all,
      total_poi_initial,
      grid_mode,
      grid_counts,
      grid_width_meters,
      total_square_meters,
    } = this.props.insight_reducer;
    const { geo_project } = this.props.project;
    const { domain } = this.props.auth;

    let features_grid = this.props.insight_reducer.features_grid;
    features_grid = features_grid.map((feature, idx) => {
      feature.idx = idx;
      feature.key = idx;
      feature.properties.key = idx;
      feature.properties.id = idx;
      feature.properties.ID_GRID = idx;
      return feature;
    });

    let fields = [];

    //fields auto genegerate ALL_POI_COUNT, FINAL_NUMBER_SCORE, FINAL_STATUS_SCORE,
    fields.push({
      uuid: uuid(),
      key: "ID_GRID",
      name: "ID_GRID",
      description: "",
      unit: "",
      type: "text",
      isHighlight: false,
      isRequired: true,
      isHide: false,
      isStyle: false,
    });
    fields.push({
      uuid: uuid(),
      key: "ALL_POI_COUNT",
      name: "ALL_POI_COUNT",
      description: "",
      unit: "",
      type: "number",
      isHighlight: false,
      isRequired: true,
      isHide: false,
      isStyle: false,
    });
    fields.push({
      uuid: uuid(),
      key: "FINAL_NUMBER_SCORE",
      name: "FINAL_NUMBER_SCORE",
      description: "",
      unit: "",
      type: "number",
      isHighlight: false,
      isRequired: true,
      isHide: false,
      isStyle: false,
    });
    fields.push({
      uuid: uuid(),
      key: "FINAL_STATUS_SCORE",
      name: "FINAL_STATUS_SCORE",
      description: "",
      unit: "",
      type: "text",
      isHighlight: false,
      isRequired: true,
      isHide: false,
      isStyle: false,
    });

    //fields dari formula (poi dan demografi) array_formula_insight
    array_formula_insight.forEach((item) => {
      fields.push({
        uuid: uuid(),
        key: item.key,
        name: item.key,
        description: "",
        unit: "",
        type: "number",
        isHighlight: false,
        isRequired: true,
        isHide: false,
        isStyle: false,
      });
    });

    const field_key = "FINAL_STATUS_SCORE";
    const style_array = [
      {
        key: "insight_color",
        field_key: field_key,
        paint_object: {
          "fill-opacity": 0.6,
          "fill-outline-color": "transparent",
          "line-color": "#000000",
          "line-width": 1,
          "line-opacity": 1,
          "fill-color": [
            "match",
            ["get", field_key],
            "Sangat Sesuai",
            "rgb(0,107,61)",
            "Sesuai",
            "rgb(128,181,30)",
            "Cukup Sesuai",
            "rgb(255,255,0)",
            "Tidak Sesuai",
            "rgb(246,137,11)",
            "Sangat Tidak Sesuai",
            "rgb(237,19,22)",
            "#FFFFFF",
          ],
        },
        case_recomendation: "text",
        color_logic: "by_text",
        color_mode: "green_to_yellow_to_red",
        value_array: [
          "Sangat Sesuai",
          "Sesuai",
          "Cukup Sesuai",
          "Tidak Sesuai",
          "Sangat Tidak Sesuai",
        ],
        type_of_object: {
          string: 5,
        },
        type_of_array: [
          {
            key: "string",
            value: 5,
          },
        ],
        min: 0,
        max: 100,
        is_number_count: 0,
        steps: 5,
        constant_color: "#a0d062",
        style_type: "fill",
      },
    ];

    const insight_object = {
      is_insight: true,
      version: 2,
      array_formula_insight,
      array_group_insight,
      config_final_score,
      provinsi,
      kota,
      kecamatan,
      kelurahan,
      tipe_1_array,
      tipe_2_array,
      tipe_3_array,
      tipe_level,
      tipe_1_all,
      total_poi_initial,
      grid_mode,
      grid_counts,
      grid_width_meters,
      total_square_meters,
    };
    const geojson = {
      type: "FeatureCollection",
      features: features_grid,
    };
    const body = {
      name: `[INSIGHT V2] ${kota} ${format_date_formal(Date.now())}`,
      description: "",
      type: "Polygon",
      user_id: this?.props?.auth?.user?._id,
      folder: "",
      geo_project_id: geo_project?._id,
      fields,
      geojson,
      status: "digitasi",
      domain,
      style_array,
      default_style_key: "insight_color",
      is_cluster: false,
      is_use_new_style: true,
      is_show_and_fly: false,
      insight_object,
    };
    this.props.importLayer(body, false);
  };

  on_run_scoring = () => {
    let {
      features_grid,
      array_formula_insight,
      array_group_insight,
      config_final_score,
    } = this.props.insight_reducer;
    run_scoring({
      features_grid,
      array_formula_insight,
      array_group_insight,
      config_final_score,
      set_value_insight: this.props.set_value_insight,
      insight_update: this.props.insight_update,
    });
    this.props.set_value_insight({
      key: "auto_step",
      value: 3,
    });
  };

  generate_square = () => {
    this.props.set_value_insight({
      key: "features_grid",
      value: [],
    });
    this.props.insight_update();
    setTimeout(() => {
      const {
        features_demography,
        grid_size_mode, //count, width
        grid_counts,
        grid_width_meters,
      } = this.props.insight_reducer;
      const geojson_demography = {
        type: "FeatureCollection",
        features: features_demography,
      };
      const total_square_meters = area(geojson_demography); //square meters
      this.props.set_value_insight({
        key: "total_square_meters",
        value: total_square_meters,
      });
      let cell_width_meters, cell_height_meters;
      const [min_longitude, min_latitude, max_longitude, max_latitude] =
        bbox(geojson_demography);
      const width = max_longitude - min_longitude; //width and height of the bounding box in degrees
      const height = max_latitude - min_latitude;

      if (grid_size_mode === "count") {
        const width_meters = width * 111_000; //convert width and height to approximate meters
        const height_meters = height * 111_000;
        const bounding_box_area = width_meters * height_meters; //calculate the bounding box area in square meters
        const initial_cell_area = bounding_box_area / grid_counts; //initial cell area based on bounding box area
        //adjust the cell area to fit the demography area more accurately
        const adjusted_cell_area =
          initial_cell_area * (total_square_meters / bounding_box_area);
        cell_width_meters = Math.sqrt(adjusted_cell_area); //in meters
        cell_height_meters = cell_width_meters; //in meters (to maintain square shape)
        this.props.set_value_insight({
          key: "grid_width_meters_approx",
          value: parseInt(cell_width_meters),
        });
      } else {
        cell_width_meters = grid_width_meters;
        cell_height_meters = grid_width_meters;
        const adjusted_cell_area = cell_width_meters * cell_height_meters;
        const grid_counts_approx = parseInt(
          total_square_meters / adjusted_cell_area
        );
        this.props.set_value_insight({
          key: "grid_counts_approx",
          value: grid_counts_approx,
        });
      }
      //convert cell width and height back to degrees for grid generation
      const cell_width_deg = cell_width_meters / 111_000;
      const cell_height_deg = cell_height_meters / 111_000;
      const features_grid_raw = []; //create the grid features
      const num_rows = Math.ceil(height / cell_height_deg);
      const num_cols = Math.ceil(width / cell_width_deg);
      for (let row = 0; row < num_rows; row++) {
        //loop through rows and columns to generate grid cells
        for (let col = 0; col < num_cols; col++) {
          const cell_min_longitude = min_longitude + col * cell_width_deg;
          const cell_min_latitude = min_latitude + row * cell_height_deg;
          const cellMaxLng = cell_min_longitude + cell_width_deg;
          const cellMaxLat = cell_min_latitude + cell_height_deg;
          //create the grid cell as a GeoJSON Polygon feature
          const cell_feature = {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [cell_min_longitude, cell_min_latitude],
                  [cellMaxLng, cell_min_latitude],
                  [cellMaxLng, cellMaxLat],
                  [cell_min_longitude, cellMaxLat],
                  [cell_min_longitude, cell_min_latitude],
                ],
              ],
            },
            properties: {
              row,
              col,
            },
          };
          features_grid_raw.push(cell_feature);
        }
      }
      this.props.set_value_insight({
        key: "features_grid_raw",
        value: features_grid_raw,
      });
      //clear grid intersections
      this.clear_grid_intersections(features_grid_raw);
    }, timeout_miliseconds); //milliseconds delay
  };

  clear_grid_intersections = (features_grid_raw) => {
    this.setState({ loading_status_grid: true });
    const {
      features_demography,
      features_poi,
      tipe_1_array,
      tipe_2_array,
      tipe_3_array,
      tipe_level,
      tipe_1_all,
    } = this.props.insight_reducer;
    let array_formula_insight =
      this.props.insight_reducer.array_formula_insight;
    if (tipe_level === "tipe_1" || tipe_1_all) {
      tipe_1_array.forEach((item) => {
        const key = item.TIPE_1;
        //cek apakah sudah ada atau belum, jika sudah ada tidak perlu push
        if (!array_formula_insight.some((item) => item.key === key))
          array_formula_insight.push({
            key,
            is_directly_proportional: true,
            min: 0,
            max: 0,
            weight_score: 1,
            group: "poi",
          });
      });
    } else if (tipe_level === "tipe_2") {
      tipe_2_array.forEach((item) => {
        const key = item.TIPE_2 + ", " + item.TIPE_1;
        //cek apakah sudah ada atau belum, jika sudah ada tidak perlu push
        if (!array_formula_insight.some((item) => item.key === key))
          array_formula_insight.push({
            key,
            is_directly_proportional: true,
            min: 0,
            max: 0,
            weight_score: 1,
            group: "poi",
          });
      });
    } else if (tipe_level === "tipe_3") {
      tipe_3_array.forEach((item) => {
        const key = item.TIPE_3 + ", " + item.TIPE_2 + ", " + item.TIPE_1;
        //cek apakah sudah ada atau belum, jika sudah ada tidak perlu push
        if (!array_formula_insight.some((item) => item.key === key))
          array_formula_insight.push({
            key,
            is_directly_proportional: true,
            min: 0,
            max: 0,
            weight_score: 1,
            group: "poi",
          });
      });
    }
    this.props.set_value_insight({
      key: "array_formula_insight",
      value: array_formula_insight,
    });
    const geojson_poi = {
      type: "FeatureCollection",
      features: features_poi,
    };
    const delay_milisecond_constant = 77;
    const delay_promise = () =>
      new Promise((res) => setTimeout(res, delay_milisecond_constant));
    const total_item = features_grid_raw.length;
    const group_per_looping = [];
    const geojson_demography = {
      type: "FeatureCollection",
      features: features_demography,
    };
    const glookup = new geojson_geometries_lookup(geojson_demography);
    function get_mid_point(coordinates) {
      const numPoints = coordinates.length;
      let lat_sum = 0;
      let long_sum = 0;
      //sum all latitude and longitude values of the vertices
      coordinates.forEach(([longitude, latitude]) => {
        lat_sum += latitude;
        long_sum += longitude;
      });
      //calculate the average to get the center point
      const lat_center = lat_sum / numPoints;
      const long_center = long_sum / numPoints;
      return {
        type: "Point",
        coordinates: [long_center, lat_center],
      };
    }
    for (let i = 0; i < total_item; i += item_per_looping) {
      group_per_looping.push({
        start: i,
        end: Math.min(i + item_per_looping, total_item),
      });
    }
    this.props.set_value_insight({
      key: "current_grid_group",
      value: 0,
    });
    this.props.set_value_insight({
      key: "total_grid_group",
      value: group_per_looping.length,
    });
    const parent_function = () => {
      return group_per_looping.reduce(
        (last_promise, range, index) =>
          last_promise.then((result_sum) =>
            child_function(range, index).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    const child_function = async (range, index) => {
      return delay_promise().then(() => {
        const core_function = async () => {
          try {
            const items = features_grid_raw.slice(range.start, range.end);
            items.forEach((feature_grid) => {
              const grid_geojson = get_mid_point(
                feature_grid.geometry.coordinates[0] //Pass entire coordinates array
              );
              const geojson_filtered = glookup.getContainers(grid_geojson);
              if (geojson_filtered?.features?.length > 0) {
                //step 1: demography --> next interpolate by area grid vs area DESA
                const properties_demography =
                  geojson_filtered?.features?.[0]?.properties || {};
                //step 2: poi
                const geojson_poi_inside = points_within_polygon(
                  geojson_poi,
                  feature_grid
                );
                const features_poi_inside = geojson_poi_inside?.features || [];
                const ALL_POI_COUNT = geojson_poi_inside?.features?.length || 0;
                let properties_poi = {};
                /*
                 tipe_1_array,
                 tipe_2_array,
                 tipe_3_array,
                 tipe_level,
                 tipe_1_all,


                tipe_level === "tipe_1"
                TIPE_1: Number

                tipe_level === "tipe_2"
                TIPE_2, TIPE_1: Number

                tipe_level === "tipe_3"
                TIPE_3, TIPE_2, TIPE_1: Number

                separator: ", " 
                */
                if (tipe_level === "tipe_1" || tipe_1_all) {
                  tipe_1_array.forEach((item) => {
                    const features_poi_inside_type = features_poi_inside.filter(
                      (feature) => {
                        return feature.properties.TIPE_1 === item.TIPE_1;
                      }
                    );
                    const value = features_poi_inside_type.length;
                    const key = item.TIPE_1;
                    properties_poi[key] = value;
                  });
                } else if (tipe_level === "tipe_2") {
                  tipe_2_array.forEach((item) => {
                    const features_poi_inside_type = features_poi_inside.filter(
                      (feature) => {
                        return (
                          feature.properties.TIPE_1 === item.TIPE_1 &&
                          feature.properties.TIPE_2 === item.TIPE_2
                        );
                      }
                    );
                    const value = features_poi_inside_type.length;
                    const key = item.TIPE_2 + ", " + item.TIPE_1;
                    properties_poi[key] = value;
                  });
                } else if (tipe_level === "tipe_3") {
                  tipe_3_array.forEach((item) => {
                    const features_poi_inside_type = features_poi_inside.filter(
                      (feature) => {
                        return (
                          feature.properties.TIPE_1 === item.TIPE_1 &&
                          feature.properties.TIPE_2 === item.TIPE_2 &&
                          feature.properties.TIPE_3 === item.TIPE_3
                        );
                      }
                    );
                    const value = features_poi_inside_type.length;
                    const key =
                      item.TIPE_3 + ", " + item.TIPE_2 + ", " + item.TIPE_1;
                    properties_poi[key] = value;
                  });
                }
                feature_grid.properties = {
                  ...feature_grid.properties,
                  ...properties_demography,
                  ...properties_poi,
                  ALL_POI_COUNT,
                };
                this.props.push_value_insight({
                  key: "features_grid",
                  value: feature_grid,
                });
              }
            });
            this.props.set_value_insight({
              key: "current_grid_group",
              value: index + 1,
            });
          } catch (error) {}
        };
        return core_function();
      });
    };
    parent_function().then(() => {
      this.setState({ loading_status_grid: false });
      this.props.insight_update();
      if (this.props.insight_reducer.features_grid.length > 0) {
        this.props.set_value_insight({
          key: "grid_counts",
          value: this.props.insight_reducer.features_grid.length,
        });
        this.props.set_value_insight({
          key: "auto_step",
          value: 2,
        });
      }
    });
  };

  toggle_login = () => {
    const { modal_login } = this.props.auth;
    this.props.set_value_user("modal_login", !modal_login);
  };

  toggle_payment_universal = () => {
    const { modal_payment_universal } = this.props.auth;
    this.props.set_value_user(
      "modal_payment_universal",
      !modal_payment_universal
    );
  };

  on_set_mode_input = (mode_input) => {
    this.setState({ mode_input });
  };

  toggle_choose_poi = () => {
    this.setState({ modal_choose_poi: !this.state.modal_choose_poi });
  };

  toggle_payment = () => {
    const { modal_payment_universal } = this.props.auth;
    this.props.set_value_user(
      "modal_payment_universal",
      !modal_payment_universal
    );
  };

  on_get_insight = () => {
    const { isAuthenticated, quota_access } = this.props.auth;
    const quota_access_sini_ai = this.props.auth.quota_access_sini_ai || 0;
    const { license_user_status, license_group_status } =
      this.props.license_reducer;
    const payment_type = license_user_status?.payment_type;
    const logic_1 = !["license_1", "license_2", "license_3"].includes(
      payment_type
    );
    const logic_2 = !license_group_status?.is_has_license;
    const logic_3 = quota_access <= 0;
    const logic_4 = quota_access_sini_ai <= 0;
    if (!isAuthenticated) {
      this.toggle_login();
    } else if (logic_1 && logic_2 && logic_3 && logic_4) {
      this.toggle_payment_universal();
    } else {
      const {
        provinsi,
        kota,
        kecamatan,
        kelurahan,
        tipe_1_array,
        tipe_2_array,
        tipe_3_array,
        tipe_level,
        tipe_1_all,
      } = this.props.insight_reducer;
      const query = {
        request_id: uuid(),
        PROVINSI: provinsi,
        KABKOT: kota,
        KECAMATAN: kecamatan,
        DESA: kelurahan,
        tipe_1_array,
        tipe_2_array,
        tipe_3_array,
        tipe_level,
        tipe_1_all,
      };
      this.props.get_insight_v2(query);
    }
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { mode_input, modal_choose_poi } = this.state;

    //global props
    const {
      kota,
      total_poi,
      current_progress_poi,
      tipe_1_array,
      tipe_2_array,
      tipe_3_array,
      tipe_level,
      tipe_1_all,
      features_poi,
      current_grid_group,
      total_grid_group,
      total_save_feature,
      current_save_feature,
    } = this.props.insight_reducer;
    const { loading_item_array } = this.props.loading_reducer;

    //content
    let button_content;
    if (!kota) {
      button_content = (
        <div className="button background_grey">Generate insight</div>
      );
    } else if (loading_item_array.includes("get_insight_v2")) {
      button_content = (
        <div className="button background_grey">
          {dict?.["Loading"]?.[language]}
        </div>
      );
    } else {
      button_content = (
        <button
          className="button background_blue"
          onClick={this.on_get_insight}
          data-mapid="clickCari"
        >
          Generate insight
        </button>
      );
    }

    const modal_choose_poi_content = modal_choose_poi && (
      <Modal
        modalSize="full"
        is_hide="show"
        backgroundColor="rgba(255, 255, 255, 1);"
        use_blur={false}
        id="modal_choose_poi"
        isOpen={modal_choose_poi}
        onClose={this.toggle_choose_poi}
      >
        <div className="box-body">
          <INPUT_TIPE_FULL toggle_choose_poi={this.toggle_choose_poi} />
        </div>
      </Modal>
    );

    let input_area_content = <InputAreaSingle />;
    if (mode_input === "hirarki") {
      input_area_content = <InputAreaHirarki />;
    }

    return (
      <main className="container_light outline_transparent background_grey_light margin_bottom_extra">
        {modal_choose_poi_content}
        <div className="container_light background_white outline_transparent margin_bottom">
          <section className="margin_bottom">
            <button
              className={`button margin_right margin_bottom ${
                mode_input === "single_input"
                  ? "background_black"
                  : "background_grey"
              }`}
              onClick={this.on_set_mode_input.bind(this, "single_input")}
            >
              Pencarian
            </button>
            <button
              className={`button margin_right margin_bottom ${
                mode_input === "hirarki"
                  ? "background_black"
                  : "background_grey"
              }`}
              onClick={this.on_set_mode_input.bind(this, "hirarki")}
              data-mapid="clickAdminisTrasi"
            >
              Level Administrasi
            </button>
          </section>
          {input_area_content}
        </div>

        <section className="container_light background_white outline_transparent margin_bottom">
          <table className="full_width">
            <tbody>
              <tr>
                <td>
                  <p className="text_bold">
                    Pilih tema lokasi point of interest
                  </p>
                </td>
                <td className="text_right">
                  <img
                    className="cursor_pointer"
                    alt="expand"
                    src={expand}
                    style={{ height: "20px" }}
                    onClick={this.toggle_choose_poi}
                    data-mapid="clickExpand"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <INPUT_TIPE_SHORT />
          {tipe_level !== "tipe_1" && (
            <table className="table full_width">
              <tbody>
                <tr>
                  <td>Level yang dipilih</td>
                  <td>{tipe_1_all ? "ALL" : tipe_level}</td>
                </tr>
                {!tipe_1_all && (
                  <tr>
                    <td>Jumlah tipe yang dipilih</td>
                    <td>
                      {tipe_level === "tipe_1" ? (
                        <>{tipe_1_array.length}</>
                      ) : tipe_level === "tipe_2" ? (
                        <>{tipe_2_array.length}</>
                      ) : (
                        <>{tipe_3_array.length}</>
                      )}
                    </td>
                  </tr>
                )}
                {!tipe_1_all && (
                  <tr>
                    <td>Tipe yang dipilih</td>
                    <td>
                      <div style={{ maxHeight: "70px", overflowY: "auto" }}>
                        {tipe_level === "tipe_1" ? (
                          <>
                            {tipe_1_array.map((item, idx) => {
                              return (
                                <div
                                  className="text_small margin_right"
                                  key={idx}
                                >
                                  {item.TIPE_1},
                                </div>
                              );
                            })}
                          </>
                        ) : tipe_level === "tipe_2" ? (
                          <>
                            {tipe_2_array.map((item, idx) => {
                              return (
                                <div
                                  className="text_small margin_right"
                                  key={idx}
                                >
                                  {item.TIPE_2},
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            {tipe_3_array.map((item, idx) => {
                              return (
                                <div
                                  className="text_small margin_right"
                                  key={idx}
                                >
                                  {item.TIPE_3},
                                </div>
                              );
                            })}
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </section>

        <div className="container_light background_white outline_transparent margin_bottom">
          <INPUT_DEMOGRAPHY />
        </div>

        <section className="container_light background_white outline_transparent margin_bottom">
          {features_poi.length > 0 && (
            <div className="badge_small background_grey margin_bottom">
              Jumlah titik POI: {features_poi.length}
            </div>
          )}
          <ProgressBar
            current_number={current_progress_poi}
            total_number={total_poi}
            name="Memuat data"
          />
          <ProgressBar
            current_number={current_grid_group}
            total_number={total_grid_group}
            name="Membuat grid"
          />
          <ProgressBar
            current_number={current_save_feature}
            total_number={total_save_feature}
            name="Menyimpan sebagai layer"
          />
        </section>
        {button_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  map: state.map,
  layer: state.layer,
  payment: state.payment,
  properties: state.properties,
  sini: state.sini,
  license_reducer: state.license_reducer,
  insight_reducer: state.insight_reducer,
  loading_reducer: state.loading_reducer,
  project: state.project,
});

export default connect(mapStateToProps, {
  set_value_properties,
  set_value_user,
  get_insight_v2,
  set_value_layer,
  importLayer,
  set_value_insight,
  insight_update,
  push_value_insight,
})(INPUT_SIMPLE);
