import React, { Component } from "react";
import { Dropdown } from "../../../reuseable";
import Modal from "../../../common_modal/Modal";
import ColumnsSelectionChildTable from "../ColumnsSelectionChildTable";

export default class SetupTableC7DataLegal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_columns_selection: false,
    };
  }

  toggleModalColumnsSelection = () => {
    const { modal_columns_selection } = this.state;
    this.setState({
      modal_columns_selection: !modal_columns_selection,
    });
  };

  render() {
    const { fields, placeholder = "Pilih", body } = this.props;

    const { id, ...rest_props } = this.props;

    const { modal_columns_selection } = this.state;

    const modal_columns_selection_content = modal_columns_selection && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={modal_columns_selection}
        onClose={this.toggleModalColumnsSelection}
      >
        <table>
          <tbody>
            <tr>
              <td>
                <p>Jenis Dokumen</p>
              </td>
              <td>
                <ColumnsSelectionChildTable
                  id="c7_jenis_dokumen_pdf"
                  column_parent_uuid={body[id]}
                  {...rest_props}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p>No. Dokumen</p>
              </td>
              <td>
                <ColumnsSelectionChildTable
                  id="c7_no_dokumen_pdf"
                  column_parent_uuid={body[id]}
                  {...rest_props}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p>Atas Nama</p>
              </td>
              <td>
                <ColumnsSelectionChildTable
                  id="c7_atas_nama_pdf"
                  column_parent_uuid={body[id]}
                  {...rest_props}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p>Tgl Terbit</p>
              </td>
              <td>
                <ColumnsSelectionChildTable
                  id="c7_tgl_terbit_pdf"
                  column_parent_uuid={body[id]}
                  {...rest_props}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p>Tgl Akhir Hak</p>
              </td>
              <td>
                <ColumnsSelectionChildTable
                  id="c7_tgl_akhir_hak_pdf"
                  column_parent_uuid={body[id]}
                  {...rest_props}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p>No./Tgl GS/Su</p>
              </td>
              <td>
                <ColumnsSelectionChildTable
                  id="c7_no_tgl_gs_su_pdf"
                  column_parent_uuid={body[id]}
                  {...rest_props}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p>Luas</p>
              </td>
              <td>
                <ColumnsSelectionChildTable
                  id="c7_luas_pdf"
                  column_parent_uuid={body[id]}
                  {...rest_props}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p>Hubungan dengan Debitur</p>
              </td>
              <td>
                <ColumnsSelectionChildTable
                  id="c7_hubungan_dengan_debitur_pdf"
                  column_parent_uuid={body[id]}
                  {...rest_props}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p>Pengurangan tata kota</p>
              </td>
              <td>
                <ColumnsSelectionChildTable
                  id="c7_pengurangan_tata_kota_pdf"
                  column_parent_uuid={body[id]}
                  {...rest_props}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Modal>
    );

    return (
      <div>
        <table className="table_pdf side_border">
          <thead>
            <tr style={{ height: "16pt" }}>
              <td colSpan={8}>
                <div className="flex">
                  <p className="subtitle_pdf">Informasi Dokumen</p>
                  <Dropdown
                    className="w_100"
                    placeholder={placeholder}
                    filter_on
                    is_enable_empty
                    value={body?.[id] || ""}
                    onChange={(value) => {
                      if (this.props.onChange) {
                        this.props.onChange(id, value);
                      }
                    }}
                  >
                    {fields &&
                      fields.map((field) => (
                        <option key={field.key} value={field.key}>
                          {field.name}
                        </option>
                      ))}
                  </Dropdown>
                  {body?.["c7_setup_data_legal_pdf"] ? (
                    <button
                      onClick={() => {
                        this.toggleModalColumnsSelection();
                      }}
                      className="button background_blue"
                    >
                      Fill the Table
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <p>No.</p>
              </td>
              <td>
                <p>Jenis Dokumen</p>
              </td>
              <td>
                <p>No. Dokumen</p>
              </td>
              <td>
                <p>Atas Nama</p>
              </td>
              <td>
                <p>Tgl Terbit</p>
              </td>
              <td>
                <p>Tgl Akhir hak</p>
              </td>
              <td>
                <p>No./Tgl GS/SU</p>
              </td>
              <td>
                <p>Luas</p>
              </td>
              <td>
                <p>Hubungan dengan Debitur</p>
              </td>
            </tr>
          </thead>
        </table>
        {modal_columns_selection_content}
      </div>
    );
  }
}
