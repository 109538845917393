import React, { Component } from "react";
import { connect } from "react-redux";
class ICON_EXPAND extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.props.width || 10}
        height={this.props.height || 10}
        viewBox="0 0 3.925 3.925"
        className="margin_left"
      >
        <path
          d="m443.56 84.139 1.323 1.323 1.323-1.323z"
          style={{
            fillRule: "evenodd",
            fill: this.props.fill ? this.props.fill : "#000",
            paintOrder: "stroke markers fill",
            stroke: this.props.stroke ? this.props.stroke : "#000",
          }}
          transform="translate(-442.92 -82.838)"
        />
      </svg>
    );
  }
}
const mapStateToProps = () => ({});
export default connect(mapStateToProps, {})(ICON_EXPAND);
