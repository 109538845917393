/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/
import {
  delete_folder_project,
  get_all_folder_project_list_by_user_id,
} from "../../App/actions/folderProjectAction";

/*PICTURE*/

/*FUNCTION*/
import get_child_nested from "../../App/validation/get_child_nested";

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class DELETE_FOLDER_PROJECT extends Component {
  state = {};

  componentDidMount() {
    this.props.get_all_folder_project_list_by_user_id();
  }

  on_delete_folder_project = () => {
    const folder_id = this?.props?.folder?._id;
    const { folder_project_child_list } = this.props.project;
    const key =
      folder_project_child_list.length > 0
        ? "folder_project_child_list"
        : "folder_project_list";
    const body = { folder_id, key };
    this.props.delete_folder_project(body);
    this.props.toggle_delete_folder();
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const { folder_project_list_all } = this.props.project;
    const folder = this.props.folder;

    //content
    let child_list = get_child_nested({
      parent_value: folder?._id,
      parent_key: "folder_parent_id",
      all_list: folder_project_list_all,
      result_key: "_id",
    });

    child_list = child_list.slice(1, child_list.length);

    return (
      <main>
        <h2 className="margin_bottom">{dict?.["Delete folder"]?.[language]}</h2>
        <br />
        <p className="margin_bottom">
          {
            dict?.[
              "Are you sure you want to delete this folder? For security reasons, all projects inside the folder will not be deleted but will be outside the folder."
            ]?.[language]
          }
        </p>
        <br />
        <section
          className="container_light"
          style={{ maxHeight: "150px", overflowY: "auto" }}
        >
          <p>{dict?.["Folder name"]?.[language]}</p>
          <h4>{folder?.name}</h4>
          <br />
          <p>{dict?.["Subfolder to be deleted"]?.[language]}</p>
          {child_list.length === 0 ? (
            <h4>-</h4>
          ) : (
            <>
              {child_list.map((item, idx) => {
                const folder_child = folder_project_list_all?.find(
                  (e) => e?._id === item
                );
                return <h4 key={idx}>{folder_child?.name}</h4>;
              })}
            </>
          )}
        </section>
        <br />
        <button
          className="button background_red margin_top"
          onClick={this.on_delete_folder_project}
        >
          {dict?.["Delete"]?.[language]}
        </button>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
});

export default connect(mapStateToProps, {
  delete_folder_project,
  get_all_folder_project_list_by_user_id,
})(DELETE_FOLDER_PROJECT);
