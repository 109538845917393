/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

/*COMPONENT*/
import Modal from "../common_modal/Modal";

/*REDUX*/
import { createProjectNew } from "../../App/actions/layerNewActions";

/*PICTURE*/
import checklist from "../../Assets/svg/checklist.svg";

/*FUNCTION*/
import isEmpty from "../../App/validation/is-empty";
import formatDate from "../../App/validation/format-date";

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class CREATE_PROJECT extends Component {
  state = {
    name: "",
    description: "",
    user_id: this.props.auth.user._id,
    isPublic: false,
    isPersonal: true,
    modalGroup: false,
    modalLicense: false,
    modalWarningNotAdmin: false,
    group: {},
  };

  toggleWarningNotAdmin = () => {
    this.setState({ modalWarningNotAdmin: !this.state.modalWarningNotAdmin });
  };

  toggleLicense = () => {
    this.setState({ modalLicense: !this.state.modalLicense });
  };

  setPersonal = () => {
    this.setState({ isPersonal: true });
  };

  toggleGroup = () => {
    this.setState({ modalGroup: !this.state.modalGroup });
  };

  setGroup = (group) => {
    this.setState({ group, isPersonal: false }, () => {
      setTimeout(() => {
        this.toggleGroup();
      }, 500);
    });
  };

  handleChange = (e) => {
    e.preventDefault();
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { folder_object_selected_list } = this.props.project;

    const { name, description, isPublic, isPersonal, group } = this.state;
    const { user, domain } = this.props.auth;
    const { folder_id_selected } = this.props.project;
    const isOrg = !isPersonal && !isEmpty(group);
    const group_id = isOrg ? group._id : "";
    const user_id = isOrg ? group.creator_id : user._id;

    const key =
      folder_object_selected_list.length > 0
        ? "geo_project_list_child"
        : "geo_project_list";

    const body = {
      key,
      date: Date.now(),
      name,
      description,
      user_id,
      isPublic,
      group_id,
      domain,
      folder_id_selected,
    };
    this.props.createProjectNew(body);
    this.props.toggle_create_project();
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const {
      name,
      description,
      modalGroup,
      group,
      isPersonal,
      modalLicense,
      modalWarningNotAdmin,
    } = this.state;

    //global props
    const { groups, user } = this.props.auth;
    const { loading_status, loading_item } = this.props.layer;
    const { folder_object_selected } = this.props.project;

    //content
    const isOrg = !isPersonal && !isEmpty(group);

    let button_content;

    if (loading_status && loading_item === "create_project") {
      button_content = (
        <div className="button background_grey">
          {dict["Processing..."][language]}
        </div>
      );
    } else {
      button_content = (
        <button
          type="submit"
          className="button background_blue"
          data-mapid="clickCreateProject"
        >
          {dict["Create Project"][language]}
        </button>
      );
    }

    const content = (
      <div>
        <h2 className="margin_bottom">
          {dict?.["Create a new project"]?.[language]}
        </h2>
        {folder_object_selected?._id && (
          <section className="container_light background_grey_light margin_bottom">
            <p>
              {dict?.["In a folder"]?.[language]}: <br /> <br />
              <b className="text_medium">{folder_object_selected?.name}</b>
            </p>
          </section>
        )}
        <form onSubmit={this.handleSubmit.bind(this)} className="margin_top">
          <input
            type="text"
            name="name"
            id="name"
            placeholder={dict["Project name"][language]}
            value={name}
            onChange={this.handleChange}
            className="input_square"
            data-mapid="insertProjectName"
          />
          <textarea
            type="text"
            name="description"
            className="input_square"
            id="description"
            placeholder={dict["Project description"][language]}
            value={description}
            onChange={this.handleChange}
            rows="4"
            data-mapid="insertProjectDeskription"
          />
          <div
            className={`button margin_bottom margin_right ${
              !isOrg ? "background_blue" : "background_grey"
            }`}
            onClick={this.setPersonal}
            style={{ width: "150px", maxWidth: "40%", marginRight: "10px" }}
          >
            Personal
          </div>
          <div
            className={`button margin_bottom margin_right ${
              isOrg ? "background_blue" : "background_grey"
            }`}
            onClick={this.toggleGroup}
            style={{ width: "150px", maxWidth: "40%" }}
          >
            Team
          </div>
          <br />
          <br />
          {button_content}
        </form>
      </div>
    );

    const modalGroupContent = modalGroup && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modalGroup}
        onClose={this.toggleGroup}
      >
        <div
          className="box-body"
          style={{ minHeight: "80vh", textAlign: "center" }}
        >
          <div style={{ textAlign: "center" }}>
            {dict["Choose your group or team"][language]}{" "}
          </div>
          {groups.map((group_element, idx) => {
            const { name, _id, members, payment } = group_element;
            let organizationContent = null;
            if (!isEmpty(payment)) {
              const { date_in } = payment;
              const multiple_month = payment.multiple_month
                ? payment.multiple_month
                : 1;
              //86400 unix = 1 day
              //1 month = 30.5 day
              const month_unix = 86400 * 30.5;
              const day_unix = 86400;
              const date_finish_unix =
                parseInt((new Date(date_in).getTime() / 1000).toFixed(0)) +
                month_unix * multiple_month;
              const date_now_unix = parseInt(
                (new Date(Date.now()).getTime() / 1000).toFixed(0)
              );
              const delta_unix =
                (date_finish_unix - date_now_unix) * multiple_month;
              const delta_day = parseInt(delta_unix / day_unix);
              const date_finish = new Date(date_finish_unix * 1000);
              if (delta_day > 0) {
                if (delta_day > 30) {
                  organizationContent = (
                    <div className="badge_normal" id="grey_light">
                      <b>
                        {dict["Team license active until"][language]}{" "}
                        {formatDate(date_finish)}
                      </b>
                    </div>
                  );
                } else {
                  organizationContent = (
                    <div className="badge_normal" id="grey_light">
                      <b>
                        {dict["Team license active for"][language]}
                        {delta_day}
                        {dict["days"][language]}
                      </b>
                    </div>
                  );
                }
              } else {
                organizationContent = (
                  <div className="badge_normal" id="grey_light">
                    <b>{dict["Team license exp."][language]}</b>
                  </div>
                );
              }
            }
            let userStatus = "viewer";
            const array_member_filtered = members.filter(
              (member) => member?.user?._id === user._id
            )[0];
            userStatus = array_member_filtered?.status;
            let checkContent;
            if (_id === group._id) {
              checkContent = (
                <img alt="checklist" src={checklist} width="11px" />
              );
            }
            let button_content = (
              <main
                className="container_basic"
                style={{
                  display: "inline-block",
                  margin: "10px",
                  width: "150px",
                  minHeight: "120px",
                  fontSize: "12px",
                  cursor: "pointer",
                  overflow: "hidden",
                  backgroundColor: "#d3d3d3",
                  color: "#fff",
                  borderRadius: "10px",
                  position: "relative",
                  verticalAlign: "top",
                }}
                onClick={this.toggleWarningNotAdmin}
                key={_id}
              >
                <section
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxHeight: "60px",
                    width: "95px",
                    overflow: "hidden",
                    fontWeight: "bold",
                  }}
                >
                  <div style={{ overflow: "hidden", maxHeight: "45px" }}>
                    {name}
                  </div>
                </section>
                {organizationContent}
              </main>
            );
            if (userStatus === "creator" || userStatus === "admin") {
              button_content = (
                <main
                  className="container_basic"
                  style={{
                    display: "inline-block",
                    margin: "10px",
                    width: "150px",
                    minHeight: "120px",
                    fontSize: "12px",
                    cursor: "pointer",
                    overflow: "hidden",
                    backgroundColor: "#0ca5eb",
                    color: "#fff",
                    borderRadius: "10px",
                    position: "relative",
                    verticalAlign: "top",
                  }}
                  onClick={this.setGroup.bind(this, group_element)}
                  key={_id}
                >
                  <section
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      maxHeight: "60px",
                      width: "95px",
                      overflow: "hidden",
                      fontWeight: "bold",
                    }}
                  >
                    <div style={{ overflow: "hidden", maxHeight: "45px" }}>
                      {name}
                    </div>{" "}
                    {checkContent}
                  </section>
                  {organizationContent}
                </main>
              );
            }
            return button_content;
          })}
        </div>
      </Modal>
    );

    const modalLicenseContent = modalLicense && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modalLicense}
        onClose={this.toggleLicense}
      >
        <div className="box-body">
          <main
            style={{
              marginTop: "100px",
              marginBottom: "100px",
              textAlign: "center",
            }}
          >
            <section className="text_medium" style={{ marginBottom: "20px" }}>
              {
                dict[
                  "You do not own your license or your license has expired, please renew your license."
                ][language]
              }
            </section>
            <Link to="/user_profile" className="button_block background_blue">
              {dict["Renew License"][language]}
            </Link>
          </main>
        </div>
      </Modal>
    );

    const modalWarningNotAdminContent = modalWarningNotAdmin && (
      <Modal
        modalSize="small"
        id="warning modal"
        isOpen={modalWarningNotAdmin}
        onClose={this.toggleWarningNotAdmin}
      >
        <div className="box-body" style={{ textAlign: "center" }}>
          <main className="text_bold">
            {
              dict[
                "You are only viewer in this group, only admin can add group to the project."
              ][language]
            }
          </main>
        </div>
      </Modal>
    );

    return (
      <main>
        {content}
        {modalGroupContent}
        {modalLicenseContent}
        {modalWarningNotAdminContent}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  project: state.project,
  payment: state.payment,
  layer: state.layer,
});

export default connect(mapStateToProps, { createProjectNew })(CREATE_PROJECT);
