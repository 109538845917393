import uuid from "../../App/validation/uuid";
import calculate_length from "@turf/length";
import calculate_area from "@turf/area";
import centroid from "@turf/centroid";
import along from "@turf/along";
import st_colors from "../../Data/st_colors.json";

const on_draw_toolbox = ({
  event,
  toolbox,
  set_value_toolbox,
  generate_elevation,
  push_value_toolbox,
  generate_isokron,
  status_toolbox,
}) => {
  const { mode_toolbox } = toolbox;
  const geojson = event;
  let feature = geojson?.features?.[0];
  feature.properties.uuid = uuid();
  let uuid_name;

  if (mode_toolbox === "features_distance") {
    let {
      features_distance,
      features_distance_label,
      features_distance_center,
    } = toolbox;
    uuid_name = "uuid_distance";
    const length_km = calculate_length(feature, {
      units: "kilometers",
    }).toFixed(2);
    const length_m = length_km * 1000;
    const name = `D-${features_distance.length + 1}`;
    feature.properties.name = name;
    feature.properties.length_km = length_km;
    feature.properties.length_m = length_m;
    features_distance.push(feature);
    const center_length = length_km / 2;
    let point = along(feature, center_length, { units: "kilometers" });
    point.properties.uuid = feature.properties.uuid;
    point.properties.name = name;
    const coordinates_a = feature.geometry.coordinates[0];
    const coordinates_b =
      feature.geometry.coordinates[feature.geometry.coordinates.length - 1];
    const features_label = [
      {
        type: "Feature",
        properties: { name: "A", parent_uuid: feature.properties.uuid },
        geometry: { type: "Point", coordinates: coordinates_a },
      },
      {
        type: "Feature",
        properties: { name: "B", parent_uuid: feature.properties.uuid },
        geometry: { type: "Point", coordinates: coordinates_b },
      },
    ];
    features_distance_label = [...features_distance_label, ...features_label];
    features_distance_center.push(point);
    set_value_toolbox({ key: "features_distance", value: features_distance });
    set_value_toolbox({
      key: "features_distance_label",
      value: features_distance_label,
    });
    set_value_toolbox({
      key: "features_distance_center",
      value: features_distance_center,
    });
  } else if (mode_toolbox === "features_area") {
    let { features_area, features_area_center } = toolbox;
    uuid_name = "uuid_area";
    const area_meter_square = calculate_area(feature).toFixed(0);
    const area_hectare = parseFloat((area_meter_square / 10_000).toFixed(2));
    const name = `A-${features_area.length + 1}`;
    feature.properties.area_meter_square = area_meter_square;
    feature.properties.area_hectare = area_hectare;
    feature.properties.name = name;
    features_area.push(feature);
    const geojson = { type: "FeatureCollection", features: [feature] };
    let point = centroid(geojson);
    point.properties.uuid = feature.properties.uuid;
    point.properties.name = name;
    features_area_center.push(point);
    set_value_toolbox({ key: "features_area", value: features_area });
    set_value_toolbox({
      key: "features_area_center",
      value: features_area_center,
    });
  } else if (mode_toolbox === "features_elevation") {
    let { features_elevation, features_elevation_center } = toolbox;
    uuid_name = "uuid_elevation";
    const length_km = calculate_length(feature, {
      units: "kilometers",
    }).toFixed(2);
    const length_m = length_km * 1000;
    const name = `E-${features_elevation.length + 1}`;
    feature.properties.name = name;
    feature.properties.length_km = length_km;
    feature.properties.length_m = length_m;
    const center_length = length_km / 2;
    let point = along(feature, center_length, { units: "kilometers" });
    point.properties.uuid = feature.properties.uuid;
    point.properties.name = name;
    features_elevation.push(feature);
    features_elevation_center.push(point);
    set_value_toolbox({ key: "features_elevation", value: features_elevation });
    set_value_toolbox({
      key: "features_elevation_center",
      value: features_elevation_center,
    });
    generate_elevation(feature);
  } else if (mode_toolbox === "features_radius") {
    let { default_radius_km, default_radius_m, features_radius } = toolbox;
    uuid_name = "uuid_radius";
    const name = `R-${features_radius.length + 1}`;
    feature.properties.name = name;
    feature.properties.radius_km = default_radius_km;
    feature.properties.radius_m = default_radius_m;
    features_radius.push(feature);
    set_value_toolbox({ key: "features_radius", value: features_radius });
  } else if (mode_toolbox === "features_isokron") {
    const {
      routing_profile,
      contour_type,
      contours_minutes,
      contours_meters,
      features_isokron_center,
    } = toolbox;
    const isokron_index = features_isokron_center.length;
    uuid_name = "uuid_isokron";
    const name = `I-${isokron_index + 1}`;
    feature.properties.name = name;
    feature.properties.routing_profile = routing_profile;
    feature.properties.contour_type = contour_type;
    feature.properties.contours_minutes = contours_minutes;
    feature.properties.contours_meters = contours_meters;
    feature.properties.color = st_colors[isokron_index % st_colors.length];
    feature.properties.center = feature.geometry.coordinates;
    push_value_toolbox({ key: "features_isokron_center", value: [feature] });
    generate_isokron(feature);
  }

  set_value_toolbox({ key: "mode_draw", value: "simple_select" });
  set_value_toolbox({ key: uuid_name, value: feature.properties.uuid });
  status_toolbox();
};

export default on_draw_toolbox;
