/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import SpinnerSimpleBlue from "../common_spinner/SpinnerSimpleBlue";

/*REDUX FUNCTION*/
import { pushFolder } from "../../App/actions/projectActions";

/*PICTURE ASSET*/

/*GENERAL*/
import dict from "../../Data/dict.json";

class CreateFolder extends Component {
  state = {
    name: "",
  };

  on_change(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  on_submit = (e) => {
    e.preventDefault();
    const { geo_project } = this.props.project;
    const { name } = this.state;
    const parent_folder = this.props.parent_folder;

    const body = {
      geo_project_id: geo_project._id,
      name,
      isPublic: false,
      parent_folder,
    };
    this.props.pushFolder(body);

    if (this.props.from_modal) {
      this.props.toggle_create_sub_folder();
    }
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const { name } = this.state;

    //global props
    const { loadingProcess, geo_project } = this.props.project;
    const parent_folder = this.props.parent_folder;

    //content
    const folders = geo_project?.folders || [];
    const folder = folders.find((item) => item?._id === parent_folder);

    let button_content;
    if (loadingProcess) {
      button_content = (
        <SpinnerSimpleBlue
          width={34}
          unik="loading_create_layer"
          marginTop="0px"
          border={0.2}
        />
      );
    } else {
      button_content = (
        <button
          type="submit"
          className="button background_blue"
          data-mapid="clickCreateFolder"
        >
          {dict["Create Folder"][language]}
        </button>
      );
    }
    const content = (
      <main>
        {this.props.parent_folder && (
          <div className="margin_bottom">
            <h1 className="text_header">Buat sub folder</h1>
            <br />
            <p className="text_small">Parent folder : {folder?.name}</p>
          </div>
        )}
        <form onSubmit={this.on_submit.bind(this)}>
          <input
            type="text"
            placeholder={dict["Folder name"][language]}
            autoComplete="off"
            className="input_new"
            name="name"
            value={name}
            onChange={this.on_change.bind(this)}
            data-mapid="insertFolderName"
          />
          <div>{button_content}</div>
        </form>
      </main>
    );

    return <main>{content}</main>;
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  layer: state.layer,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  pushFolder,
})(CreateFolder);
