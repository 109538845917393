/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import Modal from "../common_modal/Modal";

/*REDUX*/
import {
  edit_inside_object,
  set_value_bi,
  push_group_card,
  edit_group_card,
} from "../../App/actions/biActions";

/*PICTURE*/

/*FUNCTION*/

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class BI_ADD_GROUP_CARD extends Component {
  state = {
    modal_warn: false,
  };

  toggle_warn = () => {
    this.setState({ modal_warn: !this.state.modal_warn });
  };

  on_change_group_card_add_object = (e) => {
    const id_key = e.target.name;
    const value = e.target.value;
    this.props.edit_inside_object({
      key: "group_card_add_object",
      id_key: id_key,
      value: value,
    });
  };

  on_push_card_id = (card_id) => {
    const { group_card_add_object } = this.props.bi;
    let { card_id_list } = group_card_add_object;
    if (card_id_list.includes(card_id)) {
      card_id_list = card_id_list.filter((item) => item !== card_id);
    } else {
      card_id_list.push(card_id);
    }
    this.props.edit_inside_object({
      key: "group_card_add_object",
      id_key: "card_id_list",
      value: card_id_list,
    });
  };

  on_save = () => {
    const { group_card_add_object, bi_object, project_object_selected } =
      this.props.bi;
    const { name, card_id_list } = group_card_add_object;
    if (!name) {
      this.setState({ modal_warn: true });
    } else {
      const body = {
        _id: bi_object._id,
        group_card_id: group_card_add_object?._id,
        name,
        project_id: project_object_selected._id,
        card_id_list,
      };
      if (group_card_add_object?._id) {
        this.props.edit_group_card(body);
      } else {
        this.props.push_group_card(body);
      }
      this.props.set_value_bi({
        key: "modal_add_group_card",
        value: !this.props.bi.modal_add_group_card,
      });
    }
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { modal_warn } = this.state;

    //global props
    const { group_card_add_object, bi_object, project_object_selected } =
      this.props.bi;

    //content
    const { name, card_id_list } = group_card_add_object;
    let card_list = bi_object?.card_list || [];
    card_list = card_list.filter(
      (item) => item?.project_id === project_object_selected?._id
    );

    const modal_warn_content = modal_warn && (
      <Modal
        modalSize="small"
        isOpen={modal_warn}
        onClose={this.toggle_warn}
        backgroundColor="rgb(235, 235, 235)"
      >
        <div className="box-body" id="box-body">
          {dict?.["Fill in the group name first"]?.[language]}
        </div>
      </Modal>
    );

    return (
      <main>
        {modal_warn_content}
        <h1 className="text_bold margin_bottom">
          {group_card_add_object?._id
            ? dict?.["Edit group"]?.[language]
            : dict?.["Add group"]?.[language]}
        </h1>
        <section className="container_light margin_bottom background_white">
          <p className="text_medium">{dict?.["Group name"]?.[language]}</p>
          <input
            type="text"
            value={name}
            name="name"
            onChange={this.on_change_group_card_add_object}
            placeholder={dict?.["Group name"]?.[language]}
          />
        </section>
        <section className="container_light margin_bottom background_white">
          <p className="text_medium">
            {dict?.["Select the data to be grouped"]?.[language]}
          </p>
          <div
            style={{
              maxHeight: "calc( 80vh - 250px )",
              overflowY: "auto",
            }}
          >
            <table>
              <tbody>
                {card_list.map((item, idx) => {
                  return (
                    <tr
                      key={idx}
                      onClick={this.on_push_card_id.bind(this, item?._id)}
                    >
                      <td>
                        <div
                          className={
                            card_id_list.includes(item?._id)
                              ? "checklist_active"
                              : "checklist_not"
                          }
                        />
                      </td>
                      <td>{item?.name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </section>
        <button
          className={`button outline_black ${
            name ? "background_blue" : "background_grey"
          }`}
          onClick={this.on_save}
        >
          {dict?.["Save"]?.[language]}
        </button>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
});

export default connect(mapStateToProps, {
  edit_inside_object,
  set_value_bi,
  push_group_card,
  edit_group_card,
})(BI_ADD_GROUP_CARD);
