/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import Modal from "../common_modal/Modal";
import COLOR_SCHEME_EDITOR from "./COLOR_SCHEME_EDITOR";
import DEFAULT_STYLE_EDITOR from "./DEFAULT_STYLE_EDITOR";
import RANGE_TEXT from "./RANGE_TEXT";
import RANGE_NUMBER_STEP from "./RANGE_NUMBER_STEP";
import RANGE_NUMBER_GRADIENT from "./RANGE_NUMBER_GRADIENT";
import RANGE_CONSTANT from "./RANGE_CONSTANT";
import RANGE_USE_EXISTING from "./RANGE_USE_EXISTING";

/*REDUX*/
import {
  set_value_properties,
  push_style_array,
  set_style_array,
} from "../../App/actions/propertiesActions";
import { status_action } from "../../App/actions/mapActions";

/*PICTURE*/

/*FUNCTION*/
import generate_uuid from "../../App/validation/generate_uuid";
import { generate_paint } from "../../Functions/map_style/generate_paint";

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class EDIT_LAYER_STYLE_3 extends Component {
  state = {
    color_logic_state: "",
    modal_error: false,
  };

  componentDidMount() {
    this.setState({
      color_logic_state: this.props.properties.style_parameter.color_logic,
    });
  }

  toggle_error = () => {
    this.setState({ modal_error: !this.state.modal_error });
  };

  on_regenerate_paint_color_logic = () => {
    const { color_logic_state } = this.state;
    const { style_parameter, paint_object_edited } = this.props.properties;
    const { case_recomendation } = style_parameter;

    /*
    kasus-kasus yang tidak direkomendasikan:
    1. case_recomendation === "text" && ["by_number_step", "by_number_gradient"].includes(color_logic_state)
    2. case_recomendation !== "color" && color_logic_state === "use_existing"
    3. 
    */

    if (
      (case_recomendation === "text" &&
        ["by_number_step", "by_number_gradient"].includes(color_logic_state)) ||
      (case_recomendation !== "color" && color_logic_state === "use_existing")
    ) {
      this.setState({ modal_error: true });
    } else {
      style_parameter.color_logic = color_logic_state;
      this.props.set_value_properties({
        key: "style_parameter",
        value: style_parameter,
      });
      const style_parameter_new = {
        ...style_parameter,
        paint_object_edited,
      };
      const paint_object_new = generate_paint(style_parameter_new);
      this.props.set_value_properties({
        key: "paint_object_edited",
        value: paint_object_new,
      });
      this.props.status_action();
    }
  };

  on_change = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    const type = e.target.type;
    if (type === "number" || type === "range") {
      value = Number(value);
    }
    if (name === "color_logic_state") {
      this.setState({ [name]: value });
    } else {
      const { style_parameter } = this.props.properties;
      style_parameter[name] = value;
      this.props.set_value_properties({
        key: "style_parameter",
        value: style_parameter,
      });
    }
  };

  on_save = () => {
    const { layer_id } = this.props.layer;
    const { paint_object_edited, style_parameter } = this.props.properties;
    if (!!style_parameter?.key) {
      //edit
      const body = {
        geo_layer_id: layer_id,
        style_object: {
          ...style_parameter,
          paint_object: paint_object_edited,
        },
      };

      this.props.set_style_array(body);
    } else {
      delete style_parameter._id;
      //create new
      const body = {
        geo_layer_id: layer_id,
        style_object: {
          ...style_parameter,
          key: generate_uuid(),
          paint_object: paint_object_edited,
        },
      };

      this.props.push_style_array(body);
    }
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { color_logic_state, modal_error } = this.state;

    //global props
    const { style_parameter } = this.props.properties;
    const { loading_item_array } = this.props.loading_reducer;

    //content
    const { case_recomendation } = style_parameter;
    const color_logic_array = [
      {
        value: "by_text",
        name: "by_text",
      },
      {
        value: "by_number_step",
        name: "by_number_step",
      },
      {
        value: "by_number_gradient",
        name: "by_number_gradient",
      },
      {
        value: "constant",
        name: "constant",
      },
      {
        value: "use_existing",
        name: "use_existing",
      },
    ];
    const { color_logic } = style_parameter;
    let content;
    switch (color_logic) {
      case "constant":
        content = <RANGE_CONSTANT />;
        break;
      case "use_existing":
        content = <RANGE_USE_EXISTING />;
        break;
      case "by_text":
        content = <RANGE_TEXT />;
        break;
      case "by_number_step":
        content = <RANGE_NUMBER_STEP />;
        break;
      case "by_number_gradient":
        content = <RANGE_NUMBER_GRADIENT />;
        break;
      default:
        break;
    }

    /*
    kasus-kasus yang tidak direkomendasikan:
    1. case_recomendation === "text" && ["by_number_step", "by_number_gradient"].includes(color_logic_state)
    2. case_recomendation !== "color" && color_logic_state === "use_existing"
    3. 
    */

    let error_content;

    if (
      case_recomendation === "text" &&
      ["by_number_step", "by_number_gradient"].includes(color_logic_state)
    ) {
      error_content = (
        <main>
          <h1 className="text_bold margin_bottom">
            {
              dict?.[
                "The data in this field is text, it is not recommended to use to use numeric classification"
              ]?.[language]
            }
          </h1>
          <br />
          <button
            className="button background_blue"
            onClick={this.toggle_error}
          >
            {dict?.["Close"]?.[language]}
          </button>
        </main>
      );
    } else if (
      case_recomendation !== "color" &&
      color_logic_state === "use_existing"
    ) {
      error_content = (
        <main>
          <h1 className="text_bold margin_bottom">
            {
              dict?.[
                "The data in this column is not color, please select the color column if you want to use this classification mode."
              ]?.[language]
            }
          </h1>
          <br />
          <button
            className="button background_blue"
            onClick={this.toggle_error}
          >
            {dict?.["Close"]?.[language]}
          </button>
        </main>
      );
    } else {
      error_content = (
        <main>
          <h1 className="text_bold margin_bottom">
            {
              dict?.[
                "The data in the column does not match the recommendation, to avoid errors please select another classification mode."
              ]?.[language]
            }
          </h1>
          <br />
          <button
            className="button background_blue"
            onClick={this.toggle_error}
          >
            {dict?.["Close"]?.[language]}
          </button>
        </main>
      );
    }

    const modal_error_content = modal_error && (
      <Modal modalSize="small" isOpen={modal_error} onClose={this.toggle_error}>
        <div className="box-body" id="box-body">
          {error_content}
        </div>
      </Modal>
    );

    return (
      <main className="container_light margin_bottom outline_black">
        {modal_error_content}
        <h1 className="text_bold margin_bottom">
          {dict?.["Style editor:"]?.[language]}
        </h1>
        <section className="container_light background_grey_light margin_bottom">
          <p className="text_medium margin_bottom">
            {dict?.["Color classification mode"]?.[language]}
          </p>
          <select
            name="color_logic_state"
            value={color_logic_state}
            onChange={this.on_change}
          >
            {color_logic_array.map((item, index) => {
              return (
                <option key={index} value={item.value}>
                  {item.name}
                </option>
              );
            })}
          </select>
          <button
            className="button_small background_blue"
            onClick={this.on_regenerate_paint_color_logic}
          >
            {dict?.["Process the change"]?.[language]}
          </button>
        </section>
        <section className="container_light background_grey_light margin_bottom">
          {["by_text", "by_number_step", "by_number_gradient"].includes(
            color_logic
          ) && <COLOR_SCHEME_EDITOR />}
          {content}
        </section>
        <DEFAULT_STYLE_EDITOR />
        {loading_item_array.includes("push_style_array") ? (
          <button className="button margin_top background_grey">
            {dict?.["Saving"]?.[language]}...
          </button>
        ) : (
          <button
            className="button margin_top background_blue"
            onClick={this.on_save}
          >
            {style_parameter?.key
              ? dict?.["Save changes"]?.[language]
              : dict?.["Create a new color classification"]?.[language]}
          </button>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  properties: state.properties,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, {
  set_value_properties,
  push_style_array,
  set_style_array,
  status_action,
})(EDIT_LAYER_STYLE_3);
