/* LIBRARY MODULE */
import React, { Component } from "react";
import ReactDOMServer from "react-dom/server";
import { connect } from "react-redux";
import maplibregl from "maplibre-gl";
import centroid from "@turf/centroid";
import along from "@turf/along";
import calculate_length from "@turf/length";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class BI_MARKER extends Component {
  state = {
    markers: [],
  };

  componentDidMount() {
    this.on_render();
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.bi.trigger_rerender !== prevProps.bi.trigger_rerender ||
        this.props.layer.map_object !== prevProps.layer.map_object) &&
      this.props.layer.map_object
    ) {
      this.on_render();
    }
  }

  on_render = () => {
    const { project_list, bi_object, project_object_selected, layer_id } =
      this.props.bi;
    const { map_object } = this.props.layer;
    if (map_object) {
      this.cleanup_markers();
      const geo_layer_list = this.get_geo_layers(
        project_list,
        bi_object,
        project_object_selected,
        layer_id
      );
      geo_layer_list.forEach((data) => {
        const type = data.geo_layer?.type;
        const features_filtered = data.geo_layer?.geojson?.features || [];
        const label_config = data.geo_layer?.label_config || {};

        if (
          label_config?.is_use_label_text ||
          label_config?.is_use_label_image
        ) {
          let features_center_marker = this.calculate_marker_centers(
            type,
            features_filtered
          );

          this.render_label_markers({
            features_center_marker,
            label_config,
            fields: data.geo_layer.fields,
            map_object,
            type,
            visibility: "visible",
          });
        }
      });
    }
  };

  calculate_marker_centers = (type, features_filtered) => {
    let features_center_marker = [];
    if (
      ["Polygon", "MultiPolygon", "MultiLineString", "MultiPoint"].includes(
        type
      )
    ) {
      features_center_marker = features_filtered.map((item) => {
        let feature = {};
        const geojson = {
          type: "FeatureCollection",
          features: [item],
        };
        feature = centroid(geojson, { properties: item.properties });
        return feature;
      });
    } else if (type === "Point") {
      features_center_marker = features_filtered;
    } else if (type === "LineString") {
      features_center_marker = features_filtered.map((item) => {
        const length_km = calculate_length(item, {
          units: "kilometers",
        }).toFixed(2);
        const center_length = length_km / 2;
        let point = along(item, center_length, { units: "kilometers" });
        point.properties = item.properties;
        return point;
      });
    }
    return features_center_marker;
  };

  render_label_markers = ({
    features_center_marker,
    label_config,
    fields,
    map_object,
    type,
    visibility,
  }) => {
    features_center_marker.forEach((feature) => {
      let label_final = this.create_label_content(
        feature,
        label_config,
        fields,
        type
      );
      if (visibility === "visible") {
        const markerEl = document.createElement("div");
        markerEl.innerHTML = ReactDOMServer.renderToString(label_final);
        const marker = new maplibregl.Marker({ element: markerEl })
          .setLngLat([
            feature.geometry.coordinates[0],
            feature.geometry.coordinates[1],
          ])
          .addTo(map_object);

        this.setState((prevState) => ({
          markers: [...prevState.markers, marker],
        }));
      } else {
        // Remove markers when not visible
        if (this.state && this.state.markers) {
          this.state.markers.forEach((marker) => marker.remove());
          this.setState({ markers: [] });
        }
      }
    });
  };

  create_label_content = (feature, label_config, fields, type) => {
    const label_text_field_key_array =
      label_config?.label_text_field_key_array || [];
    const label_image_field_key_array =
      label_config?.label_image_field_key_array || [];
    const style_mode = label_config?.style_mode || "style_1";
    const background_color_primary =
      label_config?.background_color_primary || "#292571";
    const outline_color_primary =
      label_config?.outline_color_primary || "#ffffff";
    const text_color_primary = label_config?.text_color_primary || "#ffffff";
    const background_color_secondary =
      label_config?.background_color_secondary || "#ee6b1e";
    const outline_color_secondary =
      label_config?.outline_color_secondary || "#ffffff";
    const text_color_secondary =
      label_config?.text_color_secondary || "#ffffff";

    let label_image;
    let label_text;
    if (label_config?.is_use_label_image) {
      label_image = (
        <div>
          {label_image_field_key_array.map((field_key, idx) => {
            let content;
            if (feature?.properties?.[field_key]) {
              content = (
                <div
                  key={idx}
                  style={{
                    width: "35px",
                    height: "35px",
                    borderRadius: "5px",
                    backgroundImage: `url(${feature?.properties?.[field_key]})`,
                    backgroundSize: "cover",
                  }}
                  className="margin_bottom_minor margin_right_minor"
                />
              );
            } else {
              content = <div key={idx} />;
            }
            return content;
          })}
        </div>
      );
    }

    if (label_config?.is_use_label_text) {
      label_text = (
        <div>
          {label_text_field_key_array.map((field_key, idx) => {
            const field = fields.find((feature) => feature.key === field_key);
            const field_type = field?.type;
            let text = feature?.properties?.[field_key];
            if (["number"].includes(field_type)) {
              text = new Intl.NumberFormat("id-ID", {
                style: "decimal",
              }).format(text);
            } else if (field_type === "currency") {
              text =
                "Rp." +
                new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
                  text
                );
            }
            let content;
            if (feature?.properties?.[field_key]) {
              content = (
                <div key={idx}>
                  <p
                    className={`margin_bottom_minor badge_pill_small text_small`}
                    style={{
                      color:
                        idx === 0 ? text_color_primary : text_color_secondary,
                      backgroundColor:
                        idx === 0
                          ? background_color_primary
                          : background_color_secondary,
                      borderColor:
                        idx === 0
                          ? outline_color_primary
                          : outline_color_secondary,
                      borderWidth: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    {text}
                  </p>
                </div>
              );
            } else {
              content = <div key={idx}></div>;
            }
            return content;
          })}
        </div>
      );
    }

    let label_final;
    if (type === "Point" || type === "MultiPoint") {
      label_final = (
        <div
          style={{ position: "absolute", transform: "translate(-50%, -100%)" }}
        >
          <main
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {label_image}
            {label_text}
            <div
              style={{ width: "2px", height: "25px", backgroundColor: "black" }}
            />
          </main>
        </div>
      );
    } else if (style_mode === "style_1") {
      label_final = (
        <>
          {label_image}
          {label_text}
        </>
      );
    } else {
      label_final = (
        <div>
          <div
            className="button_pill margin_bottom_minor"
            style={{
              color: text_color_primary,
              backgroundColor: background_color_primary,
              borderColor: outline_color_primary,
              borderWidth: "1px",
              borderStyle: "solid",
            }}
          >
            {label_image}
            {label_text}
          </div>
        </div>
      );
    }
    return label_final;
  };

  cleanup_markers = () => {
    this.state.markers.forEach((marker) => marker.remove());
    this.setState({ markers: [] });
  };

  get_geo_layers = (
    project_list,
    bi_object,
    project_object_selected,
    layer_id
  ) => {
    const setting_list = bi_object?.setting_list || [];
    const setting_object = setting_list.find(
      (item) => item?.project_id === project_object_selected?._id
    );
    const layer_view_map_list = setting_object?.layer_view_map_list || [];
    let geo_layer_list = [];

    project_list.forEach((item) => {
      let layer_list = item?.layer_list || [];
      layer_list = layer_list.filter((layer) => {
        return (
          layer?.geo_layer?.geojson?.features?.length > 0 &&
          !!layer?.geo_layer?.geojson?.features?.length &&
          layer_view_map_list.includes(layer?.geo_layer?._id)
        );
      });
      if (layer_list.length > 0) {
        geo_layer_list = [...geo_layer_list, ...layer_list];
      }
    });
    if (layer_id) {
      geo_layer_list = geo_layer_list.filter(
        (item) => item?.geo_layer?._id === layer_id
      );
    }
    return geo_layer_list;
  };

  render() {
    return <main />;
  }
}

const mapStateToProps = (state) => ({
  bi: state.bi,
  layer: state.layer,
});

export default connect(mapStateToProps, {})(BI_MARKER);
