import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";
import { object_to_url_params } from "../validation/object_to_url_params";

const SERVER_URL = geoServerBaseUrl;

export const push_child_array = (body, component_id) => async (dispatch) => {
  try {
    dispatch(setLoading("push_child_array"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(
      SERVER_URL + "/properties/push_child_array",
      body,
      config
    );
    if (res?.status === 200) {
      dispatch({
        type: "push_child_array",
        payload: body,
      });
      dispatch({
        type: "status_action",
      });
      dispatch({
        type: "refresh_nested_table",
        payload: component_id,
      });
    }
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

export const edit_child_cell = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("edit_child_cell"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.put(
      SERVER_URL + "/properties/edit_child_cell",
      body,
      config
    );
    if (res?.status === 200) {
      dispatch({
        type: "edit_child_cell",
        payload: body,
      });
      dispatch({
        type: "status_action",
      });
    }
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

export const delete_child_cell = (body, component_id) => async (dispatch) => {
  try {
    dispatch(setLoading("delete_child_cell"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const url_params = object_to_url_params(body);
    const res = await axios.delete(
      SERVER_URL + "/properties/delete_child_array" + url_params,
      config
    );
    if (res?.status === 200) {
      dispatch({
        type: "delete_child_cell",
        payload: body,
      });
      dispatch({
        type: "status_action",
      });
      dispatch({
        type: "refresh_nested_table",
        payload: component_id,
      });
    }
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//setLoading
export const setLoading = (itemLoading) => {
  return {
    type: "SET_LOADING_PROCESS_LAYER",
    payload: itemLoading,
  };
};

//clearLoading
export const clearLoading = () => {
  return {
    type: "CLEAR_LOADING_LAYER",
  };
};

export const refresh_nested_table = () => {
  return {
    type: "refresh_nested_table",
  };
};
