/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class PROYEKSI_INFO extends Component {
  state = {};

  render() {
    //local storage

    //local state

    //global props
    const { variable_object, p_5_month, p_5_day, p_10_month, p_10_day } =
      this.props;

    //content
    return (
      <main>
        <section className="container_light margin_bottom">
          <table className="full_width">
            <tbody>
              <tr>
                <td colSpan={3}>
                  <h1 className="badge_pill" id="orange">
                    Proyeksi 5 tahun per bulan
                  </h1>
                </td>
              </tr>
              <tr>
                <td>p_5_month</td>
                <td>=</td>
                <td>
                  AVG_MONTH * (AVG_RATIO<sup>5</sup>)
                </td>
              </tr>
              <tr>
                <td></td>
                <td>=</td>
                <td>
                  {variable_object?.avg_passenger_month} * ( 1 + (
                  {variable_object?.avg_ratio_max_passenger} - 1))<sup>5</sup>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>=</td>
                <td>
                  <b>
                    {new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    })?.format(p_5_month)}
                  </b>
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <hr />
                  <h1 className="badge_pill" id="orange">
                    Proyeksi 5 tahun per hari
                  </h1>
                </td>
              </tr>
              <tr>
                <td>p_5_day</td>
                <td>=</td>
                <td>
                  AVG_DAY * ( 1 + (AVG_RATIO - 1 ) )<sup>5</sup>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>=</td>
                <td>
                  {variable_object?.avg_passenger_day} * ( 1 + (
                  {variable_object?.avg_ratio_max_passenger} - 1))
                  <sup>5</sup>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>=</td>
                <td>
                  <b>
                    {new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    })?.format(p_5_day)}
                  </b>
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <hr />
                  <h1 className="badge_pill" id="orange">
                    Proyeksi 10 tahun perbulan
                  </h1>
                </td>
              </tr>
              <tr>
                <td>p_10_month</td>
                <td>=</td>
                <td>
                  AVG_MONTH * ( 1 + ( AVG_RATIO - 1 ))<sup>10</sup>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>=</td>
                <td>
                  {variable_object.avg_passenger_month} * ( 1 + (
                  {variable_object.avg_ratio_max_passenger} - 1))
                  <sup>10</sup>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>=</td>
                <td>
                  <b>
                    {new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    })?.format(p_10_month)}
                  </b>
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <hr />
                  <h1 className="badge_pill" id="orange">
                    Proyeksi 10 tahun per hari
                  </h1>
                </td>
              </tr>
              <tr>
                <td>p_10_day</td>
                <td>=</td>
                <td>
                  AVG_DAY * ( 1 + ( AVG_RATIO - 1 ))<sup>10</sup>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>=</td>
                <td>
                  {variable_object.avg_passenger_day} * ( 1 + (
                  {variable_object.avg_ratio_max_passenger} - 1))
                  <sup>10</sup>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>=</td>
                <td>
                  <b>
                    {new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    })?.format(p_10_day)}
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(PROYEKSI_INFO);
