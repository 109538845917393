const aggregate_property_price = (poi_property) => {
  poi_property = poi_property?.length > 0 ? poi_property : [];
  const config = {
    rumah: ["RUMAH BARU", "RUMAH SEKEN"],
    apartemen: ["APARTEMEN BARU", "APARTEMEN SEKEN"],
    ruko: ["RUKO BARU", "RUKO SEKEN"],
  };
  //rumah
  let count_property_rumah = 0;
  let sum_property_rumah = 0;
  //apartemen
  let count_property_apartemen = 0;
  let sum_property_apartemen = 0;
  //ruko
  let count_property_ruko = 0;
  let sum_property_ruko = 0;

  let min_property_rumah_rp = 0,
    min_property_apartemen_rp = 0,
    min_property_ruko_rp = 0,
    max_property_rumah_rp = 0,
    max_property_apartemen_rp = 0,
    max_property_ruko_rp = 0;

  let array_rumah = [],
    array_apartemen = [],
    array_ruko = [];

  poi_property.forEach((item) => {
    let add_properti = Number(item?.properties?.["HARGA PROPERTI NET (RP)"]);
    add_properti = isNaN(add_properti) ? 0 : add_properti;

    /*
    Logic untuk menghindari angka tidak make sense

    jika add_properti < 100_000_000 maka angkanya dikalikan dengan 12, dengan asumsi itu adalah harga sewa per bulan

    formula = 

    harga jual = (harga sewa * 12)/0.05
    dengan asumsi cap rate 5%, 
    */

    if (add_properti < 100_000_000) {
      add_properti = (add_properti * 12) / 0.05;
    }

    if (config.rumah.includes(item.properties["TIPE_3"]) && add_properti > 0) {
      count_property_rumah = count_property_rumah + 1;
      sum_property_rumah = sum_property_rumah + add_properti;
      array_rumah.push(add_properti);
    }
    if (
      config.apartemen.includes(item.properties["TIPE_3"]) &&
      add_properti > 0
    ) {
      count_property_apartemen = count_property_apartemen + 1;
      sum_property_apartemen = sum_property_apartemen + add_properti;
      array_apartemen.push(add_properti);
    }
    if (config.ruko.includes(item.properties["TIPE_3"]) && add_properti > 0) {
      count_property_ruko = count_property_ruko + 1;
      sum_property_ruko = sum_property_ruko + add_properti;
      array_ruko.push(add_properti);
    }
  });
  if (array_rumah.length > 0) {
    min_property_rumah_rp = Math.min(...array_rumah);
    max_property_rumah_rp = Math.max(...array_rumah);
  }

  if (array_apartemen.length > 0) {
    min_property_apartemen_rp = Math.min(...array_apartemen);
    max_property_apartemen_rp = Math.max(...array_apartemen);
  }

  if (array_ruko.length > 0) {
    min_property_ruko_rp = Math.min(...array_ruko);
    max_property_ruko_rp = Math.max(...array_ruko);
  }

  const average_property_rumah_rp =
    count_property_rumah > 0
      ? parseInt(sum_property_rumah / count_property_rumah)
      : 0;
  const average_property_apartemen_rp =
    count_property_apartemen > 0
      ? parseInt(sum_property_apartemen / count_property_apartemen)
      : 0;
  const average_property_ruko_rp =
    count_property_ruko > 0
      ? parseInt(sum_property_ruko / count_property_ruko)
      : 0;

  return {
    average_property_rumah_rp,
    average_property_apartemen_rp,
    average_property_ruko_rp,

    min_property_rumah_rp,
    min_property_apartemen_rp,
    min_property_ruko_rp,

    max_property_rumah_rp,
    max_property_apartemen_rp,
    max_property_ruko_rp,

    count_property_rumah,
    count_property_apartemen,
    count_property_ruko,
  };
};

export default aggregate_property_price;
