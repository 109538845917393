/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Map } from "maplibre-gl";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

/*COMPONENT*/
import LAYER_S_CENTER from "../libre_layer_sini/LAYER_S_CENTER";
import LAYER_S_POI from "../libre_layer_sini/LAYER_S_POI";
import LAYER_S_POI_SURVEY_1 from "../libre_layer_sini/LAYER_S_POI_SURVEY_1";
import LAYER_S_DEMOGRAPHY_ONE from "../libre_layer_sini/LAYER_S_DEMOGRAPHY_ONE";
import LAYER_S_DEMOGRAPHY_NEIGHBOR from "../libre_layer_sini/LAYER_S_DEMOGRAPHY_NEIGHBOR";
import LAYER_S_ISOCHRONE from "../libre_layer_sini/LAYER_S_ISOCHRONE";
import LAYER_S_FLOOD_NEIGHBOR from "../libre_layer_sini/LAYER_S_FLOOD_NEIGHBOR";
import LAYER_S_FLOOD from "../libre_layer_sini/LAYER_S_FLOOD";
import LAYER_S_LANDSLIDE_NEIGHBOR from "../libre_layer_sini/LAYER_S_LANDSLIDE_NEIGHBOR";
import LAYER_S_LANDSLIDE from "../libre_layer_sini/LAYER_S_LANDSLIDE";
import LAYER_S_LANDVALUE from "../libre_layer_sini/LAYER_S_LANDVALUE";
import LAYER_S_LANDVALUE_NEIGHBOR from "../libre_layer_sini/LAYER_S_LANDVALUE_NEIGHBOR";
import LAYER_S_LANDZONE_NEIGHBOR from "../libre_layer_sini/LAYER_S_LANDZONE_NEIGHBOR";
import LAYER_S_LANDZONE from "../libre_layer_sini/LAYER_S_LANDZONE";
import LAYER_S_RDTR_NEIGHBOR from "../libre_layer_sini/LAYER_S_RDTR_NEIGHBOR";
import LAYER_S_RDTR_ONE from "../libre_layer_sini/LAYER_S_RDTR_ONE";
import LAYER_S_TSUNAMI_NEIGHBOR from "../libre_layer_sini/LAYER_S_TSUNAMI_NEIGHBOR";
import LAYER_S_TSUNAMI from "../libre_layer_sini/LAYER_S_TSUNAMI";

/*REDUX*/
import { set_value_layer } from "../../App/actions/layerActions";
import { set_value_sini } from "../../App/actions/sini_v2";
import LAYER_GEO from "../libre_layer/LAYER_GEO";

/*FUNCTION*/

/*DATA*/

/*CONST*/
const basemap_url = process.env.REACT_APP_MAPID_BASEMAP;
const basemap_key = process.env.REACT_APP_BASEMAP_KEY;

class MAP_EXCEL extends Component {
  componentDidMount() {
    const { set_value_layer } = this.props;
    const map_object_excel = new Map({
      container: "map_object_excel",
      style: `${basemap_url}/styles/street-2d-building/style.json?key=${basemap_key}`,
      center: [118.72762419354456, -0.2055368192132505],
      zoom: 3.7,
      maxPitch: 85,
      projection: "globe",
      attributionControl: false,
    });
    map_object_excel.addControl(
      new maplibregl.AttributionControl({
        customAttribution: "GEO",
        compact: true,
      })
    );
    set_value_layer({
      key: "map_object_excel",
      value: map_object_excel,
    });
  }

  render() {
    return (
      <main
        id="map_object_excel"
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "15px",
        }}
      >
        <LAYER_GEO map_object_string="map_object_excel" />
        <LAYER_S_CENTER map_object_string="map_object_excel" />
        <LAYER_S_ISOCHRONE map_object_string="map_object_excel" />
        <LAYER_S_POI map_object_string="map_object_excel" />
        <LAYER_S_DEMOGRAPHY_ONE map_object_string="map_object_excel" />
        <LAYER_S_DEMOGRAPHY_NEIGHBOR map_object_string="map_object_excel" />
        <LAYER_S_LANDVALUE map_object_string="map_object_excel" />
        <LAYER_S_LANDVALUE_NEIGHBOR map_object_string="map_object_excel" />
        <LAYER_S_LANDZONE map_object_string="map_object_excel" />
        <LAYER_S_LANDZONE_NEIGHBOR map_object_string="map_object_excel" />
        <LAYER_S_RDTR_ONE map_object_string="map_object_excel" />
        <LAYER_S_RDTR_NEIGHBOR map_object_string="map_object_excel" />
        <LAYER_S_POI_SURVEY_1 map_object_string="map_object_excel" />
        <LAYER_S_FLOOD map_object_string="map_object_excel" />
        <LAYER_S_FLOOD_NEIGHBOR map_object_string="map_object_excel" />
        <LAYER_S_LANDSLIDE map_object_string="map_object_excel" />
        <LAYER_S_LANDSLIDE_NEIGHBOR map_object_string="map_object_excel" />
        <LAYER_S_TSUNAMI map_object_string="map_object_excel" />
        <LAYER_S_TSUNAMI_NEIGHBOR map_object_string="map_object_excel" />
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
});

export default connect(mapStateToProps, { set_value_layer, set_value_sini })(
  MAP_EXCEL
);
