/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import Modal from "../common_modal/Modal";

/*REDUX*/
import { set_value_hbu } from "../../App/actions/hbu_actions";
import { calc_fs } from "../../App/actions/calc_fs";

/*PICTURE*/
import expand from "../../Assets/svg/expand.svg";
import NPV_DETAIL from "./NPV_DETAIL";
import IRR_DETAIL from "./IRR_DETAIL";
import ARR_DETAIL from "./ARR_DETAIL";
import PROFITABILITY_INDEX_DETAIL from "./PROFITABILITY_INDEX_DETAIL";
import PAYBACK_PERIODE_DETAIL from "./PAYBACK_PERIODE_DETAIL";

/*FUNCTION*/

/*DATA*/

/*CONST*/

class UJI_FINANSIAL extends Component {
  state = {
    modal_npv_detail: false,
    modal_irr_detail: false,
    modal_arr_detail: false,
    modal_pi_detail: false,
    modal_pp_detail: false,
  };

  toggle_npv_detail = (alt_selected) => {
    this.setState({
      modal_npv_detail: !this.state.modal_npv_detail,
    });
    if (alt_selected) {
      this.props.set_value_hbu({
        key: "alt_selected",
        value: alt_selected,
      });
    }
  };

  toggle_irr_detail = (alt_selected) => {
    this.setState({
      modal_irr_detail: !this.state.modal_irr_detail,
    });
    if (alt_selected) {
      this.props.set_value_hbu({
        key: "alt_selected",
        value: alt_selected,
      });
    }
  };

  toggle_pi_detail = (alt_selected) => {
    this.setState({
      modal_pi_detail: !this.state.modal_pi_detail,
    });
    if (alt_selected) {
      this.props.set_value_hbu({
        key: "alt_selected",
        value: alt_selected,
      });
    }
  };

  toggle_arr_detail = (alt_selected) => {
    this.setState({
      modal_arr_detail: !this.state.modal_arr_detail,
    });
    if (alt_selected) {
      this.props.set_value_hbu({
        key: "alt_selected",
        value: alt_selected,
      });
    }
  };

  toggle_pp_detail = (alt_selected) => {
    this.setState({
      modal_pp_detail: !this.state.modal_pp_detail,
    });
    if (alt_selected) {
      this.props.set_value_hbu({
        key: "alt_selected",
        value: alt_selected,
      });
    }
  };

  on_change_props = (event) => {
    const name = event.target.name;
    const value = Number(event.target.value);
    this.props.set_value_hbu({
      key: name,
      value,
    });
  };

  on_change_skema_tv = (event) => {
    const value = event.target.value === "true";
    this.props.set_value_hbu({
      key: "skema_terminal_value",
      value,
    });
  };

  render() {
    //local storage

    //local state
    const {
      modal_npv_detail,
      modal_irr_detail,
      modal_arr_detail,
      modal_pp_detail,
      modal_pi_detail,
    } = this.state;
    const skema_tv_value = [
      { value: true, label: "Skema Terminal Value" },
      { value: false, label: "Tanpa skema Terminal Value" },
    ];

    //global props
    const {
      rows_filtered_3,
      project_duration_years,
      discount_rate_1_percent,
      revenue_growth_percent,
      opex_growth_percent,
      working_capital_idr,
      discount_rate_2_percent,
      // skema_terminal_value,
    } = this.props.hbu_reducer;

    //content
    const modal_npv_detail_content = modal_npv_detail && (
      <Modal
        modalSize="large"
        isOpen={modal_npv_detail}
        onClose={this.toggle_npv_detail}
      >
        <div className="box-body" id="box-body">
          <NPV_DETAIL />
        </div>
      </Modal>
    );

    const modal_irr_detail_content = modal_irr_detail && (
      <Modal
        modalSize="large"
        isOpen={modal_irr_detail}
        onClose={this.toggle_irr_detail}
      >
        <div className="box-body" id="box-body">
          <IRR_DETAIL />
        </div>
      </Modal>
    );

    const modal_pi_detail_content = modal_pi_detail && (
      <Modal
        modalSize="large"
        isOpen={modal_pi_detail}
        onClose={this.toggle_pi_detail}
      >
        <div className="box-body" id="box-body">
          <PROFITABILITY_INDEX_DETAIL />
        </div>
      </Modal>
    );

    const modal_arr_detail_content = modal_arr_detail && (
      <Modal
        modalSize="large"
        isOpen={modal_arr_detail}
        onClose={this.toggle_arr_detail}
      >
        <div className="box-body" id="box-body">
          <ARR_DETAIL />
        </div>
      </Modal>
    );

    const modal_pp_detail_content = modal_pp_detail && (
      <Modal
        modalSize="large"
        isOpen={modal_pp_detail}
        onClose={this.toggle_pp_detail}
      >
        <div className="box-body" id="box-body">
          <PAYBACK_PERIODE_DETAIL />
        </div>
      </Modal>
    );

    return (
      <section className="container background_grey_light margin_bottom">
        {[
          modal_npv_detail_content,
          modal_pi_detail_content,
          modal_pp_detail_content,
          modal_arr_detail_content,
          modal_irr_detail_content,
        ]}
        <div className="text_bold margin_bottom">Uji Finansial</div>
        <div className="two_container_equal">
          <article className="container background_white margin_bottom">
            <div className="two_container_small_right">
              <h1 className="text_bold margin_bottom">
                Atur parameter dan asumsi
              </h1>
              <img
                className="cursor_pointer text_right"
                alt="expand"
                src={expand}
                style={{ height: "20px" }}
              />
            </div>
            <div className="margin_bottom">
              <p className="text_medium">Durasi Proyek (dalam tahun)</p>
              <input
                name="project_duration_years"
                value={project_duration_years}
                onChange={this.on_change_props}
                type="number"
              />
            </div>
            <div className="margin_bottom">
              <p className="text_medium">
                Tingkat Diskonto Primer (dalam persen)
              </p>
              <input
                name="discount_rate_1_percent"
                value={discount_rate_1_percent}
                onChange={this.on_change_props}
                type="number"
              />
            </div>
            <div className="margin_bottom">
              <p className="text_medium">
                Tingkat Diskonto Sekunder (dalam persen)
              </p>
              <input
                name="discount_rate_2_percent"
                value={discount_rate_2_percent}
                onChange={this.on_change_props}
                type="number"
              />
            </div>
            <div className="margin_bottom">
              <p className="text_medium">
                Growth Pendapatan (dalam persen per tahun)
              </p>
              <input
                name="revenue_growth_percent"
                value={revenue_growth_percent}
                onChange={this.on_change_props}
                type="number"
              />
            </div>
            <div className="margin_bottom">
              <p className="text_medium">
                Growth OPEX (dalam persen per tahun)
              </p>
              <input
                name="opex_growth_percent"
                value={opex_growth_percent}
                onChange={this.on_change_props}
                type="number"
              />
            </div>
            <div className="margin_bottom">
              <p className="text_medium">Modal Kerja (Rupiah)</p>
              <input
                name="working_capital_idr"
                value={working_capital_idr}
                onChange={this.on_change_props}
                type="number"
              />
            </div>
            <div className="margin_bottom">
              <p className="text_medium">Skema</p>
              <select name="skema_tv" onChange={this.on_change_skema_tv}>
                {skema_tv_value.map((item, idx) => {
                  return (
                    <option key={idx} value={item.value}>
                      {item.label}
                    </option>
                  );
                })}
              </select>
            </div>
            <button
              className="button background_black"
              onClick={this.props.on_run}
            >
              Hitung Kembali Uji Finansial
            </button>
          </article>
          <article className="container background_white margin_bottom">
            <div className="two_container_small_right">
              <h1 className="text_bold margin_bottom">Penjelasan</h1>
              <img
                className="cursor_pointer text_right"
                alt="expand"
                src={expand}
                style={{ height: "20px" }}
              />
            </div>
            <p className="text_medium">...</p>
          </article>
        </div>
        <aside>
          <table className="table margin_bottom" style={{ width: "100%" }}>
            <thead>
              <tr>
                <td className="text_bold">No</td>
                <td className="text_bold"> Nama Alternatif</td>
                <td>
                  CAPEX Tanah
                  <br />
                  CAPEX Bangunan
                  <br />
                  <br />
                  <b className="text_bold">
                    CAPEX Total <br />
                    (rupiah)
                  </b>
                </td>
                <td className="text_bold">NPV (rupiah)</td>
                <td className="text_bold">IRR (persen)</td>
                <td className="text_bold">ARR (persen)</td>
                <td className="text_bold">PI (indeks)</td>
                <td className="text_bold">PP (tahun)</td>
                {/* <td className="text_bold">DR (persen)</td> */}
              </tr>
            </thead>
            <tbody>
              {rows_filtered_3.map((item, idx) => {
                const {
                  JENIS,
                  capex_tanah,
                  capex_bangunan,
                  capex_total,
                  npv,
                  irr,
                  arr,
                  payback_periode,
                  profitability_index,
                } = item;

                return (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{JENIS}</td>
                    <td>
                      {capex_tanah &&
                        new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(capex_tanah)}
                      <br />
                      {capex_bangunan &&
                        new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(capex_bangunan)}
                      <br />
                      <br />
                      <b className="text_bold">
                        {capex_total &&
                          new Intl.NumberFormat("id-ID", {
                            style: "decimal",
                          }).format(capex_total)}
                      </b>
                    </td>
                    <td className="text_bold">
                      <div className="text_right">
                        <img
                          onClick={this.toggle_npv_detail.bind(this, item)}
                          className="cursor_pointer"
                          alt="expand"
                          src={expand}
                          style={{ height: "15px" }}
                        />
                      </div>
                      {capex_tanah &&
                        new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(npv)}
                    </td>

                    <td className="text_bold">
                      <div className="text_right">
                        <img
                          onClick={this.toggle_irr_detail.bind(this, item)}
                          className="cursor_pointer"
                          alt="expand"
                          src={expand}
                          style={{ height: "15px" }}
                        />
                      </div>
                      {irr &&
                        new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                          maximumFractionDigits: 2,
                        }).format(irr)}
                    </td>
                    <td className="text_bold">
                      <div className="text_right">
                        <img
                          onClick={this.toggle_arr_detail.bind(this, item)}
                          className="cursor_pointer"
                          alt="expand"
                          src={expand}
                          style={{ height: "15px" }}
                        />
                      </div>
                      {arr &&
                        new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                          maximumFractionDigits: 2,
                        }).format(arr)}
                    </td>

                    <td className="text_bold">
                      <div className="text_right">
                        <img
                          onClick={this.toggle_pi_detail.bind(this, item)}
                          className="cursor_pointer"
                          alt="expand"
                          src={expand}
                          style={{ height: "15px" }}
                        />
                      </div>

                      {profitability_index &&
                        new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                          maximumFractionDigits: 2,
                        }).format(profitability_index)}
                    </td>
                    <td className="text_bold">
                      <div className="text_right">
                        <img
                          onClick={this.toggle_pp_detail.bind(this, item)}
                          className="cursor_pointer"
                          alt="expand"
                          src={expand}
                          style={{ height: "15px" }}
                        />
                      </div>
                      {payback_periode ? (
                        new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                          maximumFractionDigits: 2,
                        }).format(payback_periode)
                      ) : (
                        <div className="text_center">
                          <span
                            style={{
                              letterSpacing: "1px",
                              maxWidth: "13rem",
                            }}
                            className="badge background_red"
                          >
                            INVESTASI TIDAK MENCAPAI TITIK BALIK MODAL
                          </span>
                        </div>
                      )}
                    </td>
                    {/* <td className="text_bold">-</td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </aside>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  loading_reducer: state.loading_reducer,
  sini: state.sini,
  properties: state.properties,
  layer: state.layer,
  hbu_reducer: state.hbu_reducer,
});

export default connect(mapStateToProps, {
  set_value_hbu,
  calc_fs,
})(UJI_FINANSIAL);
