import React from "react";

function ICON_MARKER() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.286"
      height="18.017"
      fill="none"
      viewBox="0 0 14.286 18.017"
    >
      <path
        fill="#a0d061"
        stroke="#104585"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.355"
        d="M4.41.25c-1.065 0-2.13.406-2.943 1.219a4.148 4.148 0 00-1.21 2.644c-.05.787.097 1.49.393 2.11.422.884 1.05 1.364 1.846 1.77 1.432.729 1.902 2.074 1.914 3.112.012-1.038.482-2.383 1.914-3.113.796-.405 1.424-.885 1.846-1.77.296-.62.443-1.322.393-2.109a4.147 4.147 0 00-1.21-2.644A4.15 4.15 0 004.41.25zm0 2.316a1.847 1.847 0 11-.002 3.693 1.847 1.847 0 01.002-3.693z"
        clipPath="url(#clipPath10831)"
        paintOrder="stroke markers fill"
        transform="translate(.633 .631) scale(1.4761)"
      ></path>
      <defs>
        <clipPath id="clipPath10831">
          <path fill="#fff" d="M-0.997 -1.254H9.82V11.803H-0.997z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default ICON_MARKER;
