/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import {
  getBlogListPublic,
  getBlogDetailPublicNew,
  getLayerProjectBlog,
  clearLayerBlogList,
} from "../../App/actions/blogAction";

/*PICTURE ASSET*/
import official_account from "../../Assets/svg/official_account.svg";

/*GENERAL*/
import isEmpty from "../../App/validation/is-empty";
import dict from "../../Data/dict.json";

class BlogListPublicCard extends Component {
  state = {};
  componentDidMount() {
    const { domain } = this.props.auth;
    this.props.getBlogListPublic(domain);
  }
  handleClickCard = (link) => {
    this.props.getBlogDetailPublicNew(link);
    this.props.getLayerProjectBlog(link);
    this.props.clearLayerBlogList();
  };
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { blog_list } = this.props.blog;
    let itemContent;
    if (isEmpty(blog_list)) {
      itemContent = <div>{dict["Loading publication"][language]} ...</div>;
    } else {
      itemContent = (
        <main className="carousell">
          {blog_list.map((blog) => {
            const { _id, title, link, authors } = blog;
            const picture_url = blog?.picture_url ? blog?.picture_url : "";
            const full_name = authors[0]?.user?.full_name;
            const badge = authors[0]?.user?.badge;
            const is_official = badge === "official";
            const url_img = String(picture_url).replace(/\s/g, "%20");
            let text_status = "Public";
            const market_status = blog?.market_status;
            if (market_status?.status === "free") {
              text_status = "Free";
            } else if (market_status?.status === "paid") {
              text_status = `Rp ${new Intl.NumberFormat("id-ID", {
                style: "decimal",
              }).format(1.1 * 1.3 * market_status?.price)}`;
            }
            return (
              <Link
                to={`/blog_read/${link}`}
                key={_id}
                onClick={this.handleClickCard.bind(this, link)}
              >
                <section
                  className="container_blog_card"
                  id="white"
                  style={{ border: "3px solid #e0ecf5ff" }}
                >
                  <main className="container_square">
                    <section
                      className="container_image_blog"
                      style={{
                        backgroundImage: `url(${url_img})`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    />
                    <section className="container_badge">{text_status}</section>
                    <section className="container_second_half_blog">
                      <h1 className="title_blog">{title}</h1>
                      <p className="sub_title_blog_card">
                        {full_name}
                        {is_official && (
                          <img
                            alt="Official account"
                            src={official_account}
                            width="13px"
                            style={{ marginLeft: "5px" }}
                          />
                        )}
                      </p>
                    </section>
                  </main>
                </section>
              </Link>
            );
          })}
        </main>
      );
    }
    return (
      <div
        style={{
          marginTop: "30px",
          marginBottom: "30px",
          paddingBottom: "300px",
        }}
      >
        <div className="text_bold" style={{ textAlign: "center" }}>
          {dict["Other Publication"][language]}
        </div>
        {itemContent}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  blog: state.blog,
});
export default connect(mapStateToProps, {
  getBlogListPublic,
  getBlogDetailPublicNew,
  getLayerProjectBlog,
  clearLayerBlogList,
})(BlogListPublicCard);
