const initial_state = {
  //search
  kelurahan_list_by_search: [],
  kecamatan_list_by_search: [],
  kota_list_by_search: [],
  //value selected
  provinsi: "",
  kota: "",
  kecamatan: "",
  desa: "",
  //hirarki
  kelurahan_hirarki: [],
  kecamatan_hirarki: [],
  kota_hirarki: [],
  provinsi_hirarki: [],
  //data raw
  features_demography: [],
  features_poi: [],
  //data sementara
  auto_step: 0, //0 || 1 || 2 || 3
  mode_input_parent: "", // "" || "simple" || "custom"
  is_estimated: false,
  features_grid_raw: [],
  features_grid: [],
  grid_mode: "square", //square || hex
  grid_size_mode: "count", //count, width
  grid_counts: 200,
  grid_counts_approx: 200,
  grid_width_meters: 1000,
  grid_width_meters_approx: 1000,
  total_square_meters: 0,
  total_poi_initial: 0,
  array_formula_insight: [],
  array_group_insight: [
    {
      key: "demography",
      key_raw: "demography_raw",
      is_directly_proportional: true,
      min: 0,
      max: 100,
      weight_score: 1,
    },
    {
      key: "poi",
      key_raw: "poi_raw",
      is_directly_proportional: true,
      min: 0,
      max: 100,
      weight_score: 1,
    },
  ],
  config_final_score: {
    min: 0,
    max: 100,
  },
  //miscellaneous
  demography_group_selected: "all",
  sidebar_content: "home", //home || formula || result
  total_grid_group: 1,
  current_grid_group: 0,
  total_save_feature: 100,
  current_save_feature: 0,
  step_formula: 0,
  insight_update: 0,
  insight_fly_update: 0,
  total_poi: 1,
  current_progress_poi: 0,
  tipe_level: "tipe_1", //tipe_1 || tipe_2 || tipe_3
  tipe_1_array: [], //array of object
  tipe_2_array: [], //array of object
  tipe_3_array: [], //array of object
  tipe_1_all: false,
  list_tipe_1: [],
  list_tipe_2: [],
  list_tipe_3: [],
};

export default function dataReducer(state = initial_state, action) {
  switch (action.type) {
    case "insight_update":
      return {
        ...state,
        insight_update: state.insight_update + 1,
      };
    case "insight_fly_update":
      return {
        ...state,
        insight_fly_update: state.insight_fly_update + 1,
      };
    case "set_value_insight":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case "push_value_insight":
      return {
        ...state,
        [action.payload.key]: [
          ...state[action.payload.key],
          action.payload.value,
        ],
      };
    case "push_value_many_insight":
      return {
        ...state,
        [action.payload.key]: [
          ...state[action.payload.key],
          [...action.payload.value],
        ],
      };
    default:
      return state;
  }
}
