/*LIBRARY*/
import React, { Component } from "react";
import ReactDOMServer from "react-dom/server";
import { connect } from "react-redux";
import maplibregl from "maplibre-gl";
import centroid from "@turf/centroid";
import along from "@turf/along";
import calculate_length from "@turf/length";

/*COMPONENT*/

/*REDUX*/

/*PICTURE*/

/*FUNCTION*/

/*DATA*/

/*CONST*/
function get_alpha_value(background_color_primary) {
  // Check if the input is in rgba format
  const rgba_regex = /rgba\(\d+,\s*\d+,\s*\d+,\s*(\d*\.?\d+)\)/;
  const match = background_color_primary.match(rgba_regex);
  if (match) {
    return parseFloat(match[1]); // Extract and return the alpha value
  }
  return 1; // Return 1 if not in rgba format
}

class MARKER_GEO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      markers: [],
    };
  }

  componentDidUpdate(prevProps) {
    const status_action_after = this.props.map.status_action;
    const status_action_before = prevProps.map.status_action;
    const basemap_used_after = this.props.properties.basemap_used;
    const basemap_used_before = prevProps.properties.basemap_used;
    const { map_object } = this.props.layer;
    if (
      status_action_after !== status_action_before ||
      basemap_used_after !== basemap_used_before
    ) {
      if (map_object && map_object.isStyleLoaded()) {
        this.on_render();
      } else if (map_object) {
        map_object.once("styledata", this.on_render);
      }
    }
  }

  on_render = () => {
    let { geo_layer_list, layer_id_active } = this.props.layer;
    const { map_object } = this.props.map;
    if (!map_object) return;
    this.cleanup_markers();
    geo_layer_list.forEach((data) => {
      const type = data.geo_layer?.type;
      const features_filtered =
        data.geo_layer?.geojson_filtered?.features || [];
      const label_config = data.geo_layer?.label_config || {};
      const visibility = layer_id_active.includes(data.geo_layer?._id)
        ? "visible"
        : "none";
      if (
        (label_config?.is_use_label_text || label_config?.is_use_label_image) &&
        visibility === "visible"
      ) {
        let features_center_marker = this.calculate_marker_centers(
          type,
          features_filtered
        );

        this.render_label_markers({
          features_center_marker,
          label_config,
          fields: data.geo_layer.fields,
          map_object,
          type,
          visibility,
        });
      }
    });
  };

  calculate_marker_centers = (type, features_filtered) => {
    let features_center_marker = [];
    // Calculate marker centers based on geometry type
    if (
      ["Polygon", "MultiPolygon", "MultiLineString", "MultiPoint"].includes(
        type
      )
    ) {
      features_center_marker = features_filtered.map((feature) => {
        let feature_final = {};
        const geojson = {
          type: "FeatureCollection",
          features: [feature],
        };
        feature_final = centroid(geojson, { properties: feature.properties });
        return feature_final;
      });
    } else if (type === "Point") {
      features_center_marker = features_filtered;
    } else if (type === "LineString") {
      features_center_marker = features_filtered.map((feature) => {
        const length_km = calculate_length(feature, {
          units: "kilometers",
        }).toFixed(2);
        const center_length = length_km / 2;
        let point = along(feature, center_length, { units: "kilometers" });
        point.properties = feature.properties;
        return point;
      });
    }
    return features_center_marker;
  };

  render_label_markers = ({
    features_center_marker,
    label_config,
    fields,
    map_object,
    type,
    visibility,
  }) => {
    features_center_marker.forEach((feature) => {
      let label_final = this.create_label_content(
        feature,
        label_config,
        fields,
        type
      );
      if (visibility === "visible") {
        const markerEl = document.createElement("div");
        markerEl.innerHTML = ReactDOMServer.renderToString(label_final);
        const marker = new maplibregl.Marker({ element: markerEl })
          .setLngLat([
            feature.geometry.coordinates[0],
            feature.geometry.coordinates[1],
          ])
          .addTo(map_object);
        this.setState((prevState) => ({
          markers: [...prevState.markers, marker],
        }));
      } else {
        // Remove markers when not visible
        if (this.state && this.state.markers) {
          this.state.markers.forEach((marker) => marker.remove());
          this.setState({ markers: [] });
        }
      }
    });
  };

  create_label_content = (feature, label_config, fields) => {
    const label_text_field_key_array =
      label_config?.label_text_field_key_array || [];
    const label_image_field_key_array =
      label_config?.label_image_field_key_array || [];
    const background_color_primary =
      label_config?.background_color_primary || "#292571";
    const outline_color_primary =
      label_config?.outline_color_primary || "#ffffff";
    const text_color_primary = label_config?.text_color_primary || "#ffffff";
    const is_use_pin = label_config?.is_use_pin || false;
    const text_stroke_width = label_config?.text_stroke_width || 0;
    const text_stroke_color =
      label_config?.text_stroke_color || "rgba(0, 0, 0, 0)";
    const font_size = label_config?.font_size || 16;
    const font_weight = label_config?.font_weight || 500;
    const pin_height = label_config?.pin_height || 25;
    let label_image;
    let label_text;

    if (label_config?.is_use_label_image) {
      label_image = (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {label_image_field_key_array.map((field_key, idx) => {
            let content;
            if (feature?.properties?.[field_key]) {
              content = (
                <div
                  key={idx}
                  style={{
                    width: "35px",
                    height: "35px",
                    borderRadius: "5px",
                    backgroundImage: `url(${feature?.properties?.[field_key]})`,
                    backgroundSize: "cover",
                  }}
                  className="margin_bottom_minor margin_right_minor"
                />
              );
            } else {
              content = <div key={idx} />;
            }
            return content;
          })}
        </div>
      );
    }

    if (label_config?.is_use_label_text) {
      label_text = (
        <div>
          {label_text_field_key_array.map((field_key, idx) => {
            const field = fields.find((feature) => feature.key === field_key);
            const field_type = field?.type;
            let text = feature?.properties?.[field_key];
            if (["number"].includes(field_type)) {
              text = new Intl.NumberFormat("id-ID", {
                style: "decimal",
              }).format(text);
            } else if (field_type === "currency") {
              text =
                "Rp." +
                new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
                  text
                );
            }
            let padding = "4px 8px";
            let margin = "5px auto";
            const alpha = get_alpha_value(background_color_primary);
            if (alpha === 0) {
              padding = "0px";
              margin = "-10px auto";
            }
            const style_general = {
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
              overflow: "hidden",
              whiteSpace: "normal",
              wordBreak: "break-word",
              borderWidth: "2px",
              borderStyle: "solid",
              borderRadius: "7px",
              textAlign: "center",
              lineHeight: "120%",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              fontSize: `${font_size}px`,
              fontWeight: font_weight,
              padding,
            };
            return (
              <div
                key={idx}
                style={{
                  position: "relative",
                  // width: "100px",
                  margin: margin,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  id="text_stroke_marker"
                  style={{
                    ...style_general,
                    zIndex: 1,
                    color: "transparent",
                    borderColor: "transparent",
                    WebkitTextStrokeWidth: `${text_stroke_width}px`,
                    WebkitTextStrokeColor: text_stroke_color,
                    position: "absolute",
                    maxWidth: "100px",
                  }}
                >
                  {text}
                </p>
                <p
                  id="text_inside_marker"
                  style={{
                    ...style_general,
                    zIndex: 2,
                    color: text_color_primary,
                    backgroundColor: background_color_primary,
                    borderColor: outline_color_primary,
                    position: "relative",
                    maxWidth: "100px",
                  }}
                >
                  {text}
                </p>
              </div>
            );
          })}
        </div>
      );
    }

    const label_final = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        <div
          style={{
            transform: "translate(-50%, -50%)",
            position: "relative",
            left: "50%",
          }}
        >
          <main style={{ textAlign: "center" }}>
            <div style={{ display: "inline-block" }}>
              {label_image}
              {label_text}
              <div
                id="pin_marker"
                style={{
                  visibility: is_use_pin ? "visible" : "hidden",
                  width: "2px",
                  height: `${pin_height}px`,
                  backgroundColor: "black",
                  marginTop: "-10px",
                  marginBottom: "0px",
                  marginRight: "auto",
                  marginLeft: "auto",
                }}
              />
            </div>
          </main>
        </div>
      </div>
    );

    return label_final;
  };

  cleanup_markers = () => {
    this.state.markers.forEach((marker) => marker.remove());
    this.setState({ markers: [] });
  };

  render() {
    return <main />;
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  map: state.map,
  properties: state.properties,
});

export default connect(mapStateToProps, {})(MARKER_GEO);
