/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class CAPEX_INFO extends Component {
  state = {};

  render() {
    //local storage

    //local state

    //global props
    const { retail, capex_value } = this.props;

    //content
    return (
      <main>
        <table>
          <tbody>
            <tr>
              <td>CAPEX</td>
              <td>=</td>
              <td>LUAS RETAIL * 9.500.000</td>
            </tr>
            <tr className="margin_bottom">
              <td></td>
              <td>=</td>
              <td>
                {new Intl.NumberFormat("id-ID", {
                  style: "decimal",
                }).format(retail.toFixed(0))}{" "}
                * 9.500.000
              </td>
            </tr>
            <tr>
              <td>CAPEX</td>
              <td>=</td>
              <td className="text_bold">
                {new Intl.NumberFormat("id-ID", {
                  style: "decimal",
                }).format(parseInt(capex_value))}
              </td>
            </tr>
          </tbody>
        </table>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(CAPEX_INFO);
