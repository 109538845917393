/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import bbox from "@turf/bbox";
import area from "@turf/area";
import geojson_geometries_lookup from "geojson-geometries-lookup";
import points_within_polygon from "@turf/points-within-polygon";

/*COMPONENT*/
import ErrorFree from "../user/ErrorFree";
import Modal from "../common_modal/Modal";
import ProgressBar from "../common_spinner/ProgressBar";

/*REDUX*/
import { set_value_layer } from "../../App/actions/layerActions";
import { importLayer } from "../../App/actions/layerNewActions";
import { set_value_user } from "../../App/actions/authActions";
import { set_value_properties } from "../../App/actions/propertiesActions";
import {
  set_value_insight,
  insight_update,
  push_value_insight,
} from "../../App/actions/insight_v2";

/*PICTURE*/
import grid_hex from "../../Assets/png_jpg/grid_hex.png";
import grid_square from "../../Assets/png_jpg/grid_square.png";

/*FUNCTION*/

/*DATA*/

/*CONST*/
const timeout_miliseconds = 100;
const item_per_looping = 25;
const max_grid_counts = 300;

class INPUT_GRID extends Component {
  state = {
    modal_payment: false,
    loading_status_grid: false,
    modal_warning_grid_max: false,
    modal_warn_step: false,
  };

  toggle_warn_step = () => {
    this.setState({
      modal_warn_step: !this.state.modal_warn_step,
    });
  };

  toggle_warning_grid_max = () => {
    this.setState({
      modal_warning_grid_max: !this.state.modal_warning_grid_max,
    });
  };

  on_change = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.props.set_value_insight({
      key: name,
      value,
    });
    this.props.set_value_insight({
      key: "is_estimated",
      value: false,
    });
  };

  on_estimate = () => {
    this.props.set_value_insight({
      key: "is_estimated",
      value: true,
    });
    const { grid_mode } = this.props.insight_reducer;
    let count;
    if (grid_mode === "square") {
      count = this.estimate_square();
    } else {
      count = this.estimate_hex();
    }

    if (count > max_grid_counts) {
      this.toggle_warning_grid_max();
    } else {
      if (grid_mode === "square") {
        this.generate_square();
      } else {
        this.generate_hex();
      }
    }
  };

  estimate_square = () => {
    const {
      features_demography,
      grid_size_mode,
      grid_counts,
      grid_width_meters,
    } = this.props.insight_reducer;
    const geojson_demography = {
      type: "FeatureCollection",
      features: features_demography,
    };
    const total_square_meters = area(geojson_demography);
    this.props.set_value_insight({
      key: "total_square_meters",
      value: total_square_meters,
    });
    let cell_width_meters, cell_height_meters;
    const [min_longitude, min_latitude, max_longitude, max_latitude] =
      bbox(geojson_demography);
    const width = max_longitude - min_longitude;
    const height = max_latitude - min_latitude;
    if (grid_size_mode === "count") {
      const width_meters = width * 111_000;
      const height_meters = height * 111_000;
      const bounding_box_area = width_meters * height_meters;
      const initial_cell_area = bounding_box_area / grid_counts;
      const adjusted_cell_area =
        initial_cell_area * (total_square_meters / bounding_box_area);
      cell_width_meters = Math.sqrt(adjusted_cell_area);
      cell_height_meters = cell_width_meters;
      this.props.set_value_insight({
        key: "grid_width_meters_approx",
        value: parseInt(cell_width_meters),
      });
      return grid_counts;
    } else {
      cell_width_meters = grid_width_meters;
      cell_height_meters = grid_width_meters;
      const adjusted_cell_area = cell_width_meters * cell_height_meters;
      const grid_counts_approx = parseInt(
        total_square_meters / adjusted_cell_area
      );
      this.props.set_value_insight({
        key: "grid_counts_approx",
        value: grid_counts_approx,
      });
      return grid_counts_approx;
    }
  };

  estimate_hex = () => {
    const {
      features_demography,
      grid_size_mode,
      grid_counts,
      grid_width_meters,
    } = this.props.insight_reducer;
    const geojson_demography = {
      type: "FeatureCollection",
      features: features_demography,
    };
    const total_square_meters = area(geojson_demography);
    this.props.set_value_insight({
      key: "total_square_meters",
      value: total_square_meters,
    });
    const [min_longitude, min_latitude, max_longitude, max_latitude] =
      bbox(geojson_demography);
    const width = max_longitude - min_longitude;
    const height = max_latitude - min_latitude;
    let hex_width, hex_radius;
    if (grid_size_mode === "count") {
      const width_meters = width * 111_000;
      const height_meters = height * 111_000;
      const bounding_box_area = width_meters * height_meters;
      const initial_cell_area = bounding_box_area / grid_counts;
      const adjusted_cell_area =
        initial_cell_area * (total_square_meters / bounding_box_area);
      hex_radius = Math.sqrt((2 * adjusted_cell_area) / (3 * Math.sqrt(3)));
      hex_width = 2 * hex_radius;
      this.props.set_value_insight({
        key: "grid_width_meters_approx",
        value: parseInt(hex_width),
      });
      return grid_counts;
    } else {
      hex_width = grid_width_meters;
      hex_radius = hex_width / 2;
      const adjusted_cell_area =
        ((3 * Math.sqrt(3)) / 8) * Math.pow(hex_width, 2);
      const grid_counts_approx = parseInt(
        total_square_meters / adjusted_cell_area
      );
      this.props.set_value_insight({
        key: "grid_counts_approx",
        value: grid_counts_approx,
      });
      return grid_counts_approx;
    }
  };

  on_generate_grid = () => {
    const { grid_mode } = this.props.insight_reducer;
    if (grid_mode === "square") {
      this.generate_square();
    } else {
      this.generate_hex();
    }
  };

  toggle_login = () => {
    const { modal_login } = this.props.auth;
    this.props.set_value_user("modal_login", !modal_login);
  };

  toggle_payment = () => {
    this.setState({ modal_payment: !this.state.modal_payment });
  };

  generate_square = () => {
    this.props.set_value_insight({
      key: "features_grid",
      value: [],
    });
    this.props.insight_update();
    setTimeout(() => {
      const {
        features_demography,
        grid_size_mode, //count, width
        grid_counts,
        grid_width_meters,
      } = this.props.insight_reducer;
      const geojson_demography = {
        type: "FeatureCollection",
        features: features_demography,
      };
      const total_square_meters = area(geojson_demography); //square meters
      this.props.set_value_insight({
        key: "total_square_meters",
        value: total_square_meters,
      });
      let cell_width_meters, cell_height_meters;
      const [min_longitude, min_latitude, max_longitude, max_latitude] =
        bbox(geojson_demography);
      const width = max_longitude - min_longitude; //width and height of the bounding box in degrees
      const height = max_latitude - min_latitude;

      if (grid_size_mode === "count") {
        const width_meters = width * 111_000; //convert width and height to approximate meters
        const height_meters = height * 111_000;
        const bounding_box_area = width_meters * height_meters; //calculate the bounding box area in square meters
        const initial_cell_area = bounding_box_area / grid_counts; //initial cell area based on bounding box area
        //adjust the cell area to fit the demography area more accurately
        const adjusted_cell_area =
          initial_cell_area * (total_square_meters / bounding_box_area);
        cell_width_meters = Math.sqrt(adjusted_cell_area); //in meters
        cell_height_meters = cell_width_meters; //in meters (to maintain square shape)
        this.props.set_value_insight({
          key: "grid_width_meters_approx",
          value: parseInt(cell_width_meters),
        });
      } else {
        cell_width_meters = grid_width_meters;
        cell_height_meters = grid_width_meters;
        const adjusted_cell_area = cell_width_meters * cell_height_meters;
        const grid_counts_approx = parseInt(
          total_square_meters / adjusted_cell_area
        );
        this.props.set_value_insight({
          key: "grid_counts_approx",
          value: grid_counts_approx,
        });
      }
      //convert cell width and height back to degrees for grid generation
      const cell_width_deg = cell_width_meters / 111_000;
      const cell_height_deg = cell_height_meters / 111_000;
      const features_grid_raw = []; //create the grid features
      const num_rows = Math.ceil(height / cell_height_deg);
      const num_cols = Math.ceil(width / cell_width_deg);
      for (let row = 0; row < num_rows; row++) {
        //loop through rows and columns to generate grid cells
        for (let col = 0; col < num_cols; col++) {
          const cell_min_longitude = min_longitude + col * cell_width_deg;
          const cell_min_latitude = min_latitude + row * cell_height_deg;
          const cellMaxLng = cell_min_longitude + cell_width_deg;
          const cellMaxLat = cell_min_latitude + cell_height_deg;
          //create the grid cell as a GeoJSON Polygon feature
          const cell_feature = {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [cell_min_longitude, cell_min_latitude],
                  [cellMaxLng, cell_min_latitude],
                  [cellMaxLng, cellMaxLat],
                  [cell_min_longitude, cellMaxLat],
                  [cell_min_longitude, cell_min_latitude],
                ],
              ],
            },
            properties: {
              row,
              col,
            },
          };
          features_grid_raw.push(cell_feature);
        }
      }
      this.props.set_value_insight({
        key: "features_grid_raw",
        value: features_grid_raw,
      });
      //clear grid intersections
      this.clear_grid_intersections(features_grid_raw);
    }, timeout_miliseconds); //milliseconds delay
  };

  generate_hex = () => {
    this.props.set_value_insight({
      key: "features_grid",
      value: [],
    });
    this.props.insight_update();
    setTimeout(() => {
      const {
        features_demography,
        grid_size_mode,
        grid_counts,
        grid_width_meters,
      } = this.props.insight_reducer;
      const geojson_demography = {
        type: "FeatureCollection",
        features: features_demography,
      };
      const total_square_meters = area(geojson_demography); // in square meters
      this.props.set_value_insight({
        key: "total_square_meters",
        value: total_square_meters,
      });
      const [min_longitude, min_latitude, max_longitude, max_latitude] =
        bbox(geojson_demography);
      //calculate the width and height of the bounding box in degrees
      const width = max_longitude - min_longitude;
      const height = max_latitude - min_latitude;
      //nilai yang akan tergantung logic grid_size_mode
      let hex_width, hex_height, hex_radius;
      if (grid_size_mode === "count") {
        const width_meters = width * 111_000; //convert width and height to approximate meters
        const height_meters = height * 111_000;
        const bounding_box_area = width_meters * height_meters; //bounding box area in square meters
        const initial_cell_area = bounding_box_area / grid_counts; //initial cell area based on bounding box area
        //adjust the cell area to fit the demography area more accurately
        const adjusted_cell_area =
          initial_cell_area * (total_square_meters / bounding_box_area);
        hex_radius = Math.sqrt((2 * adjusted_cell_area) / (3 * Math.sqrt(3))); //in meters
        hex_width = 2 * hex_radius; //horizontal distance across hexagon
        hex_height = Math.sqrt(3) * hex_radius; //vertical distance across hexagon
        this.props.set_value_insight({
          key: "grid_width_meters_approx",
          value: parseInt(hex_width),
        });
      } else {
        hex_width = grid_width_meters;
        hex_radius = hex_width / 2;
        hex_height = Math.sqrt(3) * hex_radius; // Vertical distance across hexagon
        const adjusted_cell_area =
          ((3 * Math.sqrt(3)) / 8) * Math.pow(hex_width, 2);
        const grid_counts_approx = parseInt(
          total_square_meters / adjusted_cell_area
        );
        this.props.set_value_insight({
          key: "grid_counts_approx",
          value: grid_counts_approx,
        });
      }
      //convert hex width and height back to degrees for grid generation
      const hex_width_deg = hex_width / 111_000;
      const hex_height_deg = hex_height / 111_000;
      const features_grid_raw = []; //create the hexagonal grid features
      const num_rows = Math.ceil(height / hex_height_deg);
      const num_cols = Math.ceil(width / (hex_width_deg * 0.75)); // offset columns by 3/4 width for hex pattern
      for (let row = 0; row < num_rows; row++) {
        //loop through rows and columns to generate hexagonal grid cells
        for (let col = 0; col < num_cols; col++) {
          const center_longitude = min_longitude + col * hex_width_deg * 0.75; // Calculate hex center position
          let center_lat = min_latitude + row * hex_height_deg;
          //offset every second row by half the hex width to create the staggered hex pattern
          if (col % 2 === 1) {
            center_lat += hex_height_deg / 2;
          }
          //define hexagon points based on center point and hex radius
          const hex_points = [];
          for (let i = 0; i < 6; i++) {
            const angle = (Math.PI / 3) * i; //60 degrees for each hex side
            const point_longitude =
              center_longitude + (hex_radius / 111_000) * Math.cos(angle);
            const point_latitude =
              center_lat + (hex_radius / 111_000) * Math.sin(angle);
            hex_points.push([point_longitude, point_latitude]);
          }
          hex_points.push(hex_points[0]); //close the polygon
          //Create the hex cell as a GeoJSON Polygon feature
          const cell_feature = {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [hex_points],
            },
            properties: {
              row,
              col,
            },
          };
          features_grid_raw.push(cell_feature);
        }
      }
      this.props.set_value_insight({
        key: "features_grid_raw",
        value: features_grid_raw,
      });
      //clear grid intersections
      this.clear_grid_intersections(features_grid_raw);
    }, timeout_miliseconds); //milliseconds delay
  };

  clear_grid_intersections = (features_grid_raw) => {
    this.setState({ loading_status_grid: true });
    const {
      features_demography,
      features_poi,
      tipe_1_array,
      tipe_2_array,
      tipe_3_array,
      tipe_level,
      tipe_1_all,
    } = this.props.insight_reducer;
    let array_formula_insight =
      this.props.insight_reducer.array_formula_insight;
    if (tipe_level === "tipe_1" || tipe_1_all) {
      tipe_1_array.forEach((item) => {
        const key = item.TIPE_1;
        //cek apakah sudah ada atau belum, jika sudah ada tidak perlu push
        if (!array_formula_insight.some((item) => item.key === key))
          array_formula_insight.push({
            key,
            is_directly_proportional: true,
            min: 0,
            max: 0,
            weight_score: 1,
            group: "poi",
          });
      });
    } else if (tipe_level === "tipe_2") {
      tipe_2_array.forEach((item) => {
        const key = item.TIPE_2 + ", " + item.TIPE_1;
        //cek apakah sudah ada atau belum, jika sudah ada tidak perlu push
        if (!array_formula_insight.some((item) => item.key === key))
          array_formula_insight.push({
            key,
            is_directly_proportional: true,
            min: 0,
            max: 0,
            weight_score: 1,
            group: "poi",
          });
      });
    } else if (tipe_level === "tipe_3") {
      tipe_3_array.forEach((item) => {
        const key = item.TIPE_3 + ", " + item.TIPE_2 + ", " + item.TIPE_1;
        //cek apakah sudah ada atau belum, jika sudah ada tidak perlu push
        if (!array_formula_insight.some((item) => item.key === key))
          array_formula_insight.push({
            key,
            is_directly_proportional: true,
            min: 0,
            max: 0,
            weight_score: 1,
            group: "poi",
          });
      });
    }
    this.props.set_value_insight({
      key: "array_formula_insight",
      value: array_formula_insight,
    });
    const geojson_poi = {
      type: "FeatureCollection",
      features: features_poi,
    };
    const delay_milisecond_constant = 77;
    const delay_promise = () =>
      new Promise((res) => setTimeout(res, delay_milisecond_constant));
    const total_item = features_grid_raw.length;
    const group_per_looping = [];
    const geojson_demography = {
      type: "FeatureCollection",
      features: features_demography,
    };
    const glookup = new geojson_geometries_lookup(geojson_demography);
    function get_mid_point(coordinates) {
      const numPoints = coordinates.length;
      let lat_sum = 0;
      let long_sum = 0;
      //sum all latitude and longitude values of the vertices
      coordinates.forEach(([longitude, latitude]) => {
        lat_sum += latitude;
        long_sum += longitude;
      });
      //calculate the average to get the center point
      const lat_center = lat_sum / numPoints;
      const long_center = long_sum / numPoints;
      return {
        type: "Point",
        coordinates: [long_center, lat_center],
      };
    }
    for (let i = 0; i < total_item; i += item_per_looping) {
      group_per_looping.push({
        start: i,
        end: Math.min(i + item_per_looping, total_item),
      });
    }
    this.props.set_value_insight({
      key: "current_grid_group",
      value: 0,
    });
    this.props.set_value_insight({
      key: "total_grid_group",
      value: group_per_looping.length,
    });
    const parent_function = () => {
      return group_per_looping.reduce(
        (last_promise, range, index) =>
          last_promise.then((result_sum) =>
            child_function(range, index).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    const child_function = async (range, index) => {
      return delay_promise().then(() => {
        const core_function = async () => {
          try {
            const items = features_grid_raw.slice(range.start, range.end);
            items.forEach((feature_grid) => {
              const grid_geojson = get_mid_point(
                feature_grid.geometry.coordinates[0] //Pass entire coordinates array
              );
              const geojson_filtered = glookup.getContainers(grid_geojson);
              if (geojson_filtered?.features?.length > 0) {
                //step 1: demography --> next interpolate by area grid vs area DESA
                const properties_demography =
                  geojson_filtered?.features?.[0]?.properties || {};
                //step 2: poi
                const geojson_poi_inside = points_within_polygon(
                  geojson_poi,
                  feature_grid
                );
                const features_poi_inside = geojson_poi_inside?.features || [];
                const ALL_POI_COUNT = geojson_poi_inside?.features?.length || 0;
                let properties_poi = {};
                /*
                 tipe_1_array,
                 tipe_2_array,
                 tipe_3_array,
                 tipe_level,
                 tipe_1_all,


                tipe_level === "tipe_1"
                TIPE_1: Number

                tipe_level === "tipe_2"
                TIPE_2, TIPE_1: Number

                tipe_level === "tipe_3"
                TIPE_3, TIPE_2, TIPE_1: Number

                separator: ", " 
                */
                if (tipe_level === "tipe_1" || tipe_1_all) {
                  tipe_1_array.forEach((item) => {
                    const features_poi_inside_type = features_poi_inside.filter(
                      (feature) => {
                        return feature.properties.TIPE_1 === item.TIPE_1;
                      }
                    );
                    const value = features_poi_inside_type.length;
                    const key = item.TIPE_1;
                    properties_poi[key] = value;
                  });
                } else if (tipe_level === "tipe_2") {
                  tipe_2_array.forEach((item) => {
                    const features_poi_inside_type = features_poi_inside.filter(
                      (feature) => {
                        return (
                          feature.properties.TIPE_1 === item.TIPE_1 &&
                          feature.properties.TIPE_2 === item.TIPE_2
                        );
                      }
                    );
                    const value = features_poi_inside_type.length;
                    const key = item.TIPE_2 + ", " + item.TIPE_1;
                    properties_poi[key] = value;
                  });
                } else if (tipe_level === "tipe_3") {
                  tipe_3_array.forEach((item) => {
                    const features_poi_inside_type = features_poi_inside.filter(
                      (feature) => {
                        return (
                          feature.properties.TIPE_1 === item.TIPE_1 &&
                          feature.properties.TIPE_2 === item.TIPE_2 &&
                          feature.properties.TIPE_3 === item.TIPE_3
                        );
                      }
                    );
                    const value = features_poi_inside_type.length;
                    const key =
                      item.TIPE_3 + ", " + item.TIPE_2 + ", " + item.TIPE_1;
                    properties_poi[key] = value;
                  });
                }
                feature_grid.properties = {
                  ...feature_grid.properties,
                  ...properties_demography,
                  ...properties_poi,
                  ALL_POI_COUNT,
                };
                this.props.push_value_insight({
                  key: "features_grid",
                  value: feature_grid,
                });
              }
            });
            this.props.set_value_insight({
              key: "current_grid_group",
              value: index + 1,
            });
          } catch (error) {}
        };
        return core_function();
      });
    };
    parent_function().then(() => {
      this.setState({ loading_status_grid: false });
      this.props.insight_update();
      if (this.props.insight_reducer.features_grid.length > 0) {
        this.props.set_value_insight({
          key: "grid_counts",
          value: this.props.insight_reducer.features_grid.length,
        });
      }
    });
  };

  render() {
    const {
      modal_payment,
      loading_status_grid,
      modal_warning_grid_max,
      modal_warn_step,
    } = this.state;
    const { quota_access, isAuthenticated } = this.props.auth;
    const { license_user_status, license_group_status } =
      this.props.license_reducer;
    const {
      total_grid_group,
      current_grid_group,
      grid_mode,
      grid_size_mode,
      grid_counts,
      grid_counts_approx,
      grid_width_meters,
      grid_width_meters_approx,
      is_estimated,
      features_demography,
    } = this.props.insight_reducer;

    const grid_counts_final =
      grid_size_mode === "count" ? grid_counts : grid_counts_approx;

    let button_generate_grid;

    if (grid_counts_final > max_grid_counts) {
      button_generate_grid = (
        <button
          className="button background_grey"
          onClick={this.toggle_warning_grid_max}
        >
          Generate grid
        </button>
      );
    } else {
      button_generate_grid = (
        <button
          className="button background_blue"
          onClick={this.on_generate_grid}
          data-mapid="clickArea"
        >
          Generate grid
        </button>
      );
    }

    let button_content;

    if (features_demography.length === 0) {
      button_content = (
        <div className="button background_grey" onClick={this.toggle_warn_step}>
          Belum memilih kota
        </div>
      );
    } else if (
      license_user_status?.is_has_license ||
      license_group_status?.is_has_license ||
      quota_access > 0
    ) {
      button_content = (
        <section>
          <table
            // className="table"
            style={{
              tableLayout: "fixed",
              margin: "auto",
              textAlign: "center",
              width: "100%",
            }}
          >
            <tbody>
              <tr>
                <td colSpan={2} className="text_medium text_left">
                  Pilih bentuk grid:
                </td>
              </tr>
              <tr>
                <td>
                  <div className="container_light outline_transparent background_white">
                    <button
                      onClick={this.on_change}
                      name="grid_mode"
                      value="square"
                      className={
                        grid_mode === "square"
                          ? "checklist_active"
                          : "checklist_not"
                      }
                    />
                    <br />
                    <img
                      alt="square"
                      src={grid_square}
                      width={31}
                      className="margin_top"
                    />
                    <br />
                    <p className="text_small">Square</p>
                  </div>
                </td>
                <td>
                  <div className="container_light outline_transparent background_white">
                    <button
                      onClick={this.on_change}
                      name="grid_mode"
                      value="hex"
                      className={
                        grid_mode === "hex"
                          ? "checklist_active"
                          : "checklist_not"
                      }
                      data-mapid="clickHexagon"
                    />
                    <br />
                    <img
                      alt="hexagon"
                      src={grid_hex}
                      width={31}
                      className="margin_top"
                    />
                    <br />
                    <p className="text_small">Hexagon</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="text_medium text_left">
                  <br />
                  Pilih parameter ukuran/jumlah grid:
                </td>
              </tr>
              <tr>
                <td style={{ verticalAlign: "top" }}>
                  <div className="container_light outline_transparent background_white">
                    <button
                      onClick={this.on_change}
                      name="grid_size_mode"
                      value="count"
                      className={
                        grid_size_mode === "count"
                          ? "checklist_active"
                          : "checklist_not"
                      }
                    />
                    <br />
                    <p className="text_small">Jumlah grid</p>
                    <div>
                      {grid_size_mode === "count" ? (
                        <input
                          name="grid_counts"
                          value={grid_counts}
                          onChange={this.on_change}
                          type="number"
                          data-mapid="inputGrid"
                        />
                      ) : (
                        <p className="text_medium">
                          {grid_counts_approx} grid
                          <br />
                          (perkiraan)
                        </p>
                      )}
                    </div>
                  </div>
                </td>
                <td style={{ verticalAlign: "top" }}>
                  <div className="container_light outline_transparent background_white">
                    <button
                      onClick={this.on_change}
                      name="grid_size_mode"
                      value="width"
                      className={
                        grid_size_mode === "width"
                          ? "checklist_active"
                          : "checklist_not"
                      }
                    />
                    <br />
                    <p className="text_small">Ukuran (meter)</p>
                    <div>
                      {grid_size_mode === "width" ? (
                        <input
                          name="grid_width_meters"
                          value={grid_width_meters}
                          onChange={this.on_change}
                          type="number"
                        />
                      ) : (
                        <p className="text_medium">
                          {grid_width_meters_approx}
                          <br />
                          (perkiraan)
                        </p>
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          {is_estimated ? (
            button_generate_grid
          ) : (
            <button
              className="button background_blue"
              onClick={this.on_estimate}
              data-mapid="clickArea"
            >
              {grid_size_mode === "count"
                ? "Hitung perkiraan lebar grid"
                : "Hitung perkiraan jumlah grid"}
            </button>
          )}
        </section>
      );
    } else if (!isAuthenticated) {
      button_content = (
        <div
          className="button background_blue"
          id="grey"
          onClick={this.toggle_login}
        >
          Rekomendasi grid
        </div>
      );
    } else {
      button_content = (
        <div
          className="button background_blue"
          id="grey"
          onClick={this.toggle_payment}
        >
          Rekomendasi grid
        </div>
      );
    }

    const modal_payment_content = modal_payment && (
      <Modal
        modalSize="medium"
        id="modal_payment"
        isOpen={modal_payment}
        onClose={this.toggle_payment}
      >
        <div className="box-body">
          <ErrorFree />
        </div>
      </Modal>
    );

    const modal_warning_grid_max_content = modal_warning_grid_max && (
      <Modal
        modalSize="small"
        id="modal_warning_grid_max"
        isOpen={modal_warning_grid_max}
        onClose={this.toggle_warning_grid_max}
      >
        <div className="box-body">
          <p className="text_medium">
            Jumlah grid melampau batas maksimal. Harap kurangi jumlah grid atau
            perbesar lebar grid .
          </p>
          <br />
          <p className="text_medium">
            Untuk menghindari kendala data tidak tersimpan, maksimal grid adalah{" "}
            {max_grid_counts}.
          </p>
        </div>
      </Modal>
    );

    const modal_warn_step_content = modal_warn_step && (
      <Modal
        modalSize="small"
        id="modal_warn_step"
        isOpen={modal_warn_step}
        onClose={this.toggle_warn_step}
      >
        <div className="box-body">
          <p className="text_medium">
            Harap pilih kota dan ambil data pada step sebelumnya.
          </p>
        </div>
      </Modal>
    );

    return (
      <main className="container_light outline_transparent background_grey_light margin_bottom_extra">
        {modal_payment_content}
        {modal_warning_grid_max_content}
        {modal_warn_step_content}
        <section className="margin_bottom">
          <div className="badge_pill background_white">Step 2: Atur grid</div>
        </section>
        {button_content}
        {loading_status_grid && (
          <section className="margin_top">
            <ProgressBar
              current_number={current_grid_group}
              total_number={total_grid_group}
              name="Membuat grid"
            />
          </section>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  map: state.map,
  project: state.project,
  payment: state.payment,
  license_reducer: state.license_reducer,
  loading_reducer: state.loading_reducer,
  insight_reducer: state.insight_reducer,
});

export default connect(mapStateToProps, {
  set_value_layer,
  importLayer,
  set_value_user,
  set_value_properties,
  set_value_insight,
  insight_update,
  push_value_insight,
})(INPUT_GRID);
