function markdown_to_html(markdown) {
  // Define a style string to be added to each element
  const style = ' style="margin-bottom: 1px;"';
  const listStyle = ' style="margin-left: 11px; margin-bottom: 1px;"';
  const tableStyle = ' style="border-collapse: collapse; margin-bottom: 1px;"';
  const thStyle = ' style="border: 1px solid #ddd; padding: 1px;"';
  const tdStyle = ' style="border: 1px solid #ddd; padding: 1px;"';

  // Convert headings
  markdown = markdown?.replace(/^# (.+)$/gm, `<h1${style}>$1</h1>`);
  markdown = markdown?.replace(/^## (.+)$/gm, `<h2${style}>$1</h2>`);
  markdown = markdown?.replace(/^### (.+)$/gm, `<h3${style}>$1</h3>`);
  markdown = markdown?.replace(/^#### (.+)$/gm, `<h4${style}>$1</h4>`);
  markdown = markdown?.replace(/^##### (.+)$/gm, `<h5${style}>$1</h5>`);
  markdown = markdown?.replace(/^###### (.+)$/gm, `<h6${style}>$1</h6>`);

  // Convert bold
  markdown = markdown?.replace(/\*\*(.+?)\*\*/g, `<strong${style}>$1</strong>`);

  // Convert italic
  markdown = markdown?.replace(/\*(.+?)\*/g, `<em${style}>$1</em>`);

  // Handle unordered list items
  markdown = markdown?.replace(/(?:^\s*-\s+.+$)+/gm, (match) => {
    const items = match
      .split(/\n/)
      .map((item) => item?.trim())
      .filter((item) => item);
    const listItems = items
      .map((item) => item?.replace(/^\s*-\s+(.+)$/, `<li${style}>$1</li>`))
      .join("");
    return `<ul${listStyle}>${listItems}</ul>`;
  });

  let inList = false; // Flag to track if we're currently processing an ordered list
  let olCounter = 1; // Ordered list counter

  markdown = markdown?.replace(/^([^\n]+)$/, (line) => {
    // Check for headings before processing the line
    if (line.match(/^#+/)) {
      inList = false; // Reset flag for headings
      return line; // Return the heading line as it is
    }

    // Handle ordered list items only if not currently in a list or after a heading
    if (!inList && line.match(/^\d+\.\s+.+$/)) {
      inList = true; // Set flag when entering a new ordered list
      olCounter = 1; // Reset counter for new list
    }

    let processedLine = line;
    if (inList) {
      // Process ordered list items
      processedLine = line.replace(
        /^\d+\.\s+(.+)$/,
        `<li${style} value="${olCounter}">$1</li>`
      );
      olCounter++; // Increment counter for each list item
    }
    return processedLine;
  });

  // Convert LaTeX-like expressions
  markdown = markdown?.replace(/\\\[ (.+?) \\]/g, (match, p1) => {
    return `<div>
    <div class="formula"${style}>\\[ ${p1} \\]</div></div>`;
  });

  // Handle tables with or without a leading empty row
  markdown = markdown?.replace(
    /^((?:\| (.*?) \|)+$|(?:\|\s*\|)+$\n(?:\| (.*?) \|)+$)/gm,
    (match) => {
      const rows = match.split(/\n+/).map((row) => row.trim().split("|"));

      // Extract headers and data rows
      let headers = [];
      let dataRows = [];
      let hasHeaderRow = false;
      for (const row of rows) {
        if (row.includes("----")) {
          hasHeaderRow = true;
          continue;
        }
        if (hasHeaderRow && headers.length === 0) {
          headers = row.map((header) => header.trim());
        } else {
          dataRows.push(row);
        }
      }

      // Build table structure
      let tableHtml = `<table${tableStyle}>`;

      // Create header row
      if (headers.length > 0) {
        tableHtml += `<thead><tr>`;
        headers.forEach((header) => {
          tableHtml += `<th${thStyle}>${header}</th>`;
        });
        tableHtml += `</tr></thead>`;
      }

      // Create data rows
      tableHtml += `<tbody>`;
      dataRows.forEach((row) => {
        tableHtml += `<tr>`;
        row.forEach((cell) => {
          tableHtml += `<td${tdStyle}>${cell.trim()}</td>`;
        });
        tableHtml += `</tr>`;
      });
      tableHtml += `</tbody></table>`;

      return tableHtml;
    }
  );

  return markdown;
}

export default markdown_to_html;
