import React, { Component } from "react";
import { connect } from "react-redux";
import { accept_feature } from "../../App/actions/layerNewActions";

class ModalValidasi extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClick = () => {
    const { modal_data } = this.props;
    let { layer_id } = this.props.layer;

    const body = {
      geo_layer_id: layer_id,
      feature_key: modal_data.key,
    };

    this.props.accept_feature(body);
  };

  render() {
    return (
      <div className="modal_validasi">
        <h1 style={{ fontWeight: "600" }}>
          Apakah Anda yakin untuk memvalidasikan isian ini?
        </h1>
        <p>Dana survei akan diteruskan ke surveyor ini.</p>
        <button
          className="button background_blue"
          id="green"
          onClick={() => this.handleClick()}
        >
          Konfirmasi
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  auth: state.auth,
});

export default connect(mapStateToProps, { accept_feature })(ModalValidasi);
