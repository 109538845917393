/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";

/*REDUX FUNCTION*/
import {
  push_child_array_bulk,
  edit_child_array_bulk,
} from "../../App/actions/layerActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import uuid from "../../App/validation/uuid";
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class GENERATE_ROW_BULK extends Component {
  constructor(props) {
    super(props);
    this.state = {
      field_name: "",
      first_value: 1,
      growth: 1,
      rows_total: 3,
      skip: 0,
      body: {},
      preview_status_push: false,
      preview_status_edit: false,
      parent_uuid: "",
      field_uuid: "",
      modal_warn: false,
    };
  }

  toggle_warn = () => {
    this.setState({ modal_warn: !this.state.modal_warn });
  };

  on_change = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  on_cancel = () => {
    this.setState({
      preview_status_push: false,
      preview_status_edit: false,
      field_name: "",

      first_value: 1,
      growth: 1,
      rows_total: 3,
      skip: 0,
      body: {},
    });
  };

  on_generate = () => {
    const { field_name } = this.state;
    const child_fields_shown = this?.props?.child_fields_shown || [];
    if (!field_name) {
      this.setState({ modal_warn: true });
    } else if (child_fields_shown.length === 0) {
      this.on_generate_push();
    } else {
      this.on_generate_edit();
    }
  };

  on_generate_push = () => {
    const { feature_key, layer_id, parent_uuid, parent_uuid_temp } = this.props;
    const { field_name, first_value, growth, rows_total, skip } = this.state;
    const uuid_field = uuid();
    const field = {
      parent_uuid,
      uuid: uuid_field,
      key: uuid_field,
      name: field_name,
      description: "",
      type: "arithmetic",

      array_templates: [],
      isHighlight: false,
      isRequired: false,
      isAllowOtherAnswer: false,
      isHide: false,
      isStyle: false,
      defaultValue: "",
      index: 0,
      min: 0,
      max: 0,
      step: 0,
      term_mode: "",
      term: [],
      digit_term: [],
      picture_url: "",
      poi_type: "",
      poi_rad: 0,
      reference_list: [],
      reference_key: "",
      formula: [],
      reference_list_2d: {},
      decimal_digits: "",
      conditional_statement_list: [],
      custom_counting_id: "",
      value: "",
      table_formula: {},

      first_value,
      growth,
      rows_total,
      skip,
    };
    let child_object_array = [];
    for (let index = 0; index < rows_total; index++) {
      const child_object = {
        child_uuid: uuid(),
        parent_uuid,
        parent_uuid_temp,
        properties: {
          [uuid_field]: Number(first_value) + index * Number(growth),
        },
      };
      child_object_array.push(child_object);
    }
    const body = {
      feature_key,
      geo_layer_id: layer_id,
      parent_uuid,
      parent_uuid_temp,
      field,
      child_object_array,
      field_uuid: uuid_field,
    };
    this.setState({ body, preview_status_push: true });
  };

  on_generate_edit = () => {
    const child_fields_shown = this?.props?.child_fields_shown || [];
    const { feature_key, layer_id, parent_uuid, parent_uuid_temp } = this.props;
    const { field_name, first_value, growth, rows_total, skip } = this.state;
    const uuid_field = uuid();
    const field = {
      parent_uuid,
      uuid: uuid_field,
      key: uuid_field,
      name: field_name,
      description: "",
      type: "arithmetic",

      array_templates: [],
      isHighlight: false,
      isRequired: false,
      isAllowOtherAnswer: false,
      isHide: false,
      isStyle: false,
      defaultValue: "",
      index: 0,
      min: 0,
      max: 0,
      step: 0,
      term_mode: "",
      term: [],
      digit_term: [],
      picture_url: "",
      poi_type: "",
      poi_rad: 0,
      reference_list: [],
      reference_key: "",
      formula: [],
      reference_list_2d: {},
      decimal_digits: "",
      conditional_statement_list: [],
      custom_counting_id: "",
      value: "",
      table_formula: {},

      first_value,
      growth,
      rows_total,
      skip,
    };
    for (let index = 0; index < rows_total; index++) {
      if (child_fields_shown?.[index + Number(skip)]) {
        child_fields_shown[index + Number(skip)].properties[uuid_field] =
          Number(first_value) + index * Number(growth);
      }
    }
    const body = {
      feature_key,
      geo_layer_id: layer_id,
      parent_uuid,
      parent_uuid_temp,
      field,
      child_object_array: child_fields_shown,
      field_key: field.key,
    };
    this.setState({ body, preview_status_edit: true });
  };

  on_save = () => {
    const { body } = this.state;
    const child_fields_shown = this?.props?.child_fields_shown || [];
    if (child_fields_shown.length === 0) {
      this.props.push_child_array_bulk(body);
    } else {
      this.props.edit_child_array_bulk(body);
    }
    this.props.toggle_generate_row_bulk();
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const {
      field_name,
      first_value,
      growth,
      rows_total,
      skip,
      body,
      preview_status_push,
      preview_status_edit,
      modal_warn,
    } = this.state;

    //global props
    const { layer_id, geo_layer_list } = this.props.layer;
    const { parent_uuid } = this.props;
    const child_fields_shown = this?.props?.child_fields_shown || [];

    //content
    let preview_content;
    if (preview_status_push) {
      const child_object_array = body?.child_object_array || [];
      const field = body?.field || {};
      preview_content = (
        <main>
          <table className="table">
            <thead>
              <tr>
                <th>No</th>
                <th>{field?.name}</th>
              </tr>
            </thead>
            <tbody>
              {child_object_array.map((item, idx) => {
                return (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{item?.properties?.[field?.uuid]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </main>
      );
    }
    if (preview_status_edit) {
      const child_object_array = body?.child_object_array || [];
      const field = body?.field || {};
      const layer_object = geo_layer_list.find(
        (item) => item?.geo_layer?._id === layer_id
      )?.geo_layer;
      let fields = layer_object?.fields || [];
      fields = fields.filter((item) => item.parent_uuid === parent_uuid);
      preview_content = (
        <main>
          <table className="table">
            <thead>
              <tr>
                <th>No</th>
                {fields.map((item, idx) => {
                  return <th key={idx}>{item.name}</th>;
                })}
                <th>{field?.name}</th>
              </tr>
            </thead>
            <tbody>
              {child_object_array.map((item, index_child) => {
                return (
                  <tr key={index_child}>
                    <td>{index_child + 1}</td>
                    {fields.map((item, index_field) => {
                      return (
                        <td key={index_field}>
                          {
                            child_fields_shown?.[index_child]?.properties?.[
                              item?.key
                            ]
                          }
                        </td>
                      );
                    })}
                    <td>{item?.properties?.[field?.uuid]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </main>
      );
    }

    const modal_warn_content = modal_warn && (
      <Modal modalSize="small" isOpen={modal_warn} onClose={this.toggle_warn}>
        <div className="box-body" id="box-body">
          {dict?.["Fill in the column name first"]?.[language]}
        </div>
      </Modal>
    );

    return (
      <main>
        {modal_warn_content}
        <h1>Parameter baris aritmatika</h1>
        <section className="margin_bottom">
          <p>Nama kolom</p>
          <input
            name="field_name"
            value={field_name}
            onChange={this.on_change}
            placeholder="Nama kolom"
            type="text"
          />
        </section>
        <section className="margin_bottom">
          <p>Nilai pertama</p>
          <input
            name="first_value"
            value={first_value}
            onChange={this.on_change}
            placeholder="Nilai pertama"
            type="number"
          />
        </section>
        <section className="margin_bottom">
          <p>Koefisien growth</p>
          <input
            name="growth"
            value={growth}
            onChange={this.on_change}
            placeholder="Koefision growth"
            type="number"
          />
        </section>
        <section className="margin_bottom">
          <p>Jumlah baris</p>
          <input
            name="rows_total"
            value={rows_total}
            onChange={this.on_change}
            placeholder="Jumlah baris"
            type="number"
          />
        </section>
        <section className="margin_bottom">
          <p>Opsi skip nilai</p>
          <input
            name="skip"
            value={skip}
            onChange={this.on_change}
            placeholder="Skip nilai"
            type="number"
          />
        </section>
        <button
          className={`button margin_bottom margin_right ${
            field_name ? "background_blue" : "background_grey"
          }`}
          onClick={this.on_generate}
        >
          Preview
        </button>
        {(preview_status_push || preview_status_edit) && (
          <>
            <button
              className="button margin_bottom margin_right"
              onClick={this.on_save}
            >
              Simpan
            </button>
            <button
              className="button margin_bottom margin_right background_red"
              onClick={this.on_cancel}
            >
              Batal
            </button>
          </>
        )}
        {preview_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
});

export default connect(mapStateToProps, {
  push_child_array_bulk,
  edit_child_array_bulk,
})(GENERATE_ROW_BULK);
