/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import BI_MODE_BOX from "../bi_basic/BI_MODE_BOX";
import BI_MODE_LIST from "../bi_basic/BI_MODE_LIST";

/*REDUX*/

/*PICTURE*/

/*FUNCTION*/

/*DATA*/

/*CONST*/

class BI_MENU_PAGE extends Component {
  state = {};

  render() {
    //local storage
    const mode_view = localStorage.getItem("mode_view") || "box";

    //local state

    //global props

    //content

    let bi_content = null;
    bi_content = mode_view === "box" ? <BI_MODE_BOX /> : <BI_MODE_LIST />;

    return (
      <main>
        <h1 className="text_big margin_bottom">Business Intelligence</h1>
        {bi_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  payment: state.payment,
  auth: state.auth,
  project: state.project,
  license_reducer: state.license_reducer,
  properties: state.properties,
});

export default connect(mapStateToProps, {})(BI_MENU_PAGE);
