/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_sini } from "../../App/actions/sini_v2";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class SINI_POLYGON_CONFIG extends Component {
  state = {};

  on_change = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.props.set_value_sini({
      key: name,
      value: value,
    });
  };

  on_toggle = (e) => {
    const name = e.target.name;
    this.props.set_value_sini({
      key: name,
      value: !this.props.sini[name],
    });
  };

  render_scale = (min, max, step) => {
    const numbers_array = [];
    for (let i = min; i <= max; i += step) {
      let number = i;
      if (!Number.isInteger(i)) {
        number = i.toFixed(1);
      }
      numbers_array.push(number);
    }
    return (
      <div className="scale_range text_small">
        {numbers_array.map((number) => (
          <span key={number} className="scale_range_number">
            {number}
          </span>
        ))}
      </div>
    );
  };

  render() {
    //local storage

    //local state

    //global props
    const {
      sini_polygon_fill_opacity,
      sini_polygon_line_width,
      sini_show_polygon_label,
      // sini_use_label_color,
    } = this.props.sini;

    //content

    return (
      <main>
        <section className="margin_bottom">
          <p className="text_small">
            Transparansi warna poligon ({sini_polygon_fill_opacity})
          </p>
          <input
            name="sini_polygon_fill_opacity"
            value={sini_polygon_fill_opacity}
            onChange={this.on_change}
            min="0"
            max="1"
            step="0.01"
            type="range"
          />
          {this.render_scale(0, 1, 0.2)}
        </section>
        <section className="margin_bottom">
          <p className="text_small">
            Tebal garis poligon ({sini_polygon_line_width})
          </p>
          <input
            name="sini_polygon_line_width"
            value={sini_polygon_line_width}
            onChange={this.on_change}
            min="0"
            max="10"
            step="0.1"
            type="range"
          />
          {this.render_scale(0, 10, 1)}
        </section>
        <section className="margin_bottom">
          <table>
            <tbody>
              <tr>
                <td>
                  <button
                    className={
                      sini_show_polygon_label
                        ? "checklist_active"
                        : "checklist_not"
                    }
                    name="sini_show_polygon_label"
                    onClick={this.on_toggle}
                  />
                </td>
                <td>
                  <p className="text_small">Tampilkan label pada poligon</p>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {/* <section className="margin_bottom">
          <p className="text_small">Terapkan warna pada label</p>
          <br />
          <button
            className={
              sini_use_label_color ? "checklist_active" : "checklist_not"
            }
            name="sini_use_label_color"
            onClick={this.on_toggle}
          />
        </section> */}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, { set_value_sini })(
  SINI_POLYGON_CONFIG
);
