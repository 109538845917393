import MapboxDraw from "@mapbox/mapbox-gl-draw";
/*
#20a4f6 - bright blue
#1a649d - dark blue
#a0d061 - hijau
*/

const draw_initiation = ({ map_object, on_create, on_update, on_delete }) => {
  const polygon_fill_color = "#20a4f6";
  const selected_polygon_fill_color = "#ffffff";

  const polygon_outline_color = "#1a649d";
  const line_color = "#20a4f6";
  const static_line_color = "#1a649d";

  const vertex_halo_color = "#000000";
  const point_outline_polygon_selected_active = "#a0d061";
  const point_outline_polygon_selected_potential_point = "#ffffff";

  const point_color = "#20a4f6";
  const point_selected_color = "#1a649d";
  const point_polygon_color = "#1a649d";

  const polygon_fill_opacity = 0.4;
  const selected_polygon_fill_opacity = 0.6;
  const static_polygon_fill_opacity = 0.2;
  const point_stroke_opacity = 1;

  const polygon_outline_width = 2;
  const line_width = 2;
  const point_stroke_width = 2;
  const static_line_width = 2;

  const point_radius = 5;
  const point_selected_radius = 7;
  const active_vertex_radius = 5;
  const vertex_halo_radius = 7;
  const selected_vertex_radius = 9;

  const draw = new MapboxDraw({
    displayControlsDefault: false,
    styles: [
      {
        id: "gl-draw-polygon-fill",
        type: "fill",
        filter: [
          "all",
          ["==", "$type", "Polygon"],
          ["!=", "mode", "static"],
          ["!=", "active", "true"],
        ],
        paint: {
          "fill-color": polygon_fill_color,
          "fill-outline-color": polygon_outline_color,
          "fill-opacity": polygon_fill_opacity,
        },
      },
      {
        id: "gl-draw-polygon-fill-selected",
        type: "fill",
        filter: ["all", ["==", "$type", "Polygon"], ["==", "active", "true"]],
        paint: {
          "fill-color": selected_polygon_fill_color,
          "fill-outline-color": polygon_outline_color,
          "fill-opacity": selected_polygon_fill_opacity,
        },
      },
      {
        id: "gl-draw-polygon-stroke-active",
        type: "line",
        filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
        layout: {
          "line-cap": "round",
          "line-join": "round",
        },
        paint: {
          "line-color": polygon_outline_color,
          "line-width": polygon_outline_width,
        },
      },
      {
        id: "gl-draw-line",
        type: "line",
        filter: [
          "all",
          ["==", "$type", "LineString"],
          ["!=", "mode", "static"],
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round",
        },
        paint: {
          "line-color": line_color,
          "line-width": line_width,
        },
      },
      {
        id: "gl-draw-point",
        type: "circle",
        filter: ["all", ["==", "$type", "Point"], ["!=", "mode", "static"]],
        paint: {
          "circle-radius": point_radius,
          "circle-color": point_color,
          "circle-stroke-color": point_outline_polygon_selected_potential_point,
          "circle-stroke-width": point_stroke_width,
          "circle-stroke-opacity": point_stroke_opacity,
        },
      },
      {
        id: "gl-draw-point-selected",
        type: "circle",
        filter: ["all", ["==", "$type", "Point"], ["==", "active", "true"]],
        paint: {
          "circle-radius": point_selected_radius, // Use the selected point size
          "circle-color": point_selected_color, // Use the selected color when active
          "circle-stroke-color": point_outline_polygon_selected_potential_point,
          "circle-stroke-width": point_stroke_width,
          "circle-stroke-opacity": point_stroke_opacity,
        },
      },
      {
        id: "gl-draw-polygon-and-line-vertex-halo-active",
        type: "circle",
        filter: [
          "all",
          ["==", "meta", "vertex"],
          ["==", "$type", "Point"],
          ["!=", "mode", "static"],
        ],
        paint: {
          "circle-radius": vertex_halo_radius,
          "circle-color": vertex_halo_color,
        },
      },
      {
        id: "gl-draw-selected-vertex-halo",
        type: "circle",
        filter: [
          "all",
          ["==", "$type", "Point"],
          ["==", "meta", "vertex"],
          ["==", "active", "true"],
        ],
        paint: {
          "circle-radius": vertex_halo_radius,
          "circle-color": vertex_halo_color,
        },
      },
      {
        id: "gl-draw-selected-vertex",
        type: "circle",
        filter: ["all", ["==", "meta", "vertex"], ["==", "active", "true"]],
        paint: {
          "circle-radius": selected_vertex_radius,
          "circle-color": point_outline_polygon_selected_active,
        },
      },
      {
        id: "gl-draw-polygon-and-line-vertex-active",
        type: "circle",
        filter: [
          "all",
          ["==", "meta", "vertex"],
          ["==", "$type", "Point"],
          ["!=", "mode", "static"],
        ],
        paint: {
          "circle-radius": active_vertex_radius,
          "circle-color": point_polygon_color,
        },
      },
      {
        id: "gl-draw-polygon-fill-static",
        type: "fill",
        filter: ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
        paint: {
          "fill-color": polygon_fill_color,
          "fill-outline-color": polygon_outline_color,
          "fill-opacity": static_polygon_fill_opacity,
        },
      },
      {
        id: "gl-draw-line-static",
        type: "line",
        filter: [
          "all",
          ["==", "$type", "LineString"],
          ["==", "mode", "static"],
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round",
        },
        paint: {
          "line-color": static_line_color,
          "line-width": static_line_width,
        },
      },
    ],
  });

  if (map_object) {
    map_object.addControl(draw);
    map_object.on("draw.create", on_create);
    map_object.on("draw.update", on_update);
    map_object.on("draw.delete", on_delete);
  }
};

export default draw_initiation;
