import React, { useState, useEffect } from "react";

const NavTitleBlog = ({ data }) => {
  const [title, setTitle] = useState(false);

  useEffect(() => {
    const navTitle = () => {
      if (window.scrollY >= 300) {
        setTitle(true);
      } else {
        setTitle(false);
      }
    };

    window.addEventListener("scroll", navTitle);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("scroll", navTitle);
    };
  }, []); // Empty dependency array ensures this runs once on mount and cleanup on unmount

  const language = localStorage?.language ? localStorage?.language : "ina";

  return (
    <div
      className="row_blog_title"
      title={language === "ina" ? data?.title : data?.title_en}
    >
      <h1 className={title ? "title_blog_nav" : "title_blog_nav_hide"}>
        {language === "ina" ? data?.title : data?.title_en}
      </h1>
    </div>
  );
};

export default NavTitleBlog;
