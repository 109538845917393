/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "@mui/material/Menu";

/*COMPONENT*/

/*REDUX*/
import { set_value_bi } from "../../App/actions/biActions";

/*PICTURE*/

/*FUNCTION*/
import { calculate_conditional_statement } from "../../App/validation/conditional_statement_validation";
import get_url_query from "../../App/validation/get_url_query";

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class BI_CARD_LIST extends Component {
  state = {
    anchor: null,
    modal_menu_card: false,
  };

  toggle_menu_card = (e) => {
    this.setState(
      { modal_menu_card: !this.state.modal_menu_card, anchor: e.currentTarget },
      () => {
        const card_id = e?.target?.id;
        if (card_id) {
          const { bi_object } = this.props.bi;
          const card_list = bi_object?.card_list || [];
          const card_object = card_list.find((item) => item._id === card_id);
          if (card_object) {
            this.props.set_value_bi({
              key: "card_add_object",
              value: card_object,
            });
          }
        }
      }
    );
  };

  toggle_edit_card = () => {
    this.props.set_value_bi({
      key: "modal_add_card",
      value: !this.props.bi.modal_add_card,
    });
    this.props.set_value_bi({
      key: "add_card_current_step",
      value: 0,
    });
    this.setState({ modal_menu_card: false });
  };

  toggle_delete_card = () => {
    this.props.set_value_bi({
      key: "modal_delete_card",
      value: !this.props.bi.modal_delete_card,
    });
    this.setState({ modal_menu_card: false });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";
    const mode = get_url_query("mode");

    //local state
    const { anchor, modal_menu_card } = this.state;

    //global props
    let { bi_object, project_object_selected } = this.props.bi;

    //content
    const setting_list = bi_object?.setting_list || [];
    const setting_object = setting_list.find(
      (item) => item?.project_id === project_object_selected?._id
    );
    const layer_load_list = setting_object?.layer_load_list || [];
    const layer_view_map_list = setting_object?.layer_view_map_list || [];
    let layer_final_list = [...layer_load_list];
    layer_view_map_list.forEach((item) => {
      if (!layer_load_list.includes(item)) layer_final_list.push(item);
    });
    const layer_list = project_object_selected?.layer_list || [];
    const card_list = this.props.card_list;

    const modal_menu_card_content = modal_menu_card && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_menu_card}
        onClose={this.toggle_menu_card}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        style={{ borderRadius: "15px" }}
      >
        <div style={{ padding: "7px 15px 0px 15px" }}>
          <button
            className="button button_white margin_bottom"
            onClick={this.toggle_edit_card}
          >
            {dict?.["Edit"]?.[language]}
          </button>
          <br />
          <button
            className="button button_white"
            onClick={this.toggle_delete_card}
          >
            {dict?.["Delete"]?.[language]}
          </button>
        </div>
      </Menu>
    );

    return (
      <main>
        {modal_menu_card_content}
        {card_list.map((item, idx) => {
          const { field_key_value, field_key_selection, feature_key_list } =
            item;
          const layer = layer_list.find(
            (element) => element?.geo_layer?._id === item?.layer_id
          );
          const features = layer?.geo_layer?.geojson?.features || [];
          const features_filtered = feature_key_list.map((feature_key) => {
            const feature = features.find((item) => item.key === feature_key);
            return feature;
          });
          const conditional_statement_list =
            item?.conditional_statement_list || [];
          let value_content;
          let logic_content, color_content;
          if (features_filtered.length === 0) {
            value_content = (
              <section>
                <div className="margin_bottom text_right">
                  <p className="text_small">2021 (sample)</p>
                  <p className="text_bold">23497232 (sample)</p>
                </div>
                <div className="margin_bottom text_right">
                  <p className="text_small">2022 (sample)</p>
                  <p className="text_bold">44497232 (sample)</p>
                </div>
              </section>
            );
          } else {
            value_content = (
              <>
                {features_filtered.map((feature, idx) => {
                  let value = feature?.properties?.[field_key_value];
                  const selection = feature?.properties?.[field_key_selection];
                  if (!isNaN(value)) {
                    value = new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    }).format(value);
                  }
                  const properties = feature?.properties || {};
                  if (conditional_statement_list.length > 0) {
                    logic_content = calculate_conditional_statement(
                      conditional_statement_list,
                      properties
                    );
                    color_content = conditional_statement_list.find(
                      (item) => item.then === logic_content
                    )?.color;
                  }
                  return (
                    <section key={idx} className="margin_bottom text_right">
                      <p className="text_small">{selection}</p>
                      <p className="text_bold">{value}</p>
                    </section>
                  );
                })}
              </>
            );
          }
          return (
            <div key={idx} className="bi_card_item margin_right margin_bottom">
              <section
                className="bi_card_title two_container_small_right"
                style={{ width: "100%", boxSizing: "border-box" }}
              >
                <p className="text_left text_medium max_two_lines margin_right">
                  {item.name}
                </p>
                {mode === "edit" && (
                  <button
                    onClick={this.toggle_menu_card}
                    className="button almost_white"
                    id={item?._id}
                  >
                    ⫶
                  </button>
                )}
              </section>
              <section className="bi_card_value">
                {value_content}
                {logic_content && (
                  <section className="text_right">
                    <div
                      className="badge"
                      style={{ backgroundColor: color_content }}
                    >
                      {logic_content}
                    </div>
                  </section>
                )}
              </section>
            </div>
          );
        })}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
  properties: state.properties,
});

export default connect(mapStateToProps, { set_value_bi })(BI_CARD_LIST);
