//General Function
import dict from "../../Data/dict.json";

const get_industries = (language) => {
  return [
    {
      value: "Information Technology and Services",
      title: dict["Information Technology and Services"][language],
    },
    {
      value: "Government",
      title: dict["Government"][language],
    },
    {
      value: "Real Estate and Architecture",
      title: dict["Real Estate and Architecture"][language],
    },
    {
      value: "Mining and Metals",
      title: dict["Mining and Metals"][language],
    },
    {
      value: "Oil and Energy",
      title: dict["Oil and Energy"][language],
    },
    {
      value: "Research and Education",
      title: dict["Research and Education"][language],
    },
    {
      value: "Nonprofit Organization Management",
      title: dict["Nonprofit Organization Management"][language],
    },
    {
      value: "Internet and Telecommunications",
      title: dict["Internet and Telecommunications"][language],
    },
    {
      value: "Farming and Agriculture",
      title: dict["Farming and Agriculture"][language],
    },
    {
      value: "Banking and Financial Services",
      title: dict["Banking and Financial Services"][language],
    },
    {
      value: "Consumer Goods",
      title: dict["Consumer Goods"][language],
    },
    {
      value: "Logistics and Supply Chain",
      title: dict["Logistics and Supply Chain"][language],
    },
    {
      value: "Retail and Fashion",
      title: dict["Retail and Fashion"][language],
    },
    {
      value: "Manufacture",
      title: dict["Manufacture"][language],
    },
    {
      value: "Transportation and Mobility",
      title: dict["Transportation and Mobility"][language],
    },
    {
      value: "Food and Beverages",
      title: dict["Food and Beverages"][language],
    },
    {
      value: "Hospital and Health Care",
      title: dict["Hospital and Health Care"][language],
    },
    {
      value: "Hospitality, Entertainment, and Tourism",
      title: dict["Hospitality, Entertainment, and Tourism"][language],
    },
    {
      value: "Media and Public Relations",
      title: dict["Media and Public Relations"][language],
    },
    {
      value: "Fishery and Marine",
      title: dict["Fishery and Marine"][language],
    },
  ];
}

export default get_industries;