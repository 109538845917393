/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import BI_LOGIC_EDIT from "./BI_LOGIC_EDIT";
import Modal from "../common_modal/Modal";

/*REDUX*/
import {
  set_value_bi,
  edit_inside_object,
  push_card,
  edit_card,
} from "../../App/actions/biActions";

/*PICTURE*/

/*FUNCTION*/
import { simpleConvertFieldsToHeaders } from "../../App/validation/convert_data";

/*DATA*/
import dict from "../../Data/dict.json";
import st_colors from "../../Data/st_colors.json";

/*CONST*/

class BI_ADD_STEP_6_LOGIC extends Component {
  state = {
    edited_index: -1,
    modal_edit: false,
    modal_color: false,
  };

  on_use_template = (template) => {
    const { project_object_selected, card_add_object } = this.props.bi;
    const layer_list = project_object_selected?.layer_list || [];
    const layer_id = card_add_object?.layer_id;
    const layer = layer_list.find((item) => item?.geo_layer?._id === layer_id);
    const geo_layer = layer?.geo_layer || {};
    const fields = geo_layer?.fields || [];
    const field_key_value = card_add_object?.field_key_value;
    const field = fields.find((item) => item.key === field_key_value);

    let conditional_statement_list = [];

    if (template === 2) {
      conditional_statement_list = [
        {
          function: "if",
          if: [
            {
              type: "variable",
              content: field.name,
              ref_key: field.key,
            },
            { type: "math_symbol", content: "<=" },
            { type: "constant", content: "0" },
          ],
          then: "Tidak layak",
          color: "#cf0e0f",
        },
        {
          function: "if",
          if: [
            {
              type: "variable",
              content: field.name,
              ref_key: field.key,
            },
            { type: "math_symbol", content: ">" },
            { type: "constant", content: "0" },
          ],
          then: "Layak",
          color: "#6cae00",
        },
      ];
    } else {
      conditional_statement_list = [
        {
          function: "if",
          if: [
            {
              type: "variable",
              content: "angka",
              ref_key: "946b475f-df0f-451d-ac73-b45bee2f46c6",
            },
            {
              type: "operator",
              content: "<=",
            },
            {
              type: "constant",
              content: "0",
            },
          ],
          then: "Kemungkinan 1",
          color: "#3689c3",
        },
        {
          function: "if",
          if: [
            {
              type: "variable",
              content: "angka",
              ref_key: "946b475f-df0f-451d-ac73-b45bee2f46c6",
            },
            {
              type: "math_symbol",
              content: ">",
            },
            {
              type: "constant",
              content: "0",
            },
            {
              type: "operator",
              content: "&&",
            },
            {
              type: "variable",
              content: "angka",
              ref_key: "946b475f-df0f-451d-ac73-b45bee2f46c6",
            },
            {
              type: "operator",
              content: "<=",
            },
            {
              type: "constant",
              content: "10",
            },
          ],
          then: "Kemungkinan 2",
          color: "#cf0e0f",
        },
        {
          function: "if",
          if: [
            {
              type: "variable",
              content: "angka",
              ref_key: "946b475f-df0f-451d-ac73-b45bee2f46c6",
            },
            {
              type: "math_symbol",
              content: ">",
            },
            {
              type: "constant",
              content: "10",
            },
          ],
          then: "Kemungkinan 3",
          color: "#6cae00",
        },
      ];
    }

    this.props.edit_inside_object({
      key: "card_add_object",
      id_key: "conditional_statement_list",
      value: conditional_statement_list,
    });
  };

  toggle_color = (edited_index) => {
    this.setState({ modal_color: !this.state.modal_color });
    if (edited_index !== undefined) this.setState({ edited_index });
  };

  toggle_edit = (edited_index) => {
    this.setState({ modal_edit: !this.state.modal_edit });
    if (edited_index !== undefined) this.setState({ edited_index });
  };

  on_change_color = (color) => {
    const { edited_index } = this.state;
    const { card_add_object } = this.props.bi;
    let { conditional_statement_list } = card_add_object;
    conditional_statement_list[edited_index].color = color;
    this.props.edit_inside_object({
      key: "card_add_object",
      id_key: "conditional_statement_list",
      value: conditional_statement_list,
    });
  };

  on_delete_item = (index) => {
    const { card_add_object } = this.props.bi;
    let { conditional_statement_list } = card_add_object;
    conditional_statement_list = conditional_statement_list.filter(
      (item, idx) => idx !== index
    );
    this.props.edit_inside_object({
      key: "card_add_object",
      id_key: "conditional_statement_list",
      value: conditional_statement_list,
    });
  };

  on_add_if_statement = () => {
    const { card_add_object } = this.props.bi;
    let { conditional_statement_list } = card_add_object;
    const item = {
      function: "if",
      if: [],
      then: "",
      color: st_colors[conditional_statement_list.length],
    };
    conditional_statement_list.push(item);
    this.props.edit_inside_object({
      key: "card_add_object",
      id_key: "conditional_statement_list",
      value: conditional_statement_list,
    });
  };

  on_add_else_statement = () => {
    const { card_add_object } = this.props.bi;
    let { conditional_statement_list } = card_add_object;
    const item = {
      function: "else",
      then: "",
      color: st_colors[conditional_statement_list.length],
    };
    conditional_statement_list.push(item);
    this.props.edit_inside_object({
      key: "card_add_object",
      id_key: "conditional_statement_list",
      value: conditional_statement_list,
    });
  };

  on_prev_step = () => {
    const { add_card_current_step } = this.props.bi;
    this.props.set_value_bi({
      key: "add_card_current_step",
      value: add_card_current_step - 1,
    });
  };

  handleConditionalStatementList = (value) => {
    this.props.edit_inside_object({
      key: "card_add_object",
      id_key: "conditional_statement_list",
      value: value,
    });
  };

  on_submit = () => {
    const { bi_object, project_object_selected, card_add_object } =
      this.props.bi;
    const {
      layer_id,
      name,
      mode,
      field_key_value,
      field_key_selection,
      default_value,
      feature_key_list,
      conditional_statement_list,
    } = card_add_object;
    let body = {
      _id: bi_object?._id,
      project_id: project_object_selected?._id,
      layer_id,
      name,
      mode,
      field_key_value,
      field_key_selection,
      default_value,
      feature_key_list,
      conditional_statement_list,
    };
    if (!!card_add_object?._id) {
      body.card_id = card_add_object?._id;
      this.props.edit_card(body);
    } else {
      this.props.push_card(body);
    }
    this.props.set_value_bi({
      key: "modal_add_card",
      value: false,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { edited_index, modal_edit, modal_color } = this.state;

    //global props
    const { card_add_object, project_object_selected } = this.props.bi;

    //content
    const {
      conditional_statement_list,
      name,
      mode,
      feature_key_list,
      field_key_value,
      default_value,
    } = card_add_object;
    let layer_list = project_object_selected?.layer_list || [];
    const layer_id = card_add_object?.layer_id;
    const layer_object = layer_list.find(
      (item) => item?.geo_layer?._id === layer_id
    );
    const fields = layer_object?.geo_layer?.fields || [];
    const headers = simpleConvertFieldsToHeaders(fields);

    const is_there_else =
      conditional_statement_list.filter((item) => item?.function === "else")
        ?.length > 0;
    const current_color = conditional_statement_list?.[edited_index]?.color;

    let button_content = (
      <button className="button background_grey outline_black">
        {card_add_object?._id
          ? dict?.["Edit data"]?.[language]
          : dict?.["Add data"]?.[language]}
      </button>
    );

    if (
      !!name &&
      !!mode &&
      !!layer_id &&
      !!feature_key_list.length > 0 &&
      !!field_key_value &&
      default_value !== undefined
    ) {
      button_content = (
        <button
          className="button background_blue outline_blue"
          onClick={this.on_submit}
        >
          {card_add_object?._id
            ? dict?.["Edit data"]?.[language]
            : dict?.["Add data"]?.[language]}
        </button>
      );
    }

    const modal_edit_content = modal_edit && (
      <Modal modalSize="large" isOpen={modal_edit} onClose={this.toggle_edit}>
        <div className="box-body" id="box-body">
          <BI_LOGIC_EDIT
            headers={headers}
            language={language}
            conditional_statement_index_that_will_be_edited={edited_index}
            handleConditionalStatementList={this.handleConditionalStatementList}
            conditional_statement_list={conditional_statement_list}
          />
        </div>
      </Modal>
    );

    const modal_color_content = modal_color && (
      <Modal modalSize="small" isOpen={modal_color} onClose={this.toggle_color}>
        <div className="box-body" id="box-body">
          <table>
            <tbody>
              {st_colors.map((item, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      <button
                        className={
                          current_color === item
                            ? "checklist_active"
                            : "checklist_not"
                        }
                        onClick={this.on_change_color.bind(this, item)}
                      />
                    </td>
                    <td>
                      <div
                        style={{
                          width: "15px",
                          height: "15px",
                          backgroundColor: item,
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Modal>
    );

    return (
      <main>
        {modal_edit_content}
        {modal_color_content}
        <h1 className="text_bold margin_bottom">
          Enter logic text & color (optional)
        </h1>
        <button
          className="button margin_right margin_bottom background_blue"
          onClick={this.on_use_template.bind(this, 2)}
        >
          Gunakan template dua rentang
        </button>
        <button
          className="button margin_right margin_bottom background_blue"
          onClick={this.on_use_template.bind(this, 3)}
        >
          Gunakan template tiga rentang
        </button>
        <table className="table full_width" style={{ tableLayout: "fixed" }}>
          <tbody>
            <tr>
              <td>Jika</td>
              <td>Maka</td>
              <td>Warna</td>
            </tr>
            {conditional_statement_list.map((item, idx) => {
              const if_content = item?.if || [];
              let if_render = [];
              if_content.forEach((item, idx) => {
                if_render.push(
                  <p
                    className={`badge_small margin_right background_orange`}
                    key={idx}
                  >
                    {item?.content}
                  </p>
                );
              });
              return (
                <tr key={idx}>
                  <td>
                    {item?.function === "if" ? (
                      <>
                        <button
                          className="button_small margin_right background_blue"
                          onClick={this.toggle_edit.bind(this, idx)}
                        >
                          Edit
                        </button>
                        {if_render}
                      </>
                    ) : (
                      <>
                        <div className="badge_small background_red">Else</div>
                      </>
                    )}
                  </td>
                  <td>
                    <button
                      className="button_small margin_right background_blue"
                      onClick={this.toggle_edit.bind(this, idx)}
                    >
                      Edit
                    </button>
                    {item?.then}
                  </td>
                  <td>
                    <div
                      style={{
                        width: "50px",
                        height: "15px",
                        backgroundColor: item?.color,
                        display: "inline-block",
                      }}
                      className="margin_right"
                    />
                    <button
                      className="button_small margin_right background_blue"
                      onClick={this.toggle_color.bind(this, idx)}
                    >
                      Edit warna
                    </button>
                    <button
                      className="button_small background_red"
                      onClick={this.on_delete_item.bind(this, idx)}
                    >
                      X
                    </button>
                  </td>
                </tr>
              );
            })}
            <tr>
              <td>
                <button
                  className="button_small margin_right margin_bottom background_blue"
                  onClick={this.on_add_if_statement}
                >
                  Tambah logika "jika-maka"
                </button>
              </td>
              <td colSpan={2}>
                {!is_there_else && (
                  <button
                    className="button_small background_blue"
                    onClick={this.on_add_else_statement}
                  >
                    Atur keluaran untuk kondisi lain
                  </button>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        {button_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
});

export default connect(mapStateToProps, {
  set_value_bi,
  push_card,
  edit_inside_object,
  edit_card,
})(BI_ADD_STEP_6_LOGIC);
