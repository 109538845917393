const initial_state = {
  loading_item: "",
  loading_status: false,
  status_toolbox: 0,
  draw_toolbox_status: false,
  mode_toolbox: "features_distance", //features_distance || features_area || features_elevation || features_radius
  mode_draw: "draw_line_string", //draw_point || draw_line_string || draw_polygon || simple_select
  draw_status_toolbox: false,
  geojson_draw: { type: "FeatureCollection", features: [] },
  //features basic for map
  features_distance: [],
  features_distance_label: [],
  features_area: [],
  features_elevation: [],
  features_elevation_point: [],
  features_radius: [],
  features_isokron: [],
  //features for label
  features_distance_center: [],
  features_area_center: [],
  features_elevation_center: [],
  features_isokron_center: [],
  //elevation data
  elevation_chart_array: [],
  //uuid for selected item
  uuid_distance: "",
  uuid_area: "",
  uuid_elevation: "",
  uuid_radius: "",
  //default value for radius
  default_radius_km: 1,
  default_radius_m: 1000,
  //default value for isochrone
  routing_profile: "driving",
  contour_type: "minutes",
  contours_minutes: 10,
  contours_meters: 1000,
  //input toolbox grid
  toolbox_grid_object: {
    layer_point_id: "",
    layer_polygon_id: "",
    grid_mode: "square", //square, hex
    grid_size_mode: "count", //count, width
    grid_counts: 200,
    grid_width_meters: 5,
    bbox_mode: "polygon", //point, polygon
    unit_density: "m_square", //m_square, km_square
    total_square_meters: 0,
    total_poi_initial: 0,
    item_name: "", //bebas isi
    min_grid_value: 0,
    max_grid_value: 1,
  },
  //variable for grid heatmap toolbox
  layer_list_point_raw: [],
  layer_list_point_filtered: [],
  layer_list_polygon_raw: [],
  layer_list_polygon_filtered: [],
  //output grid result
  select_mode: "", //point || polygon
  current_grid_group: 0,
  total_grid_group: 1,
  features_grid_raw: [],
  features_grid: [],
};

export default function toolboxReducer(state = initial_state, action) {
  switch (action.type) {
    case "status_toolbox":
      return {
        ...state,
        status_toolbox: state.status_toolbox + 1,
      };

    case "set_loading_toolbox":
      return {
        ...state,
        loading_item: action.payload,
        loading_status: true,
      };

    case "clear_loading_toolbox":
      return {
        ...state,
        loading_item: "",
        loading_status: false,
      };

    case "set_value_toolbox":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case "push_value_toolbox":
      return {
        ...state,
        [action.payload.key]: [
          ...state[action.payload.key],
          ...action.payload.value,
        ],
      };

    case "push_value_toolbox_single":
      return {
        ...state,
        [action.payload.key]: [
          ...state[action.payload.key],
          action.payload.value,
        ],
      };

    case "push_value_toolbox_multiple":
      return {
        ...state,
        [action.payload.key]: [
          ...state[action.payload.key],
          ...action.payload.value,
        ],
      };

    case "change_radius":
      return {
        ...state,
        features_radius: change_radius(state.features_radius, action.payload),
      };

    default:
      return state;
  }
}

const change_radius = (list, body) => {
  const { uuid, key, value } = body;
  const index = list.findIndex((item) => item.properties.uuid === uuid);
  list[index].properties[key] = value;
  if (key === "radius_m") {
    const radius_km = value / 1000;
    list[index].properties["radius_km"] = radius_km;
  } else if (key === "radius_km") {
    const radius_m = value * 1000;
    list[index].properties["radius_m"] = radius_m;
  }
  return list;
};
