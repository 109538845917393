/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
// import Path from "./Path";
// import Modal from "../reuseable/Modal";
import ModalLayerCustomApi from "./ModalLayerCustomApi";

/*REDUX FUNCTION*/
import {
  create_layer_custom_api,
  request_direct_to_user_api,
  // request_by_client_api,
} from "../../App/actions/openApiActions";

/*PICTURE ASSET*/

/*GENERAL*/
import dict from "../../Data/dict.json";
import ApiTable from "./ApiTable";
import uuid from "../../App/validation/uuid";
// import { get_nested_object } from "../../App/validation/nested_object";
import { getGeoLayer } from "../../App/reduxHelper/layer";
import {
  lineString,
  point,
  polygon,
} from "../../App/validation/geojson_validation";
import ICON_SUCCESS from "../../Assets/jsx/ICON_SUCCESS";

/*VARIABLES*/
const http_methods = [
  {
    key: "post",
    name: "POST",
    color: "#c8a000",
  },
  {
    key: "get",
    name: "GET",
    color: "#297e21",
  },
];

const geometry_types = [
  {
    key: "point",
    name: "Point",
  },
  {
    key: "polygon",
    name: "Polygon",
  },
  {
    key: "linstring",
    name: "LineString",
  },
];

class ApiInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layer_name: "",
      description: "",
      folder: "",
      modal_layer_name: false,
      geometry_type: "Point",
      page: "Test",
      response: {},
      // latitude_path: [],
      // longitude_path: [],
      // properties_path: [],
      // features_path: [],
      // total_data_path: [],
      // is_total_data_valid: false,
      is_feature_valid: false,
      read_open_api: {
        api_endpoint: "",
        http_method: "get",
        headers: [],
        params: [],
        body: [],
      },
    };
  }

  componentDidMount = () => {
    const layer = this.props.layer;
    // const from = this.props.from
    // if (from === "editor") {
    const geo_layer = getGeoLayer(layer);

    const headers = this.createRows(geo_layer?.headers) || [];
    const params = this.createRows(geo_layer?.params) || [];
    const body = this.createRows(geo_layer?.body) || [];

    this.setState({
      geometry_type: geo_layer?.geometry_type || "Point",
      response: {},
      // latitude_path: geo_layer?.latitude_path || [],
      // longitude_path: geo_layer?.longitude_path || [],
      // properties_path: geo_layer?.properties_path || [],
      // features_path: geo_layer?.features_path || [],
      read_open_api: {
        api_endpoint: geo_layer?.api_endpoint || "",
        http_method: geo_layer?.http_method || "get",
        headers: [...headers, this.createRow("Headers")],
        params: [...params, this.createRow("Params")],
        body: [...body, this.createRow("Body")],
      },
    });
    // }
  };

  componentDidUpdate(_, prevState) {
    // const { geometry_type, response } = this.state

    const response_old = prevState?.response;
    const response_new = this.state?.response;
    if (JSON.stringify(response_old) !== JSON.stringify(response_new)) {
      const response = response_new?.data || {};
      if (
        response?.type === "FeatureCollection" &&
        Array.isArray(response?.features)
      ) {
        // const validity = this.check_validity(response?.features);
        // console.log("validity", validity)
        this.setState({
          is_feature_valid: true,
          // validity?.is_feature_valid
        });

        const first_feature_data_type = response?.features?.[0]?.geometry?.type;
        if (first_feature_data_type) {
          this.setState({
            geometry_type: first_feature_data_type,
          });
        }
      }
    }

    // const feature_path_old = prevState?.features_path;
    // const feature_path_new = this.state?.features_path;
    // if (JSON.stringify(feature_path_old) !== JSON.stringify(feature_path_new)) {
    //   const selected_features_obj = get_nested_object(response?.data || {}, feature_path_new) || {};
    //   const validity = this.check_validity(selected_features_obj, geometry_type);
    //   this.setState({
    //     is_feature_valid: validity?.is_feature_valid
    //   })
    // }

    // const total_data_path_old = prevState?.total_data_path;
    // const total_data_path_new = this.state?.total_data_path;
    // if (JSON.stringify(total_data_path_old) !== JSON.stringify(total_data_path_new)) {
    //   const selected_total_data_obj = get_nested_object(response?.data || {}, total_data_path_new) || {};
    //   if (!isNaN(selected_total_data_obj)) {
    //     this.setState({
    //       is_total_data_valid: true
    //     })
    //   } else {
    //     this.setState({
    //       is_total_data_valid: false
    //     })
    //   }
    // }
  }

  handle_next = () => {
    const { modal_layer_name } = this.state;
    this.setState({
      modal_layer_name: !modal_layer_name,
    });
  };

  handle_folder = (e) => {
    this.setState({
      folder: e?.target?.value,
    });
  };

  handle_save = async () => {
    const { layer_name, geometry_type, description, folder, read_open_api } =
      this.state;
    const { geo_project } = this.props.project;

    const { headers, params, body } = read_open_api;

    const body_request = {
      name: layer_name,
      description,
      type: geometry_type,
      folder,
      user_id: this.props.auth.user._id,
      geo_project_id: geo_project._id,
      is_custom_endpoint: true,
      read_open_api: {
        ...read_open_api,
        headers: headers.slice(0, headers?.length - 1),
        params: params.slice(0, params?.length - 1),
        body: body.slice(0, body?.length - 1),
      },
      status: "original",
      architecture: "api",
      is_show_and_fly: true,
      properties: [
        {
          key: "fill_color",
          name: "color",
          type: "color",
          array_templates: [],
          isHighlight: false,
          isHide: true,
          isStyle: true,
          defaultValue: "#1E5E96",
        },
        {
          key: "opacity",
          name: "opacity",
          type: "range",
          array_templates: [],
          isHighlight: false,
          isHide: true,
          isStyle: true,
          defaultValue: 0.5,
          min: 0,
          max: 1,
          step: 0.1,
        },
        {
          key: "outline_color",
          name: "outline_color",
          type: "color",
          array_templates: [],
          isHighlight: false,
          isHide: true,
          isStyle: true,
          defaultValue: "#1E5E96",
        },
        {
          key: "outline_width",
          name: "outline_width",
          type: "number",
          array_templates: [],
          isHighlight: false,
          isHide: true,
          isStyle: true,
          defaultValue: 1,
        },
        {
          key: "text_field",
          name: "label",
          type: "text",
          array_templates: [],
          isHighlight: false,
          isHide: true,
          isStyle: true,
          defaultValue: "point",
        },
        {
          key: "is_using_label",
          name: "Use label",
          type: "checkbox",
          array_templates: [],
          isHighlight: false,
          isHide: true,
          isStyle: true,
          defaultValue: false,
        },
      ],
    };

    await this.props.create_layer_custom_api(body_request);

    if (this.props?.toggle_modal_api_input) {
      this.props?.toggle_modal_api_input()
    }

  };

  params_merge = () => {
    const { params } = this.state?.read_open_api;
    const result = params
      .slice(0, params?.length - 1)
      .map((param, index) => this.convert_to_url(param, index));
    return result.join("");
  };

  convert_to_url = (param, index) => {
    if (index === 0) {
      return `${param?.key ? `?${param?.key}` : ""}${param?.value ? `=${param?.value}` : ""
        }`;
    } else {
      return `${param?.key ? `&${param?.key}` : ""}${param?.value ? `=${param?.value}` : ""
        }`;
    }
  };

  convert_to_api_attributes = (data) => {
    let result = {};
    data.forEach((item) => {
      result[item?.key] = item?.value;
    });
    return result;
  };

  send_request = async () => {
    const { geometry_type, read_open_api } = this.state;
    const { http_method, api_endpoint, headers, body } = read_open_api;

    if (api_endpoint) {
      const response = await this.props.request_direct_to_user_api({
        http_method,
        api_endpoint,
        geometry_type,
        headers: this.convert_to_api_attributes(
          headers.slice(0, headers?.length - 1)
        ),
        params: this.params_merge(),
        body: this.convert_to_api_attributes(body.slice(0, body?.length - 1)),
      });

      this.setState({
        response,
      });
    }
  };

  createRow = () => {
    return { id: uuid(), key: "", value: "" };
  };

  createRows = (rows_object) => {
    const result = [];
    for (const key in rows_object) {
      result.push({
        id: uuid(),
        key: key,
        value: rows_object[key],
      });
    }
    return result;
  };

  handle_change = (e) => {
    const { name, value } = e?.target;
    this.setState({
      [name]: value,
    });
  };

  handle_change_read_open_api = (e) => {
    const { read_open_api } = this.state;
    const { name, value } = e?.target;
    this.setState({
      read_open_api: {
        ...read_open_api,
        [name]: value,
      },
    });
  };

  get_http_color = (method) => {
    const color =
      http_methods.find((item) => item.key === method)?.color || "#000000";
    return color;
  };

  set_page = (page) => {
    this.setState({
      page,
    });
  };

  delete_row = (id, type) => {
    const { read_open_api } = this.state;
    const data = this.state?.[`${type?.toLowerCase()}`]?.filter(
      (header) => header?.id !== id
    );

    this.setState({
      read_open_api: {
        ...read_open_api,
        [type?.toLowerCase()]: data,
      },
    });
  };

  edit_cell = (type, row) => {
    const { read_open_api } = this.state;
    const lowerCasetype = type?.toLowerCase();
    let array_of_object = structuredClone(read_open_api?.[lowerCasetype]);
    const index = array_of_object.findIndex((item) => item?.id === row?.id);
    if (index >= 0) {
      array_of_object[index] = row;

      const last_object = array_of_object?.at(-1);
      if (last_object?.key !== "" || last_object?.value !== "") {
        array_of_object.push(this.createRow());
      }
      this.setState({
        read_open_api: {
          ...read_open_api,
          [lowerCasetype]: array_of_object,
        },
      });
    }
  };

  handle_add_path = (path_type, value) => {
    if (path_type === "features_path") {
      const { features_path } = this.state;
      this.setState({
        features_path: [...features_path, value],
      });
    } else if (path_type === "total_data_path") {
      const { total_data_path } = this.state;
      this.setState({
        total_data_path: [...total_data_path, value],
      });
    }
  };

  handle_delete_path = (path_type) => {
    if (path_type === "features_path") {
      const { features_path } = this.state;
      this.setState({
        features_path: features_path.slice(0, features_path.length - 1),
      });
    } else if (path_type === "total_data_path") {
      const { total_data_path } = this.state;
      this.setState({
        total_data_path: total_data_path.slice(0, total_data_path.length - 1),
      });
    }
  };

  check_validity = (selected_features_obj, geometry_type) => {
    if (Array.isArray(selected_features_obj)) {
      let index = 0;
      while (index < 3 && selected_features_obj?.[index]) {
        const feature = selected_features_obj?.[index];
        let validity = {
          is_feature_valid: true,
          error: "",
        };

        if (geometry_type === "Point" && feature?.geometry?.type === "Point") {
          validity = point(feature?.geometry);
        } else if (
          geometry_type === "Polygon" &&
          feature?.geometry?.type === "Polygon"
        ) {
          validity = polygon(feature?.geometry);
        } else if (
          geometry_type === "LineString" &&
          feature?.geometry?.type === "LineString"
        ) {
          validity = lineString(feature?.geometry);
        } else {
          validity.is_feature_valid = false;
          validity.error = `${feature?.geometry?.type} type is not supported`;
        }

        if (!validity?.is_feature_valid) return validity;
        index++;
      }
      return {
        is_feature_valid: true,
        error: "",
      };
    }
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const {
      geometry_type,
      page,

      layer_name,
      description,
      response,
      // latitude_path,
      // longitude_path,
      // properties_path,
      // features_path,
      // total_data_path,
      // is_total_data_valid
      modal_layer_name,
      folder,
      is_feature_valid,
      read_open_api,
    } = this.state;

    const { http_method, headers, params, body, api_endpoint } = read_open_api;

    //global props
    const folders = this.props.project?.geo_project?.folders;
    const from = this.props.from;

    //content

    return (
      <main>
        {!modal_layer_name ? (
          <>
            <section className="flex gap_40"></section>
            <section className="flex gap_5">
              <select
                name="http_method"
                style={{
                  color: this.get_http_color(http_method),
                }}
                value={http_method}
                onChange={this.handle_change_read_open_api}
                className="w_100"
              >
                {http_methods.map((method) => (
                  <option
                    style={{ color: method.color }}
                    key={method.key}
                    value={method.key}
                  >
                    {method.name}
                  </option>
                ))}
              </select>
              <input
                name="api_endpoint"
                type="text"
                value={api_endpoint}
                onChange={this.handle_change_read_open_api}
                placeholder={dict["Enter URL"][language]}
              />
              <div
                onClick={() => {
                  this.send_request();
                }}
                className="center_perfect pointer bg_blue text_white button_inactive rounded_5 font_18 w_100 h_40"
              >
                {`Send >`}
              </div>
            </section>

            <section className="flex justify_between marginTop_10 marginBottom_10 h_40">
              <div className="font_16 center_perfect gap_10 bg_white padding_x_10 rounded_5">
                <div
                  className={`pointer noselect ${page === "Headers" ? "underline bold" : ""
                    }`}
                  onClick={() => this.set_page("Headers")}
                >
                  Headers
                </div>
                <div
                  className={`pointer noselect ${page === "Params" ? "underline bold" : ""
                    }`}
                  onClick={() => this.set_page("Params")}
                >
                  Params
                </div>
                <div
                  className={`pointer noselect ${page === "Body" ? "underline bold" : ""
                    }`}
                  onClick={() => this.set_page("Body")}
                >
                  Body
                </div>
                <div
                  className={`pointer noselect ${page === "Test" ? "underline bold" : ""
                    }`}
                  onClick={() => this.set_page("Test")}
                >
                  Request Test
                </div>
              </div>

              <div className="flex align_end gap_10">
                <div className="flex justify_end align_center text_right h_50">
                  <label className="text_right">
                    {dict["Geometry Type"][language]}
                  </label>
                </div>
                <select
                  name="geometry_type"
                  value={geometry_type}
                  onChange={this.handle_change}
                  className="w_100"
                >
                  {geometry_types.map((method) => (
                    <option key={method.key} value={method.name}>
                      {method.name}
                    </option>
                  ))}
                  {!geometry_types
                    .map((method) => method?.name)
                    .includes(geometry_type) && (
                      <option
                        key={geometry_type?.toLowerCase()}
                        value={geometry_type}
                      >
                        {geometry_type}
                      </option>
                    )}
                </select>
              </div>
            </section>

            {page === "Headers" && (
              <section className="marginBottom_10">
                <ApiTable
                  title="Headers"
                  data={headers}
                  edit_cell={this.edit_cell}
                  delete_row={this.delete_row}
                />
              </section>
            )}
            {page === "Params" && (
              <section className="marginBottom_10">
                <ApiTable
                  title="Params"
                  full_url={`${api_endpoint}${this.params_merge()}`}
                  data={params}
                  edit_cell={this.edit_cell}
                  delete_row={this.delete_row}
                />
              </section>
            )}
            {page === "Body" && (
              <section className="marginBottom_10">
                <ApiTable
                  title="Body"
                  data={body}
                  edit_cell={this.edit_cell}
                  delete_row={this.delete_row}
                />
              </section>
            )}

            {page === "Test" && (
              <>
                <section className="marginTop_20 marginBottom_10">
                  <div className="flex justify_between">
                    <label>Response</label>
                    <label>Status: {response.status}</label>
                  </div>
                  <textarea
                    value={JSON.stringify(response.data, null, 2)}
                    onChange={() => { }}
                    className="padding_10 pre h_300 border_grey rounded_5 scroll_y_auto bg_white monospace"
                  />
                </section>

                {/* {response?.status === 200 &&
                  <section className="bg_white marginBottom_10 min_h_75 max_h_200 scroll_y_auto border_grey padding_5 rounded_5">
                    <div className="">

                    </div>
                  </section>} */}

                {/* <section className="flex gap_10 w_full">
                  <div className="w_80_percent">
                    {response?.status === 200 && (
                      <Path
                        handle_delete_path={this.handle_delete_path}
                        handle_add_path={this.handle_add_path}
                        path={features_path}
                        object={response.data}
                        title="Array of geojson features"
                      />
                    )}
                  </div>
                </section> */}

                {/* {response?.status === 200 &&
                  <section className="bg_white marginBottom_10 min_h_75 max_h_200 scroll_y_auto border_grey padding_5 rounded_5">
                    <div className="">
                      <label className="bold">Total data (for pagination)</label>
                      <div className="flex">
                        <label className="w_20 center_perfect">{">"}</label>
                        <Path
                          handle_delete_path={this.handle_delete_path}
                          handle_add_path={this.handle_add_path}
                          variable_name="total_data_path"
                          path={total_data_path}
                          object={response.data}
                          title="Path"
                          is_valid={is_total_data_valid}
                        />
                      </div>
                      <hr className="margin_y_2" />
                      <label className="bold">Array of geojson features</label>
                      <div className="flex">
                        <label className="w_20 center_perfect">{">"}</label>
                        <Path
                          handle_delete_path={this.handle_delete_path}
                          handle_add_path={this.handle_add_path}
                          variable_name="features_path"
                          path={features_path}
                          object={response.data}
                          title="Path"
                          is_valid={is_feature_valid}
                        />
                      </div>
                    </div>
                  </section>} */}

                {/* {
                  Array.isArray(selected_features_path) &&
                  (
                    <section className="bg_white marginBottom_10 min_h_75 max_h_200 scroll_y_auto border_grey padding_5 rounded_5">
                      <div className="">
                        <div className="flex">
                          <label className="w_20 center_perfect">{">"}</label>
                          <Path
                            handle_delete_path={this.handle_delete_path}
                            handle_add_path={this.handle_add_path}
                            path={properties_path}
                            object={selected_features_path?.[0] || {}}
                            title="Properties"
                          />
                        </div>
                        <hr className="margin_y_2" />
                        <div className="flex">
                          <label className="w_20 center_perfect">{">"}</label>
                          <Path
                            handle_delete_path={this.handle_delete_path}
                            handle_add_path={this.handle_add_path}
                            path={latitude_path}
                            object={selected_features_path?.[0] || {}}
                            title="Latitude"
                          />
                        </div>
                        <hr className="margin_y_2" />
                        <div className="flex">
                          <label className="w_20 center_perfect">{">"}</label>
                          <Path
                            handle_delete_path={this.handle_delete_path}
                            handle_add_path={this.handle_add_path}
                            path={longitude_path}
                            object={selected_features_path?.[0] || {}}
                            title="Longitude"
                          />
                        </div>
                      </div>
                    </section>
                  )} */}
              </>
            )}
            {response?.status === 200 && from !== "editor" && (
              <div className="flex justify_end marginTop_10 gap_10">
                {is_feature_valid && (
                  <div className="flex justify_end">
                    <ICON_SUCCESS alt={"read as geojson"} />
                  </div>
                )}
                <div
                  onClick={() => this.handle_next()}
                  className="pointer bg_blue text_white button_inactive padding_5 rounded_5 font_18"
                >
                  Next
                </div>
              </div>
            )}
          </>
        ) : (
          <ModalLayerCustomApi
            handle_change={this.handle_change}
            folder={folder}
            folders={folders}
            layer_name={layer_name}
            description={description}
            handle_back={this.handle_next}
            handle_save={this.handle_save}
          />
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  layer: state.layer,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  // request_by_client_api,
  request_direct_to_user_api,
  create_layer_custom_api,
})(ApiInput);
