import React from 'react'
import checklist_no_outline from "../svg/checklist_no_outline.svg"

function ICON_SUCCESS(props) {
  const { className, alt = "success" } = props

  return (
    <div className={`rounded_full bg_green center_perfect w_30 h_30 ${className}`}>
      <img className="h_15 w_15" src={checklist_no_outline} alt={alt} />
    </div>
  )
}

export default ICON_SUCCESS