/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  EditorState,
  RichUtils,
  convertFromRaw,
  CompositeDecorator,
} from "draft-js";
import Editor from "draft-js-plugins-editor";
import { Helmet } from "react-helmet";

/*COMPONENT*/
import Modal from "../../Components/common_modal/Modal";
import Login from "../../Components/auth/Login";
import Register from "../../Components/auth/Register";
import BlogListPublicCard from "../../Components/public/BlogListPublicCard";
import RequestFree from "../../Components/blog/RequestFree";
import ImportBlog from "../../Components/blog/ImportBlog";
import BarProcess from "../../Components/common_trivial/BarProcess";
import NavTitleBlog from "../../Components/blog/navTitleBlog";

/*REDUX*/
import {
  getBlogDetailPublicNew,
  voteBlog,
  clearBlog,
  addToCart,
  clearLayerBlogList,
} from "../../App/actions/blogAction";
import { setPathname } from "../../App/actions/authActions";

/*PICTURE*/
import official_account from "../../Assets/svg/official_account.svg";
import checklist from "../../Assets/svg/checklist.svg";
import icon_blog_public from "../../Assets/svg/icon_blog_public.svg";
import icon_blog_public_white from "../../Assets/svg/icon_blog_public_white.svg";
import icon_blog_free from "../../Assets/svg/icon_blog_free.svg";

/*FUNCTION*/
import isEmpty from "../../App/validation/is-empty";
import formatDate from "../../App/validation/format-date";

/*DATA*/
import dict from "../../Data/dict.json";
import domain_list from "../../Data/domain_list";

/*CONST*/
function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
}
const LinkNew = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a
      href={url}
      style={{
        color: "#3b5998",
        textDecoration: "underline",
      }}
      target="_blank"
      rel="noreferrer"
    >
      {props.children}
    </a>
  );
};

class BlogRead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalLogin: false,
      loginStatus: true,
      votesList: false,
      modal_request: false,
      modal_import: false,
      modal_cart: false,
      navbar_title: false,
    };
    this.myRef = React.createRef();
    this.focus = () => this.myRef.current.focus();
  }

  componentDidMount() {
    const link = this.props?.match?.params?.link;
    this.props.getBlogDetailPublicNew(link);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 500);
  }

  componentWillUnmount() {
    this.props.clearBlog();
    this.props.clearLayerBlogList();
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated) {
      this.setState({ modalLogin: false });
    }
    if (this.props.auth.isRegistered !== prevProps.auth.isRegistered) {
      this.setState({ loginStatus: true });
    }
    const loadingProcess_this = this.props.blog.loadingProcess;
    const loadingProcess_prev = prevProps.blog.loadingProcess;
    const itemLoading = this.props.blog.itemLoading;
    if (
      loadingProcess_this === false &&
      itemLoading === "request_free_import" &&
      loadingProcess_this !== loadingProcess_prev
    ) {
      this.setState({ modal_request: false });
    }
  }

  toggleCart = (blog) => {
    const { isAuthenticated } = this.props.auth;
    if (isAuthenticated) {
      this.setState({ modal_cart: !this.state.modal_cart });
      if (blog) {
        const body = {
          blog,
        };
        this.props.addToCart(body);
      }
    } else {
      this.setState({ modalLogin: true });
    }
  };

  toggleImport = () => {
    const { isAuthenticated } = this.props.auth;
    if (isAuthenticated) {
      this.setState({ modal_import: !this.state.modal_import });
    } else {
      this.setState({ modalLogin: true });
    }
  };

  onClickPathname = (pathname) => {
    this.props.setPathname(pathname);
  };

  toggleRequest = () => {
    const { isAuthenticated } = this.props.auth;
    if (isAuthenticated) {
      this.setState({ modal_request: !this.state.modal_request });
    } else {
      this.setState({ modalLogin: true });
    }
  };

  toggleLogin = () => {
    this.setState({ modalLogin: !this.state.modalLogin });
  };

  toggleLoginRegister = () => {
    this.setState({ loginStatus: !this.state.loginStatus });
  };

  toggleVoteList = () => {
    this.setState({ votesList: !this.state.votesList });
  };

  handleVote = () => {
    const { blog } = this.props.blog;
    const { user } = this.props.auth;
    const blog_id = blog._id;
    const body = { blog_id, user };
    this.props.voteBlog(body);
  };

  focus = () => this.refs.editor.focus();

  onChangeEditor = () => {};

  handleKeyCommand = (command) => {
    const { editorState } = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  };

  toggleBlockType = (blockType) => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  };

  toggleInlineStyle = (inlineStyle) => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
    );
  };
  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const {
      modalLogin,
      loginStatus,
      votesList,
      modal_request,
      modal_import,
      modal_cart,
    } = this.state;

    //global props
    const { domain, isAuthenticated, isRegistered, user } = this.props.auth;
    const user_id = user?._id;
    const { blog, loadingProcess, itemLoading } = this.props.blog;

    //content
    const market_status = blog?.market_status?.status;
    const price = blog?.market_status?.price;
    const request_list = blog?.request_list ? blog?.request_list : [];
    const request_list_free = request_list.filter(
      (request) =>
        request.user === user_id && request.status === "free_accepted"
    );
    const request_list_free_pending = request_list.filter(
      (request) => request.user === user_id && request.status === "free_pending"
    );
    const request_list_buy = request_list.filter(
      (request) => request.user === user_id && request.status === "buyer_pay"
    );
    const is_allowed_import =
      market_status === "public" ||
      (market_status === "free" && request_list_free.length > 0) ||
      (market_status === "paid" && request_list_buy.length > 0);
    let button_add;
    /*
    1. Public, langsung import
    2. Free, 
    > Belum request
    > Sudah request belum accept
    > Sudah accept
    3. Paid,
    > Belum beli
    > Sudah beli
    */
    if (market_status === "free") {
      if (request_list_free_pending.length === 0) {
        button_add = button_add = (
          <button
            onClick={this.toggleRequest}
            className="flex_container container_request"
          >
            <section className="flex_child">
              <section
                className="container_blog_status_img_like"
                style={{ margin: "auto" }}
              >
                <img alt="like" src={icon_blog_free} width="100%" />
              </section>
            </section>
            <section className="flex_child">
              {dict["Request data"][language]}
            </section>
          </button>
        );
      } else if (request_list_free.length > 0) {
        button_add = (
          <button
            className="flex_container container_request"
            onClick={this.toggleImport}
            id="green"
          >
            <section className="flex_child">
              <section
                className="container_blog_status_img_like"
                style={{ margin: "auto" }}
              >
                <img alt="like" src={checklist} width="100%" />
              </section>
            </section>
            <section className="flex_child">
              {dict["Request accepted"][language]}
              <br />
              {dict["Import data now"][language]}
            </section>
          </button>
        );
      }
    } else if (market_status === "paid") {
      if (request_list_buy.length === 0) {
        button_add = (
          <div>
            <h3>{`Rp ${new Intl.NumberFormat("id-ID", {
              style: "decimal",
            }).format(1.1 * 1.3 * price)}`}</h3>
            <button
              onClick={this.toggleCart.bind(this, blog)}
              className="button_pill background_black"
            >
              + {dict["Add to cart"][language]}
            </button>
          </div>
        );
      } else {
        button_add = (
          <button
            className="flex_container container_request"
            onClick={this.toggleImport}
            id="green"
          >
            <section className="flex_child">
              <section
                className="container_blog_status_img_like"
                style={{ margin: "auto" }}
              >
                <img alt="like" src={checklist} width="100%" />
              </section>
            </section>
            <section className="flex_child">
              {dict["Already paid"][language]}
              <br />
              {dict["Import data now"][language]}
            </section>
          </button>
        );
      }
    } else {
      button_add = (
        <button
          className="flex_container container_request"
          onClick={this.toggleImport}
          id="green"
        >
          <section className="flex_child">
            <section
              className="container_blog_status_img_like"
              style={{ margin: "auto" }}
            >
              <img alt="like" src={checklist} width="100%" />
            </section>
          </section>
          <section className="flex_child">
            {dict["Import data"][language]}
          </section>
        </button>
      );
    }
    let vote_name_list;

    let content = (
      <div style={{ minHeight: "100vh" }}>
        <div
          className="text_medium"
          style={{ marginTop: "123px", marginBottom: "10px" }}
        >
          {dict["Publication"][language]}
        </div>
        <div className="text_header margin_bottom">
          {dict["Loading publication"][language]}
        </div>
        <div className="text_medium">{dict["Author"][language]}</div>
        <div className="badge_normal" style={{ marginBottom: "30px" }}>
          0 {dict["views"][language]}
        </div>
      </div>
    );
    if (!isEmpty(blog) && !!blog.editorState) {
      const {
        title,
        title_en,
        editorState,
        layers,
        projects,
        date_created,
        authors,
        votes_count,
      } = blog;
      vote_name_list = votes_count.map((vote) => {
        const { user } = vote;
        const name_current = user?.full_name ? user?.full_name : user?.name;
        return <div style={{ marginBottom: "10px" }}>{name_current}</div>;
      });
      let vote_button;
      if (isAuthenticated) {
        if (loadingProcess && itemLoading === "vote") {
          vote_button = (
            <button className="flex_container">
              <section className="flex_child">
                <section
                  className="container_blog_status_img_like scale_animate"
                  style={{ margin: "auto", backgroundColor: "#f1f1f1" }}
                >
                  <img alt="like" src={icon_blog_public} width="100%" />
                </section>
              </section>
              <section className="flex_child">
                {dict["Like"][language]}...
              </section>
            </button>
          );
        } else {
          const isVote =
            votes_count.filter((vote) => vote?.user?._id === user_id).length >
            0;
          if (isVote) {
            vote_button = (
              <button className="flex_container">
                <section className="flex_child">
                  <section
                    className="container_blog_status_img_like"
                    style={{ margin: "auto", backgroundColor: "#ecf6fe" }}
                  >
                    <img alt="like" src={icon_blog_public_white} width="100%" />
                  </section>
                </section>
                <section className="flex_child">
                  {votes_count.length} {dict["Liked"][language]}
                </section>
              </button>
            );
          } else {
            vote_button = (
              <button onClick={this.handleVote} className="flex_container">
                <section className="flex_child">
                  <section
                    className="container_blog_status_img_like"
                    style={{ margin: "auto", backgroundColor: "#f1f1f1" }}
                  >
                    <img alt="like" src={icon_blog_public} width="100%" />
                  </section>
                </section>
                <section className="flex_child">
                  {votes_count.length} {dict["Like"][language]}
                </section>
              </button>
            );
          }
        }
      } else {
        vote_button = (
          <button onClick={this.toggleLogin} className="flex_container">
            <section className="flex_child">
              <section
                className="container_blog_status_img_like"
                style={{ margin: "auto" }}
              >
                <img alt="like" src={icon_blog_public} width="100%" />
              </section>
            </section>
            <section className="flex_child">
              {votes_count.length} {dict["Log in to like"][language]}
            </section>
          </button>
        );
      }
      const full_name = authors[0]?.user?.full_name;
      const badge = authors[0]?.user?.badge;
      const is_official = badge === "official";
      const views_count = blog.views_count
        ? new Intl.NumberFormat("id-ID", {
            style: "decimal",
          }).format(blog.views_count)
        : 0;
      let layerArray = [];
      if (!isEmpty(layers)) {
        const layers_filtered = layers.filter(
          (layer) => !!layer && !!layer.geo_layer
        );
        layerArray = layers_filtered.map((layer) => {
          const { geo_layer } = layer;
          return geo_layer;
        });
      }
      let projectArray = [];
      if (!isEmpty(projects)) {
        const projects_filtered = projects.filter(
          (project) => !!project && !!project.geo_project
        );
        projectArray = projects_filtered.map((project) => {
          const { geo_project } = project;
          return geo_project;
        });
      }
      const layers_content = (
        <div style={{ marginBottom: "10px" }}>
          <p className="text_medium">{dict?.["Layer attached"]?.[language]}</p>
          {isEmpty(layerArray) ? (
            <div>
              <p className="text_inferior">
                {dict?.["No layer attached"]?.[language]}
              </p>
            </div>
          ) : (
            <div className="carousell" style={{ marginBottom: "10px" }}>
              {layerArray.map((layer, idx) => {
                let layerContent = null;
                let name;
                if (layer) {
                  name = layer?.name;
                  const project_link = layer?.geo_project?.link;
                  const layer_id = layer?._id;
                  const button_open = is_allowed_import && (
                    <Link
                      to={`/view/${project_link}?layer_id=${layer_id}`}
                      className="button_small background_white margin_right"
                    >
                      {dict["Open"][language]}
                    </Link>
                  );
                  layerContent = (
                    <div key={idx} className="container_layer_card">
                      <div className="max_four_lines text_left">{name}</div>
                      <div
                        style={{
                          position: "absolute",
                          bottom: "5px",
                        }}
                      >
                        {button_open}
                      </div>
                    </div>
                  );
                }
                return layerContent;
              })}
            </div>
          )}
        </div>
      );
      const projects_content = (
        <div style={{ marginBottom: "10px" }}>
          <p className="text_medium">{dict["Project attached"][language]}</p>
          {isEmpty(projectArray) ? (
            <div>
              <p className="text_inferior">
                {dict["No project attached"][language]}
              </p>
            </div>
          ) : (
            <div className="carousell" style={{ marginBottom: "10px" }}>
              {projectArray.map((project, idx) => {
                const link = project?.link;
                // const _id = project?._id;
                const name = project?.name;
                const button_open = is_allowed_import && (
                  <Link
                    to={`/view/${link}`}
                    className="button_small background_white margin_right"
                    data-mapid="clickProyek"
                  >
                    {dict["Open"][language]}
                  </Link>
                );
                return (
                  <div key={idx} className="container_layer_card">
                    <div className="max_four_lines text_left">{name}</div>
                    <div
                      style={{
                        position: "absolute",
                        bottom: "5px",
                      }}
                    >
                      {button_open}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      );
      const decorator = new CompositeDecorator([
        {
          strategy: findLinkEntities,
          component: LinkNew,
        },
      ]);
      const contentState = convertFromRaw(JSON.parse(editorState));
      const editorStateFinal = EditorState.createWithContent(
        contentState,
        decorator
      );
      let className = "RichEditor-editor";
      if (!contentState.hasText()) {
        if (contentState.getBlockMap().first().getType() !== "unstyled") {
          className += " RichEditor-hidePlaceholder";
        }
      }

      content = (
        <main>
          <section
            className="text_medium"
            style={{ marginTop: "123px", marginBottom: "10px" }}
          >
            {formatDate(date_created)}
          </section>
          <section className="text_header margin_bottom">
            {language === "ina" ? title : title_en || title}
          </section>
          <section className="text_medium">
            {full_name}
            {is_official && (
              <img
                src={official_account}
                width="13px"
                alt="official account"
                style={{ marginLeft: "5px", marginBottom: "-2px" }}
              />
            )}
          </section>
          <section className="badge_normal" style={{ marginBottom: "30px" }}>
            {views_count} {dict["views"][language]}
          </section>
          <section style={{ marginBottom: "30px" }}>
            {layers_content}
            {projects_content}
            {button_add}
          </section>
          <section className="RichEditor-root ">
            <div className={className} onClick={this.focus}>
              <Editor
                editorState={editorStateFinal}
                placeholder={`${dict["Publication for this data has not yet been written."][language]}`}
                ref={this.myRef}
                spellCheck={false}
                readOnly={true}
                blockRendererFn={mediaBlockRenderer2}
                onChange={() => {}}
              />
            </div>
            {vote_button}
          </section>
        </main>
      );
    }

    const home_button = (
      <div className="top_nav_document" id="top_nav_document">
        <div className="top_nav_container_document">
          <Link
            to="/"
            className="left"
            onClick={this.onClickPathname.bind(this, `/`)}
            data-mapid="clickHome"
          >
            <img
              src={domain_list?.[domain]?.logo}
              height="35px"
              alt="Home button"
            />
          </Link>
          <NavTitleBlog data={blog} />
        </div>
        <BarProcess />
      </div>
    );

    const modalLoginContent = modalLogin && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modalLogin}
        onClose={this.toggleLogin}
      >
        <div
          className="box-body"
          style={{ marginTop: "30px", marginBottom: "30px" }}
        >
          {loginStatus || isRegistered ? <Login isNav={true} /> : <Register />}
          {loginStatus || isRegistered ? (
            <div style={{ display: "block", marginTop: "30px" }}>
              <div className="button_account">
                {dict["Don't have an account?"][language]}
              </div>
              <div
                className="button_register"
                onClick={this.toggleLoginRegister}
              >
                {dict["Register here"][language]}
              </div>
            </div>
          ) : (
            <div style={{ display: "block", marginTop: "30px" }}>
              <div className="button_account">
                {dict["Aready have an account?"][language]}
              </div>
              <div
                className="button_register"
                onClick={this.toggleLoginRegister}
              >
                {dict["Login instead"][language]}
              </div>
            </div>
          )}
        </div>
      </Modal>
    );

    const modalVoteListContent = votesList && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={votesList}
        onClose={this.toggleVoteList}
      >
        <div className="box-body">{vote_name_list}</div>
      </Modal>
    );

    const modal_request_content = modal_request && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_request}
        onClose={this.toggleRequest}
        backgroundColor="#f1f1f1"
      >
        <div className="box-body">
          <RequestFree />
        </div>
      </Modal>
    );

    const modal_import_content = modal_import && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={modal_import}
        onClose={this.toggleImport}
      >
        <div className="box-body">
          <ImportBlog />
        </div>
      </Modal>
    );

    const modal_cart_content = modal_cart && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_cart}
        onClose={this.toggleCart}
      >
        <div className="box-body">
          <section>{dict["Added successfully"][language]}</section>
          <Link to="/cart" className="button background_blue" id="blue">
            {dict["See cart"][language]}
          </Link>
        </div>
      </Modal>
    );

    return (
      <div>
        {home_button}
        {modalLoginContent}
        {modalVoteListContent}
        {modal_request_content}
        {modal_import_content}
        {modal_cart_content}
        <div
          id="container_document"
          style={{
            transition: "all 0.2s ease-in-out",
          }}
        >
          <div className="container_document">{content}</div>
        </div>
        <BlogListPublicCard />
        <style>
          {`
          body{
            padding-top: 0px;
            overflow-y: auto;
            background-color: #fff;
          }
          .navbarDefault{ visibility: hidden;}
          .top_nav{ visibility: hidden;}
          .sidebar_home{visibility: hidden;}
          #top_nav{ visibility: hidden;}
          #sidebar_home{ visibility: hidden;}
          `}
        </style>
        <Helmet>
          <title>
            {language === "ina" ? blog?.title : blog?.title_en || blog?.title}
          </title>
          <meta
            name="description"
            content={
              language === "ina" ? blog?.title : blog?.title_en || blog?.title
            }
          />
          <meta property="og:url" content="{{url()->current()}}" />
          <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content={
              language === "ina" ? blog?.title : blog?.title_en || blog?.title
            }
          />
          <meta
            property="og:description"
            content={
              language === "ina" ? blog?.title : blog?.title_en || blog?.title
            }
          />
          <meta property="og:image" content={blog?.picture_url} />

          <meta name="twitter:card" content="summary" />
          <meta name="twitter:domain" content="mapid.co.id" />
          <meta
            name="twitter:title"
            property="og:title"
            itemprop="name"
            content={
              language === "ina" ? blog?.title : blog?.title_en || blog?.title
            }
          />
          <meta
            name="twitter:description"
            property="og:description"
            itemprop="description"
            content={
              language === "ina" ? blog?.title : blog?.title_en || blog?.title
            }
          />
          <meta property="og:url" content="{{url()->current()}}" />
          <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content={
              language === "ina" ? blog?.title : blog?.title_en || blog?.title
            }
          />
          <meta
            property="og:description"
            content={
              language === "ina" ? blog?.title : blog?.title_en || blog?.title
            }
          />
          <meta property="og:image" content={blog?.picture_url} />

          <meta name="twitter:card" content="summary" />
          <meta name="twitter:domain" content="mapid.co.id" />
          <meta
            name="twitter:title"
            property="og:title"
            itemprop="name"
            content={
              language === "ina" ? blog?.title : blog?.title_en || blog?.title
            }
          />
          <meta
            name="twitter:description"
            property="og:description"
            itemprop="description"
            content={
              language === "ina" ? blog?.title : blog?.title_en || blog?.title
            }
          />
          <meta property="og:url" content="{{url()->current()}}" />
          <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content={
              language === "ina" ? blog?.title : blog?.title_en || blog?.title
            }
          />
          <meta
            property="og:description"
            content={
              language === "ina" ? blog?.title : blog?.title_en || blog?.title
            }
          />
          <meta property="og:image" content={blog?.picture_url} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:domain" content="mapid.co.id" />
          <meta
            name="twitter:title"
            property="og:title"
            itemprop="name"
            content={
              language === "ina" ? blog?.title : blog?.title_en || blog?.title
            }
          />
          <meta
            name="twitter:description"
            property="og:description"
            itemprop="description"
            content={
              language === "ina" ? blog?.title : blog?.title_en || blog?.title
            }
          />
        </Helmet>
        <div id="like" style={{ height: "0px" }} />
      </div>
    );
  }
}
// const styleMap = {
//   CODE: {
//     backgroundColor: "rgba(0, 0, 0, 0.05)",
//     fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
//     fontSize: 16,
//     padding: 2,
//   },
// };
// function getBlockStyle(block) {
//   switch (block.getType()) {
//     case "blockquote":
//       return "RichEditor-blockquote";
//     case "code-block":
//       return "RichEditor-code-block";
//     case "center":
//       return "RichEditor-center";
//     default:
//       return null;
//   }
// }
function mediaBlockRenderer2(block) {
  if (block.getType() === "atomic") {
    return {
      component: Media,
      editable: false,
    };
  }
  return null;
}

const Audio = (props) => {
  return <audio controls src={props.src} style={styles.media} />;
};

const Image = (props) => {
  return <img src={props.src} style={styles.media} alt={props.alt} />;
};

const Video = (props) => {
  return <video controls src={props.src} style={styles.media} />;
};

const Media = (props) => {
  const entity = props.contentState.getEntity(props.block.getEntityAt(0));
  const { src, alt } = entity.getData();
  const type = entity.getType();

  let media = <div></div>;
  if (type === "audio") {
    media = <Audio src={src} />;
  } else if (type === "image") {
    media = <Image src={src} alt={alt} />;
  } else if (type === "video") {
    media = <Video src={src} />;
  }

  return media;
};

const styles = {
  root: {
    fontFamily: "'Georgia', serif",
    padding: 20,
    width: 600,
  },
  buttons: {
    marginBottom: 10,
  },
  urlInputContainer: {
    marginBottom: 10,
  },
  urlInput: {
    fontFamily: "'Georgia', serif",
    marginRight: 10,
    padding: 3,
  },
  editor: {
    border: "1px solid #ccc",
    cursor: "text",
    minHeight: 300,
    padding: 10,
  },
  button: {
    marginTop: 10,
    textAlign: "center",
  },
  media: {
    width: "100%",
    whiteSpace: "initial",
  },
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  blog: state.blog,
});

export default connect(mapStateToProps, {
  getBlogDetailPublicNew,
  voteBlog,
  clearBlog,
  setPathname,
  addToCart,
  clearLayerBlogList,
})(BlogRead);
