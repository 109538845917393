const initialState = {
  loadingProcess: false,
  loadingDetail: false,
  loadingList: false,
  loading_status: false,
  loading_item: "",

  payment_list: [],
  payment: {},

  bankList: [],
  specialist_avail: false,
  specialist_code: "",
  specialist_id: "",
  price_list: [],
};

export default function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case "get_price_2023":
      return {
        ...state,
        price_list: action.payload,
      };

    case "set_loading_payment":
      return {
        ...state,
        loading_status: true,
        loading_item: action.payload,
      };
    case "clear_loading":
      return {
        ...state,
        loading_status: false,
        loading_item: "",
      };
    case "SET_LOADING_PROCESS_PAYMENT":
      return {
        ...state,
        loadingProcess: true,
      };
    case "SET_LOADING_DETAIL_PAYMENT":
      return {
        ...state,
        loadingDetail: true,
      };
    case "SET_LOADING_LIST_PAYMENT":
      return {
        ...state,
        loadingList: true,
      };
    case "CLEAR_LOADING_PAYMENT":
      return {
        ...state,
        loadingProcess: false,
        loadingDetail: false,
        loadingList: false,
      };
    case "create_payment":
      return {
        ...state,
        payment_list: [action.payload, ...state.payment_list],
      };
    case "ACTION_PAYMENT":
      return {
        ...state,
        payment_list: editFunction(state.payment_list, action.payload),
      };
    case "SURVEY_ACTION": //harus lewat geo layer
      return {
        ...state,
      };
    case "GET_USER_PAYMENT_LIST":
      return {
        ...state,
        payment_list: action.payload,
      };
    case "GET_USER_PAYMENT_DETAIL":
      return {
        ...state,
        payment: action.payload,
      };
    case "ADD_BANK":
      return {
        ...state,
        bankList: [action.payload, ...state.bankList],
      };
    case "GET_USER_BANK_LIST":
      return {
        ...state,
        bankList: action.payload,
      };
    case "CHECK_SPECIALIST":
      return {
        ...state,
        specialist_avail: action.payload ? true : false,
        specialist_id: action.payload ? action.payload._id : "",
      };
    case "SET_SPECIALIST_CODE":
      return {
        ...state,
        specialist_code: action.payload,
        specialist_avail: true,
      };

    case "clear_payment_object": {
      return {
        ...state,
        payment: {},
      };
    }

    case "CLEAR_PAYMENT": {
      return {
        ...state,
        loadingProcess: false,
        loadingDetail: false,
        loadingList: false,
        payment_list: [],
        payment: {},
        license: {},
        bankList: [],
        specialist_avail: false,
        specialist_code: "",
      };
    }
    default:
      return state;
  }
}

const editFunction = (state, newItem) => {
  const newState = state.slice();
  //get index
  const index = newState.findIndex((element) => element._id === newItem._id);
  //replace item
  newState.splice(index, 1, newItem);
  return newState;
};
