/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import {
  pull_value_sini_object,
  set_value_sini,
  fly_init_update,
} from "../../App/actions/sini_v2";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/
const isochrone_mode_array = [
  { key: "driving", ina: "Mobil", eng: "Car" },
  {
    key: "driving-traffic",
    ina: "Mobil (dengan kemacetan)",
    eng: "Car (with Traffic)",
  },
  { key: "walking", ina: "Berjalan", eng: "Walking" },
  { key: "cycling", ina: "Bersepeda", eng: "Cycling" },
];

class SINI_ISOCHRONE extends Component {
  state = {};

  on_activate_sini_ai = () => {
    this.props.set_value_sini({
      key: "sini_menu_active",
      value: "post_ai_chat",
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const { sini_v2_list, request_id_active, ai_parent_detail_list } =
      this.props.sini;

    //content
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    const sini_object = merge_array.find(
      (item) => item.request_id === request_id_active
    );
    const properties =
      sini_object?.geojson_polygon_isochrone?.features?.[0]?.properties || {};
    const geojson_polygon_isochrone = sini_object?.geojson_polygon_isochrone;

    let content;

    if (geojson_polygon_isochrone !== undefined) {
      const isochrone_mode_language = isochrone_mode_array.find(
        (item) => item.key === properties?.["isochrone_mode"]
      )?.[language];
      content = (
        <section className="container_light background_white margin_bottom outline_green">
          <table className="full_width">
            <tbody>
              <tr>
                <td className="text_left">
                  <div className="badge background_green margin_bottom">
                    {dict?.["Isochrone"]?.[language]}
                  </div>
                  <section className="margin_bottom">
                    <p className="text_small">
                      {dict?.["Transportation mode"]?.[language]}
                    </p>
                    <p className="text_bold">{isochrone_mode_language}</p>
                  </section>
                  <section className="margin_bottom">
                    <p className="text_small">
                      {dict?.["Driving time"]?.[language]}
                    </p>
                    <p className="text_bold">
                      {properties?.["contours_minutes"]}{" "}
                      {dict?.["minutes"]?.[language]}
                    </p>
                  </section>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      );
    }

    return <main>{content}</main>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  sini: state.sini,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, {
  pull_value_sini_object,
  set_value_sini,
  fly_init_update,
})(SINI_ISOCHRONE);
