/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";
import { Dropdown } from "../reuseable";

/*REDUX FUNCTION*/
import {
  push_resume_kai,
  delete_resume_kai,
} from "../../App/actions/propertiesActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/
const array_element_timeseries_object = [
  {
    name: "RELASI",
    key: "field_name_relasi",
  },
  {
    name: "Jumlah KA per 30 menit",
    key: "field_name_jumlah_kereta",
  },
  {
    name: "Jumlah pnp 30 menit",
    key: "field_name_jumlah_pnp",
  },
  {
    name: "Grand Total",
    key: "field_name_grand_total",
  },
  {
    name: "Max per Kereta",
    key: "field_name_max_per_kereta",
  },
  {
    name: "JAM BERANGKAT",
    key: "field_name_jam_berangkat",
  },
  {
    name: "JAM DATANG",
    key: "field_name_jam_datang",
  },
];

class CAPEX_EDITOR extends Component {
  state = {
    modal_add_layer: false,
    modal_cancel: false,
    search_layer_param: "",
    layer_id: "",
    year: "",
    layer_name: "",
    layer_fields: [],
    timeseries_object: {
      timeseries_mode: "kai",
      field_name_array_month: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MEI",
        "JUN",
        "JUL",
        "AGS",
        "SEP",
        "OKT",
        "NOV",
        "DES",
      ],
      field_name_relasi: "RELASI",
      field_name_jumlah_kereta: "Jumlah KA per 30 menit",
      field_name_jumlah_pnp: "Jumlah pnp 30 menit",
      field_name_grand_total: "Grand Total",
      field_name_max_per_kereta: "Max per Kereta",
      field_name_jam_berangkat: "JAM BERANGKAT",
      field_name_jam_datang: "JAM DATANG",
    },
    layer_id_delete: "",
    year_delete: "",
  };

  toggle_cancel = (year_delete, layer_id_delete) => {
    this.setState({ modal_cancel: !this.state.modal_cancel });
    if (layer_id_delete && year_delete) {
      this.setState({ layer_id_delete, year_delete });
    }
  };

  on_delete_resume_kai = () => {
    const { geo_project } = this.props.project;
    const folder = this.props.folder;
    const { layer_id_delete, year_delete } = this.state;
    const body = {
      geo_project_id: geo_project._id,
      folder_id: folder._id,
      geo_layer_id: layer_id_delete,
      year: year_delete,
    };
    this.props.delete_resume_kai(body);
    this.setState({ modal_cancel: false });
  };

  on_setup_timeseries_object = () => {
    const { geo_layer_list } = this.props.layer;
    const { layer_id } = this.state;
    const layer_object = geo_layer_list.find(
      (item) => item.geo_layer._id === layer_id
    );
    const layer_name = layer_object?.geo_layer?.name || "";
    const layer_fields = layer_object?.geo_layer?.fields || [];

    this.setState({
      layer_name,
      layer_fields,
    });
  };

  on_change_timeseries_object = (value, key) => {
    let timeseries_object = this.state.timeseries_object;
    timeseries_object[key] = value;
    this.setState({ timeseries_object });
  };

  on_change_month = (value, idx) => {
    let timeseries_object = this.state.timeseries_object;
    timeseries_object.field_name_array_month[idx] = value;
    this.setState({ timeseries_object });
  };

  toggle_add_layer = () => {
    this.setState({
      modal_add_layer: !this.state.modal_add_layer,
      layer_id: "",
      year: "",
      layer_name: "",
      layer_fields: [],
    });
  };

  on_add_layer = (layer_id) => {
    this.setState({ layer_id });
  };

  on_change = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  };

  on_push_resume_kai = () => {
    this.setState({ modal_add_layer: false });
    const folder = this.props.folder;
    const { layer_id, year, timeseries_object } = this.state;
    const { geo_project } = this.props.project;

    const body = {
      geo_project_id: geo_project._id,
      folder_id: folder._id,
      geo_layer_id: layer_id,
      year: year,
      timeseries_object: timeseries_object,
    };

    this.props.push_resume_kai(body);
  };

  render() {
    //local storage

    //local state
    const {
      modal_add_layer,
      layer_id,
      year,
      layer_name,
      layer_fields,
      timeseries_object,
      modal_cancel,
      year_cancel,
    } = this.state;

    //global props
    const folder = this.props.folder;
    const { geo_layer_list } = this.props.layer;

    //content
    const year_array = folder?.year_array || [];
    const layer_id_year_array = folder?.layer_id_year_array || [];

    const modal_add_layer_content = modal_add_layer && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={modal_add_layer}
        onClose={this.toggle_add_layer}
      >
        <div className="box-body">
          <main className="max_height">
            <h1 className="text_header">Tambahkan layer</h1>
            <table className="table full_width margin_bottom">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Tahun</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Dropdown
                      placeholder="Cari dan pilih data train plan"
                      filter_on
                      is_enable_empty={false}
                      value={layer_id}
                      onChange={(value) => {
                        this.on_add_layer(value);
                      }}
                    >
                      {geo_layer_list.map((item) => {
                        return (
                          <option
                            key={item.geo_layer._id}
                            value={item.geo_layer._id}
                          >
                            {item.geo_layer.name}
                          </option>
                        );
                      })}
                    </Dropdown>
                  </td>
                  <td>
                    <input
                      type="text"
                      value={year}
                      name="year"
                      placeholder="Masukan tahun, misal: 2018"
                      onChange={this.on_change}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            {layer_id && year ? (
              <>
                <button
                  className="button margin_bottom background_blue"
                  onClick={this.on_setup_timeseries_object}
                >
                  Lanjut
                </button>
              </>
            ) : (
              <section className="margin_bottom">
                <div className="button background_grey">Lanjut</div>
                <p className="text_small">
                  Pilih layer dan masukkan tahun terlebih dahulu
                </p>
              </section>
            )}
            <br />
            {layer_fields.length > 0 && (
              <main>
                <section className="container_light margin_bottom">
                  <p className="text_bold margin_bottom">Data terpilih</p>
                  <table className="table">
                    <thead>
                      <tr>
                        <td>Nama data</td>
                        <td>Tahun</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text_header">{layer_name}</td>
                        <td className="text_header">{year}</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section className="container_light margin_bottom">
                  <p className="text_bold">
                    Pilih kolom-kolom untuk perhitungan
                  </p>
                  <table className="table full_width">
                    <thead>
                      <tr>
                        <th>Elemen kolom</th>
                        <th>Pilih kolom</th>
                        <th>Status cocok</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th colSpan={3} className="text_left">
                          Kolom perhitungan
                        </th>
                      </tr>
                      {array_element_timeseries_object.map((item, idx) => {
                        const field = layer_fields.find(
                          (element) => element.name === item.name
                        );
                        return (
                          <tr key={idx}>
                            <td>{item.name}</td>
                            <td>
                              <Dropdown
                                placeholder="Cari dan pilih data train plan"
                                filter_on
                                is_enable_empty={false}
                                value={timeseries_object[item.key]}
                                onChange={(value) => {
                                  this.on_change_timeseries_object(
                                    value,
                                    item.key
                                  );
                                }}
                              >
                                {layer_fields.map((item) => {
                                  return (
                                    <option key={item.name} value={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </Dropdown>
                            </td>
                            <td>
                              {field ? (
                                <div className="button background_green">
                                  Terpenuhi
                                </div>
                              ) : (
                                <div className="button background_red">x</div>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <th colSpan={3} className="text_left">
                          Kolom bulan
                        </th>
                      </tr>
                      {timeseries_object.field_name_array_month.map(
                        (month, idx) => {
                          const field = layer_fields.find(
                            (element) => element.name === month
                          );
                          return (
                            <tr key={idx}>
                              <td>{month}</td>
                              <td>
                                <Dropdown
                                  placeholder="Cari dan pilih data train plan"
                                  filter_on
                                  is_enable_empty={false}
                                  value={
                                    timeseries_object.field_name_array_month[
                                      idx
                                    ]
                                  }
                                  onChange={(value) => {
                                    this.on_change_month(value, idx);
                                  }}
                                >
                                  {layer_fields.map((item) => {
                                    return (
                                      <option key={item.name} value={item.name}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </Dropdown>
                              </td>
                              <td>
                                {field ? (
                                  <div className="button background_green">
                                    Terpenuhi
                                  </div>
                                ) : (
                                  <div className="button background_red">x</div>
                                )}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                  <br />

                  <br />
                  <button
                    className="button background_blue"
                    onClick={this.on_push_resume_kai}
                  >
                    Simpan
                  </button>
                </section>
              </main>
            )}
          </main>
        </div>
      </Modal>
    );

    const modal_cancel_content = modal_cancel && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_cancel}
        onClose={this.toggle_cancel}
      >
        <div className="box-body">
          <h1 className="text_header">
            Konfirmasi hapus pengaturan tahun {year_cancel}
          </h1>
          <p className="text_small">
            Pengaturan CAPEX pada data akan terhapus namun data tidak akan ikut
            terhapus
          </p>
          <br />
          <br />
          <button
            className="button background_red"
            onClick={this.on_delete_resume_kai}
          >
            Hapus
          </button>
        </div>
      </Modal>
    );

    return (
      <main className="max_height">
        {modal_add_layer_content}
        {modal_cancel_content}
        <h1 className="text_header margin_bottom">
          Pengaturan data train plan resume
        </h1>
        <br />
        <table className="table full_width">
          <thead>
            <tr>
              <th>Tahun</th>
              <th>Data</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {year_array.map((item, idx) => {
              return (
                <tr key={idx}>
                  <td>{item}</td>
                  <td>{layer_id_year_array[idx]}</td>
                  <td>
                    <button
                      className="button background_red"
                      onClick={this.toggle_cancel.bind(
                        this,
                        item,
                        layer_id_year_array[idx]
                      )}
                    >
                      Batalkan
                    </button>
                  </td>
                </tr>
              );
            })}
            <tr>
              <td colSpan={3}>
                <button
                  className="button background_blue"
                  type="button background_blue"
                  onClick={this.toggle_add_layer}
                >
                  Tambah data tahun
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  project: state.project,
});

export default connect(mapStateToProps, { push_resume_kai, delete_resume_kai })(
  CAPEX_EDITOR
);
