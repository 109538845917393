import React, { Component } from "react";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";
import { get_pda } from "../../App/actions/iotActions";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

class IotStatisticResume extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bbwsCC: false,
      loading: true,
      loadingPCH: true,
      clock: "00:00:00",
      clockInterval: null,
      lastMinutes: 0,
      prevDataPDA: this?.props?.iot?.lastDataPDA.list
        ? this?.props?.iot?.lastDataPDA.list
        : [],
      prevDataPCH: this?.props?.iot?.lastDataPCH?.list
        ? this?.props?.iot?.lastDataPCH?.list
        : [],
    };
  }

  componentDidMount() {
    if (this?.props?.iot?.lastDataPDA.length === 0) {
      this.props.get_pda();
    } else {
      this.setState({ loading: false });
    }

    const time = new Date();
    const m = time.getMinutes();
    this.setState({ lastMinutes: m });

    let clockInterval = setInterval(() => {
      const time = new Date();
      const h = time.getHours() < 10 ? `0${time.getHours()}` : time.getHours();
      const m =
        time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes();
      const s =
        time.getSeconds() < 10 ? `0${time.getSeconds()}` : time.getSeconds();
      this.setState({ clock: `${h}:${m}:${s}` });
    }, 1000);
    this.setState({ clockInterval });
  }

  componentWillUnmount() {
    clearInterval(this.state.clockInterval);
  }

  refresh(e) {
    this.props.get_pda();
  }

  convertDate(params) {
    const res = params.split("-");
    return `${res[2]}/${res[1]}/${res[0].slice(2, 4)}`;
  }

  style = {
    title: {
      margin: "1.5rem 0rem",
    },
    table: {
      marginTop: "1rem",
      textAlign: "left",
    },
    tr: {
      border: "solid 0.7px #c4c4c4",
    },
    siaga1: {
      color: "#ff0000",
    },
    siaga2: {
      color: "#ffff00",
    },
    siaga3: {
      color: "#008000",
    },
    normal: {
      color: "#0000ff",
    },
    error: {
      color: "#000000",
    },
    delay: {
      color: "#000000",
    },
    tanpaSiaga: {
      color: "#000000",
    },
    tma: {
      textAlign: "center",
    },
    iotStatistic: {
      marginBottom: "3rem",
    },
    clock: {
      justifyContent: "center",
      display: "flex",
      marginTop: "1rem",
    },
  };

  render() {
    const s = this.style;

    const contentPDA = (
      <div>
        <table style={s.table}>
          <tbody>
            <tr>
              <td>Jumlah Pos Duga Air</td>
              <td>
                :{" "}
                {this.state.loading
                  ? "loading.."
                  : this?.props?.iot?.lastDataPDA?.status?.sum}
              </td>
            </tr>
            <tr>
              <td>Siaga 1</td>
              <td>
                : <span style={s.siaga1}>&#9609;</span>{" "}
                {this.state.loading
                  ? "loading.."
                  : this?.props?.iot?.lastDataPDA?.status?.siaga1}
              </td>
            </tr>
            <tr>
              <td>Siaga 2</td>
              <td>
                : <span style={s.siaga2}>&#9609;</span>{" "}
                {this.state.loading
                  ? "loading.."
                  : this?.props?.iot?.lastDataPDA?.status?.siaga2}
              </td>
            </tr>
            <tr>
              <td>Siaga 3</td>
              <td>
                : <span style={s.siaga3}>&#9609;</span>{" "}
                {this.state.loading
                  ? "loading.."
                  : this?.props?.iot?.lastDataPDA?.status?.siaga3}
              </td>
            </tr>
            <tr>
              <td>Normal</td>
              <td>
                : <span style={s.normal}>&#9609;</span>{" "}
                {this.state.loading
                  ? "loading.."
                  : this?.props?.iot?.lastDataPDA?.status?.normal}
              </td>
            </tr>
            <tr>
              <td>Offline</td>
              <td>
                : <span style={s.error}>&#9746;</span>{" "}
                {this.state.loading
                  ? "loading.."
                  : this?.props?.iot?.lastDataPDA?.status?.offline}
              </td>
            </tr>
            <tr>
              <td>Delay</td>
              <td>
                : <span style={s.delay}>&#9746;</span>{" "}
                {this.state.loading
                  ? "loading.."
                  : this?.props?.iot?.lastDataPDA?.status?.delay}
              </td>
            </tr>
            <tr>
              <td>Tanpa Status</td>
              <td>
                : <span style={s.tanpaSiaga}>&#9609;</span>{" "}
                {this.state.loading
                  ? "loading.."
                  : this?.props?.iot?.lastDataPDA?.status?.tanpaSiaga}
              </td>
            </tr>
          </tbody>
        </table>
        <Typography style={s.clock}>
          <AccessTimeIcon></AccessTimeIcon> {this.state.clock}
        </Typography>
        <hr />
        <div style={{ overflowX: "auto" }}>
          <table className="table_pch">
            <tbody>
              <tr>
                <td></td>
                <td>Lokasi</td>
                <td>Jam</td>
                <td>TMA(cm)</td>
                <td></td>
                <td>Tanggal</td>
              </tr>
              {this.state.loading ? (
                <tr>
                  <td></td>
                  <td>Loading</td>
                  <td>...</td>
                  <td style={s.date}>...</td>
                  <td>...</td>
                  <td style={s.tma}>...</td>
                </tr>
              ) : (
                (this?.props?.iot?.lastDataPDA?.list
                  ? this?.props?.iot?.lastDataPDA?.list
                  : []
                ).map((e, idx) => (
                  <tr style={s.tr} key={idx}>
                    <td>
                      {e.status === "normal" &&
                      parseInt(e.siaga4) === 1000 &&
                      parseInt(e.siaga3) === 1000 &&
                      parseInt(e.siaga2) === 1000 &&
                      parseInt(e.siaga1) === 1000 ? (
                        <span style={s.tanpaSiaga}>&#9609;</span>
                      ) : (
                        <span style={s.normal}>&#9609;</span>
                      )}
                      {e.status === "Delay" && (
                        <span style={s.delay}>&#9746;</span>
                      )}
                      {e.status === "Offline" && (
                        <span style={s.error}>&#9746;</span>
                      )}
                    </td>
                    <td>
                      {e.nama_alat.toUpperCase()}
                      <br />
                      <br />
                      Sungai: <br /> <b>{e.sungai}</b>
                    </td>
                    <td>{e.ReceivedTime.slice(0, 5)}</td>
                    <td style={s.tma}>
                      <b>{parseInt(e.WLevel)}</b>
                    </td>
                    <td>
                      {e.compare && e.compare.value === "up" && (
                        <span style={{ color: "red" }}>&uarr;</span>
                      )}
                      {e.compare && e.compare.value === "down" && (
                        <span style={{ color: "green" }}>&darr;</span>
                      )}
                      {e?.compare && e?.compare.value === "-" && (
                        <span>(-)</span>
                      )}
                    </td>
                    <td style={s.date}>{this.convertDate(e.ReceivedDate)}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div style={{ textAlign: "center", marginTop: "1rem" }}>
          {/* <Tooltip title="Refresh Data">
            <IconButton size="small" onClick={this.refresh.bind(this, "pda")}>
              <RefreshIcon />
            </IconButton>
          </Tooltip> */}
        </div>
      </div>
    );

    return (
      <div style={s.iotStatistic}>
        <p className="text_header">Pos Duga Air</p>
        {contentPDA}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  iot: state.iot,
});

export default connect(mapStateToProps, { get_pda })(IotStatisticResume);
