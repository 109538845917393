import isEmpty from "../validation/is-empty";
// import env from "react-dotenv";

// require("dotenv").config();

const initialState = {
  version: process.env.REACT_APP_VERSION,
  mode_balai: "off", //cilicis, brantas, off
  mode_list: "default",
  language: "indonesia",
  send_link_status: false,
  verify_link_status: "pending",
  isAuthenticated: false,
  isRegistered: false,
  user: {},
  loading: false,
  loadingDetail: false,
  loadingProcess: false,
  itemLoading: "",
  groups: [],
  groups_org: [],
  groups_project: [],
  groups_org_project: [],
  group: {},
  user_list: [],
  notification_list: [],
  notification_list_project: [],
  domain: window.location.host,
  pathname: window.location.pathname,
  bank_data: [],
  current_bank_data: {},
  map_access: 0,
  max_quota: 30,
  tutorial_list: [],
  tutorial_active: "",
  banner_array: [],
  modal_tutorial: false,
  modal_login: false,
  modal_quota_access: false,
  modal_payment_universal: false,
  quota_access: 0,
  quota_access_sini_ai: 0,
  quota_ai_chat: 0,
  auth_action: 0,
  collaborator_qty: 10,
  collaborators: [],
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case "auth_action":
      return {
        ...state,
        auth_action: state.auth_action + 1,
      };
    case "set_modal_quota_access":
      return {
        ...state,
        modal_quota_access: action.payload,
      };
    case "get_quota_access":
      return {
        ...state,
        quota_access: action.payload,
      };
    case "invite_email":
      return {
        ...state,
        group: invite_email(state.group, action.payload),
      };
    //4. Edit Member
    case "edit_active":
      return {
        ...state,
        group: edit_active(state.group, action.payload),
      };
    case "set_value_user":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case "edit_whatsapp_confirm":
      return {
        ...state,
        user: { ...state.user, is_whatsapp_confirm: "success" },
      };
    case "failed_whatsapp_confirm":
      return {
        ...state,
        user: { ...state.user, is_whatsapp_confirm: "not_propose" },
      };
    case "edit_email_confirm":
      return {
        ...state,
        user: { ...state.user, is_email_confirm: "success" },
      };
    case "failed_email_confirm":
      return {
        ...state,
        user: { ...state.user, is_email_confirm: "not_propose" },
      };
    case "edit_text_profile":
      return {
        ...state,
        user: edit_text_profile(state.user, action.payload),
      };
    case "UPDATE_PP":
      return {
        ...state,
        user: editPP(state.user, action.payload),
      };
    case "INIT_API":
      return {
        ...state,
        user: initApi(state.user, action.payload),
      };
    case "GET_BANNER":
      return {
        ...state,
        banner_array: action.payload,
      };
    case "SET_MODE_LIST":
      return {
        ...state,
        mode_list: action.payload,
      };
    case "SET_LANGUAGE":
      return {
        ...state,
        language: action.payload,
      };
    case "SEND_LINK":
      return {
        ...state,
        send_link_status: true,
      };
    case "VERIFY_LINK":
      return {
        ...state,
        verify_link_status: action.payload,
      };
    case "OPEN_MODAL":
      return {
        ...state,
        tutorial_active: action.payload,
        modal_tutorial: true,
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        modal_tutorial: false,
      };
    case "GET_TUTORIAL_LIST":
      return {
        ...state,
        tutorial_list: action.payload,
      };
    case "SET_PATHNAME":
      return {
        ...state,
        pathname: action.payload,
      };
    case "AUTH_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "AUTH_LOADING_DETAIL":
      return {
        ...state,
        loadingDetail: true,
      };
    case "AUTH_LOADING_PROCESS":
      return {
        ...state,
        loadingProcess: true,
        itemLoading: action.payload,
      };
    case "CLEAR_AUTH_LOADING":
      return {
        ...state,
        loading: false,
        loadingDetail: false,
        loadingProcess: false,
      };
    case "INC_MAP_ACCESS":
      return {
        ...state,
        map_access: action.payload,
      };
    case "GET_MAP_ACCESS":
      return {
        ...state,
        map_access: action.payload,
      };
    case "GET_MAX_QUOTA":
      return {
        ...state,
        max_quota: action.payload,
      };
    case "GET_BANK_DATA":
      return {
        ...state,
        bank_data: action.payload,
      };
    case "GET_CURRENT_BANK_DATA":
      return {
        ...state,
        current_bank_data: action.payload,
      };
    case "ADD_BANK_DATA":
      return {
        ...state,
        bank_data: [action.payload, ...state.bank_data],
        current_bank_data: action.payload,
      };
    case "SET_CURRENT_BANK_DATA":
      return {
        ...state,
        current_bank_data: action.payload,
      };
    case "SET_REGISTER_SUCCESS":
      return {
        ...state,
        isRegistered: true,
      };
    case "SET_CURRENT_USER":
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case "UPDATE_USER_PROFILE":
      return {
        ...state,
        user: action.payload,
      };
    case "PUSH_UPLOADED_PICTURE":
      return {
        ...state,
        user: pushUploadedPicture(state.user, action.payload),
      };
    case "UPLOAD_KTM":
      return {
        ...state,
        user: uploadKTM(state.user, action.payload),
      };
    case "EDIT_EMAIL":
      return {
        ...state,
        user: editEmail(state.user, action.payload),
      };
    case "TRIGGER_EMAIL_CONFIRMATION":
      return {
        ...state,
        user: tiggerEmailConfirmation(state.user, action.payload),
      };
    case "EMAIL_MATCH":
      return {
        ...state,
        user: emailMatch(state.user, action.payload),
      };
    case "APPLY_STUDENT":
      return {
        ...state,
        user: applyAsStudent(state.user, action.payload),
      };
    case "GET_NOTIFICATION_LIST":
      return {
        ...state,
        notification_list: action.payload,
      };
    case "GET_NOTIF_BY_PROJECT":
      return {
        ...state,
        notification_list_project: action.payload,
      };
    //group posty
    //1. Create Group
    case "ADD_GROUP":
      return {
        ...state,
        groups: [action.payload, ...state.groups],
      };
    //2. Edit Group
    case "EDIT_GROUP":
      return {
        ...state,
        group: editGroup(state.group, action.payload),
        groups: editGroupList(state.groups, action.payload),
      };
    //3. Delete member
    case "DELETE_MEMBER":
      return {
        ...state,
        group: deleteMember(state.group, action.payload),
      };
    //4. Edit Member
    case "EDIT_MEMBER":
      return {
        ...state,
        group: editMember(state.group, action.payload),
      };
    //5. Add Member
    case "ADD_MEMBER":
      return {
        ...state,
        group: action.payload,
        groups: addMemberList(state.groups, action.payload),
      };
    //6. Join Group
    case "JOIN_GROUP":
      return {
        ...state,
        group: joinGroup(state.group, action.payload),
        groups: joinGroupList(state.groups, action.payload),
      };
    //7. Confirm member
    case "CONFIRM_MEMBER":
      return {
        ...state,
        group: confirmMember(state.group, action.payload),
        groups: confirmMemberList(state.groups, action.payload),
      };
    //group getty
    //1. Get Group By Member _id
    case "GET_GROUPS":
      return {
        ...state,
        groups: action.payload,
        groups_org: getGroupsOrg(action.payload),
      };
    //2. Get Group List By Project
    case "GET_GROUPS_PROJECT":
      return {
        ...state,
        groups_project: action.payload,
        groups_org_project: getGroupsOrg(action.payload),
      };
    //3. Get Group Detail
    case "GET_GROUP":
      return {
        ...state,
        group: action.payload,
      };
    //4. Delete Group
    case "DELETE_GROUP":
      return {
        ...state,
        groups: state.groups.filter((group) => group._id !== action.payload),
      };
    //5. Search Users
    case "SEARCH_USERS":
      return {
        ...state,
        user_list: action.payload,
      };
    //COLLABORATORS
    case "push_value_auth":
      return {
        ...state,
        [action.payload.key]: [
          ...state[action.payload.key],
          action.payload.value,
        ],
      };
    case "set_value_auth":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    default:
      return state;
  }
}

// invitations_email
const invite_email = (group, payload) => {
  let members = group?.members || [];
  let new_member = [...members];
  new_member.push(payload);
  let new_groups = {
    ...group,
    members: new_member,
  };
  return new_groups;
};

const edit_text_profile = (state, body) => {
  const newState = { ...state, ...body };
  // newState.bio = body.bio;
  // newState.full_name = body.full_name;
  return newState;
};

const initApi = (state, api_keys) => {
  const newUser = { ...state };
  newUser.api_keys = api_keys;
  return newUser;
};

const pushUploadedPicture = (state, picture) => {
  const newUser = { ...state };
  const newUserUploadedPictures = newUser?.uploaded_pictures
    ? newUser?.uploaded_pictures
    : [];
  newUserUploadedPictures.push(picture);
  newUser.uploaded_pictures = newUserUploadedPictures;
  return newUser;
};

const uploadKTM = (state, response) => {
  const newUser = { ...state };
  newUser.ktm = response.ktm;
  return newUser;
};

const editEmail = (state, body) => {
  const newUser = { ...state };
  newUser.email = body.email;
  return newUser;
};

const tiggerEmailConfirmation = (state, body) => {
  const newUser = { ...state };
  newUser.is_email_confirm = body.is_email_confirm;
  return newUser;
};

const emailMatch = (state, body) => {
  const newUser = { ...state };
  newUser.is_email_confirm = body.is_email_confirm;
  return newUser;
};

const applyAsStudent = (state, body) => {
  const newUser = { ...state };
  newUser.student_status = body.student_status;
  return newUser;
};

const getGroupsOrg = (groups) => {
  const newState = groups.slice();
  //filter only license payment;
  const groups_org = newState.filter((group) => !isEmpty(group.payment));
  //get date convert to unix
  const groups_org_unix = groups_org.map((group) => {
    const { payment } = group;
    const { date_in } = payment;
    const multiple_month = payment.multiple_month ? payment.multiple_month : 1;
    //86400 unix = 1 day
    //1 month = 30.5 day
    const month_unix = 86400 * 30.5;
    const date_finish_unix =
      parseInt((new Date(date_in).getTime() / 1000).toFixed(0)) +
      month_unix * multiple_month;
    const date_now_unix = parseInt(
      (new Date(Date.now()).getTime() / 1000).toFixed(0)
    );
    const delta_unix = date_finish_unix - date_now_unix;
    //86400 unix = 1 day
    const delta_day = parseInt(delta_unix / 86400);
    let payment_edited = { ...payment, delta_unix, delta_day };
    const group_edited = { ...group, payment: payment_edited };
    return group_edited;
  });
  const groups_org_unix_filtered = groups_org_unix.filter(
    (group) => group.payment.delta_unix > 0
  );
  return groups_org_unix_filtered;
};

//2. Edit Group
const editGroup = (state, body) => {
  let new_group = { ...state }; //clone object
  const { group_name } = body;
  new_group.name = group_name;
  return new_group;
};
const editGroupList = (state, body) => {
  const groups = state.slice(); //clone array
  const { group_id, group_name } = body;
  let new_group = groups.filter((e) => e._id === group_id)[0];
  new_group.name = group_name;
  const index = groups.findIndex((e) => e._id === group_id);
  groups.splice(index, 1, new_group); //replace item
  return groups;
};

//3. Delete member
const deleteMember = (state, body) => {
  let new_group = { ...state }; //clone object
  const { member_id } = body;
  let members = new_group.members;
  members = members.filter((e) => e._id !== member_id);
  new_group.members = members;
  return new_group;
};

//4. Edit Member
const editMember = (state, body) => {
  let new_group = { ...state }; //clone object
  const { member_id, status } = body;
  let members = new_group.members;
  let member = members.filter((e) => e._id === member_id)[0];
  member.status = status;
  const index_member = members.findIndex((e) => e._id === member_id);
  members.splice(index_member, 1, member);
  new_group.members = members;
  return new_group;
};

//5. Add Member
const addMemberList = (state, body) => {
  const groups = state.slice(); //clone array
  const new_group = body;
  const index = groups.findIndex((e) => e._id === body._id);
  groups.splice(index, 1, new_group); //replace item
  return groups;
};

//6. Join Group
const joinGroup = () => {};
const joinGroupList = () => {};

//7. Confirm member
const confirmMember = (state, body) => {
  let new_group = { ...state }; //clone object
  const { member_id, status } = body;
  let members = new_group.members;
  let member = members.filter((e) => e._id === member_id)[0];
  member.status = status;
  const index_member = members.findIndex((e) => e._id === member_id);
  members.splice(index_member, 1, member);
  new_group.members = members;
  return new_group;
};
const confirmMemberList = (state, body) => {
  const groups = state.slice(); //clone array
  const { group_id, member_id, status } = body;
  let new_group = groups.filter((e) => e._id === group_id)[0];
  let members = new_group.members;
  let member = members.filter((e) => e._id === member_id)[0];
  member.status = status;
  const index_member = members.findIndex((e) => e._id === member_id);
  members.splice(index_member, 1, member);
  new_group.members = members;
  const index = groups.findIndex((e) => e._id === group_id);
  groups.splice(index, 1, new_group); //replace item
  return groups;
};
const editPP = (state, body) => {
  const newState = { ...state };
  const profile_picture = body.profile_picture;
  newState.profile_picture = profile_picture;
  return newState;
};

// edit_active
const edit_active = (object, body) => {
  const { user_id, active_status } = body;
  let members = object?.members || [];
  const index = members.findIndex((d) => d?.user._id === user_id);
  members[index].active_status = active_status;
  object.members = members;
  return object;
};
