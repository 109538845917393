/*eslint-disable*/
import React, { Component } from "react";
import icon_type_image from "../../../../Assets/png_jpg/logo_danamas.png";

export default class C1_Logo extends Component {
  render() {
    return (
      <div className="h_50 marginLeft_10 marginBottom_5">
        <img
          id="c1-logo"
          alt="Danamas"
          src={icon_type_image}
          className="h_40 object_cover"
        />
      </div>
    );
  }
}
