/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class RUANG_INFO extends Component {
  state = {};

  render() {
    //local storage

    //local state

    //global props
    const { room_list } = this.props;

    //content
    return (
      <main>
        <table className="table full_width">
          <thead>
            <tr>
              <th>NAMA</th>
              <th>FORMULA</th>
              <th>
                NILAI (m<sup>2</sup>)
              </th>
            </tr>
          </thead>
          <tbody>
            {room_list.map((item, idx) => {
              let { name, formula, value } = item;
              value = parseInt(value);

              return (
                <tr key={idx}>
                  <td>{name}</td>
                  <td>{formula}</td>
                  <td>
                    {new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    })?.format(value)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(RUANG_INFO);
