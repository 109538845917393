import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      version="1.1"
      viewBox="0 0 6.879 6.879"
    >
      <g
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="1"
        transform="translate(-420.188 -14.98)"
      >
        <circle
          cx="423.628"
          cy="18.419"
          r="3.44"
          fill="#690"
          fillOpacity="1"
          stroke="none"
          strokeMiterlimit="100"
          strokeWidth="0.185"
          paintOrder="stroke markers fill"
          stopColor="#000"
        ></circle>
        <path
          fill="none"
          stroke="#fff"
          strokeDasharray="none"
          strokeWidth="0.397"
          d="M424.041 20.454l.587-1.035 1.035-.587-2.55-.929.928 2.551"
          paintOrder="stroke markers fill"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          strokeDasharray="none"
          strokeWidth="0.397"
          d="M424.628 19.419l.826.826"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          strokeDasharray="none"
          strokeWidth="0.397"
          d="M422.442 17.232l-.384-.383"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          strokeDasharray="none"
          strokeWidth="0.397"
          d="M423.182 16.925v-.542"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          strokeDasharray="none"
          strokeWidth="0.397"
          d="M423.922 17.232l.384-.383"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          strokeDasharray="none"
          strokeWidth="0.397"
          d="M422.442 18.713l-.384.383"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          strokeDasharray="none"
          strokeWidth="0.397"
          d="M422.135 17.972h-.542"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
