import React from "react";

function ICON_BACK() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      version="1.1"
      viewBox="0 0 7.937 7.937"
      xmlSpace="preserve"
      className="cursor_pointer"
    >
      <g
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(-69.002 -87.427)"
      >
        <rect
          width="7.938"
          height="7.938"
          x="69.002"
          y="87.427"
          fill="#164d7c"
          fillOpacity="1"
          strokeMiterlimit="100"
          strokeWidth="0.397"
          paintOrder="stroke markers fill"
          rx="4.585"
          ry="4.585"
          stopColor="#000"
        ></rect>
        <g
          fill="none"
          stroke="#fff"
          strokeDasharray="none"
          strokeOpacity="1"
          strokeWidth="0.794"
          transform="translate(.443)"
        >
          <path d="M72.164 90.162l-1.249 1.234 1.25 1.234"></path>
          <path d="M70.915 91.396h3.225"></path>
        </g>
      </g>
    </svg>
  );
}

export default ICON_BACK;
