import axios from "axios";

// const bun_server = "http://localhost:4004";
const bun_server = "https://geoserver.mapid.io";

/**
 * @route : push_ai_sheet_chat
 * @endpoint : /sini_v2/ai_excel/push_ai_sheet_chat
 * @methode : post
 * @params : -
 * @body : ai_hbu_summary_object, sini_data, geo_layer_id, feature_key, field_key, user_message, json_ai
 */
export const push_ai_sheet_chat = (body) => async (dispatch) => {
  try {
    const { user_message, sini_object } = body;

    dispatch({
      type: "set_loading_action",
      payload: "push_ai_sheet_chat",
    });

    /*START*/
    let json_ai = {};
    const add_properties = (key, object) => {
      if (object?._id) json_ai[key] = { properties: object.properties || {} };
    };
    const add_default = (key, object, status) => {
      json_ai[key] = {
        properties: object?._id ? object.properties || {} : { status },
      };
    };
    add_properties("demography_one", sini_object?.demography_one);
    add_properties("people_spending", sini_object?.people_spending);
    add_properties("landzone_one", sini_object?.landzone_one);
    add_default(
      "flood_one",
      sini_object?.flood_one,
      "Tidak ada data kerawanan banjir"
    );
    add_default(
      "landslide_one",
      sini_object?.landslide_one,
      "Tidak ada data kerawanan longsor"
    );
    add_default(
      "tsunami_one",
      sini_object?.tsunami_one,
      "Tidak ada data kerawanan tsunami"
    );
    if (sini_object?.rdtr_one?._id) {
      json_ai.rdtr_one = {
        properties: { NAMZON_1: sini_object.rdtr_one.properties?.NAMZON_1 },
      };
    }
    const map_array = (key, array) => {
      json_ai[key] =
        array?.length > 0
          ? array.map((item) => ({ properties: item?.properties || {} }))
          : [];
    };
    map_array("landzone_neighbor", sini_object?.landzone_neighbor);
    map_array("flood_neighbor", sini_object?.flood_neighbor);
    map_array("landslide_neighbor", sini_object?.landslide_neighbor);
    map_array("tsunami_neighbor", sini_object?.tsunami_neighbor);
    add_properties("landvalue_one", sini_object?.landvalue_one);
    map_array("landvalue_neighbor", sini_object?.landvalue_neighbor);
    json_ai.poi_features = [];
    json_ai.poi_resume_1 = sini_object?.poi_resume_1 || [];
    json_ai.poi_resume_2 = sini_object?.poi_resume_2 || [];
    json_ai.poi_resume_3 = sini_object?.poi_resume_3 || [];
    if (sini_object?.poi_survey_1_features?.length > 0) {
      const {
        jenis_bangunan_resume,
        average_sewa,
        min_sewa,
        max_sewa,
        count_sewa,
        manifest_survey_1,
      } = sini_object;
      let { poi_survey_1_features } = sini_object;

      //pake fields.name
      const fields_used = [
        "DATA LINGKUNGAN",
        "Karakteristik Lokasi",
        "Karakteristik Kepadatan Pengembangan",
        "Karakteristik Pertumbuhan",
        "Issue Lingkungan",
        "Catatan Issue Lingkungan",
        "Analisis Risiko",
        "Catatan Analisis Risiko",
        "DATA PROPERTI",
        "Tipe Properti",
        "Aksesibilitas",
        "Data Jalan: Jumlah Jalur",
        "Data Jalan: Lebar Jalan",
        "Data Jalan: Jumlah Lajur",
        "Data Jalan: Material",
        "Data Jalan: Drainase",
        "Data Jalan: Penerangan",
        "Data Jalan: Kondisi Fisik",
        "Data Jalan: Intensitas Lalulintas",
        "Status Hunian",
      ];
      const fields = manifest_survey_1?.fields || [];
      poi_survey_1_features = poi_survey_1_features.map((feature) => {
        let properties = {};
        fields_used.forEach((field_name) => {
          const field_key = fields.find(
            (field) => field.name === field_name
          )?.key;
          const value = feature?.properties?.[field_key];
          properties[field_name] = value;
        });
        return properties;
      });
      json_ai = {
        ...json_ai,
        poi_survey_1_features,
        jenis_bangunan_resume,
        average_sewa,
        min_sewa,
        max_sewa,
        count_sewa,
      };
    }

    body.json_ai = json_ai;
    delete body.sini_object;
    /*FINISH*/

    dispatch({
      type: "push_value_sini_single",
      payload: {
        key: "ai_sheet_chat_list",
        value: {
          role: "user",
          content: user_message,
        },
      },
    });
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(
      bun_server + `/sini_v2/ai_excel/push_ai_sheet_chat`,
      body,
      config
    );
    dispatch({
      type: "push_value_sini_single",
      payload: {
        key: "ai_sheet_chat_list",
        value: res.data,
      },
    });
    dispatch({
      type: "clear_loading_action",
      payload: "push_ai_sheet_chat",
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "push_ai_sheet_chat",
    });
  }
};
