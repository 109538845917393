/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import Modal from "../common_modal/Modal";
import ADD_FOLDER_BI from "./ADD_FOLDER_BI";
import ADD_PROJECT_BI from "./ADD_PROJECT_BI";

/*REDUX*/
import { push_folder } from "../../App/actions/biActions";

/*PICTURE*/
import ICON_PLUS from "../../Assets/jsx/ICON_PLUS";

/*FUNCTION*/

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class ADD_PARENT_BI extends Component {
  state = {
    modal_add_folder: false,
    modal_add_project: false,
  };

  toggle_add_folder = () => {
    this.setState({ modal_add_folder: !this.state.modal_add_folder });
  };

  toggle_add_project = () => {
    this.setState({ modal_add_project: !this.state.modal_add_project });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { modal_add_folder, modal_add_project } = this.state;

    //global props

    //content
    const modal_add_folder_content = modal_add_folder && (
      <Modal
        modalSize="large"
        isOpen={modal_add_folder}
        onClose={this.toggle_add_folder}
        backgroundColor="rgb(235, 235, 235)"
      >
        <div className="box-body" id="box-body">
          <main className="max_height">
            <ADD_FOLDER_BI toggle_add_folder={this.toggle_add_folder} />
          </main>
        </div>
      </Modal>
    );

    const modal_add_project_content = modal_add_project && (
      <Modal
        modalSize="large"
        isOpen={modal_add_project}
        onClose={this.toggle_add_project}
        backgroundColor="rgb(235, 235, 235)"
      >
        <div className="box-body" id="box-body">
          <main className="max_height">
            <ADD_PROJECT_BI toggle_add_project={this.toggle_add_project} />
          </main>
        </div>
      </Modal>
    );

    return (
      <main>
        {modal_add_folder_content}
        {modal_add_project_content}
        <h1 className="text_bold">
          {dict?.["Add folders or projects into the BI dashboard"]?.[language]}
        </h1>
        <br />
        <button
          onClick={this.toggle_add_folder}
          className="button_pill background_white margin_bottom margin_right"
        >
          <span className="badge_circle background_green margin_right">
            <ICON_PLUS />
          </span>
          {dict?.["Add folder"]?.[language]}
        </button>
        <button
          onClick={this.toggle_add_project}
          className="button_pill background_white margin_bottom margin_right"
        >
          <span className="badge_circle background_green margin_right">
            <ICON_PLUS />
          </span>
          {dict?.["Add project"]?.[language]}
        </button>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
  project: state.project,
});

export default connect(mapStateToProps, {
  push_folder,
})(ADD_PARENT_BI);
