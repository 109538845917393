import React from "react";

const ICON_ARROW_UP = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={7.5}
      height={9.499}
      viewBox="0 0 1.984 2.514"
    >
      <path
        d="M.992.198v2.117m.794-1.33L.992.198.198.985"
        style={{
          fill: "none",
          paintOrder: "stroke markers fill",
          strokeDashoffset: 11.411,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 0.39688,
          stroke: props.line_color || "#000",
        }}
      />
    </svg>
  );
};

export default ICON_ARROW_UP;
