/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import RANGKING_CHART_MANUAL from "./RANKING_CHART_MANUAL";

/*REDUX*/

/*PICTURE*/

/*FUNCTION*/
import is_not_empty from "../../App/validation/is_not_empty";
import sort_array from "../../App/validation/sort_array";

/*DATA*/

/*CONST*/

class HBU_SUMMARY_MANUAL extends Component {
  render() {
    //global props
    const { ai_parent_detail_list, request_id_active } = this.props.sini;
    const {
      luas_tanah,
      luas_bangunan,
      data_bangun,
      kesimpulan_tanah_kosong,
      kesimpulan_bangunan,
      kesimpulan_hbu_fisik_tanah_kosong,
      kesimpulan_hbu_fisik_bangunan,
    } = this.props.hbu_reducer;
    let rows_filtered_3 = this.props.hbu_reducer?.rows_filtered_3 || [];

    //content
    const sini_object = ai_parent_detail_list?.find(
      (item) => item?.request_id === request_id_active
    ); //sini_object dengan request_id_active
    const properties = sini_object?.demography_one?.properties || {};
    const { DESA, KECAMATAN, KABKOT, PROVINSI } = properties;
    rows_filtered_3 = sort_array(rows_filtered_3, "total_rank", true)?.slice(
      0,
      3
    );
    const alternatif_1 = rows_filtered_3?.[0];
    let { peruntukan, gsb, kdb, klb, ktb_tanah_kosong, ktb_bangunan } =
      data_bangun || {};
    const is_kdb_and_klb_fill = is_not_empty(klb) || is_not_empty(kdb);

    if (!is_not_empty(kdb)) kdb = "-";
    if (!is_not_empty(gsb)) gsb = "-";
    if (!is_not_empty(klb)) klb = "-";

    //content

    return (
      <section>
        <h1 className="margin_bottom">HBU SUMMARY</h1>

        <section className="margin_bottom">
          {/* {loading_item_array.includes("generate_ai_hbu_summary") ? (
            <button className="button_big background_grey">
              AI SEDANG MELAKUKAN SUMMARY...
            </button>
          ) : !ai_sini_sheet_object?.request_id ? (
            <button className="button_big background_grey">WAIT</button>
          ) : (
            <button
              className="button_big background_black"
            // onClick={this.toggle_re_hbu}
            >
              Perbarui Ringkasan HBU dengan Prompt
            </button>
          )} */}
        </section>

        <aside className="container_column">
          <div className="child_tile container_light background_grey_light outline_transparent">
            {peruntukan && (
              <h1 className="margin_bottom">
                <label>Nama object: </label>
                <p className="text_bold">{peruntukan}</p>
              </h1>
            )}
            <div className="margin_bottom">
              <label className="text_small">Provinsi</label>
              <p className="text_bold">{PROVINSI}</p>
            </div>
            <div className="margin_bottom">
              <label className="text_small">Kabupaten atau Kota</label>
              <p className="text_bold">{KABKOT}</p>
            </div>
            <div className="margin_bottom">
              <label className="text_small">Kecamatan</label>
              <p className="text_bold">{KECAMATAN}</p>
            </div>
            <div className="margin_bottom">
              <label className="text_small">Desa atau Kelurahan</label>
              <p className="text_bold">{DESA}</p>
            </div>
            <div className="margin_bottom">
              <label className="text_small">Luas Tanah m2</label>
              <p className="text_bold">{luas_tanah}</p>
            </div>
            <div className="margin_bottom">
              <label className="text_small">Luas Bangunan m2</label>
              <p className="text_bold">{luas_bangunan}</p>
            </div>
          </div>

          <div className="child_tile card_uji_legal_hbu container_light background_grey_light outline_transparent flex">
            <section>
              <h1 className="text_bold margin_bottom">
                Rangkuman kalkulasi HBU
              </h1>
              <RANGKING_CHART_MANUAL hbu_alternative_array={rows_filtered_3} />
            </section>
            <section className="container_light background_white">
              <div className="center_perfect h_full">
                <div>
                  <span className="center_perfect">
                    <label className="margin_bottom text_center">
                      Alternatif HBU terbaik
                    </label>
                  </span>
                  <span className="center_perfect">
                    <h1 className="rounded_5 padding_5 background_black text_center">
                      {alternatif_1?.JENIS ? alternatif_1?.JENIS : "-"}
                    </h1>
                  </span>
                </div>
              </div>
            </section>
          </div>

          <section className="child_tile span_3 container_light background_grey_light outline_transparent">
            <div className="text_bold margin_bottom">Uji Legal</div>
            <div className="container background_white margin_bottom">
              <table>
                <tbody>
                  {peruntukan && (
                    <tr>
                      <td className="text_medium_normal">Nama Objek</td>
                      <td>:</td>
                      <td className="text_bold"> {peruntukan}</td>
                    </tr>
                  )}
                  <tr>
                    <td className="text_medium_normal">KDB</td>
                    <td>:</td>
                    <td className="text_bold"> {`${kdb}%`}</td>
                  </tr>
                  <tr>
                    <td className="text_medium_normal">GSB</td>
                    <td>:</td>
                    <td className="text_bold"> {gsb}</td>
                  </tr>
                  <tr>
                    <td className="text_medium_normal">KLB</td>
                    <td>:</td>
                    <td className="text_bold"> {klb}</td>
                  </tr>
                  <tr>
                    <td className="text_medium_normal">Luas Terbangun</td>
                    <td>:</td>
                    <td className="text_bold">
                      {" "}
                      {luas_bangunan} m<sup>2</sup>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="two_container_equal">
              <article
                className="container background_white"
                style={{ position: "relative", paddingBottom: "35px" }}
              >
                <div className="two_container_small_right">
                  <h1 className="text_bold margin_bottom">
                    Perhitungan Sesuai Tata Ruang (Tanah Kosong)
                  </h1>
                </div>
                <p className="text_medium">{kesimpulan_tanah_kosong}</p>

                {!is_kdb_and_klb_fill && (
                  <p className="">
                    <br />
                    KDB dan KLB harus diisi pada sheet 'Uji HBU - Legal' agar
                    kesimpulan sesuai.
                  </p>
                )}

                {is_not_empty(ktb_tanah_kosong) && (
                  <div
                    style={{
                      position: "absolute",
                      right: 10,
                      bottom: 10,
                    }}
                  >
                    KTB : {ktb_tanah_kosong}
                  </div>
                )}
              </article>
              <article
                className="container background_white"
                style={{ position: "relative", paddingBottom: "35px" }}
              >
                <div className="two_container_small_right">
                  <h1 className="text_bold margin_bottom">
                    Perhitungan Sesuai Tata Ruang (Bangunan)
                  </h1>
                </div>
                <p className="text_medium">{kesimpulan_bangunan}</p>

                {!is_kdb_and_klb_fill && (
                  <p>
                    <br />
                    KDB dan KLB harus diisi pada sheet 'Uji HBU - Legal' agar
                    kesimpulan sesuai.
                  </p>
                )}

                {is_not_empty(ktb_bangunan) && (
                  <div
                    style={{
                      position: "absolute",
                      right: 10,
                      bottom: 10,
                    }}
                  >
                    KTB : {ktb_bangunan}
                  </div>
                )}
              </article>
            </div>
          </section>

          <div className="child_tile container_light background_grey_light outline_transparent">
            <h1 className="text_bold margin_bottom">
              Uji Maximum Productivity
            </h1>
            <p>
              Kesimpulan uji maximum productivity menyatakan bahwa aset yang
              terletak di lokasi ini memenuhi syarat indikator kelayakan.
            </p>
          </div>

          <div className="child_tile container_light background_grey_light outline_transparent">
            <h1 className="text_bold margin_bottom">
              Uji Fisik HBU (Tanah Kosong)
            </h1>

            <p>{kesimpulan_hbu_fisik_tanah_kosong}</p>
          </div>

          <div className="child_tile container_light background_grey_light outline_transparent">
            <h1 className="text_bold margin_bottom">
              Uji Fisik HBU Bangunan (Existing)
            </h1>

            <p>{kesimpulan_hbu_fisik_bangunan}</p>
          </div>

          <div className="child_tile span_3 container_light background_grey_light outline_transparent">
            <h1 className="text_bold margin_bottom">
              Rangkuman finansial setiap alternatif
            </h1>
            <table className="table scroll_auto">
              <thead>
                <tr className="font_10">
                  <th></th>
                  <th>CAPEX Tanah</th>
                  <th>CAPEX Bangunan</th>
                  <th>CAPEX Total</th>
                  <th>NPV</th>
                  <th>IRR</th>
                  <th>PI</th>
                  <th>PP</th>
                  <th>ARR</th>
                  <th>DR</th>
                  <th>Ranking</th>
                </tr>
              </thead>
              <tbody>
                {rows_filtered_3?.map((item, idx) => {
                  const {
                    JENIS,
                    capex_tanah,
                    capex_bangunan,
                    capex_total,
                    npv,
                    irr,
                    arr,
                    payback_periode,
                    profitability_index,
                  } = item;

                  const to_id_format = (num_val) => {
                    return new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                      maximumFractionDigits: 2,
                    })?.format(Number(num_val));
                  };

                  return (
                    <tr key={idx} className="font_10">
                      <td>{JENIS}</td>
                      <td>Rp{to_id_format(capex_tanah)}</td>
                      <td>Rp{to_id_format(capex_bangunan)}</td>
                      <td>Rp{to_id_format(capex_total)}</td>
                      <td>Rp{to_id_format(npv)}</td>
                      <td>{to_id_format(irr)}%</td>
                      <td>{`${profitability_index} tahun`}</td>
                      <td>{to_id_format(payback_periode)}</td>
                      <td>{to_id_format(arr)}</td>
                      <td>15%</td>
                      <td>{to_id_format(idx + 1)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <div>
              <br />
              <h1 className="text_bold margin_bottom">Indikator Kelayakan</h1>
              <ul>
                <li>
                  <b style={{ fontWeight: 800 }}>NPV</b>
                  {
                    " > 0 maka layak, semakin besar maka baik karena lebih profitable"
                  }
                </li>
                <li>
                  <b style={{ fontWeight: 800 }}>IRR</b>
                  {
                    " > Investasi, semakin besar maka baik karena lebih profitable"
                  }
                </li>
                <li>
                  <b style={{ fontWeight: 800 }}>PP</b>
                  {" Semakin kecil maka baik / cepat balik modal"}
                </li>
                <li>
                  <b style={{ fontWeight: 800 }}>PI</b>
                  {" > 1 maka layak"}
                </li>
                <li>
                  <b style={{ fontWeight: 800 }}>Akumulasi Rank</b>
                  {" semakin rendah, maka prioritas semakin tinggi."}
                </li>
              </ul>
            </div>

            <div className="h_full margin_top">
              <br />
              <h1 className="text_bold margin_bottom">
                Alasan Pemilihan Alternatif
              </h1>
              <p className="text_medium">
                {`Pemilihan ${
                  alternatif_1?.JENIS ? alternatif_1?.JENIS : "-"
                } sebagai alternatif terbaik didasarkan pada analisis mendalam terhadap kebutuhan lokal dan tren pasar yang stabil, memberikan return investasi yang diharapkan serta memenuhi fungsi utilitas nyata bagi masyarakat.`}
              </p>
            </div>
          </div>
        </aside>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
  hbu_reducer: state.hbu_reducer,
});

export default connect(mapStateToProps, {})(HBU_SUMMARY_MANUAL);
