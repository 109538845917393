import React, { Component } from 'react'

import checklist_no_outline from "../../Assets/svg/checklist_no_outline.svg";

export default class ICON_CHECKLIST extends Component {

  render() {
    const { color = "black" } = this.props;
    const size = !isNaN(this.props.size) ? Number(this.props.size) : 20;

    return (
      <div
        className={`rounded_full center_perfect`}
        style={{
          height: `${size}px`,
          width: `${size}px`,
          background: color
        }}
      >
        <img
          src={checklist_no_outline}
          width={`${size / 2}px`}
          alt="checklist"
        />
      </div>
    )
  }
}
