/*LIBRARY*/
import React, { Component } from "react";
import { Resizable } from "re-resizable";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { connect } from "react-redux";

/*COMPONENT*/
import SIDEBAR_LAYER from "./SIDEBAR_LAYER";
import SIDEBAR_LAYER_EMPTY from "./SIDEBAR_LAYER_EMPTY";
import PARENT_SINI from "../sini_v2/PARENT_SINI";
import TOOLBOX_TABLE from "../sidebar_toolbox/TOOLBOX_TABLE";
import LayerList from "../viewer_bottom_bar/LayerList";

/*REDUX*/
import { setStatisticMode } from "../../App/actions/layerNewActions";
import { set_value_properties } from "../../App/actions/propertiesActions";

/*PICTURE*/
import icon_data from "../../Assets/svg/icon_data_new.svg";
import icon_sini from "../../Assets/svg/icon_sini.svg";
import icon_toolbox from "../../Assets/svg/icon_tool_new.svg";

/*FUNCTION*/
import isEmpty from "../../App/validation/is-empty";

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/
const sidebar_width_desktop = 330;
const tabs = [
  {
    value: "data",
    label: "DATA",
    icon: icon_data,
    alt: "Icon Data",
  },
  {
    value: "sini",
    label: "SINI",
    icon: icon_sini,
    alt: "Icon Sini",
  },
  {
    value: "toolbox",
    label: "TOOL",
    icon: icon_toolbox,
    alt: "Icon Toolbox",
  },
];

class RIGHT_SIDEBAR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableComponent: props.tableComponent || { height: 0 },
      statusBarComponent: {
        width: 0,
      },
    };
  }

  componentDidMount = () => {
    if (document.getElementById("statistic_resume_box")) {
      const width = document.getElementById("statistic_resume_box").offsetWidth;
      localStorage.setItem("rightbar_width", width);
    }
  };

  setStatisticModeFunc = (event, value) => {
    this.props.setStatisticMode(value);
    this.props.set_value_properties({
      key: "sidebar_right_status",
      value: true,
    });
  };

  setStatusBarComponent = () => {
    const width = document.getElementById("statistic_resume_box").offsetWidth;
    this.setState({
      tableComponent: {
        ...this.state.statusBarComponent,
        width: width,
      },
    });
    localStorage.setItem("rightbar_width", width);
  };

  render() {
    //local storage

    //local state

    //global props
    const { statistic_mode, layer_id } = this.props.layer;
    const { is_mobile } = this.props.properties;

    //content
    let final_tabs = tabs;
    let sidebar_content;

    if (statistic_mode === "toolbox") {
      sidebar_content = <TOOLBOX_TABLE />;
    } else if (statistic_mode === "sini") {
      sidebar_content = <PARENT_SINI />;
    } else {
      if (layer_id) {
        sidebar_content = (
          <SIDEBAR_LAYER geo_project_link={this.props.geo_project_link} />
        );
      } else {
        sidebar_content = <SIDEBAR_LAYER_EMPTY />;
      }
    }

    const main_content = (
      <main>
        <Tabs
          style={{
            margin: "10px 15px 0 15px",
            padding: "5px",
            height: is_mobile ? "65px" : "80px",
            paddingTop: 0,
          }}
          value={statistic_mode}
          onChange={this.setStatisticModeFunc}
          aria-label="secondary tabs example"
          centered
        >
          {final_tabs?.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              iconPosition={is_mobile ? "start" : "top"}
              icon={
                <div className="border w_35 h_35 rounded_5 center_perfect">
                  <img
                    className={"img-rounded w_30 h_30"}
                    src={tab.icon}
                    alt={tab.alt}
                  />
                </div>
              }
              data-mapid="clickTabIcon"
            />
          ))}
        </Tabs>
        {statistic_mode === "data" && is_mobile && (
          <section className="margin_20">
            <LayerList />
          </section>
        )}
        <section
          style={{
            padding: "0px 10px 0px 10px",
            overflowY: "auto",
            height: `calc(${window.innerHeight}px - 100px)`,
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            {this.props.loadingList ||
            this.props.loadingDetail ||
            (isEmpty(this.props.geo_project) &&
              this.props.geo_project_link !== "data") ? (
              <div className="button background_blue">
                {dict["Loading GIS data"][this.props.language]} ...
              </div>
            ) : (
              sidebar_content
            )}
            <div
              style={{
                height: `${this.props.tableComponent.height + 200}px`,
              }}
            />
          </div>
        </section>
      </main>
    );
    return (
      <main>
        {!is_mobile ? (
          <Resizable
            id="statistic_resume_box"
            onResizeStop={(e, direction, ref, d) => {
              this.setStatusBarComponent(d);
            }}
            style={{
              zIndex: 1,
              position: "absolute",
              top: 0,
              right: "39px",
              backgroundColor: "#fff",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
            }}
            defaultSize={{ width: sidebar_width_desktop, height: "100vh" }}
            minWidth={sidebar_width_desktop}
            maxWidth="100vw"
          >
            {main_content}
          </Resizable>
        ) : (
          <div
            id="statistic_resume_box"
            style={{
              zIndex: 2,
              position: "absolute",
              top: 0,
              right: 0,
              backgroundColor: "white",
              width: "100%",
              paddingTop: "15px",
              maxWidth: "100vw",
            }}
          >
            {main_content}
          </div>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  auth: state.auth,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  setStatisticMode,
  set_value_properties,
})(RIGHT_SIDEBAR);
