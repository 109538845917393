/* eslint-disable */

import React, { Component } from "react";
// import { Dropdown } from "../reuseable";
import dict from "../../Data/dict.json";
import { MenuItem, Select } from "@mui/material";

const operators_that_need_function = ["log(", "sin(", "cos(", "tan("];

const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
export default class FormulaCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      variable: {
        key: "",
        name: "",
      },
    };
  }

  handleResetFormula = () => {
    this.props.handleFormula([]);
  };

  handleFormulaOperator = (e) => {
    const { formula } = this.props;
    const { value } = e.target;
    if (operators_that_need_function.includes(value)) {
      if (!/[0-9]/gi.test(formula[formula.length - 1])) {
        this.pushFormula("special_operator", value);
      }
    } else if (value === ".") {
      this.handleFormulaOnChange(e);
    } else {
      this.pushFormula("operator", value);
    }
  };

  handleColumnSelector = (value) => {
    if (`${value}`?.includes("#_spliter_#")) {
      const key = value?.split("#_spliter_#")[0];
      const name = value?.split("#_spliter_#")[1];
      this.pushFormula("variable", name, key);
    }
  };

  setFormula = (value) => {
    this.props.handleFormula(value);
  };

  pushFormula = (type, value, key) => {
    const { formula } = this.props;
    this.props.handleFormula([
      ...formula,
      {
        type: type,
        content: value,
        ref_key: key,
      },
    ]);
  };

  handleFormulaOnChange = (e) => {
    const { formula } = this.props;
    const { value } = e.target;
    const { data, inputType } = e.nativeEvent;

    // hapus
    if (inputType === "deleteContentBackward" || value === "backspace") {
      const last_array = formula[formula.length - 1];
      if (last_array?.type === "constant" && last_array?.content?.length > 1) {
        let new_formula = formula.slice();
        new_formula[new_formula.length - 1].content = new_formula[
          new_formula.length - 1
        ].content.slice(0, -1);
        this.setFormula(new_formula);
      } else if (
        (last_array?.type === "constant" &&
          last_array?.content?.length === 1) ||
        last_array?.type !== "constant"
      ) {
        this.setFormula(formula?.slice(0, -1));
      } else {
        this.setFormula(formula?.slice(0, -1));
      }
    }
    // input angka
    else if (
      (/[0-9]/gi.test(data) === true &&
        inputType === "insertText" &&
        !isNaN(data)) ||
      (/[0-9]/gi.test(value) === true && !data && !isNaN(value)) ||
      (data === "." && isNaN(data)) ||
      (value === "." && isNaN(value))
    ) {
      if (formula[formula.length - 1]?.type === "constant") {
        let new_formula = formula.slice();
        new_formula[new_formula.length - 1].content =
          new_formula[new_formula.length - 1].content + (data || value);
        this.setFormula(new_formula);
      } else {
        this.pushFormula("constant", data || value);
      }
    }
    // input special operator matematika (log,sin,cos,tan)
    else if (/^(l|s|c|t|L|S|C|T){1}$/gi.test(data) === true) {
      switch (data) {
        case "l" || "L":
          this.pushFormula("special_operator", "log(");
          break;
        case "s" || "S":
          this.pushFormula("special_operator", "sin(");
          break;
        case "c" || "C":
          this.pushFormula("special_operator", "cos(");
          break;
        case "t" || "T":
          this.pushFormula("special_operator", "tan(");
          break;
        default:
          break;
      }
    }
    // input standard operator matematika
    else if (/^(\*|\/|\+|\-|\(|\)|\^|\%){1}$/gi.test(data) === true) {
      this.pushFormula("operator", data);
    }
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    const { formula, headers } = this.props;

    return (
      <div className="">
        <div className="marginBottom_10">
          <label className="font_18 w_full">Input Formula</label>
        </div>
        <div className="grid_child gap_10_10 marginBottom_10 w_full">
          <div>
            <label htmlFor="refer_to_column" className="font_14 w_full">
              Referring Column
            </label>
            <Select
              className="w_full bg_white"
              placeholder={dict["Select"][language]}
              value={"select"}
              onChange={(value) => {
                this.handleColumnSelector(value?.target?.value);
              }}
              data-mapid="clickFormula"
            >
              <MenuItem value={"select"}>{dict["Select"][language]}</MenuItem>
              {headers
                // ?.filter((header) => header.type === "number")
                .map((header, key) => {
                  return (
                    <MenuItem
                      key={`${header.field}#_spliter_#${header.headerName}`}
                      value={`${header.field}#_spliter_#${header.headerName}`}
                      data-mapid={`selectFormula-${key}`}
                    >
                      {header.headerName}
                    </MenuItem>
                  );
                })}
            </Select>
          </div>

          <div className="flex w_full gap_5_5 wrap">
            <button
              value="C"
              onClick={this.handleResetFormula}
              className="rounded_10 center_perfect text_white background_red font_14 hover_darkRed w_40 h_30"
            >
              C
            </button>
            <button
              value="backspace"
              onClick={this.handleFormulaOnChange}
              className="rounded_10 center_perfect text_white font_14 background_red hover_darkRed w_40 h_30"
            >
              Del
            </button>
            <button
              value="log("
              onClick={this.handleFormulaOperator}
              className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
            >
              Log
            </button>
            <button
              value="sin("
              onClick={this.handleFormulaOperator}
              className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
            >
              Sin
            </button>
            <button
              value="cos("
              onClick={this.handleFormulaOperator}
              className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
            >
              Cos
            </button>
            <button
              value="tan("
              onClick={this.handleFormulaOperator}
              className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
            >
              Tan
            </button>
            <button
              value="*"
              onClick={this.handleFormulaOperator}
              className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
            >
              *
            </button>
            <button
              value="/"
              onClick={this.handleFormulaOperator}
              className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
            >
              /
            </button>
            <button
              value="+"
              onClick={this.handleFormulaOperator}
              className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
              data-mapid="click+"
            >
              +
            </button>
            <button
              value="-"
              onClick={this.handleFormulaOperator}
              className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
            >
              -
            </button>
            <button
              value="("
              onClick={this.handleFormulaOperator}
              className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
            >
              {"("}
            </button>
            <button
              value=")"
              onClick={this.handleFormulaOperator}
              className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
            >
              {")"}
            </button>
            <button
              value="^"
              onClick={this.handleFormulaOperator}
              className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
            >
              ^
            </button>
            <button
              value="%"
              onClick={this.handleFormulaOperator}
              className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
            >
              %
            </button>
            <button
              value="."
              onClick={this.handleFormulaOperator}
              className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
            >
              .
            </button>
          </div>
          <div className="flex gap_5_5 wrap">
            {numbers?.map((number) => (
              <button
                key={number}
                value={number}
                onClick={this.handleFormulaOnChange}
                className="rounded_10 center_perfect text_white bg_blue font_20 hover_darkBlue w_40 h_30"
              >
                {number}
              </button>
            ))}
          </div>
          <div>
            <label>Formula</label>
            <div className="grid_child w_full">
              <textarea
                type="number"
                onChange={(e) => this.handleFormulaOnChange(e)}
                className="w_full"
                value={formula.map((f) => f.content).join("")}
                rows={6}
                style={{ fontSize: "10px" }}
              />
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    );
  }
}
