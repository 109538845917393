/*GET METHODE*/

/*POST METHODE*/

/*NON API*/

//push_field_bulk
/*
body={
  geo_layer_id,
  fields: []
}
*/
export const push_field_bulk = (body) => (dispatch) => {
  dispatch({
    type: "push_field_bulk",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

//replace_features
/*
body={
  geo_layer_id,
  features: []
}
*/
export const replace_features = (body) => (dispatch) => {
  dispatch({
    type: "replace_features",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

//clearLoading
export const clearLoading = () => {
  return {
    type: "CLEAR_LOADING_LAYER",
  };
};

export const setLoading = (itemLoading) => {
  return {
    type: "SET_LOADING_PROCESS_LAYER",
    payload: itemLoading,
  };
};
