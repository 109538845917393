/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import CHART_TEMPLATE from "../viewer_chart/CHART_TEMPLATE";

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class EXAMPLE_NOT_USE_Y extends Component {
  state = {};

  render() {
    //local storage

    //local state

    //global props

    //content
    // Normal Waspada Siaga Darurat
    const array = [
      {
        pos: "POS 1",
        status: "Normal",
      },
      {
        pos: "POS 2",
        status: "Normal",
      },
      {
        pos: "POS 3",
        status: "Normal",
      },
      {
        pos: "POS 4",
        status: "Normal",
      },
      {
        pos: "POS 5",
        status: "Siaga",
      },
      {
        pos: "POS 6",
        status: "Siaga",
      },
      {
        pos: "POS 7",
        status: "Waspada",
      },
      {
        pos: "POS 8",
        status: "Waspada",
      },
      {
        pos: "POS 9",
        status: "Waspada",
      },
      {
        pos: "POS 10",
        status: "Darurat",
      },
    ];

    return (
      <main>
        <table className="table full_width" style={{ tableLayout: "fixed" }}>
          <thead>
            <tr>
              <th>Contoh data</th>
              <th>Output grafik</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p className="container_light outline_green">
                  Nilai pada grafik akan dihitung dengan mengelompokkan nilai
                  yang memiliki label yang sama, lalu dihitung jumlahnya.
                </p>
                <table className="table">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Pos</th>
                      <th>Status (sebagai sumbu x atau label)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {array.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td> {idx + 1}</td>
                          <td>{item.pos}</td>
                          <td>{item.status}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </td>
              <td>
                <h1 className="text_medium">PIE CHART</h1>
                <CHART_TEMPLATE
                  type="pie"
                  width="100"
                  height="100"
                  use_legend={true}
                  use_generate_array_color={true}
                  options={{
                    hoverBackgroundColor: "#f38026",
                    animation: false,
                    maintainAspectRatio: false,
                    responsive: false,
                    legend: {
                      display: false,
                    },
                    indexAxis: "x",
                    title: {
                      display: false,
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                  data={{
                    labels: ["Normal", "Siaga", "Waspada", "Darurat"],
                    datasets: [
                      {
                        label: "Contoh",
                        labels: ["Normal", "Siaga", "Waspada", "Darurat"],
                        data: [4, 2, 3, 1],
                        backgroundColor: "#373784",
                      },
                    ],
                  }}
                />
                <br />
                <br />
                <h1 className="text_medium">BAR CHART</h1>
                <CHART_TEMPLATE
                  type="bar"
                  width="300"
                  height="200"
                  use_legend={false}
                  use_generate_array_color={true}
                  options={{
                    hoverBackgroundColor: "#f38026",
                    animation: false,
                    maintainAspectRatio: false,
                    responsive: false,
                    legend: {
                      display: false,
                    },
                    indexAxis: "x",
                    title: {
                      display: false,
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                  data={{
                    labels: ["Normal", "Siaga", "Waspada", "Darurat"],
                    datasets: [
                      {
                        label: "Contoh",
                        labels: ["Normal", "Siaga", "Waspada", "Darurat"],
                        data: [4, 2, 3, 1],
                        backgroundColor: "#373784",
                      },
                    ],
                  }}
                />
                <br />
                <br />
                <h1 className="text_medium">LINE CHART</h1>
                <CHART_TEMPLATE
                  type="line"
                  width="300"
                  height="200"
                  use_legend={false}
                  use_generate_array_color={true}
                  options={{
                    hoverBackgroundColor: "#f38026",
                    animation: false,
                    maintainAspectRatio: false,
                    responsive: false,
                    legend: {
                      display: false,
                    },
                    indexAxis: "x",
                    title: {
                      display: false,
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                  data={{
                    labels: ["Normal", "Siaga", "Waspada", "Darurat"],
                    datasets: [
                      {
                        label: "Contoh",
                        labels: ["Normal", "Siaga", "Waspada", "Darurat"],
                        data: [4, 2, 3, 1],
                        backgroundColor: "#373784",
                      },
                    ],
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(EXAMPLE_NOT_USE_Y);
