import React from "react";
// import { connect } from "react-redux";
import {
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridPagination,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import CreateRow from "./CreateRow";
import DownloadExcel from "../../App/validation/DownloadExcel";

// solusi sementara pakai functional component
function CustomToolbarContainer(props) {
  return (
    <GridToolbarContainer>
      <div className="row_table">
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <GridToolbarQuickFilter />
          <CreateRow {...props} />
          <GridToolbarColumnsButton />
          <DownloadExcel />
        </div>
        <div style={{ marginRight: "80px" }}>
          <GridPagination />
        </div>
      </div>
    </GridToolbarContainer>
  );
}

export default CustomToolbarContainer;
