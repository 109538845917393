/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import bbox from "@turf/bbox";

/*COMPONENT*/

/*REDUX*/
import { set_value_properties } from "../../App/actions/propertiesActions";
import { status_action } from "../../App/actions/mapActions";
import { set_value_layer } from "../../App/actions/layerActions";

/*PICTURE*/

/*FUNCTION*/
import getStatusMember from "../../App/validation/get-status-member";

/*DATA*/

/*CONST*/

class FlyTo extends Component {
  state = {};

  toggle_share = () => {
    const feature = this.props.feature;
    this.props.set_value_properties({
      key: "feature_will_be_shared_key",
      value: feature.key,
    });
    const { modal_share } = this.props.properties;
    this.props.set_value_properties({
      key: "modal_share",
      value: !modal_share,
    });
  };

  on_fly_feature = () => {
    const feature = this.props.feature;

    //STEP 1 masukkan ke dalam props feature_key_array untuk styling menonjolkan feature yang di select
    this.props.set_value_layer({
      key: "feature_key_selected",
      value: feature.key,
    });

    this.props.set_value_layer({
      key: "feature_object_selected",
      value: feature,
    });

    //STEP 2 mendapatkan data geometry dari feature untuk fly to
    if (!this.props.layer.is_pause_zoom_map) {
      const [min_longitude, min_latitude, max_longitude, max_latitude] =
        bbox(feature);

      const { map_object } = this.props.layer;
      if (map_object) {
        map_object.fitBounds(
          [
            [min_longitude, min_latitude], //Southwest corner [longitude, latitude]
            [max_longitude, max_latitude], //Northeast corner [longitude, latitude]
          ],
          {
            padding: {
              top: 150,
              bottom: 300,
              left: 420,
              right: 500,
            },
            maxZoom: this.props.layer.max_zoom,
            duration: 750,
          }
        );
      }
    }
    this.props.status_action();
  };

  render() {
    //local storage

    //local state

    //global props
    const { idx, indexOfFirstTodo } = this.props;
    const { isAuthenticated } = this.props.auth;
    const { user } = this.props.auth;
    const { geo_project } = this.props.project;

    //content
    let is_author = false;

    if (isAuthenticated) {
      let userCurrentStatus;

      const groups = geo_project?.groups || [];
      if (geo_project._id) {
        userCurrentStatus = getStatusMember(groups, user?._id);
      }
      is_author =
        !!userCurrentStatus || !!(geo_project?.user?._id === user?._id);
    }

    return (
      <main>
        <div className="h_full text_left">
          {idx + 1 + indexOfFirstTodo}
          <br />
          <button
            className="button margin_bottom background_blue"
            style={{
              whiteSpace: "nowrap",
            }}
            onClick={this.on_fly_feature}
          >
            Fly to
          </button>
          {is_author && (
            <>
              <br />
              <button
                className="button background_blue"
                onClick={this.toggle_share}
                feature={this.props.feature}
              >
                Share
              </button>
            </>
          )}
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  auth: state.auth,
  project: state.project,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  set_value_properties,
  status_action,
  set_value_layer,
})(FlyTo);
