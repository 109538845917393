const get_year_kai = (geo_layer) => {
  const timeseries_mode = geo_layer?.timeseries_object?.timeseries_mode;

  let timeseries_komponen_array = [];

  if (timeseries_mode === "kai_komponen_pendapatan_tiket") {
    const timeseries_object = geo_layer?.timeseries_object || {};

    const {
      year_array, // [ 2022, 2023, 2024, 2025, 2026, 2027]
      field_name_tahun,

      field_name_total,
      field_name_biaya_modal,
      field_name_keuntungan,

      field_name_biaya_operasi,
      field_name_biaya_perawatann,
    } = timeseries_object;

    const fields = geo_layer?.fields || [];
    const features = geo_layer?.geojson?.features || [];

    const field_key_tahun = fields.find(
      (item) => item.name === field_name_tahun
    )?.key; //komponen sumbu x

    const field_key_total = fields.find(
      (item) => item.name === field_name_total
    )?.key; //dipakai sumbu y 1
    const field_key_biaya_modal = fields.find(
      (item) => item.name === field_name_biaya_modal
    )?.key; //dipakai sumbu y 2
    const field_key_keuntungan = fields.find(
      (item) => item.name === field_name_keuntungan
    )?.key; //dipakai sumbu y 3

    const field_key_biaya_operasi = fields.find(
      (item) => item.name === field_name_biaya_operasi
    )?.key; //belum ada data
    const field_key_biaya_perawatann = fields.find(
      (item) => item.name === field_name_biaya_perawatann
    )?.key; //belum ada data

    //konversi dari features ke data_array
    timeseries_komponen_array = [
      //sumbu y sudah ada
      {
        name: field_name_total,
        key: field_key_total,
      },
      {
        name: field_name_biaya_modal,
        key: field_key_biaya_modal,
      },
      {
        name: field_name_keuntungan,
        key: field_key_keuntungan,
      },
      //sumbu y belum ada
      {
        name: field_name_biaya_operasi,
        key: field_key_biaya_operasi,
      },
      {
        name: field_name_biaya_perawatann,
        key: field_key_biaya_perawatann,
      },
    ];

    timeseries_komponen_array = timeseries_komponen_array.map((item) => {
      let data = [];
      features.forEach((feature) => {
        const value = feature?.properties?.[item.key]; //get value
        const tahun_value = feature?.properties?.[field_key_tahun]; //get tahun_value
        const tahun_index = year_array.findIndex(
          (item) => Number(item) === Number(tahun_value)
        ); //get tahun_index
        data[tahun_index] = Number(value); //set each value to year_index position
      });

      //mencari nilai maksimum maksimum dari data_array
      const y_max = Math.max(...data);

      //mendapatkan index dari nilai maksimum
      const index_max = data.findIndex(
        (value) => Number(value) === Number(y_max)
      );

      //mendapatkan nilai dari sumbu x yang sumbu y nya maksimum
      const x_max = year_array[index_max];

      const chart_js_data = {
        labels: year_array,
        y_max,
        x_max,
        index_max,
        datasets: [
          {
            label: item.name,
            labels: year_array,
            data: data,
            backgroundColor: "#373784", //warna dark_blue KAI
          },
        ],
      };

      return { ...item, chart_js_data };
    });
  }

  return timeseries_komponen_array;
};

export default get_year_kai;
