/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import CellReturnValue from "./CellReturnValue";
import { calculateWithoutConvert } from "../../App/validation/convert_data";

/*REDUX*/

/*PICTURE*/

/*FUNCTION*/

/*DATA*/

/*CONST*/

class PARENT_TABLE_NESTED_VIEWER extends Component {
  state = {};

  handlePreviewText = (value) => {
    this.setState(
      {
        text: value,
      },
      () => {
        this.toggleTextPreview();
      }
    );
  };

  handlePreviewImage = (value) => {
    this.setState({
      image: value,
    });
  };

  toggleImagePreview = () => {
    this.setState({ modalImagePreview: !this.state.modalImagePreview });
  };

  render() {
    //local storage

    //local state

    //global props
    const { layer_id, geo_layer_list, field_parent, feature_parent, child } =
      this.props.layer;

    //content
    const layer = geo_layer_list.find(
      (l) => l.geo_layer._id === layer_id
    )?.geo_layer;
    const fields = layer?.fields || [];
    const fields_child = fields.filter(
      (f) => f?.parent_uuid === field_parent?.uuid
    );
    let child_array = feature_parent?.child_array || [];
    if (!child) {
      //mode table level 1
      child_array = child_array.filter(
        (c) => c?.parent_uuid === field_parent?.uuid
      );
    } else {
      //mode table level 1 lebih
      child_array = child_array.filter(
        (c) =>
          c?.parent_uuid_temp === child?.child_uuid &&
          c?.parent_uuid === field_parent?.uuid
      );
    }
    const id_pembanding = layer?.layer_data_list?.[0]?._id;
    const layer_pembanding = geo_layer_list.find(
      (item) => item?.geo_layer?._id === id_pembanding
    )?.geo_layer;

    child_array = calculateWithoutConvert({
      features: child_array,
      fields: fields_child,
      layer_pembanding,
      feature_parent,
      source: "parent table",
    });

    return (
      <main style={{ minHeight: "75vh" }}>
        <h1 className="text_bold text_center margin_bottom">
          {field_parent?.name}
        </h1>
        <table className="table">
          <thead>
            <tr>
              <th rowSpan={2}>No</th>
              {fields_child.map((f, idx) => {
                return <th key={idx}>{f.name}</th>;
              })}
            </tr>
            <tr>
              {fields_child.map((f, idx) => {
                return (
                  <td key={idx}>
                    <span className="badge_pill_small background_black">
                      {f.type}
                    </span>
                  </td>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {child_array.map((c, idx) => {
              const properties = c?.properties;
              return (
                <tr key={idx}>
                  <td style={{ whiteSpace: "nowrap" }}>{idx + 1}</td>
                  {fields_child.map((f, idx) => {
                    return (
                      <td key={idx}>
                        <CellReturnValue
                          value={properties?.[f.key]}
                          field={f}
                          feature={feature_parent}
                          child={c}
                          handlePreviewText={this.handlePreviewText}
                          handlePreviewImage={this.handlePreviewImage}
                          toggleImagePreview={this.toggleImagePreview}
                        />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
});

export default connect(mapStateToProps, {})(PARENT_TABLE_NESTED_VIEWER);
