/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

import {
  set_value_insight,
  insight_search_admin,
  insight_hirarki_admin,
} from "../../App/actions/insight_v2";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

class InputAreaHirarki extends Component {
  state = {};

  componentDidMount() {
    //get hirarki provinsi
    this.props.insight_hirarki_admin({
      level: "provinsi",
    });
  }

  on_change_general = (e) => {
    const { provinsi, kota } = this.props.insight_reducer;

    const value = e.target.value;
    const name = e.target.name;
    this.props.set_value_insight({
      key: name,
      value: value,
    });

    if (name === "provinsi") {
      this.props.set_value_insight({
        key: "kota",
        value: "",
      });
      this.props.set_value_insight({
        key: "kecamatan",
        value: "",
      });
      this.props.set_value_insight({
        key: "kelurahan",
        value: "",
      });

      //get hirarki
      this.props.insight_hirarki_admin({
        PROVINSI: value,
        level: "kota",
      });
    } else if (name === "kota") {
      this.props.set_value_insight({
        key: "kecamatan",
        value: "",
      });
      this.props.set_value_insight({
        key: "kelurahan",
        value: "",
      });

      //get hirarki
      this.props.insight_hirarki_admin({
        PROVINSI: provinsi,
        KABKOT: value,
        level: "kecamatan",
      });
    } else if (name === "kecamatan") {
      this.props.set_value_insight({
        key: "kelurahan",
        value: "",
      });

      //get hirarki
      this.props.insight_hirarki_admin({
        PROVINSI: provinsi,
        KABKOT: kota,
        KECAMATAN: value,
        level: "kelurahan",
      });
    } else if (name === "kelurahan") {
    }
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props

    const {
      kelurahan_hirarki,
      kecamatan_hirarki,
      kota_hirarki,
      provinsi_hirarki,
      provinsi,
      kota,
      kecamatan,
      kelurahan,
    } = this.props.insight_reducer;

    //content

    return (
      <main>
        <section className="margin_bottom">
          <p className="text_bold">{dict?.["Provinces"]?.[language]}</p>
          <select
            id="provinsi"
            name="provinsi"
            value={provinsi}
            onChange={this.on_change_general}
            className="input_new"
            data-mapid="selectProvince"
          >
            <option value="" hidden>
              {dict?.["Select provinces"]?.[language]}
            </option>
            {provinsi_hirarki.map((item, idx) => {
              return (
                <option value={item.PROVINSI} key={idx}>
                  {item.PROVINSI}
                </option>
              );
            })}
          </select>
        </section>

        {provinsi && (
          <section className="margin_bottom">
            <p className="text_bold">{dict?.["Cities"]?.[language]}</p>
            <select
              id="kota"
              name="kota"
              value={kota}
              onChange={this.on_change_general}
              className="input_new"
              data-mapid="selectCities"
            >
              <option value="" hidden>
                {dict?.["Select cities"]?.[language]}
              </option>
              {kota_hirarki.map((item, idx) => {
                return (
                  <option value={item?.["KABKOT"]} key={idx}>
                    {item?.["KABKOT"]}
                  </option>
                );
              })}
            </select>
          </section>
        )}

        {kota && (
          <section className="margin_bottom">
            <p className="text_bold">Kecamatan</p>
            <select
              id="kecamatan"
              name="kecamatan"
              value={kecamatan}
              onChange={this.on_change_general}
              className="input_new"
              data-mapid="selectSubDistrict"
            >
              <option value="">Pilih Kecamatan</option>
              {kecamatan_hirarki.map((item, idx) => {
                return (
                  <option value={item?.["KECAMATAN"]} key={idx}>
                    {item?.["KECAMATAN"]}
                  </option>
                );
              })}
            </select>
          </section>
        )}

        {kecamatan && (
          <section className="margin_bottom">
            <p className="text_bold">Kelurahan</p>
            <select
              id="kelurahan"
              name="kelurahan"
              value={kelurahan}
              onChange={this.on_change_general}
              className="input_new"
              data-mapid="selectDistrict"
            >
              <option value="">Pilih kelurahan</option>
              {kelurahan_hirarki.map((item, idx) => {
                return (
                  <option value={item?.["DESA"]} key={idx}>
                    {item?.["DESA"]}
                  </option>
                );
              })}
            </select>
          </section>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  properties: state.properties,
  insight_reducer: state.insight_reducer,
});

export default connect(mapStateToProps, {
  set_value_insight,
  insight_search_admin,
  insight_hirarki_admin,
})(InputAreaHirarki);
