import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
// import PaperComponent from "./PapperComponent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { DialogActions, DialogContent } from "@mui/material";
import ICON_CLOSE from "../../../Assets/jsx/ICON_CLOSE";

export default function Modal(props) {
  const {
    title,
    modalSize,
    id = "modal",
    isOpen,
    onClose,
    children,
    fullScreen,
    content,
    footer,
  } = props;
  const theme = useTheme();
  const media_query_fullscreen = useMediaQuery(theme.breakpoints.down("md"));
  const [size, setSize] = useState("sm");

  useEffect(() => {
    if (modalSize === "extra_large") {
      setSize("xl");
    } else if (modalSize === "large") {
      setSize("lg");
    }
    else if (modalSize === "normal" || modalSize === "medium") {
      setSize("md");
    }
  }, [modalSize]);

  return (
    <Dialog
      id={id}
      fullScreen={fullScreen || media_query_fullscreen}
      fullWidth
      maxWidth={size}
      open={isOpen}
      onClose={onClose}
      aria-labelledby="draggable-dialog-title"
      PaperProps={{ sx: { borderRadius: "20px" } }}
    >
      <main className="margin_30">
        <header className="flex justify_between">
          {title && <DialogTitle
          //  style={{ cursor: "move" }}
          //  id="draggable-dialog-title"
          >
            {title}
          </DialogTitle>}
          <button
            onClick={() => onClose()}
            aria-label="close"
            className="absolute top_10 right_10"
          >
            <ICON_CLOSE
              onClick={() => onClose()}
              aria-label="close"
              className="hover_bigger"
              color="#CE0000" size={30} />
          </button>
          {/* </div> */}
        </header>
        {children && (
          <main className="marginBottom_20 marginLeft_20 marginRight_20">
            <section>{children}</section>
          </main>
        )}
        {content && <DialogContent>{content}</DialogContent>}
        {footer && <DialogActions>{footer}</DialogActions>}
      </main>
    </Dialog>
  );
}
