//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component
import SpinnerWhite from "../common_spinner/SpinnerWhite";

//Redux function
import { actionPayment } from "../../App/actions/paymentActions";

//Picture Asset

//General Function
import dict from "../../Data/dict.json";

class PaymentConfirmation extends Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.state = {};
  }

  handleSubmit = () => {
    const { payment } = this.props.payment;
    const body = {
      payment_id: payment._id,
      status: "user_cancel",
    };
    let finalObject;
    finalObject = {
      body,
    };
    this.props.actionPayment(finalObject);
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";

    const { loadingProcess } = this.props.payment;
    const buttonContent = loadingProcess ? (
      <div className="button_block_tight" id="delete">
        <SpinnerWhite
          width={30}
          unik="loading_item_list"
          marginTop="0px"
          center={true}
          border={0.1}
        />
      </div>
    ) : (
      <button onClick={this.handleSubmit} className="button_block" id="delete">
        {dict["Cancel transaction"][language]}
      </button>
    );
    const content = (
      <div style={{ textAlign: "center" }}>
        <div style={{ marginBottom: "30px" }}>
          {dict["Are you sure to cancel the transaction"][language]}
        </div>
        {buttonContent}
      </div>
    );
    return <div>{content}</div>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  payment: state.payment,
});

export default connect(mapStateToProps, { actionPayment })(PaymentConfirmation);
