/*eslint-disable*/

export const check_conditional_statement_validity = function (
  conditional_statement_list
) {
  try {
    conditional_statement_list.forEach((row, index) => {
      if (row.function === "if") {
        const string_logic = row.if.map((f) => f.content).join("");
        const is_bracket_valid =
          check_brackets(string_logic) === 0 ? true : false;
        if (!is_bracket_valid) {
          throw Error({ index, message: "Error brackets" });
        }

        const last_element = row.if[row.if.length - 1];
        if (
          (last_element.type === "operator" &&
            last_element.content !== ")" &&
            last_element.content !== "%") ||
          last_element.type === "math_symbol"
        ) {
          throw Error({ index, message: "Incomplete logic" });
        }

        let variabel_gantet = 0;
        const string_logic_calculateable = row.if
          .map((logic) => {
            if (logic.type === "variable") {
              variabel_gantet += 1;
              if (variabel_gantet > 1) {
                throw Error({
                  index,
                  message:
                    "Can't put variable beside another variable/constant",
                });
              }
              return 1;
            } else if (logic.content === "^" && logic.type === "operator") {
              variabel_gantet = 0;
              return "**";
            } else if (logic.content === "%" && logic.type === "operator") {
              variabel_gantet = 0;
              return "/100";
            } else if (logic.type === "constant") {
              variabel_gantet += 1;
              if (variabel_gantet > 1) {
                throw Error({
                  index,
                  message: "Can't put constant beside variable",
                });
              }
            } else if (logic.type === "operator") {
              variabel_gantet = 0;
            } else if (logic.type === "math_symbol") {
              variabel_gantet = 0;
            }
            return logic.content;
          })
          .join(" ");
        const result = eval(string_logic_calculateable);

        if (
          isNaN(result) ||
          result === undefined ||
          result === null ||
          result === "" ||
          result === NaN
        ) {
          throw { index, message: "Undefined result" };
        }

        if (result !== true && result !== false) {
          throw { index, message: "The result must be a boolean [true/false]" };
        }
      }
    });
    return {
      status: "valid",
    };
  } catch (e) {
    if (!isNaN(e.index)) {
      return {
        status: "invalid",
        ...e,
      };
    } else {
      return {
        status: "invalid",
      };
    }
  }
};

export function calculate_conditional_statement(
  conditional_statement_list,
  properties
) {
  try {
    let string_logic = "";
    conditional_statement_list.forEach((row, logic_index) => {
      if (row.function === "if") {
        if (logic_index === 0) {
          string_logic = string_logic + "if(";
        } else if (logic_index > 0) {
          string_logic = string_logic + "else if(";
        }
        const string_logic_calculateable = row.if
          .map((logic) => {
            if (logic.type === "variable") {
              let variable = properties[logic.ref_key];

              // Fungsi untuk mengubah variabel menjadi milisekon
              const convertToMilliseconds = (value) => {
                // checking data type - Date
                // pengecekkan tipe data date
                const date_regex = /^\d{4}-\d{2}-\d{2}$/;
                if (date_regex.test(value)) {
                  return new Date(value).getTime();
                }

                // checking data type - Time
                // pengecekkan tipe data time
                const time_regex = /^\d{2}:\d{2}$/;
                if (time_regex.test(value)) {
                  const [hour, minute] = value.split(":").map(Number);
                  const new_date = new Date();
                  new_date.setHours(hour, minute, 0, 0);

                  return new_date.getTime();
                }
                return value;
              };

              variable = convertToMilliseconds(variable);

              return variable;
            } else if (logic.content === "^" && logic.type === "operator") {
              return "**";
            } else if (logic.content === "%" && logic.type === "operator") {
              return "/100";
            }
            return logic.content;
          })
          .join(" ");
        string_logic = `${string_logic}${string_logic_calculateable}){result = "${row.then}"}`;
      } else if (row.function === "else") {
        string_logic = `${string_logic}else{result = "${row.then}"}`;
      }
    });
    let result;
    // console.table({ string_logic, result });
    eval(string_logic);
    return result;
  } catch (e) {
    return "";
  }
}

export function check_brackets(string) {
  var value = 0;
  for (var i = 0; i < string.length; i++) {
    if (string[i] === "(") value++;
    else if (string[i] === ")") value--;
  }
  return value;
}
