import React, { Component } from "react";
import dict from "../../Data/dict.json";
import { getAllFields, getGeoLayer } from "./../../App/reduxHelper/layer";
import { connect } from "react-redux";
import { MenuItem, Select } from "@mui/material";

const options = [
  { key: "sum", name: "Sum" },
  { key: "avg", name: "Average" },
  { key: "min", name: "Minimum" },
  { key: "max", name: "Maximum" },
  { key: "count", name: "Count" },
];

class TableFormula extends Component {
  constructor(props) {
    super(props);
    const { fields } = props;
    this.state = {
      parent_fields: fields?.filter((field) => field?.type === "table") || [],
      children_fields: [],
      field_parent_uuid: "",
      field_child_uuid: "",
      formula: "",
    };
  }

  set_table_formula = (key, value) => {
    const { set_table_formula } = this.props;
    const { field_parent_uuid, field_child_uuid, formula } = this.state;
    if (set_table_formula) {
      const body = {
        field_parent_uuid: key === "parent" ? value : field_parent_uuid,
        field_child_uuid: key === "child" ? value : field_child_uuid,
        formula: key === "formula" ? value : formula,
      };
      set_table_formula({ ...body });
    }
  };

  componentDidUpdate = (prevProps) => {
    const { table_formula } = this.props;
    if (typeof table_formula === "object") {
      const { field_parent_uuid, field_child_uuid, formula } = table_formula;
      if (
        prevProps?.table_formula?.field_parent_uuid !==
        table_formula?.field_parent_uuid
      ) {
        this.setState({ field_parent_uuid });
      }

      if (
        prevProps?.table_formula?.field_child_uuid !==
        table_formula?.field_child_uuid
      ) {
        this.setState({ field_child_uuid });
      }

      if (prevProps?.table_formula?.formula !== table_formula?.formula) {
        this.setState({ formula });
      }
    }
  };

  componentDidMount = () => {
    const { table_formula } = this.props;
    this.setState({
      field_parent_uuid: table_formula?.field_parent_uuid,
      field_child_uuid: table_formula?.field_child_uuid,
      formula: table_formula?.formula,
    });

    if (table_formula?.field_parent_uuid) {
      this.set_parent_column(table_formula?.field_parent_uuid);
    }
  };

  set_parent_column = (e) => {
    const { fields } = this.props;
    const children_fields = fields?.filter((field) => field?.parent_uuid === e);
    this.setState({
      children_fields,
    });
  };

  render() {
    const { language, from } = this.props;
    const {
      parent_fields,
      children_fields,
      field_parent_uuid,
      field_child_uuid,
      formula,
    } = this.state;
    return (
      <main>
        <section className="font_18 bold subtitle marginBottom_10">
          Summary of Nested Table
        </section>
        <section className={from === "sidebar" ? "" : "flex gap_5"}>
          <div className="flex flex_col">
            <label htmlFor="refer_to_column" className="font_14 w_full">
              Parent Column
            </label>
            <Select
              className="w_full bg_white w_200"
              placeholder={dict["Select"][language]}
              value={field_parent_uuid}
              onChange={(e) => {
                const value = e?.target?.value
                this.set_parent_column(value);
                this.set_table_formula("parent", value);
              }}
            >
              <MenuItem value={"select"}>{dict["Select"][language]}</MenuItem>
              {parent_fields
                .map((field) => {
                  return (
                    <MenuItem
                      key={field?.key} value={field?.key}
                    >
                      {field?.name}
                    </MenuItem>
                  );
                })}
            </Select>

          </div>
          {children_fields?.length > 0 && (
            <div className="flex flex_col">
              <label htmlFor="refer_to_column" className="font_14 w_full">
                Child Column
              </label>
              <Select
                className="w_full bg_white w_200"
                placeholder={dict["Select"][language]}
                value={field_child_uuid}
                onChange={(e) => {
                  const value = e?.target?.value
                  this.set_table_formula("child", value);
                }}>
                <MenuItem value={"select"}>{dict["Select"][language]}</MenuItem>
                {children_fields
                  .map((field) => {
                    return (
                      <MenuItem
                        key={field?.key} value={field?.key}
                      >
                        {field?.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </div>
          )}
          {children_fields?.length > 0 && (
            <div className="flex flex_col">
              <label htmlFor="refer_to_column" className="font_14 w_full">
                Formula
              </label>
              <Select
                className="w_full bg_white w_200"
                placeholder={dict["Select"][language]}
                value={formula}
                onChange={(e) => {
                  const value = e?.target?.value
                  this.set_table_formula("formula", value);
                }}>
                <MenuItem value={"select"}>{dict["Select"][language]}</MenuItem>
                {options
                  .map(({ key, name }) => {
                    return (
                      <MenuItem
                        key={key} value={key}
                      >
                        {name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </div>
          )}
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  fields: getAllFields(
    getGeoLayer(state.layer) || state.layer.geo_layer_wo_geojson?.geo_layer
  ),
});
export default connect(mapStateToProps)(TableFormula);
