/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import CellReturnValue from "../viewer_table/CellReturnValue";

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/

class CountingCustomTable extends Component {
  render() {
    //local storage

    //local state

    //global props
    const {
      // feature_key_array,
      // custom_counting_id,
      // custom_counting_layer,
      custom_counting_fields,
      feature_array,
    } = this.props;

    //content

    return (
      <main className="max_hight">
        <h1 className="text_header">
          Geometri yang berada di dalam radius titik surveyor
        </h1>
        <br />
        <table className="table">
          <thead>
            <tr>
              <th>No</th>
              {custom_counting_fields.map((field, idx) => {
                return (
                  <th key={idx}>
                    <p>{field?.name}</p>
                    <p className="badge_pill">{field.type}</p>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {feature_array.map((feature, idx) => {
              return (
                <tr key={idx}>
                  <th>{idx + 1}</th>
                  {custom_counting_fields.map((field, idx_field) => {
                    const value = feature?.properties[field?.key] || "";
                    return (
                      <td key={idx_field}>
                        <CellReturnValue
                          value={value}
                          field={field}
                          feature={feature}
                          handlePreviewImage={this.handlePreviewImage}
                          handlePreviewText={this.handlePreviewText}
                          toggleImagePreview={this.toggleImagePreview}
                          deleteDriver={this.deleteDriver}
                        />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
});

export default connect(mapStateToProps, {
  // getDetailLayerById
})(CountingCustomTable);
