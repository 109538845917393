/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import bbox from "@turf/bbox";
import area from "@turf/area";
import geojson_geometries_lookup from "geojson-geometries-lookup";
import points_within_polygon from "@turf/points-within-polygon";

/*COMPONENT*/
import ProgressBar from "../common_spinner/ProgressBar";

/*REDUX*/
import {
  set_value_toolbox,
  push_value_toolbox_single,
  status_toolbox,
} from "../../App/actions/toolboxActions";
import { set_value_properties } from "../../App/actions/propertiesActions";

/*PICTURE*/

/*FUNCTION*/
import { generate_paint } from "../../Functions/map_style/generate_paint";

/*DATA*/

/*CONST*/
const timeout_miliseconds = 10;
const item_per_looping = 25;

class GRID_STEP_2 extends Component {
  state = {};

  on_change = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const toolbox_grid_object = this.props.toolbox.toolbox_grid_object;
    toolbox_grid_object[name] = value;
    this.props.set_value_toolbox({
      key: "toolbox_grid_object",
      value: toolbox_grid_object,
    });
  };

  on_run_grid = () => {
    this.props.set_value_properties({
      key: "style_parameter",
      value: {
        paint_object: {},
        key: "",
        field_key: "",
        case_recomendation: "",
        color_logic: "", //constant, use_existing, by_text, by_number_gradient, by_number_step
        color_mode: "",
        value_array: [],
        type_of_object: {},
        type_of_array: [],
        min: 0,
        max: 0,
        steps: 5,
        is_number_count: [],
        constant_color: "#a0d062",
        style_type: "", //circle, line, fill
        is_cluster: false,
      },
    });
    this.props.set_value_properties({
      key: "paint_object_edited",
      value: {
        "fill-opacity": 0.6,
        "fill-outline-color": "#000",
        "fill-color": "#fff",
      },
    });
    const { geo_layer_list } = this.props.layer;
    const { toolbox_grid_object } = this.props.toolbox;
    const { layer_point_id, layer_polygon_id, grid_mode } = toolbox_grid_object;
    const features_polygon =
      geo_layer_list.find((item) => item?.geo_layer?._id === layer_polygon_id)
        ?.geo_layer?.geojson?.features || [];
    const features_point =
      geo_layer_list.find((item) => item?.geo_layer?._id === layer_point_id)
        ?.geo_layer?.geojson?.features || [];
    if (features_polygon.length > 0 && features_point.length > 0) {
      if (grid_mode === "hex") {
        this.generate_hex({ features_polygon, features_point });
      } else {
        this.generate_square({ features_polygon, features_point });
      }
    }
  };

  generate_square = ({ features_polygon, features_point }) => {
    this.props.set_value_toolbox({
      key: "features_grid",
      value: [],
    });
    this.props.status_toolbox();
    setTimeout(() => {
      const { toolbox_grid_object } = this.props.toolbox;
      const {
        grid_counts,
        grid_width_meters,
        grid_size_mode, //count, width
      } = toolbox_grid_object;
      const geojson_polygon = {
        type: "FeatureCollection",
        features: [...features_polygon, ...features_point],
      };
      const total_square_meters = area(geojson_polygon); // in square meters
      this.props.set_value_toolbox({
        key: "total_square_meters",
        value: parseInt(total_square_meters),
      });
      const [min_longitude, min_latitude, max_longitude, max_latitude] =
        bbox(geojson_polygon);
      // Calculate the width and height of the bounding box in degrees
      const width = max_longitude - min_longitude;
      const height = max_latitude - min_latitude;
      let square_side_length;
      if (grid_size_mode === "count") {
        const width_meters = width * 111_000; // Convert width and height to approximate meters
        const height_meters = height * 111_000;
        const bounding_box_area = width_meters * height_meters; // bounding box area in square meters
        const initial_cell_area = bounding_box_area / grid_counts; // initial cell area based on bounding box area
        // Adjust the cell area to fit the demography area more accurately
        const adjusted_cell_area =
          initial_cell_area * (total_square_meters / bounding_box_area);
        square_side_length = Math.sqrt(adjusted_cell_area); // in meters
        this.props.set_value_toolbox({
          key: "grid_width_meters",
          value: parseInt(square_side_length),
        });
      } else {
        square_side_length = grid_width_meters; // in meters
      }

      // Convert square side length back to degrees for grid generation
      const square_side_deg = square_side_length / 111_000;
      const features_grid_raw = []; // Create the square grid features
      const num_rows = Math.ceil(height / square_side_deg);
      const num_cols = Math.ceil(width / square_side_deg);
      for (let row = 0; row < num_rows; row++) {
        for (let col = 0; col < num_cols; col++) {
          const min_lon = min_longitude + col * square_side_deg;
          const min_lat = min_latitude + row * square_side_deg;
          const max_lon = min_lon + square_side_deg;
          const max_lat = min_lat + square_side_deg;
          // Define square points (clockwise from bottom-left)
          const square_points = [
            [min_lon, min_lat],
            [max_lon, min_lat],
            [max_lon, max_lat],
            [min_lon, max_lat],
            [min_lon, min_lat], // Close the polygon
          ];
          // Create the square cell as a GeoJSON Polygon feature
          const cell_feature = {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [square_points],
            },
            properties: {
              row,
              col,
            },
          };
          features_grid_raw.push(cell_feature);
        }
      }
      this.props.set_value_toolbox({
        key: "features_grid_raw",
        value: features_grid_raw,
      });
      // Clear grid intersections
      this.clear_grid_intersections({
        features_polygon,
        features_grid_raw,
        features_point,
      });
    }, timeout_miliseconds); // milliseconds delay
  };

  generate_hex = ({ features_polygon, features_point }) => {
    this.props.set_value_toolbox({
      key: "features_grid",
      value: [],
    });
    this.props.status_toolbox();
    setTimeout(() => {
      const { toolbox_grid_object } = this.props.toolbox;
      const {
        grid_counts,
        grid_width_meters,
        grid_size_mode, //count, width
      } = toolbox_grid_object;
      const geojson_polygon = {
        type: "FeatureCollection",
        features: [...features_polygon, ...features_point],
      };
      const total_square_meters = area(geojson_polygon); // in square meters
      this.props.set_value_toolbox({
        key: "total_square_meters",
        value: parseInt(total_square_meters),
      });
      const [min_longitude, min_latitude, max_longitude, max_latitude] =
        bbox(geojson_polygon);
      // Calculate the width and height of the bounding box in degrees
      const width = max_longitude - min_longitude;
      const height = max_latitude - min_latitude;
      let hex_width;
      let hex_height;
      let hex_radius;

      if (grid_size_mode === "count") {
        const width_meters = width * 111_000; //convert width and height to approximate meters
        const height_meters = height * 111_000;
        const bounding_box_area = width_meters * height_meters; //bounding box area in square meters
        const initial_cell_area = bounding_box_area / grid_counts; //initial cell area based on bounding box area
        // Adjust the cell area to fit the demography area more accurately
        const adjusted_cell_area =
          initial_cell_area * (total_square_meters / bounding_box_area);
        hex_radius = Math.sqrt((2 * adjusted_cell_area) / (3 * Math.sqrt(3))); //in meters
        // Hexagon dimensions
        hex_width = 2 * hex_radius; // Horizontal distance across hexagon
        this.props.set_value_toolbox({
          key: "grid_width_meters",
          value: parseInt(hex_width),
        });
      } else {
        hex_width = grid_width_meters;
        hex_radius = hex_width / 2;
      }

      hex_height = Math.sqrt(3) * (hex_width / 2); // Vertical distance across hexagon

      // Convert hex width and height back to degrees for grid generation
      const hex_width_deg = hex_width / 111_000;
      const hex_height_deg = hex_height / 111_000;
      const features_grid_raw = []; // Create the hexagonal grid features
      const num_rows = Math.ceil(height / hex_height_deg);
      const num_cols = Math.ceil(width / (hex_width_deg * 0.75)); // offset columns by 3/4 width for hex pattern
      for (let row = 0; row < num_rows; row++) {
        // Loop through rows and columns to generate hexagonal grid cells
        for (let col = 0; col < num_cols; col++) {
          const center_longitude = min_longitude + col * hex_width_deg * 0.75; // Calculate hex center position
          let center_lat = min_latitude + row * hex_height_deg;
          // Offset every second row by half the hex width to create the staggered hex pattern
          if (col % 2 === 1) {
            center_lat += hex_height_deg / 2;
          }
          // Define hexagon points based on center point and hex radius
          const hex_points = [];
          for (let i = 0; i < 6; i++) {
            const angle = (Math.PI / 3) * i; // 60 degrees for each hex side
            const point_longitude =
              center_longitude + (hex_radius / 111_000) * Math.cos(angle);
            const point_latitude =
              center_lat + (hex_radius / 111_000) * Math.sin(angle);
            hex_points.push([point_longitude, point_latitude]);
          }
          hex_points.push(hex_points[0]); // close the polygon
          //Create the hex cell as a GeoJSON Polygon feature
          const cell_feature = {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [hex_points],
            },
            properties: {
              row,
              col,
            },
          };
          features_grid_raw.push(cell_feature);
        }
      }
      this.props.set_value_toolbox({
        key: "features_grid_raw",
        value: features_grid_raw,
      });
      // Clear grid intersections
      this.clear_grid_intersections({
        features_polygon,
        features_grid_raw,
        features_point,
      });
    }, timeout_miliseconds); // milliseconds delay
  };

  clear_grid_intersections = ({
    features_polygon,
    features_grid_raw,
    features_point,
  }) => {
    this.props.set_value_toolbox({
      key: "total_poi_initial",
      value: features_point.length,
    });

    let min_grid_value = Infinity;
    let max_grid_value = -Infinity;
    this.setState({ loading_status_grid: true });

    const geojson_point = {
      type: "FeatureCollection",
      features: features_point,
    };

    const delay_millisecond_constant = 77;
    const delay_promise = () =>
      new Promise((res) => setTimeout(res, delay_millisecond_constant));

    const total_items = features_grid_raw.length;
    const groups_per_looping = [];
    const geojson_polygon = {
      type: "FeatureCollection",
      features: features_polygon,
    };
    const geometry_lookup = new geojson_geometries_lookup(geojson_polygon);

    // Helper function to calculate the center of a polygon
    const calculate_center = (coordinates) => {
      let x = 0;
      let y = 0;
      let n = coordinates.length;
      coordinates.forEach(([longitude, latitude]) => {
        x += longitude;
        y += latitude;
      });
      return [x / n, y / n];
    };

    // Divide into manageable groups
    for (let i = 0; i < total_items; i += item_per_looping) {
      groups_per_looping.push({
        start: i,
        end: Math.min(i + item_per_looping, total_items),
      });
    }

    this.props.set_value_toolbox({
      key: "current_grid_group",
      value: 0,
    });
    this.props.set_value_toolbox({
      key: "total_grid_group",
      value: groups_per_looping.length,
    });

    const parent_function = () => {
      return groups_per_looping.reduce(
        (last_promise, range, index) =>
          last_promise.then((result_sum) =>
            child_function(range, index).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };

    const child_function = async (range, index) => {
      return delay_promise().then(() => {
        const core_function = async () => {
          try {
            const items = features_grid_raw.slice(range.start, range.end);
            items.forEach((feature_grid) => {
              // Calculate the center of the grid
              const coordinates = feature_grid.geometry.coordinates[0];
              const [centerX, centerY] = calculate_center(coordinates);

              const center_point = {
                type: "Point",
                coordinates: [centerX, centerY],
              };

              // Check if the center point is inside any polygon
              const geojson_filtered =
                geometry_lookup.getContainers(center_point);
              if (geojson_filtered?.features?.length > 0) {
                const geojson_poi_inside = points_within_polygon(
                  geojson_point,
                  feature_grid
                );
                const all_poi_count = geojson_poi_inside?.features?.length || 0;

                feature_grid.properties = {
                  ALL_POI_COUNT: all_poi_count,
                };

                // Update min and max values
                if (all_poi_count < min_grid_value) {
                  min_grid_value = all_poi_count;
                }
                if (all_poi_count > max_grid_value) {
                  max_grid_value = all_poi_count;
                }

                this.props.push_value_toolbox_single({
                  key: "features_grid",
                  value: feature_grid,
                });
                const { toolbox_grid_object } = this.props.toolbox;
                toolbox_grid_object["min_grid_value"] = min_grid_value;
                toolbox_grid_object["max_grid_value"] = max_grid_value;
              }
            });
            this.props.set_value_toolbox({
              key: "current_grid_group",
              value: index + 1,
            });
          } catch (error) {}
        };
        return core_function();
      });
    };

    parent_function().then(() => {
      this.setState({ loading_status_grid: false });
      const { toolbox_grid_object } = this.props.toolbox;
      toolbox_grid_object["grid_counts"] =
        this.props.toolbox.features_grid.length;
      this.props.set_value_toolbox({
        key: "toolbox_grid_object",
        value: toolbox_grid_object,
      });
      const paint_object_edited = {
        "fill-opacity": 0.6,
        "fill-outline-color": "#000",
      };
      const { min_grid_value, max_grid_value } = toolbox_grid_object;
      const style_parameter = {
        color_mode: "green_to_yellow_to_red", //green_to_yellow_to_red
        color_logic: "by_number_step", //by_number_step
        field_key: "ALL_POI_COUNT", //ALL_POI_COUNT
        style_type: "fill", //fill
        paint_object_edited,
        min: min_grid_value,
        max: max_grid_value,
        steps: 5,
      };
      const paint_object = generate_paint(style_parameter);
      //properties untuk edit layer config
      this.props.set_value_properties({
        key: "paint_object_edited",
        value: paint_object,
      });
      this.props.set_value_properties({
        key: "style_parameter",
        value: style_parameter,
      });
      this.props.status_toolbox();
    });
  };

  on_reset = () => {
    this.props.set_value_toolbox({
      key: "features_grid",
      value: [],
    });
    this.props.set_value_toolbox({
      key: "features_grid_raw",
      value: [],
    });
    this.props.status_toolbox();
  };

  render() {
    //local storage

    //local state

    //global props
    const { current_grid_group, total_grid_group, toolbox_grid_object } =
      this.props.toolbox;

    //content
    const { grid_size_mode, grid_mode, grid_counts, grid_width_meters } =
      toolbox_grid_object;

    return (
      <main className="container_light background_grey_light outline_transparent margin_bottom">
        <h2 className="text_bold">Pengaturan</h2>
        <p className="text_small">Parameter ukuran/jumlah grid</p>
        <select
          className="margin_bottom"
          name="grid_size_mode"
          value={grid_size_mode}
          onChange={this.on_change}
        >
          <option value="count">Jumlah grid</option>
          <option value="width">Ukuran grid</option>
        </select>
        {grid_size_mode === "count" ? (
          <>
            <p className="text_small">Jumlah perkiraan grid</p>
            <input
              className="margin_bottom"
              value={grid_counts}
              name="grid_counts"
              onChange={this.on_change}
            />
          </>
        ) : (
          <>
            <p className="text_small">Ukuran grid (meter)</p>
            <input
              className="margin_bottom"
              value={grid_width_meters}
              name="grid_width_meters"
              onChange={this.on_change}
            />
          </>
        )}

        <p className="text_small">Grid mode</p>
        <select
          className="margin_bottom"
          name="grid_mode"
          value={grid_mode}
          onChange={this.on_change}
        >
          <option value="square">Square</option>
          <option value="hex">Hexagon</option>
        </select>
        <ProgressBar
          current_number={current_grid_group}
          total_number={total_grid_group}
          name="Membuat grid"
        />
        <button
          className="button margin_right background_blue"
          onClick={this.on_run_grid}
        >
          Run
        </button>
        <button className="button background_black" onClick={this.on_reset}>
          Reset
        </button>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  toolbox: state.toolbox,
  map: state.map,
  layer: state.layer,
});

export default connect(mapStateToProps, {
  set_value_toolbox,
  push_value_toolbox_single,
  status_toolbox,
  set_value_properties,
})(GRID_STEP_2);
