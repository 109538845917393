/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_sini } from "../../App/actions/sini_v2";

/*PICTURE ASSET*/
import icon_expand_bottom from "../../Assets/svg/icon_expand_bottom.svg";

/*GENERAL FUNCTION & DATA*/
// import dict from "../../Data/dict.json";

/*NON IMPORT*/

class SINI_DISASTER_RISK extends Component {
  state = {};

  componentDidMount() {
    const { sini_v2_list, request_id_active, ai_parent_detail_list } =
      this.props.sini;
    const { loading_item_array } = this.props.loading_reducer;
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    const sini_object = merge_array.find(
      (item) => item.request_id === request_id_active
    );
    const flood_one = sini_object?.flood_one;
    const landslide_one = sini_object?.landslide_one;
    const tsunami_one = sini_object?.tsunami_one;
    const poi_features = sini_object?.poi_features;
    if (
      flood_one !== undefined ||
      landslide_one !== undefined ||
      tsunami_one !== undefined ||
      loading_item_array.length === 0 ||
      poi_features !== undefined
    ) {
      this.add_slide_in_class();
    }
  }

  componentDidUpdate(prevProps) {
    const loading_item_array_before =
      prevProps.loading_reducer.loading_item_array;
    const loading_item_array_after =
      this.props.loading_reducer.loading_item_array;
    const last_loading =
      loading_item_array_after[loading_item_array_after.length - 1];
    if (
      loading_item_array_after.length !== loading_item_array_before.length &&
      (last_loading === "flood" || last_loading === "get_ai_parent_object")
    ) {
      this.add_slide_in_class();
    }
  }

  add_slide_in_class = () => {
    const element = document.querySelector("#flood_parent");
    if (element) {
      element.classList.add("slide-in");
    }
  };

  on_set_sini_menu_active = () => {
    this.props.set_value_sini({
      key: "sini_menu_active",
      value: "flood",
    });
    this.props.set_value_sini({
      key: "sini_map_show",
      value: [
        "flood",
        "landslide",
        "tsunami",
        "flood_neighbor",
        "landslide_neighbor",
        "tsunami_neighbor",
      ],
    });
  };

  render() {
    //local storage
    // const language = localStorage?.language || "ina";

    //local state

    //global props
    const { sini_v2_list, request_id_active, ai_parent_detail_list } =
      this.props.sini;
    const { loading_item_array } = this.props.loading_reducer;

    //content
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    const is_loading_flood = loading_item_array.includes("flood");
    const is_loading_landslide = loading_item_array.includes("landslide");
    const is_loading_tsunami = loading_item_array.includes("tsunami");

    const sini_object = merge_array.find(
      (item) => item.request_id === request_id_active
    );
    const flood_one = sini_object?.flood_one;
    const landslide_one = sini_object?.landslide_one;
    const tsunami_one = sini_object?.tsunami_one;

    const flood_neighbor = sini_object?.flood_neighbor;
    const landslide_neighbor = sini_object?.landslide_neighbor;
    const tsunami_neighbor = sini_object?.tsunami_neighbor;

    let content;
    let final_content;

    if (
      is_loading_flood ||
      is_loading_landslide ||
      is_loading_tsunami ||
      flood_one !== undefined ||
      landslide_one !== undefined ||
      tsunami_one !== undefined ||
      flood_neighbor?.length > 0 ||
      landslide_neighbor?.length > 0 ||
      tsunami_neighbor?.length > 0
      // ||
      // loading_item_array.length === 0
    ) {
      let flood_content;
      if (is_loading_flood) {
        flood_content = (
          <>
            <p className="text_small">Memuat data...</p>
            <br />
          </>
        );
      } else if (
        flood_one?.properties?.["Kelas"] ||
        flood_neighbor?.length > 0
      ) {
        flood_content = (
          <main className="margin_bottom">
            <section>
              <p className="text_small">Bahaya banjir di titik yang diklik</p>
              <p className="text_bold">
                {flood_one?.properties?.["Kelas"] || "Tidak ada data"}
              </p>
            </section>
            <section>
              <p className="text_small margin_right">Data sekitar</p>
              <p className="text_bold">{flood_neighbor?.length || 0} area</p>
            </section>
          </main>
        );
      } else {
        flood_content = <></>;
      }
      let landslide_content;
      if (is_loading_landslide) {
        landslide_content = (
          <>
            <p className="text_small">Memuat data...</p>
            <br />
          </>
        );
      } else if (is_loading_flood) {
        landslide_content = <></>;
      } else if (
        landslide_one?.properties?.["Kelas"] ||
        landslide_neighbor?.length > 0
      ) {
        landslide_content = (
          <main className="margin_bottom">
            <section>
              <p className="text_small">Bahaya longsor di titik yang diklik</p>
              <p className="text_bold">
                {landslide_one?.properties?.["Kelas"] || "Tidak ada data"}
              </p>
            </section>
            <section>
              <p className="text_small margin_right">Data sekitar</p>
              <p className="text_bold">
                {landslide_neighbor?.length || 0} area
              </p>
            </section>
          </main>
        );
      } else {
        landslide_content = <></>;
      }

      let tsunami_content;
      if (is_loading_tsunami) {
        tsunami_content = (
          <>
            <p className="text_small">Memuat data...</p>
            <br />
          </>
        );
      } else if (is_loading_landslide) {
        tsunami_content = <></>;
      } else if (
        tsunami_one?.properties?.["Kelas"] ||
        tsunami_neighbor?.length > 0
      ) {
        tsunami_content = (
          <main className="margin_bottom">
            <section>
              <p className="text_small">Bahaya tsunami di titik yang diklik</p>
              <p className="text_bold">
                {tsunami_one?.properties?.["Kelas"] || "Tidak ada data"}
              </p>
            </section>
            <section>
              <p className="text_small margin_right">Data sekitar</p>
              <p className="text_bold">{tsunami_neighbor?.length || 0} area</p>
            </section>
          </main>
        );
      } else {
        tsunami_content = <></>;
      }

      if (
        !flood_one?.properties?.["Kelas"] &&
        !landslide_one?.properties?.["Kelas"] &&
        !tsunami_one?.properties?.["Kelas"] &&
        !flood_neighbor?.length &&
        !landslide_neighbor?.length &&
        !tsunami_neighbor?.length &&
        loading_item_array?.length === 0
      ) {
        content = <p className="text_small">Tidak ada data</p>;
      } else {
        content = (
          <main>
            {flood_content}
            {landslide_content}
            {tsunami_content}
          </main>
        );
      }
      final_content = (
        <main className="container_light background_white margin_bottom outline_green">
          <div
            className="badge background_green margin_bottom"
            onClick={this.on_set_sini_menu_active}
          >
            Kebencanaan
          </div>
          {content}
          <div className="text_center">
            <img
              onClick={this.on_set_sini_menu_active}
              alt="expand"
              src={icon_expand_bottom}
              width={45}
              className="cursor_pointer"
              data-mapid="clickOpenBencana"
            />
          </div>
        </main>
      );
    } else {
      final_content = null;
    }

    return <main id="flood_parent">{final_content}</main>;
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, { set_value_sini })(SINI_DISASTER_RISK);
