import { FunctionPlugin, CellError, ErrorType, FunctionArgumentType } from 'hyperformula';

/**
 * Custom function plugin.
 */
export class IrrCustomPlugin extends FunctionPlugin {
  irr(ast, state) {
    return this.runFunction(
      ast.args,
      state,
      this.metadata('IRR'),
      (firstParam, secondParam) => {
        const cashFlows = firstParam?.data?.flat();
        const guess = secondParam;
        const result = this.irr_calculation(cashFlows, guess)
        return result
      },
    );
  }

  irr_calculation = (cashFlows, guess = 0.1) => {
    const values = cashFlows.filter(v => typeof v === 'number' && !isNaN(v));
    if (values.length === 0) {
      return new CellError(ErrorType.VALUE);
    }

    const maxIterations = 1000;
    const tolerance = 1e-6;
    let irr = guess;

    if (Array.isArray(values)) {
      for (let i = 0; i < maxIterations; i++) {
        let npv = 0;
        let dNpv = 0;
        for (let j = 0; j < values.length; j++) {
          if (!isNaN(values?.[j])) {
            npv += values?.[j] / Math.pow(1 + irr, j);
            dNpv -= (j * values?.[j]) / Math.pow(1 + irr, j + 1);
          }
        }
        let newIrr = irr - npv / dNpv;
        if (Math.abs(newIrr - irr) < tolerance) {
          return newIrr;
        }
        irr = newIrr;
      }
      return new CellError(ErrorType.NUM);
    }
  }
}

// Static property with the custom functions definitions
IrrCustomPlugin.implementedFunctions = {
  IRR: {
    method: 'irr',
    parameters: [
      { argumentType: FunctionArgumentType.RANGE },
      { argumentType: FunctionArgumentType.NUMBER }
    ],
  },
};

// Custom functions translations
export const IrrCustomPluginTranslations = {
  enGB: {
    IRR: 'IRR',
  },
};
