import React, { Component } from "react";
import { connect } from "react-redux";
import DeleteIconFill from "../common_icon/DeleteIconFill";

import icon_fly from "../../Assets/svg/icon_fly.svg";
import icon_assign from "../../Assets/svg/icon_assign.svg";
import icon_assign_grey from "../../Assets/svg/icon_assign_grey.svg";

class NumberAndAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActionButtonShown: false,
    };
  }

  componentDidMount = () => {
    this.buttonAssign();
  };

  componentDidUpdate = (prevProps) => {
    const geo_layer_list_before = this.props.layer.geo_layer_list;
    const geo_layer_list_after = prevProps.layer.geo_layer_list;

    const layer_id_before = this.props.layer?.layer_id;
    const layer_id_after = prevProps?.layer?.layer_id;

    const is_survey_before = geo_layer_list_before.find(
      (l) => l.geo_layer._id === layer_id_before
    )?.geo_layer.is_survey;

    const is_survey_after = geo_layer_list_after.find(
      (l) => l.geo_layer._id === layer_id_after
    )?.geo_layer.is_survey;

    if (
      prevProps?.params?.row?.formStatus?.status !==
        this?.props?.params?.row?.formStatus?.status ||
      is_survey_before !== is_survey_after
    ) {
      this.buttonAssign();
    }
  };

  toggleActionButtonShown = (value) => {
    this.setState({ isActionButtonShown: value });
  };

  buttonAssign = () => {
    const { layer_id, geo_layer_list } = this.props.layer;

    const is_survey = geo_layer_list.find((l) => l.geo_layer._id === layer_id)
      ?.geo_layer.is_survey;

    return is_survey === false || is_survey === undefined ? null : (
      <button
        onClick={() => this.props.toggleAssign(this.props.params.row)}
        style={{
          marginRight: 5,
        }}
      >
        <img
          src={
            this?.props?.params?.row?.formStatus?.status === "assign"
              ? icon_assign_grey
              : icon_assign
          }
          alt="icon assign"
          width={30}
          height={30}
        />
      </button>
    );
  };

  render() {
    return (
      <section
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: this.props.backgroundColor,
        }}
        onMouseEnter={() => {
          this.toggleActionButtonShown(true);
        }}
        onMouseLeave={() => {
          this.toggleActionButtonShown(false);
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            height: "100%",
            alignItems: "center",
          }}
        >
          {this.state.isActionButtonShown && (
            <div
              style={{
                display: "flex",
              }}
            >
              {/* {this.buttonAssign()} */}
              <button
                onClick={(e) => {
                  this.props.toggleDeleteFeature(this.props.params.row);
                }}
                style={{
                  fill: "#ca0b00",
                  marginRight: 5,
                }}
              >
                <DeleteIconFill width={30} height={30} />
              </button>
              <button
                onClick={(e) => {
                  this.props.onClick(this.props.params.id);
                }}
              >
                <img src={icon_fly} alt="icon assign" width={30} height={30} />
              </button>
            </div>
          )}
          {!this.state.isActionButtonShown && (
            <div>{this.props.params.id + 1}.</div>
          )}
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
});

export default connect(mapStateToProps, {})(NumberAndAction);
