import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { get_projects_from_recovery } from "../../../App/actions/recoveryProjectActions";
import { get_layers_from_recovery } from "../../../App/actions/recoveryLayerActions";

function useRecovery({ limit, update_skip }) {
  const dispatch = useDispatch();

  const [restore_page, set_restore_page] = useState(2);
  const [is_multiple, set_is_multiple] = useState(false);
  const [actived_tab, set_actived_tab] = useState("project"); // tab yang aktif (project atau layer)
  const [mode_view, set_mode_view] = useState("list");
  const [recovery_of_projects, set_recovery_of_projects] = useState([]); // daftar project dalam recovery
  const [recovery_of_layers, set_recovery_of_layers] = useState([]); // daftar layer dalam recovery
  const [selected, set_selected] = useState([]); // project atau layer yang ingin dipulihkan
  const [restore_selected_project, set_restore_selected_project] =
    useState(null); // project yang dipilih ketika ingin memulihkan layer
  const [sort_by, set_sort_by] = useState({
    attribute: "trash.updated_at",
    sort_value: -1,
  }); // mode sort yang sedang aktif
  const [skip, set_skip] = useState({
    project: 0,
    layer: 0,
  }); // jumlah skip (kebutuhan fitur load more)
  const [is_stop_load_more, set_is_stop_load_more] = useState(false);

  // [HIT API] get data ketika ada perubahan pada tab yang dipilih (sort [asc/desc] atau tab [project/layer])
  useEffect(() => {
    const { attribute, sort_value } = sort_by;
    if (actived_tab === "project") {
      dispatch(
        get_projects_from_recovery({
          limit,
          skip: 0,
          attribute,
          sort_value,
        })
      ).then((res) => {
        const first_15_projects = res;
        set_recovery_of_projects(first_15_projects);
        update_skip(first_15_projects?.length);
      });
    } else {
      dispatch(
        get_layers_from_recovery({ limit, skip: 0, attribute, sort_value })
      ).then((res) => {
        const first_15_layers = res;
        set_recovery_of_layers(first_15_layers);
        update_skip(first_15_layers?.length);
      });
    }
  }, [actived_tab, sort_by?.["attribute"], sort_by?.["sort_value"]]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    restore_page,
    set_restore_page,
    is_multiple,
    set_is_multiple,
    actived_tab,
    set_actived_tab,
    mode_view,
    set_mode_view,
    recovery_of_projects,
    set_recovery_of_projects,
    recovery_of_layers,
    set_recovery_of_layers,
    selected,
    set_selected,
    restore_selected_project,
    set_restore_selected_project,
    sort_by,
    set_sort_by,
    skip,
    set_skip,
    is_stop_load_more,
    set_is_stop_load_more,
  };
}

export default useRecovery;
