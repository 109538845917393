import React, { Component } from "react";
import { connect } from "react-redux";

import Modal from "../common_modal/Modal";

//Redux function
import { editField } from "../../App/actions/layerActions";

class PreviewReferrenceData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalPreview: false,
      newRow: {
        name: null,
        value: null,
      },
      modalWarning: false,
    };
  }

  handleSave = () => {
    const layer_id = this.props.layer_id;
    let field = this.props.field;
    const newRow = this.state.newRow;
    if (!Object.values(newRow).some((x) => !x)) {
      field.reference_list.push(newRow);
      const body = {
        geo_layer_id: layer_id,
        field,
      };
      this.props.editField(body);
    } else {
      this.toggleModalWarning();
    }
  };

  togglePreview = (value) => {
    this.setState({
      modalPreview: value || !this.state.modalPreview,
    });
  };

  toggleModalWarning = (value) => {
    this.setState({
      modalWarning: value || !this.state.modalWarning,
    });
  };

  toggleNewRow = (e) => {
    const newRow = this.state.newRow;
    const { name, value } = e.target;
    this.setState({
      newRow: {
        ...newRow,
        [name]: value,
      },
    });
  };

  render() {
    const { modalPreview, modalWarning } = this.state;
    const { data } = this.props;
    const modalPreviewContent = modalPreview && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modalPreview}
        onClose={this.togglePreview}
      >
        <div className="scroll_y_auto h_500">
          <table className="">
            <thead>
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((row, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{row.name}</td>
                  <td>{row.value}</td>
                </tr>
              ))}
              <tr>
                <td></td>
                <td>
                  <input
                    onChange={(e) => this.toggleNewRow(e)}
                    name="name"
                    type="text"
                  />
                </td>
                <td>
                  <input
                    onChange={(e) => this.toggleNewRow(e)}
                    name="value"
                    type="number"
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="3">
                  <button
                    onClick={() => this.handleSave()}
                    className="button w_full hover_darkBlue"
                  >
                    Add Row
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    );

    const modalWarningContent = modalWarning && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modalWarning}
        onClose={this.toggleModalWarning}
      >
        <div className="center_perfect">
          <p>Input fields can't empty</p>
        </div>
      </Modal>
    );

    return (
      <div>
        <div
          onClick={() => this.togglePreview(true)}
          className="bg_blue pointer rounded_5 font_14 h_35 w_100 text_white center_perfect hover_darkBlue"
        >
          Preview
        </div>
        {modalPreviewContent}
        {modalWarningContent}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
});

export default connect(mapStateToProps, { editField })(PreviewReferrenceData);
