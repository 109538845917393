const initial_state = {
  calc_update: 0,
  sini_hbu_update: 0,
  alt_selected: {},
  first_year: 0,
  headers: [],
  rows: [],
  rows_filtered_1: [],
  rows_filtered_2: [],
  rows_filtered_3: [],
  rows_rejected_1: [],
  rows_rejected_2: [],
  rows_rejected_3: [],
  percent_ratio_tolerance: 0,
  project_duration_years: 10, //Durasi Proyek
  discount_rate_1_percent: 15, //Tingkat Diskonto Satu
  revenue_growth_percent: 8, //Growth Pendapatan
  opex_growth_percent: 3, //Growth OPEX
  working_capital_idr: 0, //Modal Kerja
  discount_rate_2_percent: 30, //Tingkat Diskonto Dua
  economic_life_years: 30, //Umur Ekonomis
  tax_percent: 22, //Pajak Penghasilan Badan
  skema_terminal_value: true, //Skema Perhitungan Terminal Value
};

export default function hbu_reducer(state = initial_state, action) {
  switch (action.type) {
    case "calc_update":
      return {
        ...state,
        calc_update: state.calc_update + 1,
      };
    case "sini_hbu_update":
      return {
        ...state,
        sini_hbu_update: state.sini_hbu_update + 1,
      };
    case "set_value_hbu":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case "set_hbu_reducer_from_db":
      return {
        ...action.payload,
      };
    case "set_hbu_reducer_into_intitial":
      return {
        ...initial_state,
      };
    default:
      return state;
  }
}
