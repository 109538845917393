/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import Menu from "@mui/material/Menu";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_project } from "../../App/actions/projectActions";

/*PICTURE ASSET*/
import ICON_PLUS from "../../Assets/jsx/ICON_PLUS";
import ICON_BOX from "../../Assets/jsx/ICON_BOX";
import ICON_LIST from "../../Assets/jsx/ICON_LIST";
import ICON_ARROW_DOWN from "../../Assets/jsx/ICON_ARROW_DOWN";
import ICON_ARROW_UP from "../../Assets/jsx/ICON_ARROW_UP";
import ICON_EXPAND from "../../Assets/jsx/ICON_EXPAND";

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";
import sort_array from "../../App/validation/sort_array";

/*NON IMPORT*/

class BI_CONTROL_PANEL extends Component {
  state = {
    modal_menu: false,
  };

  on_change_localstorage = (name, value) => {
    this.setState({ modal_menu: false });
    localStorage.setItem(name, value);
    const is_sort_from_small = this.props.project.is_sort_from_small;
    this.props.set_value_project({ key: name, value: value });
    if (name === "mode_sort") {
      let {
        geo_project_list,
        geo_project_list_child,
        folder_project_list,
        folder_project_child_list,
        folder_object_selected_list,
      } = this.props.project;
      /*
      folder
      
      name - name
      date_created - date_created
      date_modified - date

      project

      name - name
      date_created - date_created
      date_modified - date_modified
      */
      let key_project = value;
      let key_folder = value;
      if (value === "date_modified") {
        key_project = "date";
      }
      if (folder_object_selected_list.length === 0) {
        geo_project_list = sort_array(
          geo_project_list,
          key_project,
          is_sort_from_small
        );
        folder_project_list = sort_array(
          folder_project_list,
          key_folder,
          is_sort_from_small
        );
        this.props.set_value_project({
          key: "geo_project_list",
          value: geo_project_list,
        });
        this.props.set_value_project({
          key: "folder_project_list",
          value: folder_project_list,
        });
      } else {
        geo_project_list_child = sort_array(
          geo_project_list_child,
          key_project,
          is_sort_from_small
        );
        folder_project_child_list = sort_array(
          folder_project_child_list,
          key_folder,
          is_sort_from_small
        );
        this.props.set_value_project({
          key: "geo_project_list_child",
          value: geo_project_list_child,
        });
        this.props.set_value_project({
          key: "folder_project_child_list",
          value: folder_project_child_list,
        });
      }
    }
  };

  toggle_localstorage = (name) => {
    const value = this.props.project[name];
    const new_value = !value;
    localStorage.setItem(name, new_value);
    this.props.set_value_project({ key: name, value: new_value });
    const mode_sort = !!localStorage.getItem("mode_sort")
      ? localStorage.getItem("mode_sort")
      : "date_created";
    this.on_change_localstorage("mode_sort", mode_sort);
  };

  toggle_menu = (e) => {
    this.setState({ modal_menu: !this.state.modal_menu });
    if (e) {
      this.setState({
        anchor: e.currentTarget,
      });
    }
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";
    const mode_view = !!localStorage.getItem("mode_view")
      ? localStorage.getItem("mode_view")
      : "box"; //box || list
    const mode_sort = !!localStorage.getItem("mode_sort")
      ? localStorage.getItem("mode_sort")
      : "date_modified"; //name || date_created || date_modified

    //local state
    const { modal_menu, anchor } = this.state;

    //global props
    const is_sort_from_small = this.props.project.is_sort_from_small;

    //content

    const mode_sort_list = [
      {
        key: "name",
        name: dict?.["Name"]?.[language],
      },
      {
        key: "date_created",
        name: dict?.["Date created"]?.[language],
      },
      {
        key: "date_modified",
        name: dict?.["Date modified"]?.[language],
      },
    ];

    const mode_sort_name = mode_sort_list.find(
      (item) => item.key === mode_sort
    )?.name;

    const modal_menu_content = modal_menu && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_menu}
        onClose={this.toggle_menu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        style={{ borderRadius: "15px" }}
      >
        <div style={{ padding: "7px 15px 0px 15px" }}>
          {mode_sort_list.map((item, idx) => {
            return (
              <div key={idx}>
                <button
                  onClick={this.on_change_localstorage.bind(
                    this,
                    "mode_sort",
                    item.key
                  )}
                  className={`button_small margin_bottom ${
                    mode_sort === item.key
                      ? "background_black"
                      : "background_grey"
                  }`}
                >
                  {item?.name}
                </button>
                <br />
              </div>
            );
          })}
        </div>
      </Menu>
    );

    return (
      <main>
        {modal_menu_content}
        <table className="full_width">
          <tbody>
            <tr>
              <td className="text_left" style={{ verticalAlign: "top" }}>
                <main className="margin_bottom">
                  <button
                    onClick={this.props.toggle_create_bi}
                    className="button_pill background_white margin_bottom margin_right"
                  >
                    <span className="badge_circle background_green">
                      <ICON_PLUS />
                    </span>{" "}
                    {dict?.["New BI Dasboard"]?.[language]}
                  </button>
                </main>
              </td>
              <td className="text_right">
                <button
                  className={`button margin_bottom margin_right ${
                    mode_view === "box"
                      ? "background_black"
                      : "background_white"
                  }`}
                  onClick={this.on_change_localstorage.bind(
                    this,
                    "mode_view",
                    "box"
                  )}
                >
                  <ICON_BOX
                    className={
                      mode_view === "box"
                        ? "background_black"
                        : "background_white"
                    }
                  />
                </button>
                <button
                  className={`button margin_bottom margin_right ${
                    mode_view === "list"
                      ? "background_black"
                      : "background_white"
                  }`}
                  onClick={this.on_change_localstorage.bind(
                    this,
                    "mode_view",
                    "list"
                  )}
                >
                  <ICON_LIST
                    className={
                      mode_view === "list"
                        ? "background_black"
                        : "background_white"
                    }
                  />
                </button>
                <br />
                <div className="button_pill background_white margin_bottom margin_right">
                  <table className="full_width" style={{ minWidth: "160px" }}>
                    <tbody>
                      <tr>
                        <td className="text_left">
                          <span
                            className="badge_circle background_grey margin_right"
                            onClick={this.toggle_localstorage.bind(
                              this,
                              "is_sort_from_small"
                            )}
                          >
                            {!is_sort_from_small ? (
                              <ICON_ARROW_DOWN />
                            ) : (
                              <ICON_ARROW_UP />
                            )}
                          </span>
                        </td>
                        <td className="text_right">
                          <div
                            onClick={this.toggle_menu}
                            className="inline_center"
                          >
                            {mode_sort_name} <ICON_EXPAND />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  project: state.project,
});

export default connect(mapStateToProps, { set_value_project })(
  BI_CONTROL_PANEL
);
