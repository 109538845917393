export function object_to_url_params(params) {
    let result = ""
    for (const [index, [key, value]] of Object.entries(Object.entries(params))) {
        if (+index === 0) {
            result += "?"
        } else {
            result += "&"
        }
        result += `${key}=${value}`
    }
    return result
};