/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { edit_inside_object } from "../../App/actions/biActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import basemap_styles from "../../App/validation/basemap_styles";

/*NON IMPORT*/

class BI_EDIT_BASEMAP extends Component {
  state = {};

  on_change_basemap = (value) => {
    this.props.edit_inside_object({
      key: "setting_add_object",
      id_key: "basemap_mode",
      value: value,
    });
  };

  render() {
    //local storage

    //local state

    //global props
    const { setting_add_object } = this.props.bi;

    //content
    const basemap_mode = setting_add_object?.basemap_mode;

    return (
      <main>
        <h1 className="text_bold margin_bottom">
          Atur <i>basemap</i>
        </h1>
        {basemap_styles().map((item, idx) => {
          return (
            <button
              key={idx}
              className={`button outline_black margin_bottom margin_right ${
                basemap_mode === item.value
                  ? "background_black"
                  : "background_white"
              }`}
              onClick={this.on_change_basemap.bind(this, item.value)}
            >
              {item.name}
            </button>
          );
        })}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
  project: state.project,
});

export default connect(mapStateToProps, { edit_inside_object })(
  BI_EDIT_BASEMAP
);
