export function extract_value_array(paint_object_edited, style_type) {
  let color_key;
  if (style_type === "circle") {
    color_key = "circle-color";
  } else if (style_type === "line") {
    color_key = "line-color";
  } else if (style_type === "fill") {
    color_key = "fill-color";
  }
  const array = paint_object_edited?.[color_key] || [];
  const value_array = [];
  for (let i = 2; i < array.length - 1; i += 2) {
    value_array.push(array[i]);
  }
  return value_array;
}
