import { data_types } from "../../App/validation/data_types";

const standard_data_types = data_types(localStorage?.language || "ina")
  .filter(field => field.type === "standard" && !["section", "table"].includes(field.value));


/**
 * Keterangan:
 *
 * buat generate angka 0 atau string kosong di tabel
 */

const generate_feature_base_value = (fields) => {
  let feature = {}

  // nambahin data default (0 utk number dan "" utk string) pada properties
  const filtered_fields = fields?.filter(field =>
    standard_data_types.map(item => item.value)?.includes(field?.type)
  );

  filtered_fields.forEach(field => feature[field.key] =
    ["number", "range", "currency"].includes(field?.type) ? 0 : ""
  )
  return feature
}


export { generate_feature_base_value };