//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component
import PushFieldForm from "./PushFieldForm";
import Modal from "../common_modal/Modal";

//Redux function

//Picture Asset
import icon_plus from "../../Assets/svg/icon_plus.svg";
import icon_edit_text from "../../Assets/svg/icon_edit_text.svg";
import icon_delete_2 from "../../Assets/svg/icon_delete_2.svg";

//General Function
import dict from "../../Data/dict.json";
import PhoneNumber from "../../Data/PhoneNumber.json";

const phone_number_list = PhoneNumber.sort();

class InputDummy extends Component {
  state = {
    modal_push_field: false,
    modal_table: false,
    field_table: {},
    field_table_active: {},
  };

  toggle_table = (field_table) => {
    this.setState({ modal_table: !this.state.modal_table });
    if (field_table) {
      this.setState({ field_table });
    }
  };

  activate_table = (field_table) => {
    this.setState({ modal_table: true });
    if (field_table) {
      this.setState({ field_table });
    }
  };

  toggle_push_field = (field_table_active) => {
    this.setState({ modal_push_field: !this.state.modal_push_field });
    if (field_table_active) {
      this.setState({ field_table_active });
    }
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const field_table_active = this.state.field_table_active;

    //global props
    const field_state = this.props.field_state;
    // const fields = this.props.fields;
    let geo_layer = this.props.layer.geo_layer_wo_geojson;
    geo_layer = geo_layer?.geo_layer;
    const fields_original = geo_layer?.fields || [];

    //content

    //child
    const child = (field) => {
      let result;
      const array_templates = field.array_templates;
      const type = field.type;
      switch (field?.type) {
        case "text":
          result = <input name="fields" type={type} />;
          break;

        case "table":
          result = (
            <button
              className="button_small background_blue"
              onClick={this.activate_table.bind(this, field)}
            >
              Open table
            </button>
          );
          break;

        case "textarea":
          result = <textarea rows="3" name="fields" type="text" />;
          break;

        case "number":
          result = (
            <main>
              <input name="fields" type={type} />
              {
                <div className="badge_normal">
                  {dict["Decimal"][language]}:{" "}
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(100000)}
                </div>
              }
            </main>
          );
          break;

        case "phone_number":
          result = (
            <main>
              <section className="phone_number">
                <select name="phone_code" defaultValue={62}>
                  {phone_number_list.map((phone, idx) => {
                    return (
                      <option value={phone} key={idx}>
                        {phone}
                      </option>
                    );
                  })}
                </select>
                <input
                  name="phone_number"
                  type="number"
                  defaultValue={85700000}
                />
              </section>
              <section className="badge_normal">6285700000 (Sample)</section>
            </main>
          );
          break;

        case "currency":
          result = (
            <main>
              <input name="fields" type="number" />
              <section className="badge_normal">
                {dict["Decimal"][language]}: Rp{" "}
                {new Intl.NumberFormat("id-ID", {
                  style: "decimal",
                }).format(100000)}
              </section>
            </main>
          );
          break;

        case "year":
          result = <input name="fields" type="number" />;
          break;

        case "date":
          result = <input name="fields" type={type} />;
          break;

        case "time":
          result = <input name="fields" type={type} />;
          break;
        case "section":
          result = <input name="fields" type="text" />;
          break;

        case "selection":
          result = (
            <select name="fields" type={type}>
              <option defaultValue="">
                {dict["Please choose from options"][language]}
              </option>
              {array_templates.map(({ name }, idx) => {
                return (
                  <option key={idx} value={name}>
                    {name}
                  </option>
                );
              })}
            </select>
          );
          break;

        case "radio_button":
          result = (
            <main>
              {array_templates.map(({ name }, idx_c) => {
                return (
                  <main key={idx_c} style={{ marginBottom: "5px" }}>
                    <section className="radio_button" id="not_active"></section>
                    <section
                      style={{
                        display: "inline-block",
                        verticalAlign: "top",
                      }}
                    >
                      {name}
                    </section>
                  </main>
                );
              })}
            </main>
          );
          break;

        case "checklist":
          const value_array = field?.value_array ? field?.value_array : [];
          result = (
            <main>
              {array_templates.map(({ name }, idx_c) => {
                return (
                  <main key={idx_c} style={{ marginBottom: "5px" }}>
                    {value_array.includes(name) ? (
                      <section className="multi_check" id="active_strong">
                        ✓
                      </section>
                    ) : (
                      <section className="multi_check" id="not_active" />
                    )}
                    <section htmlFor={name} style={{ display: "inline-block" }}>
                      {name}
                    </section>
                  </main>
                );
              })}
            </main>
          );
          break;

        case "image":
          result = (
            <main>
              <label
                className="fileContainer upload_container vertical_center"
                style={{
                  backgroundImage: `url(${icon_plus})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></label>
            </main>
          );
          break;

        case "document":
          result = (
            <main>
              <label
                className="fileContainer upload_container vertical_center"
                style={{
                  backgroundImage: `url(${icon_plus})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></label>
            </main>
          );
          break;

        case "link":
          result = (
            <main>
              <section className="phone_number">
                <div>https://</div>
                <input name="fields" type={type} />
              </section>
              <a
                href={`https://mapid.io`}
                target="_blank"
                rel="noopener noreferrer"
                className="badge_normal"
              >
                Test link https://mapid.io
              </a>
            </main>
          );
          break;

        case "range":
          result = (
            <main>
              <input name="fields" type={type} />
              <section className="three_container">
                <section style={{ textAlign: "left" }}>
                  <div
                    className="badge_normal"
                    style={{ fontSize: "16px", color: "#52606eff" }}
                  >
                    {min}
                  </div>
                </section>
                <section style={{ textAlign: "center" }}>
                  <div
                    className="badge_normal"
                    style={{ fontSize: "9px", color: "#52606eff" }}
                  >
                    {"Belum diisi, geser slider untuk mengisi"}
                  </div>
                </section>
                <section style={{ textAlign: "right" }}>
                  <div
                    className="badge_normal"
                    style={{ fontSize: "16px", color: "#52606eff" }}
                  >
                    {max}
                  </div>
                </section>
              </section>
            </main>
          );
          break;

        default:
          result = <input name="fields" type={type} />;
          break;
      }
      return result;
    };

    //table
    const table = (fields_nested, field_element) => {
      return (
        <table className="table_lite">
          <tbody>
            <tr>
              <td colSpan={4}>{dict["Sub questions"][language]}</td>
              <td>
                {field_element.name} 1 ({dict["Example"][language]})
              </td>
              <td>
                {field_element.name} 2 ({dict["Example"][language]})
              </td>
            </tr>
            {fields_nested.map((f, idx) => {
              const { formula } = f;
              const rumus = formula.map((f, idx) => {
                if (f.type === "operator") {
                  return (
                    <p className="inline" style={{ color: "red" }} key={idx}>
                      {f.content}
                    </p>
                  );
                } else
                  return (
                    <p className="inline" style={{ color: "blue" }} key={idx}>
                      {f.content}
                    </p>
                  );
              });
              return (
                <tr
                  key={idx}
                  id={f.uuid === field_state.uuid ? "almost_white" : ""}
                >
                  <td>{idx + 1}</td>
                  <td>
                    <div>{f.name}</div>
                    {formula?.length > 0 && (
                      <div
                        style={{
                          border: "1px solid",
                          padding: "5px",
                          borderRadius: "5px",
                          backgroundColor: "white",
                        }}
                      >
                        {rumus}
                      </div>
                    )}
                  </td>
                  <td>
                    <img
                      alt="edit"
                      src={icon_edit_text}
                      width="13"
                      className="pointer"
                      onClick={this.props.toggle_sidebar.bind(this, { ...f })}
                    />
                  </td>
                  <td>
                    <img
                      alt="delete"
                      src={icon_delete_2}
                      width="13"
                      className="pointer"
                      onClick={this.props.toggle_delete_field.bind(this, {
                        ...f,
                      })}
                    />
                  </td>
                  <td colSpan={2}>{child(f)}</td>
                </tr>
              );
            })}
            {!fields_nested?.length && (
              <>
                <tr>
                  <td>1</td>
                  <td colSpan={3}>{dict["Question 1 (Example)"][language]}</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>2</td>
                  <td colSpan={3}>{dict["Question 2 (Example)"][language]}</td>
                  <td></td>
                  <td></td>
                </tr>
              </>
            )}
            <tr>
              <td colSpan={6}>
                <button
                  className="button margin_auto"
                  onClick={this.toggle_push_field.bind(this, field_element)}
                >
                  {dict["Add sub questions"][language]}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      );
    };

    let content;

    const field_element = this.props.field_element; //perlu direview

    const type = field_element.type;
    const array_templates = field_element.array_templates;
    const min = field_element.min;
    const max = field_element.max;

    //parent
    if (type === "text") {
      //text
      content = <input name="fields" type={type} />;
    } else if (type === "table") {
      //table

      const fields_nested = fields_original.filter(
        (f) => f.parent_uuid === field_element.uuid
      );

      content = table(fields_nested, field_element);
    } else if (type === "textarea") {
      //textarea
      content = <textarea rows="3" name="fields" type="text" />;
    } else if (type === "number") {
      //number
      content = (
        <main>
          <input name="fields" type={type} />
          {
            <div className="badge_normal">
              {dict["Decimal"][language]}:{" "}
              {new Intl.NumberFormat("id-ID", {
                style: "decimal",
              }).format(100000)}
            </div>
          }
        </main>
      );
    } else if (type === "phone_number") {
      //phone_number
      content = (
        <main>
          <section className="phone_number">
            <select name="phone_code" defaultValue={62}>
              {phone_number_list.map((phone, idx) => {
                return (
                  <option value={phone} key={idx}>
                    {phone}
                  </option>
                );
              })}
            </select>
            <input name="phone_number" type="number" defaultValue={85700000} />
          </section>
          <section className="badge_normal">6285700000 (Sample)</section>
        </main>
      );
    } else if (type === "currency") {
      //currency
      content = (
        <main>
          <input name="fields" type="number" />
          <section className="badge_normal">
            {dict["Decimal"][language]}: Rp{" "}
            {new Intl.NumberFormat("id-ID", {
              style: "decimal",
            }).format(100000)}
          </section>
        </main>
      );
    } else if (type === "year") {
      //year
      content = <input name="fields" type="number" />;
    } else if (type === "date" || type === "time") {
      //date, time
      content = <input name="fields" type={type} />;
    } else if (type === "section") {
      content = <></>;
    } else if (type === "selection") {
      //selection
      content = (
        <select defaultValue="" name="fields" type={type}>
          <option value="">
            {dict["Please choose from options"][language]}
          </option>
          {array_templates.map(({ name }, idx) => {
            return (
              <option key={idx} value={name}>
                {name}
              </option>
            );
          })}
        </select>
      );
    } else if (type === "radio_button") {
      content = (
        <main>
          {array_templates.map(({ name }, idx_c) => {
            return (
              <main key={idx_c} style={{ marginBottom: "5px" }}>
                <section className="radio_button" id="not_active"></section>
                <section
                  className="scroll_hidden max_w_200 truncate"
                  title={name}
                  style={{
                    display: "inline-block",
                    verticalAlign: "top",
                    width: "inherit",
                  }}
                >
                  {name}
                </section>
              </main>
            );
          })}
        </main>
      );
    } else if (type === "checklist") {
      const value_array = field_element?.value_array
        ? field_element?.value_array
        : [];
      content = (
        <main>
          {array_templates.map(({ name }, idx_c) => {
            return (
              <main key={idx_c} style={{ marginBottom: "5px" }}>
                {value_array.includes(name) ? (
                  <section className="multi_check" id="active_strong">
                    ✓
                  </section>
                ) : (
                  <section className="multi_check" id="not_active" />
                )}
                <section htmlFor={name} style={{ display: "inline-block" }}>
                  {name}
                </section>
              </main>
            );
          })}
        </main>
      );
    } else if (type === "image" || type === "document") {
      //image
      content = (
        <main>
          <label
            className="fileContainer upload_container vertical_center"
            style={{
              backgroundImage: `url(${icon_plus})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          ></label>
        </main>
      );
    } else if (type === "link") {
      //link
      content = (
        <main>
          <section className="phone_number">
            <div>https://</div>
            <input name="fields" type={type} />
          </section>
          <a
            href={`https://mapid.io`}
            target="_blank"
            rel="noopener noreferrer"
            className="badge_normal"
          >
            Test link https://mapid.io
          </a>
        </main>
      );
    } else if (type === "range") {
      //range
      content = (
        <main>
          <input name="fields" type={type} />
          <section className="three_container">
            <section style={{ textAlign: "left" }}>
              <div
                className="badge_normal"
                style={{ fontSize: "16px", color: "#52606eff" }}
              >
                {min}
              </div>
            </section>
            <section style={{ textAlign: "center" }}>
              <div
                className="badge_normal"
                style={{ fontSize: "9px", color: "#52606eff" }}
              >
                {"Belum diisi, geser slider untuk mengisi"}
              </div>
            </section>
            <section style={{ textAlign: "right" }}>
              <div
                className="badge_normal"
                style={{ fontSize: "16px", color: "#52606eff" }}
              >
                {max}
              </div>
            </section>
          </section>
        </main>
      );
    } else {
      //other
      content = <input name="fields" type={type} />;
    }

    const { modal_push_field, modal_table } = this.state;

    const modal_push_field_content = modal_push_field && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_push_field}
        onClose={this.toggle_push_field}
      >
        <main className="box-body">
          <PushFieldForm parent_uuid={field_table_active?.uuid} />
        </main>
      </Modal>
    );

    const { field_table } = this.state;
    const fields_nested = fields_original.filter(
      (f) => f.parent_uuid === field_table.uuid
    );

    const modal_table_content = modal_table && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_table}
        onClose={this.toggle_table}
      >
        <main className="box-body">
          <main style={{ height: "100vh" }}>
            <p className="text_bold">{field_table.name}</p>
            {table(fields_nested, field_table)}
          </main>
        </main>
      </Modal>
    );

    return (
      <>
        {modal_table_content}
        {modal_push_field_content}
        {content}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
});

export default connect(mapStateToProps, {})(InputDummy);
