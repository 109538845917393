import React, { Component } from "react";
import { connect } from "react-redux";

class LAYER_POLYGONS_GENANGAN extends Component {
  state = {};

  componentDidUpdate(prevProps) {
    const genangan_update_before = prevProps.genangan_reducer.genangan_update;
    const genangan_update_after = this.props.genangan_reducer.genangan_update;

    if (genangan_update_before !== genangan_update_after) {
      this.on_render();
    }
  }
  on_render() {
    const { map_object } = this.props.layer;
    if (map_object !== null) {
      this.addMapContent(map_object);
    }
  }

  addMapContent = (map_object) => {
    const { features_polygon_convex } = this.props.genangan_reducer;
    const id_map_polygon = "features_polygon_convex";
    const id_map_line = "features_line_convex";
    const geojson_polygons_convex = {
      type: "FeatureCollection",
      features: features_polygon_convex,
    };

    if (!map_object.getSource(id_map_polygon)) {
      map_object.addSource(id_map_polygon, {
        type: "geojson",
        data: geojson_polygons_convex,
      });
    } else {
      map_object.getSource(id_map_polygon).setData(geojson_polygons_convex);
    }

    if (!map_object.getLayer(id_map_polygon)) {
      map_object.addLayer(
        {
          id: id_map_polygon,
          source: id_map_polygon,
          type: "fill",
          paint: {
            "fill-color": "blue",
            "fill-opacity": 0.5,
            "fill-outline-color": "#000",
          },
          layout: { visibility: "visible" },
        },
        "building"
      );
    }

    if (!map_object.getSource(id_map_line)) {
      map_object.addSource(id_map_line, {
        type: "geojson",
        data: geojson_polygons_convex,
      });
    } else {
      map_object.getSource(id_map_line).setData(geojson_polygons_convex);
    }

    if (!map_object.getLayer(id_map_line)) {
      map_object.addLayer(
        {
          id: id_map_line,
          source: id_map_line,
          type: "line",
          paint: {
            "line-color": "black",
            "line-width": 3,
          },
          layout: { visibility: "visible" },
        },
        "building"
      );
    }
  };

  render() {
    return <main />;
  }
}

const mapStateToProps = (state) => ({
  genangan_reducer: state.genangan_reducer,
  loading_reducer: state.loading_reducer,
  layer: state.layer,
});

export default connect(mapStateToProps, {})(LAYER_POLYGONS_GENANGAN);
