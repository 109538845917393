/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import ChartTemplate from "../viewer_chart/ChartTemplate";

/*REDUX FUNCTION*/
import { set_value_properties } from "../../App/actions/propertiesActions";

/*PICTURE ASSET*/

/*GENERAL*/

class TimeseriesMonth extends Component {
  state = {
    show_element_mode: "expand", //expand || selection
  };

  componentDidMount() {
    //set value show_element_mode dari props ke state
    const { layer_id, geo_layer_list } = this.props.layer;
    const geo_layer =
      geo_layer_list.find((e) => e?.geo_layer?._id === layer_id)?.geo_layer ||
      {};
    const show_element_mode = geo_layer?.timeseries_object?.show_element_mode;
    if (show_element_mode) {
      this.setState({ show_element_mode });
    }
  }

  on_change = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  };

  on_set_current_item = (e) => {
    const value = e.target.value;
    const { layer_id } = this.props.layer;
    const key = `${layer_id}_selected_item`;
    this.props.set_value_properties({
      key: key,
      value: value,
    });
  };

  render() {
    //local storage

    //local state
    const { show_element_mode } = this.state;

    //global props
    const { layer_id, geo_layer_list } = this.props.layer;
    const selected_item =
      this.props.properties?.[`${layer_id}_selected_item`] || "";

    //content
    const geo_layer =
      geo_layer_list.find((e) => e?.geo_layer?._id === layer_id)?.geo_layer ||
      {};

    //pengambilan atribut di dalam geo_layer
    const relasi_list = geo_layer?.relasi_list || [];

    let content;

    if (show_element_mode === "selection") {
      const relasi = relasi_list.find((item) => item?.name === selected_item);

      if (selected_item && relasi?.name) {
        content = (
          <section>
            <div className="container_light margin_bottom">
              <h2 className="badge_pill" id="orange">
                {relasi.name}
              </h2>
              <br />
              <div style={{ overflowX: "scroll" }}>
                <ChartTemplate
                  type="bar"
                  width={600}
                  height={200}
                  style={{ width: "600px" }}
                  options={{
                    hoverBackgroundColor: "#f38026",
                    animation: false,
                    maintainAspectRatio: false,
                    responsive: false,
                    legend: {
                      display: false,
                    },
                    indexAxis: "x",
                    title: {
                      display: false,
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                  data={relasi.chart_js_data}
                />
              </div>
              <table className="table">
                <tbody>
                  <tr>
                    <td>y_max</td>
                    <td>
                      {new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(relasi?.chart_js_data?.y_max)}
                    </td>
                  </tr>
                  <tr>
                    <td>x_max</td>
                    <td>{relasi?.chart_js_data?.x_max}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        );
      } else {
        content = (
          <section>
            <p className="text_small">Pilih kereta untuk menampilkan grafik</p>
          </section>
        );
      }
    } else {
      content = (
        <>
          {relasi_list.map((relasi, idx) => {
            return (
              <div key={idx} className="container_light margin_bottom">
                <h2 className="badge_pill" id="orange">
                  {relasi.name}
                </h2>
                <br />
                <div style={{ overflowX: "scroll" }}>
                  <ChartTemplate
                    type="bar"
                    width={600}
                    height={200}
                    style={{ width: "600px" }}
                    options={{
                      hoverBackgroundColor: "#f38026",
                      animation: false,
                      maintainAspectRatio: false,
                      responsive: false,
                      legend: {
                        display: false,
                      },
                      indexAxis: "x",
                      title: {
                        display: false,
                      },
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                    data={relasi.chart_js_data}
                  />
                </div>
                <table className="table">
                  <tbody>
                    <tr>
                      <td>y_max</td>
                      <td>
                        {new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(relasi?.chart_js_data?.y_max)}
                      </td>
                    </tr>
                    <tr>
                      <td>x_max</td>
                      <td>{relasi?.chart_js_data?.x_max}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            );
          })}
        </>
      );
    }

    return (
      <main>
        <p className="text_small">Pilih yang ditampilkan</p>
        <select
          onChange={this.on_change}
          name="show_element_mode"
          value={show_element_mode}
        >
          <option value="expand">Semua</option>
          <option value="selection">Hanya yang terpilih</option>
        </select>
        {show_element_mode === "selection" && (
          <section>
            <p className="text_small">Pilih</p>
            <select
              onChange={this.on_set_current_item}
              value={selected_item}
              name="selected_name"
            >
              <option value="">Pilih kereta</option>
              {relasi_list.map((relasi, idx) => {
                return (
                  <option value={relasi.name} key={idx}>
                    {relasi.name}
                  </option>
                );
              })}
            </select>
          </section>
        )}

        {content}

        {/* <section className="container_light margin_bottom">
          <h2 className="badge_pill" id="orange">
            Semua relasi
          </h2>
          <br />
          <p className="text_bold">Coming soon</p>
        </section> */}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, { set_value_properties })(
  TimeseriesMonth
);
