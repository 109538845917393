/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { clone_project } from "../../App/actions/projectActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class CLONE_PROJECT extends Component {
  state = { new_project_name: "", new_project_desc: "" };

  componentDidMount() {
    //new_project_name
    const { geo_project } = this.props.project;
    this.setState({
      new_project_name: geo_project.name + " COPY",
      new_project_desc: geo_project.description + " COPY",
    });
  }

  on_change = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  };

  on_clone_project = () => {
    const { geo_project } = this.props.project;
    const { new_project_name, new_project_desc } = this.state;
    const body = {
      geo_project_id: geo_project._id,
      new_project_name,
      new_project_desc,
    };
    this.props.clone_project(body);
    this.props.toggle_clone_project();
    this.props.toggle_edit_project();
  };

  render() {
    //local storage

    //local state
    const { new_project_name, new_project_desc } = this.state;

    //global props

    //content

    return (
      <main>
        <h1 className="text_header">Duplikat proyek ini</h1>
        <p className="text_small margin_bottom">
          Detail proyek akan diduplikat, folder-folder akan diduplikat namun
          data layer tidak ikut diduplikat
        </p>
        <br />
        <p className="text_small">Project name</p>
        <input
          name="new_project_name"
          value={new_project_name}
          onChange={this.on_change}
          data-mapid="inputProjectName"
        />

        <p className="text_small">Project description</p>
        <input
          name="new_project_desc"
          value={new_project_desc}
          onChange={this.on_change}
          data-mapid="inputProjectDeskription"
        />
        <br />
        <button
          className="button background_blue"
          onClick={this.on_clone_project}
          data-mapid="clickSekarang"
        >
          Duplikat sekarang
        </button>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
});

export default connect(mapStateToProps, { clone_project })(CLONE_PROJECT);
