import React, { createRef } from 'react'
import MARKDOWN_RENDERER from '../sini_v2_full/MARKDOWN_RENDERER';
import st_colors from "../../Data/st_colors.json";
import BarDiagram from './BarDiagram';
import is_not_empty from '../../App/validation/is_not_empty';
import sort_array from '../../App/validation/sort_array';

export default class PRINT_HBU_MANUAL extends React.PureComponent {
  constructor(props) {
    super(props);
    this.imgRef = createRef(null)
  }

  componentDidMount = () => {}

  scoring = (totalRank) => {
    // Buat copy array dan urutkan secara descending
    const sorted = [...totalRank].sort((a, b) => {
        return b?.total_rank - a?.total_rank
    });
    
    // return sorted;
    // Mapping setiap elemen ke ranking berdasarkan posisi di array terurut
    return totalRank.map(item => {
        return {
            ...item,
            score: sorted.indexOf(item) + 1
        }
    });
  }

  render() {
      const {
            luas_tanah,
            luas_bangunan,
            data_bangun,
            kesimpulan_tanah_kosong,
            kesimpulan_bangunan,
            kesimpulan_hbu_fisik_tanah_kosong,
            kesimpulan_hbu_fisik_bangunan,
            // ai_parent_detail_list,
            // sheet_object_selected,
            rows_filtered_3,
            properties = {},
            summary_sini,
        } = this.props;
    
    const sorted_data = this.scoring(rows_filtered_3);
    
    const chart_data = sorted_data.slice(0, 3).map((item) => {
        return {
            label: item.JENIS,
            value: item.score
        }
    });
    
    // Map colors using the modulo operation
    const backgroundColors = sorted_data.map(
        (_, index) => st_colors[index % st_colors.length]
    );

    // const sini_object = ai_parent_detail_list?.find(
    // (item) => item?.request_id === sheet_object_selected?.request_id
    // );

    // let rentang_usia_dominan = properties && Object.entries(properties);
    // rentang_usia_dominan = rentang_usia_dominan
    // .filter((item) => item[0].startsWith("USIA"))
    // .sort(([, a], [, b]) => b - a)?.[0]?.[0];


    // let poi_resume_1_dominan =
    // sini_object && Object.entries(sini_object?.poi_resume_1 || {});
    // poi_resume_1_dominan = poi_resume_1_dominan
    // ?.sort(([, a], [, b]) => b?.number - a?.number)
    // ?.slice(0, 3);

    const { DESA, KECAMATAN, KABKOT, PROVINSI } = properties;
    
    const {
        peruntukan,
        gsb,
        kdb,
        klb,
        ktb_tanah_kosong,
        ktb_bangunan
    } = data_bangun || {};

    const is_kdb_and_klb_fill = is_not_empty(data_bangun?.klb) || is_not_empty(data_bangun?.kdb)

    return (
      <div className='hide_component'>
        <section className='padding_20 flex flex_col gap_10'>
          <h1 className="margin_bottom">HBU SUMMARY</h1>
            <aside className="grid grid_2 gap_10">
                <div className="child_tile container_light background_grey_light outline_transparent">
                    <h1 className="margin_bottom">
                    <label>Nama object: </label>
                    </h1>
                    <div className="margin_bottom">
                    <label className="text_small">Provinsi</label>
                    <p className="text_bold">{PROVINSI}</p>
                    </div>
                    <div className="margin_bottom">
                    <label className="text_small">Kabupaten atau Kota</label>
                    <p className="text_bold">{KABKOT}</p>
                    </div>
                    <div className="margin_bottom">
                    <label className="text_small">Kecamatan</label>
                    <p className="text_bold">{KECAMATAN}</p>
                    </div>
                    <div className="margin_bottom">
                    <label className="text_small">Desa atau Kelurahan</label>
                    <p className="text_bold">{DESA}</p>
                    </div>
                    <div className="margin_bottom">
                    <label className="text_small">Luas Tanah m2</label>
                    <p className="text_bold">{luas_tanah}</p>
                    </div>
                    <div className="margin_bottom">
                    <label className="text_small">Luas Bangunan m2</label>
                    <p className="text_bold">{luas_bangunan}</p>
                    </div>
                </div>

                <div className="child_tile card_uji_legal_hbu container_light background_grey_light outline_transparent">
                        <h1 className="text_bold margin_bottom">
                            Rangkuman kalkulasi HBU
                        </h1>
                        <div className='h_200 center_perfect'>
                            <BarDiagram data={chart_data} width={600} height={120} colors={backgroundColors} />
                        </div>
                    <section className="container_light background_white">
                    <div className="center_perfect h_full">
                        <div>
                        <span className="center_perfect">
                            <label className="margin_bottom text_center">
                            Alternatif HBU terbaik
                            </label>
                        </span>
                        <span className="center_perfect">
                            <h1 className="rounded_5 padding_5 background_black text_center">
                            {rows_filtered_3?.[0] ? rows_filtered_3?.[0]?.JENIS : "-"}
                            
                            </h1>
                        </span>
                        </div>
                    </div>
                    </section>
                </div>

                <section className="span_2 child_tile card_uji_legal_hbu container_light background_grey_light outline_transparent">
                    <div className="text_bold margin_bottom">Uji Legal</div>
                    <div className="container background_white margin_bottom">
                    <table>
                        <tbody>
                        {peruntukan && <tr><td className="text_medium_normal">Nama Objek</td><td>:</td><td className="text_bold"> {peruntukan}</td></tr>}
                        <tr><td className="text_medium_normal">KDB</td><td>:</td><td className="text_bold"> {kdb ? `${kdb}%` : "-"}</td></tr>
                        <tr><td className="text_medium_normal">GSB</td><td>:</td><td className="text_bold"> {gsb || '-'}</td></tr>
                        <tr><td className="text_medium_normal">KLB</td><td>:</td><td className="text_bold"> {klb || '-'}</td></tr>
                        <tr><td className="text_medium_normal">Luas Terbangun</td><td>:</td><td className="text_bold"> {luas_bangunan || "-"} m<sup>2</sup></td></tr>
                        </tbody>
                    </table>
                    </div>
                    <div className="two_container_equal">
                    <article className="container background_white" style={{ position: "relative", paddingBottom: "35px" }}>
                        <div className="two_container_small_right">
                        <h1 className="text_bold margin_bottom">Perhitungan Sesuai Tata Ruang (Tanah Kosong)</h1>
                        </div>
                        <p className="text_medium">
                        {kesimpulan_tanah_kosong}
                        </p>

                        {!is_kdb_and_klb_fill &&
                        <p className="">
                            <br />
                            KDB dan KLB harus diisi pada sheet 'Uji HBU - Legal' agar kesimpulan sesuai.
                        </p>
                        }


                        {is_not_empty(ktb_tanah_kosong) &&
                        <div style={{
                            position: "absolute",
                            right: 10,
                            bottom: 10
                        }}>KTB : {ktb_tanah_kosong}</div>}
                    </article>
                    <article className="container background_white" style={{ position: "relative", paddingBottom: "35px" }}>
                        <div className="two_container_small_right">
                        <h1 className="text_bold margin_bottom">Perhitungan Sesuai Tata Ruang (Bangunan)</h1>
                        </div>
                        <p className="text_medium">
                        {kesimpulan_bangunan}
                        </p>


                        {!is_kdb_and_klb_fill &&
                        <p>
                            <br />
                            KDB dan KLB harus diisi pada sheet 'Uji HBU - Legal' agar kesimpulan sesuai.
                        </p>
                        }

                        {is_not_empty(ktb_bangunan) &&
                        <div style={{
                            position: "absolute",
                            right: 10,
                            bottom: 10
                        }}>KTB : {ktb_bangunan}</div>}

                    </article>
                    </div>
                </section >

                <div className="span_2 child_tile container_light background_grey_light outline_transparent">
                    <h1 className="text_bold margin_bottom">
                    Uji Maximum Productivity
                    </h1>
                    <p>Kesimpulan uji maximum productivity menyatakan bahwa aset yang terletak di lokasi ini memenuhi syarat indikator kelayakan.</p>
                </div>
            </aside>
            <div
                style={{
                    display: "grid",
                    gap: "10px",
                    gridTemplateColumns: "1fr",
                }}
            >
                <div className="break_before_pdf child_tile card_uji_legal_hbu container_light background_grey_light outline_transparent">
                    <div className="two_container_equal margin_bottom">
                        <article className="container background_white" style={{ position: "relative", paddingBottom: "35px" }}>
                            <div className="child_tile outline_transparent">
                                <h1 className="text_bold margin_bottom">
                                Uji Fisik HBU (Tanah Kosong)
                                </h1>
                                <p>{kesimpulan_hbu_fisik_tanah_kosong}</p>
                            </div>
                        </article>
                        <article className="container background_white" style={{ position: "relative", paddingBottom: "35px" }}>
                            <div className="child_tile outline_transparent">
                                <h1 className="text_bold margin_bottom">
                                Uji Fisik HBU Bangunan (Existing)
                                </h1>

                                <p>{kesimpulan_hbu_fisik_bangunan}</p>
                            </div>
                        </article>
                    </div>
                    <h1 className="text_bold margin_bottom">
                        Rangkuman finansial setiap alternatif
                    </h1>
                    <table className="table scroll_auto">
                        <thead>
                            <tr className="font_10">
                            <th></th>
                            <th>CAPEX Tanah</th>
                            <th>CAPEX Bangunan</th>
                            <th>CAPEX Total</th>
                            <th>NPV</th>
                            <th>IRR</th>
                            <th>PI</th>
                            <th>PP</th>
                            <th>ARR</th>
                            <th>DR</th>
                            <th>Ranking</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sort_array(rows_filtered_3,"total_rank", true)?.map((item, idx) => {

                            const { JENIS, capex_tanah, capex_bangunan, capex_total, npv, irr, arr, payback_periode, profitability_index } =
                                item;

                            const to_id_format = (num_val) => {
                                return new Intl.NumberFormat("id-ID", {
                                style: "decimal",
                                })?.format(Number(num_val));
                            };

                            return (
                                <tr key={idx} className="font_10">
                                <td>{JENIS}</td>
                                <td>Rp{to_id_format(capex_tanah)}</td>
                                <td>Rp{to_id_format(capex_bangunan)}</td>
                                <td>Rp{to_id_format(capex_total)}</td>
                                <td>Rp{to_id_format(npv)}</td>
                                <td>{(irr * 100)?.toFixed(1)}%</td>
                                <td>
                                    {`${profitability_index?.toFixed(3)} tahun`}
                                </td>
                                <td>
                                    {to_id_format(payback_periode?.toFixed(3))}
                                </td>
                                <td>
                                    {to_id_format(arr)}
                                </td>
                                <td>
                                    15%
                                </td>
                                <td>
                                    {to_id_format(idx + 1)}
                                </td>
                                </tr>
                            );
                            })}
                        </tbody>
                    </table>

                    <div>
                    <br />
                    <h1 className="text_bold margin_bottom">Indikator Kelayakan</h1>
                    <ul className="margin_left">
                        <li>
                        <b style={{ fontWeight: 800 }}>NPV</b>
                        {
                            " > 0 maka layak, semakin besar maka baik karena lebih profitable"
                        }
                        </li>
                        <li>
                        <b style={{ fontWeight: 800 }}>IRR</b>
                        {
                            " > Investasi, semakin besar maka baik karena lebih profitable"
                        }
                        </li>
                        <li>
                        <b style={{ fontWeight: 800 }}>PP</b>
                        {" Semakin kecil maka baik / cepat balik modal"}
                        </li>
                        <li>
                        <b style={{ fontWeight: 800 }}>PI</b>
                        {" > 1 maka layak"}
                        </li>
                        <li>
                        <b style={{ fontWeight: 800 }}>Akumulasi Rank</b>
                        {" semakin rendah, maka prioritas semakin tinggi."}
                        </li>
                    </ul>
                    </div>

                    
                    <div className="h_full">
                    <h1 className="text_bold margin_bottom">
                        Alasan Pemilihan Alternatif
                    </h1>
                    <p className="text_medium">
                        {`Pemilihan ${rows_filtered_3?.[0] ? rows_filtered_3?.[0]?.JENIS : "-"} sebagai alternatif terbaik didasarkan pada analisis mendalam terhadap kebutuhan lokal dan tren pasar yang stabil, memberikan return investasi yang diharapkan serta memenuhi fungsi utilitas nyata bagi masyarakat.`}
                    </p>
                    </div>
                    <div className="h_100" />
                </div>
            </div>
            <div
                className="break_before_pdf margin_bottom child_tile container_light background_grey_light outline_transparent"
                style={{ height: "50%" }}
                >
                <h2
                    className="text_bold margin_bottom"
                    style={{ fontSize: "1.2rem" }}
                >
                    Summary SINI
                </h2>
                <hr />
                <div className="">
                    <MARKDOWN_RENDERER markdownContent={summary_sini} />
                </div>
            </div>
        </section>
      </div>
    )
  }
}
