import React, { Component } from "react";
import { Dropdown } from "../../../reuseable";
import Modal from "../../../common_modal/Modal";
import ColumnsSelectionChildTable from "../ColumnsSelectionChildTable";

export default class SetupTableC4SesuaiFisikPeraturan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_columns_selection: false,
    };
  }

  toggleModalColumnsSelection = () => {
    const { modal_columns_selection } = this.state;
    this.setState({
      modal_columns_selection: !modal_columns_selection,
    });
  };

  render() {
    const { fields, placeholder = "Pilih", body } = this.props;

    const { id, ...rest_props } = this.props;

    const { modal_columns_selection } = this.state;

    const modal_columns_selection_content = modal_columns_selection && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={modal_columns_selection}
        onClose={this.toggleModalColumnsSelection}
      >
        <table>
          <tbody>
            <tr>
              <td>
                <p>Object</p>
              </td>
              <td>
                <ColumnsSelectionChildTable
                  id="c4_object_pdf"
                  column_parent_uuid={body[id]}
                  {...rest_props}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p>Luas (m²)</p>
              </td>
              <td>
                <ColumnsSelectionChildTable
                  id="c4_luas_m2_pdf"
                  column_parent_uuid={body[id]}
                  {...rest_props}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p>Nilai pasar: Nilai/m² (Rp.)</p>
              </td>
              <td>
                <ColumnsSelectionChildTable
                  id="c4_nilai_pasar_nilai_per_meter_kuadrat_pdf"
                  column_parent_uuid={body[id]}
                  {...rest_props}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p>Nilai pasar: Total (Rp.)</p>
              </td>
              <td>
                <ColumnsSelectionChildTable
                  id="c4_nilai_pasar_total_pdf"
                  column_parent_uuid={body[id]}
                  {...rest_props}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p>Indikasi Nilai Likuidasi: Nilai/m² (Rp.)</p>
              </td>
              <td>
                <ColumnsSelectionChildTable
                  id="c4_indikasi_nilai_likuidasi_nilai_per_meter_kuadrat_pdf"
                  column_parent_uuid={body[id]}
                  {...rest_props}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p>Indikasi Nilai Likuidasi: Total (Rp.)</p>
              </td>
              <td>
                <ColumnsSelectionChildTable
                  id="c4_indikasi_nilai_likuidasi_total_pdf"
                  column_parent_uuid={body[id]}
                  {...rest_props}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p>MV</p>
              </td>
              <td>
                <ColumnsSelectionChildTable
                  id="c4_mv_pdf"
                  column_parent_uuid={body["c4_tabel_sarana_pelengkap_pdf"]}
                  {...rest_props}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p>Likuidasi (Rp)</p>
              </td>
              <td>
                <ColumnsSelectionChildTable
                  id="c4_likuidasi_rp_pdf"
                  column_parent_uuid={body["c4_tabel_sarana_pelengkap_pdf"]}
                  {...rest_props}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Modal>
    );

    return (
      <div>
        <table className="table_pdf side_border">
          <thead>
            <tr style={{ height: "16pt" }}>
              <td colSpan={6}>
                <div className="flex">
                  <p className="subtitle_pdf">Tabel Informasi Bangunan</p>
                  <Dropdown
                    className="w_100"
                    placeholder={placeholder}
                    filter_on
                    is_enable_empty
                    value={body?.[id] || ""}
                    onChange={(value) => {
                      if (this.props.onChange) {
                        this.props.onChange(id, value);
                      }
                    }}
                  >
                    {fields &&
                      fields.map((field) => (
                        <option key={field.key} value={field.key}>
                          {field.name}
                        </option>
                      ))}
                  </Dropdown>
                </div>
              </td>
              <td>
                <div className="flex">
                  <p className="subtitle_pdf">Tabel Sarana Pelengkap</p>
                  <Dropdown
                    className="w_100"
                    placeholder={placeholder}
                    filter_on
                    is_enable_empty
                    value={body?.["c4_tabel_sarana_pelengkap_pdf"] || ""}
                    onChange={(value) => {
                      if (this.props.onChange) {
                        this.props.onChange(
                          "c4_tabel_sarana_pelengkap_pdf",
                          value
                        );
                      }
                    }}
                  >
                    {fields &&
                      fields.map((field) => (
                        <option key={field.key} value={field.key}>
                          {field.name}
                        </option>
                      ))}
                  </Dropdown>
                </div>
              </td>
              <td>
                {body?.["c4_setup_sesuai_fisik_peraturan_pdf"] &&
                  body?.["c4_tabel_sarana_pelengkap_pdf"] && (
                    <button
                      onClick={() => {
                        this.toggleModalColumnsSelection();
                      }}
                      className="button background_blue"
                    >
                      Fill the Table
                    </button>
                  )}
              </td>
            </tr>
            {/* <tr>
              <td rowSpan={2}>
                <p>Object</p>
              </td>
              <td rowSpan={2}>
                <p>Luas (m²)</p>
              </td>
              <td colSpan={2}>
                <p>Nilai Pasar</p>
              </td>
              <td colSpan={2}>
                <p>Indikasi Nilai Likuidasi</p>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <p>Nilai/m² (Rp.) Total (Rp.)</p>
              </td>
              <td colSpan={2}>
                <p>Nilai/m² (Rp.) Total (Rp.)</p>
              </td>
            </tr> */}
          </thead>
        </table>
        {modal_columns_selection_content}
      </div>
    );
  }
}
