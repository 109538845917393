const basemap_url = process.env.REACT_APP_MAPID_BASEMAP;
const basemap_key = process.env.REACT_APP_BASEMAP_KEY;

const basemap_styles = () => {
  return [
    {
      name: "Street 2D Building",
      value: "2d",
      api: {
        mapid: `${basemap_url}/styles/street-2d-building/style.json?key=${basemap_key}`,
      },
    },
    {
      name: "Street 3D Building",
      value: "3d",
      api: {
        mapbox:
          "https://api.maptiler.com/maps/streets/style.json?key=CVYOeJckfxqURN5wjKEB",
        mapid: `${basemap_url}/styles/basic/style.json?key=${basemap_key}`,
      },
    },
    {
      name: "Light",
      value: "light",
      api: {
        mapbox: "mapbox://styles/mapbox/light-v10",
        mapid: `${basemap_url}/styles/light/style.json?key=${basemap_key}`,
      },
    },
    {
      name: "Dark",
      value: "dark",
      api: {
        mapbox: "mapbox://styles/mapbox/dark-v10",
        mapid: `${basemap_url}/styles/dark/style.json?key=${basemap_key}`,
      },
    },
    {
      name: "Satellite",
      value: "sat",
      api: {
        mapbox: "mapbox://styles/mapbox/satellite-streets-v11",
        mapid: `${basemap_url}/styles/satellite/style.json?key=${basemap_key}`,
      },
    },
  ];
};

export default basemap_styles;
