export const getLayerDetails = (layer) => {
  /* note:
    layer = this.props.layer
    geo_layer = this.props.layer.geo_layer_list[index].geo_layer  
  */
  const { layer_id } = layer;
  const geo_layer_list = layer?.geo_layer_list;
  const layer_details = geo_layer_list?.find(
    (e) => e?.geo_layer?._id === layer_id
  );
  return layer_details;
};

export const getAllFieldsFromGeoLayerWoGeojson = (layer) => {
  const fields = layer?.geo_layer_wo_geojson?.geo_layer?.fields || [];
  return fields;
};

export const getGeoLayerWoGeojson = (layer) => {
  const geo_layer = layer?.geo_layer_wo_geojson?.geo_layer;
  return geo_layer;
};

export const getGeoLayer = (layer) => {
  /* note:
    layer = this.props.layer
    geo_layer = this.props.layer.geo_layer_list[index].geo_layer  
  */
  const { layer_id } = layer;
  const geo_layer_list = layer?.geo_layer_list;
  const geo_layer = geo_layer_list?.find(
    (e) => e?.geo_layer?._id === layer_id
  )?.geo_layer;
  return geo_layer;
};

export const getGeoLayersByArrayOfId = ({ layer, ids }) => {
  const arr_ids = ids?.map((item) => item?._id);
  const geo_layers = layer?.geo_layer_list?.filter((layer) =>
    arr_ids.includes(layer?.geo_layer?._id)
  );
  return geo_layers;
};

export const getGeoLayerById = ({ layer, layer_id }) => {
  const geo_layer_list = layer?.geo_layer_list;
  const geo_layer = geo_layer_list?.find(
    (e) => e?.geo_layer?._id === layer_id
  )?.geo_layer;
  return geo_layer;
};

export const getAllFeatures = (geo_layer) => {
  const features = geo_layer?.geojson_filtered?.features || [];
  return features;
};

export const getAllFields = (geo_layer) => {
  const fields = geo_layer?.fields || [];
  return fields;
};

export const getFieldByKey = ({ geo_layer, field_key }) => {
  const field = geo_layer?.fields?.find((field) => field?.key === field_key);
  return field;
};

export const getFeatureByKey = ({ geo_layer, feature_key }) => {
  const feature = geo_layer?.geojson_filtered?.features?.filter(
    (feature) => feature?.key === feature_key
  )?.[0];
  return feature;
};

export const getChildrenFieldsByColumnParentUuid = ({
  geo_layer,
  column_parent_uuid,
}) => {
  const fields = geo_layer?.fields?.filter(
    (field) => field?.parent_uuid === column_parent_uuid
  );
  return fields;
};

export const getChildrenFeaturesByColumnParentUuidAndRowParentUuid = ({
  feature,
  column_parent_uuid,
  row_parent_uuid,
}) => {
  /*
    table dalam table:
        field_parent_uuid = key dari parent field (type tabel)
        feature_parent_uuid = key dari parent feature (buat merefer child_uuid punya parentnya)

        parent_uuid = uuid dari field
        parent_uuid_temp = uuid untuk row table
  */
  let children = [];
  if (row_parent_uuid) {
    children = feature?.child_array?.filter(
      (child) =>
        child?.parent_uuid === column_parent_uuid &&
        child?.parent_uuid_temp === row_parent_uuid
    );
  } else {
    children = feature?.child_array?.filter(
      (child) => child?.parent_uuid === column_parent_uuid
    );
  }

  return children;
};

export const getChildByUuid = ({ feature, uuid }) => {
  const child = feature?.child_array?.find(
    (child) => child?.child_uuid === uuid
  );
  return child;
};

export const getAllChildrenRows = (feature) => {
  const children = feature?.child_array || [];
  return children;
};

export const getAllDataPembanding = (layer) => {
  const { layer_id } = layer;
  const geo_layer_list = layer?.geo_layer_list;
  const geo_layer = geo_layer_list?.find(
    (e) => e?.geo_layer?._id === layer_id
  )?.geo_layer;
  const data_pembanding_list = geo_layer?.layer_data_list?.map(
    (item) => item?._id
  );
  const layer_data_pembanding_list = geo_layer_list?.filter((e) =>
    data_pembanding_list?.includes(e?.geo_layer?._id)
  );
  return layer_data_pembanding_list;
};
