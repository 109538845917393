import { Menu, MenuItem } from "@mui/material";
import React, { Component, createRef } from "react";
import { delete_sheet_by_id } from "../../App/actions/sheetAction";
import { connect } from "react-redux";

class SheetButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // modal_right_click: false,
      anchorEl: null,
      isEditing: false,
      renameText: props.sheetname?.name || ""
    };
    this.inputRef = createRef();
  }

  // componentDidMount = () => {
  //   const { sheetname } = this.props;
  //   this.setState({
  //     renameText: sheetname?.name
  //   })
  // }

  toggle_right_click = (event, value) => {
    event.preventDefault();
    const { setSelectedSheet } = this.props;
    this.setState({ anchorEl: event.currentTarget });
    setSelectedSheet(value);
  };

  toggle_close = () => {
    this.setState({ anchorEl: null });
  };

  handleDelete = async () => {
    const {
      sheetname,
      setSheetnames,
      sheetnames,
      selectedSheet,
      setSelectedSheet,
      worksheet,
      setWorksheet,
    } = this.props;
    const res = await this.props.delete_sheet_by_id({ _id: sheetname?._id });
    if (res?.status === 200) {
      const newSheetnames = sheetnames.filter(
        (item) => item?._id !== sheetname?._id
      );
      setSheetnames(newSheetnames);
      let filteredWorksheet = worksheet.filter(
        (item) => item?.metadata?.id_ori !== selectedSheet
      );
      if (filteredWorksheet.length > 0) {
        setWorksheet(filteredWorksheet);
      } else {
        setWorksheet(null);
      }

      if (sheetname?.id_ori === selectedSheet) {
        const newSelectedSheet = newSheetnames?.[0]?.id_ori || "";
        setSelectedSheet(newSelectedSheet);
      }
    }
  };

  // handleIsEditing = () => {
  //   console.log("running")
  //   this.setState({
  //     isEditing: true,
  //     anchorEl: null
  //   },
  //     () => {
  //       console.log(this.inputRef.current)
  //       // this.inputRef //.current?.focus();
  //     }
  //   )
  // }

  // handleRename = () => {

  // }

  render() {
    const { setSelectedSheet, sheetname, selectedSheet } = this.props;
    const { anchorEl, isEditing, renameText } = this.state;
    const open = Boolean(anchorEl);


    let labelContent = <label
      className={`pointer no_wrap padding_2 button ${selectedSheet === sheetname?.id_ori
        ? "background_black"
        : "background_white"
        }`}
    >
      {sheetname?.name}
    </label>

    if (isEditing) {
      labelContent = <input
        ref={this.inputRef}
        id={`rename_field_${sheetname?._id}`}
        className="w_150"
        value={renameText}
        onChange={(e) => {
          this.setState({
            renameText: e.target.value
          })
        }}
        onBlur={(e) => {
          this.setState({
            isEditing: false
          })
        }} />
    }


    return (
      <div
        className="noselect"
        onContextMenu={(e) => this.toggle_right_click(e, sheetname?.id_ori)}
        onClick={(e) => {
          setSelectedSheet(sheetname?.id_ori);
        }}
      >
        {labelContent}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.toggle_close}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={this.handleDelete}>Delete</MenuItem>
          {/* <MenuItem onClick={this.handleIsEditing}>Rename</MenuItem> */}
        </Menu>
      </div >
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  delete_sheet_by_id,
})(SheetButton);
