//LIBRARY
import axios from "axios";
import calculate_area from "@turf/area";
import convex from "@turf/convex";

//PERSONAL
import flood_propagation_v3 from "../client_functions/flood_propagation_v3";
import { geoServerBaseUrl, alphaServerUrl } from "./baseUrl";

const SERVER_URL = geoServerBaseUrl;
const ALPHA_URL = alphaServerUrl;

/*GET METHODE*/

//get_genangan
export const get_genangan = (params) => async (dispatch) => {
  let {
    longitude,
    latitude,
    genangan_cm,
    feature_key,
    overwrite_status,
    index_max,
    radius_start,
    delta_sample_perimeter_m,
    delta_sample_final_m,
    radius_start_propagation,
  } = params;

  try {
    genangan_cm = parseInt(genangan_cm);

    if (!overwrite_status) {
      dispatch({
        type: "pull_value_sini_object",
        payload: {
          key: "features_genangan",
          value: feature_key,
          id: "feature_key",
        },
      });
    }

    /*
    index_max:
    total iterasi yang akan dilakukan
    semakin besar angka semakin banyak iterasi yang dilakukan, luas area kemungkinan menjadi semakin luas

    radius_start:
    radius perambatan dari satu titik untuk mendapatkan satu titik pertama
    semakin besar angka semakin banyak titik yang dijadikan sample pertama, kemungkian mendapatkan titik elevasi semakin besar

    delta_sample_perimeter_m:
    interval jarak titik sample ke titik lainnya dalam meter
    semakin besar, semakin sedikit titik sample, akurasi semakin sedikit, luas semakin kecil

    delta_sample_final_m:
    interval jarak titik sample ke titik lainnya dalam meter untuk poligon terakhir yang disederhanakan
    */

    if (!!overwrite_status) {
      //hapus data lama
      await axios.delete(
        SERVER_URL +
          `/algoritma_cilicis/delete_poligon_genangan?feature_key=${feature_key}`
      );
      dispatch({
        type: "set_value_sini",
        payload: {
          key: "mode_genangan",
          value: "overwrite",
        },
      });
    }

    const res_feature = await axios.get(
      SERVER_URL +
        `/algoritma_cilicis/get_polygon_result?feature_key=${feature_key}`
    );
    let feature_db = res_feature.data;

    dispatch({
      type: "set_value_properties",
      payload: {
        key: "success_status",
        value: false,
      },
    });
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "error_status",
        value: false,
      },
    });
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "error_message",
        value: "",
      },
    });
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "popupInfo",
        value: null,
      },
    });
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "modal_pop_up_layer",
        value: false,
      },
    });
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "loading_status",
        value: true,
      },
    });
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "loading_item",
        value: "get_genangan",
      },
    });

    dispatch({
      type: "set_value_sini",
      payload: {
        key: "feature_key_loading_genangan",
        value: feature_key,
      },
    });
    dispatch({
      type: "set_value_sini",
      payload: {
        key: "feature_key_fly_genangan",
        value: feature_key,
      },
    });
    dispatch({
      type: "set_value_sini",
      payload: {
        key: "overwrite_longitude",
        value: longitude,
      },
    });
    dispatch({
      type: "set_value_sini",
      payload: {
        key: "overwrite_latitude",
        value: latitude,
      },
    });
    dispatch({
      type: "set_value_sini",
      payload: {
        key: "overwrite_genangan_cm",
        value: genangan_cm,
      },
    });
    dispatch({
      type: "set_value_sini",
      payload: {
        key: "overwrite_feature_key",
        value: feature_key,
      },
    });

    if (feature_db && !overwrite_status) {
      dispatch({
        type: "set_value_sini",
        payload: {
          key: "mode_genangan",
          value: "db",
        },
      });

      feature_db.feature_key = feature_key;
      feature_db.status = "db";

      dispatch({
        type: "push_value_sini",
        payload: {
          key: "features_genangan",
          value: [feature_db],
        },
      });

      dispatch({
        type: "push_value_sini",
        payload: {
          key: "features_genangan_feature_key",
          value: [feature_key],
        },
      });

      dispatch({
        type: "state_update",
      });
      dispatch({
        type: "set_value_properties",
        payload: {
          key: "success_status",
          value: true,
        },
      });
      dispatch({
        type: "set_value_properties",
        payload: {
          key: "loading_status",
          value: false,
        },
      });
    } else {
      dispatch({
        type: "set_value_properties",
        payload: {
          key: "total_progress_genangan_global",
          value: index_max,
        },
      });

      dispatch({
        type: "set_value_properties",
        payload: {
          key: "current_progress_genangan",
          value: 0,
        },
      });

      dispatch({
        type: "set_value_properties",
        payload: {
          key: "current_progress_genangan_global",
          value: 0,
        },
      });

      let res;
      let data;

      res = await axios.get(
        ALPHA_URL +
          `/algoritma_cilicis/get_point_flood_db?long=${longitude}&lat=${latitude}&genangan_cm=${genangan_cm}&feature_key=${feature_key}&genangan_absolute_m=0&radius_start=${radius_start}&concavity=1`
      );
      dispatch({
        type: "set_value_properties",
        payload: {
          key: "current_progress_genangan",
          value: 1,
        },
      });
      data = res.data;
      const genangan_absolute_m = data?.genangan_absolute_m;
      const features_point_flood = data?.features_point_flood;

      //generate poligon awal

      const geojson_polygon = {
        type: "FeatureCollection",
        features: features_point_flood,
      };
      let feature_polygon = convex(geojson_polygon, {
        concavity: 1,
      });
      const area_meter_square = parseFloat(
        calculate_area(feature_polygon).toFixed(0)
      );
      const area_hectare = parseFloat((area_meter_square / 10_000).toFixed(4));
      feature_polygon.properties = {
        feature_key,
        area_meter_square,
        area_hectare,
      };
      feature_polygon.feature_key = feature_key;

      if (!overwrite_status) {
        dispatch({
          type: "push_value_sini",
          payload: {
            key: "features_genangan",
            value: [feature_polygon],
          },
        });
        dispatch({
          type: "push_value_sini",
          payload: {
            key: "features_genangan_feature_key",
            value: [feature_key],
          },
        });
        dispatch({
          type: "set_value_sini",
          payload: {
            key: "mode_genangan",
            value: "first_run",
          },
        });
      }

      dispatch({
        type: "state_update",
      });
      dispatch(
        flood_propagation_v3({
          longitude,
          latitude,
          genangan_cm,
          feature_key,
          features_point_flood,
          index_iteration: 0,
          index_max: index_max,
          genangan_absolute_m,
          radius_start,
          delta_sample_perimeter_m,
          delta_sample_final_m,
          radius_start_propagation,
        })
      );
    }
  } catch (error) {
    let error_message;
    if (error.response) {
      /*
      The request was made and the server responded with a status code that falls out of the range of 2xx

      error.response.data
      error.response.status
      error.response.headers
      */
      error_message = error?.response?.data?.message;
    } else if (error.request) {
      // The request was made but no response was received
      error_message = error.request;
    } else {
      // Something happened in setting up the request that triggered an Error
      error_message = error.message;
    }
    dispatch({
      type: "push_value_genangan",
      payload: {
        key: "array_genangan_message_failed",
        value: {
          feature_key,
          error_message,
        },
      },
    });
    dispatch({
      type: "push_value_genangan",
      payload: {
        key: "array_genangan_failed",
        value: feature_key,
      },
    });
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "loading_status",
        value: false,
      },
    });
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "error_status",
        value: true,
      },
    });
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "error_message",
        value: error_message,
      },
    });
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "modal_pop_up_genangan",
        value: true,
      },
    });
  }
};
