const sortArray = function (array, key, isFromSmall) {
  if (key) {
    if (isFromSmall) {
      function compare(a, b) {
        if (a[key] < b[key]) return -1;
        if (a[key] > b[key]) return 1;
        return 0;
      }
      return array.sort(compare);
    } else {
      function compare(a, b) {
        if (a[key] < b[key]) return 1;
        if (a[key] > b[key]) return -1;
        return 0;
      }
      return array.sort(compare);
    }
  } else {
    if (isFromSmall) {
      function compare(a, b) {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      }
      return array.sort(compare);
    } else {
      function compare(a, b) {
        if (a < b) return 1;
        if (a > b) return -1;
        return 0;
      }
      return array.sort(compare);
    }
  }
};
export default sortArray;
