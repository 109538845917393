/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "@mui/material/Menu";

/*PERSONAL COMPONENT*/
import ProgressBar from "../common_spinner/ProgressBar";
import SINI_DOWNLOAD_UNIVERSAL from "./SINI_DOWNLOAD_UNIVERSAL";
import SINI_POLYGON_CONFIG from "./SINI_POLYGON_CONFIG";

/*REDUX FUNCTION*/
import { set_value_sini } from "../../App/actions/sini_v2";

/*PICTURE ASSET*/
import ICON_BACK from "../../Assets/jsx/ICON_BACK";

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";
import manifest_people_spending from "../../Data/manifest_people_spending.json";
import st_colors from "../../Data/st_colors.json";
import sort_array from "../../App/validation/sort_array";

/*NON IMPORT*/

class SINI_PEOPLE_SPENDING_FULL extends Component {
  state = {
    anchor: null,
    modal_download: false,
    modal_layer_config: false,
  };

  toggle_layer_config = (e) => {
    this.setState({ modal_layer_config: !this.state.modal_layer_config });
    if (e) {
      this.setState({ anchor: e.currentTarget });
    }
  };

  toggle_download = (e) => {
    this.setState({ modal_download: !this.state.modal_download });
    if (e) {
      this.setState({ anchor: e.currentTarget });
    }
  };

  on_set_sini_menu_active = () => {
    this.props.set_value_sini({
      key: "sini_menu_active",
      value: "",
    });
    this.props.set_value_sini({
      key: "sini_map_show",
      value: ["poi", "isochrone"],
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { modal_download, anchor, modal_layer_config } = this.state;

    //global props
    const {
      sini_v2_list,
      request_id_active,
      ai_parent_detail_list,
      is_show_download_button,
    } = this.props.sini;
    const { loading_item_array } = this.props.loading_reducer;

    //content
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    const is_loading = loading_item_array.includes("people_spending");
    const sini_object = merge_array.find(
      (item) => item.request_id === request_id_active
    );
    const properties = sini_object?.people_spending?.properties || {};
    let content;

    const modal_layer_config_content = modal_layer_config && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_layer_config}
        onClose={this.toggle_layer_config}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div
          style={{
            padding: "7px 15px 0px 15px",
            width: "250px",
          }}
        >
          <SINI_POLYGON_CONFIG />
        </div>
      </Menu>
    );

    if (is_loading || properties !== undefined) {
      content = (
        <section
          className="sini_full_scroll_area"
          style={{ paddingRight: "10px" }}
        >
          {is_loading ? (
            <p className="text_small">
              {dict?.["Loading data"]?.[language]}...
            </p>
          ) : (
            <section className="margin_bottom">
              {manifest_people_spending.map((item, idx) => {
                const { child } = item;
                let array_of_object = child.map((item) => {
                  return {
                    label: item[language],
                    number: properties?.[item?.key],
                  };
                });
                array_of_object = array_of_object.filter(
                  (item) => item.label && item.number
                );
                array_of_object = sort_array(array_of_object, "number", false);
                const number_array = array_of_object.map((item) => {
                  return item.number;
                });
                const color_array = number_array.map((item, idx) => {
                  const index =
                    idx < st_colors.length - 1 ? idx : idx % st_colors.length;
                  return st_colors[index];
                });
                const max = number_array[0];
                return (
                  <main key={idx} className="margin_bottom_extra">
                    <p
                      className="button_big background_light_grey margin_bottom text_left"
                      style={{ position: "sticky", top: 0, borderRadius: 0 }}
                    >
                      {item[language]}
                    </p>
                    {array_of_object.map((element, idx) => {
                      let formated_number;
                      if (element.number) {
                        formated_number = parseInt(element.number);
                        formated_number = new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(formated_number);
                      }
                      return (
                        <section
                          key={idx}
                          className="button_transparent"
                          style={{
                            paddingBottom: "10px",
                            paddingTop: "10px",
                          }}
                        >
                          <div className="parent_half text_small">
                            <div className="left_half text_left full_width">
                              {element.label}
                            </div>
                            <div className="right_half text_right">
                              {formated_number}
                            </div>
                          </div>
                          <ProgressBar
                            current_number={element.number}
                            total_number={max}
                            color={color_array[idx]}
                            is_hide_text={true}
                          />
                        </section>
                      );
                    })}
                  </main>
                );
              })}
            </section>
          )}
        </section>
      );
    }

    const modal_download_content = modal_download && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_download}
        onClose={this.toggle_download}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div
          style={{
            padding: "7px 15px 0px 15px",
            width: "250px",
          }}
        >
          <SINI_DOWNLOAD_UNIVERSAL download_topic="people_spending" />
        </div>
      </Menu>
    );

    return (
      <main className="sini_full_parent">
        {modal_download_content}
        {modal_layer_config_content}
        <section className="sini_full_header">
          <table>
            <tbody>
              <tr>
                <td
                  style={{ width: "30px" }}
                  onClick={this.on_set_sini_menu_active}
                  data-mapid="clickBack"
                >
                  <ICON_BACK />
                </td>
                <td className="text_left">
                  <p className="badge background_green margin_bottom margin_right">
                    {dict?.["People spending"]?.[language]}
                  </p>
                </td>
                <td className="text_left">
                  <p
                    onClick={this.toggle_layer_config}
                    className="button button_white outline_black margin_bottom margin_right"
                  >
                    Visual setting
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          {is_show_download_button && (
            <button
              className="badge background_green margin_bottom"
              onClick={this.toggle_download}
            >
              {dict?.["Download"]?.[language]}
            </button>
          )}
        </section>
        {content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  sini: state.sini,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, { set_value_sini })(
  SINI_PEOPLE_SPENDING_FULL
);
