/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datasheet/lib/react-datasheet.css";

/*COMPONENT*/
import SpinnerSimpleBlue from "../common_spinner/SpinnerSimpleBlue";
import TABLE_VIEWER from "./TABLE_VIEWER";
import IotTable from "../viewer_chart/IotTable";
import IotTableBar from "../viewer_chart/IotTableBar";
import IotTableSpbu from "../viewer_chart/IotTableSpbu";

/*REDUX*/
import {
  pushFeature,
  pushField,
  editProperties,
  deleteFeature,
  setTabelStatus,
  setFlyFeatures,
} from "../../App/actions/layerActions";
import {
  tableFull,
  editGeometryTracking,
} from "../../App/actions/layerNewActions";

/*PICTURE*/

/*FUNCTION*/

/*DATA*/

/*CONST*/

class TABLE_VIEWER_PARENT extends Component {
  state = {
    modalPushField: false,
    modalEditField: false,
    modalDeleteFeature: false,
    fields: [],
    grid: [],
    new_fields: [],
    geo_layer: {},
    features: [],
    param_filter: [],
    filtered_features: [],
    content: null,
  };

  componentWillUnmount() {
    this.props.setTabelStatus(false);
  }

  flyTo = (e) => {};

  render() {
    const { loadingDetail, realtimeStatus, layer_id, geo_layer_list } =
      this.props.layer;
    const geo_layer = geo_layer_list?.find((l) => l.geo_layer._id === layer_id);
    let content;
    if (loadingDetail && !realtimeStatus) {
      content = (
        <SpinnerSimpleBlue
          width={100}
          unik="loading_create_layer"
          border={0.1}
          center={true}
        />
      );
    } else {
      if (geo_layer?.geo_layer) {
        if (
          geo_layer?.geo_layer?.type_2 === "pda" ||
          geo_layer?.geo_layer?.type_2 === "pch"
        ) {
          content =
            geo_layer?.geo_layer?.type_2 === "pda" ? (
              <IotTable propsFly={(e) => this.flyTo(e)} />
            ) : (
              // <IotTableBar propsFly={(e) => this.flyTo(e)} />
              <IotTableBar />
            );
        } else if (geo_layer?.geo_layer?.type !== "IoT") {
          console.log("masuk sini");
          content = (
            <div style={{ height: "300px" }}>
              <TABLE_VIEWER />
            </div>
          );
        } else {
          const domain = window.location.hostname;
          if (
            ["bbwsbrantas.mapid.io"].includes(domain) ||
            this.props.auth.mode_balai === "brantas" ||
            !isNaN(parseInt(domain))
          ) {
            content = geo_layer?.geo_layer?.name.includes("AWLR") ? (
              <IotTable propsFly={(e) => this.flyTo(e)} />
            ) : (
              <IotTableBar propsFly={(e) => this.flyTo(e)} />
            );
          } else {
            content = <IotTableSpbu propsFly={(e) => this.flyTo(e)} />;
          }
        }
      }
    }
    return <main>{content}</main>;
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  layer: state.layer,
  auth: state.auth,
  map: state.map,
});

export default connect(mapStateToProps, {
  pushFeature,
  pushField,
  editProperties,
  deleteFeature,
  setTabelStatus,
  setFlyFeatures,
  tableFull,
  editGeometryTracking,
})(TABLE_VIEWER_PARENT);
