const formatDateFormal = function (timestamp) {
  const date = new Date(timestamp);
  const months_ina = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "Septermber",
    "Oktober",
    "November",
    "Desember",
  ];
  const tanggal = date.getDate();
  let month_final = months_ina[date.getMonth()];
  let date_final = tanggal + " " + month_final + " " + date.getFullYear();
  return date_final;
};

export default formatDateFormal;
