//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
//Personal Component
//Redux function
import { upload_picture_field } from "../../App/actions/layerActions";
//Picture Asset
import icon_upload_image from "../../Assets/svg/icon_upload_image.svg";
//General Function
import uuid from "../../App/validation/uuid";
import dict from "../../Data/dict.json";
class UploadPictureField extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fileInput = React.createRef();
  }
  handleSubmit(e) {
    const { geo_layer_wo_geojson } = this.props.layer;
    e.preventDefault();
    const file = this.fileInput.current.files[0];
    const objParams = {
      file: file,
      file_name: "field_form" + uuid(),
      dirname: "field_form",
    };
    let finalObject;
    finalObject = {
      objParams,
      geo_layer_id: geo_layer_wo_geojson?.geo_layer?._id,
      field: this.props.field_current,
    };
    this.props.upload_picture_field(finalObject);
  }
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    let itemContent = (
      <label
        title={dict["Upload a question image"][language]}
        className="fileContainer"
        style={{
          width: "50px",
          wordBreak: "break-word",
        }}
      >
        <div
          className="container_icon"
          style={{
            cursor: "pointer",
            width: "20px",
            backgroundColor: "#fff",
          }}
        >
          <img
            src={icon_upload_image}
            alt="Upload gambar field"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
        {/* <p className="text_inferior">
          {dict["Upload a question image"][language]}
        </p> */}

        <input
          style={{ cursor: "pointer" }}
          type="file"
          accept="image/*"
          ref={this.fileInput}
          onChange={this.handleSubmit}
        />
      </label>
    );
    return <div>{itemContent}</div>;
  }
}
const mapStateToProps = (state) => ({
  layer: state.layer,
});
export default connect(mapStateToProps, {
  upload_picture_field,
})(UploadPictureField);
