import React, { Component } from "react";
// import dict from "../../Data/dict.json";

export default class NumberAndActionHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  toggleHover = (value) => {
    this.setState({ hover: value });
  };
  render() {
    // const language = localStorage?.language ? localStorage?.language : "ina";
    const { params, onOpenModalTutorial } = this.props;
    return (
      <section
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
        }}
        onMouseEnter={() => {
          this.toggleHover(true);
        }}
        onMouseLeave={() => {
          this.toggleHover(false);
        }}
      >
        {this.state.hover && (
          <div
            style={{
              marginRight: 5,
            }}
          >
            <button
              className="button_small hover_darkBlue"
              onClick={(e) => {
                onOpenModalTutorial("Attribute Table");
              }}
              style={{
                display: "inline",
              }}
            >
              INFO
            </button>
          </div>
        )}
        <div>
          <strong>{params.colDef?.headerName}</strong>
        </div>
      </section>
    );
  }
}
