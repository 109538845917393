import React, { Component } from 'react'

//General Function
import dict from "../../Data/dict.json";

export default class WaitForTransactionProcess extends Component {

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    return (
      <div className='flex_col center_perfect h_250'>
        <div>
          <svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth="10">
            <circle cx="50" cy="50" r="40" strokeOpacity="1" stroke='#006aba' />
            <circle cx="50" cy="50" r="40" strokeDasharray="251.2" strokeDashoffset="0" stroke='#8bcfea'>
              <animate attributeName="stroke-dashoffset" values="0;251.2" dur="2s" repeatCount="indefinite" />
              <animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="2s" repeatCount="indefinite" />
            </circle>
          </svg>
        </div>
        <div>
          <p className='font_20'>{dict["Processing your transaction. Please wait"][language]}</p>
        </div>
      </div>
    )
  }
}
