import React, { Component } from "react";
import { connect } from "react-redux";
// import { getGeoLayer } from '../../App/reduxHelper/layer';
import { StyledDataGrid } from "../../Style/trivial/StyledDataGrid";
import is_not_empty from "../../App/validation/is_not_empty";

// import dict from "../../Data/dict.json";

class DataTypeConfirmation extends Component {
  // constructor(props) {
  //   super(props);
  //   // this.state = {
  //   //   features_counter: []
  //   // }
  // }

  handle_data_type = ({ field, value }) => {
    const { set_target_data_types } = this.props;
    let target_data_types = structuredClone(this.props.target_data_types) || [];
    const idx = target_data_types.findIndex((item) => item.fields === field);
    if (idx !== -1) {
      target_data_types[idx]["dataType"] = value;
      set_target_data_types(target_data_types);
    }
  };

  componentDidUpdate = (prevProps) => {
    if (JSON.stringify(prevProps.file) !== JSON.stringify(this.props.file)) {
      const { set_target_data_types } = this.props;
      const file = this.props.file;

      let result = [];
      file?.headers?.forEach((key, index) => {
        const no = index + 1;
        result.push({
          id: no,
          no: no,
          fields: key,
          dataType: "text",
          nan: 0,
          empty: 0,
        });
      });

      file?.headers?.forEach((field) => {
        const idx = result.findIndex((item) => item.fields === field);
        if (idx !== -1) {
          if (!isNaN(file.data?.features?.[0]?.["properties"][field])) {
            result[idx]["dataType"] = "number";
          }
        }
      });

      file.data?.features?.forEach((feature) => {
        file?.headers?.forEach((field) => {
          const idx = result.findIndex((item) => item.fields === field);
          if (idx !== -1) {
            // cari nan value dan type data (text/number)
            if (isNaN(feature?.["properties"][field])) {
              result[idx]["nan"] += 1;
            }

            // cari value kosong (null, undefined)
            if (!is_not_empty(feature?.["properties"][field])) {
              result[idx]["empty"] += 1;
            }
          }
        });
      });

      set_target_data_types(result);
    }
  };

  get_columns = () => {
    return [
      {
        field: "no",
        headerName: "No.",
        editable: false,
        flex: 1,
        type: "number",
      },
      {
        field: "fields",
        headerName: "Fields",
        editable: false,
        flex: 3,
      },
      {
        field: "dataType",
        headerName: "Data Types",
        editable: false,
        flex: 2,
        renderCell: (params) => {
          const field = params?.row?.fields;
          const value = params?.formattedValue;

          const selection = (
            <select
              className="dropdown_in_table"
              value={value}
              onChange={(e) => {
                this.handle_data_type({ field, value: e.target.value });
              }}
            >
              <option value={"number"}>Number</option>
              <option value={"text"}>Text</option>
            </select>
          );
          return selection;
        },
      },
      {
        field: "nan",
        headerName: "NaN (Not a Number)",
        editable: false,
        flex: 2,
      },
      {
        field: "empty",
        headerName: "Empty Value",
        editable: false,
        flex: 2,
      },
    ];
  };

  render() {
    const { target_data_types } = this.props;
    const rows_text = [];
    const rows_number = [];
    target_data_types?.forEach((item) => {
      if (item["dataType"] === "number") {
        rows_number.push(item);
      } else {
        rows_text.push(item);
      }
    });

    return (
      <div className="flex flex_col gap_20 marginTop_20">
        <section>
          <label>Number</label>
          <StyledDataGrid
            rows={rows_number}
            columns={this.get_columns()}
            getRowHeight={() => 35}
            rowsPerPageOptions={[25, 50, 100, 500, 1000]}
            disableColumnMenu
          />
        </section>
        <section>
          <label>Text</label>
          <StyledDataGrid
            rows={rows_text}
            columns={this.get_columns()}
            getRowHeight={() => 35}
            rowsPerPageOptions={[25, 50, 100, 500, 1000]}
            disableColumnMenu
          />
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    layer: state.layer,
  };
};
export default connect(mapStateToProps, {})(DataTypeConfirmation);
