const initialState = {
  dataset: null,
  lastDataAll: [],
  lastDataPDA: [],
  lastDataPCH: [],
  brantasLastDataPDA: [],
  brantasLastDataPCH: [],
  cilicisCCTV: [
    {
      nama_lokasi: "KATULAMPA(M)",
      url: ["katulampa-01.html", "katulampa-02.html"],
    },
    {
      nama_lokasi: "CILEUNGSI",
      url: ["cileungsi-01.html"],
    },
    {
      nama_lokasi: "BKT1",
      url: ["bkt-1-01.html"],
    },
    {
      nama_lokasi: "BKT2",
      url: ["bkt-2-01.html"],
    },
    {
      nama_lokasi: "BKT3",
      url: ["bkt-3-02.html"],
    },
    {
      nama_lokasi: "PANUS.DEPOK",
      url: ["panusdki-01.html"],
    },
    {
      nama_lokasi: "MAYOR.OKING",
      url: ["mayoroking-01.html"],
    },
    {
      nama_lokasi: "MANGGARAI",
      url: ["manggarai-01.html", "manggarai-02.html"],
    },
    {
      nama_lokasi: "BENDUNG BEKASI",
      url: ["bendungbekasi-01.html", "bendungbekasi-02.html"],
    },
  ],
};

export default function iotReducer(state = initialState, action) {
  switch (action.type) {
    case "IOT_DATA":
      return {
        ...state,
        dataset: action.payload,
      };
    case "IOT_LAST_DATA_ALL":
      return {
        ...state,
        lastDataAll: action.payload,
      };
    case "SDA_PDA":
      return {
        ...state,
        lastDataPDA: action.payload,
      };
    case "SDA_PCH":
      return {
        ...state,
        lastDataPCH: action.payload,
      };
    case "BRANTAS_PDA":
      return {
        ...state,
        brantasLastDataPDA: action.payload,
      };
    case "BRANTAS_PCH":
      return {
        ...state,
        brantasLastDataPCH: action.payload,
      };

    default:
      return state;
  }
}
