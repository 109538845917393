const is_equal_array = function (value, other) {
  const objects_equal = (o1, o2) => {
    if (o1 === null && o2 === null) {
      return true;
    } else {
      return typeof o1 === "object" && Object.keys(o1)?.length > 0
        ? Object.keys(o1)?.length === Object.keys(o2)?.length &&
            Object.keys(o1).every((p) => objects_equal(o1[p], o2[p]))
        : o1 === o2;
    }
  };
  const arrays_equal = (a1, a2) =>
    a1?.length === a2?.length &&
    a1.every((o, idx) => objects_equal(o, a2?.[idx]));
  return arrays_equal(value, other);
};
export default is_equal_array;
