/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import {
  edit_inside_object,
  delete_array_string_inside_object,
  push_array_inside_object,
  set_value_bi,
} from "../../App/actions/biActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class BI_ADD_LAYER_DATA extends Component {
  state = {};

  on_use_load_for_view = () => {
    const layer_load_list =
      this?.props?.bi?.setting_add_object?.layer_load_list || [];
    const layer_load_mode =
      this?.props?.bi?.setting_add_object?.layer_load_mode;
    this.props.edit_inside_object({
      key: "setting_add_object",
      id_key: "layer_view_map_list",
      value: layer_load_list,
    });
    this.props.edit_inside_object({
      key: "setting_add_object",
      id_key: "layer_view_map_mode",
      value: layer_load_mode,
    });
  };

  on_continue = () => {
    const { add_setting_current_step } = this.props.bi;
    this.props.set_value_bi({
      key: "add_setting_current_step",
      value: add_setting_current_step + 1,
    });
  };

  on_change_setting = (id_key, value) => {
    this.props.edit_inside_object({
      key: "setting_add_object",
      id_key,
      value,
    });
    if (id_key === "layer_load_mode" || id_key === "layer_view_map_mode") {
      if (value === "all") {
        this.on_set_all_layer();
      }
    }
  };

  on_push_layer = (layer_id) => {
    const { add_setting_current_step } = this.props.bi;
    const id_key =
      add_setting_current_step === 0
        ? "layer_load_list"
        : "layer_view_map_list";
    const id_key_mode =
      add_setting_current_step === 0
        ? "layer_load_mode"
        : "layer_view_map_mode";
    const list = this.props.bi.setting_add_object?.[id_key] || [];
    if (!list.includes(layer_id)) {
      this.props.push_array_inside_object({
        key: "setting_add_object",
        id_key,
        value: layer_id,
      });
    } else {
      this.props.delete_array_string_inside_object({
        key: "setting_add_object",
        id_key,
        value: layer_id,
      });
      this.props.edit_inside_object({
        key: "setting_add_object",
        id_key: id_key_mode,
        value: "selected_only",
      });
    }
  };

  on_set_all_layer = () => {
    const { add_setting_current_step, project_object_selected } = this.props.bi;
    const layer_list = project_object_selected?.layer_list || [];
    const id_key =
      add_setting_current_step === 0
        ? "layer_load_list"
        : "layer_view_map_list";
    const layer_id_list = layer_list.map((item) => item?.geo_layer?._id);
    this.props.edit_inside_object({
      key: "setting_add_object",
      id_key,
      value: layer_id_list,
    });
  };

  on_clear_all_layer = () => {
    const { add_setting_current_step } = this.props.bi;
    const id_key =
      add_setting_current_step === 0
        ? "layer_load_list"
        : "layer_view_map_list";
    const id_key_mode =
      add_setting_current_step === 0
        ? "layer_load_mode"
        : "layer_view_map_mode";
    this.props.edit_inside_object({
      key: "setting_add_object",
      id_key,
      value: [],
    });
    this.props.edit_inside_object({
      key: "setting_add_object",
      id_key: id_key_mode,
      value: "selected_only",
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const {
      project_object_selected,
      add_setting_current_step,
      setting_add_object,
    } = this.props.bi;

    //content
    const {
      layer_load_mode,
      layer_view_map_mode,
      layer_load_list,
      layer_view_map_list,
    } = setting_add_object;

    const layer_list = project_object_selected?.layer_list || [];

    let select_content;
    if (add_setting_current_step === 0) {
      select_content = (
        <>
          <button
            className={`button_small outline_black margin_bottom margin_right ${
              layer_load_mode === "all"
                ? "background_green"
                : "background_white"
            }`}
            onClick={this.on_change_setting.bind(
              this,
              "layer_load_mode",
              "all"
            )}
          >
            {dict?.["All"]?.[language]}
          </button>
          <button
            className={`button_small outline_black margin_bottom margin_right ${
              layer_load_mode === "selected_only"
                ? "background_green"
                : "background_white"
            }`}
            onClick={this.on_change_setting.bind(
              this,
              "layer_load_mode",
              "selected_only"
            )}
          >
            {dict?.["Only selected ones"]?.[language]}
          </button>
          <button
            className="button_small outline_black margin_bottom margin_right background_black"
            onClick={this.on_clear_all_layer}
          >
            {dict?.["Unselect all"]?.[language]}
          </button>
        </>
      );
    } else if (add_setting_current_step === 1) {
      select_content = (
        <>
          <button
            className={`button_small outline_black margin_bottom margin_right ${
              layer_view_map_mode === "all"
                ? "background_green"
                : "background_white"
            }`}
            onClick={this.on_change_setting.bind(
              this,
              "layer_view_map_mode",
              "all"
            )}
          >
            {dict?.["All"]?.[language]}
          </button>
          <button
            className={`button_small outline_black margin_bottom margin_right ${
              layer_view_map_mode === "selected_only"
                ? "background_green"
                : "background_white"
            }`}
            onClick={this.on_change_setting.bind(
              this,
              "layer_view_map_mode",
              "selected_only"
            )}
          >
            {dict?.["Only selected ones"]?.[language]}
          </button>
          <button
            className="button_small outline_black margin_bottom margin_right background_black"
            onClick={this.on_clear_all_layer}
          >
            {dict?.["Unselect all"]?.[language]}
          </button>
          <button
            className="button_small outline_black margin_bottom margin_right background_black"
            onClick={this.on_use_load_for_view}
          >
            {dict?.["Match the settings to the loaded layers"]?.[language]}
          </button>
        </>
      );
    }

    return (
      <main>
        {(add_setting_current_step === 0 || add_setting_current_step === 1) && (
          <section className="container_light">
            {add_setting_current_step === 0 && (
              <p className="text_bold">
                {dict?.["Select any layer to load"]?.[language]}
              </p>
            )}
            {add_setting_current_step === 1 && (
              <p className="text_bold">
                {dict?.["Select any layer to display on the map"]?.[language]}
              </p>
            )}
            <input placeholder="Search by name (coming soon)" />
            {select_content}
            <section
              style={{ maxHeight: "calc(85vh - 400px)", overflowY: "auto" }}
            >
              <table className="full_width margin_bottom">
                <tbody>
                  {layer_list.map((item, idx) => {
                    let is_open;
                    if (add_setting_current_step === 0) {
                      is_open = layer_load_list.includes(item?.geo_layer?._id);
                    } else {
                      is_open = layer_view_map_list.includes(
                        item?.geo_layer?._id
                      );
                    }
                    return (
                      <tr
                        className="cursor_pointer"
                        key={idx}
                        onClick={this.on_push_layer.bind(
                          this,
                          item?.geo_layer?._id
                        )}
                      >
                        <td>
                          <div
                            className={
                              is_open ? "checklist_active" : "checklist_not"
                            }
                          />
                        </td>
                        <td>{item?.geo_layer?.name}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </section>
            <button
              className="button_small background_green margin_top"
              onClick={this.on_continue}
            >
              {dict?.["Next"]?.[language]}
            </button>
          </section>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
  project: state.project,
});

export default connect(mapStateToProps, {
  edit_inside_object,
  delete_array_string_inside_object,
  push_array_inside_object,
  set_value_bi,
})(BI_ADD_LAYER_DATA);
