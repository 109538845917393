/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import ChartTemplate from "../viewer_chart/ChartTemplate";

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/

class TimeseriesBiop extends Component {
  state = {};

  render() {
    //local storage

    //local state

    //global props
    const { layer_id, geo_layer_list } = this.props.layer;

    //content
    const geo_layer =
      geo_layer_list.find((e) => e?.geo_layer?._id === layer_id)?.geo_layer ||
      {};
    const chart_js_data_biop = geo_layer?.chart_js_data_biop;

    let content;

    if (chart_js_data_biop) {
      content = (
        <main className="container_light margin_bottom">
          <h2 className="badge_pill" id="orange">
            BiOp Non Farebox
          </h2>
          <br />
          <div style={{ overflowX: "scroll" }}>
            <ChartTemplate
              type="bar"
              width={600}
              height={200}
              style={{ width: "600px" }}
              options={{
                hoverBackgroundColor: "#f38026",
                animation: false,
                maintainAspectRatio: false,
                responsive: false,
                legend: {
                  display: false,
                },
                indexAxis: "x",
                title: {
                  display: false,
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}
              data={chart_js_data_biop}
            />
          </div>
          <table className="table">
            <tbody>
              <tr>
                <td>y_max</td>
                <td>
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(chart_js_data_biop?.y_max)}
                </td>
              </tr>
              <tr>
                <td>x_max</td>
                <td>{chart_js_data_biop?.x_max}</td>
              </tr>
            </tbody>
          </table>
        </main>
      );
    }

    return <div>{content}</div>;
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
});

export default connect(mapStateToProps, {})(TimeseriesBiop);
