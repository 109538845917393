/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/
import { edit_inside_object, set_value_bi } from "../../App/actions/biActions";

/*PICTURE*/

/*FUNCTION*/

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class BI_ADD_STEP_0_TITLE extends Component {
  state = {};

  on_change_card_add_object = (e) => {
    const id_key = e.target.name;
    const value = e.target.value;
    this.props.edit_inside_object({
      key: "card_add_object",
      id_key: id_key,
      value: value,
    });
  };

  on_next_step = () => {
    const { add_card_current_step } = this.props.bi;
    this.props.set_value_bi({
      key: "add_card_current_step",
      value: add_card_current_step + 1,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const { card_add_object } = this.props.bi;

    //content
    const { name } = card_add_object;

    return (
      <main>
        <section className="container_light margin_bottom">
          <p className="text_medium">{dict?.["Title"]?.[language]}</p>
          <input
            type="text"
            value={name}
            name="name"
            onChange={this.on_change_card_add_object}
            placeholder={dict?.["Insert title"]?.[language]}
          />
        </section>
        {name ? (
          <button
            onClick={this.on_next_step}
            className="button background_blue margin_bottom"
          >
            {dict?.["Next step"]?.[language]}
          </button>
        ) : (
          <div>
            <button className="button background_grey margin_bottom">
              {dict?.["Next step"]?.[language]}
            </button>
            <br />
            <p className="text_small text_red">
              {dict?.["Write the title card first"]?.[language]}
            </p>
          </div>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
});

export default connect(mapStateToProps, {
  edit_inside_object,
  set_value_bi,
})(BI_ADD_STEP_0_TITLE);
