//16. Get list layer bulk
import axios from "axios";
import { snackbar } from "./snackbarActions";
// const SERVER_URL = "http://localhost:4006";
// const SERVER_URL = "https://server-beta-kim.kai.id";
const SERVER_URL = "https://server-kim.kai.id";

export const setLoadingProcess = (itemLoading) => {
  return {
    type: "SET_LOADING_PROCESS_LAYER",
    payload: itemLoading,
  };
};

export const clearLoading = () => {
  return {
    type: "CLEAR_LOADING_LAYER",
  };
};

export const getAssetNumber = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    dispatch(setLoadingProcess("Memuat Data RAM"));
    const res = await axios.get(
      SERVER_URL + `/ram/asset?nomoraset=${body}`,
      config
    );

    dispatch(clearLoading());
    return Promise.resolve(res.data);
  } catch (error) {
    dispatch(clearLoading());
    dispatch(
      snackbar({
        is_open: true,
        status: "error",
        message: error?.response?.data?.message || "Failed to get data",
      })
    );
  }
};
