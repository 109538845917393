import React, { Component } from "react";
import EditIcon from "../common_icon/EditIcon";

import Modal from "../common_modal/Modal";

import { connect } from "react-redux";
import { which_laporan_setup_will_be_active } from "./../../App/validation/all_about_client";
import SetupDownloadLaporanPdfCilicis from "../laporan_pdf/cilicis/SetupDownloadLaporanPdfCilicis";
import SetupDownloadLaporanPdfDanamas from "../laporan_pdf/danamas/SetupDownloadLaporanPdfDanamas";
import { getAllFields, getGeoLayer } from "../../App/reduxHelper/layer";

class ColumnSetupDownloadLaporanPdf extends Component {
  constructor(props) {
    super(props);
    this.laporan_ref = React.createRef();
    this.state = {
      fields: [],
      modal_setup_laporan: false,
      text_hover: false,
    };
  }

  componentDidMount = () => {
    this.getFields();
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { modal_setup_laporan } = this.state;
    if (prevState.modal_setup_laporan !== modal_setup_laporan) {
      this.getFields();
    }
  };

  toggleHover = (value) => {
    const { text_hover } = this.state;
    this.setState({
      text_hover: value || !text_hover,
    });
  };

  toggleModalSetupLaporan = (value) => {
    const { modal_setup_laporan } = this.state;
    this.setState({
      modal_setup_laporan: value || !modal_setup_laporan,
    });
  };

  getFields = () => {
    const { layer } = this.props;
    const { layer_id } = layer;
    const geo_layer = getGeoLayer(layer);
    const fields = getAllFields(geo_layer) || [];

    const fields_for_hide_purpose = fields?.filter(
      (f) => !f.isStyle && f.key !== "circle_color" && f.type !== "section"
    );

    this.setState({
      fields: fields_for_hide_purpose,
      geo_layer_id: layer_id,
    });
  };

  render() {
    const { text_hover, modal_setup_laporan, fields, layer_id } = this.state;

    const domain = window.location.hostname;

    const setupDownloadLaporanPdf = which_laporan_setup_will_be_active({
      SetupDownloadLaporanPdfCilicis,
      SetupDownloadLaporanPdfDanamas,
      domain,
      fields,
      layer_id,
    });

    const modal_setup_laporan_content = modal_setup_laporan && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_setup_laporan}
        onClose={this.toggleModalSetupLaporan}
      >
        <div className="box-body">
          <div>{setupDownloadLaporanPdf}</div>
        </div>
      </Modal>
    );

    return (
      <section
        className="header_table center_perfect"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "500px",
        }}
        onMouseEnter={() => {
          this.toggleHover(true);
        }}
        onMouseLeave={() => {
          this.toggleHover(false);
        }}
      >
        {text_hover && (
          <div
            className="center_perfect"
            style={{
              width: 50,
              paddingTop: 3,
              paddingBottom: 3,
              paddingLeft: 5,
              paddingRight: 5,
              borderRadius: 10,
            }}
          >
            <div className="flex gap_5">
              <div className="center_perfect">
                <button
                  onClick={(e) => {
                    this.toggleModalSetupLaporan();
                  }}
                  className="center_perfect"
                >
                  <EditIcon width={22} height={22} />
                </button>
              </div>
            </div>
          </div>
        )}

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            marginLeft: 5,
          }}
        >
          <strong
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              marginLeft: 5,
            }}
          >
            Laporan
          </strong>
        </div>

        {modal_setup_laporan_content}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
});

export default connect(mapStateToProps)(ColumnSetupDownloadLaporanPdf);
