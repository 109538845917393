import React, { useState, useRef } from "react";

const Draggable = (props) => {
  const {
    children,
    style,
    initialPosition = { x: 0, y: 0 },
    title,
    toggle_close,
  } = props;
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({
    x: initialPosition.x,
    y: initialPosition.y,
  });
  const dragRef = useRef(null);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    const initialX = e.clientX - dragRef.current.getBoundingClientRect().left;
    const initialY = e.clientY - dragRef.current.getBoundingClientRect().top;

    const handleMouseMove = (e) => {
      const newX = e.clientX - initialX;
      const newY = e.clientY - initialY;
      setPosition({ x: newX, y: newY });
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    const initialX =
      e.touches[0].clientX - dragRef.current.getBoundingClientRect().left;
    const initialY =
      e.touches[0].clientY - dragRef.current.getBoundingClientRect().top;

    const handleTouchMove = (e) => {
      const newX = e.touches[0].clientX - initialX;
      const newY = e.touches[0].clientY - initialY;
      setPosition({ x: newX, y: newY });
    };

    const handleTouchEnd = () => {
      setIsDragging(false);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleTouchEnd);
    };

    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchend", handleTouchEnd);
  };

  return (
    <div
      ref={dragRef}
      style={{
        zIndex: "999999",
        position: "fixed",
        left: position.x,
        top: position.y,
        userSelect: "none",
        borderRadius: "10px",
        ...style,
      }}
    >
      <div
        style={{
          cursor: isDragging ? "grabbing" : "grab",
        }}
        className="draggable-panel flex justify_between background_black"
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
      >
        {title}
        <button
          onClick={toggle_close}
          className="background_red w_20 h_20 text_white rounded_5"
        >
          x
        </button>
      </div>
      {children}
    </div>
  );
};

export default Draggable;
