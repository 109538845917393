/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import CheckBoxNew from "../common_input/CheckBoxNew";
import PreviewReferrenceData from "../common_field/PreviewReferrenceData";
import FormulaCard from "../common_field/FormulaCard";
import PreviewReferrenceData2D from "../common_field/PreviewReferrenceData2D";
import ConditionalStatementInput from "../common_field/ConditionalStatementInput";

/*REDUX FUNCTION*/
import { editFieldForm } from "../../App/actions/layerActions";

/*PICTURE ASSET*/
import referrence_2d_file from "../../Assets/file/[2d-referrence]-[comma-delimited-UTF8].csv";
import referrence_simple_file from "../../Assets/file/[simple-referrence]-[comma-delimited-UTF8].csv";

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";
import uuid from "../../App/validation/uuid";
import { data_types } from "../../App/validation/data_types";
import poi_list from "../../App/validation/poi_list";
import { handle_upload_csv } from "../../App/validation/handle_file";
import { check_formula_validity } from "../../App/validation/formula_validation";
import { simpleConvertFieldsToHeaders } from "../../App/validation/convert_data";
import { Dropdown } from "../reuseable";
import { getAllFieldsFromGeoLayerWoGeojson } from "../../App/reduxHelper/layer";
import is_equal_array from "../../App/validation/is_equal_array";
import TableFormula from "../common_field/TableFormula";
import DataTypesSelection from "../common_field/DataTypesSelection";

/*NON IMPORT*/
const WAIT_INTERVAL = 1000;

class SidebarForm extends Component {
  state = {
    field: {},
    operation_digit: "=",
    fields: [],
  };

  componentDidMount() {
    const { layer } = this.props;
    const fields = getAllFieldsFromGeoLayerWoGeojson(layer)?.filter(
      (item) => !item.isStyle
    ); // termasuk child
    this.setState({ field: this.props.field, fields });
  }

  componentDidUpdate(prev_props) {
    const { layer, field } = this.props;
    const fields = getAllFieldsFromGeoLayerWoGeojson(layer);
    const prevFields = getAllFieldsFromGeoLayerWoGeojson(prev_props?.layer);

    if (field?.uuid !== prev_props?.field?.uuid) {
      this.setState({ field: this?.props?.field });
    }

    if (!is_equal_array(fields, prevFields)) {
      const filtered_fields = fields?.filter((item) => !item.isStyle); // termasuk child
      this.setState({
        fields: filtered_fields,
      });
    }
  }

  handleFormula = (value) => {
    let field = this.state.field;
    const { type } = field;
    const is_formula_valid =
      check_formula_validity(value) === "valid" ? true : false;

    clearTimeout(this.timer);
    field.formula = value;
    this.setState({
      field: field,
    });

    // tidak akan tersimpan ketika formula masih salah
    if (
      (type === "math_operators" && is_formula_valid) ||
      type !== "math_operators"
    ) {
      this.timer = setTimeout(this.handle_save_field, WAIT_INTERVAL);
    }
  };

  handleConditionalStatementList = (value) => {
    let field = this.state.field;
    field["conditional_statement_list"] = value;
    this.setState(
      {
        field: field,
      },
      () => {
        setTimeout(this.handle_save_field, WAIT_INTERVAL);
      }
    );
  };

  handle_save_field = () => {
    const { field } = this.state;
    const body = {
      geo_layer_id: this?.props?.layer?.geo_layer_wo_geojson?.geo_layer?._id,
      field,
    };
    this.props.editFieldForm(body);
  };

  handle_toggle_field = (name) => {
    clearTimeout(this.timer);
    let field = this.state.field;
    field[name] = !field[name];
    this.setState({
      field,
    });
    this.timer = setTimeout(this.handle_save_field, WAIT_INTERVAL);
  };

  handle_change_field = (e) => {
    clearTimeout(this.timer);
    e.preventDefault();
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let field = this.state.field;
    if (["tag"].includes(name)) {
      const tagArray = value.split("\n");
      const array_templates = tagArray.map((value, idx) => {
        return { name: value, index: idx };
      });
      field[name] = value;
      field.array_templates = array_templates;
      field.defaultValue = "";
      this.setState({
        field,
      });
    } else {
      field[name] = value;
      this.setState({
        field,
      });
    }
    this.timer = setTimeout(this.handle_save_field, WAIT_INTERVAL);
  };

  handleDecimalInput = (e) => {
    clearTimeout(this.timer);
    if (
      /[0-9]/.test(e.nativeEvent.data) ||
      e.nativeEvent.inputType === "deleteContentBackward"
    ) {
      let field = this.state.field;
      field["decimal_digits"] = e.target.value;
      this.setState({
        field,
      });
      this.timer = setTimeout(this.handle_save_field, WAIT_INTERVAL);
    }
  };

  handle_value_template = (new_value) => {
    let field = this.state.field;
    field["value"] = field?.value !== new_value ? new_value : "";
    this.setState({
      field,
    });
    this.timer = setTimeout(this.handle_save_field, WAIT_INTERVAL);
  };

  handleCsvValidation = async (e) => {
    const field = this.state.field;
    const file = await handle_upload_csv(e);
    const headers = file?.headers?.map((field) => field.toUpperCase());
    let is_valid = "invalid";

    if (headers?.includes("NAME") && headers?.includes("VALUE")) {
      is_valid = "valid";
    }

    if (is_valid === "valid") {
      this.setState(
        {
          field: { ...field, reference_list: file.data },
        },
        () => {
          this.handle_save_field();
        }
      );
    }
  };

  handleCsvValidation2dReferring = async (e) => {
    let { field } = this.state;
    const { reference_list_2d } = field;
    const file = await handle_upload_csv(e);
    const headers = file?.headers;
    // ?.map((field) => field.toUpperCase());
    let is_valid = "invalid";

    if (headers?.includes("index_name")) {
      is_valid = "valid";
    }
    if (is_valid === "valid") {
      let new_result = reference_list_2d;
      new_result["headers"] = headers;
      new_result["features"] = file.data;
      field["reference_list_2d"] = new_result;

      this.setState(
        {
          field,
        },
        () => {
          this.handle_save_field();
        }
      );
    }
  };

  handleRowSelect = (value) => {
    let { field } = this.state;
    const { reference_list_2d } = field;
    let new_ref = reference_list_2d || {};
    new_ref["row_name"] = value;
    field["reference_list_2d"] = new_ref;
    this.setState(
      {
        field,
      },
      () => {
        this.handle_save_field();
      }
    );
  };

  set_table_formula = (table_formula) => {
    let { field } = this.state;
    field["table_formula"] = table_formula;
    this.setState(
      {
        field,
      },
      () => {
        setTimeout(this.handle_save_field, WAIT_INTERVAL);
      }
    );
  };

  handleColumnSelect = (value) => {
    let { field } = this.state;
    const { reference_list_2d } = field;
    let new_ref = reference_list_2d || {};
    new_ref["column_name"] = value;
    field["reference_list_2d"] = new_ref;
    this.setState(
      {
        field,
      },
      () => {
        this.handle_save_field();
      }
    );
  };

  handle_click_type = (value) => {
    clearTimeout(this.timer);
    let field = this.state.field;
    field.type = value;
    this.setState({ field });
    this.timer = setTimeout(this.handle_save_field, WAIT_INTERVAL);
  };

  on_change = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  toggle_limit_digit = () => {
    this.setState({ modal_limit_digit: !this.state.modal_limit_digit });
  };

  submit_add_limit_digit = () => {
    let { field, operation_digit, number_digit } = this.state;

    let digit_term = field?.digit_term ? field.digit_term : [];
    const item = {
      uuid: uuid(),
      operation: operation_digit,
      number: number_digit,
    };
    digit_term.push(item);
    field.digit_term = digit_term;
    const body = {
      geo_layer_id: this?.props?.layer?.geo_layer_wo_geojson?.geo_layer?._id,
      field,
    };
    this.props.editFieldForm(body);
    this.setState({ modal_limit_digit: false });
  };

  isDataTypeCalculateDimensionAndLayerTypeIsPoint = (dataType, layerType) => {
    if (layerType === "Point" && dataType === "calculate_dimension") {
      return true;
    } else return false;
  };

  handleDataTypeOnClick = (dataType, layerType) => {
    if (
      !this.isDataTypeCalculateDimensionAndLayerTypeIsPoint(dataType, layerType)
    ) {
      let field = this.state.field;
      field.type = dataType;
      this.setState({ field }, () => {
        this.handle_save_field();
      });
    }
  };

  submit_delete_limit_digit = (uuid_item) => {
    let { field } = this.state;
    let digit_term = field?.digit_term ? field.digit_term : [];

    digit_term = digit_term.filter((item) => item.uuid !== uuid_item);
    field.digit_term = digit_term;
    const body = {
      geo_layer_id: this?.props?.layer?.geo_layer_wo_geojson?.geo_layer?._id,
      field,
    };
    this.props.editFieldForm(body);
  };

  on_input_click = (event) => {
    event.target.value = "";
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { field, modal_limit_digit, operation_digit, number_digit, fields } =
      this.state;

    //global props

    //content
    const {
      reference_list,
      reference_key,
      formula,
      reference_list_2d,
      decimal_digits,
      conditional_statement_list,
      table_formula,
    } = field;

    const geometry_type =
      this.props.layer?.geo_layer_wo_geojson?.geo_layer?.type;
    let sidebar_content = null;

    if (field?.uuid) {
      sidebar_content = (
        <main>
          <div>
            <section
              className="container_flat"
              style={{ marginBottom: "15px" }}
            >
              <div className="text_bold">{dict["Name"][language]}</div>
              <input
                type="text"
                name="name"
                id="name"
                value={field.name}
                onChange={this.handle_change_field}
              />
              <CheckBoxNew
                text="isRequired"
                title={dict["Required to fill by respondent"][language]}
                value={!!field.isRequired}
                handle={this.handle_toggle_field.bind(this, "isRequired")}
              />
            </section>
            <section
              className="container_flat"
              style={{ marginBottom: "15px" }}
            >
              <div className="text_bold">{dict["Description"][language]}</div>
              <textarea
                rows="4"
                type="text"
                name="description"
                id="description"
                placeholder={dict["Describe this question here"][language]}
                value={field.description}
                onChange={this.handle_change_field}
              />
            </section>
            <section
              className="container_flat"
              style={{ marginBottom: "15px" }}
            >
              <div className="text_bold">{dict["Type data"][language]}</div>
              <div>
                <DataTypesSelection
                  field={field}
                  geo_layer_type={geometry_type}
                  language={language}
                  data_type={data_types(language)}
                  handleDataTypeOnClick={this.handleDataTypeOnClick}
                  isDataTypeCalculateDimensionAndLayerTypeIsPoint={
                    this.isDataTypeCalculateDimensionAndLayerTypeIsPoint
                  }
                />
              </div>
              {/* <div style={{ textAlign: "left" }}>
                {data_types(language).map(({ name, value, icon }) => {
                  let backgroundColor = "#fff";
                  if (value === field.type) {
                    backgroundColor = "#c0e4ff";
                  }
                  return (
                    <div
                      onClick={this.handle_click_type.bind(this, value)}
                      key={value}
                      style={{
                        cursor: "pointer",
                        display: "inline-block",
                        textAlign: "center",
                        width: "70px",
                        verticalAlign: "top",
                        backgroundColor,
                        margin: "5px 10px 5px 10px",
                        padding: "3px",
                        borderRadius: "10px",
                      }}
                    >
                      <img
                        src={icon}
                        alt={name}
                        style={{
                          width: "20px",
                          height: "20px",
                          margin: "auto",
                        }}
                      />
                      <div style={{ fontSize: "11px", marginTop: "5px" }}>
                        {name}
                      </div>
                    </div>
                  );
                })}
              </div> */}
              {field.type === "counting_poi" && (
                <section>
                  <br />
                  <p className="text_bold">
                    Pilih kategori POI untuk dihitung jumlahnya
                  </p>
                  <select
                    id="poi_type"
                    name="poi_type"
                    value={field.poi_type}
                    onChange={this.handle_change_field}
                  >
                    {poi_list.map(({ name, value }, idx) => {
                      return (
                        <option key={idx} value={value}>
                          {name}
                        </option>
                      );
                    })}
                  </select>
                  <br />
                  <br />
                  <p className="text_bold">
                    Atur radius POI dari titik surveyor (km)
                  </p>
                  <input
                    id="poi_rad"
                    name="poi_rad"
                    value={field?.poi_rad}
                    type="number"
                    onChange={this.handle_change_field}
                    className="full_width"
                  />
                  {field?.poi_rad < 1 && (
                    <section>
                      {field?.poi_rad} km = {field?.poi_rad * 1000} meter
                    </section>
                  )}
                </section>
              )}
              {field.type === "range" && (
                <section>
                  <div className="text_bold">
                    {dict["Minimum value"][language]}
                  </div>
                  <input
                    type="number"
                    name="min"
                    id="min"
                    placeholder={dict["Minimum value"][language]}
                    value={field.min}
                    onChange={this.handle_change_field}
                  />
                  <div className="text_bold">
                    {dict["Maximum value"][language]}
                  </div>
                  <input
                    type="number"
                    name="max"
                    id="max"
                    placeholder={dict["Maximum value"][language]}
                    value={field.max}
                    onChange={this.handle_change_field}
                  />
                  <div className="text_bold">
                    {dict["Step value"][language]}
                  </div>
                  <input
                    type="number"
                    name="step"
                    id="step"
                    placeholder={dict["Step value"][language]}
                    value={field.step ? field.step : ""}
                    onChange={this.handle_change_field}
                  />
                </section>
              )}

              {field.type === "simple_referring" && (
                <main className="container_flat marginBottom_15">
                  <div className="flex gap_10 paddingTop_10">
                    <a
                      className="bg_blue pointer rounded_5 font_14 h_35 w_200 text_white hover_darkBlue"
                      href={referrence_simple_file}
                    >
                      <div className="center_perfect w_full h_full">
                        Template CSV Download
                      </div>
                    </a>

                    <label className="inline">
                      <div
                        className={`center_perfect pointer rounded_5 text_center h_35 text_white bg_blue`}
                      >
                        {"Upload CSV"}
                      </div>
                      <input
                        style={{ display: "none" }}
                        type="file"
                        accept=".csv*"
                        onChange={this.handleCsvValidation}
                        onClick={this.on_input_click}
                      />
                    </label>
                    {reference_list?.length > 0 && (
                      <PreviewReferrenceData
                        field={field}
                        data={reference_list}
                        layer_id={
                          this?.props?.layer?.geo_layer_wo_geojson?.geo_layer
                            ?._id
                        }
                      />
                    )}
                  </div>
                  <div className="paddingTop_10">
                    <label htmlFor="refer_to_column" className="font_18">
                      Referred by
                    </label>
                    <select
                      id="reference_key"
                      name="reference_key"
                      value={reference_key}
                      onChange={this.handle_change_field}
                    >
                      <option key="" value="">
                        Select Column
                      </option>
                      {fields
                        ?.filter((header) => header.key !== field.key)
                        .map((header) => {
                          return (
                            <option key={header.key} value={header.key}>
                              {header.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </main>
              )}
            </section>

            {field.type === "2d_referring" && (
              <main className="container_flat">
                <div>
                  <label className="font_18 w_full">2D Referring</label>
                  <div className="flex gap_10 paddingTop_10">
                    <a
                      className="bg_blue pointer rounded_5 font_14 h_35 w_200 text_white hover_darkBlue"
                      href={referrence_2d_file}
                    >
                      <div className="center_perfect w_full h_full">
                        Template CSV Download
                      </div>
                    </a>

                    <label className="inline">
                      <div
                        className={`center_perfect pointer rounded_5 font_14 h_35 text_white bg_blue hover_darkBlue`}
                      >
                        {dict["Upload CSV"][language]}
                      </div>
                      <input
                        style={{ display: "none" }}
                        type="file"
                        accept=".csv*"
                        onChange={this.handleCsvValidation2dReferring}
                        onClick={this.on_input_click}
                      />
                    </label>
                    {reference_list_2d?.features?.length > 0 && (
                      <PreviewReferrenceData2D data={reference_list_2d} />
                    )}
                  </div>
                  <div className="two-container">
                    <div className="paddingTop_10 grid_child">
                      <label className="font_14 w_full">
                        {
                          dict[
                            "Select column name from main table that will refer as a row in secondary table"
                          ][language]
                        }
                      </label>
                      <Dropdown
                        className="w_100"
                        placeholder={"Pilih"}
                        filter_on
                        is_enable_empty
                        value={reference_list_2d?.["row_name"]}
                        onChange={(value) => {
                          this.handleRowSelect(value);
                        }}
                      >
                        {fields?.map((header) => {
                          return (
                            <option key={header.key} value={header.key}>
                              {header.name}
                            </option>
                          );
                        })}
                      </Dropdown>

                      {/* <select
                        id="reference_list"
                        name="reference_list"
                        value={reference_list_2d?.["row_name"]}
                        onChange={(e) => this.handleRowSelect(e)}
                      >
                        <option key="" value="">
                          {dict["Select column"][language]}
                        </option>
                        {headers?.map((header) => {
                          return (
                            <option key={header.key} value={header.key}>
                              {header.name}
                            </option>
                          );
                        })}
                      </select> */}
                    </div>
                    <div className="paddingTop_10 grid_child">
                      <label className="font_14 w_full">
                        {
                          dict[
                            "Select column name from main table that will refer as a column in secondary table"
                          ][language]
                        }
                      </label>
                      <Dropdown
                        className="w_100"
                        placeholder={"Pilih"}
                        filter_on
                        is_enable_empty
                        value={reference_list_2d?.["column_name"]}
                        onChange={(value) => {
                          this.handleColumnSelect(value);
                        }}
                      >
                        {fields?.map((header) => {
                          return (
                            <option key={header.key} value={header.key}>
                              {header.name}
                            </option>
                          );
                        })}
                      </Dropdown>
                      {/* <select
                        id="reference_list"
                        name="reference_list"
                        value={reference_list_2d?.["column_name"]}
                        onChange={(e) => this.handleColumnSelect(e)}
                      >
                        <option key="" value="">
                          {dict["Select column"][language]}
                        </option>
                        {fields?.map((header) => {
                          return (
                            <option key={header.key} value={header.key}>
                              {header.name}
                            </option>
                          );
                        })}
                      </select> */}
                    </div>
                  </div>
                </div>
              </main>
            )}

            {field.type === "math_operators" && (
              <main className="container_flat paddingTop_10 marginBottom_15">
                <FormulaCard
                  formula={formula}
                  headers={simpleConvertFieldsToHeaders(fields)}
                  handleFormula={this.handleFormula}
                />
                <div>
                  <label className="font_18 w_full">Decimal</label>
                  <div>
                    <label className="w_full">Insert a decimal point</label>
                    <div>
                      <label>Places:</label>
                      <input
                        type="number"
                        required={true}
                        onChange={this.handleDecimalInput}
                        value={decimal_digits}
                      />
                    </div>
                  </div>
                </div>
              </main>
            )}

            {field.type === "conditional_statement" && (
              <main className="container_flat paddingTop_10 marginBottom_15">
                <ConditionalStatementInput
                  language={language}
                  headers={simpleConvertFieldsToHeaders(fields)}
                  handleConditionalStatementList={
                    this.handleConditionalStatementList
                  }
                  conditional_statement_list={conditional_statement_list}
                  is_sidebar
                />
              </main>
            )}

            {field.type === "table_formula" && (
              <main className="container_flat paddingTop_10 marginBottom_15">
                <TableFormula
                  language={language}
                  table_formula={table_formula}
                  set_table_formula={this.set_table_formula}
                  from="sidebar"
                />
              </main>
            )}

            <main className="container_flat" style={{ marginBottom: "15px" }}>
              <section className="text_bold">
                {dict["Value template"][language]}
              </section>
              <CheckBoxNew
                text="isAllowOtherAnswer"
                title={dict["Allow other answers"][language]}
                value={!!field.isAllowOtherAnswer}
                handle={this.handle_toggle_field.bind(
                  this,
                  "isAllowOtherAnswer"
                )}
              />
              <textarea
                type="text"
                name="tag"
                id="tag"
                autoComplete="off"
                value={field.tag ? field.tag : ""}
                onChange={this.handle_change_field}
                rows="5"
              />
              <div className="input_note">
                {
                  dict[
                    "Fill in if you want to provide several value options for attribute input (in MAPID FORM). Separate between values ​​with next values ​​with enter."
                  ][language]
                }
              </div>
              <div className="flex gap_5 marginTop_10">
                {(field.array_templates || []).map((content, idx) => {
                  const is_active = field.value === content.name;
                  // return (
                  //   <main key={idx}>
                  //     <div className="badge_normal" id="white">
                  //       {content.name}
                  //     </div>
                  //     <br />
                  //   </main>
                  // );
                  return (
                    <>
                      {content.name !== "" && (
                        <main key={idx}>
                          <div
                            onClick={() =>
                              this.handle_value_template(content.name)
                            }
                            style={{
                              border: "1px solid #b4b6b8",
                            }}
                            className={`h_20 w_fit padding_5 rounded_5 pointer noselect ${
                              is_active ? "button_active" : "button_inactive"
                            }`}
                          >
                            {content.name}
                          </div>
                          <br />
                        </main>
                      )}
                    </>
                  );
                })}
              </div>
            </main>
            <main className="container_flat" style={{ marginBottom: "15px" }}>
              <section className="text_bold">
                {dict["Limit digit"][language]}
              </section>
              <button
                className="button background_blue"
                onClick={this.toggle_limit_digit}
              >
                {dict["Add Digit Limit"][language]}
              </button>
              {modal_limit_digit && (
                <section>
                  <table style={{ width: "100%", tableLayout: "fixed" }}>
                    <tbody>
                      <tr>
                        <td>
                          <select
                            id="operation_digit"
                            name="operation_digit"
                            value={operation_digit}
                            onChange={this.on_change}
                          >
                            <option value="===">{"="}</option>
                            <option value=">">{">"}</option>
                            <option value="<">{"<"}</option>
                            <option value=">=">{">="}</option>
                            <option value="<=">{"<="}</option>
                          </select>
                        </td>
                        <td>
                          <input
                            type="number"
                            name="number_digit"
                            id="number_digit"
                            value={number_digit}
                            onChange={this.on_change}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <button
                    className="button background_blue"
                    onClick={this.submit_add_limit_digit}
                  >
                    Submit
                  </button>
                </section>
              )}
              <table
                style={{
                  width: "100%",
                  tableLayout: "fixed",
                  textAlign: "center",
                }}
                className="table_lite"
              >
                <thead>
                  <tr>
                    <th>{dict?.["Operation"]?.[language]}</th>
                    <th>{dict?.["Number"]?.[language]}</th>
                    <th>{dict?.["Delete"]?.[language]}</th>
                  </tr>
                </thead>
                <tbody>
                  {(field?.digit_term || []).map((content, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{content?.operation}</td>
                        <td>{content?.number}</td>
                        <td>
                          <button
                            className="button_small background_blue"
                            id="red"
                            onClick={this.submit_delete_limit_digit.bind(
                              this,
                              content?.uuid
                            )}
                          >
                            x
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </main>
            <div className="container_flat" style={{ marginBottom: "15px" }}>
              <h1 className="text_bold">{dict?.["Others"]?.[language]}</h1>
              <CheckBoxNew
                text="isHide"
                title={dict["Hide attribute"][language]}
                value={field.isHide}
                handle={this.handle_toggle_field.bind(this, "isHide")}
              />
              <CheckBoxNew
                text="isHighlight"
                title={dict["Hightlight attribute"][language]}
                value={field.isHighlight}
                handle={this.handle_toggle_field.bind(this, "isHighlight")}
              />
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
        </main>
      );
    }

    return sidebar_content;
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
});

export default connect(mapStateToProps, { editFieldForm })(SidebarForm);
