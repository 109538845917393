/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import CHART_TEMPLATE from "../viewer_chart/CHART_TEMPLATE";
import GET_CAPEX from "../trivial_components/GET_CAPEX";
import Modal from "../common_modal/Modal";

/*REDUX*/
import { replace_features } from "../../App/actions/siniActions";

/*PICTURE*/

/*FUNCTION*/
import { calculateWithoutConvert } from "../../App/validation/convert_data";

/*DATA*/

/*CONST*/

class CUSTOM_CHART extends Component {
  state = {
    modal_npv: false,
    modal_irr: false,
  };

  toggle_npv = () => {
    this.setState({ modal_npv: !this.state.modal_npv });
  };

  toggle_irr = () => {
    this.setState({ modal_irr: !this.state.modal_irr });
  };

  calculate_local = (source) => {
    const { layer_id, geo_layer_list } = this.props.layer;
    const { capex_value } = this.props.properties;
    const layer_object = geo_layer_list.find(
      (item) => item?.geo_layer?._id === layer_id
    )?.geo_layer;
    const features = layer_object?.geojson?.features;
    const fields = layer_object?.fields;
    if (features && fields && capex_value && layer_id) {
      const features_new = calculateWithoutConvert({
        features,
        fields,
        source,
        capex_value,
      });
      this.props.replace_features({
        geo_layer_id: layer_id,
        features: features_new,
      });
    }
  };

  componentDidMount() {
    this.calculate_local("componentDidMount");
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.properties.capex_value !== prevProps.properties.capex_value ||
      this.props.layer.layer_id !== prevProps.layer.layer_id
    ) {
      this.calculate_local("componentDidUpdate");
    }
  }

  render() {
    //local storage

    //local state
    const { modal_irr, modal_npv } = this.state;

    //global props
    const { layer_id, geo_layer_list } = this.props.layer;
    const { capex_value } = this.props.properties;

    //content
    const layer_object = geo_layer_list.find(
      (item) => item?.geo_layer?._id === layer_id
    )?.geo_layer;
    let chart_list = layer_object?.chart_list || [];
    const features = layer_object?.geojson?.features || [];

    let irr_value = 0;
    let npv_value = 0;
    let npv_array = [];

    let npv_info;
    let irr_info;

    chart_list = chart_list.map((item) => {
      const {
        ina,
        x_axis_field_key,
        y_axis_field_key,
        x_element_custom_order,
        color_default,
        is_npv_kai,
        wacc_field_key,

        is_use_y_axis,
        is_use_custom_order,
      } = item;

      //generate sumbu x
      let labels = [];

      if (is_use_custom_order) {
        labels = x_element_custom_order;
      } else {
        labels = features.map((feature) => {
          return feature?.properties?.[x_axis_field_key];
        });
        labels = labels.sort();
      }

      //generate sumbu y
      let data = [];

      if (is_use_y_axis) {
        features.forEach((feature) => {
          const data_index = labels.findIndex(
            (label) =>
              String(label) === String(feature.properties[x_axis_field_key])
          );
          const init_value = data?.[data_index] || 0;
          let final_value = isNaN(feature.properties[y_axis_field_key])
            ? 0
            : parseInt(feature.properties[y_axis_field_key]);
          if (init_value) {
            final_value = init_value + final_value;
          }
          data[data_index] = final_value;
        });
      } else {
        features.forEach((feature) => {
          const data_index = labels.findIndex(
            (label) =>
              String(label) === String(feature.properties[x_axis_field_key])
          );
          const init_value = data?.[data_index] || 0;
          let final_value = 1;
          if (init_value) {
            final_value = init_value + final_value;
          }
          data[data_index] = final_value;
        });
      }

      if (is_npv_kai) {
        //perhitungan npv
        function create_npv_function_from_array(array_income, array_wacc) {
          return function y() {
            let result = 0;
            for (let i = 0; i < array_income.length; i++) {
              result += array_income[i] / Math.pow(1 + array_wacc[i], i + 1);
            }
            return result;
          };
        }
        const array_income = data;
        const array_wacc = features.map(
          (item) => parseFloat(item?.properties?.[wacc_field_key]) / 100
        );
        const y_function = create_npv_function_from_array(
          array_income,
          array_wacc
        );
        npv_value = parseInt(y_function());

        const wacc = (
          parseFloat(features?.[0]?.properties?.[wacc_field_key]) / 100
        ).toFixed(4);

        npv_info = (
          <main>
            <h1 className="text_header margin_bottom">Rumus perhitungan NPV</h1>

            <table
              style={{ backgroundColor: "#ffffff", borderRadius: "7px" }}
              className="table_padding"
            >
              <tbody>
                <tr>
                  <td>NPV</td>
                  <td style={{ paddingBottom: "7px" }}>=</td>
                  <td>
                    {x_element_custom_order.map((year, idx) => {
                      return (
                        <table key={idx} className="inline">
                          <tbody className="text_center">
                            <tr>
                              <td
                                style={{
                                  borderBottom: "1px solid black",
                                }}
                              >
                                Net cash flow {year}
                              </td>
                              <td
                                rowSpan={2}
                                style={{
                                  verticalAlign: "center",
                                  paddingBottom: "7px",
                                }}
                              >
                                {idx !== x_element_custom_order.length - 1 &&
                                  " + "}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                ( 1 + WACC) <sup>{idx + 1}</sup>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      );
                    })}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td style={{ paddingBottom: "7px" }}>=</td>
                  <td>
                    {data.map((value, idx) => {
                      return (
                        <table key={idx} className="inline">
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  borderBottom: "1px solid black",
                                }}
                              >
                                {new Intl.NumberFormat("id-ID", {
                                  style: "decimal",
                                }).format(value)}
                              </td>
                              <td
                                rowSpan={2}
                                style={{
                                  verticalAlign: "center",
                                  paddingBottom: "7px",
                                }}
                              >
                                {idx !== data.length - 1 && " + "}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                ( 1 + {wacc}) <sup>{idx + 1}</sup>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      );
                    })}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>=</td>
                  <td>
                    {new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    }).format(npv_value)}
                  </td>
                </tr>
              </tbody>
            </table>
            <section className="container_light">
              <p className="text_small">
                Catatan: Net cash flow pada tahun pertama menggunakan nilai
                negatif dari CAPEX penataan, jika nilai CAPEX belum dihitung
                maka perhitungan NPV belum bisa dilakukan dan nilainya menjadi
                tidak sesuai.
              </p>
            </section>
          </main>
        );

        //perhitungan irr_value
        function create_y_function(array_income) {
          return function y(x) {
            let result = 0;
            for (let i = 0; i < array_income.length; i++) {
              result += array_income[i] / Math.pow(1 + x, i + 1);
            }
            return result;
          };
        }

        const y = create_y_function(array_income);

        function find_root_recursive(a, b, tolerance) {
          // Base case: If the interval is already small enough, return the midpoint
          if (b - a < tolerance) {
            return (a + b) / 2;
          }

          let mid = (a + b) / 2;
          let f_mid = y(mid);

          if (f_mid === 0) {
            return mid; // Found the root
          } else if (f_mid * y(a) < 0) {
            // The root is in the left half
            return find_root_recursive(a, mid, tolerance);
          } else {
            // The root is in the right half
            return find_root_recursive(mid, b, tolerance);
          }
        }

        irr_value = find_root_recursive(-1000, 1000, 1e-10);

        irr_info = (
          <main>
            <h1 className="text_header margin_bottom">Rumus perhitungan IRR</h1>
            <p className="text_small">
              Rumus IRR diambil menggunakan rumus NPV dengan menghitung WACC
              ketika NPV sama dengan nol.
            </p>

            <table
              style={{ backgroundColor: "#ffffff", borderRadius: "7px" }}
              className="table_padding"
            >
              <tbody>
                <tr>
                  <td>NPV</td>
                  <td style={{ paddingBottom: "7px" }}>=</td>
                  <td>
                    {x_element_custom_order.map((year, idx) => {
                      return (
                        <table key={idx} className="inline">
                          <tbody className="text_center">
                            <tr>
                              <td
                                style={{
                                  borderBottom: "1px solid black",
                                }}
                              >
                                Net cash flow {year}
                              </td>
                              <td
                                rowSpan={2}
                                style={{
                                  verticalAlign: "center",
                                  paddingBottom: "7px",
                                }}
                              >
                                {idx !== x_element_custom_order.length - 1 &&
                                  " + "}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                ( 1 + WACC) <sup>{idx + 1}</sup>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      );
                    })}
                  </td>
                </tr>
                <tr>
                  <td>0</td>
                  <td style={{ paddingBottom: "7px" }}>=</td>
                  <td>
                    {data.map((value, idx) => {
                      return (
                        <table key={idx} className="inline">
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  borderBottom: "1px solid black",
                                }}
                              >
                                {new Intl.NumberFormat("id-ID", {
                                  style: "decimal",
                                }).format(value)}
                              </td>
                              <td
                                rowSpan={2}
                                style={{
                                  verticalAlign: "center",
                                  paddingBottom: "7px",
                                }}
                              >
                                {idx !== data.length - 1 && " + "}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                ( 1 + IRR) <sup>{idx + 1}</sup>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      );
                    })}
                  </td>
                </tr>
                <tr>
                  <td>IRR</td>
                  <td>≈</td>
                  <td>{irr_value}</td>
                </tr>
              </tbody>
            </table>
            <section className="container_light">
              <p className="text_small">
                Catatan: Pada kasus ini nilai IRR adalah pendekatan dari solusi
                persamaan polinomial orde 6 (tergantung rentang jumlah tahun),
                angka IRR yang dihasilkan mungkin tidak menghasilkan NPV yang
                eksak sama dengan 0.
              </p>
            </section>
          </main>
        );
      }
      const chart_js_data = {
        labels,
        // y_max,
        // x_max,
        // index_max,
        datasets: [
          {
            label: ina,
            labels,
            data,
            backgroundColor: color_default, //warna dark_blue KAI
          },
        ],
      };
      item.chart_js_data = chart_js_data;
      item.npv_value = npv_value;
      item.npv_array = npv_array;
      return item;
    });

    const modal_npv_content = modal_npv && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={modal_npv}
        onClose={this.toggle_npv}
      >
        <div className="box-body">
          <main className="max_height">{npv_info}</main>
        </div>
      </Modal>
    );

    const modal_irr_content = modal_irr && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={modal_irr}
        onClose={this.toggle_irr}
      >
        <div className="box-body">
          <main className="max_height">{irr_info}</main>
        </div>
      </Modal>
    );

    return (
      <main>
        {modal_npv_content}
        {modal_irr_content}
        {chart_list.map((item, idx) => {
          const { is_npv_kai, npv_value, x_axis_field_key } = item;
          return (
            <section key={idx}>
              <section className="container_light margin_bottom">
                <h2 className="badge_pill background_orange margin_bottom">
                  {item.ina}
                </h2>
                <br />
                <div
                  style={{
                    overflowX: item.chart_type === "pie" ? "hidden" : "scroll",
                  }}
                >
                  <CHART_TEMPLATE
                    type={item.chart_type}
                    height={200}
                    style={{
                      width: item.chart_type === "pie" ? "200px" : "100%",
                      margin: "auto",
                    }}
                    options={{
                      hoverBackgroundColor: item.color_hover,
                      animation: false,
                      maintainAspectRatio: false,
                      responsive: false,
                      legend: {
                        display: false,
                      },
                      indexAxis: "x",
                      title: {
                        display: false,
                      },
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                    data={item.chart_js_data}
                    use_legend={item.chart_type === "pie" ? true : false}
                    use_generate_array_color={true}
                    field_key={x_axis_field_key}
                  />
                </div>
              </section>
              {is_npv_kai && (
                <>
                  <section className="container_light margin_bottom">
                    <section>
                      <h2 className="badge_pill background_orange inline margin_right">
                        PERHITUNGAN NPV
                      </h2>
                      <div
                        className="badge_pill_small background_black inline cursor_pointer"
                        onClick={this.toggle_npv}
                      >
                        i
                      </div>
                    </section>

                    <br />
                    <p className="text_bold">
                      {new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(npv_value)}
                    </p>
                    {!capex_value && (
                      <section className="container_light background_red">
                        <p className="text_small margin_bottom">
                          Nilai CAPEX belum dimuat, nilai NPV & IRR belum final,
                          klik tombol di bawah untuk memuat nilai CAPEX terlebih
                          dahulu.
                        </p>
                        <GET_CAPEX />
                      </section>
                    )}
                  </section>
                  <section className="container_light margin_bottom">
                    <section>
                      <h2 className="badge_pill background_orange inline margin_right">
                        PERHITUNGAN IRR
                      </h2>
                      <div
                        className="badge_pill_small background_black inline cursor_pointer"
                        onClick={this.toggle_irr}
                      >
                        i
                      </div>
                    </section>

                    <br />
                    <h1 className="text_bold">{irr_value}</h1>
                  </section>
                </>
              )}
            </section>
          );
        })}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, { replace_features })(CUSTOM_CHART);
