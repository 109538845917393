// Component.jsx
/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_sini } from "../../App/actions/sini_v2";

/*PICTURE ASSET*/
import icon_expand_bottom from "../../Assets/svg/icon_expand_bottom.svg";

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class SINI_PEOPLE_SPENDING extends Component {
  state = {};

  componentDidMount() {
    const { sini_v2_list, request_id_active, ai_parent_detail_list } =
      this.props.sini;
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    const sini_object = merge_array.find(
      (item) => item.request_id === request_id_active
    );
    const properties = sini_object?.people_spending?.properties || {};
    if (properties !== undefined) {
      this.add_slide_in_class();
    }
  }

  componentDidUpdate(prevProps) {
    const loading_item_array_before =
      prevProps.loading_reducer.loading_item_array;
    const loading_item_array_after =
      this.props.loading_reducer.loading_item_array;
    const last_loading =
      loading_item_array_after[loading_item_array_after.length - 1];
    if (
      loading_item_array_after.length !== loading_item_array_before.length &&
      (last_loading === "people_spending" ||
        last_loading === "get_ai_parent_object")
    ) {
      this.add_slide_in_class();
    }
  }

  add_slide_in_class = () => {
    const element = document.querySelector("#people_spending_parent");
    if (element) {
      element.classList.add("slide-in");
    }
  };

  on_set_sini_menu_active = () => {
    this.props.set_value_sini({
      key: "sini_menu_active",
      value: "people_spending",
    });
    this.props.set_value_sini({
      key: "sini_map_show",
      value: ["demography_one", "demography_neighbor"],
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const { sini_v2_list, request_id_active, ai_parent_detail_list } =
      this.props.sini;
    const { loading_item_array } = this.props.loading_reducer;

    //content
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    const is_loading = loading_item_array.includes("people_spending");
    const sini_object = merge_array.find(
      (item) => item.request_id === request_id_active
    );
    const properties = sini_object?.people_spending?.properties;

    let content;
    if (is_loading || properties !== undefined) {
      const food_expanse = properties?.["MAKANAN"] || 0;
      const non_food_expanse = properties?.["NON MAKANAN"] || 0;
      content = (
        <main className="container_light background_white margin_bottom outline_green">
          <div
            className="badge background_green margin_bottom"
            onClick={this.on_set_sini_menu_active}
          >
            {dict?.["People spending"]?.[language]}
          </div>
          <br />
          {is_loading ? (
            <p className="text_small">
              {dict?.["Loading data"]?.[language]}...
            </p>
          ) : (
            <>
              <section className="margin_bottom">
                <span className="text_bold margin_right">
                  Rp
                  {new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
                    parseInt(food_expanse)
                  )}
                </span>
                <p className="text_small">makanan</p>
              </section>
              <section className="margin_bottom">
                <span className="text_bold margin_right">
                  Rp
                  {new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
                    parseInt(non_food_expanse)
                  )}
                </span>
                <p className="text_small">non makanan</p>
              </section>
            </>
          )}
          <div className="text_center">
            <img
              onClick={this.on_set_sini_menu_active}
              alt="expand"
              src={icon_expand_bottom}
              width={45}
              className="cursor_pointer"
              data-mapid="clickOpenSpending"
            />
          </div>
        </main>
      );
    }
    return <main id="people_spending_parent">{content}</main>;
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, { set_value_sini })(
  SINI_PEOPLE_SPENDING
);
