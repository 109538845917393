import is_not_empty from "../../../App/validation/is_not_empty";

export function createTd({ tr, text, tdClassName, pClassName, colspan }) {
  const td = tr.insertCell();
  const p = document.createElement("p");
  p.appendChild(document.createTextNode(is_not_empty(text) ? text : "-"));
  td.appendChild(p);
  if (tdClassName) {
    td.setAttribute("class", tdClassName);
  }
  if (colspan) {
    td.setAttribute("colspan", colspan);
  }
  if (pClassName) {
    p.setAttribute("class", pClassName);
  }
}
