// import { types } from "@babel/core";
// import gjv from "geojson-validation";

/** 
 * Keterangan:
 * 
 * Melakukan validasi apakah geojson sesuai dengan standard yang telah ditentukan
*/
export function geojson_validation(geojson) {
  const { filtered_features, ...validation_result } = check_parent(geojson);
  return { filtered_features, validation_result };
}

const check_parent = (geojson) => {
  const { features, type } = geojson;

  let geojson_check_result = {
    type: type,
    geometry_type: "",
    is_valid_in_general: false,
    error: "",
    invalid_features: [],
    valid_number: 0,
    invalid_number: 0,
    filtered_features: [],
    total_original_features: geojson?.features?.length || 0,
  };

  if (Array.isArray(features) && type === "FeatureCollection") {
    geojson_check_result["is_valid_in_general"] = true;
    if (features.length > 0) {
      geojson_check_result["geometry_type"] = features?.[0]?.geometry?.type
      features.forEach((feature, index) => {
        const { is_feature_valid, error } = checkChild(feature);
        if (is_feature_valid) {
          geojson_check_result["filtered_features"].push(feature);
          geojson_check_result["valid_number"] += 1;
        } else {
          geojson_check_result["invalid_features"].push({
            index,
            error,
          });
          geojson_check_result["invalid_number"] += 1;
        }
      });

      if (
        geojson_check_result["filtered_features"].length === 0 &&
        features.length > geojson_check_result["filtered_features"].length
      ) {
        geojson_check_result["is_valid_in_general"] = false;
        geojson_check_result["error"] = "No data passes the filter stage";
      } else if (
        geojson_check_result["filtered_features"].length === 0 &&
        features.length === geojson_check_result["filtered_features"].length
      ) {
        geojson_check_result["is_valid_in_general"] = false;
        geojson_check_result["error"] = "No data";
      }
    }
    return geojson_check_result;
  } else if (type === "Feature") {
    const { is_feature_valid, error } = checkChild(geojson);
    geojson_check_result["is_valid_in_general"] = is_feature_valid;
    geojson_check_result["error"] = error;
    return geojson_check_result;
  } else {
    geojson_check_result["is_valid_in_general"] = false;
    geojson_check_result["error"] = `Invalid type`;
    return geojson_check_result;
  }
};


const checkChild = (feature) => {
  const { properties, geometry } = feature || {};
  const feature_type = feature.type;
  if (feature_type === "Feature") {
    const { type } = geometry || {};
    if (typeof properties === "object") {
      if (type === "Point") {
        return point(geometry);
      } else if (type === "LineString") {
        return lineString(geometry);
      } else if (type === "Polygon") {
        return polygon(geometry);
      } else if (type === "MultiPoint") {
        return multiPoint(geometry);
      } else if (type === "MultiLineString") {
        return multiLineString(geometry);
      } else if (type === "MultiPolygon") {
        return multiPolygon(geometry);
      } else {
        return { is_feature_valid: false, error: "Invalid Geometry Type" };
      }
    } else {
      return { is_feature_valid: false, error: "Invalid Properties" };
    }
  } else {
    return { is_feature_valid: false, error: "Invalid Feature Type" };
  }
};

export function is_lng_lat_valid(lng, lat) {
  if (typeof lng === String || typeof lat === String) {
    if (lng.includes(",") || lat.includes(",")) {
      return false;
    }
  }
  lng = +lng;
  lat = +lat;
  if (lat > -90 && lat < 90 && lng > -180 && lng < 180) {
    return true;
  } else {
    return false;
  }
}

export const point = ({ type, coordinates }) => {
  try {
    // apakah coordinates berupa array
    if (!Array.isArray(coordinates)) {
      return {
        is_feature_valid: false,
        error: `Invalid ${type} coordinate data type`,
      };
    }

    // apakah koordinat terdiri dari 2 nilai (latitude dan longitude)?
    if (coordinates.length < 2) {
      return {
        is_feature_valid: false,
        error: `Invalid number of array inside the ${type} coordinates`,
      };
    }

    // apakah longitude dan latitudenya valid?
    const is_true = is_lng_lat_valid(coordinates?.[0], coordinates?.[1]);
    if (is_true) {
      return { is_feature_valid: true, error: "" };
    } else {
      return {
        is_feature_valid: false,
        error: `Invalid ${type} coordinates value (Only WGS84 / CGS in decimal separated by a dot are supported.)`,
      };
    }
  } catch (error) {
    // ketika error tidak terdeteksi
    return {
      is_feature_valid: false,
      error: `Invalid ${type} coordinates format`,
    };
  }
};

export const lineString = ({ type, coordinates }) => {
  try {
    if (!Array.isArray(coordinates)) {
      return {
        is_feature_valid: false,
        error: `Invalid ${type} coordinate data type`,
      };
    }
    if (coordinates.length < 2) {
      return {
        is_feature_valid: false,
        error: `Invalid number of array inside the ${type} coordinates`,
      };
    }

    const is_any_invalid_coordinates = coordinates?.some(
      (coordinate) => !is_lng_lat_valid(coordinate?.[0], coordinate?.[1])
    );

    if (is_any_invalid_coordinates) {
      // invalid
      return {
        is_feature_valid: false,
        error: `Invalid ${type} coordinates value (Only WGS84 / CGS in decimal separated by a dot are supported.)`,
      };
    } else {
      // valid
      return { is_feature_valid: true, error: "" };
    }
  } catch (error) {
    // invalid
    return {
      is_feature_valid: false,
      error: `Invalid ${type} coordinates format`,
    };
  }
};

export const polygon = ({ type, coordinates }) => {
  try {
    if (!Array.isArray(coordinates)) {
      return {
        is_feature_valid: false,
        error: `Invalid ${type} coordinate data type`,
      };
    }

    if (coordinates.length < 1) {
      return {
        is_feature_valid: false,
        error: `Invalid number of array inside the ${type} coordinates`,
      };
    }

    for (let lngLat of coordinates) {
      if (
        lngLat[0][0] !== lngLat[lngLat.length - 1][0] ||
        lngLat[0][1] !== lngLat[lngLat.length - 1][1]
      ) {
        return {
          is_feature_valid: false,
          error: `Invalid ${type} coordinates value, the first and the last coordinate must be same value`,
        };
      }

      // for (let row of dont_know) {
      //   for (let lngLat of row) {
      if (lngLat?.length < 4) {
        return {
          is_feature_valid: false,
          error: `${type} must be contain at least 4 coordinates`,
        };
      }

      let is_any_invalid_coordinates = lngLat?.some(
        (lng_lat) => !is_lng_lat_valid(lng_lat[0], lng_lat[1])
      );

      if (is_any_invalid_coordinates) {
        return {
          is_feature_valid: false,
          error: `Invalid ${type} coordinates value (Only WGS84 / CGS in decimal separated by a dot are supported.)`,
        };
      } else {
        return { is_feature_valid: true, error: "" };
      }
      // }
      // }
    }

    return { is_feature_valid: true, error: "" };
  } catch (error) {
    return {
      is_feature_valid: false,
      error: `Invalid ${type} coordinates format`,
    };
  }
};

const multiPoint = ({ type, coordinates }) => {
  try {
    if (!Array.isArray(coordinates)) {
      return {
        is_feature_valid: false,
        error: `Invalid ${type} coordinate data type`,
      };
    }
    if (coordinates.length < 1) {
      return {
        is_feature_valid: false,
        error: `Invalid number of array inside the ${type} coordinates`,
      };
    }

    const is_any_invalid_coordinates = coordinates?.some(
      (coordinate) => !is_lng_lat_valid(coordinate?.[0], coordinate?.[1])
    );

    if (is_any_invalid_coordinates) {
      // invalid
      return {
        is_feature_valid: false,
        error: `Invalid ${type} coordinates value (Only WGS84 / CGS in decimal separated by a dot are supported.)`,
      };
    } else {
      // valid
      return { is_feature_valid: true, error: "" };
    }
  } catch (error) {
    // invalid
    return {
      is_feature_valid: false,
      error: `Invalid ${type} coordinates format`,
    };
  }
};

const multiLineString = ({ type, coordinates }) => {
  try {
    if (!Array.isArray(coordinates)) {
      return {
        is_feature_valid: false,
        error: `Invalid ${type} coordinate data type`,
      };
    }

    if (coordinates.length < 1) {
      return {
        is_feature_valid: false,
        error: `Invalid number of array inside the ${type} coordinates`,
      };
    }

    coordinates?.forEach((lngLat) => {
      if (
        lngLat[0][0] !== lngLat[lngLat.length - 1][0] &&
        lngLat[0][1] === lngLat[lngLat.length - 1][1]
      ) {
        return {
          is_feature_valid: false,
          error: `Invalid ${type} coordinates value, the first and the last coordinate must be same value`,
        };
      }

      if (lngLat?.length < 4) {
        return {
          is_feature_valid: false,
          error: `${type} must be contain at least 4 coordinates`,
        };
      }

      let is_any_invalid_coordinates = lngLat?.some(
        (lng_lat) => !is_lng_lat_valid(lng_lat[0], lng_lat[1])
      );

      if (is_any_invalid_coordinates) {
        return {
          is_feature_valid: false,
          error: `Invalid ${type} coordinates value (Only WGS84 / CGS in decimal separated by a dot are supported.)`,
        };
      } else {
        return { is_feature_valid: true, error: "" };
      }
    });

    return { is_feature_valid: true, error: "" };
  } catch (error) {
    return {
      is_feature_valid: false,
      error: `Invalid ${type} coordinates format`,
    };
  }
};

const multiPolygon = ({ type, coordinates }) => {
  try {
    if (!Array.isArray(coordinates)) {
      return {
        is_feature_valid: false,
        error: `Invalid ${type} coordinate data type`,
      };
    }

    if (coordinates.length < 1) {
      return {
        is_feature_valid: false,
        error: `Invalid number of array inside the ${type} coordinates`,
      };
    }

    for (let dont_know_first of coordinates) {
      for (let lngLat of dont_know_first) {
        if (
          lngLat[0][0] !== lngLat[lngLat.length - 1][0] ||
          lngLat[0][1] !== lngLat[lngLat.length - 1][1]
        ) {
          return {
            is_feature_valid: false,
            error: `Invalid ${type} coordinates value, the first and the last coordinate must be same value`,
          };
        }

        // for (let row of dont_know) {
        //   for (let lngLat of row) {
        if (lngLat?.length < 4) {
          return {
            is_feature_valid: false,
            error: `${type} must be contain at least 4 coordinates`,
          };
        }

        let is_any_invalid_coordinates = lngLat?.some(
          (lng_lat) => !is_lng_lat_valid(lng_lat[0], lng_lat[1])
        );

        if (is_any_invalid_coordinates) {
          return {
            is_feature_valid: false,
            error: `Invalid ${type} coordinates value (Only WGS84 / CGS in decimal separated by a dot are supported.)`,
          };
        } else {
          return { is_feature_valid: true, error: "" };
        }
        //   }
        // }
      }
    }

    return { is_feature_valid: true, error: "" };
  } catch (error) {
    return {
      is_feature_valid: false,
      error: `Invalid ${type} coordinates format`,
    };
  }
};

export function check_array_coordinates(coordinates) {
  let invalid_array = [];
  coordinates.forEach((coordinate, index) => {
    const is_valid = is_lng_lat_valid(coordinate[0], coordinate[1]);
    if (!is_valid) invalid_array.push(index + 1);
  });
  return invalid_array;
}
