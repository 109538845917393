import React, { Component } from "react";
class DeleteIcon extends Component {
  render() {
    return (
      <svg
        id="icon_delete"
        version="1.1"
        viewBox="0 0 2.3171712 2.3166545"
        width="20px"
        height="20px"
      >
        <defs id="defs2" />

        <g transform="translate(-293.01629,-175.45625)" id="layer1">
          <g
            transform="matrix(0.39735388,0,0,0.39735388,178.62674,105.23104)"
            id="g869"
          />
          <g
            transform="matrix(0.26458333,0,0,0.26458333,299.63119,174.13775)"
            id="g941"
          />
          <g transform="translate(-0.01211312,-0.00814589)" id="g956" />
          <path
            id="delete_path"
            transform="matrix(0.26458333,0,0,0.26458333,299.80451,174.28785)"
            d="m -21.277344,4.4160156 a 4.3785827,4.3785827 0 0 0 -4.378906,4.3769532 4.3785827,4.3785827 0 0 0 4.378906,4.3789062 4.3785827,4.3785827 0 0 0 4.378906,-4.3789062 4.3785827,4.3785827 0 0 0 -4.378906,-4.3769532 z M -21.835938,6.34375 h 1.175782 c 0.329083,0 0.599609,0.2711684 0.599609,0.5996094 v 0.2792968 h 0.552735 a 0.18541686,0.18541686 0 0 1 0.185546,0.1894532 0.18541686,0.18541686 0 0 1 -0.185546,0.1855468 h -0.736329 a 0.18541689,0.18541689 0 0 0 -0.002,0.00195 0.18541689,0.18541689 0 0 1 -0.0039,-0.00195 h -1.998047 a 0.18541689,0.18541689 0 0 1 -0.0039,0.00195 0.18541689,0.18541689 0 0 1 -0.0039,-0.00195 h -0.796875 a 0.18541686,0.18541686 0 0 1 -0.185547,-0.1855468 0.18541686,0.18541686 0 0 1 0.185547,-0.1894532 h 0.617187 V 6.9433594 c 0,-0.3287811 0.271169,-0.5996094 0.599609,-0.5996094 z m 0,0.3691406 c -0.129259,0 -0.228515,0.100453 -0.228515,0.2304688 v 0.2792968 h 1.636719 V 6.9433594 c 0,-0.1292599 -0.0985,-0.2304688 -0.228516,-0.2304688 z m -0.708984,1.15625 h 2.542969 a 0.29669669,0.29669669 0 0 1 0.294922,0.296875 v 2.3222654 c 0,0.414879 -0.341019,0.75586 -0.75586,0.75586 h -1.625 c -0.414614,0 -0.751953,-0.343249 -0.751953,-0.75586 V 8.1660156 a 0.29669669,0.29669669 0 0 1 0.294922,-0.296875 z m 0.863281,0.8867188 a 0.18541678,0.18541678 0 0 0 -0.185547,0.1855468 v 1.2265628 a 0.18541678,0.18541678 0 0 0 0.185547,0.185547 0.18541678,0.18541678 0 0 0 0.185547,-0.185547 V 8.9414062 a 0.18541678,0.18541678 0 0 0 -0.185547,-0.1855468 z m 0.822266,0 a 0.18541678,0.18541678 0 0 0 -0.1875,0.1855468 v 1.2265628 a 0.18541678,0.18541678 0 0 0 0.1875,0.185547 0.18541678,0.18541678 0 0 0 0.185547,-0.185547 V 8.9414062 a 0.18541678,0.18541678 0 0 0 -0.185547,-0.1855468 z"
            style={{
              strokeWidth: 1.00157,
              strokeLinecap: "round",
              strokeLinejoin: "round",
            }}
          />
        </g>
      </svg>
    );
  }
}

export default DeleteIcon;
