/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import centroid from "@turf/centroid";
import ReactToPrint from "react-to-print";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import { timeout } from "workbox-core/_private";
import { CircularProgress } from "@mui/material";

/*COMPONENT*/
import Modal from "../common_modal/Modal";
import ProgressBar from "../common_spinner/ProgressBar";
import MARKDOWN_RENDERER from "../sini_v2_full/MARKDOWN_RENDERER";
import RANGKING_CHART from "./RANKING_CHART";
import PARENT_HBU_MANUAL from "./PARENT_HBU_MANUAL";

/*REDUX*/
import {
  get_ai_hbu_summary,
  get_ai_sheet_chat_list,
  get_ai_sini_sheet,
} from "../../App/actions/ai_excel";
import { push_ai_sheet_chat } from "../../App/actions/push_ai_sheet_chat";
import { generate_ai_hbu_summary } from "../../App/actions/generate_ai_hbu_summary";

/*PICTURE*/

/*FUNCTION*/
import uuid from "../../App/validation/uuid";
import MAP_EXCEL from "./MAP_EXCEL";
import PARENT_SINI from "../sini_v2/PARENT_SINI";
import sort_array from "../../App/validation/sort_array";
import { snackbar } from "../../App/actions/snackbarActions";
import TemplatePrint from "./TemplatePrint";
import { InsertChart } from "@material-ui/icons";

/*DATA*/
import hbu_list from "../../Data/hbu_list.csv";
import {
  get_value_cell,
  // getColRowIndex,
  numberToColumn,
} from "../../App/validation/calculateExcelFormula";

/*CONST*/
let hbu_list_json = [];
const csv_to_json = async () => {
  try {
    const response = await fetch(hbu_list);
    const reader = response.body.getReader();
    const { value } = await reader.read();
    const text = new TextDecoder("utf-8").decode(value);
    const rows = text.split("\n");
    const headers = rows[0].split(";");
    hbu_list_json = rows.slice(1).map((row) => {
      const values = row.split(";");
      return headers.reduce((acc, header, index) => {
        acc[header.trim()] = values[index] ? values[index].trim() : "";
        return acc;
      }, {});
    });
  } catch (error) {}
};
csv_to_json();
const to_id_format = (num_val) => {
  return new Intl.NumberFormat("id-ID", {
    style: "decimal",
  })?.format(Number(num_val));
};
const sanitize_string = (str) => str.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();

class EXCEL_HBU extends Component {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    this.state = {
      user_message: "",
      user_message_hbu: "",
      mode_capex: "manual", //excel_ai || manual
      is_chat_ai_show: false,
      modal_re_hbu: false,
      is_print: false,
      is_loading: false,
      is_timeout: false,
      modal_show_pedoman: false,
      modal_hbu_manual: false,
    };
  }

  componentDidMount() {
    //basic
    const { geo_layer_id, feature_key, field_key } = this.props;
    const params = {
      geo_layer_id,
      feature_key,
      field_key,
    };
    this.props.get_ai_hbu_summary(params);
    this.props.get_ai_sheet_chat_list(params);

    //advance
    const { feature_object_selected } = this.props.layer;
    const type = feature_object_selected?.geometry?.type;

    let long = 0;
    let lat = 0;

    if (type === "Point") {
      long = feature_object_selected?.geometry?.coordinates?.[0];
      lat = feature_object_selected?.geometry?.coordinates?.[1];
    } else {
      const geojson = {
        type: "FeatureCollection",
        features: [feature_object_selected],
      };
      const feature_center = centroid(geojson);
      long = feature_center?.geometry?.coordinates?.[0];
      lat = feature_center?.geometry?.coordinates?.[1];
    }

    const params_sini = {
      geo_layer_id,
      feature_key,
      field_key,
      long,
      lat,
      request_id: uuid(),
      is_from_excel: true,
      sini_data_mode: "sini_general",
      show_survey_1: true,
    };
    this.props.get_ai_sini_sheet(params_sini, "ai_hbu");
  }

  toggle_show_pedoman = () => {
    this.setState({ modal_show_pedoman: !this.state.modal_show_pedoman });
  };

  on_set_mode_capex = (mode_capex) => {
    this.setState({
      mode_capex,
    });
  };

  toggle_re_hbu = () => {
    this.setState({
      modal_re_hbu: !this.state.modal_re_hbu,
    });
  };

  on_change = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  };

  handle_refresh = () => {
    //basic
    const { geo_layer_id, feature_key, field_key } = this.props;
    const params = {
      geo_layer_id,
      feature_key,
      field_key,
    };
    this.setState({ is_loading: true }, async () => {
      await this.props.get_ai_hbu_summary(params);
      this.setState({
        is_loading: false,
      });
    });
  };

  convert2DArrayToObjects = (arr) => {
    return arr.map((row) => {
      return row.reduce((obj, value, index) => {
        if (value !== null && value !== "") {
          obj[numberToColumn(index)] = value;
        }
        return obj;
      }, {});
    });
  };

  on_generate_ai_hbu_summary = async () => {
    this.setState({ is_loading: true }, async () => {
      const { user_message_hbu } = this.state;
      const { geo_layer_id, feature_key, field_key } = this.props;
      const { ai_parent_detail_list, sheet_object_selected } = this.props.sini;
      const sini_object = ai_parent_detail_list?.find(
        (item) => item.request_id === sheet_object_selected.request_id
      );

      const sini_not_used_list = ["people_spending"];

      for await (const key of sini_not_used_list) {
        if (sini_object?.[key]) delete sini_object[key];
      }

      let worksheet = JSON.parse(JSON.stringify(this.props.worksheet));
      if (worksheet["Read Me"]) delete worksheet["Read Me"];
      if (worksheet["DATA"]) delete worksheet["DATA"];
      if (worksheet["Asumsi Finansial"]) delete worksheet["Asumsi Finansial"];

      const sheet_array = Object.keys(worksheet).map((item) => {
        let sheet = worksheet[item];
        sheet[0][0] = item;
        return this.convert2DArrayToObjects(sheet);
      });

      const landvalue_one = sini_object?.landvalue_one;
      const landvalue_value = landvalue_one?.properties?.["HARGA"];

      const luas_tanah = get_value_cell("D12", worksheet, "Data Objek");
      const capex_tanah = luas_tanah * landvalue_value;

      const body = {
        worksheet: sheet_array,
        geo_layer_id,
        feature_key,
        field_key,
        sini_object,
        user_message_hbu,
        capex_tanah,
      };

      const is_timeout =
        (await this.props.generate_ai_hbu_summary(body)) === "Timeout";
      if (is_timeout) await timeout(25000);

      this.setState({
        user_message_hbu: "",
        modal_re_hbu: false,
        is_loading: false,
        is_timeout: is_timeout ? true : false,
      });
    });
  };

  reset_ai_hbu_summary = () => {
    const { geo_layer_id, feature_key, field_key } = this.props;
    const { ai_parent_detail_list, sheet_object_selected } = this.props.sini;
    const sini_object = ai_parent_detail_list.find(
      (item) => item.request_id === sheet_object_selected.request_id
    );

    const { worksheet } = this.props;
    const sheet_array = Object.keys(worksheet).map((item) => {
      let sheet = worksheet[item];
      sheet[0][0] = item;
      return this.convert2DArrayToObjects(sheet);
    });

    const body = {
      worksheet: sheet_array,
      geo_layer_id,
      feature_key,
      field_key,
      sini_object,
      user_message_hbu: "",
    };
    this.props.generate_ai_hbu_summary(body);
    this.setState({
      user_message_hbu: "",
      modal_re_hbu: false,
    });
  };

  on_push_ai_sheet_chat = () => {
    const { user_message } = this.state;
    const { geo_layer_id, feature_key, field_key } = this.props;
    const {
      ai_parent_detail_list,
      sheet_object_selected,
      ai_hbu_summary_object,
    } = this.props.sini;
    const sini_object = ai_parent_detail_list.find(
      (item) => item.request_id === sheet_object_selected.request_id
    );
    const body = {
      ai_hbu_summary_object,
      user_message,
      geo_layer_id,
      feature_key,
      field_key,
      sini_object,
    };
    this.props.push_ai_sheet_chat(body);
    this.setState({
      user_message: "",
    });
  };

  toggle_chat_ai = () => {
    this.setState({
      is_chat_ai_show: !this.state?.is_chat_ai_show,
    });
  };

  handleBeforePrint = () => {
    // const canvas = document.getElementById("myCanvas");
    // if (canvas) {
    //   const ctx = canvas.getContext("2d");
    //   canvas.width = 500;
    //   canvas.height = 500;
    //   const img = new Image();
    //   img.src = "path/to/image.jpg";
    //   img.onload = () => {
    //     ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    //   };
    // }
  };

  render() {
    //local storage

    //local state
    const {
      user_message,
      modal_re_hbu,
      user_message_hbu,
      is_chat_ai_show,
      is_loading,
      is_timeout,
      mode_capex,
      modal_show_pedoman,
    } = this.state;

    //global props
    const { worksheet, geo_layer_id, feature_key, field_key } = this.props;
    const { loading_item_array } = this.props.loading_reducer;
    const {
      total_step,
      current_step,
      current_topic_loading,
      ai_hbu_summary_object,
      ai_sheet_chat_list,
      ai_sini_sheet_object,
    } = this.props.sini;
    const { map_object_excel } = this.props.layer;
    const { ai_parent_detail_list, sheet_object_selected } = this.props.sini;

    //content
    const sini_object = ai_parent_detail_list?.find(
      (item) => item?.request_id === sheet_object_selected?.request_id
    );
    const properties = sini_object?.demography_one?.properties || {};
    const { DESA, KECAMATAN, KABKOT, PROVINSI } = properties;
    const landvalue_one = sini_object?.landvalue_one;
    const landvalue_value = landvalue_one?.properties?.["HARGA"];

    let is_alternative_hbu_empty = false;
    const luas_tanah = get_value_cell("D12", worksheet, "Data Objek");
    const luas_bangunan = get_value_cell("D24", worksheet, "Data Objek");
    const cek = get_value_cell("C2", worksheet, "Uji HBU - Finance - Alt. 1");

    if (cek === "Uji HBU - Finance - Alt. 1") is_alternative_hbu_empty = true;
    // console.log("worksheet",worksheet)

    const modal_show_pedoman_content = modal_show_pedoman && (
      <Modal
        modalSize="large"
        isOpen={modal_show_pedoman}
        onClose={this.toggle_show_pedoman}
      >
        <div className="box-body" id="box-body">
          <section style={{ overflowX: "auto" }}>
            <table className="table scroll_auto">
              <thead>
                <tr>
                  <th>NO</th>
                  <th>JENIS_PROPERTI</th>
                  <th>TIPE_BANGUNAN</th>
                  <th>BANGUNAN_PER_METER_PERSEGI</th>
                  <th>LUAS_MIN_METER_PERSEGI</th>
                  <th>LUAS_MAX_METER_PERSEGI</th>
                  <th>DIMENSI_MIN_METER</th>
                  <th>DIMENSI_MAX_METER</th>
                  <th>LEBAR_JALAN_MIN_METER</th>
                  <th>KETERANGAN</th>
                </tr>
              </thead>
              <tbody>
                {hbu_list_json.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>{item?.JENIS_PROPERTI}</td>
                      <td>{item?.TIPE_BANGUNAN}</td>
                      <td>{item?.BANGUNAN_PER_METER_PERSEGI}</td>
                      <td>{item?.LUAS_MIN_METER_PERSEGI}</td>
                      <td>{item?.LUAS_MAX_METER_PERSEGI}</td>
                      <td>{item?.DIMENSI_MIN_METER}</td>
                      <td>{item?.DIMENSI_MAX_METER}</td>
                      <td>{item?.LEBAR_JALAN_MIN_METER}</td>
                      <td>{item?.KETERANGAN}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </section>
        </div>
      </Modal>
    );

    const modal_re_hbu_content = modal_re_hbu && (
      <Modal
        modalSize="small"
        isOpen={modal_re_hbu}
        onClose={this.toggle_re_hbu}
      >
        <div className="box-body" id="box-body">
          <textarea
            className="margin_bottom"
            rows={6}
            placeholder="Masukkan pesan untuk pembaruan summary"
            value={user_message_hbu}
            name="user_message_hbu"
            onChange={this.on_change}
          />
          <br />
          <button
            className="button_big background_black margin_right margin_bottom"
            onClick={this.on_generate_ai_hbu_summary}
          >
            Perbarui Summary
          </button>
          <button
            className="button_big background_black"
            onClick={this.reset_ai_hbu_summary}
          >
            Reset
          </button>
        </div>
      </Modal>
    );

    const summary_result = ai_hbu_summary_object?.summary_result;
    let summary_content, sub_content;
    let summary_sini = "";
    let keterangan = "";
    if (!summary_result) {
      if (
        loading_item_array.includes("generate_ai_hbu_summary") ||
        is_loading
      ) {
        keterangan = "Sedang dilakukan perangkuman HBU...";
        sub_content = (
          <button className="button_big background_grey">
            <CircularProgress size={20} />
          </button>
        );
      } else if (!ai_sini_sheet_object?.request_id) {
        keterangan = "Tunggu, sedang memuat...";
        sub_content = (
          <button className="button_big background_grey">
            {" "}
            <CircularProgress size={20} />
          </button>
        );
      } else {
        keterangan = is_timeout
          ? "Klik tombol di bawah untuk memuat hasil rangkuman"
          : "Rangkumkan sheet HBU";
        sub_content = (
          <>
            {is_timeout ? (
              <button
                className="button_big background_black"
                onClick={() => {
                  this.handle_refresh();
                }}
              >
                <InsertChart />
              </button>
            ) : (
              <button
                className="button_big background_black marginRight_10"
                onClick={this.on_generate_ai_hbu_summary}
              >
                Rangkum
              </button>
            )}
          </>
        );
      }

      summary_content = (
        <section className="text_center container_light outline_transparent background_grey_light">
          <p className="text_bold margin_bottom">{keterangan}</p>
          <br />
          {sub_content}
          <br />
        </section>
      );
    } else {
      const hbu_best_alternative = summary_result?.hbu_best_alternative || "";
      const hbu_best_alternative_reason =
        summary_result?.hbu_best_alternative_reason || "";
      const hbu_best_alternative_notes =
        summary_result?.hbu_best_alternative_notes || "";
      const hbu_alternative_array = summary_result?.hbu_alternative_array || [];

      const {
        data_object_hbu = "",
        perhitungan_sesuai_tata_ruang_tanah_kosong = "",
        perhitungan_sesuai_tata_ruang_bangunan = "",
        uji_maximum_productivity = "",
        uji_fisik_hbu_tanah_kosong = "",
        uji_fisik_hbu_bangunan_existing = "",
        alasan_dipilihnya_ranking_1 = "",
      } = summary_result || {};
      summary_sini = summary_result?.summary_sini || "";
      // rekomendasi_transportasi = summary_result?.rekomendasi_transportasi || ""
      // supply_demand = summary_result?.supply_demand || "";

      summary_content = (
        <section
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            overflowY: "auto",
            height: "100%",
            gap: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h1 className="margin_bottom">HBU SUMMARY</h1>
          <section className="margin_bottom">
            {loading_item_array.includes("generate_ai_hbu_summary") ? (
              <button className="button_big background_grey">
                AI SEDANG MELAKUKAN SUMMARY...
              </button>
            ) : !ai_sini_sheet_object?.request_id ? (
              <button className="button_big background_grey">WAIT</button>
            ) : (
              <button
                className="button_big background_black"
                onClick={this.toggle_re_hbu}
              >
                Perbarui Ringkasan HBU dengan Prompt
              </button>
            )}
          </section>

          {/* ============================== */}

          {/* <StreamExample > */}

          {/* ============================== */}

          <aside className="container_column">
            <div className="child_tile container_light background_grey_light outline_transparent">
              <h1 className="margin_bottom">
                <label>Nama object: </label>
                <p className="text_bold">{data_object_hbu}</p>
              </h1>
              <div className="margin_bottom">
                <label className="text_small">Provinsi</label>
                <p className="text_bold">{PROVINSI}</p>
              </div>
              <div className="margin_bottom">
                <label className="text_small">Kabupaten atau Kota</label>
                <p className="text_bold">{KABKOT}</p>
              </div>
              <div className="margin_bottom">
                <label className="text_small">Kecamatan</label>
                <p className="text_bold">{KECAMATAN}</p>
              </div>
              <div className="margin_bottom">
                <label className="text_small">Desa atau Kelurahan</label>
                <p className="text_bold">{DESA}</p>
              </div>
              <div className="margin_bottom">
                <label className="text_small">Luas Tanah m2</label>
                <p className="text_bold">{luas_tanah}</p>
              </div>
              <div className="margin_bottom">
                <label className="text_small">Luas Bangunan m2</label>
                <p className="text_bold">{luas_bangunan}</p>
              </div>
            </div>

            <div className="child_tile card_uji_legal_hbu container_light background_grey_light outline_transparent flex">
              <section>
                <h1 className="text_bold margin_bottom">
                  Rangkuman kalkulasi HBU
                </h1>
                <RANGKING_CHART hbu_alternative_array={hbu_alternative_array} />
              </section>
              <section className="container_light background_white">
                <div className="center_perfect h_full">
                  <div>
                    <span className="center_perfect">
                      <label className="margin_bottom text_center">
                        Alternatif HBU terbaik
                      </label>
                    </span>
                    <span className="center_perfect">
                      <h1 className="rounded_5 padding_5 background_black text_center">
                        {hbu_best_alternative}
                      </h1>
                    </span>
                  </div>
                </div>
              </section>
            </div>

            <div className="child_tile span_3 container_light background_grey_light outline_transparent">
              <h1 className="text_bold margin_bottom">Uji Legal HBU</h1>

              <h1 className="text_bold margin_bottom">
                Perhitungan Sesuai Tata Ruang (Tanah Kosong)
              </h1>
              <p>{perhitungan_sesuai_tata_ruang_tanah_kosong}</p>
              <br />
              <h1 className="text_bold margin_bottom">
                Perhitungan Sesuai Tata Ruang (Bangunan)
              </h1>
              <p>{perhitungan_sesuai_tata_ruang_bangunan}</p>
            </div>
            <div className="child_tile container_light background_grey_light outline_transparent">
              <h1 className="text_bold margin_bottom">
                Uji Maximum Productivity
              </h1>
              <p>{uji_maximum_productivity}</p>
            </div>
            {/* </section> */}

            {/* <section className="border"> */}
            <div className="child_tile container_light background_grey_light outline_transparent">
              <h1 className="text_bold margin_bottom">
                Uji Fisik HBU (Tanah Kosong)
              </h1>
              <p>{uji_fisik_hbu_tanah_kosong}</p>
            </div>
            <div className="child_tile container_light background_grey_light outline_transparent">
              <h1 className="text_bold margin_bottom">
                Uji Fisik HBU Bangunan (Existing)
              </h1>
              <p>{uji_fisik_hbu_bangunan_existing}</p>
            </div>
            {/* </section> */}
          </aside>

          <div
            style={{
              display: "grid",
              gap: "10px",
              gridTemplateColumns: "1fr",
            }}
          >
            <div className="child_tile card_uji_legal_hbu container_light background_grey_light outline_transparent">
              <h1 className="text_bold margin_bottom">
                Rangkuman finansial setiap alternatif
              </h1>
              <button
                className={`button_small button_white margin_right margin_bottom ${
                  mode_capex === "excel_ai" ? "outline_black" : "outline_white"
                }`}
                onClick={this.on_set_mode_capex.bind(this, "excel_ai")}
              >
                Versi Sheet
              </button>
              <button
                className={`button_small button_white margin_right margin_bottom ${
                  mode_capex === "manual" ? "outline_black" : "outline_white"
                }`}
                onClick={this.on_set_mode_capex.bind(this, "manual")}
              >
                Versi SINI
              </button>

              <section className="container_light outline_black margin_bottom">
                Catatan:{" "}
                {is_alternative_hbu_empty
                  ? "Mode sheet alternative HBU kosong"
                  : "Mode sheet alternative HBU terisi"}
              </section>
              {mode_capex === "manual" ? (
                <>
                  <table className="table margin_bottom">
                    <tbody>
                      <tr>
                        <td>Luas tanah</td>
                        <td>{to_id_format(luas_tanah)}</td>
                        <td>meter persegi</td>
                      </tr>
                      <tr>
                        <td>Luas bangunan</td>
                        <td>{to_id_format(luas_bangunan)}</td>
                        <td>meter persegi</td>
                      </tr>
                      <tr>
                        <td>Perkiraan harga tanah</td>
                        <td>{to_id_format(landvalue_value)}</td>
                        <td>rupiah per meter persegi</td>
                      </tr>
                    </tbody>
                  </table>
                  <button
                    className="button background_black margin_bottom"
                    onClick={this.toggle_show_pedoman}
                  >
                    Tampilkan pedoman
                  </button>
                  <table className="table scroll_auto">
                    <thead>
                      <tr className="font_10">
                        <th></th>
                        <th>CAPEX TANAH</th>
                        <th>CAPEX BANGUNAN</th>
                        <th>CAPEX TOTAL</th>
                        <th>NPV</th>
                        <th>IRR</th>
                        <th>PI</th>
                        <th>PP</th>
                        <th>DR</th>
                        <th>Result score</th>
                        <th>Ranking</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sort_array(
                        hbu_alternative_array,
                        "result_score",
                        false
                      ).map((item, idx) => {
                        const {
                          name,
                          npv,
                          irr,
                          pi,
                          payback_periode_year,
                          result_score,
                          discount_rate,
                        } = item;
                        const capex_tanah = luas_tanah * landvalue_value;
                        const hbu_find = hbu_list_json.find(
                          (hbu_item) =>
                            sanitize_string(hbu_item.JENIS_PROPERTI) ===
                            sanitize_string(name)
                        );
                        const harga_bangunan_per_meter = parseInt(
                          hbu_find?.BANGUNAN_PER_METER_PERSEGI
                        );
                        const capex_bangunan =
                          luas_bangunan * harga_bangunan_per_meter;
                        const capex_total = capex_tanah + capex_bangunan;
                        return (
                          <tr key={idx} className="font_10">
                            <td>{name}</td>
                            <td>{to_id_format(capex_tanah)}</td>
                            <td>{to_id_format(capex_bangunan)}</td>
                            <td>{to_id_format(capex_total)}</td>
                            <td>{to_id_format(npv)}</td>
                            <td>{to_id_format(irr?.toFixed(2))}</td>
                            <td>{to_id_format(pi)}</td>
                            <td>
                              {to_id_format(payback_periode_year?.toFixed(1))}
                            </td>
                            <td>{discount_rate}</td>
                            <td>{to_id_format(result_score)}</td>
                            <td>{to_id_format(idx + 1)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <label>
                    *Note: Asumsi CAPEX: mempertimbangkan harga jual tanah
                    sebagai bagian dari CAPEX
                  </label>
                </>
              ) : (
                <>
                  {is_alternative_hbu_empty ? (
                    <div>
                      Model excel HBU kosong maka tidak ada data yang bisa
                      ditampilkan, gunakan versi SINI
                    </div>
                  ) : (
                    <table className="table scroll_auto">
                      <thead>
                        <tr className="font_10">
                          <th></th>
                          <th>CAPEX Tanah</th>
                          <th>CAPEX Bangunan</th>
                          <th>CAPEX</th>
                          <th>NPV</th>
                          <th>IRR</th>
                          <th>PI</th>
                          <th>PP</th>
                          <th>DR</th>
                          {/* <th>Raw score</th> */}
                          <th>Result score</th>
                          {/* <th>Keterangan</th> */}
                          {/* <th>Indikator Kelayakan</th> */}
                          <th>Ranking</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sort_array(
                          hbu_alternative_array,
                          "result_score",
                          false
                        ).map((item, idx) => {
                          const {
                            name,
                            npv,
                            irr,
                            pi,
                            capex,
                            capex_tanah,
                            capex_bangunan,
                            payback_periode_year,
                            result_score,
                            discount_rate,
                            // raw_score,
                            // reasoning_behind_the_result_score,
                            // indikator_kelayakan
                            // rangking,
                          } = item;

                          const to_id_format = (num_val) => {
                            return new Intl.NumberFormat("id-ID", {
                              style: "decimal",
                            })?.format(Number(num_val));
                          };

                          return (
                            <tr key={idx} className="font_10">
                              <td>{name}</td>
                              <td>Rp{to_id_format(capex_tanah)}</td>
                              <td>Rp{to_id_format(capex_bangunan)}</td>
                              <td>Rp{to_id_format(capex)}</td>
                              <td>Rp{to_id_format(npv)}</td>
                              <td>{(irr * 100).toFixed(1)}%</td>
                              <td>{`${to_id_format(pi.toFixed(1))} tahun`}</td>
                              <td>
                                {to_id_format(payback_periode_year?.toFixed(1))}
                              </td>
                              <td>{(discount_rate * 100).toFixed(1)}%</td>
                              {/* <td>{to_id_format(raw_score)}</td> */}
                              <td>{to_id_format(result_score)}</td>
                              {/* <td>{reasoning_behind_the_result_score}</td> */}
                              {/* <td>{indikator_kelayakan}</td> */}
                              <td>{to_id_format(idx + 1)}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </>
              )}

              <div>
                <br />
                <h1 className="text_bold margin_bottom">Indikator Kelayakan</h1>
                <ul>
                  <li>
                    <b style={{ fontWeight: 800 }}>NPV</b>
                    {
                      " > 0 maka layak, semakin besar maka baik karena lebih profitable"
                    }
                  </li>
                  <li>
                    <b style={{ fontWeight: 800 }}>IRR</b>
                    {
                      " > Investasi, semakin besar maka baik karena lebih profitable"
                    }
                  </li>
                  <li>
                    <b style={{ fontWeight: 800 }}>PP</b>
                    {" Semakin kecil maka baik / cepat balik modal"}
                  </li>
                  <li>
                    <b style={{ fontWeight: 800 }}>PI</b>
                    {" > 1 maka layak"}
                  </li>
                  <li>
                    <b style={{ fontWeight: 800 }}>Akumulasi Rank</b>
                    {" semakin rendah, maka prioritas semakin tinggi."}
                  </li>
                </ul>
              </div>

              <div className="h_full">
                <br />
                <h1 className="text_bold margin_bottom">
                  Alasan Dipilihnya Alternatif Ranking 1
                </h1>
                <p className="text_medium">{alasan_dipilihnya_ranking_1}</p>
                <br />
                <h1 className="text_bold margin_bottom">
                  Alasan Pemilihan Alternatif
                </h1>
                <p className="text_medium">
                  <MARKDOWN_RENDERER
                    markdownContent={hbu_best_alternative_reason}
                  />
                </p>
                <br />
                <h1 className="text_bold margin_bottom">Catatan</h1>
                <p className="text_medium">{hbu_best_alternative_notes}</p>
              </div>
            </div>
          </div>
        </section>
      );
    }

    const chat_content = (
      <main className="parent_section_full container_light outline_transparent background_grey_light">
        <section className="section_1">
          <p className="badge background_black">
            AI CHAT ({!ai_sini_sheet_object?.request_id ? "Load..." : "Ready"})
          </p>
        </section>
        <section
          className="section_2"
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              flexGrow: 1,
              overflowY: "auto",
              padding: "10px",
              maxHeight: "calc( 80vh - 150px )",
            }}
          >
            <div>
              {total_step !== current_step && (
                <ProgressBar
                  current_number={current_step}
                  total_number={total_step}
                  name={current_topic_loading}
                />
              )}
            </div>
            {ai_sheet_chat_list.map((chat, idx) => {
              let final_content;
              if (chat.role === "assistant" && chat.content) {
                final_content = (
                  <section key={idx} id={`ai_sini_${idx}`}>
                    <div className="container_light outline_transparent margin_bottom background_dark_blue">
                      <MARKDOWN_RENDERER markdownContent={chat.content} />
                    </div>
                  </section>
                );
              } else {
                final_content = (
                  <section key={idx} id={`ai_sini_${idx}`}>
                    <div
                      className="container_light outline_transparent margin_bottom max_width_300 background_white"
                      style={{ right: 0, whiteSpace: "pre-wrap" }}
                    >
                      {chat.content}
                    </div>
                  </section>
                );
              }
              return final_content;
            })}
          </div>
          <div
            id="chat_dialog"
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <textarea
              name="user_message"
              value={user_message}
              onChange={this.on_change}
              rows={6}
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
            {loading_item_array.includes("push_ai_sheet_chat") ? (
              <button
                className="button background_grey"
                style={{
                  alignSelf: "flex-start",
                }}
              >
                Sending...
              </button>
            ) : !ai_sini_sheet_object?.request_id ? (
              <button
                className="button background_grey"
                style={{
                  alignSelf: "flex-start",
                }}
              >
                Wait
              </button>
            ) : (
              <button
                className="button background_blue"
                style={{
                  alignSelf: "flex-start",
                }}
                onClick={this.on_push_ai_sheet_chat}
              >
                Chat
              </button>
            )}
          </div>
        </section>
      </main>
    );

    return (
      <main>
        {modal_show_pedoman_content}
        {modal_re_hbu_content}
        {!is_alternative_hbu_empty ? (
          <section>
            <div className="hidden">
              <TemplatePrint
                ref={(el) => (this.componentRef = el)}
                summary_result={summary_result}
                properties={properties}
                map_object_excel={map_object_excel}
                is_print={this.state.is_print}
              />
            </div>
            <ReactToPrint
              trigger={() => (
                <button className="absolute left top button marginTop_30 background_blue">
                  Print PDF
                </button>
              )}
              onAfterPrint={() => {
                this.setState({
                  is_print: true,
                });
              }}
              content={() => {
                return this.componentRef;
              }}
            />
            <div
              style={{
                display: "flex",
                gap: "10px",
                height: "calc(100vh - 75px)",
              }}
            >
              <section
                style={{
                  width: "50%",
                }}
              >
                {summary_content}
              </section>
              <section
                style={{
                  display: "flex",
                  height: "100%",
                  width: "50%",
                  gap: "15px",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div style={{ height: "50%" }}>
                    {map_object_excel !== null &&
                      map_object_excel.isStyleLoaded() && <MAP_EXCEL />}
                  </div>
                  <div
                    className="child_tile container_light background_grey_light outline_transparent"
                    style={{ height: "50%" }}
                  >
                    {summary_result && (
                      <div style={{ height: "100%" }}>
                        <div style={{ maxHeight: "45vh", overflowY: "auto" }}>
                          <h1 className="bold">Summary SINI</h1>
                          <hr />
                          <MARKDOWN_RENDERER markdownContent={summary_sini} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    width: "300px",
                    overflowY: "auto",
                  }}
                >
                  <PARENT_SINI from="ai_hbu" />
                </div>
              </section>
              <section
                style={{
                  visibility: is_chat_ai_show ? "visible" : "hidden",
                  width: is_chat_ai_show ? "300px" : "0px",
                  flexShrink: 0,
                  flexGrow: 0,
                  height: "100%",
                }}
              >
                {chat_content}
              </section>
            </div>
            <button
              className="floating_button circle_button center_perfect"
              onClick={this.toggle_chat_ai}
            >
              <div className="flex flex_col">
                <span className="w_full flex justify_center">
                  <QuestionAnswerIcon />
                </span>
                <label className="font_10 pointer">Chat AI</label>
              </div>
            </button>
          </section>
        ) : (
          <PARENT_HBU_MANUAL
            worksheet={worksheet}
            geo_layer_id={geo_layer_id}
            feature_key={feature_key}
            field_key={field_key}
          />
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  loading_reducer: state.loading_reducer,
  sini: state.sini,
  properties: state.properties,
  layer: state.layer,
});

export default connect(mapStateToProps, {
  generate_ai_hbu_summary,
  get_ai_hbu_summary,
  push_ai_sheet_chat,
  get_ai_sheet_chat_list,
  get_ai_sini_sheet,
  snackbar,
})(EXCEL_HBU);
