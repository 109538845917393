/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import Modal from "../common_modal/Modal";
import UJI_LUAS from "./UJI_LUAS";
import UJI_RASIO from "./UJI_RASIO";
import UJI_FINANSIAL from "./UJI_FINANSIAL";

/*REDUX*/

/*PICTURE*/
import expand from "../../Assets/svg/expand.svg";

/*FUNCTION*/
import is_not_empty from "../../App/validation/is_not_empty";
import sort_array from "../../App/validation/sort_array";

/*DATA*/

/*CONST*/

class HBU_MANUAL extends Component {
  state = {
    modal_show_all_alt: false,
    modal_uji_luas: false,
    modal_uji_rasio: false,
  };

  toggle_uji_rasio = () => {
    this.setState({
      modal_uji_rasio: !this.state.modal_uji_rasio,
    });
  };

  toggle_uji_luas = () => {
    this.setState({
      modal_uji_luas: !this.state.modal_uji_luas,
    });
  };

  on_change = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  };

  toggle_show_all_alt = () => {
    this.setState({ modal_show_all_alt: !this.state.modal_show_all_alt });
  };

  render() {
    //local storage

    //local state
    const { modal_show_all_alt, modal_uji_luas, modal_uji_rasio } = this.state;

    //global props
    const {
      headers,
      rows,
      luas_tanah,
      luas_bangunan,
      panjang_tanah,
      lebar_tanah,
      ratio_tanah,
      rows_filtered_1,
      rows_filtered_2,
      rows_filtered_3,
      rows_rejected_1,
      rows_rejected_2,
      rows_rejected_3,
      kesimpulan_tanah_kosong,
      kesimpulan_bangunan,
      data_bangun,
    } = this.props.hbu_reducer;
    const { ai_parent_detail_list, sheet_object_selected } = this.props.sini;

    //content
    const { peruntukan, gsb, kdb, klb, ktb_tanah_kosong, ktb_bangunan } =
      data_bangun || {};
    const is_kdb_and_klb_fill =
      is_not_empty(data_bangun?.klb) || is_not_empty(data_bangun?.kdb);
    const sini_object = ai_parent_detail_list.find(
      (item) => item?.request_id === sheet_object_selected?.request_id
    );
    const landvalue_one = sini_object?.landvalue_one;
    const landvalue_value = landvalue_one?.properties?.["HARGA"];
    const is_not_same_rows_1 =
      rows_filtered_1?.length !== rows_rejected_1?.length;
    const is_not_same_rows_2 =
      rows_filtered_2?.length !== rows_rejected_2?.length;
    const is_not_same_rows_3 =
      rows_filtered_1?.length !== rows_rejected_3?.length;

    const poi_resume_2 = sini_object?.poi_resume_2;
    const sorted_poi_resume_2 = sort_array(
      Object.keys(poi_resume_2)?.map((item) => poi_resume_2?.[item]),
      "number",
      false
    );
    const modal_uji_rasio_content = modal_uji_rasio && (
      <Modal
        modalSize="medium"
        isOpen={modal_uji_rasio}
        onClose={this.toggle_uji_rasio}
      >
        <div className="box-body" id="box-body">
          <UJI_RASIO />
        </div>
      </Modal>
    );

    const modal_uji_luas_content = modal_uji_luas && (
      <Modal
        modalSize="medium"
        isOpen={modal_uji_luas}
        onClose={this.toggle_uji_luas}
      >
        <div className="box-body" id="box-body">
          <UJI_LUAS />
        </div>
      </Modal>
    );

    const modal_show_all_alt_content = modal_show_all_alt && (
      <Modal
        modalSize="large"
        isOpen={modal_show_all_alt}
        onClose={this.toggle_show_all_alt}
      >
        <div className="box-body" id="box-body">
          <section style={{ overflowX: "auto" }}>
            <table className="table scroll_auto">
              <thead>
                <tr>
                  <td>No</td>
                  {headers.map((header, index) => (
                    <td key={index}>{header}</td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows.map((item, idx) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    {headers.map((header, index) => (
                      <td key={index}>{item[header]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        </div>
      </Modal>
    );

    return (
      <main
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          overflowY: "auto",
          height: "100%",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {modal_show_all_alt_content}
        {modal_uji_luas_content}
        {modal_uji_rasio_content}
        <table className="table margin_bottom">
          <tbody>
            <tr>
              <td>Luas tanah</td>
              <td>{luas_tanah}</td>
            </tr>
            <tr>
              <td>Luas bangunan</td>
              <td>{luas_bangunan}</td>
            </tr>
            <tr>
              <td>Panjang tanah</td>
              <td>{panjang_tanah}</td>
            </tr>
            <tr>
              <td>Lebar tanah</td>
              <td>{lebar_tanah}</td>
            </tr>
            <tr>
              <td>Rasion panjang/lebar</td>
              <td>{ratio_tanah}</td>
            </tr>
            <tr>
              <td>Landvalue SINI</td>
              <td>{landvalue_value}</td>
            </tr>
          </tbody>
        </table>

        <button
          className="button background_black margin_bottom margin_right"
          onClick={this.toggle_show_all_alt}
        >
          Buka pedoman
        </button>
        {/* <button
          className="button background_black margin_bottom margin_right"
          onClick={this.on_run}
        >
          Run
        </button> */}

        <section className="container background_grey_light margin_bottom">
          <div className="text_bold margin_bottom">Uji Legal</div>
          <div className="container background_white margin_bottom">
            <table>
              <tbody>
                {peruntukan && (
                  <tr>
                    <td className="text_medium_normal">Nama Objek</td>
                    <td>:</td>
                    <td className="text_bold"> {peruntukan}</td>
                  </tr>
                )}
                <tr>
                  <td className="text_medium_normal">KDB</td>
                  <td>:</td>
                  <td className="text_bold"> {kdb}%</td>
                </tr>
                <tr>
                  <td className="text_medium_normal">GSB</td>
                  <td>:</td>
                  <td className="text_bold"> {gsb}</td>
                </tr>
                <tr>
                  <td className="text_medium_normal">KLB</td>
                  <td>:</td>
                  <td className="text_bold"> {klb}</td>
                </tr>
                <tr>
                  <td className="text_medium_normal">Luas Terbangun</td>
                  <td>:</td>
                  <td className="text_bold">
                    {" "}
                    {luas_bangunan} m<sup>2</sup>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="two_container_equal">
            <article
              className="container background_white"
              style={{ position: "relative", paddingBottom: "35px" }}
            >
              <div className="two_container_small_right">
                <h1 className="text_bold margin_bottom">
                  Perhitungan Sesuai Tata Ruang (Tanah Kosong)
                </h1>
                <img
                  className="cursor_pointer text_right"
                  alt="expand"
                  src={expand}
                  style={{ height: "20px" }}
                  onClick={this.toggle_uji_luas}
                />
              </div>
              <p className="text_medium">{kesimpulan_tanah_kosong}</p>

              {!is_kdb_and_klb_fill && (
                <div className="badge background_orange">
                  KDB dan KLB harus diisi pada sheet 'Uji HBU - Legal' agar
                  kesimpulan sesuai.
                </div>
              )}

              <div
                style={{
                  position: "absolute",
                  right: 10,
                  bottom: 10,
                }}
              >
                KTB : {!is_not_empty(ktb_tanah_kosong) ? "-" : ktb_tanah_kosong}
              </div>
            </article>
            <article
              className="container background_white"
              style={{ position: "relative", paddingBottom: "35px" }}
            >
              <div className="two_container_small_right">
                <h1 className="text_bold margin_bottom">
                  Perhitungan Sesuai Tata Ruang (Bangunan)
                </h1>
                <img
                  className="cursor_pointer text_right"
                  alt="expand"
                  src={expand}
                  style={{ height: "20px" }}
                  onClick={this.toggle_uji_luas}
                />
              </div>
              <p className="text_medium">{kesimpulan_bangunan}</p>

              {!is_kdb_and_klb_fill && (
                <div className="badge background_orange">
                  KDB dan KLB harus diisi pada sheet 'Uji HBU - Legal' agar
                  kesimpulan sesuai.
                </div>
              )}

              <div
                style={{
                  position: "absolute",
                  right: 10,
                  bottom: 10,
                }}
              >
                KTB : {!is_not_empty(ktb_bangunan) ? "-" : ktb_bangunan}
              </div>
            </article>
          </div>
        </section>

        <section className="container background_grey_light margin_bottom">
          <div className="text_bold margin_bottom">Uji Fisik Luas</div>
          <div className="two_container_equal">
            <aside>
              <table
                className="table margin_bottom"
                style={{ tableLayout: "fixed", width: "100%" }}
              >
                <thead>
                  <tr>
                    <td>Memenuhi Syarat</td>
                    <td>Tidak Memenuhi Syarat</td>
                  </tr>
                  <tr>
                    <td>
                      {is_not_same_rows_1 &&
                        rows_filtered_1.map((item, idx) => {
                          return (
                            <div key={idx}>
                              {idx + 1}. {item.JENIS}
                            </div>
                          );
                        })}
                    </td>
                    <td>
                      {rows_rejected_1.map((item, idx) => {
                        return (
                          <div key={idx}>
                            {idx + 1}. {item.JENIS}
                          </div>
                        );
                      })}
                    </td>
                  </tr>
                </thead>
              </table>
            </aside>
            <article className="container background_white">
              <div className="two_container_small_right">
                <h1 className="text_bold margin_bottom">Penjelasan</h1>
                <img
                  className="cursor_pointer text_right"
                  alt="expand"
                  src={expand}
                  style={{ height: "20px" }}
                  onClick={this.toggle_uji_luas}
                />
              </div>
              <p className="text_medium">
                Uji luas objek dalam penentuan Highest and Best Use (HBU)
                bertujuan memastikan lahan sesuai dengan regulasi dan mendukung
                pengembangan yang paling menguntungkan. Proses ini membantu
                mengevaluasi kelayakan investasi, mengoptimalkan pemanfaatan
                sumber daya, serta meningkatkan nilai properti dengan memilih
                alternatif penggunaan terbaik berdasarkan aspek fisik, ekonomi,
                dan hukum.
              </p>
              {!is_not_same_rows_1 && <label>diloloskan</label>}
            </article>
          </div>
        </section>

        <section className="container background_grey_light margin_bottom">
          <div className="text_bold margin_bottom">Uji Fisik Rasio dimensi</div>
          <div className="two_container_equal">
            <aside>
              <table
                className="table margin_bottom"
                style={{ tableLayout: "fixed", width: "100%" }}
              >
                <thead>
                  <tr>
                    <td>Memenuhi Syarat</td>
                    <td>Tidak Memenuhi Syarat</td>
                  </tr>
                  <tr>
                    <td>
                      {is_not_same_rows_2 &&
                        rows_filtered_2.map((item, idx) => {
                          return (
                            <div key={idx}>
                              {idx + 1}. {item.JENIS}
                            </div>
                          );
                        })}
                    </td>
                    <td>
                      {rows_rejected_2.map((item, idx) => {
                        return (
                          <div key={idx}>
                            {idx + 1}. {item.JENIS}
                          </div>
                        );
                      })}
                    </td>
                  </tr>
                </thead>
              </table>
            </aside>
            <article className="container background_white">
              <div className="two_container_small_right">
                <h1 className="text_bold margin_bottom">Penjelasan</h1>
                <img
                  className="cursor_pointer text_right"
                  alt="expand"
                  src={expand}
                  style={{ height: "20px" }}
                  onClick={this.toggle_uji_rasio}
                />
              </div>
              <p className="text_medium margin_bottom">
                Uji rasio dimensi, seperti 30x30 meter (persegi) atau 30x20
                meter (persegi panjang), dalam penentuan Highest and Best Use
                (HBU) perlu dilakukan untuk memastikan efisiensi tata letak dan
                optimalisasi pemanfaatan lahan. Rasio ini berpengaruh terhadap
                desain bangunan, aksesibilitas, serta kepatuhan terhadap
                regulasi zonasi dan tata ruang. Selain itu, pemilihan dimensi
                yang tepat membantu meningkatkan nilai ekonomi properti dengan
                menyesuaikan bentuk lahan terhadap kebutuhan pasar dan jenis
                penggunaan terbaiknya, seperti komersial, residensial, atau
                industri.
              </p>
              {!is_not_same_rows_2 && <label>diloloskan</label>}
            </article>
          </div>
        </section>

        <section className="container background_grey_light margin_bottom">
          <div className="text_bold margin_bottom">
            Uji Kecocokan supply dan demand
          </div>
          <div className="two_container_equal">
            <aside>
              <table
                className="table margin_bottom"
                style={{ tableLayout: "fixed", width: "100%" }}
              >
                <thead>
                  <tr>
                    <td>Memenuhi Syarat</td>
                    <td>Tidak Memenuhi Syarat</td>
                  </tr>
                  <tr>
                    <td>
                      {is_not_same_rows_3 &&
                        rows_filtered_3.map((item, idx) => {
                          return (
                            <div key={idx}>
                              {idx + 1}. {item.JENIS}
                            </div>
                          );
                        })}
                    </td>
                    <td>
                      {rows_rejected_3.map((item, idx) => {
                        return (
                          <div key={idx}>
                            {idx + 1}. {item.JENIS}
                          </div>
                        );
                      })}
                    </td>
                  </tr>
                </thead>
              </table>
            </aside>
            <article className="container background_white">
              <div className="two_container_small_right">
                <h1 className="text_bold margin_bottom">Penjelasan</h1>
                <img
                  className="cursor_pointer text_right"
                  alt="expand"
                  src={expand}
                  style={{ height: "20px" }}
                />
              </div>
              <div
                className="text_medium"
                style={{ maxHeight: 400, overflowY: "auto" }}
              >
                <table className="table">
                  <thead>
                    <tr>
                      <th>Nama</th>
                      <th>Jumlah</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sorted_poi_resume_2?.map((item, idx) => {
                      const { name, number } = item || {};
                      return (
                        <tr key={idx}>
                          <td>{name}</td>
                          <td>{number || 0}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {!is_not_same_rows_3 && <label>diloloskan</label>}
            </article>
          </div>
        </section>

        <UJI_FINANSIAL on_run={this.props.on_run} />
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  loading_reducer: state.loading_reducer,
  sini: state.sini,
  properties: state.properties,
  layer: state.layer,
  hbu_reducer: state.hbu_reducer,
});

export default connect(mapStateToProps, {})(HBU_MANUAL);
