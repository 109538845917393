//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datasheet/lib/react-datasheet.css";

//Personal Component
import SpinnerSimpleBlue from "../common_spinner/SpinnerSimpleBlue";

//Redux function
import {
  pushFeature,
  pushField,
  editProperties,
  deleteFeature,
  setTabelStatus,
  setFlyFeatures,
} from "../../App/actions/layerActions";

//Picture Asset
//General Function
import isEqual from "../../App/validation/is-equal";
import isEmpty from "../../App/validation/is-empty";

class TableFix extends Component {
  state = {
    modalPushField: false,
    modalEditField: false,
    modalDeleteFeature: false,
    fields: [],
    grid: [],
    new_fields: [],
    geo_layer: {},
    features: [],
    param_filter: [],
    filtered_features: [],
  };

  componentWillUnmount() {
    this.props.setTabelStatus(false);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      (!isEqual(nextProps?.layer?.geo_layer, prevState?.geo_layer) &&
        !isEmpty(nextProps?.layer?.geo_layer?.geojson)) ||
      (!isEqual(nextProps?.layer?.geo_layer?.features, prevState?.features) &&
        !isEmpty(nextProps?.layer?.geo_layer?.geojson))
      //   ||
      // (!isEqual(nextProps?.layer?.param_filter,prevState.param_filter))
    ) {
      const fields = nextProps?.layer?.geo_layer?.fields;
      // const features = nextProps?.layer?.geo_layer?.filtered_features
      //   ? nextProps?.layer?.geo_layer?.filtered_features
      //   : [];
      const { param_filter } = nextProps?.layer;
      const features = !isEmpty(param_filter)
        ? nextProps?.layer?.geo_layer?.geojson?.features.filter(
            (f) =>
              String(f.properties[param_filter.key]).toLowerCase() ===
              String(param_filter.value).toLowerCase()
          )
        : nextProps?.layer?.geo_layer?.geojson?.features;
      let grid_value;

      let filtered_features = features.slice();
      param_filter.forEach((prm) => {
        filtered_features = filtered_features.filter(
          (f) =>
            String(f.properties[prm.key]).toLowerCase() ===
            String(prm.value).toLowerCase()
        );
      });

      // let new_fields = fields.slice(); //Clone fields
      let new_fields = fields.filter((field) => field.isHide === false);
      if (nextProps?.layer?.geo_layer?.type === "Point") {
        if (fields) {
          grid_value = filtered_features.map(
            ({ properties, geometry }, idx) => {
              let objectPerIndex = new_fields.map(({ key }, idx) => {
                return properties && properties[key] ? properties[key] : "";
              });
              // objectPerIndex.unshift(geometry.coordinates[1]);
              // objectPerIndex.unshift(geometry.coordinates[0]);
              objectPerIndex.unshift(idx + 1);
              return objectPerIndex;
            }
          );
        }
        // const longitude_field = {
        //   isHide: false,
        //   key: "Longitude",
        //   name: "Longitude",
        //   type: "geometry",
        // };
        // const latitude_field = {
        //   isHide: false,
        //   key: "Latitude",
        //   name: "Latitude",
        //   type: "geometry",
        // };

        // new_fields.unshift(longitude_field);
        // new_fields.unshift(latitude_field);
      } else {
        if (fields) {
          grid_value = filtered_features.map(({ properties }, idx) => {
            let objectPerIndex = new_fields.map(({ key }, idx) => {
              return properties && properties[key] ? properties[key] : "";
            });
            objectPerIndex.unshift(idx + 1);
            return objectPerIndex;
          });
        }
      }
      const number_field = {
        isHide: false,
        key: "Number",
        name: "No",
        type: "number",
      };
      new_fields.unshift(number_field);
      return {
        geo_layer: nextProps.project.geo_layer,
        grid: grid_value,
        new_fields,
        features,
        filtered_features,
        // param_filter
      };
    } else return null;
  }

  flyTo = (idx) => {
    const features = this.state.filtered_features;
    this.props.setFlyFeatures(features[idx]);
    this.setState({ rowSelected: idx });
  };

  render() {
    const { grid, new_fields } = this.state;
    const { loadingDetail, realtimeStatus } = this.props.layer;
    let itemContent;

    if (loadingDetail && !realtimeStatus) {
      itemContent = (
        <SpinnerSimpleBlue
          width={100}
          unik="loading_create_layer"
          border={0.1}
          center={true}
        />
      );
    } else {
      itemContent = (
        <div className="table-render-container">
          {/* <div>
            <CSVLink
              className="buttonShadowSmall"
              data={grid}
              filename={geo_layer.name + ".csv"}
            >
              Download CSV
            </CSVLink>
          </div> */}
          <table className="table_lite">
            <tbody>
              <tr>
                {new_fields.map(({ name, _id }, idx) => {
                  return <th key={idx}>{name}</th>;
                })}
              </tr>
              {grid.map((row, idx_row) => {
                const column_content = row.map((element, idx_col) => {
                  const field_type = new_fields[idx_col].type;
                  const field_name = new_fields[idx_col].name;
                  let new_element = element;
                  let text_allign = "left";
                  if (field_type === "currency" && !isNaN(element)) {
                    new_element = new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                    }).format(element);
                  }
                  if (field_type === "number") {
                    new_element = new Intl.NumberFormat("id-ID").format(
                      element
                    );
                  }
                  if (field_type === "image") {
                    if (element) {
                      new_element = (
                        <img alt={field_name} src={element} height="200px" />
                      );
                    } else {
                      new_element = `${field_name} URL not found`;
                    }
                  }
                  if (
                    field_type === "currency" ||
                    field_type === "number" ||
                    !isNaN(element)
                  ) {
                    text_allign = "right";
                  }
                  let column_width = "100px";
                  if (idx_col === 0) {
                    column_width = "30px";
                  }
                  return (
                    <td
                      key={idx_col}
                      style={{ textAlign: text_allign, minWidth: column_width }}
                    >
                      {new_element}
                    </td>
                  );
                });
                return (
                  <tr
                    key={idx_row}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      this.flyTo(idx_row);
                    }}
                  >
                    {column_content}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }
    return <div>{itemContent}</div>;
  }
}
const mapStateToProps = (state) => ({
  project: state.project,
  layer: state.layer,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  pushFeature,
  pushField,
  editProperties,
  deleteFeature,
  setTabelStatus,
  setFlyFeatures,
})(TableFix);
