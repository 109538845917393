import sort_array from "./sort_array";

const poi_list_default = [
  {
    name: "ATM DAN BANK",
    value: "ATM DAN BANK",
  },
  {
    name: "PEMERINTAHAN",
    value: "PEMERINTAHAN",
  },
  {
    name: "KELAUTAN DAN PERIKANAN",
    value: "KELAUTAN DAN PERIKANAN",
  },
  {
    name: "FASILITAS KESEHATAN",
    value: "FASILITAS KESEHATAN",
  },
  {
    name: "FASILITAS OLAHRAGA",
    value: "FASILITAS OLAHRAGA",
  },
  {
    name: "FASILITAS PENDIDIKAN",
    value: "FASILITAS PENDIDIKAN",
  },
  {
    name: "FASILITAS TRANSPORTASI",
    value: "FASILITAS TRANSPORTASI",
  },
  {
    name: "HOTEL DAN TEMPAT MENGINAP",
    value: "HOTEL DAN TEMPAT MENGINAP",
  },
  {
    name: "PARIWISATA DAN HIBURAN",
    value: "PARIWISATA DAN HIBURAN",
  },
  {
    name: "FASILITAS UMUM",
    value: "FASILITAS UMUM",
  },
  {
    name: "RETAIL",
    value: "RETAIL",
  },
  {
    name: "TEMPAT IBADAH",
    value: "TEMPAT IBADAH",
  },
  {
    name: "TEMPAT MAKAN/MINUM",
    value: "TEMPAT MAKAN/MINUM",
  },
  {
    name: "UMKM",
    value: "UMKM",
  },
];

const poi_list = sort_array(poi_list_default, "name", true);

export default poi_list;
