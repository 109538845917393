const calc_distance = (lat1, lon1, lat2, lon2) => {
  const to_rad = (Value) => {
    return (Value * Math.PI) / 180;
  };
  const R = 6371; // km
  const dLat = to_rad(lat2 - lat1);
  const dLon = to_rad(lon2 - lon1);
  const lat_1 = to_rad(lat1);
  const lat_2 = to_rad(lat2);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat_1) * Math.cos(lat_2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
};
export default calc_distance;
