import {
  FunctionPlugin,
  FunctionArgumentType,
  CellError,
  ErrorType,
} from "hyperformula";

export class RankCustomPlugin extends FunctionPlugin {
  rank(ast, state) {
    return this.runFunction(
      ast.args,
      state,
      this.metadata("RANK"),
      (firstParam, secondParam, thirdParam) => {
        const val = firstParam;
        const rangeArr = secondParam?.data?.flat();
        const is_ascending = thirdParam;
        const result = this.rank_calculation(val, rangeArr, is_ascending);
        return result;
      }
    );
  }

  rank_calculation = (val, rangeArr, is_ascending = 1) => {
    let uniqueArr = [...new Set(rangeArr)];
    uniqueArr = uniqueArr.filter((v) => typeof v === "number" && !isNaN(v));
    if (uniqueArr.length === 0) {
      return new CellError(ErrorType.VALUE);
    }

    if (is_ascending) {
      uniqueArr.sort((a, b) => a - b);
    } else {
      uniqueArr.sort((a, b) => b - a);
    }

    const result = uniqueArr.findIndex((item) => item === val) + 1;

    if (!result) return null;

    return result;
  };
}

RankCustomPlugin.implementedFunctions = {
  RANK: {
    method: "rank",
    parameters: [
      { argumentType: FunctionArgumentType.NUMBER },
      { argumentType: FunctionArgumentType.RANGE },
      { argumentType: FunctionArgumentType.BOOLEAN, 
        optionalArg: true, 
        defaultValue: true,
      },
    ],
  },
};

export const RankCustomPluginTranslations = {
  enGB: {
    RANK: "RANK"
  },
  enUS: {
    RANK: "RANK"
  },
}