/** 
 * Panggil saja ketika selesai melakukan request api
 * 
 * parameter:
 * - is_open : boolean
   - status : string
   - message : string
 * 
 * macam-macam status:
 *  success, info, warning, error
 */
export const snackbar = (params) => (dispatch) => {
    const is_open = params?.is_open;
    const status = params?.status ;
    const message = params?.message || ""

  dispatch({
    type: "snackbar",
    payload: {
      is_open,
      status,
      message,
    },
  });
};
