//Library modul
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//Personal Component

//Redux function

//Picture Asset
import checklist from "../../Assets/svg/checklist.svg";

//General Function
import isEmpty from "../../App/validation/is-empty";
import formatDate from "../../App/validation/format-date";
import { getGroups } from "../../App/actions/groupActions";
import useLanguage from "../common_hooks/useLanguage";

function ModalGroup(props) {
  const dispatch = useDispatch();
  const [group, set_group] = useState(props.group);
  const { auth } = useSelector((state) => {
    return {
      auth: state.auth,
    };
  });
  const { groups, user } = auth;
  const { toggleWarningNotAdmin } = props;
  const { dict, language } = useLanguage();

  const setGroup = (group) => {
    const { handle_group, set_is_personal } = props;
    if ((handle_group, set_is_personal)) {
      handle_group(group);
      if (group?._id) {
        set_is_personal(false);
      }
    }
  };

  useEffect(() => {
    console.log("how many changes");
    set_group(props?.group);
  }, [props?.group?._id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (groups?.length === 0) {
      dispatch(getGroups());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className="box-body"
      style={{ minHeight: "80vh", textAlign: "center" }}
    >
      <div style={{ textAlign: "center" }}>
        {dict["Choose your group or team"][language]}{" "}
      </div>
      {groups.map((group_element, idx) => {
        const { name, _id, members, payment } = group_element;
        let organizationContent = null;
        if (!isEmpty(payment)) {
          const { date_in } = payment;
          const multiple_month = payment.multiple_month
            ? payment.multiple_month
            : 1;
          //86400 unix = 1 day
          //1 month = 30.5 day
          const month_unix = 86400 * 30.5;
          const day_unix = 86400;
          const date_finish_unix =
            parseInt((new Date(date_in).getTime() / 1000).toFixed(0)) +
            month_unix * multiple_month;
          const date_now_unix = parseInt(
            (new Date(Date.now()).getTime() / 1000).toFixed(0)
          );
          const delta_unix =
            (date_finish_unix - date_now_unix) * multiple_month;
          const delta_day = parseInt(delta_unix / day_unix);
          const date_finish = new Date(date_finish_unix * 1000);
          if (delta_day > 0) {
            if (delta_day > 30) {
              organizationContent = (
                <div className="badge_normal" id="grey_light">
                  <b>
                    {dict["Team license active until"][language]}{" "}
                    {formatDate(date_finish)}
                  </b>
                </div>
              );
            } else {
              organizationContent = (
                <div className="badge_normal" id="grey_light">
                  <b>
                    {dict["Team license active for"][language]}
                    {delta_day}
                    {dict["days"][language]}
                  </b>
                </div>
              );
            }
          } else {
            organizationContent = (
              <div className="badge_normal" id="grey_light">
                <b>{dict["Team license exp."][language]}</b>
              </div>
            );
          }
        }
        let userStatus = "viewer";
        const array_member_filtered = members.filter(
          (member) => member?.user?._id === user._id
        )[0];
        userStatus = array_member_filtered?.status;
        let checkContent;
        if (_id === group._id) {
          checkContent = <img alt="checklist" src={checklist} width="11px" />;
        }
        let button_content = (
          <main
            className="container_basic"
            style={{
              display: "inline-block",
              margin: "10px",
              width: "150px",
              minHeight: "120px",
              fontSize: "12px",
              cursor: "pointer",
              overflow: "hidden",
              backgroundColor: "#d3d3d3",
              color: "#fff",
              borderRadius: "10px",
              position: "relative",
              verticalAlign: "top",
            }}
            onClick={toggleWarningNotAdmin}
            key={_id}
          >
            <section
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxHeight: "60px",
                width: "95px",
                overflow: "hidden",
                fontWeight: "bold",
              }}
            >
              <div style={{ overflow: "hidden", maxHeight: "45px" }}>
                {name}
              </div>
            </section>
            {organizationContent}
          </main>
        );
        if (userStatus === "creator" || userStatus === "admin") {
          button_content = (
            <main
              className="container_basic"
              style={{
                display: "inline-block",
                margin: "10px",
                width: "150px",
                minHeight: "120px",
                fontSize: "12px",
                cursor: "pointer",
                overflow: "hidden",
                backgroundColor: "#0ca5eb",
                color: "#fff",
                borderRadius: "10px",
                position: "relative",
                verticalAlign: "top",
              }}
              onClick={() => setGroup(group_element)}
              key={_id}
            >
              <section
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  maxHeight: "60px",
                  width: "95px",
                  overflow: "hidden",
                  fontWeight: "bold",
                }}
              >
                <div style={{ overflow: "hidden", maxHeight: "45px" }}>
                  {name}
                </div>{" "}
                {checkContent}
              </section>
              {organizationContent}
            </main>
          );
        }
        return button_content;
      })}
    </div>
  );
}

export default ModalGroup;
