/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/
import {
  set_value_insight,
  insight_update,
} from "../../App/actions/insight_v2";

/*PICTURE*/

/*FUNCTION*/

/*DATA*/
import manifest_demography from "../../Data/manifest_demography.json";

/*CONST*/

class INPUT_DEMOGRAPHY extends Component {
  state = {};

  componentDidMount() {
    const mode_input_parent = localStorage?.mode_input_parent || "";
    if (mode_input_parent === "simple") {
      this.props.set_value_insight({
        key: "demography_group_selected",
        value: "PENDUDUK",
      });
    }
  }

  toggle_config_demography = (item) => {
    let { array_formula_insight } = this.props.insight_reducer;
    if (!array_formula_insight.some((element) => element.key === item)) {
      //belum ada, set
      array_formula_insight.push({
        key: item,
        is_directly_proportional: true,
        min: 0,
        max: 0,
        weight_score: 1,
        group: "demography",
      });
    } else {
      //sudah ada, delete
      array_formula_insight = array_formula_insight.filter(
        (element) => element.key !== item
      );
    }
    this.props.set_value_insight({
      key: "array_formula_insight",
      value: array_formula_insight,
    });
  };

  on_change = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.props.set_value_insight({
      key: name,
      value,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";
    const mode_input_parent = localStorage?.mode_input_parent || "";
    const demography_keys = manifest_demography.filter(
      (item) =>
        //hanya ambil kolom demografi yang angka dan relevan
        (item.chart_type !== "line" && item.chart_type !== "Table") ||
        item.key === "PENDUDUK"
    );
    let manifest_demography_new = manifest_demography.filter(
      (item) =>
        //hanya ambil kolom demografi yang angka dan relevan
        (item.chart_type !== "line" && item.chart_type !== "Table") ||
        item.key === "PENDUDUK"
    );

    //local state

    //global props
    const { array_formula_insight, demography_group_selected } =
      this.props.insight_reducer;

    //content
    if (demography_group_selected !== "all") {
      manifest_demography_new = manifest_demography_new.filter(
        (item) => item.key === demography_group_selected
      );
    } else if (
      demography_group_selected === "all" &&
      mode_input_parent === "simple"
    ) {
      manifest_demography_new = [];
    }

    return (
      <main>
        <section>
          <p className="text_bold">Pilih parameter demografi:</p>
          <select
            name="demography_group_selected"
            value={demography_group_selected}
            onChange={this.on_change}
            style={{ maxWidth: "200px" }}
          >
            {mode_input_parent === "custom" && (
              <option value="all">Semua</option>
            )}

            {demography_keys.map((item, idx) => {
              return (
                <option key={idx} value={item.key}>
                  {item[language]}
                </option>
              );
            })}
          </select>
        </section>

        <section className="parent_tile">
          {manifest_demography_new.map((item, idx) => {
            return (
              <div
                key={idx}
                className="container_light child_tile outline_transparent background_grey_light margin_bottom margin_right"
                style={{ width: "200px" }}
              >
                {mode_input_parent === "custom" && (
                  <>
                    <h1 className="text_bold">{item?.[language]}</h1>
                    <hr />
                  </>
                )}
                <table>
                  <tbody>
                    {item.child.map((child, idx_child) => {
                      const is_active = array_formula_insight.some(
                        (element) => element.key === child.key
                      );
                      return (
                        <tr key={idx_child}>
                          <td>
                            <button
                              className={
                                is_active ? "checklist_active" : "checklist_not"
                              }
                              onClick={this.toggle_config_demography.bind(
                                this,
                                child.key
                              )}
                              data-mapid={`selectParameter-${idx_child}`}
                            />
                          </td>
                          <td>{child[language]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            );
          })}
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  insight_reducer: state.insight_reducer,
});

export default connect(mapStateToProps, { set_value_insight, insight_update })(
  INPUT_DEMOGRAPHY
);
