/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/
import { set_value_properties } from "../../App/actions/propertiesActions";
import { status_action } from "../../App/actions/mapActions";

/*PICTURE*/

/*FUNCTION*/
import { generate_paint } from "../../Functions/map_style/generate_paint";

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class RANGE_CONSTANT extends Component {
  state = {};

  on_change = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    const type = e.target.type;
    if (type === "number" || type === "range") {
      value = Number(value);
    }
    if (name === "color_logic_state") {
      this.setState({ [name]: value });
    } else {
      const { style_parameter } = this.props.properties;
      style_parameter[name] = value;
      this.props.set_value_properties({
        key: "style_parameter",
        value: style_parameter,
      });
    }
    this.on_regenerate_paint();
  };

  on_regenerate_paint = () => {
    const { style_parameter } = this.props.properties;
    const paint_object_new = generate_paint(style_parameter);
    this.props.set_value_properties({
      key: "paint_object_edited",
      value: paint_object_new,
    });
    this.props.status_action();
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const { style_parameter } = this.props.properties;

    //content
    const { constant_color } = style_parameter;

    return (
      <main>
        <p className="text_medium">
          {dict?.["Choose a constant color"]?.[language]}
        </p>
        <input
          name="constant_color"
          value={constant_color}
          onChange={this.on_change}
          type="color"
        />
        <input
          name="constant_color"
          value={constant_color}
          onChange={this.on_change}
          type="text"
        />
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  properties: state.properties,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, {
  set_value_properties,
  status_action,
})(RANGE_CONSTANT);
