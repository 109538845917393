import React, { Component } from 'react'
import TextFieldGroup from '../common_input/TextFieldGroup'

//General Function
import dict from "../../Data/dict.json";

export default class RegisterStep3Page2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_password: false,
      show_password_2: false
    }
  }

  toggleShowPassword = () => {
    this.setState({
      show_password: !this.state.show_password
    })
  }

  toggleShowPassword2 = () => {
    this.setState({
      show_password_2: !this.state.show_password_2
    })
  }

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { phone_number, password, password2, onChange, errors } = this.props;
    const { show_password, show_password_2 } = this.state
    return (
      <>
        <TextFieldGroup
          Title={dict["Whatsapp number"][language]}
          placeholder="81.."
          name="phone_number"
          type="phone_number"
          id="phone_number"
          value={phone_number}
          onChange={onChange}
          error={errors?.phone_number}
        />
        <TextFieldGroup
          Title={dict["Password"][language]}
          placeholder={`${dict["Enter Your Password"][language]}`}
          isPassword={true}
          name="password"
          id="password"
          type={show_password ? "text" : "password"}
          value={password}
          onChange={onChange}
          error={errors?.password}
          toggleShowPassword={this.toggleShowPassword}
        />
        <TextFieldGroup
          Title={dict["Confirm password"][language]}
          placeholder={`${dict["Enter Your Password"][language]}`}
          isPassword={true}
          name="password2"
          id="password2"
          type={show_password_2 ? "text" : "password"}
          value={password2}
          onChange={onChange}
          error={errors?.password2}
          toggleShowPassword={this.toggleShowPassword2}
        />
      </>
    )
  }
}
