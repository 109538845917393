/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/
import { set_value_insight } from "../../App/actions/insight_v2";

/*PICTURE*/
import ICON_ARROW_DOWN from "../../Assets/jsx/ICON_ARROW_DOWN";
import ICON_ARROW_UP from "../../Assets/jsx/ICON_ARROW_UP";

/*FUNCTION*/

/*DATA*/

/*CONST*/
const height_closed = "20px";
// const delay_miliseconds = 400;

class OPTION_DIALOG extends Component {
  state = {
    height: "auto",
    overflow: "visible",
  };

  componentDidMount() {
    const mode_input_parent = localStorage?.mode_input_parent || "";
    if (mode_input_parent !== "") {
      this.setState({
        // height: height_closed,
        // overflow: "hidden",
        height: "auto",
        overflow: "visible",
      });
    }
  }

  on_set_mode_input_parent = (value) => {
    this.props.set_value_insight({
      key: "mode_input_parent",
      value,
    });
    localStorage.setItem("mode_input_parent", value);

    // setTimeout(() => {
    //   this.setState({
    //     height: height_closed,
    //     overflow: "hidden",
    //   });
    // }, delay_miliseconds);
  };

  on_toggle_height = () => {
    const new_height = this.state.height === "auto" ? height_closed : "auto";
    const new_overflow = new_height === "auto" ? "visible" : "hidden";
    this.setState({
      height: new_height,
      overflow: new_overflow,
    });
  };

  render() {
    //local storage
    const mode_input_parent = localStorage?.mode_input_parent || "";

    //local state
    const { height, overflow } = this.state;

    //global props

    //content
    return (
      <main
        id="insight_mode_option"
        className="container_light outline_transparent background_blue_light margin_bottom_extra"
        style={{
          height: height,
          overflow: overflow,
        }}
      >
        <span
          className="badge_circle background_blue margin_right margin_bottom_extra cursor_pointer"
          onClick={this.on_toggle_height}
        >
          {height === "auto" ? (
            <ICON_ARROW_UP line_color="#fff" />
          ) : (
            <ICON_ARROW_DOWN line_color="#fff" />
          )}
        </span>
        <p className="text_bold margin_bottom">
          Pilih mode yang Anda inginkan:
        </p>
        <button
          className={`button margin_right ${
            mode_input_parent === "simple" ? "background_black" : "button_white"
          }`}
          data-mapid="clickSimple"
          onClick={this.on_set_mode_input_parent.bind(this, "simple")}
        >
          Simpel
        </button>
        <button
          className={`button margin_right ${
            mode_input_parent === "custom" ? "background_black" : "button_white"
          }`}
          onClick={this.on_set_mode_input_parent.bind(this, "custom")}
          data-mapid="clickCustom"
        >
          Kustomisasi
        </button>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  map: state.map,
  layer: state.layer,
  project: state.project,
  payment: state.payment,
  properties: state.properties,
  license_reducer: state.license_reducer,
  insight_reducer: state.insight_reducer,
});

export default connect(mapStateToProps, { set_value_insight })(OPTION_DIALOG);
