/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/
import {
  set_value_bi,
  trigger_rerender,
  trigger_fly_map,
} from "../../App/actions/biActions";

/*PICTURE*/
import ICON_ARROW_RIGHT from "../../Assets/jsx/ICON_ARROW_RIGHT";

/*FUNCTION*/

/*DATA*/

/*CONST*/

class BI_SIDEBAR_LAYER_LIST extends Component {
  state = {};

  on_click_layer = (layer_id) => {
    this.props.set_value_bi({
      key: "layer_id",
      value: layer_id,
    });
    this.props.trigger_rerender();
    this.props.trigger_fly_map();
  };

  render() {
    //local storage

    //local state

    //global props
    const { bi_object, project_object_selected } = this.props.bi;

    //content
    let layer_list = project_object_selected?.layer_list || [];

    const setting_list = bi_object?.setting_list || [];
    const setting_object = setting_list.find(
      (item) => item?.project_id === project_object_selected?._id
    );
    const layer_view_map_list = setting_object?.layer_view_map_list || [];
    layer_list = layer_list.filter((item) =>
      layer_view_map_list.includes(item?.geo_layer?._id)
    );

    let content;
    if (layer_list.length === 0) {
      content = null;
    } else {
      content = (
        <table className="full_width">
          <tbody>
            {layer_list.map((item, idx) => {
              return (
                <tr
                  key={idx}
                  onClick={this.on_click_layer.bind(this, item?.geo_layer?._id)}
                >
                  <td style={{ width: "30px" }}>
                    <button className="button_small background_orange">
                      <ICON_ARROW_RIGHT fill="#fff" />
                    </button>
                  </td>
                  <td>{item?.geo_layer?.name}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
    return <main>{content}</main>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
  project: state.project,
});

export default connect(mapStateToProps, {
  set_value_bi,
  trigger_rerender,
  trigger_fly_map,
})(BI_SIDEBAR_LAYER_LIST);
