import React from "react";
import PropTypes from "prop-types";
import icon_password_hide from "../../Assets/svg/icon_password_hide.svg";
import icon_password_view from "../../Assets/svg/icon_password_view.svg";

const styleObjectNormal = {
  overflow: "hidden",
  boxSizing: "border-box",
  border: "none",
  borderRadius: "0px",
  borderBottom: "2px solid #cecece",
  padding: "10px 10px 10px 10px",
  fontSize: "16px",
  color: "#1e5e96ff",
  textAlign: "left",
};

const styleObjectError = {
  overflow: "hidden",
  boxSizing: "border-box",
  border: "none",
  borderRadius: "0px",
  borderBottom: "2px solid red",
  padding: "10px 10px 10px 10px",
  fontSize: "16px",
  color: "#1e5e96ff",
  textAlign: "left",
};

const styleObjectPassword = {
  overflow: "hidden",
  boxSizing: "border-box",
  border: "none",
  borderRadius: "0px",
  padding: "10px 10px 10px 10px",
  fontSize: "16px",
  color: "#1e5e96ff",
  textAlign: "left",
};

const stylePasswordNormal = {
  borderBottom: "2px solid #cecece",
};

const stylePasswordError = {
  borderBottom: "2px solid red",
};

const TextFieldGroupTight = ({
  name,
  placeholder,
  value,
  error,
  info,
  type,
  onChange,
  disabled,
  list,
  id,
  toggleShowPassword,
  isPassword,
  min,
  max,
}) => {
  return isPassword ? (
    <div style={{ marginBottom: "10px" }}>
      <div style={{ textAlign: "left", color: error ? "red" : "#1e5e96ff" }}>
        {error ? error : placeholder}
      </div>
      <div
        className="input_icon"
        style={error ? stylePasswordError : stylePasswordNormal}
      >
        <input
          type={type}
          placeholder={placeholder}
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          disabled={disabled}
          style={styleObjectPassword}
          list={list ? list : ""}
          autoComplete="off"
        />
        <img
          alt="show password"
          src={type === "password" ? icon_password_view : icon_password_hide}
          width="25px"
          onClick={toggleShowPassword}
        />
      </div>
      {info && <small>{info}</small>}
    </div>
  ) : (
    <div style={{ marginBottom: "10px" }}>
      <div style={{ textAlign: "left", color: error ? "red" : "#1e5e96ff" }}>
        {error ? error : placeholder}
      </div>
      <input
        type={type}
        placeholder={placeholder}
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        disabled={disabled}
        style={error ? styleObjectError : styleObjectNormal}
        list={list ? list : ""}
        autoComplete="off"
        min={min}
        max={max}
      />
      {info && <small>{info}</small>}
    </div>
  );
};
TextFieldGroupTight.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  info: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
TextFieldGroupTight.defaultProps = {
  type: "text",
};
export default TextFieldGroupTight;
