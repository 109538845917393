import React, { Component } from "react";
import { connect } from "react-redux";
import { brantasGetAllPosLatest } from "../../App/actions/iotActions";
import { Typography } from "@material-ui/core";

class iotStatisticBrantas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      lastDataPDA: [],
      lastDataPCH: [],

      show: "",
    };
  }
  componentDidMount() {
    if (
      this.props.iot.brantasLastDataPDA.length === 0 ||
      this.props.iot.brantasLastDataPCH.length === 0
    ) {
      this.props.brantasGetAllPosLatest("pda");
      this.props.brantasGetAllPosLatest("pch");
    } else {
      this.setState({ loading: false });
    }

    if (this?.props?.layer?.name?.includes("AWLR")) {
      this.setState({ show: "pda" });
    }
    if (this?.props?.layer?.name?.includes("ARR")) {
      this.setState({ show: "pch" });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let posShow = "";
    if (this?.props?.layer?.name?.includes("AWLR")) {
      posShow = "pda";
    }
    if (this?.props?.layer?.name?.includes("ARR")) {
      posShow = "pch";
    }
    if (prevState.show !== posShow) {
      this.setState({ show: posShow });
    }
  }

  formatDate(unix) {
    const d = new Date(unix);
    const y = `${d.getFullYear()}`;
    const m = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
    const h = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
    return `${h}/${m}/${y.slice(2, 4)}`;
  }
  formatTime(unix) {
    const d = new Date(unix);
    const h = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();
    const m = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();
    return `${h}:${m}`;
  }

  render() {
    const contentHead = (
      <div>
        <Typography variant="h6">{this?.props?.layer?.name}</Typography>
        <hr />
      </div>
    );
    const contentPDA = (
      <div>
        {!this.state.loading &&
          this?.props?.iot?.brantasLastDataPDA?.map((e, i) => (
            <div style={{ display: "flex", marginBottom: "1rem" }} key={i}>
              <div style={{ flex: "1" }}>
                <Typography style={{ fontSize: 12, fontWeight: "bold" }}>
                  {e?.pos?.name?.toUpperCase()}
                </Typography>
              </div>
              <div style={{ flex: "2" }}>
                {e.pos.sensors.map((p, i) => (
                  <div
                    style={{
                      display: "flex",
                      fontSize: 12,
                      alignItems: "center",
                    }}
                    key={i}
                  >
                    <div style={{ flex: "1.2", marginBottom: 3 }}>
                      {p.name_full}
                    </div>
                    <div style={{ flex: "1", marginBottom: 3 }}>
                      {p.v} {p.unit}
                    </div>
                    <div style={{ flex: "1.3", marginBottom: 3 }}>
                      {this.formatTime(p.t)} {this.formatDate(p.t)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div>
    );
    const contetnPCH = (
      <div>
        {!this.state.loading &&
          this.props.iot.brantasLastDataPCH.map((e, i) => (
            <div style={{ display: "flex", marginBottom: "1rem" }} key={i}>
              <div style={{ flex: "1" }}>
                <Typography style={{ fontSize: 12, fontWeight: "bold" }}>
                  {e?.pos?.name?.toUpperCase()}
                </Typography>
              </div>
              <div style={{ flex: "2" }}>
                {e?.pos?.sensors?.map((p, i) => (
                  <div
                    style={{
                      display: "flex",
                      fontSize: 12,
                      alignItems: "center",
                    }}
                    key={i}
                  >
                    <div style={{ flex: "1.2", marginBottom: 3 }}>
                      {p.name_full}
                    </div>
                    <div style={{ flex: "1", marginBottom: 3 }}>
                      {p.v} {p.unit}
                    </div>
                    <div style={{ flex: "1.3", marginBottom: 3 }}>
                      {this.formatTime(p.t)} {this.formatDate(p.t)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div>
    );
    return (
      <div>
        {contentHead}
        {this.state.show === "pda" && contentPDA}{" "}
        {this.state.show === "pch" && contetnPCH}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer.geo_layer.geo_layer,
  iot: state.iot,
});

export default connect(mapStateToProps, {
  brantasGetAllPosLatest,
})(iotStatisticBrantas);
