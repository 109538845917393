/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*REDUX FUNCTION*/
import { set_value_sini } from "../../App/actions/sini_v2";

/*PERSONAL COMPONENT*/

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class SINI_AI extends Component {
  state = {};

  on_activate_sini_ai = () => {
    this.props.set_value_sini({
      key: "sini_menu_active",
      value: "post_ai_chat",
    });
  };

  render() {
    return (
      <main className="container_light background_white margin_bottom outline_green">
        <div
          className="badge background_green margin_bottom"
          onClick={this.on_set_sini_menu_active}
        >
          SINI AI
        </div>
        <br />
        <div className="sini_container_animation">
          <div className="sini_shape_animation"></div>
          <div className="sini_shape_animation"></div>
          <div className="sini_shape_animation"></div>
        </div>
        <br />
        <button
          className="button background_blue"
          onClick={this.on_activate_sini_ai}
          data-mapid="clickChat"
        >
          Start chat
        </button>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { set_value_sini })(SINI_AI);
