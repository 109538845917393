//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux function
import { get_version_log } from "../../App/actions/blogAction";

//Picture Asset

//General Function

import { EditorState, RichUtils, convertFromRaw } from "draft-js";
import Editor from "draft-js-plugins-editor";

import { mediaBlockRenderer } from "../blog/mediaBlockRenderer";

class VersionLog extends Component {
  state = {};

  componentDidMount() {
    this.props.get_version_log();
  }

  toggle_edit_location = () => {
    this.setState({ modal_edit_location: !this.state.modal_edit_location });
  };

  toggleRequestAction = () => {
    this.setState({ modal_request_action: !this.state.modal_request_action });
  };

  toggleDelete = () => {
    this.setState({ modal_delete: !this.state.modal_delete });
  };

  handleDelete = (id) => {
    this.props.deleteBlog(id);
  };

  focus = () => this.refs.editor.focus();

  onChangeEditor = () => {};

  handleKeyCommand = (command) => {
    const { editorState } = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  };

  toggleBlockType = (blockType) => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  };

  toggleInlineStyle = (inlineStyle) => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
    );
  };

  render() {
    const { version_log } = this.props.blog;

    let content;

    if (version_log?._id) {
      const { editorState } = version_log;

      const contentState = convertFromRaw(JSON.parse(editorState));
      const editorStateFinal = EditorState.createWithContent(contentState);
      let className = "RichEditor-editor";

      if (!contentState.hasText()) {
        if (contentState.getBlockMap().first().getType() !== "unstyled") {
          className += " RichEditor-hidePlaceholder";
        }
      }

      content = (
        <main>
          <h1 className="text_header">What's new</h1>
          <br />
          <section
            className="RichEditor-root "
            style={{ marginBottom: "500px" }}
          >
            <div className={className} onClick={this.focus}>
              <Editor
                blockRendererFn={mediaBlockRenderer}
                blockStyleFn={getBlockStyle}
                customStyleMap={styleMap}
                editorState={editorStateFinal}
                handleKeyCommand={this.handleKeyCommand.bind(this)}
                onChange={this.onChangeEditor}
                placeholder="What's new"
                ref="editor"
                spellCheck={false}
                readOnly={true}
              />
            </div>
          </section>
        </main>
      );
    } else {
      content = <main></main>;
    }

    return content;
  }
}

const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    case "code-block":
      return "RichEditor-code-block";
    case "center":
      return "RichEditor-center";
    default:
      return null;
  }
}

const mapStateToProps = (state) => ({
  blog: state.blog,
});

export default connect(mapStateToProps, { get_version_log })(VersionLog);
