import React, { Component } from "react";

export default class HeaderUneditable extends Component {
  render() {
    return (
      <section
        className="header_table center_perfect"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "500px",
        }}
      >
        <table className="">
          <tbody>
            <tr>
              <td>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    marginLeft: 5,
                    height: "20px",
                  }}
                >
                  <strong className="truncate">{this.props.column.name}</strong>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    marginLeft: 5,
                    height: "20px",
                  }}
                >
                  <p className="truncate">
                    {this.props.column.type.replaceAll("_", " ")}
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    );
  }
}
