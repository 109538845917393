/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import MODAL_SEARCH from "../common_modal/MODAL_SEARCH";
import SEARCH_LAYER from "./SEARCH_LAYER";
import SEARCH_LOCATION from "./SEARCH_LOCATION";
import SEARCH_INSIDE_LAYER from "./SEARCH_INSIDE_LAYER";

/*REDUX*/
import { set_value_properties } from "../../App/actions/propertiesActions";
import { get_location_list } from "../../App/actions/dataActions";
import { state_update } from "../../App/actions/sini_v2";

/*PICTURE*/
import icon_search_new from "../../Assets/svg/icon_search_new.svg";
import close from "../../Assets/svg/close.svg";

/*FUNCTION*/

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/
const WAIT_INTERVAL = 1_000;
const ENTER_KEY = 13;

class SEARCH_BAR_MAP extends Component {
  state = {};

  componentDidMount() {
    this.timer = null;
  }

  on_change_input_search = (e) => {
    clearTimeout(this.timer);
    e.preventDefault();
    this.props.set_value_properties({
      key: e.target.name,
      value: e.target.value,
    });
    this.timer = setTimeout(this.trigger_action.bind(this), WAIT_INTERVAL);
  };

  handle_enter(e) {
    if (e.keyCode === ENTER_KEY) {
      this.trigger_action();
    }
  }

  trigger_action = () => {
    const { mode_search, search_input } = this.props.properties;
    if (mode_search === "layer") {
      let layer_id_filtered = [];
      if (!!search_input) {
        //filter layer
        const geo_layer_list = this.props.layer.geo_layer_list;
        layer_id_filtered = geo_layer_list
          .filter((item) => {
            const name = item?.geo_layer?.name?.toUpperCase();
            const description = item?.geo_layer?.description?.toUpperCase();
            const input = search_input?.toUpperCase();
            return name.includes(input) || description.includes(input);
          })
          .map((item) => item?.geo_layer?._id);
      }
      this.props.set_value_properties({
        key: "layer_id_filtered",
        value: layer_id_filtered,
      });
    } else {
      //access API places
      if (!!search_input) {
        this.props.get_location_list(search_input);
      }
    }
  };

  on_set_value_properties = (key, value) => {
    this.props.set_value_properties({
      key: key,
      value: value,
    });
  };

  on_activate_input_status = () => {
    this.props.set_value_properties({
      key: "modal_input",
      value: true,
    });
  };

  on_deactivate_input_status = () => {
    this.props.set_value_properties({
      key: "modal_input",
      value: false,
    });
    this.props.set_value_properties({
      key: "search_input",
      value: "",
    });
  };

  on_clear_input = () => {
    this.props.set_value_properties({
      key: "search_input",
      value: "",
    });
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state

    //global props
    const { loadingProcess } = this.props.layer;
    const { mode_search, modal_input, search_input } = this.props.properties;

    //content
    const modal_input_content = modal_input && (
      <MODAL_SEARCH
        modalSize="small"
        id="modal"
        isOpen={modal_input}
        onClose={this.on_deactivate_input_status}
      >
        <div className="box-body" style={{ overflowY: "hidden" }}>
          {/* <table className="full_width">
            <tbody>
              <tr>
                <td>
                  <img
                    src={icon_search_new}
                    alt="icon_search_new"
                    height="18px"
                    style={{ margin: "auto" }}
                  />
                </td>
                <td className="full_width">
                  <input
                    type="text"
                    onChange={this.on_change_input_search.bind(this)}
                    onKeyDown={this.handle_enter.bind(this)}
                    value={search_input}
                    name="search_input"
                    placeholder={dict?.["Search"]?.[language]}
                    autoFocus
                  />
                </td>
                {!!search_input && (
                  <td>
                    <button
                      className="button_pill background_grey"
                      onClick={this.on_clear_input}
                    >
                      {dict?.["clear"]?.[language]}
                    </button>
                  </td>
                )}
              </tr>
            </tbody>
          </table> */}
          <table className="full_width no_margin" style={{ marginTop: "30px" }}>
            <tbody>
              <tr>
                <td>
                  <button
                    onClick={this.on_set_value_properties.bind(
                      this,
                      "mode_search",
                      "layer"
                    )}
                    className={`button_pill_tight margin_right margin_bottom margin_top ${
                      mode_search === "layer" || mode_search === "search_inside"
                        ? "background_teal outline_blue"
                        : "button_white outline_grey"
                    }`}
                  >
                    {dict?.["Data"]?.[language]}
                  </button>
                  <button
                    onClick={this.on_set_value_properties.bind(
                      this,
                      "mode_search",
                      "location"
                    )}
                    className={`button_pill_tight margin_right margin_bottom margin_top ${
                      mode_search === "location"
                        ? "background_teal outline_blue"
                        : "button_white outline_grey"
                    }`}
                  >
                    {dict?.["Location"]?.[language]}
                  </button>
                </td>
                <td className="text_right">
                  <img
                    src={close}
                    alt="close"
                    height="18px"
                    style={{ margin: "auto" }}
                    className="cursor_pointer"
                    onClick={this.on_deactivate_input_status}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          {mode_search === "layer" ? (
            <SEARCH_LAYER
              on_deactivate_input_status={this.on_deactivate_input_status}
            />
          ) : mode_search === "search_inside" ? (
            <SEARCH_INSIDE_LAYER
              on_deactivate_input_status={this.on_deactivate_input_status}
            />
          ) : (
            <SEARCH_LOCATION
              on_deactivate_input_status={this.on_deactivate_input_status}
            />
          )}
        </div>
      </MODAL_SEARCH>
    );

    return (
      <main
        style={{
          display: `${loadingProcess ? "none" : ""}`,
        }}
      >
        <div
          onClick={this.on_activate_input_status}
          className={
            modal_input
              ? "search_center_content_show"
              : "search_center_content_hide"
          }
        >
          {!!modal_input ? (
            <table className="full_width">
              <tbody>
                <tr>
                  <td>
                    <img
                      src={icon_search_new}
                      alt="icon_search_new"
                      height="18px"
                      style={{ margin: "auto" }}
                    />
                  </td>
                  <td className="full_width">
                    <input
                      type="text"
                      onChange={this.on_change_input_search.bind(this)}
                      onKeyDown={this.handle_enter.bind(this)}
                      value={search_input}
                      name="search_input"
                      placeholder={dict?.["Search"]?.[language]}
                      autoFocus
                    />
                  </td>
                  {!!search_input && (
                    <td>
                      <button
                        className="button_pill background_grey"
                        onClick={this.on_clear_input}
                      >
                        {dict?.["clear"]?.[language]}
                      </button>
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          ) : (
            <div>
              <img
                src={icon_search_new}
                alt="icon_search_new"
                height="18px"
                className="inline margin_right"
              />
              <p className="text_medium inline margin_left">
                {dict?.["Search"]?.[language]}
              </p>
            </div>
          )}
        </div>
        {modal_input_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  project: state.project,
  map: state.map,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  set_value_properties,
  get_location_list,
  state_update,
})(SEARCH_BAR_MAP);
