/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import writeXlsxFile from "write-excel-file";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class SINI_DOWNLOAD_UNIVERSAL extends Component {
  state = {};

  on_download_geojson = () => {
    const { request_id_active, sini_v2_list, ai_parent_detail_list } =
      this.props.sini;
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    const sini_object = merge_array.find(
      (item) => item.request_id === request_id_active
    );
    const download_topic = this.props.download_topic;
    const json = sini_object[download_topic];
    const jsonString = JSON.stringify(json);
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `SINI_${download_topic}_${sini_object.title_1}.geojson`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  on_download_excel = () => {
    const { request_id_active, sini_v2_list, ai_parent_detail_list } =
      this.props.sini;
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    const sini_object = merge_array.find(
      (item) => item.request_id === request_id_active
    );
    const download_topic = this.props.download_topic;
    const json = sini_object[download_topic];
    const json_download = json.properties;

    const rows = Object.keys(json_download).map((key) => [
      { value: key, fontWeight: "bold" },
      { value: json_download[key] },
    ]);

    writeXlsxFile(rows, {
      fileName: `SINI_${download_topic}_${sini_object.title_1}.xlsx`,
    });
  };

  render() {
    //local storage

    //local state

    //global props

    //content
    return (
      <main>
        <button
          className="button_small margin_bottom margin_right"
          onClick={this.on_download_excel}
        >
          Excel
        </button>
        <br />
        <button
          className="button_small margin_bottom margin_right"
          onClick={this.on_download_geojson}
        >
          Geojson
        </button>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  sini: state.sini,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, {})(SINI_DOWNLOAD_UNIVERSAL);
