import React, { Component } from "react";

class IconRenameText extends Component {
  render() {
    return (
      <svg
        id="svg8"
        version="1.1"
        viewBox="0 0 1.2647654 1.2646819"
        height="15px"
        width="15px"
      >
        <defs id="defs2" />
        <g transform="translate(-300.3925,-174.78446)" id="layer1">
          <path
            id="path940"
            transform="matrix(0.26458333,0,0,0.26458333,292.19981,173.69977)"
            d="m 33.519531,5.0761719 a 0.19900897,0.19900897 0 0 1 0.140625,0.058594 l 1.050782,1.0507813 a 0.19900897,0.19900897 0 0 1 0,0.28125 l -2.154297,2.15625 a 0.19900897,0.19900897 0 0 1 -0.111329,0.054687 l -1.25,0.1992187 A 0.19900897,0.19900897 0 0 1 30.966797,8.6503906 l 0.199219,-1.25 a 0.19900897,0.19900897 0 0 1 0.05664,-0.109375 l 2.15625,-2.15625 a 0.19900897,0.19900897 0 0 1 0.140625,-0.058594 z"
            style={{
              fill: this.props.color ? this.props.color : "#000000",
              stroke: "none", // Set stroke to none
              strokeWidth: "1.00157",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              fillOpacity: "1",
            }}
          />
          <path
            id="path938"
            transform="matrix(0.26458333,0,0,0.26458333,292.19981,173.69977)"
            d="m 34.496094,4.0996094 a 0.19900897,0.19900897 0 0 1 0.140625,0.058594 l 1.048828,1.0527344 a 0.19900897,0.19900897 0 0 1 0,0.2832031 l -0.433594,0.4335938 a 0.19900897,0.19900897 0 0 1 -0.28125,0 L 33.917969,4.8769531 a 0.19900897,0.19900897 0 0 1 0,-0.28125 l 0.4375,-0.4375 a 0.19900897,0.19900897 0 0 1 0.140625,-0.058594 z"
            style={{
              fill: this.props.color ? this.props.color : "#000000",
              stroke: "none", // Set stroke to none
              strokeWidth: "1.00157",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              fillOpacity: "1",
            }}
          />
        </g>
      </svg>
    );
  }
}

export default IconRenameText;
