/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import {
  pull_value_sini_object,
  set_value_sini,
  fly_init_update,
} from "../../App/actions/sini_v2";

/*PICTURE ASSET*/
import icon_expand_bottom from "../../Assets/svg/icon_expand_bottom.svg";

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class SINI_DEMOGRAPHY extends Component {
  state = {};

  on_set_sini_menu_active = () => {
    this.props.set_value_sini({
      key: "sini_menu_active",
      value: "demography_one",
    });
    this.props.set_value_sini({
      key: "sini_map_show",
      value: ["demography_one", "demography_neighbor"],
    });
  };

  on_activate_sini_ai = () => {
    this.props.set_value_sini({
      key: "sini_menu_active",
      value: "post_ai_chat",
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const { sini_v2_list, request_id_active, ai_parent_detail_list } =
      this.props.sini;

    //content
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    const sini_object = merge_array.find(
      (item) => item.request_id === request_id_active
    );
    const properties = sini_object?.demography_one?.properties || {};
    const demography_one = sini_object?.demography_one;
    let content;
    if (demography_one !== undefined) {
      content = (
        <section className="container_light background_white margin_bottom outline_green">
          <table className="full_width">
            <tbody>
              <tr>
                <td className="text_left">
                  <div
                    className="badge background_green margin_bottom"
                    onClick={this.on_set_sini_menu_active}
                  >
                    {dict?.["Demographics"]?.[language]}
                  </div>
                  <section className="margin_bottom">
                    <p className="text_small">Desa</p>
                    <p className="text_bold">
                      {properties?.["DESA"] ||
                        properties?.["DESA ATAU KELURAHAN"] ||
                        ""}
                    </p>
                  </section>
                  <section className="margin_bottom">
                    <p className="text_small">Kota</p>
                    <p className="text_bold">
                      {properties?.["KABKOT"] ||
                        properties?.["KABUPATEN ATAU KOTA"] ||
                        ""}
                    </p>
                  </section>
                  <section className="margin_bottom">
                    <p className="text_small">Jumlah penduduk</p>
                    <p className="text_bold">
                      {new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(properties?.["JUMLAH PENDUDUK"])}
                    </p>
                  </section>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="text_center">
            <img
              alt="expand"
              src={icon_expand_bottom}
              width={45}
              className="cursor_pointer"
              onClick={this.on_set_sini_menu_active}
              data-mapid="clickOpenDemografi"
            />
          </div>
        </section>
      );
    }

    return <main>{content}</main>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  sini: state.sini,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, {
  pull_value_sini_object,
  set_value_sini,
  fly_init_update,
})(SINI_DEMOGRAPHY);
