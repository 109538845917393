const get_year_kai_pendapatan_non_farebox = (geo_layer) => {
  const timeseries_mode = geo_layer?.timeseries_object?.timeseries_mode;

  let timeseries_komponen_array = [];

  if (timeseries_mode === "kai_pendapatan_non_farebox") {
    const timeseries_object = geo_layer?.timeseries_object || {};

    const {
      year_array, // [ 2022, 2023, 2024, 2025, 2026, 2027]
      field_name_tahun,
      field_name_income_rental_space,
      field_name_income_parkir,
      field_name_income_iklan,
      field_name_income_naming_right,
      field_name_income_total,
    } = timeseries_object;

    const fields = geo_layer?.fields || [];
    const features = geo_layer?.geojson?.features || [];

    //sumbu x
    const field_key_tahun = fields.find(
      (item) => item.name === field_name_tahun
    )?.key;

    //sumbu y
    const field_key_income_rental_space = fields.find(
      (item) => item.name === field_name_income_rental_space
    )?.key;
    const field_key_income_parkir = fields.find(
      (item) => item.name === field_name_income_parkir
    )?.key;
    const field_key_income_iklan = fields.find(
      (item) => item.name === field_name_income_iklan
    )?.key;
    const field_key_income_naming_right = fields.find(
      (item) => item.name === field_name_income_naming_right
    )?.key;
    const field_key_income_total = fields.find(
      (item) => item.name === field_name_income_total
    )?.key;

    //konversi dari features ke data_array
    timeseries_komponen_array = [
      {
        name: field_name_income_rental_space,
        key: field_key_income_rental_space,
      },
      {
        name: field_name_income_parkir,
        key: field_key_income_parkir,
      },
      {
        name: field_name_income_iklan,
        key: field_key_income_iklan,
      },
      {
        name: field_name_income_naming_right,
        key: field_key_income_naming_right,
      },
      {
        name: field_name_income_total,
        key: field_key_income_total,
      },
    ];

    timeseries_komponen_array = timeseries_komponen_array.map((item) => {
      let data = [];
      features.forEach((feature) => {
        const value = feature?.properties?.[item.key]; //get value
        const tahun_value = feature?.properties?.[field_key_tahun]; //get tahun_value
        const tahun_index = year_array.findIndex(
          (item) => Number(item) === Number(tahun_value)
        ); //get tahun_index
        data[tahun_index] = Number(value); //set each value to year_index position
      });

      //mencari nilai maksimum maksimum dari data_array
      const y_max = Math.max(...data);

      //mendapatkan index dari nilai maksimum
      const index_max = data.findIndex(
        (value) => Number(value) === Number(y_max)
      );

      //mendapatkan nilai dari sumbu x yang sumbu y nya maksimum
      const x_max = year_array[index_max];

      const chart_js_data = {
        labels: year_array,
        y_max,
        x_max,
        index_max,
        datasets: [
          {
            label: item.name,
            labels: year_array,
            data: data,
            backgroundColor: "#373784", //warna dark_blue KAI
          },
        ],
      };

      return { ...item, chart_js_data };
    });
  }

  return timeseries_komponen_array;
};

export default get_year_kai_pendapatan_non_farebox;
