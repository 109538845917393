/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/

/*PICTURE*/

/*FUNCTION*/

/*DATA*/

/*CONST*/

class ARR_DETAIL extends Component {
  state = {
    mode_formula: "hide", //show || hide
  };

  on_set_mode_formula = (mode_formula) => {
    this.setState({
      mode_formula,
    });
  };

  render() {
    //local storage

    //local state
    const { mode_formula } = this.state;

    //global props
    const {
      alt_selected,
      luas_tanah,
      luas_bangunan,
      // project_duration_years,
      // discount_rate_1_percent,
      // revenue_growth_percent,
      // opex_growth_percent,
      working_capital_idr,
      // discount_rate_2_percent,
      economic_life_years,
      // tax_percent,
      skema_terminal_value
    } = this.props.hbu_reducer;
    const { ai_parent_detail_list, sheet_object_selected } = this.props.sini;

    //content
    const sini_object = ai_parent_detail_list.find(
      (item) => item?.request_id === sheet_object_selected?.request_id
    );
    const landvalue_one = sini_object?.landvalue_one;
    const landvalue_value = landvalue_one?.properties?.["HARGA"];
    const {
      capex_tanah,
      capex_total,
      BIAYA_PEMBANGUNAN_PER_M_2,
      capex_bangunan,
      JENIS,
      depresiasi,
      PENDAPATAN_PER_M_2_MAX,
      BIOP_PER_M_2_MIN,
      r_1,
      b_1,
      // sum_pv,
      // npv,
      // npv_2,
      // irr,
      arr,
      aoi,
    } = alt_selected;
    const finance_array = alt_selected?.finance_array?.slice(0, -1) || [];

    const finance_array_tv = alt_selected?.finance_array || [];

    return (
      <main className="max_height">
        <h1 className="text_header margin_bottom">Detail Formula ARR</h1>

        <section className="container background_grey_light margin_bottom">
          <h2 className="text_bold margin_bottom">
            Data objek dan alternatif yang diklik
          </h2>
          <table className="table margin_bottom">
            <tbody>
              <tr>
                <td>Luas tanah</td>
                <td>
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(luas_tanah)}
                </td>
              </tr>
              <tr>
                <td>Luas bangunan</td>
                <td>
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(luas_bangunan)}
                </td>
              </tr>
              <tr>
                <td colSpan={2}></td>
              </tr>
              <tr>
                <td>Harga tanah per meter persegi (SINI MAPID)</td>
                <td>
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(landvalue_value)}
                </td>
              </tr>
              <tr>
                <td>Nama alternatif</td>
                <td>{JENIS}</td>
              </tr>
              <tr>
                <td>Biaya pembangunan per meter persegi</td>
                <td>
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(BIAYA_PEMBANGUNAN_PER_M_2)}
                </td>
              </tr>
              <tr>
                <td>Pendapatan per meter persegi</td>
                <td>
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(PENDAPATAN_PER_M_2_MAX)}
                </td>
              </tr>
              <tr>
                <td>Biaya operasional per meter persegi</td>
                <td>
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(BIOP_PER_M_2_MIN)}
                </td>
              </tr>
              <tr>
                <td>{`Asumsi Umur Ekonomis (tahun)`}</td>
                <td>
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(economic_life_years)}
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <section className="container background_grey_light margin_bottom">
          <h2 className="text_bold margin_bottom">Nilai Dasar Awal</h2>
          <table>
            <tbody>
              <tr>
                <td>CAPEX tanah</td>
                <td>= Luas tanah x Harga tanah per meter persegi</td>
              </tr>
              <tr>
                <td>CAPEX tanah</td>
                <td>
                  ={" "}
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(luas_tanah)}{" "}
                  x{" "}
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(landvalue_value)}
                </td>
              </tr>
              <tr>
                <td>CAPEX tanah</td>
                <td>
                  ={" "}
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(capex_tanah)}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <hr />
                </td>
              </tr>
              <tr>
                <td>CAPEX bangunan</td>
                <td>= Luas bangunan x Biaya pembangunan per meter persegi</td>
              </tr>
              <tr>
                <td>CAPEX bangunan</td>
                <td>
                  ={" "}
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(luas_bangunan)}{" "}
                  x{" "}
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(BIAYA_PEMBANGUNAN_PER_M_2)}
                </td>
              </tr>
              <tr>
                <td>CAPEX bangunan</td>
                <td>
                  ={" "}
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(capex_bangunan)}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <hr />
                </td>
              </tr>
              <tr>
                <td>CAPEX total</td>
                <td>= capex_tanah + capex_bangunan;</td>
              </tr>
              <tr>
                <td>CAPEX total</td>
                <td>
                  ={" "}
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(capex_tanah)}{" "}
                  +{" "}
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(capex_bangunan)}
                </td>
              </tr>
              <tr>
                <td>CAPEX total</td>
                <td>
                  ={" "}
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(capex_total)}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <hr />
                </td>
              </tr>
              <tr>
                <td>Depresiasi</td>
                <td>= capex_total / Umur Ekonomis</td>
              </tr>
              <tr>
                <td>Depresiasi</td>
                <td>
                  ={" "}
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(capex_total)}{" "}
                  /{" "}
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(economic_life_years)}
                </td>
              </tr>
              <tr>
                <td>Depresiasi</td>
                <td>
                  ={" "}
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(parseInt(depresiasi))}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <hr />
                </td>
              </tr>
              <tr>
                <td>Pendapatan di tahun pertama (r_1)</td>
                <td>= Pendapatan per meter persegi x Luas bangunan</td>
              </tr>
              <tr>
                <td>Pendapatan di tahun pertama (r_1)</td>
                <td>
                  ={" "}
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(parseInt(PENDAPATAN_PER_M_2_MAX))}{" "}
                  x{" "}
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(parseInt(luas_bangunan))}
                </td>
              </tr>
              <tr>
                <td>Pendapatan di tahun pertama (r_1)</td>
                <td>
                  ={" "}
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(parseInt(r_1))}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <hr />
                </td>
              </tr>
              <tr>
                <td>Biaya operasional di tahun pertama (b_1)</td>
                <td>= BiOp per meter persegi x Luas bangunan</td>
              </tr>
              <tr>
                <td>Biaya operasional di tahun pertama (b_1)</td>
                <td>
                  ={" "}
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(parseInt(BIOP_PER_M_2_MIN))}{" "}
                  x{" "}
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(parseInt(luas_bangunan))}
                </td>
              </tr>
              <tr>
                <td>Biaya operasional di tahun pertama (b_1)</td>
                <td>
                  ={" "}
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(parseInt(b_1))}
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <section className="container background_grey_light margin_bottom">
          <h2 className="text_bold margin_bottom">
            Tabel Finansial Pertahun Selama Durasi Proyek
          </h2>
          <button
            className={`button outline_black margin_right margin_bottom ${
              mode_formula === "hide" ? "background_black" : "button_white"
            }`}
            onClick={this.on_set_mode_formula.bind(this, "hide")}
          >
            Sembunyikan formula
          </button>
          <button
            className={`button outline_black margin_right margin_bottom ${
              mode_formula === "show" ? "background_black" : "button_white"
            }`}
            onClick={this.on_set_mode_formula.bind(this, "show")}
          >
            Tampilkan formula
          </button>
          <aside style={{ overflowX: "auto" }}>
            <table className="table" style={{ minWidth: "100%" }}>
              <tbody>
                <tr>
                  <td>No</td>
                  <td>Perhitungan</td>
                  {finance_array.map((item, idx) => {
                    return <td key={idx}>Tahun ke-{idx + 1}</td>;
                  })}
                </tr>
                <tr>
                  <td>1</td>
                  <td>
                    <p>Pendapatan (R)</p>
                    {mode_formula === "show" && (
                      <>
                        <br />
                        <p>Rn = R1 + (n-1) * a</p>
                        <p>
                          Rn = R1 + (n-1) * (revenue_growth_percent/100) * R1
                        </p>
                      </>
                    )}
                  </td>
                  {finance_array.map((item, idx) => {
                    return (
                      <td key={idx} className="text_right">
                        <p>
                          {new Intl.NumberFormat("id-ID", {
                            style: "decimal",
                          }).format(parseInt(item?.r_n))}
                        </p>
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    <p>Biaya Operasional (B)</p>
                    {mode_formula === "show" && (
                      <>
                        <br />
                        <p>Bn = B1 + (n-1) * b</p>
                        <p>Bn = B1 + (n-1) * (opex_growth_percent/100) * B1</p>
                      </>
                    )}
                  </td>
                  {finance_array.map((item, idx) => {
                    return (
                      <td key={idx} className="text_right">
                        {new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(parseInt(item?.b_n))}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    <p>Earning After Tax (EAT)</p>
                    {mode_formula === "show" && (
                      <>
                        <br />
                        <p>
                          EATn = Rn - Bn - depresiasi - ( (tax_percent/100) *
                          (Rn - Bn - depresiasi) )
                        </p>
                      </>
                    )}
                  </td>
                  {finance_array.map((item, idx) => {
                    return (
                      <td key={idx} className="text_right">
                        {new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(parseInt(item?.eat_n))}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <td>4</td>
                  <td>
                    <p>Cash flow (CF)</p>
                    {mode_formula === "show" && (
                      <>
                        <br />
                        <p>CFn = EATn + depresiasi</p>
                        <p>CFn = EATn + capex_total/economic_life_years</p>
                      </>
                    )}
                  </td>
                  {finance_array.map((item, idx) => {
                    const isLastIndex = idx === finance_array.length - 1;
                    const cf_value = skema_terminal_value && isLastIndex ? finance_array_tv[finance_array_tv.length - 1]?.tv_rp + finance_array_tv[finance_array_tv.length - 1]?.cf_n_10 : item?.cf_n;
                    return (
                      <td key={idx} className="text_right">
                        {new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(parseInt(cf_value))}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <td>5</td>
                  <td>
                    <p>Present Value (PV)</p>
                    {mode_formula === "show" && (
                      <>
                        <br />
                        <p>PVn = CFn * DRn</p>
                        <p>
                          PVn = CFn * ( 1 / (( 1 + discount_rate_1_percent/100 )
                          ** n_year ) )
                        </p>
                      </>
                    )}
                  </td>
                  {finance_array.map((item, idx) => {
                    const isLastIndex = idx === finance_array.length - 1;
                    const lastTvRp = finance_array_tv[finance_array_tv.length - 1]?.tv_rp || 0;
                    const lastCfN = finance_array_tv[finance_array_tv.length - 1]?.cf_n_10 || 0;
                    const pv_value = skema_terminal_value && isLastIndex  
                        ? (lastTvRp + lastCfN) * item?.drn 
                        : item?.pv_n;
                    return (
                      <td key={idx} className="text_right">
                        {new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(parseInt(pv_value))}
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </aside>
        </section>

        <section className="container background_grey_light">
          <h2 className="text_bold margin_bottom">Menghitung ARR</h2>
          <table>
            <tbody>
              <tr>
                <td>ARR</td>
                <td>= EAT / (AoI + working_capital_idr) * 100 %</td>
              </tr>
              <tr>
                <td>ARR</td>
                <td>
                  ={" "}
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(finance_array[0].eat_n)}
                  {" / "} (
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(aoi)}
                  {" + "}
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(working_capital_idr)} )
                  {" * "} 100 %
                </td>
              </tr>
              <tr>
                <td>ARR</td>
                <td>
                  ={" "}
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(arr)}
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
  hbu_reducer: state.hbu_reducer,
});

export default connect(mapStateToProps, {})(ARR_DETAIL);