/* eslint-disable */
import React, { Component } from "react";
import DropdownOrData from "../DropdownOrData";
import { Dropdown } from "../../../reuseable";
import ColumnsSelectionChildTable from "../ColumnsSelectionChildTable";

export default class C11_InfrastrukturDanFasilitas2 extends Component {
  render() {
    const { fields, placeholder = "Pilih", body, mode } = this.props;
    return (
      <table className="table_pdf c11">
        <thead>
          <tr className="tr_border_bottom_pdf subtitle_pdf">
            <td>
              <p>PROGRES PENYELESAIAN BANGUNAN</p>
            </td>
            <td>
              <DropdownOrData
                id="progres_penyelesaian_bangunan_pdf"
                {...this.props}
              />
            </td>
          </tr>
          {mode === "setup" && (
            <tr>
              <td>
                <p>Tabel Sarana Pelengkap</p>
                <Dropdown
                  className="w_100"
                  placeholder={placeholder}
                  filter_on
                  is_enable_empty
                  value={body?.["c11_sarana_pelengkap_pdf"] || ""}
                  onChange={(value) => {
                    if (this.props.onChange) {
                      this.props.onChange("c11_sarana_pelengkap_pdf", value);
                    }
                  }}
                >
                  {fields &&
                    fields.map((field) => (
                      <option key={field.key} value={field.key}>
                        {field.name}
                      </option>
                    ))}
                </Dropdown>
              </td>
            </tr>
          )}
          {mode === "setup" && body?.["c11_sarana_pelengkap_pdf"] && (
            <tr>
              <td>
                <p>Nama Sarana Pelengkap</p>
                <ColumnsSelectionChildTable
                  id="c3_nama_sarana_pelengkap_pdf"
                  column_parent_uuid={body["c11_sarana_pelengkap_pdf"]}
                  {...this.props}
                />
              </td>
              <td>
                <p>Keterangan Sarana Pelengkap</p>
                <ColumnsSelectionChildTable
                  id="c3_keterangan_sarana_pelengkap_pdf"
                  column_parent_uuid={body["c11_sarana_pelengkap_pdf"]}
                  {...this.props}
                />
              </td>
            </tr>
          )}
        </thead>
        <tbody id="c11_tbody_sarana_pelengkap_pdf">
          {/* <tr>
            <td colSpan={2}>
              <p>SARANA PELENGKAP</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Pagar</p>
            </td>
            <td>
              <DropdownOrData id="pagar_pdf" {...this.props} />
            </td>
          </tr>
          <tr>
            <td>
              <p>Carport</p>
            </td>

            <td>
              <DropdownOrData id="carpot_pdf" {...this.props} />
            </td>
          </tr>
          <tr>
            <td>
              <p>Taman</p>
            </td>
            <td>
              <DropdownOrData id="taman_pdf" {...this.props} />
            </td>
          </tr>
          <tr>
            <td>
              <p>Lainnya</p>
            </td>

            <td>
              <DropdownOrData id="lainnya_pdf" {...this.props} />
            </td>
          </tr> */}
        </tbody>
      </table>
    );
  }
}
