import React, { Component } from 'react'
import icon_close from './../svg/x_white.svg';

export default class ICON_CLOSE extends Component {
  render() {
    const { className = "", color = "black" } = this.props;
    const size = !isNaN(this.props.size) ? Number(this.props.size) : 20;

    return (
      <div
        className={`rounded_full center_perfect ${className}`}
        style={{
          height: `${size}px`,
          width: `${size}px`,
          background: color,
        }}
      >
        <img
          src={icon_close}
          width={`${size / 2}px`}
          alt="checklist"
        />
      </div>
    )
  }
}