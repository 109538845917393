const get_relasi_kai = (geo_layer) => {
  const timeseries_mode = geo_layer?.timeseries_object?.timeseries_mode;

  let relasi_array_of_object = [];
  let relasi_array_of_string = [];
  let max_penumpang = 0;
  let max_passenger = 0;
  let max_train = 0;

  if (timeseries_mode === "kai" || timeseries_mode === "kai_income_farebox") {
    const timeseries_object = geo_layer?.timeseries_object || {};
    const {
      field_name_relasi,
      field_name_array_month,
      field_name_jumlah_pnp,
      field_name_jumlah_kereta,
    } = timeseries_object;

    const fields = geo_layer?.fields || [];
    const features_original = geo_layer?.geojson?.features || [];

    const field_key_relasi = fields.find(
      (item) => item.name === field_name_relasi
    )?.key;

    const field_key_jumlah_pnp = fields.find(
      (item) => item.name === field_name_jumlah_pnp
    )?.key;
    const field_key_jumlah_kereta = fields.find(
      (item) => item.name === field_name_jumlah_kereta
    )?.key;

    //generate array relasi dengan looping pada features_original, semua feature/row yang ada
    let passenger_array = [];
    let train_array = [];
    features_original.forEach((feature) => {
      //generate sum
      let pnp_number = Number(feature?.properties?.[field_key_jumlah_pnp]);
      if (isNaN(pnp_number) && feature?.properties?.[field_key_jumlah_pnp]) {
        pnp_number = feature?.properties?.[field_key_jumlah_pnp].replace(
          ",",
          ""
        );
        pnp_number = Number(pnp_number);
      } else if (!feature?.properties?.[field_key_jumlah_pnp]) {
        pnp_number = 0;
      }
      let train_number = Number(feature?.properties?.[field_key_jumlah_kereta]);
      if (
        isNaN(train_number) &&
        feature?.properties?.[field_key_jumlah_kereta]
      ) {
        train_number = feature?.properties?.[field_key_jumlah_kereta].replace(
          ",",
          ""
        );
        train_number = Number(train_number);
      } else if (!feature?.properties?.[field_key_jumlah_kereta]) {
        train_number = 0;
      }
      passenger_array.push(pnp_number);
      train_array.push(train_number);
      //generate array relasi
      const relasi_value = feature?.properties?.[field_key_relasi];
      if (!relasi_array_of_string.includes(relasi_value)) {
        relasi_array_of_string.push(relasi_value);
        relasi_array_of_object.push({
          name: relasi_value,
          array: [],
        });
      }
    });

    max_passenger = Math.max(...passenger_array);
    max_train = Math.max(...train_array);

    //merapikan array relasi dan konversi ke format library chart js
    relasi_array_of_object = relasi_array_of_object.map((relasi_object) => {
      const features = features_original.filter(
        (feature) =>
          feature?.properties?.[field_key_relasi] === relasi_object.name
      );
      const data_array = field_name_array_month.map((month_name) => {
        const field_key_month = fields.find(
          (item) => item.name === month_name
        )?.key;
        const value_array = features.map((feature) => {
          return Number(feature?.properties?.[field_key_month]);
        });
        const value_sum_number = value_array.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        return value_sum_number;
      });

      //mencari nilai maksimum maksimum dari data_array
      const y_max = Math.max(...data_array);

      //mendapatkan index dari nilai maksimum
      const index_max = data_array.findIndex((value) => value === y_max);

      //mendapatkan nilai dari sumbu x yang sumbu y nya maksimum
      const x_max = field_name_array_month[index_max];

      const chart_js_data = {
        labels: field_name_array_month,
        y_max,
        x_max,
        index_max,
        datasets: [
          {
            label: relasi_object?.name,
            labels: field_name_array_month,
            data: data_array,
            backgroundColor: "#373784", //warna dark_blue KAI
          },
        ],
      };

      return { ...relasi_object, chart_js_data };
    });

    if (timeseries_mode === "kai") {
      //generate array bulan max_penumpang
      const data_array = field_name_array_month.map((month_name) => {
        const field_key_month = fields.find(
          (item) => item.name === month_name
        )?.key;
        const value_array = features_original.map((feature) => {
          let number_final = Number(feature?.properties?.[field_key_month]);
          if (
            isNaN(Number(feature?.properties?.[field_key_month])) &&
            feature?.properties?.[field_key_month]
          ) {
            number_final = feature?.properties?.[field_key_month].replace(
              ",",
              ""
            );
            number_final = Number(number_final);
          } else if (!feature?.properties?.[field_key_month]) {
            number_final = 0;
          }
          return number_final;
        });
        const value_sum_number = value_array.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        return value_sum_number;
      });
      max_penumpang = Math.max(...data_array);
    }
  }
  return { relasi_array_of_object, max_penumpang, max_passenger, max_train };
};

export default get_relasi_kai;
