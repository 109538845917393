import React, { useEffect, useState } from "react";
import icon_password_hide from "../../Assets/svg/icon_password_hide.svg";
import icon_password_view from "../../Assets/svg/icon_password_view.svg";

import isEmpty from "../../App/validation/is-empty";
import dict from "../../Data/dict.json";

import phoneNumberList from "../../Data/PhoneNumber.json";
import { get_country_code_and_phone_number } from "../../App/validation/phone_number";

const styleObjectNormal = {
  overflow: "hidden",
  boxSizing: "border-box",
  borderRadius: "7px",
  border: "2px solid #e8ecf4",
  backgroundColor: "#ffffff",
  paddingLeft: "10px",
  fontSize: "16px",
  color: "#000000",
  textAlign: "left",
  width: "100%",
  height: "60px",
};

const styleObjectError = {
  overflow: "hidden",
  boxSizing: "border-box",
  borderRadius: "7px",
  border: "2px solid red",
  backgroundColor: "#ffffff",
  padding: "10px 10px 10px 10px",
  fontSize: "16px",
  color: "#000000",
  textAlign: "left",
  width: "100%",
  height: "60px",
};

const styleObjectPassword = {
  overflow: "hidden",
  boxSizing: "border-box",
  borderRadius: "7px",
  marginBottom: "0px",
  border: "none",
  backgroundColor: "#ffffff",
  paddingLeft: "5px",
  fontSize: "16px",
  color: "#000000",
  textAlign: "left",
  width: "100%",
  height: "60px",
};

const stylePasswordNormal = {
  borderRadius: "7px",
  border: "2px solid #e8ecf4",
  backgroundColor: "#ffffff",
};

const stylePasswordError = {
  borderRadius: "7px",
  border: "2px solid red",
  backgroundColor: "#ffffff",
};

const TextFieldGroup = (props) => {
  const {
    name,
    placeholder,
    value,
    error,
    info,
    type,
    onChange,
    disabled,
    list,
    id,
    toggleShowPassword,
    isPassword,
    list_array,
    pattern,
    defaultValue,
    Title,
    handleKeyDown,
    ...rest
  } = props


  const [countryCode, setCountryCode] = useState("62");
  const [phoneNumber, setPhoneNumber] = useState("");

  const onChangeCountryCode = (code) => {
    const e = {
      target: {
        name: "phone_number",
        value: code + phoneNumber,
      },
    };
    setCountryCode(code);
    onChange(e);
  };
  const onChangePhone = (phoneNumber) => {
    const newPhoneNumber = String(phoneNumber)?.[0] === "0" ? "" : phoneNumber;
    const value = countryCode + newPhoneNumber;
    const e = {
      target: {
        name: "phone_number",
        value: value,
      },
    };
    setPhoneNumber(newPhoneNumber);
    onChange(e);
  };

  useEffect(() => {
    if (type === "phone_number") {
      const number_object = get_country_code_and_phone_number(value);
      setCountryCode(number_object?.countryCode || "62");
      setPhoneNumber(number_object?.phoneNumber || "");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const language = localStorage?.language ? localStorage?.language : "ina";
  let title = Title ? Title : placeholder;
  if (error) {
    title = dict?.[error]?.[language] ? dict?.[error]?.[language] : error;
  }
  let itemContent;
  if (isPassword) {
    itemContent = (
      <div className="input_old">
        <div
          style={{
            textAlign: "left",
            color: error ? "red" : "black",
            fontSize: "1rem",
            fontWeight: "600",
            margin: "10px 0 10px 0",
          }}
        >
          {title}
        </div>
        <div
          className="input_icon"
          style={error ? stylePasswordError : stylePasswordNormal}
        >
          <input
            type={type}
            placeholder={placeholder}
            name={name}
            id={id}
            pattern={pattern}
            value={value}
            onChange={onChange}
            defaultValue={defaultValue}
            disabled={disabled}
            style={styleObjectPassword}
            list={list ? list : ""}
            autoComplete="off"
            data-mapid="insertFormPw"
            onKeyDown={(e) => {
              if (handleKeyDown) {
                handleKeyDown(e)
              }
            }}
            {...rest}
          />
          <img
            className="pointer"
            alt="show password"
            src={type === "password" ? icon_password_view : icon_password_hide}
            width="25px"
            onClick={toggleShowPassword}
          />
        </div>
        {info && <small>{info}</small>}
      </div>
    );
  } else {
    if (isEmpty(list_array)) {
      if (type === "phone_number") {
        itemContent = (
          <div className="input_old">
            <div
              style={{
                textAlign: "left",
                color: error ? "red" : "black",
                fontSize: "1rem",
                fontWeight: "600",
                margin: "10px 0 10px 0",
              }}
            >
              {title}
            </div>
            <div className="flex gap_5">
              <select
                value={countryCode}
                onChange={(e) => {
                  onChangeCountryCode(e.target.value);
                }}
                style={{ ...styleObjectNormal, width: "80px" }}
                name="country_code"
              >
                {phoneNumberList.map((code, index) => (
                  <option
                    key={index}
                    value={code}
                    className="bg_white text_black"
                  >
                    {code}
                  </option>
                ))}
              </select>
              <input
                type={type}
                placeholder={placeholder}
                defaultValue={defaultValue}
                name={name}
                id={id}
                value={phoneNumber}
                onChange={(e) => onChangePhone(e.target.value)}
                pattern={pattern}
                disabled={disabled}
                style={error ? styleObjectError : styleObjectNormal}
                list={list ? list : ""}
                autoComplete="off"
                data-mapid="insertFormId"
                onKeyDown={(e) => {
                  if (handleKeyDown) {
                    handleKeyDown(e)
                  }
                }}
                {...rest}
              />
              {info && <small>{info}</small>}
            </div>
            <label>{dict["Insert country code"][language]}</label>
          </div>
        );
      } else {
        itemContent = (
          <div className="input_old">
            <div
              style={{
                textAlign: "left",
                color: error ? "red" : "black",
                fontSize: "1rem",
                fontWeight: "600",
                margin: "10px 0 10px 0",
              }}
            >
              {title}
            </div>
            <input
              type={type}
              placeholder={placeholder}
              defaultValue={defaultValue}
              name={name}
              id={id}
              value={value}
              onChange={onChange}
              pattern={pattern}
              disabled={disabled}
              style={error ? styleObjectError : styleObjectNormal}
              list={list ? list : ""}
              autoComplete="off"
              data-mapid="insertFormId"
              onKeyDown={(e) => {
                if (handleKeyDown) {
                  handleKeyDown(e)
                }
              }}
              {...rest}
            />
            {info && <small>{info}</small>}
          </div>
        );
      }
    } else {
      itemContent = (
        <div className="input_old">
          <div
            style={{
              textAlign: "left",
              color: error ? "red" : "black",
              fontSize: "1rem",
              fontWeight: "600",
              margin: "10px 0 10px 0",
            }}
          >
            {title}
          </div>
          <input
            type={type}
            placeholder={placeholder}
            defaultValue={defaultValue}
            name={name}
            id={id}
            pattern={pattern}
            value={value}
            onChange={onChange}
            disabled={disabled}
            style={error ? styleObjectError : styleObjectNormal}
            list={list}
            autoComplete="off"
            onKeyDown={(e) => {
              if (handleKeyDown) {
                handleKeyDown(e)
              }
            }}
            {...rest}
          />
          <datalist id={list}>
            {list_array.map((element, idx) => {
              return <option value={element} key={idx} />;
            })}
          </datalist>
          {info && <small>{info}</small>}
        </div>
      );
    }
  }
  return itemContent;
};

TextFieldGroup.defaultProps = {
  type: "text",
};

export default TextFieldGroup;

export const SelectField = ({
  name,
  placeholder,
  value,
  error,
  info,
  onChange,
  disabled,
  id,
  list_array,
}) => {

  const language = localStorage?.language ? localStorage?.language : "ina";
  let title = placeholder;
  if (error) {
    title = dict?.[error]?.[language] ? dict?.[error]?.[language] : error;
  }
  return (
    <div className="input_old">
      <div
        style={{
          textAlign: "left",
          color: error ? "red" : "black",
          fontSize: "1rem",
          fontWeight: "600",
          margin: "10px 0 10px 0",
        }}
      >
        {title}
      </div>
      <div className="select-wrapper">
        <select
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          disabled={disabled}
          style={error ? styleObjectError : styleObjectNormal}
          className="remove-select-arrow"
          data-mapid="dropdown_profile"
        >
          <option value="" hidden>
            {dict?.["Choose"]?.[language]}
          </option>
          {/* Choose industry | Silakan pilih industri */}
          {list_array.map((item, index) => {
            return (
              <option value={item.value} key={index}>
                {item.title}
              </option>
            );
          })}
        </select>
      </div>
      {info && <small>{info}</small>}
    </div>
  );
};
