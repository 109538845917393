import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Title,
  SubTitle,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import "chartjs-adapter-luxon";
import generate_array_color from "../../App/validation/generate_array_color";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Title,
  SubTitle
);

const ChartTemplate = (body) => {
  const {
    type,
    key,
    options,
    width,
    height,
    use_legend,
    use_generate_array_color,
  } = body;
  let data = body.data;
  let style = body?.style || {};

  let legend_content;

  if (use_legend) {
    const label_array = data.datasets[0].labels;
    const value_array = data.datasets[0].data;

    let color_array = data?.datasets?.[0]?.backgroundColor;

    if (use_generate_array_color) {
      color_array = generate_array_color(label_array);
    }

    data.datasets[0].backgroundColor = color_array;

    legend_content = (
      <>
        <p className="text_small_margin_top">Legenda pie chart:</p>
        <table className="table margin_top">
          <tbody>
            <tr>
              <td>No</td>
              <td>W</td>
              <td></td>
              <td></td>
            </tr>
            {label_array.map((label, idx) => {
              return (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        backgroundColor: color_array[idx],
                      }}
                    />
                  </td>
                  <td>{idx === 0 ? <b>{label}</b> : <p>{label}</p>}</td>
                  <td>
                    {idx === 0 ? (
                      <b>{value_array[idx]}</b>
                    ) : (
                      <p>{value_array[idx]}</p>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }

  return (
    <>
      <Chart
        type={type}
        key={key}
        data={data}
        options={options}
        width={width}
        height={height}
        style={style}
      />
      {use_legend && legend_content}
    </>
  );
};

export default ChartTemplate;
