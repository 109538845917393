/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_properties } from "../../App/actions/propertiesActions";
import { status_action } from "../../App/actions/mapActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class RANGE_USE_EXISTING extends Component {
  state = {};

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props

    //content

    return (
      <main>
        <p className="text_medium">
          {
            dict?.[
              "Make sure that the selected color column already has a color attribute"
            ]?.[language]
          }
        </p>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  properties: state.properties,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, {
  set_value_properties,
  status_action,
})(RANGE_USE_EXISTING);
