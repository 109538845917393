const initial_state = {
  loading_item_array: [],

  geo_project_list: [],
  geo_project_list_all: [],
  geo_project_list_public: [],
  geo_project: {},
  geo_project_list_child: [],
  geo_project_pinned_list: [],

  folder_project_list: [],
  folder_project_child_list: [],
  folder_project_list_all: [],
  folder_project_selected: {},
  folder_detail: {},

  folder_object_selected_list: [],
  folder_id_selected_list: [],
  folder_object_selected: {},
  folder_id_selected: "",

  folder_id_selected_collab: "",

  folder_id_selected_move: "",
  project_id_selected_move: "",

  loadingProcess: false,
  loadingDetail: false,
  loadingList: false,
  loading: false,
  itemLoading: "",
  loadMoreBtn: true,
  loadMoreBtnLoading: false,
  is_sort_from_small: true,

  list_resume: [],
  layer_list_load_first: [],

  city_object: {},
};

export default function projectReducer(state = initial_state, action) {
  switch (action.type) {
    case "set_loading_project":
      return {
        ...state,
        loading_item_array: [...state.loading_item_array, action.payload],
      };

    case "clear_loading_project":
      return {
        ...state,
        loading_item_array: state.loading_item_array.filter(
          (item) => item !== action.payload
        ),
      };

    case "set_value_project":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case "push_value_project":
      return {
        ...state,
        [action.payload.key]: [
          ...state[action.payload.key],
          action.payload.value,
        ],
      };

    case "push_value_project_to_start":
      return {
        ...state,
        [action.payload.key]: [
          action.payload.value,
          ...state[action.payload.key],
        ],
      };

    case "push_many_values_project":
      return {
        ...state,
        [action.payload.key]: [
          ...state[action.payload.key],
          ...action.payload.value,
        ],
      };

    case "pull_value_project_string":
      return {
        ...state,
        ...pull_value_project_string(
          action.payload.key,
          state[action.payload.key],
          action.payload.value
        ),
      };

    case "pull_many_values_project_string":
      return {
        ...state,
        ...pull_many_values_project_string(
          action.payload.key,
          state[action.payload.key],
          action.payload.value_list
        ),
      };

    case "pull_value_project_object":
      return {
        ...state,
        ...pull_value_project_object(
          action.payload.key,
          state[action.payload.key],
          action.payload.value,
          action.payload.id
        ),
      };

    case "pull_many_item_project_object":
      return {
        ...state,
        ...pull_many_item_project_object(
          action.payload.key,
          state[action.payload.key],
          action.payload.value_list,
          action.payload.id
        ),
      };

    case "edit_value_project":
      return {
        ...state,
        ...edit_value_project(
          action.payload.key,
          state[action.payload.key],
          action.payload.value,
          action.payload.id_key,
          action.payload.id_value
        ),
      };

    case "push_resume_kai":
      return {
        ...state,
        geo_project: push_resume_kai(state.geo_project, action.payload),
      };
    case "delete_resume_kai":
      return {
        ...state,
        geo_project: delete_resume_kai(state.geo_project, action.payload),
      };
    case "reset_resume_kai":
      return {
        ...state,
        geo_project: reset_resume_kai(state.geo_project, action.payload),
      };
    case "set_folder_timeseries_kai":
      return {
        ...state,
        geo_project: set_folder_timeseries_kai(
          state.geo_project,
          action.payload
        ),
      };
    case "set_project_premium":
      return {
        ...state,

        geo_project: set_project_object_premium({
          object: state.geo_project,
        }),
      };
    case "undo_project_premium":
      return {
        ...state,
        geo_project: undo_project_object_premium({
          object: state.geo_project,
        }),
      };
    case "get_project_all_only_name":
      return {
        ...state,
        list_resume: action.payload,
      };
    case "MIGRATION_PROJECT":
      return {
        ...state,
        geo_project: migrationProject(state.geo_project),
      };
    case "CREATE_PROJECT":
      return {
        ...state,
        geo_project_list: [action.payload, ...state.geo_project_list],
      };
    case "EDIT_PROJECT":
      return {
        ...state,
        geo_project_list: editFunction(state.geo_project_list, action.payload),
        geo_project: action.payload,
      };
    case "DELETE_PROJECT":
      return {
        ...state,
        geo_project_list: state.geo_project_list.filter(
          (geo_project) => geo_project._id !== action.payload
        ),
      };
    case "GET_PROJECT_LIST":
      return {
        ...state,
        geo_project_list: action.payload,
      };
    case "GET_PROJECT_LIST_ALL":
      return {
        ...state,
        geo_project_list_all: action.payload,
      };
    case "GET_PROJECT_LIST_PUBLIC":
      return {
        ...state,
        geo_project_list_public: action.payload,
      };
    case "GET_PROJECT_DETAIL":
      return {
        ...state,
        geo_project: action.payload.folders
          ? action.payload
          : { ...action.payload, folders: [] },
      };
    case "CLEAR_PROJECT":
      return {
        ...state,
        geo_project: {},
      };
    case "CLEAR_PROJECT_LIST":
      return {
        ...state,
        geo_project_list: [],
      };
    case "SET_OPEN_ALL_FOLDER":
      return {
        ...state,
        geo_project: setOpenAllFolder(state.geo_project),
      };
    case "SET_CLOSE_ALL_FOLDER":
      return {
        ...state,
        geo_project: setCloseAllFolder(state.geo_project),
      };
    case "SET_CLOSE_FOLDER":
      return {
        ...state,
        geo_project: setCloseFolder(state.geo_project, action.payload),
      };
    case "SET_HEIGHT_FOLDERS":
      return {
        ...state,
        geo_project: setHeightFolders(state.geo_project, action.payload),
      };
    case "SET_LOADING_PROCESS_PROJECT":
      return {
        ...state,
        loadingProcess: true,
      };
    case "SET_LOADING_DETAIL_PROJECT":
      return {
        ...state,
        loadingDetail: true,
      };
    case "SET_LOADING_LIST_PROJECT":
      return {
        ...state,
        loadingList: true,
      };
    case "SET_LOADING_PROJECT":
      return {
        ...state,
        loading: true,
        itemLoading: action.payload,
      };
    case "CLEAR_LOADING_PROJECT":
      return {
        ...state,
        loadingProcess: false,
        loadingDetail: false,
        loadingList: false,
        loading: false,
      };
    case "SET_LOAD_MORE_BTN":
      return {
        ...state,
        loadMoreBtn: action.payload,
      };
    case "SET_LOAD_MORE_LOADING":
      return {
        ...state,
        loadMoreBtnLoading: action.payload,
      };
    default:
      return state;
  }
}

const reset_resume_kai = (geo_project, payload) => {
  const { folder_id } = payload;
  const folders = geo_project.folders;
  let folder = folders.find((item) => item._id === folder_id);
  const folder_index = folders.findIndex((item) => item._id === folder_id);
  folder.layer_id_year_array = [];
  folder.year_array = [];
  folder.folder_type = "";
  folders[folder_index] = folder;
  geo_project.folders = folders;
  return geo_project;
};

const push_resume_kai = (geo_project, payload) => {
  const { folder_id, geo_layer_id, year } = payload;
  const folders = geo_project.folders;
  let folder = folders.find((item) => item._id === folder_id);
  const folder_index = folders.findIndex((item) => item._id === folder_id);
  let layer_id_year_array = folder?.layer_id_year_array || [];
  let year_array = folder?.year_array || [];
  layer_id_year_array.push(geo_layer_id);
  year_array.push(year);
  folder.layer_id_year_array = layer_id_year_array;
  folder.year_array = year_array;
  folder.folder_type = "kai_timeseries";
  folders[folder_index] = folder;
  geo_project.folders = folders;
  return geo_project;
};

const delete_resume_kai = (geo_project, payload) => {
  const { folder_id, geo_layer_id, year } = payload;
  const folders = geo_project.folders;
  let folder = folders.find((item) => item._id === folder_id);
  const folder_index = folders.findIndex((item) => item._id === folder_id);
  let layer_id_year_array = folder?.layer_id_year_array || [];
  let year_array = folder?.year_array || [];
  layer_id_year_array = layer_id_year_array.filter(
    (item) => item !== geo_layer_id
  );
  year_array = year_array.filter((item) => item !== year);
  folder.layer_id_year_array = layer_id_year_array;
  folder.year_array = year_array;
  folders[folder_index] = folder;
  geo_project.folders = folders;
  return geo_project;
};

const set_folder_timeseries_kai = (geo_project, payload) => {
  const { geo_layer_id, folder_id, max_penumpang, max_passenger, max_train } =
    payload;
  const folders = geo_project.folders;
  const folder = folders.find((item) => item._id === folder_id);
  const folder_index = folders.findIndex((item) => item._id === folder_id);
  const layer_id_year_array = folder?.layer_id_year_array || [];
  const layer_index = layer_id_year_array.findIndex(
    (item) => item === geo_layer_id
  );
  //max_penumpang
  let max_penumpang_array = folder?.max_penumpang_array || [];
  max_penumpang_array[layer_index] = max_penumpang;
  folder.max_penumpang_array = max_penumpang_array;

  //max_passenger
  let max_passenger_array = folder?.max_passenger_array || [];
  max_passenger_array[layer_index] = max_passenger;
  folder.max_passenger_array = max_passenger_array;

  //max_train
  let max_train_array = folder?.max_train_array || [];
  max_train_array[layer_index] = max_train;
  folder.max_train_array = max_train_array;

  folders[folder_index] = folder;
  geo_project.folders = folders;
  return geo_project;
};

const set_project_object_premium = ({ object }) => {
  object.is_premium = true;
  return object;
};

const undo_project_object_premium = ({ object }) => {
  object.is_premium = false;
  return object;
};

const editFunction = (state, newItem) => {
  const newState = state.slice();
  //get index
  const index = newState.findIndex((element) => element._id === newItem._id);
  //replace item
  newState.splice(index, 1, newItem);
  return newState;
};

const setOpenAllFolder = (geo_project) => {
  const new_geo_project = Object.assign(geo_project); //clone state
  const folders = new_geo_project.folders;
  const new_folders = folders.map((e) => {
    let clone_e = { ...e };
    clone_e.closeStatus = false;
    return clone_e;
  });
  new_geo_project.folders = new_folders;
  return new_geo_project;
};

const setCloseAllFolder = (geo_project) => {
  const new_geo_project = Object.assign(geo_project); //clone state
  const folders = new_geo_project.folders;
  const new_folders = folders.map((e) => {
    let clone_e = { ...e };
    clone_e.closeStatus = true;
    return clone_e;
  });
  new_geo_project.folders = new_folders;
  return new_geo_project;
};

const setCloseFolder = (geo_project, payload) => {
  const new_geo_project = Object.assign(geo_project); //clone state
  const folders = new_geo_project.folders;
  const folder_id = payload;
  const new_folder = folders.filter((folder) => folder._id === folder_id)[0];
  const closeStatus = !new_folder.closeStatus; //toggle viewStatus
  new_folder.closeStatus = closeStatus;
  const index = folders.findIndex((folder) => folder._id === folder_id); //get index of the geo_layer in geo_layer_list
  folders.splice(index, 1, new_folder); //replace item
  new_geo_project.folders = folders;
  return new_geo_project;
};

const setHeightFolders = (geo_project, payload) => {
  const new_geo_project = Object.assign(geo_project); //clone state
  const folders = new_geo_project.folders;
  const height_array = payload;
  folders.map(({ closeStatus }, idx) => {
    return (new_geo_project.folders[idx].height = height_array[idx] - 15);
  });
  return new_geo_project;
};

const migrationProject = (geo_project) => {
  const new_geo_project = Object.assign(geo_project); //clone object
  new_geo_project.migration_status = "migrated";
  return new_geo_project;
};

const pull_value_project_string = (key, list, value) => {
  list = list.filter((item) => item !== value);
  return { [key]: list };
};

const pull_many_values_project_string = (key, list, value_list) => {
  list = list.filter((item) => !value_list.includes(item));
  return { [key]: list };
};

const pull_value_project_object = (key, list, value, id) => {
  list = list.filter((item) => item[id] !== value);
  return { [key]: list };
};

const edit_value_project = (key, list, value, id_key, id_value) => {
  const index = list.findIndex((item) => item[id_key] === id_value);
  list[index] = value;
  return { [key]: list };
};

const pull_many_item_project_object = (key, list, value_list, id) => {
  list = list.filter((item) => !value_list.includes(item?.[id]));
  return { [key]: list };
};
