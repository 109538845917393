/*
import dict from "../../validation/dict.json";
const language = localStorage?.language ? localStorage?.language : "ina";

{dict["test"][language]}
dict["test"][language]
${dict["test"][language]}

*/
const formatDate = function (timestamp) {
  const language = localStorage?.language ? localStorage?.language : "ina";
  const date = new Date(timestamp);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const months_ina = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mei",
    "Jun",
    "Jul",
    "Agt",
    "Sep",
    "Okt",
    "Nov",
    "Des",
  ];
  const tanggal = date.getDate();
  let month_final = months[date.getMonth()];
  let date_final = month_final + " " + tanggal + ", " + date.getFullYear();
  if (language === "ina") {
    month_final = months_ina[date.getMonth()];
    date_final = tanggal + " " + month_final + " " + date.getFullYear();
  }
  return date_final;
};

export default formatDate;
