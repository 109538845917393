/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/

/*PICTURE*/

/*FUNCTION*/

/*DATA*/

/*CONST*/

class LAYER_HEATMAP extends Component {
  componentDidUpdate(prevProps) {
    //memantau perubahan untuk rerender layer

    const map_object_after = this.props.layer.map_object;
    const map_object_before = prevProps.layer.map_object;

    const status_action_after = this.props.map.status_action;
    const status_action_before = prevProps.map.status_action;

    const basemap_used_after = this.props.properties.basemap_used;
    const basemap_used_before = prevProps.properties.basemap_used;

    const layer_id_heatmap_after = this.props.layer.layer_id_heatmap;
    const layer_id_heatmap_before = prevProps.layer.layer_id_heatmap;

    if (
      status_action_after !== status_action_before ||
      basemap_used_after !== basemap_used_before ||
      layer_id_heatmap_after?.length !== layer_id_heatmap_before?.length ||
      map_object_after !== map_object_before
    ) {
      this.on_render();
    }
  }

  on_render = () => {
    const { map_object, geo_layer_list, layer_id_heatmap } = this.props.layer;
    const layer_list_heatmap = geo_layer_list.filter((item) =>
      layer_id_heatmap.includes(item?.geo_layer?._id)
    );

    if (map_object) {
      layer_list_heatmap.forEach((layer) => {
        const layer_id_heatmap = `heatmap_${layer?.geo_layer?._id}`;

        const features_filtered =
          layer?.geo_layer?.geojson_filtered?.features || [];

        const geojson = {
          type: "FeatureCollection",
          features: features_filtered,
        };

        if (!map_object.getSource(layer_id_heatmap)) {
          map_object.addSource(layer_id_heatmap, {
            type: "geojson",
            data: geojson,
          });
        } else {
          map_object.getSource(layer_id_heatmap).setData(geojson);
        }

        if (!map_object.getLayer(layer_id_heatmap)) {
          map_object.addLayer({
            id: layer_id_heatmap,
            source: layer_id_heatmap,
            type: "heatmap",
            paint: {},
            layout: {
              visibility: "visible",
            },
          });
        }
      });
    }
  };

  render() {
    return <main />;
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {})(LAYER_HEATMAP);
