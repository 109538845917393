/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Map } from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

/*PERSONAL COMPONENT*/
import BI_LAYER from "./BI_LAYER";
import BI_MARKER from "./BI_MARKER";
import BI_MAP_CONTROL from "./BI_MAP_CONTROL";
import LAYER_GPS from "../libre_layer/LAYER_GPS";
import DRAW_GEO from "../libre_draw/DRAW_GEO";

import LAYER_S_CENTER from "../libre_layer_sini/LAYER_S_CENTER";
import LAYER_S_DEMOGRAPHY_ONE from "../libre_layer_sini/LAYER_S_DEMOGRAPHY_ONE";
import LAYER_S_DEMOGRAPHY_NEIGHBOR from "../libre_layer_sini/LAYER_S_DEMOGRAPHY_NEIGHBOR";
import LAYER_S_ISOCHRONE from "../libre_layer_sini/LAYER_S_ISOCHRONE";
import LAYER_S_POI from "../libre_layer_sini/LAYER_S_POI";
import LAYER_S_FLOOD_NEIGHBOR from "../libre_layer_sini/LAYER_S_FLOOD_NEIGHBOR";
import LAYER_S_FLOOD from "../libre_layer_sini/LAYER_S_FLOOD";
import LAYER_S_LANDSLIDE_NEIGHBOR from "../libre_layer_sini/LAYER_S_LANDSLIDE_NEIGHBOR";
import LAYER_S_LANDSLIDE from "../libre_layer_sini/LAYER_S_LANDSLIDE";
import LAYER_S_LANDVALUE_NEIGHBOR from "../libre_layer_sini/LAYER_S_LANDVALUE_NEIGHBOR";
import LAYER_S_LANDZONE_NEIGHBOR from "../libre_layer_sini/LAYER_S_LANDZONE_NEIGHBOR";
import LAYER_S_LANDZONE from "../libre_layer_sini/LAYER_S_LANDZONE";
import LAYER_S_RDTR_NEIGHBOR from "../libre_layer_sini/LAYER_S_RDTR_NEIGHBOR";
import LAYER_S_RDTR_ONE from "../libre_layer_sini/LAYER_S_RDTR_ONE";
import LAYER_S_TSUNAMI_NEIGHBOR from "../libre_layer_sini/LAYER_S_TSUNAMI_NEIGHBOR";
import LAYER_S_TSUNAMI from "../libre_layer_sini/LAYER_S_TSUNAMI";

/*REDUX FUNCTION*/
import { set_value_layer } from "../../App/actions/layerActions";
import { set_value_map } from "../../App/actions/mapActions";
import { set_value_bi } from "../../App/actions/biActions";
import POPUP_EDITOR from "../libre_popup/POPUP_EDITOR";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/
const basemap_url = process.env.REACT_APP_MAPID_BASEMAP;
const basemap_key = process.env.REACT_APP_BASEMAP_KEY;

class BI_MAP_PARENT extends Component {
  state = {};

  componentDidMount() {
    const map_object = new Map({
      container: "map_object",
      style: `${basemap_url}/styles/street-2d-building/style.json?key=${basemap_key}`,
      center: [109.13270221994014, -6.480303321757276],
      zoom: 7,
      maxPitch: 85,
    });
    this.props.set_value_layer({
      key: "map_object",
      value: map_object,
    });
  }

  componentWillUnmount = () => {
    try {
      const { map_object } = this.props.layer;
      if (map_object && typeof map_object.remove === "function") {
        map_object.remove();
      }
    } catch (error) {
      console.error("Failed to clean up map object:", error);
    } finally {
      this.props.set_value_layer({
        key: "map_object",
        value: null,
      });
    }
  };

  componentDidUpdate(prevProps) {
    const { bi_object, project_object_selected } = this.props.bi;
    const setting_list = bi_object?.setting_list || [];
    const basemap_mode_after = setting_list?.find(
      (item) => item?.project_id === project_object_selected?._id
    )?.basemap_mode;

    //before
    const bi_object_before = prevProps?.bi?.bi_object;
    const project_object_selected_before =
      prevProps?.bi?.project_object_selected;
    const setting_list_before = bi_object_before?.setting_list || [];
    const basemap_mode_before = setting_list_before?.find(
      (item) => item?.project_id === project_object_selected_before?._id
    )?.basemap_mode;

    // Proceed only if map is fully loaded and has a style available
    const { map_object } = this.props.layer;
    if (
      map_object &&
      map_object.style &&
      basemap_mode_after !== basemap_mode_before &&
      basemap_mode_after
    ) {
      const basemap_url = process.env.REACT_APP_MAPID_BASEMAP;
      const basemap_key = process.env.REACT_APP_BASEMAP_KEY;
      let style_url = "";

      switch (basemap_mode_after) {
        case "2d":
          style_url = `${basemap_url}/styles/street-2d-building/style.json?key=${basemap_key}`;
          break;
        case "3d":
          style_url = `${basemap_url}/styles/basic/style.json?key=${basemap_key}`;
          break;
        case "light":
          style_url = `${basemap_url}/styles/light/style.json?key=${basemap_key}`;
          break;
        case "dark":
          style_url = `${basemap_url}/styles/dark/style.json?key=${basemap_key}`;
          break;
        case "sat":
          style_url = `${basemap_url}/styles/satellite/style.json?key=${basemap_key}`;
          break;
        default:
          style_url = `${basemap_url}/styles/street-2d-building/style.json?key=${basemap_key}`;
          break;
      }
      map_object.setStyle(style_url);
    }
  }

  render() {
    const {
      modal_edit_feature_properties,
      latitude_selected,
      longitude_selected,
      fields_selected,
      properties_selected,
      type_2_selected,
      feature_key_selected,
      geo_layer_selected,
      genangan_cm_selected,
    } = this.props.layer;

    return (
      <main
        id="map_object"
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "10px",
          position: "relative",
        }}
      >
        <BI_MAP_CONTROL />
        <LAYER_GPS />
        <BI_LAYER />
        <BI_MARKER />
        <DRAW_GEO />
        <LAYER_S_CENTER />
        <LAYER_S_DEMOGRAPHY_ONE />
        <LAYER_S_DEMOGRAPHY_NEIGHBOR />
        <LAYER_S_ISOCHRONE />
        <LAYER_S_POI />
        <LAYER_S_FLOOD_NEIGHBOR />
        <LAYER_S_FLOOD />
        <LAYER_S_LANDSLIDE_NEIGHBOR />
        <LAYER_S_LANDSLIDE />
        <LAYER_S_LANDVALUE_NEIGHBOR />
        <LAYER_S_LANDZONE_NEIGHBOR />
        <LAYER_S_LANDZONE />
        <LAYER_S_RDTR_NEIGHBOR />
        <LAYER_S_RDTR_ONE />
        <LAYER_S_TSUNAMI_NEIGHBOR />
        <LAYER_S_TSUNAMI />

        {modal_edit_feature_properties && (
          <POPUP_EDITOR
            latitude_selected={latitude_selected}
            longitude_selected={longitude_selected}
            fields_selected={fields_selected}
            properties_selected={properties_selected} //properties dari feature yang dipilih
            type_2_selected={type_2_selected}
            feature_key_selected={feature_key_selected}
            genangan_cm_selected={genangan_cm_selected}
            geo_layer_selected={geo_layer_selected}
            is_editable={false}
          />
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  bi: state.bi,
});

export default connect(mapStateToProps, {
  set_value_map,
  set_value_bi,
  set_value_layer,
})(BI_MAP_PARENT);
