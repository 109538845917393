/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import SpinnerSimpleBlue from "../common_spinner/SpinnerSimpleBlue";

/*REDUX FUNCTION*/
import { deleteFolder } from "../../App/actions/projectActions";

/*PICTURE ASSET*/

/*GENERAL*/
import isEqual from "../../App/validation/is-equal";
import dict from "../../Data/dict.json";

class DeleteLayer extends Component {
  state = {
    folder: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps.folder, prevState.folder)) {
      return {
        folder: nextProps.folder,
      };
    } else return null;
  }

  on_submit = () => {
    const { geo_project } = this.props.project;
    const all_folder_list = geo_project?.folders || [];

    const { folder } = this.state;
    const body = {
      folder_id: folder._id,
      folder_name: folder.name,
      geo_project_id: geo_project._id,
      all_folder_list,
    };
    this.props.deleteFolder(body);
    this.props.toggle_delete_folder();
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const { folder } = this.state;

    //global props
    const { loadingProcess } = this.props.project;

    //content
    let content;
    let button_content;

    if (loadingProcess) {
      button_content = (
        <SpinnerSimpleBlue
          width={40}
          unik="loading_create_layer"
          marginTop="0px"
        />
      );
    } else {
      button_content = (
        <button
          className="button background_blue"
          id="deleteBright"
          style={{ marginTop: "1rem" }}
          onClick={this.on_submit}
        >
          {dict["Process deletion"][language]}
        </button>
      );
    }

    content = (
      <div style={{ textAlign: "left" }}>
        <h1 className="text_header margin_bottom">Hapus folder</h1>
        <div className="text_medium">
          {dict["Are you sure to delete this folder?"][language]}
          <br />
          {dict["This process can't be undone."][language]}
          <br />
          <br />
          Untuk menghindari kesalahan, layer di dalam folder tidak akan dihapus
          namun akan keluar dari folder.
          <br />
          <br />
          Semua folder di dalam folder ini juga akan dihapus.
        </div>
        <br />
        <div className="text_bold">{folder.name}</div>
        {button_content}
      </div>
    );
    return <div>{content}</div>;
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  layer: state.layer,
  auth: state.auth,
});

export default connect(mapStateToProps, { deleteFolder })(DeleteLayer);
