/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import SpinnerSimpleBlue from "../common_spinner/SpinnerSimpleBlue";
import CheckBoxNew from "../common_input/CheckBoxNew";
import PreviewReferrenceData from "../common_field/PreviewReferrenceData";
import PreviewReferrenceData2D from "../common_field/PreviewReferrenceData2D";
import FormulaCard from "../common_field/FormulaCard";
import ConditionalStatementInput from "../common_field/ConditionalStatementInput";
import TableFormula from "../common_field/TableFormula";

/*REDUX*/
import { pushField } from "../../App/actions/layerActions";

/*PICTURE*/

/*FUNCTION*/
import {
  polygon_units,
  line_units,
} from "../../App/validation/geometry_to_area_or_length";
import { handle_upload_csv } from "../../App/validation/handle_file";
import { check_formula_validity } from "../../App/validation/formula_validation";
import { check_conditional_statement_validity } from "../../App/validation/conditional_statement_validation";
import referrence_2d_file from "../../Assets/file/[2d-referrence]-[comma-delimited-UTF8].csv";
import referrence_simple_file from "../../Assets/file/[simple-referrence]-[comma-delimited-UTF8].csv";
import DataTypesSelection from "../common_field/DataTypesSelection";

/*DATA*/
import dict from "../../Data/dict.json";
import uuid from "../../App/validation/uuid";
import { data_types } from "../../App/validation/data_types";

/*CONST*/
const no_need_data_type = ["section"];

class PushField extends Component {
  state = {
    name: "",
    description: "",
    type: "text",
    tag: "",
    array_templates: [],
    isRequired: true,
    isAllowOtherAnswer: true,
    min: 0,
    max: 10,
    step: 1,
    unit: "",
    reference_list: [],
    decimal_digits: "",
    /*
      [
        {
          name: "KOTA JAKARTA",
          value: "0.3"
        },
      ]
     */
    reference_key: "",
    reference_list_2d: {
      row_name: "",
      column_name: "",
      headers: [],
      features: [],
    }, //array of object
    /*
     [
      {
        index_name : "Pondasi Dalam / Bor Pile / Mini Pile",
        MEWAH : 1,
        MENENGAH : 0,
        SEDERHANA: 0,
      },
      {
        index_name : "Pondasi Dangkal Dengan Pondasi Tapak Beton", 
        MEWAH : 0.80,
        MENENGAH : 1.2,
        SEDERHANA: 0,
      }
     ] 
     */
    formula: [],
    conditional_statement_list: [],
    value: "",
    table_formula: {
      field_parent_uuid: "",
      field_child_uuid: "",
      formula: "", // ex: "sum" or "avg"
    },
  };

  componentDidMount() {
    const { _id } = this.props.auth.user;
    this.setState({ user_id: _id });
  }

  handleChange = (e) => {
    e.preventDefault();
    const target = e.target;
    const value = target.value;
    const name = target.name;
    if (["tag"].includes(name)) {
      this.setState({ [name]: value }, () => {
        const tagArray = value.split("\n");
        const array_templates = tagArray.map((value, idx) => {
          return { name: value, index: idx };
        });
        const defaultValue = "";
        this.setState({
          array_templates,
          defaultValue,
        });
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  handleDecimalInput = (e) => {
    if (
      /[0-9]/.test(e.nativeEvent.data) ||
      e.nativeEvent.inputType === "deleteContentBackward"
    ) {
      this.setState({
        decimal_digits: e.target.value,
      });
    }
  };

  isDataTypeCalculateDimensionAndLayerTypeIsPoint = (dataType, layerType) => {
    if (layerType === "Point" && dataType === "calculate_dimension") {
      return true;
    } else return false;
  };

  handleDataTypeOnClick = (dataType, layerType) => {
    if (
      !this.isDataTypeCalculateDimensionAndLayerTypeIsPoint(dataType, layerType)
    ) {
      this.setState({ type: dataType });
    }
  };

  referColumn = (e) => {
    this.setState({
      reference_key: e.target.value,
    });
  };

  handleFormula = (value) => {
    this.setState({
      formula: value,
    });
  };

  handle_value_template = (new_value) => {
    const { value } = this.state;
    this.setState({
      value: value !== new_value ? new_value : "",
    });
  };

  handleCsvValidation = async (e) => {
    const file = await handle_upload_csv(e);
    const headers = file?.headers?.map((field) => field.toUpperCase());
    let is_valid = "invalid";

    if (headers?.includes("NAME") && headers?.includes("VALUE")) {
      is_valid = "valid";
    }

    if (is_valid === "valid") {
      this.setState({
        reference_list: file.data,
      });
    }
  };

  handleCsvValidation2dReferring = async (e) => {
    const { reference_list_2d } = this.state;
    const file = await handle_upload_csv(e);
    const headers = file?.headers;
    // ?.map((field) => field.toUpperCase());
    let is_valid = "invalid";

    if (headers?.includes("index_name")) {
      is_valid = "valid";
    }
    if (is_valid === "valid") {
      this.setState({
        reference_list_2d: {
          ...reference_list_2d,
          headers: headers,
          features: file.data,
        },
      });
    }
  };

  set_table_formula = (table_formula) => {
    this.setState({
      table_formula,
    });
  };

  handleRowSelect = (e) => {
    const { reference_list_2d } = this.state;
    let new_ref = reference_list_2d;
    new_ref["row_name"] = e.target.value;
    this.setState({
      reference_list_2d: new_ref,
    });
  };

  handleColumnSelect = (e) => {
    const { reference_list_2d } = this.state;
    let new_ref = reference_list_2d;
    new_ref["column_name"] = e.target.value;
    this.setState({
      reference_list_2d: new_ref,
    });
  };

  handleToggle = (name) => {
    this.setState({
      [name]: !this.state[name],
    });
  };

  handleClickType = (value) => {
    this.setState({ type: value });
  };

  handleConditionalStatementList = (value) => {
    let { conditional_statement_list } = this.state;
    conditional_statement_list = value;
    this.setState({
      conditional_statement_list,
    });
  };

  handleSubmit = () => {
    const {
      name,
      description,
      type,
      min,
      max,
      step,
      array_templates,
      isRequired,
      isAllowOtherAnswer,
      unit,
      reference_list,
      reference_key,
      formula,
      value,
      reference_list_2d,
      decimal_digits,
      conditional_statement_list,
      table_formula,
    } = this.state;
    let body = {};
    const is_formula_valid =
      check_formula_validity(formula) === "valid" ? true : false;
    const conditional_statement_validation_result =
      check_conditional_statement_validity(conditional_statement_list);
    if (
      (type === "math_operators" && is_formula_valid) ||
      (type !== "math_operators" &&
        conditional_statement_validation_result.status === "valid")
    ) {
      const uuid_value = uuid();
      let field = {
        uuid: uuid_value,
        key: uuid_value,
        name: name || type?.replaceAll("_", " "),
        description,
        type: type,
        array_templates,
        isHighlight: false,
        isRequired,
        isAllowOtherAnswer,
        date: Date.now(),
        isHide: false,
        min,
        max,
        step,
        unit,
        reference_list,
        reference_key,
        formula,
        value,
        reference_list_2d,
        decimal_digits,
        conditional_statement_list,
        table_formula,
      };
      const parent_uuid = this.props.parent_uuid;
      if (parent_uuid) {
        field = { ...field, parent_uuid };
      }
      if (this.props.from === "table" || this.props.from === "nested_table") {
        body = {
          geo_layer_id: this.props.layer_id,
          field,
        };
      } else {
        const geo_layer = this.props.layer.geo_layer_wo_geojson.geo_layer;
        body = {
          geo_layer_id: geo_layer._id,
          field,
        };
      }
      this.props.pushField(body);

      this.setState({
        name: "",
        description: "",
        type: "text",
        tag: "",
        array_templates: [],
        isRequired: true,
        isAllowOtherAnswer: true,
        unit: "",
        reference_list: [],
        reference_key: "",
        formula: [],
        decimal_digits: "",
        conditional_statement_list: [],
      });
    }
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const {
      name,
      description,
      type,
      min,
      max,
      step,
      array_templates,
      tag,
      isRequired,
      isAllowOtherAnswer,
      reference_list,
      formula,
      reference_list_2d,
      decimal_digits,
      conditional_statement_list,
      value,
      table_formula,
    } = this.state;

    //global props
    let headers = this.props.headers?.filter((header) => header.uuid);
    const { loadingProcess, geo_layer_list } = this.props.layer;
    const layer_id_after = this.props.layer.layer_id;

    //content
    const layer_details = geo_layer_list.find(
      (e) => e?.geo_layer?._id === layer_id_after
    );
    const geometry_type = layer_details.geo_layer?.type;
    const data_type = data_types(language).filter(
      (type) => !no_need_data_type.includes(type.value)
    );

    // let data_type_filtered;
    // if (geometry_type === "Point") {
    //   data_type_filtered = data_type.filter(
    //     (t) => t.value !== "calculate_dimension"
    //   );
    // } else {
    //   data_type_filtered = data_type;
    // }

    let button_content;

    if (loadingProcess) {
      button_content = (
        <SpinnerSimpleBlue
          width={40}
          unik="loading_create_project"
          marginTop="0px"
          center={true}
        />
      );
    } else {
      button_content = (
        <button
          type="submit"
          className="button background_blue"
          style={{ marginTop: "1rem" }}
          onClick={this.handleSubmit.bind(this)}
          data-mapid="clickAdd"
        >
          {dict["Add Column"][language]}
        </button>
      );
    }

    const content = (
      <>
        {this.props.parent_uuid && (
          <p className="text_bold margin_bottom text_center">
            {dict["Add sub questions"][language]}
          </p>
        )}
        <section className="container_flat" style={{ marginBottom: "15px" }}>
          <p className="text_bold">{dict["Column Name"][language]}</p>
          <input
            type="text"
            name="name"
            id="name"
            placeholder={dict["Column Name"][language]}
            value={name}
            onChange={this.handleChange}
            data-mapid="inputNameColumn"
          />
          <CheckBoxNew
            text="isRequired"
            title={dict["Required to fill by respondent"][language]}
            value={isRequired}
            handle={this.handleToggle.bind(this, "isRequired")}
          />
        </section>
        <section className="container_flat" style={{ marginBottom: "15px" }}>
          <p className="text_bold">{dict["Description"][language]}</p>
          <textarea
            rows="4"
            type="text"
            name="description"
            id="description"
            placeholder={dict["Describe this question here"][language]}
            value={description}
            onChange={this.handleChange}
            data-mapid="inputDescribeColumn"
          />
        </section>
        <section className="container_flat" style={{ marginBottom: "15px" }}>
          <p className="text_bold">{dict["Type data"][language]}</p>
          <div id="data_type_container">
            <DataTypesSelection
              field={this.state}
              geo_layer_type={geometry_type}
              language={language}
              data_type={data_type}
              handleDataTypeOnClick={this.handleDataTypeOnClick}
              isDataTypeCalculateDimensionAndLayerTypeIsPoint={
                this.isDataTypeCalculateDimensionAndLayerTypeIsPoint
              }
            />
          </div>

          {type === "range" && (
            <>
              <p className="text_bold">{dict["Minimum value"][language]}</p>
              <input
                type="number"
                name="min"
                id="min"
                placeholder={dict["Minimum value"][language]}
                value={min}
                onChange={this.handleChange}
                data-mapid="inputMinimum"
              />
              <p className="text_bold">{dict["Maximum value"][language]}</p>
              <input
                type="number"
                name="max"
                id="max"
                placeholder={dict["Maximum value"][language]}
                value={max}
                onChange={this.handleChange}
                data-mapid="inputMaximum"
              />
              <p className="text_bold">{dict["Step value"][language]}</p>
              <input
                type="number"
                name="step"
                id="step"
                placeholder={dict["Step value"][language]}
                value={step}
                onChange={this.handleChange}
                data-mapid="inputStep"
              />
            </>
          )}
        </section>

        {type === "calculate_dimension" && (
          <section className="container_flat" style={{ marginBottom: "15px" }}>
            <div className="text_bold">{"Unit"}</div>
            <select
              id="unit"
              name="unit"
              onChange={this.handleChange.bind(this)}
            >
              <option value="">Select</option>
              {(geometry_type === "MultiLineString" ||
                geometry_type === "LineString") &&
                line_units.map((element, index) => (
                  <option key={index} value={element.key}>
                    {element.name}
                  </option>
                ))}
              {(geometry_type === "Polygon" ||
                geometry_type === "MultiPolygon") &&
                polygon_units.map((element, index) => (
                  <option key={index} value={element.key}>
                    {element.name}
                  </option>
                ))}
            </select>
          </section>
        )}

        {type === "simple_referring" && (
          <main className="container_flat">
            <div className="flex gap_10 paddingTop_10">
              <a
                className="bg_blue pointer rounded_5 font_14 h_35 w_200 text_white hover_darkBlue"
                href={referrence_simple_file}
                data-mapid="clikcDownload"
              >
                <div className="center_perfect w_full h_full">
                  Template CSV Download
                </div>
              </a>

              <label className="inline">
                <div
                  className={`center_perfect pointer rounded_5 font_14 h_35 text_white bg_blue hover_darkBlue`}
                >
                  {dict["Upload CSV"][language]}
                </div>
                <input
                  style={{ display: "none" }}
                  type="file"
                  accept=".csv*"
                  onChange={this.handleCsvValidation}
                  data-mapid="clikcUpload"
                />
              </label>
              {reference_list?.length > 0 && (
                <PreviewReferrenceData data={reference_list} />
              )}
            </div>
            <div className="paddingTop_10">
              <label htmlFor="refer_to_column" className="font_18">
                {dict["Refer to"][language]}
              </label>
              <select
                id="reference_list"
                name="reference_list"
                onChange={this.referColumn}
                data-mapid="selectSection"
              >
                <option key="" value="">
                  {dict["Select column"][language]}
                </option>
                {headers?.map((header) => {
                  return (
                    <option key={header.field} value={header.field}>
                      {header.headerName}
                    </option>
                  );
                })}
              </select>
            </div>
          </main>
        )}

        {type === "2d_referring" && (
          <main className="container_flat">
            <div>
              <label className="font_18 w_full">2D Referring</label>
              <div className="flex gap_10 paddingTop_10">
                <a
                  className="bg_blue pointer rounded_5 font_14 h_35 w_200 text_white hover_darkBlue"
                  href={referrence_2d_file}
                  data-mapid="clikcDownload2d"
                >
                  <div className="center_perfect w_full h_full">
                    Template CSV Download
                  </div>
                </a>

                <label className="inline">
                  <div
                    className={`center_perfect pointer rounded_5 font_14 h_35 text_white bg_blue hover_darkBlue`}
                  >
                    {dict["Upload CSV"][language]}
                  </div>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    accept=".csv*"
                    onChange={this.handleCsvValidation2dReferring}
                    data-mapid="clikcUpload2d"
                  />
                </label>
                {reference_list_2d.features?.length > 0 && (
                  <PreviewReferrenceData2D data={reference_list_2d} />
                )}
              </div>
              <div className="two-container">
                <div className="paddingTop_10 grid_child">
                  <label className="font_14 w_full">
                    {
                      dict[
                        "Select column name from main table that will refer as a row in secondary table"
                      ][language]
                    }
                  </label>
                  <select
                    id="reference_list"
                    name="reference_list"
                    onChange={this.handleRowSelect}
                    data-mapid="selectType1"
                  >
                    <option key="" value="">
                      {dict["Select column"][language]}
                    </option>
                    {headers?.map((header) => {
                      return (
                        <option key={header.field} value={header.field}>
                          {header.headerName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="paddingTop_10 grid_child">
                  <label className="font_14 w_full">
                    {
                      dict[
                        "Select column name from main table that will refer as a column in secondary table"
                      ][language]
                    }
                  </label>
                  <select
                    id="reference_list"
                    name="reference_list"
                    onChange={this.handleColumnSelect}
                    data-mapid="selectType2"
                  >
                    <option key="" value="">
                      {dict["Select column"][language]}
                    </option>
                    {headers?.map((header) => {
                      return (
                        <option key={header.field} value={header.field}>
                          {header.headerName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </main>
        )}

        {type === "math_operators" && (
          <main className="container_flat paddingTop_10">
            <FormulaCard
              from={this.props.from}
              formula={formula}
              headers={headers}
              handleFormula={this.handleFormula}
            />
            <div>
              <label className="font_18 w_full">Decimal Format</label>
              <div>
                <label className="w_full">Insert a decimal point</label>
                <div>
                  <label>Places:</label>
                  <input
                    type="number"
                    onChange={this.handleDecimalInput}
                    value={decimal_digits}
                    min={0}
                  />
                </div>
              </div>
            </div>
          </main>
        )}

        {type === "conditional_statement" && (
          <main className="container_flat paddingTop_10 marginBottom_15">
            <ConditionalStatementInput
              language={language}
              headers={headers}
              handleConditionalStatementList={
                this.handleConditionalStatementList
              }
              conditional_statement_list={conditional_statement_list}
            />
          </main>
        )}

        {type === "table_formula" && (
          <main className="container_flat paddingTop_10 marginBottom_15">
            <TableFormula
              language={language}
              table_formula={table_formula}
              set_table_formula={this.set_table_formula}
            />
          </main>
        )}

        {["selection", "radio_button", "checklist"].includes(type) && (
          <main className="container_flat" style={{ marginBottom: "15px" }}>
            <section className="text_bold">
              {dict["Value template"][language]}
            </section>
            <CheckBoxNew
              text="isAllowOtherAnswer"
              title={dict["Allow other answers"][language]}
              value={isAllowOtherAnswer}
              handle={this.handleToggle.bind(this, "isAllowOtherAnswer")}
            />
            <textarea
              type="text"
              name="tag"
              id="tag"
              value={tag}
              onChange={this.handleChange.bind(this)}
              rows="5"
              data-mapid="inputJawaban"
            />
            <section className="input_note">
              {
                dict[
                  "Fill in if you want to provide several value options for attribute input (in MAPID FORM). Separate between values ​​with next values ​​with enter."
                ][language]
              }
            </section>
            <div className="flex gap_5 marginTop_10">
              {(array_templates ? array_templates : []).map((content, idx) => {
                const is_active = value === content.name;
                // return (
                //   <div className="badge_normal" key={idx}>
                //     {content.name}
                //   </div>
                // );
                return (
                  <main key={idx}>
                    <div
                      onClick={() => this.handle_value_template(content.name)}
                      style={{
                        border: "1px solid #b4b6b8",
                      }}
                      className={`h_20 w_fit padding_5 rounded_5 pointer noselect ${
                        is_active ? "button_active" : "button_inactive"
                      }`}
                    >
                      {content.name}
                    </div>
                    <br />
                  </main>
                );
              })}
            </div>
          </main>
        )}

        <section
          style={{
            paddingTop: 30,
            width: "100%",
            position: "absolute",
            bottom: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {button_content}
        </section>
      </>
    );

    return <main>{content}</main>;
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  layer: state.layer,
  auth: state.auth,
});
export default connect(mapStateToProps, { pushField })(PushField);
