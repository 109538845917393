const generate_array_time = function (mode, date_oldest) {
  let data_array = [];
  const first = new Date(Date.now()).getTime();
  date_oldest = new Date(date_oldest).getTime();

  if (mode === "day") {
    //24 jam ke belakang
    for (let index = 0; index < 24; index++) {
      const second = first - 3_600 * 1_000 * index;
      data_array.push({ x: second, y: 0 });
    }
  }

  if (mode === "week") {
    //24 jam ke belakang kali 7 (1 minggu)
    for (let index = 0; index < 24 * 7; index++) {
      const second = first - 3_600 * 1_000 * index;
      data_array.push({ x: second, y: 0 });
    }
  }

  if (mode === "month") {
    //30.5 hari ke belakang
    for (let index = 0; index < 30.5; index++) {
      const second = parseInt(first / 10000) * 10000 - 86_400 * 1_000 * index;
      data_array.push({ x: second, y: 0 });
    }
  }

  if (mode === "6_months") {
    //30.5 hari ke belakang
    for (let index = 0; index < 30.5 * 6; index++) {
      const second = parseInt(first / 10000) * 10000 - 86_400 * 1_000 * index;
      data_array.push({ x: second, y: 0 });
    }
  }

  if (mode === "year") {
    //365 hari ke belakang
    for (let index = 0; index < 365; index++) {
      const second = parseInt(first / 10000) * 10000 - 86_400 * 1_000 * index;
      data_array.push({ x: second, y: 0 });
    }
  }

  let temp = first;
  if (mode === "all") {
    //semua dari hari paling old
    for (let index = 0; temp > date_oldest; index++) {
      temp = temp - 86_400 * 1_000;
      data_array.push({ x: temp, y: 0 });
    }
  }

  return data_array;
};

export default generate_array_time;
