/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import { Draggable } from "../reuseable";
import BI_EDIT_LAYER_CONFIG from "./BI_EDIT_LAYER_CONFIG";
import BI_FEATURES_LIST_CARD from "./BI_FEATURES_LIST_CARD";
import BI_FEATURES_LIST_TABLE from "./BI_FEATURES_LIST_TABLE";

/*REDUX*/
import {
  set_value_bi,
  trigger_rerender,
  trigger_fly_map,
} from "../../App/actions/biActions";

/*PICTURE*/

/*FUNCTION*/

/*DATA*/
import dict from "../../Data/dict.json";
import layer_sidebar_config_constant from "../../Data/layer_sidebar_config_constant.json";

/*CONST*/

class BI_SIDEBAR_LAYER_DETAIL extends Component {
  state = {};

  toggle_edit_layer_config = () => {
    const value = this.props.bi.modal_edit_layer_config;
    const new_value = !value;
    this.props.set_value_bi({
      key: "modal_edit_layer_config",
      value: new_value,
    });
    const { bi_object, layer_id } = this.props.bi;
    const layer_sidebar_config_list =
      bi_object?.layer_sidebar_config_list || [];
    let layer_sidebar_config = layer_sidebar_config_list.find(
      (item) => item.layer_id === layer_id
    );
    let value_object = {};
    if (new_value && layer_sidebar_config) {
      value_object = { ...layer_sidebar_config };
    } else {
      value_object = { ...layer_sidebar_config_constant };
    }
    this.props.set_value_bi({
      key: "layer_sidebar_config_object",
      value: value_object,
    });
  };

  on_back = () => {
    this.props.set_value_bi({
      key: "layer_id",
      value: "",
    });
    this.props.trigger_rerender();
    this.props.trigger_fly_map();
    this.props.set_value_bi({
      key: "modal_edit_layer_config",
      value: false,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const {
      project_list,
      bi_object,
      project_object_selected,
      layer_id,
      modal_edit_layer_config,
    } = this.props.bi;

    //content
    const layer_sidebar_config_list =
      bi_object?.layer_sidebar_config_list || [];
    let layer_sidebar_config = layer_sidebar_config_list.find(
      (item) => item.layer_id === layer_id
    );

    const setting_list = bi_object?.setting_list || [];
    const setting_object = setting_list.find(
      (item) => item?.project_id === project_object_selected?._id
    );
    const layer_view_map_list = setting_object?.layer_view_map_list || [];
    let geo_layer_list = [];
    project_list.forEach((item) => {
      let layer_list = item?.layer_list || [];
      layer_list = layer_list.filter((layer) => {
        return (
          layer?.geo_layer?.geojson?.features?.length > 0 &&
          !!layer?.geo_layer?.geojson?.features?.length &&
          layer_view_map_list.includes(layer?.geo_layer?._id)
        );
      });
      if (layer_list.length > 0) {
        geo_layer_list = [...geo_layer_list, ...layer_list];
      }
    });
    const geo_layer =
      geo_layer_list.find((item) => item?.geo_layer?._id === layer_id)
        ?.geo_layer || {};
    const fields = geo_layer?.fields || [];
    const features = geo_layer?.geojson?.features || [];

    let content;
    if (layer_sidebar_config?.mode_layout === "card_1") {
      content = (
        <BI_FEATURES_LIST_CARD
          fields={fields}
          features={features}
          layer_sidebar_config={layer_sidebar_config}
        />
      );
    } else if (layer_sidebar_config?.mode_layout === "table_1") {
      content = (
        <BI_FEATURES_LIST_TABLE
          fields={fields}
          features={features}
          layer_sidebar_config={layer_sidebar_config}
        />
      );
    } else {
      content = (
        <div className="container_light">
          <p className="text_medium">
            {dict?.["Visual setting have not yet been made"]?.[language]}
          </p>
        </div>
      );
    }

    const modal_edit_layer_config_content = modal_edit_layer_config && (
      <Draggable
        title={dict?.["Edit visuals for data rows"]?.[language]}
        toggle_close={this.toggle_edit_layer_config}
        style={{
          background: "white",
          width: "270px",
          zIndex: 5,
        }}
        initialPosition={{
          x: window.innerWidth - 270 - 50,
          y: 50,
        }}
      >
        <BI_EDIT_LAYER_CONFIG fields={fields} features={features} />
      </Draggable>
    );

    return (
      <main>
        {modal_edit_layer_config_content}
        <button
          className="badge_pill background_orange margin_bottom margin_right"
          onClick={this.on_back}
        >
          {dict?.["Back"]?.[language]}
        </button>
        <br />
        <h1 className="text_header margin_bottom">{geo_layer?.name}</h1>
        <button
          className="button_small margin_bottom background_black"
          onClick={this.toggle_edit_layer_config}
        >
          Edit visual
        </button>
        {content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
  project: state.project,
});

export default connect(mapStateToProps, {
  set_value_bi,
  trigger_rerender,
  trigger_fly_map,
})(BI_SIDEBAR_LAYER_DETAIL);
