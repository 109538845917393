import React, { Component } from "react";
import { HotTable } from "@handsontable/react";
import {
  setStyleHandsonTable
} from "../../App/validation/convert_data";



const handsontableConfigInput = {
  licenseKey: "non-commercial-and-evaluation"
}

class HotTableWrapper extends Component {
  shouldComponentUpdate(nextProps) {
    // Hanya render ulang jika worksheet berubah
    return (
      nextProps.worksheet !== this.props.worksheet ||
      nextProps.selectedSheet !== this.props.selectedSheet
    );
  }

  handleBeginEditing = (row, column) => {
    const { textfield, hotTableRef } = this.props;
    const hotInstance = hotTableRef?.current?.hotInstance;
    const editor = hotInstance?.getActiveEditor();

    if (editor) {
      editor.setValue(textfield); // Set initial value directly in the editor
    }
  };

  handleBoolean = (newValue) => {
    let updatedValue = newValue
    if (updatedValue.includes("TRUE")) {
      updatedValue = updatedValue.replace(/(?<![a-zA-Z])TRUE(?![a-zA-Z])\)?/gi, '"TRUE"');
    }
    if(updatedValue.includes("FALSE")){
      updatedValue = updatedValue.replace(/(?<![a-zA-Z])FALSE(?![a-zA-Z])\)?/gi, '"FALSE"');
    }
    return updatedValue
  }

  render() {

    const {
      worksheet,
      handle_selected_cell,
      setFocused,
      handle_edit_cell,
      hotTableRef,
      selectedSheet,
      calculatedSheets,
      rawSheets,
      hyperformulaInstance
    } = this.props;


    const is_mobile = window.innerWidth < 600;

    return (
      <HotTable
        {...handsontableConfigInput}
        style={{
          border: "1px solid #AAA",
          borderRadius: "4px",
        }}
        cells={(row, col) =>
          setStyleHandsonTable({
            row,
            col,
            data: calculatedSheets,
            worksheet,
            selectedSheet,
          })
        }
        ref={hotTableRef}
        width="100%"
        data={rawSheets?.[selectedSheet] || []}
        formulas={{
          engine: hyperformulaInstance,
          sheetName: selectedSheet
        }}
        rowHeaders={true}
        colHeaders={true}
        height={((is_mobile ? 75 : 76) / 100) * window.innerHeight
        }
        settings={{
          minSpareRows: 5,
          minSpareCols: 5, // Tambahkan baris kosong di akhir
          contextMenu: true, // Menampilkan opsi konteks (cut, copy, paste)
          manualColumnResize: true,
          manualRowResize: true,
          // beforeKeyDown: this.handleBeforeKeyDown,
        }}
        selectionMode="multiple"
        afterSelectionEnd={(startRow, startCol, endRow, endCol) => {
          handle_selected_cell({
            startRow,
            startCol,
            endRow,
            endCol,
          });
        }}
        // afterBeginEditing={(row, column) => {
        //   const hotInstance = hotTableRef?.current?.hotInstance;
        //   const editor = hotInstance.getActiveEditor();
        //   if (editor) {
        //     editor.setValue(textfield); // Set initial value directly in the editor
        //   }
        // }}
        beforeChange={(changes) => {
          changes?.forEach((change) => {
            let newValue = change?.[3];
            if (newValue) {
              change[3] = this.handleBoolean(newValue) //handle boolean
            }
          });
        }}
        autoWrapCol={true}
        afterBeginEditing={this.handleBeginEditing}
        afterChange={(changed_data, event) => {
          const new_value = changed_data?.[0]?.[3];
          const old_value = changed_data?.[0]?.[2];
          const is_not_same = old_value !== new_value
          if (event === "edit" && changed_data?.[0]?.[3] !== null && is_not_same) {
            handle_edit_cell(changed_data);
          }
        }}
        afterDeselect={(e) => {
          setFocused(null);
        }}
        outsideClickDeselects={(e) => {
          return false;
        }}
        modifyColWidth={(width) => {
          if (width > 300) {
            return 300;
          }
        }}
      />
    );
  }
}

export default HotTableWrapper;