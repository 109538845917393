import React, { Component } from "react";
import { connect } from "react-redux";
import uuid from "../../App/validation/uuid";
import dict from "../../Data/dict.json";
import { push_child_array } from "../../App/actions/crudNestedTable";

class CreateRow extends Component {
  handleAddRow = async () => {
    const {
      geo_layer_id,
      feature_key,
      parent_uuid,
      parent_uuid_temp,
      component_id,
    } = this.props;

    const body = {
      geo_layer_id,
      feature_key,
      child_uuid: uuid(),
      parent_uuid,
      parent_uuid_temp,
      properties: {},
    };

    await this.props.push_child_array(body, component_id);

    // let feature = {
    //   key,
    //   type: "Feature",
    //   properties: prop,
    //   formStatus: {
    //     status: "digitasi",
    //     message: "",
    //     revision_list: [],
    //   },
    //   geometry: coordinates,
    //   isHide: false,
    // };
    // const body = {
    //   geo_layer_id: this.props.layer.layer_id,
    //   feature,
    // };
    // const { layer_id, geo_layer_list } = this.props.layer;
    // const layer = geo_layer_list.find((l) => l.geo_layer._id === layer_id);
    // if (layer?.geo_layer?.architecture === "v2") {
    //   await this.props.pushFeatureV2(body);
    // } else {
    //   await this.props.pushFeature(body);
    // }

    // const limit = this.props.current_page.pageSize;
    // const rows_length =
    //   this.props?.apiDataGridRef?.current?.getAllRowIds()?.length || 0;
    // const last_page = Math.ceil(rows_length / limit) - 1;

    // this.props.apiDataGridRef.current.setPage(last_page);

    // const rowIndex = rows_length - 1;
    // const colIndex = this.props.apiDataGridRef.current.getColumnIndex(
    //   "no",
    //   false
    // );
    // const indexes = { rowIndex, colIndex };
    // this.props.apiDataGridRef.current.scrollToIndexes(indexes);
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";

    return (
      <>
        <button
          onClick={this.handleAddRow}
          style={{
            color: "#0166cb",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            textTransform: "uppercase",
          }}
        >
          <span
            style={{
              fontWeight: "500",
              fontSize: "25px",
              marginTop: "-5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            +
          </span>
          {dict?.["Add Row"]?.[language]}
        </button>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  push_child_array,
})(CreateRow);
