/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

/*COMPONENT*/
import Modal from "../../Components/common_modal/Modal";
import SpinnerSimpleBlue from "../../Components/common_spinner/SpinnerSimpleBlue";

/*REDUX*/
import { resetSearchUser } from "../../App/actions/authActions";
import {
  getGroup,
  deleteMember,
  editMember,
  searchUser,
  addMember,
  edit_active,
} from "../../App/actions/groupActions";
import { invitations } from "../../App/actions/layerNewActions";
import { set_value_user } from "../../App/actions/authActions";
import { set_value_project } from "../../App/actions/projectActions";

/*PICTURE*/
import pic_static from "../../Assets/svg/profile_pic_comment.svg";
import checklist from "../../Assets/svg/checklist.svg";
import icon_search from "../../Assets/svg/icon_search.svg";

/*FUNCTION*/
import isEmpty from "../../App/validation/is-empty";
import uuid from "../../App/validation/uuid";

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/
const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

class GroupDetail extends Component {
  state = {
    user: {},
    modalDeleteMember: false,
    member: {},
    status: "viewer",
    search_user: "",
    message: "",
    active: false,
  };

  componentDidMount() {
    this.timer = null;
    const group_id = this.props?.match?.params?.id;
    this.props.getGroup(group_id);
  }

  componentWillUnmount() {
    this.props.set_value_user("group", {});
    this.props.set_value_project({
      key: "folder_detail",
      value: {},
    });
  }

  toggle_payment_universal = () => {
    const { modal_payment_universal } = this.props.auth;
    this.props.set_value_user(
      "modal_payment_universal",
      !modal_payment_universal
    );
  };

  toggleDeleteMember = (member) => {
    if (member) {
      this.setState({ member });
    }
    this.setState({
      modalDeleteMember: !this.state.modalDeleteMember,
    });
  };

  handleDeleteMember = () => {
    const group_id = this.props.match.params.id;
    const { member } = this.state;
    if (member._id) {
      const body = {
        group_id,
        member_id: member._id,
        member_name: member?.user?.full_name
          ? member?.user?.full_name
          : member?.user?.name,
      };
      this.props.deleteMember(body);
    }
    this.setState({
      modalDeleteMember: false,
    });
  };

  handleEditMember = (e) => {
    e.preventDefault();
    const { group } = this.props.auth;
    const status = e.target.value;
    const member_id = e.target.name;
    const member_user_id = e.target.id;
    const body = {
      group_id: group._id,
      member_id,
      member_user_id,
      status,
    };
    this.props.editMember(body);
  };

  handleChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    clearTimeout(this.timer);
    this.setState({
      [name]: value,
    });
    this.timer = setTimeout(this.triggerChange.bind(this), WAIT_INTERVAL);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.triggerChange.bind(this);
    }
  }

  triggerChange() {
    const { search_user } = this.state;
    try {
      this.props.searchUser(search_user);
    } catch (error) {}
  }

  handleResetSearchUser = () => {
    this.setState({ search_user: "" }, () => {
      this.props.resetSearchUser();
    });
  };

  onAddMember = (member_id) => {
    this.handleResetSearchUser();
    const { license_user_status, license_group_status } =
      this.props.license_reducer;
    const payment_type = license_user_status?.payment_type;
    const logic_1 = ![
      // "license_academy",
      // "license_1",
      "license_2",
      "license_3",
    ].includes(payment_type);
    const logic_2 = !license_group_status?.is_has_license;
    if (logic_1 && logic_2) {
      this.toggle_payment_universal();
    } else {
      const group_id = this.props.match.params.id;
      const body = {
        group_id: group_id,
        member_id,
      };
      this.props.addMember(body);
    }
  };

  on_edit_active = (member) => {
    const { group } = this.props.auth;
    const body = {
      group_id: group?._id,
      user_id: member?.user?._id,
      active_status: "active",
    };
    this.props.edit_active(body);
  };

  on_edit_not_active = (member) => {
    const { group } = this.props.auth;
    const body = {
      group_id: group?._id,
      user_id: member?.user?._id,
      active_status: "not_active",
    };
    this.props.edit_active(body);
  };

  on_invite_email = async () => {
    this.handleResetSearchUser();
    const { license_user_status, license_group_status } =
      this.props.license_reducer;
    const payment_type = license_user_status?.payment_type;
    const logic_1 = ![
      // "license_academy",
      // "license_1",
      "license_2",
      "license_3",
    ].includes(payment_type);
    const logic_2 = !license_group_status?.is_has_license;
    if (logic_1 && logic_2) {
      this.toggle_payment_universal();
    } else {
      const { group } = this.props.auth;
      const domain = this.props.auth.domain;
      const { search_user } = this.state;

      const origin =
        domain === "localhost"
          ? "geo.mapid.io"
          : domain
          ? domain
          : "geo.mapid.io";

      if (search_user.indexOf("@") < 1 || search_user.indexOf(".") < 1) {
        this.setState({
          message: "Masukan alamat email dengan benar",
        });
      } else {
        try {
          let body = {
            origin,
            email: search_user,
            group_id: group?._id,
          };

          this.props.invitations(body);
        } catch (error) {
          this.setState({
            message: "Masukan alamat email dengan benar",
          });
        }
      }
    }
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { modalDeleteMember, member, search_user, message } = this.state;

    //global props
    const { user, group, loadingDetail, user_list } = this.props.auth;

    //content
    let group_members = !isEmpty(group?.members) ? group?.members : [];
    group_members = group_members.filter((member) => member?.user?._id);
    let itemContent;
    if (loadingDetail) {
      itemContent = (
        <SpinnerSimpleBlue
          width={200}
          unik="loading_item_list"
          marginTop="0px"
          center={true}
          border={0.1}
        />
      );
    } else {
      const members_group_id = group_members.map((member) => member?.user?._id);
      let user_list_content;
      if (user_list.length > 0) {
        user_list_content = (
          <section>
            {user_list.map(({ _id, name, full_name, profile_picture }, idx) => {
              let user_unit;
              if (members_group_id.includes(_id)) {
                user_unit = (
                  <main
                    style={{
                      textAlign: "left",
                      marginBottom: "10px",
                      cursor: "default",
                    }}
                  >
                    <section
                      alt={_id}
                      className={`photo_${_id}`}
                      samesite="None"
                      secure="true"
                    />
                    <section
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                        marginLeft: "10px",
                        fontSize: "12px",
                      }}
                    >
                      {full_name && full_name !== "" && full_name !== "-"
                        ? full_name
                        : name}
                    </section>
                    <style>
                      {`.photo_${_id}{
                          background: url(${
                            profile_picture && profile_picture.url_compressed
                              ? profile_picture.url_compressed
                              : profile_picture &&
                                profile_picture.url &&
                                profile_picture.url !==
                                  "https://s3-us-west-2.amazonaws.com/geomapid-assets/astronot.png" &&
                                profile_picture.url !== "-" &&
                                profile_picture.url !== "default"
                              ? profile_picture.url
                              : pic_static
                          }) no-repeat center center/ cover;
                          background-color: #c1c1c1;
                          height:40px;
                          width:40px;
                          border-radius: 50%;
                          border: 2px solid #2196f3;
                          display: inline-block;
                          align: center;
                          vertical-align: middle;
            }`}
                    </style>
                    <section
                      alt={_id}
                      className="badgeMember"
                      style={{ marginLeft: "5px" }}
                    >
                      <style>
                        {`.badgeMember{
                              background: url(${checklist}) no-repeat center center/ cover;
                              background-color: #c1c1c1;
                              height:15px;
                              width:15px;
                              border-radius: 50%;
                              display: inline-block;
                              align: center;
                              vertical-align: middle;
                              }`}
                      </style>
                    </section>
                  </main>
                );
              } else {
                user_unit = (
                  <main
                    style={{
                      textAlign: "left",
                      marginBottom: "10px",
                      cursor: "pointer",
                    }}
                    onClick={this.onAddMember.bind(this, _id)}
                    data-mapid="clickAdd"
                  >
                    <section
                      alt={_id}
                      className={`photo_${_id}`}
                      samesite="None"
                      secure="true"
                    />
                    <section
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                        marginLeft: "10px",
                        fontSize: "12px",
                      }}
                    >
                      {full_name && full_name !== "" && full_name !== "-"
                        ? full_name
                        : name}
                    </section>
                    <style>
                      {`.photo_${_id}{
                          background: url(${
                            profile_picture && profile_picture.url_compressed
                              ? profile_picture.url_compressed
                              : profile_picture &&
                                profile_picture.url &&
                                profile_picture.url !==
                                  "https://s3-us-west-2.amazonaws.com/geomapid-assets/astronot.png" &&
                                profile_picture.url !== "-" &&
                                profile_picture.url !== "default"
                              ? profile_picture.url
                              : pic_static
                          }) no-repeat center center/ cover;
                          background-color: #c1c1c1;
                          height:40px;
                          width:40px;
                          border-radius: 50%;
                          border: 2px solid #2196f3;
                          display: inline-block;
                          align: center;
                          vertical-align: middle;
                          }`}
                    </style>
                  </main>
                );
              }
              return (
                <main key={idx} className="card_search_group">
                  {user_unit}
                </main>
              );
            })}
          </section>
        );
      }

      const searchForm = (
        <main className="group_search">
          <div className="group_search_row">
            <div className="group_search_input">
              <img src={icon_search} alt="search" height="16px" />
              <input
                type="text"
                placeholder={dict["Search user to add as member"][language]}
                autoComplete="off"
                name="search_user"
                value={search_user}
                onChange={this.handleChange.bind(this)}
                onKeyDown={this.handleKeyDown.bind(this)}
                data-mapid="inputUser"
              />
              {user_list.length > 0 && (
                <div
                  className="button_small background_red"
                  onClick={this.handleResetSearchUser}
                  style={{ cursor: "pointer" }}
                >
                  X
                </div>
              )}
            </div>
            {search_user?.length !== 0 && (
              <main
                style={{
                  maxHeight: "300px",
                  width: "75%",
                  overflowY: "auto",
                  backgroundColor: "#fff",
                  padding: "10px 20px",
                  position: "absolute",
                }}
              >
                <section>
                  <h1 className="text_bold margin_bottom">
                    Opsi 1 Invite email
                  </h1>
                  <button
                    className="button background_blue"
                    onClick={this.on_invite_email}
                  >
                    Invite
                  </button>
                  {message && <p className="text_red">{message}</p>}
                </section>
                <hr />
                <section>
                  <h1 className="text_bold margin_bottom">
                    Opsi 2 Pilih dari user yang ada
                  </h1>
                  {user_list_content}
                </section>
              </main>
            )}
          </div>
        </main>
      );
      let memberStatus = "viewer";
      if (group?._id) {
        memberStatus = group?.members?.filter(
          (member) => member?.user?._id === user?._id
        )[0]?.status;
      }
      itemContent = (
        <main style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <section>
            <h1 className="title_detail_group">{group?.name}</h1>
            <p className="subtitle_detail_group">
              {group?.members?.length} members
            </p>
          </section>
          {(memberStatus === "creator" || memberStatus === "admin") &&
            searchForm}
          <section className="group_detail_member">
            <div className="group_member_top">
              <p className="card_group_name">Name</p>
              <p className="card_group_email">Email</p>
              <p className="card_group_role">Role</p>
              <p className="card_group_action">Action</p>
            </div>
            <div className="group_member_bottom">
              {group_members.map((member, idx) => {
                const user = member?.user;
                const status = member?.status;
                let _id = uuid();
                let member_id = uuid();
                let profile_picture;

                if (user) {
                  member_id = member?._id;
                  _id = user?._id;
                  // full_name = user?.full_name;
                  profile_picture = user?.profile_picture;
                  // name = user?.name;
                } else {
                  // full_name = dict["Deleted user"][language];
                }
                let deleteButton;
                const statusArray = [
                  "admin",
                  "contributor",
                  "viewer",
                  // "pending_view",
                  // "pending",
                ];
                let statusButton;
                if (status === "creator") {
                  statusButton = <p>{member?.status}</p>;
                } else if (
                  memberStatus === "creator" ||
                  memberStatus === "admin"
                ) {
                  /* mengubah status pending dan 
                  pending view menjadi viewer */
                  let status_will_show = status;
                  if (status === "pending" || status === "pending_view") {
                    status_will_show = "viewer";
                  }

                  statusButton = (
                    <select
                      id={_id}
                      name={member_id}
                      value={status_will_show}
                      onChange={this.handleEditMember}
                      data-mapid="pilihStatus"
                    >
                      {statusArray.map((status, idx) => {
                        return (
                          <option key={idx} value={status}>
                            {status}
                          </option>
                        );
                      })}
                    </select>
                  );
                } else {
                  statusButton = <div>{status}</div>;
                }

                if (
                  (memberStatus === "creator" || memberStatus === "admin") &&
                  status !== "creator" &&
                  member?.user?.email !== this.props.auth.user?.email
                ) {
                  deleteButton = (
                    <div
                      onClick={this.toggleDeleteMember.bind(this, member)}
                      className="button background_red"
                      data-mapid="clickDelete"
                    >
                      {dict["Delete"][language]}
                    </div>
                  );
                } else {
                  deleteButton = null;
                }

                const buttonActive =
                  member?.active_status === "active" ? (
                    <button
                      className="button background_grey"
                      style={{ width: "110px" }}
                      onClick={() => this.on_edit_not_active(member)}
                      data-mapid="clickAktif"
                    >
                      Non aktifkan
                    </button>
                  ) : (
                    <button
                      className="button background_blue"
                      style={{ width: "110px" }}
                      onClick={() => this.on_edit_active(member)}
                      data-mapid="clickNonAktif"
                    >
                      Aktifkan
                    </button>
                  );

                return (
                  user && (
                    <div key={idx} className="card_group_detail">
                      <div className="card_group_name">
                        <div
                          alt={_id}
                          className={`photo_member_${_id}`}
                          samesite="None"
                          secure="true"
                        />
                        <style>
                          {`.photo_member_${_id}{
                            background: url(${
                              profile_picture && profile_picture.url_compressed
                                ? profile_picture.url_compressed
                                : profile_picture &&
                                  profile_picture.url &&
                                  profile_picture.url !==
                                    "https://s3-us-west-2.amazonaws.com/geomapid-assets/astronot.png" &&
                                  profile_picture.url !== "-" &&
                                  profile_picture.url !== "default"
                                ? profile_picture.url
                                : pic_static
                            }) no-repeat center center/ cover;
                            background-color: white;
                            height:40px;
                            width:40px;
                            border-radius: 50%;
                            border: 2px solid white;
                            display: inline-block;
                            align: center;
                            vertical-align: middle;
              }`}
                        </style>
                        <div className="card_group_name_row">
                          <div className="card_group_name_row_top">
                            <h3>
                              {member?.user.full_name
                                ? member?.user.full_name
                                : member?.user.name
                                ? member?.user.name
                                : "-"}
                            </h3>
                            <button
                              disabled
                              className="button_small background_blue"
                              id={
                                member?.active_status === "active"
                                  ? "green_light"
                                  : "grey_light"
                              }
                            >
                              {member?.active_status}
                            </button>
                          </div>
                          <p>{member?.status}</p>
                        </div>
                      </div>
                      <div className="card_group_email">
                        <p>{member?.user?.email}</p>
                      </div>
                      <div className="card_group_role">
                        <div style={{ textAlign: "left", width: "80%" }}>
                          {memberStatus !== "creator" ? (
                            <p>{member?.status}</p>
                          ) : (
                            statusButton
                          )}
                        </div>
                      </div>
                      <div className="card_group_action">
                        {(memberStatus === "creator" ||
                          memberStatus === "admin") &&
                        status !== "creator" &&
                        member?.user?.email !== this.props.auth.user?.email
                          ? buttonActive
                          : null}
                        {deleteButton}
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </section>
        </main>
      );
    }

    const modalDeleteMemberContent = modalDeleteMember && (
      <Modal
        modalSize="small"
        isOpen={modalDeleteMember}
        onClose={this.toggleDeleteMember.bind(this, member)}
      >
        <div className="box-body" style={{ textAlign: "center" }}>
          <div className="text_medium">
            {dict["Are you sure to delete"][language]}
          </div>
          <br />
          <div className="text_bold" style={{ textAlign: "center" }}>
            {member?.user?.full_name
              ? member?.user?.full_name
              : member?.user?.name}
          </div>
          <br />
          <div
            onClick={this.handleDeleteMember}
            className="button_very_small background_red"
            data-mapid="clickDeleteModal"
          >
            {dict["Delete"][language]}
          </div>
        </div>
      </Modal>
    );

    return (
      <div className="main_container">
        <Helmet>
          <title>{`${
            group?.name ? group?.name : dict["Group"][language]
          }`}</title>
          <meta
            name="description"
            content={`${group?.name ? group?.name : dict["Group"][language]}`}
          />
        </Helmet>
        {itemContent}
        {modalDeleteMemberContent}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  license_reducer: state.license_reducer,
});

export default connect(mapStateToProps, {
  getGroup,
  deleteMember,
  editMember,
  searchUser,
  resetSearchUser,
  addMember,
  edit_active,
  invitations,
  set_value_user,
  set_value_project,
})(GroupDetail);
