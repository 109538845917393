//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux function
import {
  searchLayersOnly,
  searchLayersOnlyPublic,
  downloadLayer,
  clearSearchLayer,
  loadMoreSearchLayersOnlyPublic,
  loadMoreSearchLayersOnly,
} from "../../App/actions/layerActions";

//Picture Asset
import pic_static from "../../Assets/svg/profile_pic_comment.svg";

//General Function
import isEmpty from "../../App/validation/is-empty";

// import is_equal_array from "../../App/validation/is_equal_array";
import dict from "../../Data/dict.json";

const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

class SearchCountingCustom extends Component {
  state = {
    search_params: "",
    geo_layer: {},
    layers_attach_id: [],
    layers_import_id: [],
    modal_block: false,
    data_skip: 0,
    isTyping: false,
  };

  componentDidMount() {
    this.timer = null;
    const list_id = this.props.layer.geo_layer_list.map((l) => l._id);
    this.setState({ layers_attach_id: list_id });
  }

  componentWillUnmount() {
    this.setState({
      search_params: "",
      geo_layer: {},
      data_skip: 0,
    });
  }

  toggleBlock = () => {
    this.setState({ modal_block: !this.state.modal_block });
  };

  handleSelect = ({ _id }) => {
    const body = _id;
    this.props.set_custom_counting_id(body);
    this.props.toggle_counting_custom();
  };

  handleChange(e) {
    if (this.props.layer.search_result_list.geo_layer_list.length > 0) {
      this.props.clearSearchLayer();
    }
    const target = e.target;
    const value = target.value;
    const name = target.name;
    clearTimeout(this.timer);
    this.setState({
      [name]: value,
      isTyping: true,
      data_skip: 0,
    });
    this.timer = setTimeout(this.triggerChange.bind(this), WAIT_INTERVAL);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.triggerChange.bind(this);
    }
  }

  triggerChange() {
    const { search_params } = this.state;
    if (search_params) {
      this.props.searchLayersOnly(search_params);
      this.setState({
        data_skip: this.state.data_skip + 20,
        isTyping: false,
      });
    }
  }

  handleLoadMore = () => {
    const { search_params, data_skip } = this.state;
    if (search_params && data_skip >= 20) {
      this.props.loadMoreSearchLayersOnly(search_params, data_skip);
      this.setState({
        data_skip: this.state.data_skip.private + 20,
      });
    }
  };
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { search_params } = this.state;
    const { loadingProcess, itemLoading } = this.props.layer;
    const { geo_layer_list } = this.props.layer.search_result_list;
    let resultContent;
    let loading_content = null;
    if (loadingProcess && itemLoading === "search_layer") {
      loading_content = (
        <div className="container_grey">{dict["Searching"][language]}...</div>
      );
    }

    if (isEmpty(geo_layer_list)) {
      resultContent = !this.state.isTyping && !loadingProcess && (
        <div className="container_grey">{dict["No result"][language]}</div>
      );
    } else {
      resultContent = (
        <div>
          {geo_layer_list.map((geo_layer, idx) => {
            const { _id, name, geo_project } = geo_layer;
            const description = geo_layer?.description;
            const geo_project_name = geo_project?.name;
            const full_name = geo_layer?.user?.full_name
              ? geo_layer?.user?.full_name
              : geo_layer?.user?.name;
            const profile_picture = geo_layer?.user?.profile_picture
              ? geo_layer?.user?.profile_picture
              : {};

            let buttonSelect = (
              <main style={{ marginBottom: "10px" }}>
                <button
                  onClick={() => this.handleSelect(geo_layer)}
                  className="button background_blue"
                >
                  {dict["Choose & save"][language]}
                </button>
              </main>
            );
            return (
              <main
                key={idx}
                className="container_grey"
                style={{ marginBottom: "10px" }}
              >
                <main className="two_container_auto">
                  <section style={{ textAlign: "left" }}>
                    <div className="text_bold">{name}</div>
                    {description && (
                      <div className="text_inferior">{description}</div>
                    )}
                    <div className="text_inferior">
                      {dict["Project"][language]}: {geo_project_name}
                    </div>
                    <div
                      alt={_id}
                      className={`photo_${_id}`}
                      samesite="None"
                      secure="true"
                    />
                    <p
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                        marginLeft: "10px",
                        fontSize: "12px",
                      }}
                    >
                      {full_name}
                    </p>
                    <style>
                      {`.photo_${_id}{
                      background: url(${
                        profile_picture && profile_picture.url_compressed
                          ? profile_picture.url_compressed
                          : profile_picture &&
                            profile_picture.url &&
                            profile_picture.url !==
                              "https://s3-us-west-2.amazonaws.com/geomapid-assets/astronot.png" &&
                            profile_picture.url !== "-" &&
                            profile_picture.url !== "default"
                          ? profile_picture.url
                          : pic_static
                      }) no-repeat center center/ cover;
                      background-color: #c1c1c1;
                      height:40px;
                      width:40px;
                      border-radius: 50%;
                      border: 2px solid #2196f3;
                      display: inline-block;
                      align: center;
                      vertical-align: middle;
        }`}
                    </style>
                  </section>
                  <section
                    className="flex align_center"
                    style={{
                      justifyContent: "flex-end",
                    }}
                  >
                    {buttonSelect}
                  </section>
                </main>
              </main>
            );
          })}
        </div>
      );
    }

    const loadMoreButton = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={this.handleLoadMore}
      >
        <button className="button background_blue">
          {dict["Load more"][language]}
        </button>
      </div>
    );

    const content = (
      <main>
        <section className="font_20 bold paddingBottom_15 text_center">
          {dict["Choose comparison data from your existing data"][language]}
        </section>
        <input
          className="w_full"
          type="text"
          placeholder={dict["Search layers"][language]}
          autoComplete="off"
          name="search_params"
          value={search_params}
          onChange={this.handleChange.bind(this)}
          onKeyDown={this.handleKeyDown.bind(this)}
        />
        <section
          style={{ height: "calc(( 80vh - 130px ))", overflowY: "auto" }}
        >
          {search_params && resultContent}
          {loading_content}
          {geo_layer_list.length % 20 === 0 &&
            geo_layer_list.length !== 0 &&
            loadMoreButton}
        </section>
      </main>
    );

    return <main style={{ minHeight: "80vh" }}>{content}</main>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  project: state.project,
  payment: state.payment,
});

export default connect(mapStateToProps, {
  searchLayersOnly,
  searchLayersOnlyPublic,
  downloadLayer,
  clearSearchLayer,

  loadMoreSearchLayersOnlyPublic,
  loadMoreSearchLayersOnly,
})(SearchCountingCustom);
