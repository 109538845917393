/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/
import icon_product from "../../Assets/png_jpg/icon_product.png";

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class LOADING_PAGE extends Component {
  state = {};

  render() {
    //local storage

    //local state

    //global props

    //content
    return (
      <main className="main_container">
        <section className="container_light background_white">
          <img src={icon_product} alt="MAPID PRODUCT" width="100" />
          <h1>Memuat...</h1>
          <p>Memuat fitur...</p>
          <br />
          <br />
          <br />
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(LOADING_PAGE);
