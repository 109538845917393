/* eslint-disable */

// const globalState = {
//     defValue:1,
//     positive:0,
//     negative:0, 
//     netral:0,
//     isOpen: false
//   }
  
// //reducer
//   const rootReducer = (state=globalState, action) => {
//     switch(action.type){
//       case 'add_positive' :
//           return {
//               ...state,
//               positive: action.newValue
//           }
//       case 'add_negative' :
//           return {
//               ...state,
//               negative: action.newValue
//           }
//       case 'add_netral' :
//           return {
//               ...state,
//               netral: action.newValue
//           }
//       case 'open_modal' :
//           return {
//               ...state,
//               isOpen: true
//           }
//       case 'hide_modal' :
//           return {
//               ...state,
//               isOpen: false
//           }
//     }
//     return state;
//   }

const globalState = {
    defValue:4,
    positive:0,
    negative:0, 
    netral:0,
    isOpen: false,
    image: ""
  }
  
  //reducer
  const rootReducer = (state = globalState, action) => {
    switch(action.type){
      case 'show_modal' :
          return {
              ...state,
              isOpen: true,
              image: action.newValue
          }
      case 'hide_modal' :
          return {
              ...state,
              isOpen: false
          }
    }
    return state;
   }

export default rootReducer;