/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import FOLDER_CHILD from "./FOLDER_CHILD";
import Modal from "../common_modal/Modal";

/*REDUX*/
import {
  get_all_folder_project_list_by_user_id,
  move_folder_to_another_folder,
} from "../../App/actions/folderProjectAction";
import {
  set_value_project,
  push_value_project,
} from "../../App/actions/projectActions";

/*PICTURE*/

/*FUNCTION*/

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class MOVE_FOLDER_TO_FOLDER extends Component {
  state = {
    modal_warn: false,
  };

  componentDidMount() {
    this.props.get_all_folder_project_list_by_user_id();
    const folder = { ...this.props.folder };
    this.setState({ ...folder });
  }

  toggle_warn = () => {
    this.setState({ modal_warn: !this.state.modal_warn });
  };

  on_click_folder = (folder_id) => {
    const { folder_id_selected } = this.props.project;
    if (folder_id !== folder_id_selected) {
      this.props.set_value_project({
        key: "folder_id_selected_move",
        value: folder_id,
      });
    }
  };

  on_move_folder_to_another_folder = () => {
    const {
      folder_id_selected_move,
      folder_object_selected_list,
      folder_id_selected,
    } = this.props.project;

    if (
      folder_id_selected === folder_id_selected_move ||
      !folder_id_selected_move
    ) {
      this.toggle_warn();
    } else {
      const key =
        folder_object_selected_list.length === 0
          ? "folder_project_list"
          : "folder_project_child_list";

      const body = {
        key,
        folder_id: this?.props?.folder?._id,
        folder_parent_id: folder_id_selected_move,
      };

      this.props.move_folder_to_another_folder(body);
      this.props.toggle_move_folder();
    }
  };

  on_remove = () => {
    const body = {
      key: "folder_project_child_list",
      folder_id: this?.props?.folder?._id,
      folder_parent_id: "",
    };
    this.props.move_folder_to_another_folder(body);
    this.props.toggle_move_folder();
    //folder_project_list
    this.props.push_value_project({
      key: "folder_project_list",
      value: this?.props?.folder,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { modal_warn } = this.state;

    //global props
    let {
      folder_project_list_all,
      folder_id_selected_move,
      folder_object_selected_list,
      folder_id_selected,
    } = this.props.project;
    const folder = this.props.folder;

    folder_project_list_all = folder_project_list_all.filter(
      (item) => item._id !== folder._id
    );

    //content
    const folder_parent_list = folder_project_list_all.filter(
      (item) => !item?.folder_parent_id
    );

    const modal_warn_content = modal_warn && (
      <Modal modalSize="small" isOpen={modal_warn} onClose={this.toggle_warn}>
        <div className="box-body" id="box-body">
          {dict?.["Please select a folder first"]?.[language]}
        </div>
      </Modal>
    );

    return (
      <main>
        {modal_warn_content}
        <h3 className="text_header margin_bottom">
          {dict?.["Move to folder"]?.[language]}
        </h3>

        <section
          className="margin_bottom"
          style={{
            maxHeight: "calc(( 70vh - 200px ))",
            height: "calc(( 70vh - 200px ))",
            overflowY: "scroll",
          }}
        >
          {folder_parent_list.map((item, idx) => {
            const folder_list_child = folder_project_list_all.filter(
              (child) => child?.folder_parent_id === item?._id
            );
            return (
              <div key={idx} className="margin_bottom">
                <div
                  className={`container_folder background_white background_white cursor_pointer ${
                    item._id === folder_id_selected_move
                      ? "outline_blue"
                      : "outline_grey"
                  }`}
                  onClick={this.on_click_folder.bind(this, item?._id)}
                >
                  <table className="full_width">
                    <tbody>
                      <tr>
                        <td>
                          <p
                            className="max_one_lines"
                            style={{
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                          >
                            {item?.name}
                          </p>
                        </td>
                        {folder_id_selected_move === item?._id && (
                          <td className="text_right">
                            <span className="badge_circle_small background_green" />
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>

                {folder_id_selected === item?._id && (
                  <div className="badge">Current</div>
                )}

                {folder_list_child?.length > 0 && (
                  <FOLDER_CHILD
                    folder_id_selected={folder_id_selected}
                    folder_parent_id={item?._id}
                    folder_list_child={folder_list_child}
                    folder_project_list_all={folder_project_list_all}
                    on_click_folder={this.on_click_folder}
                    folder_id_selected_move={folder_id_selected_move}
                  />
                )}
              </div>
            );
          })}
        </section>

        <section className="margin_top">
          <button
            className={`button margin_right margin_bottom ${
              folder_id_selected_move ? "background_blue" : "background_grey"
            }`}
            onClick={this.on_move_folder_to_another_folder}
          >
            {dict?.["Move"]?.[language]}
          </button>
          {folder_object_selected_list.length > 0 && (
            <button
              className="button background_black"
              onClick={this.on_remove}
            >
              {dict?.["Remove from folder"]?.[language]}
            </button>
          )}
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
});

export default connect(mapStateToProps, {
  get_all_folder_project_list_by_user_id,
  set_value_project,
  move_folder_to_another_folder,
  push_value_project,
})(MOVE_FOLDER_TO_FOLDER);
