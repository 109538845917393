/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*

/*PICTURE*/

/*FUNCTION*/

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class ADD_PROJECT_BI extends Component {
  state = {};

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props

    //content
    return (
      <main>
        <h1 className="text_bold">
          {dict?.["Add projects into the BI dashboard"]?.[language]}
        </h1>
        <section className="container_light">
          <p className="text_small">Coming soon</p>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(ADD_PROJECT_BI);
