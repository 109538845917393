const initial_state = {
  license_user_status: {},
  license_group_status: {},
  group_family: {},
};

export default function license_reducer(state = initial_state, action) {
  switch (action.type) {
    case "set_value_license":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    default:
      return state;
  }
}
