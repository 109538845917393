/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "@mui/material/Menu";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";
import DELETE_BI from "./DELETE_BI";
import RENAME_BI from "./RENAME_BI";

/*REDUX FUNCTION*/
import { set_value_bi } from "../../App/actions/biActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class BI_MENU extends Component {
  state = {
    anchor: null,
    modal_menu: false,
    modal_delete: false,
    modal_rename: false,
  };

  toggle_delete = () => {
    this.setState({
      modal_delete: !this.state.modal_delete,
      modal_menu: false,
    });
  };

  toggle_rename = () => {
    this.setState({
      modal_rename: !this.state.modal_rename,
      modal_menu: false,
    });
  };

  toggle_menu = (e) => {
    this.setState({ modal_menu: !this.state.modal_menu });
    if (e) {
      const { bi_list } = this.props.bi;
      const bi_selected = bi_list.find((item) => item._id === e.target.id);
      this.setState({
        anchor: e.currentTarget,
      });
      this.props.set_value_bi({
        key: "bi_selected",
        value: bi_selected,
      });
    }
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { anchor, modal_menu, modal_delete, modal_rename } = this.state;

    //global props

    //content
    const modal_menu_content = modal_menu && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_menu}
        onClose={this.toggle_menu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        style={{ borderRadius: "15px" }}
      >
        <div style={{ padding: "7px 15px 0px 15px" }}>
          <button
            className="button margin_bottom button_white"
            onClick={this.toggle_rename}
          >
            {dict?.["Rename"]?.[language]}
          </button>
          <br />
          <button
            className="button margin_bottom button_white"
            onClick={this.toggle_delete}
          >
            {dict?.["Delete"]?.[language]}
          </button>
        </div>
      </Menu>
    );

    const modal_delete_content = modal_delete && (
      <Modal
        modalSize="small"
        isOpen={modal_delete}
        onClose={this.toggle_delete}
      >
        <div className="box-body" id="box-body">
          <DELETE_BI toggle_delete={this.toggle_delete} />
        </div>
      </Modal>
    );

    const modal_rename_content = modal_rename && (
      <Modal
        modalSize="small"
        isOpen={modal_rename}
        onClose={this.toggle_rename}
      >
        <div className="box-body" id="box-body">
          <RENAME_BI toggle_rename={this.toggle_rename} />
        </div>
      </Modal>
    );

    return (
      <main>
        {modal_menu_content}
        {modal_delete_content}
        {modal_rename_content}
        <button
          onClick={this.toggle_menu}
          id={this.props.id}
          className="button almost_white"
        >
          ⫶
        </button>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  bi: state.bi,
});

export default connect(mapStateToProps, { set_value_bi })(BI_MENU);
