import axios from "axios";
import { calculateWithoutConvert } from "../validation/convert_data";

// const bun_server = "http://localhost:4004";
const bun_server = "https://geoserver.mapid.io";

/**
 * @route :
 * @endpoint :
 * @methode :
 * @params :
 * @body :
 */
export const get_features_by_bound = (params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const { bounds, geo_layer_id, geo_project_id, fields } = params;
    const min_lat = bounds?._ne?.lat;
    const min_long = bounds?._ne?.lng;
    const max_lat = bounds?._sw?.lat;
    const max_long = bounds?._sw?.lng;
    const res = await axios.get(
      bun_server +
        `/moneys_bun/get_features_by_bound?geo_layer_id=${geo_layer_id}&geo_project_id=${geo_project_id}&min_lat=${min_lat}&min_long=${min_long}&max_lat=${max_lat}&max_long=${max_long}`,
      config
    );
    let features = res?.data || [];
    features = features.map((feature, idx) => {
      return {
        ...feature,
        idx,
        type: "Feature",
        properties: { ...feature.properties, key: feature.key },
      };
    });
    features = calculateWithoutConvert({
      features,
      fields,
      source: "get_features_by_bound",
    });
    dispatch({
      type: "set_geojson",
      payload: {
        geo_layer_id,
        features: features,
      },
    });
    dispatch({
      type: "status_action",
    });
  } catch (error) {}
};
