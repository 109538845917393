import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      version="1.1"
      viewBox="0 0 6.879 6.879"
    >
      <defs>
        <clipPath id="clipPath10831">
          <path fill="#fff" d="M-0.997 -1.254H9.82V11.803H-0.997z"></path>
        </clipPath>
      </defs>
      <g
        strokeLinejoin="round"
        strokeOpacity="1"
        paintOrder="stroke markers fill"
        transform="translate(-111.019 -135.358)"
      >
        <circle
          cx="114.458"
          cy="138.797"
          r="3.44"
          fill="#21a5f6"
          fillOpacity="1"
          fillRule="evenodd"
          stroke="none"
          strokeLinecap="round"
          strokeMiterlimit="100"
          strokeWidth="0.185"
          stopColor="#000"
        ></circle>
        <path
          fill="none"
          stroke="#fff"
          strokeDasharray="none"
          strokeWidth="1.162"
          d="M4.41.25c-1.065 0-2.13.406-2.943 1.219a4.148 4.148 0 00-1.21 2.644c-.05.787.097 1.49.393 2.11.422.884 1.05 1.364 1.846 1.77 1.432.729 1.902 2.074 1.914 3.112.012-1.038.482-2.383 1.914-3.113.796-.405 1.424-.885 1.846-1.77.296-.62.443-1.322.393-2.109a4.147 4.147 0 00-1.21-2.644A4.15 4.15 0 004.41.25zm0 2.316a1.847 1.847 0 11-.002 3.693 1.847 1.847 0 01.002-3.693z"
          clipPath="url(#clipPath10831)"
          transform="matrix(.34027 0 0 .34277 112.958 136.852)"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
