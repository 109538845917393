import React, { Component } from "react";

export default class LongPress extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pressing: false,
    };

    this.pressTimer = null;
  }

  componentWillUnmount() {
    this.clearPressTimer();
  }

  clearPressTimer = () => {
    if (this.pressTimer) {
      clearTimeout(this.pressTimer);
      this.pressTimer = null;
    }
  };

  handlePressStart = () => {
    const { delay = 1000 } = this.props;
    this.clearPressTimer();

    this.pressTimer = setTimeout(() => {
      this.setState({ pressing: true });
      if (this.props.onLongPress) {
        this.props.onLongPress();
      }
    }, delay);
  };

  handlePressEnd = () => {
    this.clearPressTimer();

    if (this.state.pressing) {
      this.setState({ pressing: false });
    }
  };

  render() {
    const { onLongPress, ...rest } = this.props;
    return (
      <main
        {...rest}
        onMouseDown={this.handlePressStart}
        onMouseUp={this.handlePressEnd}
        onTouchStart={this.handlePressStart}
        onTouchEnd={this.handlePressEnd}
      >
        {this.props.children}
      </main>
    );
  }
}
