/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/

/*PICTURE*/

/*FUNCTION*/

/*DATA*/

/*CONST*/

class LAYER_TOOLBOX_GRID extends Component {
  state = {};

  componentDidUpdate(prevProps) {
    const basemap_used_after = this.props.properties.basemap_used;
    const basemap_used_before = prevProps.properties.basemap_used;
    if (
      this.props.toolbox.status_toolbox !== prevProps.toolbox.status_toolbox ||
      basemap_used_after !== basemap_used_before
    ) {
      this.on_render();
    }
  }
  on_render = () => {
    const { features_grid } = this.props.toolbox;
    const { map_object } = this.props.layer;
    if (!map_object) return;
    const { paint_object_edited } = this.props.properties;
    const geojson_grid = {
      type: "FeatureCollection",
      features: features_grid,
    };
    const layer_grid_polygon_id = "GRID_TOOLBOX_POLYGON";
    if (!map_object.getSource(layer_grid_polygon_id)) {
      map_object.addSource(layer_grid_polygon_id, {
        type: "geojson",
        data: geojson_grid,
      });
    } else {
      map_object.getSource(layer_grid_polygon_id).setData(geojson_grid);
    }
    if (!map_object.getLayer(layer_grid_polygon_id)) {
      map_object.addLayer({
        id: layer_grid_polygon_id,
        type: "fill",
        source: layer_grid_polygon_id,
        paint: Object.fromEntries(
          Object.entries(paint_object_edited).filter(([key]) =>
            key.startsWith("fill-")
          )
        ),
        layout: {
          visibility: "visible",
        },
      });
    } else {
      Object.entries(paint_object_edited).forEach(([property, value]) => {
        if (property.startsWith("fill-")) {
          map_object.setPaintProperty(layer_grid_polygon_id, property, value);
        }
      });
    }
  };

  render() {
    return <main></main>;
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  properties: state.properties,
  toolbox: state.toolbox,
});

export default connect(mapStateToProps, {})(LAYER_TOOLBOX_GRID);
