import React, { Component } from "react";
import { connect } from "react-redux";
class ICON_LIST extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={15}
        height={15}
        viewBox="0 0 5.292 5.292"
      >
        <path
          className={this.props.className}
          d="M436.07 83.754h3.885M436.07 85.368h3.885M436.07 86.981h3.885"
          style={{
            fill: "none",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 0.52917,
          }}
          transform="translate(-435.37 -82.722)"
        />
      </svg>
    );
  }
}
const mapStateToProps = () => ({});
export default connect(mapStateToProps, {})(ICON_LIST);
