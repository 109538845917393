/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import COLOR_INPUT from "../common_input/COLOR_INPUT";

/*REDUX*/
import { edit_label_config } from "../../App/actions/propertiesActions";

/*PICTURE*/

/*FUNCTION*/

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/
const font_weight_array = [
  { key: "lighter", name: "Light" },
  { key: "normal", name: "Normal" },
  { key: "bold", name: "Bold" },
];

class EDIT_LABEL extends Component {
  state = { label_config: {}, fields_text: [], fields_image: [] };

  componentDidMount() {
    const { geo_layer_list, layer_id } = this.props.layer;
    const geo_layer =
      geo_layer_list.find((item) => item?.geo_layer?._id === layer_id)
        ?.geo_layer || {};
    const label_config = geo_layer?.label_config || {};
    const fields = geo_layer?.fields || [];
    const fields_text = fields.filter((item) => item.type !== "image");
    const fields_image = fields.filter((item) => item.type === "image");
    this.setState({
      label_config: { ...label_config },
      fields_text: [...fields_text],
      fields_image: [...fields_image],
    });
  }

  on_close_modal_edit_label_config = () => {
    this.props.set_value_properties({
      key: "modal_edit_label_config",
      value: false,
    });
  };

  on_toggle = (item) => {
    let { label_config } = this.state;
    label_config[item] = !label_config[item];
    this.setState({ label_config });
  };

  on_change = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let { label_config } = this.state;
    label_config[name] = value;
    this.setState({ label_config });
  };

  on_change_color = (object) => {
    const { name, value } = object;
    let { label_config } = this.state;
    label_config[name] = value;
    this.setState({ label_config });
  };

  on_set_is_use_pin = (is_use_pin) => {
    let { label_config } = this.state;
    label_config["is_use_pin"] = is_use_pin;
    this.setState({ label_config });
  };

  on_set_font_weight = (font_weight) => {
    let { label_config } = this.state;
    label_config["font_weight"] = font_weight;
    this.setState({ label_config });
  };

  on_toggle_array_item = (item, value) => {
    let { label_config } = this.state;
    let array = label_config[item] || [];
    if (array.includes(value)) {
      array = array.filter((element) => element !== value);
    } else {
      array.push(value);
    }
    label_config[item] = array;
    this.setState({ label_config });
  };

  on_save = () => {
    const { layer_id } = this.props.layer;
    let { label_config } = this.state;
    const is_use_label_text = label_config?.is_use_label_text || false;
    const is_use_label_image = label_config?.is_use_label_image || false;
    const label_text_field_key_array =
      label_config?.label_text_field_key_array || [];
    const label_image_field_key_array =
      label_config?.label_image_field_key_array || [];
    const style_mode = label_config?.style_mode || "style_1";
    const background_color_primary =
      label_config?.background_color_primary || "#292571";
    const outline_color_primary =
      label_config?.outline_color_primary || "#ffffff";
    const text_color_primary = label_config?.text_color_primary || "#ffffff";
    const is_use_pin = label_config?.is_use_pin || false;
    const text_stroke_width = parseFloat(label_config?.text_stroke_width || 0);
    const text_stroke_color = label_config?.text_stroke_color || "#ffffff";
    const font_size = parseInt(label_config?.font_size || 16);
    const font_weight = label_config?.font_weight || "normal";
    const pin_height = parseInt(label_config?.pin_height || 25);
    label_config = {
      ...label_config,
      is_use_label_text,
      is_use_label_image,
      label_text_field_key_array,
      label_image_field_key_array,
      style_mode,
      background_color_primary,
      outline_color_primary,
      text_color_primary,
      is_use_pin,
      text_stroke_width,
      text_stroke_color,
      font_size,
      font_weight,
      pin_height,
    };
    const body = {
      geo_layer_id: layer_id,
      label_config,
    };
    this.props.edit_label_config(body);
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { label_config, fields_text, fields_image } = this.state;

    //global props
    const { loading_item_array } = this.props.loading_reducer;

    //content
    const is_use_label_text = label_config?.is_use_label_text || false;
    const is_use_label_image = label_config?.is_use_label_image || false;
    const label_text_field_key_array =
      label_config?.label_text_field_key_array || [];
    const label_image_field_key_array =
      label_config?.label_image_field_key_array || [];
    const background_color_primary =
      label_config?.background_color_primary || "#292571";
    const outline_color_primary =
      label_config?.outline_color_primary || "#ffffff";
    const text_color_primary = label_config?.text_color_primary || "#ffffff";
    const is_use_pin = label_config?.is_use_pin || false;
    const text_stroke_width = label_config?.text_stroke_width || 0;
    const text_stroke_color = label_config?.text_stroke_color || "#ffffff";
    const font_size = label_config?.font_size || 0;
    const font_weight = label_config?.font_weight || "normal";
    const pin_height = label_config?.pin_height || 0;

    return (
      <main
        style={{ maxHeight: "calc( 100vh - 200px )", overflowY: "auto" }}
        className="container_light"
      >
        <section className="container_light background_grey_light margin_bottom">
          <h1 className="text_bold margin_bottom">Pilih Kolom</h1>
          <div className="container_light background_white margin_bottom">
            <div className="two_container_list margin_bottom">
              <div
                className={
                  is_use_label_text ? "checklist_active" : "checklist_not"
                }
                onClick={this.on_toggle.bind(this, "is_use_label_text")}
              />
              <p className="text_bold margin_bottom">
                {dict?.["Use text labels"]?.[language]}
              </p>
            </div>

            {is_use_label_text && (
              <section className="margin_bottom">
                <p className="text_medium">
                  {dict?.["Select the column used as the label"]?.[language]}
                </p>
                <div
                  style={{
                    maxHeight: "calc( 50vh - 200px )",
                    overflowY: "auto",
                  }}
                >
                  <table>
                    <tbody>
                      {fields_text.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td style={{ width: "20px" }}>
                              <div
                                className={
                                  label_text_field_key_array.includes(item?.key)
                                    ? "checklist_active"
                                    : "checklist_not"
                                }
                                onClick={this.on_toggle_array_item.bind(
                                  this,
                                  "label_text_field_key_array",
                                  item?.key
                                )}
                              />
                            </td>
                            <td>{item?.name}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </section>
            )}
          </div>

          <div className="container_light background_white margin_bottom">
            <div className="two_container_list margin_bottom">
              <div
                className={
                  is_use_label_image ? "checklist_active" : "checklist_not"
                }
                onClick={this.on_toggle.bind(this, "is_use_label_image")}
              />
              <p className="text_bold margin_bottom">
                {dict?.["Use image labels"]?.[language]}
              </p>
            </div>

            {is_use_label_image && (
              <section className="margin_bottom">
                <p className="text_medium">
                  {dict?.["Select the image column"]?.[language]}
                </p>
                <div
                  style={{
                    maxHeight: "calc( 50vh - 200px )",
                    overflowY: "auto",
                  }}
                >
                  <table>
                    <tbody>
                      {fields_image.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td style={{ width: "20px" }}>
                              <div
                                className={
                                  label_image_field_key_array.includes(
                                    item?.key
                                  )
                                    ? "checklist_active"
                                    : "checklist_not"
                                }
                                onClick={this.on_toggle_array_item.bind(
                                  this,
                                  "label_image_field_key_array",
                                  item?.key
                                )}
                              />
                            </td>
                            <td>{item?.name}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </section>
            )}
          </div>
        </section>

        <section className="container_light background_grey_light margin_bottom">
          <h1 className="text_bold margin_bottom">Teks dan Outline Teks</h1>
          <div className="container_light background_white margin_bottom">
            <p className="text_medium">Ukuran teks</p>
            <input
              name="font_size"
              value={font_size}
              onChange={this.on_change}
              type="number"
            />
            <br />
            <br />
            <p className="text_medium margin_bottom">Ketebalan teks</p>
            {font_weight_array.map((item, idx) => {
              return (
                <button
                  key={idx}
                  className={`button_small outline_black margin_right margin_bottom ${
                    font_weight === item.key
                      ? "background_black"
                      : "button_white"
                  }`}
                  onClick={this.on_set_font_weight.bind(this, item.key)}
                >
                  {item.name}
                </button>
              );
            })}
            {/* <input
              name="font_weight"
              value={font_weight}
              onChange={this.on_change}
              type="number"
            /> */}
          </div>
          <div className="container_light background_white margin_bottom">
            <p className="text_medium">Warna teks</p>
            <COLOR_INPUT
              on_change={this.on_change_color}
              name="text_color_primary"
              value={text_color_primary}
            />
          </div>
          <div className="container_light background_white">
            <p className="text_medium">Warna garis luar teks</p>
            <COLOR_INPUT
              on_change={this.on_change_color}
              name="text_stroke_color"
              value={text_stroke_color}
            />
            <br />
            <p className="text_medium">Ketebalan garis luar teks</p>
            <input
              name="text_stroke_width"
              value={text_stroke_width}
              onChange={this.on_change}
              type="number"
            />
          </div>
        </section>

        <section className="container_light background_grey_light margin_bottom">
          <h1 className="text_bold margin_bottom">Kotak Teks</h1>
          <div className="container_light background_white margin_bottom">
            <p className="text_medium">Warna latar belakang teks</p>
            <COLOR_INPUT
              on_change={this.on_change_color}
              name="background_color_primary"
              value={background_color_primary}
            />
          </div>
          <div className="container_light background_white">
            <p className="text_medium">Warna garis luar latar belakang</p>
            <COLOR_INPUT
              on_change={this.on_change_color}
              name="outline_color_primary"
              value={outline_color_primary}
            />
          </div>
        </section>

        <section className="container_light background_grey_light margin_bottom">
          <h1 className="text_bold margin_bottom">Opsi Pin</h1>
          <div className="container_light background_white">
            <p className="text_medium margin_bottom">Gunakan pin</p>
            <button
              className={`button_small outline_black margin_right ${
                is_use_pin === false ? "background_black" : "button_white"
              }`}
              onClick={this.on_set_is_use_pin.bind(this, false)}
            >
              Tanpa pin
            </button>
            <button
              className={`button_small outline_black margin_right ${
                is_use_pin === true ? "background_black" : "button_white"
              }`}
              onClick={this.on_set_is_use_pin.bind(this, true)}
            >
              Gunakan pin
            </button>
            <br />
            <br />
            <p className="text_medium">Panjang pin</p>
            <input
              name="pin_height"
              value={pin_height}
              onChange={this.on_change}
              type="number"
            />
          </div>
        </section>

        {loading_item_array.includes("edit_label_config") ? (
          <div className="button background_blue">
            {dict?.["Saving"]?.[language]}...
          </div>
        ) : (
          <button className="button background_blue" onClick={this.on_save}>
            {dict?.["Save"]?.[language]}
          </button>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, { edit_label_config })(EDIT_LABEL);
