import React, { Component } from "react";
import { connect } from "react-redux";
import { Dropdown } from "../../reuseable";
import {
  getChildrenFieldsByColumnParentUuid,
  getGeoLayer,
} from "../../../App/reduxHelper/layer";

class ColumnsSelectionChildTable extends Component {
  constructor(props) {
    super(props);
    const {
      id,
      layer,
      column_parent_uuid,
      c13_setup_object_penilaian_dan_data_pembanding,
      data_pembanding_list,
      // body,
      is_pembanding,
    } = props;

    if (is_pembanding) {
      const geo_layer = getGeoLayer(layer);

      let fields = [];
      if (
        c13_setup_object_penilaian_dan_data_pembanding?.[
          "data_pembanding"
        ]?.includes(id)
      ) {
        // problem
        fields = data_pembanding_list?.[0]?.fields?.filter(
          (item) => !item?.isStyle
        );
        // ?.find(
        //   (item) => item?._id === body?.["c13_selected_data_pembanding_pdf"]
        // )
      } else {
        fields = getChildrenFieldsByColumnParentUuid({
          geo_layer,
          column_parent_uuid,
        });
      }

      this.state = {
        fields,
      };
    }
  }

  render() {
    const {
      id,
      className,
      placeholder = "Pilih",
      body,
      is_pembanding,
    } = this.props;

    let fields = [];
    if (is_pembanding) {
      fields = this.state.fields;
    } else {
      fields = this.props.fields;
    }

    return (
      <Dropdown
        className={`w_100 ${className}`}
        placeholder={placeholder}
        filter_on
        is_enable_empty
        value={body?.[id] || ""}
        onChange={(value) => {
          if (this.props.onChange) {
            this.props.onChange(id, value);
          }
        }}
      >
        {fields?.length > 0 &&
          fields?.map((field) => (
            <option key={field?.key} value={field?.key}>
              {field?.name}
            </option>
          ))}
      </Dropdown>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
});

export default connect(mapStateToProps)(ColumnsSelectionChildTable);
