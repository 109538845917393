export const domain_mapid_basemap = [
  // "localhost",
  "localhost",
  "geo-alpha.mapid.io",
];

export const domain_bbws_maluku_river_schema = [
  "localhost",
  "bwsmalukuutara.mapid.io",
  "geo-alpha.mapid.io",
  "geo-beta.mapid.io",
];

export const domain_logo_mapid_triangle = [
  "localhost",
  "geo-beta.mapid.io",
  "geo.mapid.io",
];
