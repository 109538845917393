/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import { Draggable } from "../reuseable";
import LEGEND_TEXT from "./LEGEND_TEXT";
import LEGEND_NUMBER_STEP from "./LEGEND_NUMBER_STEP";

/*REDUX FUNCTION*/
import {
  filter_isi_layer,
  reset_filter,
  undo_filter_isi_layer,
} from "../../App/actions/dataActions";
import { set_value_properties } from "../../App/actions/propertiesActions";

/*PICTURE ASSET*/

/*GENERAL*/
import dict from "../../Data/dict.json";
import is_not_empty from "../../App/validation/is_not_empty";

class Legend extends Component {
  state = {};

  on_filter_isi_layer = (field_key, value, array_value_filter) => {
    const { layer_id } = this.props.layer;
    const body = {
      geo_layer_id: layer_id,
      field_key,
      value,
    };
    if (array_value_filter.includes(value)) {
      this.props.undo_filter_isi_layer(body);
    } else {
      this.props.filter_isi_layer(body);
    }
  };

  on_reset_filter = () => {
    const { layer_id } = this.props.layer;
    const body = { geo_layer_id: layer_id };
    this.props.reset_filter(body);
  };

  toggle_legend = () => {
    this.props.set_value_properties({
      key: "legend_status",
      value: !this.props.properties.legend_status,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const { legend_status } = this.props.properties;
    const { geo_layer_list, layer_id } = this.props.layer;

    //content
    const layer = geo_layer_list.find(
      (item) => item?.geo_layer?._id === layer_id
    )?.geo_layer;
    const field_key = layer?.valueStyleProps?.selected_column;
    const fields = layer?.fields || [];
    let field = fields.find((f) => f.key === field_key);
    let array_value_filter = field?.array_value_filter
      ? field?.array_value_filter
      : [];
    const style_array = layer?.style_array || [];
    const default_style_key = layer?.default_style_key || "";

    let legend_content;

    if (legend_status) {
      if (style_array.length > 0) {
        const style_object = style_array.find(
          (item) => item.key === default_style_key
        );
        const { color_logic } = style_object;
        let content;
        switch (color_logic) {
          case "constant":
            content = <>Warna konstant</>;
            break;
          case "use_existing":
            content = <>Dengan kolom warna</>;
            break;
          case "by_text":
            content = <LEGEND_TEXT />;
            break;
          case "by_number_step":
            content = <LEGEND_NUMBER_STEP />;
            break;
          case "by_number_gradient":
            content = <>Angka gradient</>;
            break;
          default:
            break;
        }
        legend_content = (
          <Draggable
            title={dict["Legend"][language]}
            toggle_close={this.toggle_legend}
            style={{
              background: "white",
              boxShadow: "3px 3px 5px #afababab, -1px -1px 5px #afababab",
            }}
            initialPosition={{
              x: window.innerWidth - 250,
              y: window.innerHeight - 250,
            }}
          >
            <main style={{ minWidth: "200px" }}>{content}</main>
          </Draggable>
        );
      } else if (
        layer?.isGlobalStyle &&
        layer?.globalStyleMode === "by_value" &&
        layer?.valueStyleProps?.classify_mode === "number"
      ) {
        const range_value = layer?.valueStyleProps?.range || [];
        let color_array = layer?.valueStyleProps?.color || [];
        color_array = color_array.filter((item) => item !== "#000");
        legend_content = (
          <Draggable
            title={dict["Legend"][language]}
            toggle_close={this.toggle_legend}
            style={{
              background: "white",
              boxShadow: "3px 3px 5px #afababab, -1px -1px 5px #afababab",
            }}
            initialPosition={{
              x: window.innerWidth - 300,
              y: window.innerHeight < 500 ? 105 : 263,
            }}
          >
            <main>
              <div className="draggable-content">
                <p className="text_bold margin_bottom">Legenda angka:</p>
                <table>
                  <tbody>
                    {color_array.map((item, idx) => (
                      <tr key={idx}>
                        <td>
                          <div
                            style={{
                              width: "15px",
                              height: "15px",
                              backgroundColor: item,
                            }}
                          />
                        </td>
                        <td className="text_inferior">
                          {range_value?.[idx]?.min} - {range_value?.[idx]?.max}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </main>
          </Draggable>
        );
      } else if (
        layer?.isGlobalStyle &&
        layer?.globalStyleMode === "by_value" &&
        layer?.valueStyleProps?.classify_mode === "text"
      ) {
        const selected_column = layer?.valueStyleProps?.selected_column;
        const range_value = layer?.valueStyleProps?.range || [];
        let color_array = layer?.valueStyleProps?.color || [];
        color_array = color_array.filter((item) => item !== "#000");
        legend_content = (
          <Draggable
            title={dict["Legend"][language]}
            toggle_close={this.toggle_legend}
            style={{
              background: "white",
              boxShadow: "3px 3px 5px #afababab, -1px -1px 5px #afababab",
              width: "350px",
              maxWidth: window.innerWidth > 400 ? "400px" : "300px",
            }}
            initialPosition={{
              x: window.innerWidth > 400 ? window.innerWidth - 400 : "30px",
              y: window.innerHeight > 400 ? 184 : 35,
            }}
          >
            <main>
              <div
                className="draggable-content scroll_y_auto"
                style={{
                  maxHeight:
                    window.innerHeight > 400
                      ? "calc(100svh - 350px)"
                      : "calc(100svh - 150px)",
                }}
              >
                <button
                  className="button_small background_black margin_right"
                  onClick={this.on_reset_filter}
                >
                  Reset
                </button>
                <p className="text_small">Reset: tampilkan semua kembali</p>
                <p className="text_bold margin_bottom">Legenda teks:</p>
                <table>
                  <tbody>
                    {color_array.map((item, idx) => {
                      const value = is_not_empty(range_value?.[idx])
                        ? String(range_value?.[idx])
                        : "0";
                      return (
                        <tr
                          key={idx}
                          onClick={this.on_filter_isi_layer.bind(
                            this,
                            selected_column,
                            value,
                            array_value_filter
                          )}
                        >
                          <td>
                            <button
                              className={
                                array_value_filter.includes(value)
                                  ? "checklist_active"
                                  : "checklist_not"
                              }
                            />
                          </td>
                          <td>
                            <div
                              className="border"
                              style={{
                                width: "15px",
                                height: "15px",
                                backgroundColor: item,
                              }}
                            />
                          </td>
                          <td className="text_inferior">{value}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </main>
          </Draggable>
        );
      }
    }

    return <main className="noselect">{legend_content}</main>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  filter_isi_layer,
  reset_filter,
  undo_filter_isi_layer,
  set_value_properties,
})(Legend);
