//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux function
import {
  edit_no_wa,
  send_otp_wa,
  whatsapp_verification,
  email_verification,
  code_verification,
  logoutUser,
} from "../../App/actions/authActions";
import { clearProjectList } from "../../App/actions/projectActions";
import { clearFormList } from "../../App/actions/layerNewActions";

//General Function
import dict from "../../Data/dict.json";
import phoneNumberList from "../../Data/PhoneNumber.json";
import { get_country_code_and_phone_number } from "../../App/validation/phone_number";
import ModalNoClose from "../reuseable/ModalNoClose";
import { Edit } from "@material-ui/icons";

const Messages = ({ color, title }) => {
  return (
    <p
      style={{
        marginTop: "-30px",
        fontSize: "0.7rem",
        color: `${color}`,
      }}
    >
      {title}
    </p>
  );
};

class WhatsappConfirm extends Component {
  constructor() {
    super();
    this.state = {
      time: {},
      seconds: 59,
      countdown: false,
      changeWhatsapp: false,
      validasiWhatsapp: false,
      validasiUser: true,
      verification: "",
      code: ["", "", "", "", "", ""],
      fullname: "",
      username: "",
      dateofbrithday: "",
      phonenumber: "",
      industry: "",
      messageCode: "",
      errors: {},
      intervalTime: {},
      error_otp: "",
      modal_make_sure: true,

      countryCodeState: "",
    };
    this.timer = 0;
    this.startTimer = this.handleSubmitWhatsapp.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  toggle_modal_make_sure = () => {
    this.setState({
      modal_make_sure: !this.state.modal_make_sure,
    });
  };

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.state.intervalTime);
      this.setState({
        countdown: false,
      });
    } else {
      this.setState({
        countdown: true,
      });
    }
  }

  componentDidMount() {
    const user = this.props.auth.user;
    const verification = user.is_whatsapp_confirm;
    const username = user.name;
    const fullname = user.full_name;
    const dateofbrithday = user.birthday;
    const phonenumber = user.phone_number;
    const industry = user.industry;
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({
      verification,
      username,
      time: timeLeftVar,
      fullname,
      dateofbrithday,
      phonenumber,
      industry,
    });
  }

  handleSubmitWhatsapp = () => {
    this.props.send_otp_wa();
    this.setState({
      intervalTime: setInterval(this.countDown, 1000),
      countdown: true,
      seconds: 59,
      changeEmail: true,
      validasiEmail: false,
    });
  };

  handleSubmitCode = async (e) => {
    const language = localStorage?.language ? localStorage?.language : "ina";
    e.preventDefault();
    if (this.state.code === "") {
      this.setState({
        errors: {},
        messageCode: (
          <Messages
            color="#ff0000"
            title={dict["please input your code"][language]}
          />
        ),
      });
    } else {
      if (this.props.auth.user.is_whatsapp_confirm === "not_propose") {
        this.setState({
          errors: {},
          messageCode: (
            <Messages
              color="#ff0000"
              title={dict["Code not match"][language]}
            />
          ),
        });
      }
      const code = this.state.code?.join("");
      const body = { otp: code };
      const res = await this.props.whatsapp_verification(body);

      if (res?.status === 200) {
        clearInterval(this.state.intervalTime);
        this.props.onClose();
      } else {
        this.setState({
          error_otp:
            res?.data?.status || dict["The code cannot be used"][language],
        });
      }
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  countryCodeOnChange = (e, phoneNumber) => {
    const newCountryCode = e.target.value;

    this.setState({
      phonenumber: newCountryCode + phoneNumber,
    });
  };

  phoneNumberOnChange = (e, countryCode) => {
    const newPhoneNumber =
      String(e.target.value)?.[0] === "0" ? "" : e.target.value;

    this.setState({
      phonenumber: countryCode + newPhoneNumber,
    });
  };

  onLogoutClick() {
    this.props.logoutUser();
    this.props.clearProjectList();
    this.props.clearFormList();
  }

  onCheckUsername() {
    this.handleSubmitWhatsapp();
    this.setState({
      validasiWhatsapp: false,
      changeWhatsapp: false,
      validasiUser: false,
      modal_make_sure: false,
    });
  }

  handleSubmitNewWhatsappNumber = async () => {
    const { phonenumber } = this.state;
    await this.props.edit_no_wa({
      phone_number: phonenumber,
    });

    this.setState({
      validasiWhatsapp: true,
      changeWhatsapp: false,
      validasiUser: false,
    });
  };

  handleChange = (value, index) => {
    if (isNaN(value)) return; // hanya menerima angka
    const { code } = this.state;
    const newOtp = [...code];
    newOtp[index] = value;
    this.setState({
      code: newOtp,
    });

    // otomatis pindah ke input berikutnya jika terisi
    if (value && index < 5) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  handleKeyDown = (event, index) => {
    const { code } = this.state;
    if (event.key === "Backspace" && code[index] === "" && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { phonenumber, code, error_otp, modal_make_sure } = this.state;

    const { countryCode, phoneNumber } =
      get_country_code_and_phone_number(phonenumber);

    const validation_whatsapp_content = (
      <>
        <header>
          <label className="bold font_30">
            {dict["Confirmation"][language]}
          </label>
          <div className="flex">
            <hr
              style={{
                height: "2px",
                width: "50%",
                backgroundColor: "black",
                border: "1px solid black",
              }}
            />
            <hr
              style={{
                height: "2px",
                width: "50%",
                backgroundColor: "black",
                border: "1px solid black",
              }}
            />
          </div>
        </header>

        <section className="flex flex_col gap_10 margin_10">
          <p>{dict["Is it true that your whatsapp is"][language]}</p>
          <div className="flex align_center">
            <h1 className="bold">+{phonenumber} ?</h1>
            <button
              className="hover_bigger w_15 h_15 marginLeft_5 rounded_full center_perfect"
              onClick={() =>
                this.setState({
                  validasiWhatsapp: false,
                  changeWhatsapp: true,
                  validasiUser: false,
                })
              }
            >
              <Edit style={{ width: "15px" }} />
            </button>
          </div>

          <div className="center_perfect gap_10 marginTop_20">
            <button
              className="button_inactive border w_100 h_40 rounded_5"
              onClick={() => {
                this.setState({
                  validasiWhatsapp: false,
                  changeWhatsapp: false,
                  validasiUser: true,
                });
              }}
            >
              {dict["No"][language]}
            </button>
            <button
              className="button_inactive border w_100 h_40 rounded_5 bg_black text_white"
              onClick={() => this.onCheckUsername()}
            >
              {dict["Yes"][language]}
            </button>
          </div>
        </section>
      </>
    );

    const change_whatsapp_number_content = (
      <>
        <header>
          <label className="bold font_30">
            {dict["Change Whatsapp Number"][language]}
          </label>
          <div className="flex">
            <hr
              style={{
                height: "2px",
                width: "50%",
                backgroundColor: "black",
                border: "1px solid black",
              }}
            />
            <hr
              style={{
                height: "2px",
                width: "50%",
                backgroundColor: "black",
                border: "1px solid black",
              }}
            />
          </div>
        </header>

        <section className="flex flex_col gap_10 margin_10">
          <p>{dict["Enter your whatsapp correctly"][language]}</p>
          <div className="flex align_center gap_10">
            <select
              value={countryCode}
              onChange={(e) => this.countryCodeOnChange(e, phoneNumber)}
              className="rounded_5 padding_x_5 w_80"
              name="country_code"
            >
              {phoneNumberList.map((countryCode, index) => (
                <option
                  key={index}
                  value={countryCode}
                  className="bg_white text_black"
                >
                  {countryCode}
                </option>
              ))}
            </select>
            <input
              placeholder={"Whatsapp"}
              type="number"
              name="rest_number"
              id="whatsapp"
              value={phoneNumber}
              onChange={(e) => this.phoneNumberOnChange(e, countryCode)}
              className="input_new"
              style={{ borderBottom: "2px solid black" }}
            />
          </div>
          <button
            id="resend"
            className="border h_40 rounded_5 bg_black text_white marginTop_20"
            onClick={this.handleSubmitNewWhatsappNumber}
          >
            {dict["Save"][language]}
          </button>
        </section>
      </>
    );

    const validation_username_content = (
      <>
        <header>
          <label className="bold font_30">
            {dict["Confirmation"][language]}
          </label>
          <div className="flex">
            <hr
              style={{
                height: "2px",
                width: "50%",
                backgroundColor: "black",
                border: "1px solid black",
              }}
            />
            <hr
              style={{
                height: "2px",
                width: "50%",
                backgroundColor: "lightgrey",
                border: "1px solid lightgrey",
              }}
            />
          </div>
        </header>

        <section className="flex flex_col gap_10 margin_10">
          <label>{dict["Is it true that your username is"][language]}</label>
          <h1 className="bold">{this.state.username} ?</h1>
          <div className="center_perfect gap_10 marginTop_20">
            <button
              className="button_inactive border w_100 h_40 rounded_5"
              onClick={() => this.onLogoutClick()}
            >
              {dict["No"][language]}
            </button>
            <button
              className="button_inactive border w_100 h_40 rounded_5 bg_black text_white"
              onClick={() =>
                this.setState({
                  validasiWhatsapp: true,
                  changeWhatsapp: false,
                  validasiUser: false,
                })
              }
            >
              {dict["Yes"][language]}
            </button>
          </div>
        </section>
      </>
    );

    var itemContent;
    itemContent = (
      <>
        <h2 className="marginBottom_5">OTP Verification</h2>
        <p>{dict["We have sent the security code to"][language]}</p>

        <h1>{phonenumber}</h1>

        <div className="otp-inputs">
          {code.map((value, index) => (
            <input
              key={index}
              id={`otp-input-${index}`}
              type="text"
              maxLength="1"
              value={value}
              onChange={(e) => this.handleChange(e.target.value, index)}
              onKeyDown={(e) => this.handleKeyDown(e, index)}
              className="otp-input"
            />
          ))}
        </div>

        {error_otp && <p style={{ color: "#c00000" }}>{error_otp}</p>}
        <button
          className="button_inactive rounded_5 bg_black text_white w_full h_50 margin_y_20"
          onClick={this.handleSubmitCode}
        >
          {dict["Verify"][language]}
        </button>
        <div>
          {this.state.countdown ? (
            <p className="text_grey">
              {dict["Didn't receive the code?"][language]} 0{this.state.time.m}:
              {this.state.time.s}
            </p>
          ) : (
            <p className="text_grey">
              {dict["Didn't receive the code?"][language]}{" "}
              <span
                className="bold"
                style={{ color: "black" }}
                onClick={this.handleSubmitWhatsapp}
              >
                {dict["Resend"][language]}
              </span>{" "}
              or{" "}
              <span
                style={{ color: "black" }}
                className="bold"
                onClick={() =>
                  this.setState({
                    validasiWhatsapp: false,
                    changeWhatsapp: true,
                    validasiUser: false,
                    modal_make_sure: true,
                  })
                }
              >
                {dict["Change Whatsapp"][language]}
              </span>
            </p>
          )}
        </div>
        <p>
          {dict["Any mistake? Back to"][language]}
          <span
            onClick={() => this.onLogoutClick()}
            style={{ marginLeft: "5px", color: "#ba0000" }}
          >
            {dict["Login"][language]}
          </span>
        </p>
      </>
    );

    let content_popup = null;
    if (this.state.changeWhatsapp) {
      content_popup = change_whatsapp_number_content;
    } else if (this.state.validasiWhatsapp) {
      content_popup = validation_whatsapp_content;
    } else if (this.state.validasiUser) {
      content_popup = validation_username_content;
    }

    const modal_content = modal_make_sure && (
      <ModalNoClose
        modalSize="ml"
        isOpen={modal_make_sure}
        onClose={this.toggle_modal_make_sure}
      >
        {content_popup}
      </ModalNoClose>
    );

    return (
      <>
        <div className="min_h_300 min_w_300">{itemContent}</div>
        {modal_content}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  edit_no_wa,
  send_otp_wa,
  whatsapp_verification,
  email_verification,
  code_verification,
  logoutUser,
  clearProjectList,
  clearFormList,
})(WhatsappConfirm);
