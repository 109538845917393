//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component
import CheckBoxNew from "../common_input/CheckBoxNew";

//Redux function
import { editLayerForm } from "../../App/actions/layerActions";
import { openModal } from "../../App/actions/authActions";

//Picture Asset

//General Function
import dict from "../../Data/dict.json";
import domain_list from "../../Data/domain_list";

class EditGeneral extends Component {
  state = {
    geo_layer: {},
  };

  componentDidMount() {
    this.setState({ geo_layer: this.props.geo_layer });
  }

  handle_change_layer = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let geo_layer = this.state.geo_layer;
    geo_layer[name] = value;
    this.setState({
      geo_layer,
    });
  };

  on_open_modal = (name) => {
    this.props.openModal(name);
  };

  handle_toggle_general = (name) => {
    let geo_layer = this.state.geo_layer;
    geo_layer[name] = !geo_layer[name];
    this.setState({
      geo_layer,
    });
  };

  handle_edit_layer = () => {
    let { geo_layer } = this.state;
    const {
      _id,

      name,
      description,
      is_form_login_req,
      isPublic,
      show_only_highlight_field,
      isGlobalStyle,
      isFormPublic,
      isLocationChangeable,
      isExpand,
      properties,
      /*Tambahan by value */
      picture_url,
      valueStyleProps,
      globalStyleMode,
      valueStyleList,
      isCustomFormMessage,
      customFormMessageHeader,
      customFormMessageDesc,
      is_survey,
    } = geo_layer;
    const body = {
      geo_layer_id: _id,
      name,
      description,
      isLocationChangeable,
      isExpand,
      properties,
      picture_url,
      valueStyleProps,
      globalStyleMode,
      valueStyleList,
      is_form_login_req,
      isPublic,
      show_only_highlight_field,
      isGlobalStyle,
      isFormPublic,
      isCustomFormMessage,
      customFormMessageHeader,
      customFormMessageDesc,
      is_survey,
    };
    this.props.editLayerForm(body);
    this.props.toggle_edit_general();
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    let { geo_layer } = this.state;

    //global props
    const { domain } = this.props.auth;

    //content
    const domain_temp = domain_list?.[domain]?.form_link;
    let content = null;
    if (geo_layer?._id) {
      content = (
        <div>
          <section
            className="container_flat"
            style={{
              margin: "10px",
              display: "inline-block",
              width: "500px",
              maxWidth: "100%",
              verticalAlign: "top",
              textAlign: "left",
            }}
          >
            <div className="text_bold">{dict["General"][language]}</div>
            <input
              type="text"
              name="name"
              id="name"
              value={geo_layer.name}
              onChange={this.handle_change_layer}
              data-mapid="changeTitle"
            />
            <textarea
              type="text"
              name="description"
              id="description"
              value={geo_layer.description}
              onChange={this.handle_change_layer}
              rows="20"
              data-mapid="changeArea"
            />
          </section>
          <section
            className="container_flat"
            style={{
              margin: "10px",
              display: "inline-block",
              width: "500px",
              maxWidth: "100%",
              verticalAlign: "top",
              textAlign: "left",
            }}
          >
            <div className="text_bold">{dict["Form config"][language]}</div>
            <div className="text_inferior" style={{ marginBottom: "10px" }}>
              {
                dict[
                  "GEO MAPID is integrated with FORM MAPID, edit form configuration here. You can test the link by click on it, if you set this layer as public form you can just test it without logging in, if not you need to login."
                ][language]
              }
            </div>
            <CheckBoxNew
              text="isLocationChangeable"
              title={dict["Changeable Location (in FORM MAPID)"][language]}
              value={geo_layer.isLocationChangeable}
              handle={this.handle_toggle_general.bind(
                this,
                "isLocationChangeable"
              )}
            />
            <CheckBoxNew
              text="is_form_login_req"
              title="Required to login"
              value={geo_layer.is_form_login_req}
              handle={this.handle_toggle_general.bind(
                this,
                "is_form_login_req"
              )}
            />
            <CheckBoxNew
              text="isFormPublic"
              title={dict["Public Form"][language]}
              value={geo_layer.isFormPublic}
              handle={this.handle_toggle_general.bind(this, "isFormPublic")}
            />
            <div style={{ marginTop: "5px" }}>
              {dict["Link"][language]}:{" "}
              <a
                href={`${domain_temp}${geo_layer.link}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#2196f3" }}
              >
                {`${domain_temp}${geo_layer.link}`}
              </a>
            </div>
            <div className="text_bold" style={{ marginTop: "30px" }}>
              {dict["Closing message"][language]}
            </div>
            <CheckBoxNew
              text="isCustomFormMessage"
              title={dict["Activate closing message"][language]}
              value={geo_layer.isCustomFormMessage}
              handle={this.handle_toggle_general.bind(
                this,
                "isCustomFormMessage"
              )}
            />
            <input
              type="text"
              placeholder={dict["Closing message title"][language]}
              name="customFormMessageHeader"
              id="customFormMessageHeader"
              value={geo_layer.customFormMessageHeader}
              onChange={this.handle_change_layer}
              data-mapid="changeCloseTitle"
            />
            <textarea
              type="text"
              rows="5"
              placeholder={dict["Closing message subtitles"][language]}
              name="customFormMessageDesc"
              id="customFormMessageDesc"
              value={geo_layer.customFormMessageDesc}
              onChange={this.handle_change_layer}
              data-mapid="changeCloseTitleDeskription"
            />
            <button
              onClick={this.handle_edit_layer}
              className="button background_blue"
              data-mapid="clickSimpan"
            >
              {dict["Save"][language]}
            </button>
          </section>
        </div>
      );
    }

    return content;
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {
  openModal,
  editLayerForm,
})(EditGeneral);
