const getStatusMember = function (groups, current_user_id) {
  const membersInGroupsProto = groups.map(({ members }) => {
    const user_id = members.map((member) => {
      return member;
    });
    return user_id;
  });
  const membersInGroups = membersInGroupsProto.flat();
  const statusUser = membersInGroups.filter(
    (member) => member?.user?._id === current_user_id
  )[0]?.status;
  return statusUser;
};

export default getStatusMember;
