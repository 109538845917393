import React, { Component } from "react";
import { connect } from "react-redux";
class ICON_ARROW_RIGHT_LINE extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={11.138}
        height={9}
        viewBox="0 0 2.947 2.381"
      >
        <path
          d="M243.02 100.42h2.418M244.49 99.494l.944.926-.944.926"
          style={{
            fill: "none",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 0.52917,
            stroke: this.props.line_color || "#fff",
          }}
          transform="translate(-242.75 -99.229)"
        />
      </svg>
    );
  }
}
const mapStateToProps = () => ({});
export default connect(mapStateToProps, {})(ICON_ARROW_RIGHT_LINE);
