import React, { Component } from "react";

export default class MathOperators extends Component {
  render() {
    const value = this.props.params.value;
    return (
      <div>
        {!isNaN(value)
          ? new Intl.NumberFormat("id-ID", {
              style: "decimal",
            }).format(Number(value))
          : ""}
      </div>
    );
  }
}
