//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Tooltip,
  Typography,
  IconButton,
  TextField,
  Dialog,
  List,
  ListItem,
  Button,
} from "@material-ui/core";
import ListAltIcon from "@material-ui/icons/ListAlt";
import TimelineIcon from "@material-ui/icons/Timeline";
import Autocomplete from "@material-ui/lab/Autocomplete";
import RefreshIcon from "@material-ui/icons/Refresh";
import TodayIcon from "@material-ui/icons/Today";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Alert from "@material-ui/lab/Alert";
import { CSVLink } from "react-csv";

//Personal Component
import ChartNew from "./ChartTemplate";

//Redux function
import {
  getAllDatasetv3,
  getCilicisByPos,
  getDetailItem,
  brantasGetDataByPos,
} from "../../App/actions/iotActions";

import dict from "../../Data/dict.json";

//Picture Asset
import icon_full_screen_active from "../../Assets/svg/icon_full_screen_active.svg";

//General Function
//ini iot cilicis
class IotTableBar extends Component {
  state = {
    tabs: "table",

    sensor: [],
    iotData: [],
    iotDataTimeUnix: [],

    filterId: ``,
    startDate: `${this.formatDate(new Date())}`,
    finishDate: `${this.formatDate(new Date())}`,

    cilicis: false,
    brantas: false,

    value: [],
    inputValue: "",
    dataset: [],

    siaga1: [],
    siaga2: [],
    siaga3: [],
    siaga4: [],

    brantasData: [],

    fullScreen: true,
    modalDownload: false,
    headerDownload: [],
    dataDownload: [],
    proses: true,

    checkedInterval: 1,
    alert: false,

    csvData: [],

    cilicisPCH_2023: [],
  };

  async getIoTData() {
    this.setState({ sensor: [], iotData: [], iotDataTimeUnix: [] });
    const d = await getAllDatasetv3({
      id: this.state.filterId,
      startDate: this.state.startDate,
      finishDate: this.state.finishDate,
    });

    const sensor = d.log_data_v3;
    if (sensor.length > 0) {
      this.setState({ sensor });
    } else if (sensor.length === 0) {
      this.setState({
        sensor: [{ name_sensor: "Belum ada Data", unit: "-" }],
      });
    }

    const ioTdata = d.IoTData;
    if (ioTdata.length > 0) {
      let arr1 = [];
      ioTdata.forEach((element) => {
        this.setState((prevState) => ({
          iotDataTimeUnix: [...prevState.iotDataTimeUnix, element.created],
        }));
        const values = element.values;
        let arr2 = [];
        d.log_data_v3.forEach((e) => {
          let match = values.find((r) => r._id === e._id);
          arr2.push(
            match ? (match.context === "" ? match.data : match.context) : "-"
          );
        });
        arr1.push(arr2);
      });
      const transpose = arr1[0].map((_, colIndex) =>
        arr1.map((row) => row[colIndex])
      );
      this.setState({ iotData: transpose });
      this.setState({
        siaga1: Array.from(Array(ioTdata.length), (_, x) => 350),
        siaga2: Array.from(Array(ioTdata.length), (_, x) => 270),
        siaga3: Array.from(Array(ioTdata.length), (_, x) => 200),
      });
    } else if (ioTdata.length === 0) {
      // this.setState({
      //   iotData: [
      //     [
      //       0, 1, 1, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      //       0,
      //     ],
      //     [
      //       "Cerah",
      //       "Ringan",
      //       "Ringan",
      //       "Cerah",
      //       "Cerah",
      //       "Ringan",
      //       "Cerah",
      //       "Cerah",
      //       "Cerah",
      //       "Cerah",
      //       "Cerah",
      //       "Cerah",
      //       "Cerah",
      //       "Cerah",
      //       "Cerah",
      //       "Cerah",
      //       "Cerah",
      //       "Cerah",
      //       "Cerah",
      //       "Cerah",
      //       "Cerah",
      //       "Cerah",
      //       "Cerah",
      //       "Cerah",
      //     ],
      //   ],
      //   iotDataTimeUnix: [
      //     1609516800000, 1609513200000, 1609509600000, 1609506000000,
      //     1609502400000, 1609498800000, 1609495200000, 1609491600000,
      //     1609488000000, 1609484400000, 1609480800000, 1609477200000,
      //     1609473600000, 1609470000000, 1609466400000, 1609462800000,
      //     1609459200000, 1609455600000, 1609452000000, 1609448400000,
      //     1609444800000, 1609441200000, 1609437600000, 1609434000000,
      //   ],
      // });
    }
  }

  async getCilicis() {
    this.setState({ sensor: [], iotData: [], iotDataTimeUnix: [] });
    const item = await getDetailItem({ id: this.state.filterId });
    if (item) {
      const d = await getCilicisByPos({
        nama_lokasi: item.context.content.nama_lokasi,
        startDate: this.state.startDate,
        finishDate: this.state.finishDate,
      });
      const arrData = [],
        arrTime = [],
        arrStatus = [],
        siaga1 = [],
        siaga2 = [],
        siaga3 = [];

      d.data_telemetryjakarta &&
        d.data_telemetryjakarta.forEach((e) => {
          arrData.push(parseFloat(e.Rain));
          if (parseFloat(e.Rain) === 0) {
            arrStatus.push("Cerah");
          } else if (parseFloat(e.Rain) > 0.1 && parseFloat(e.Rain) < 5) {
            arrStatus.push("Ringan");
          } else if (parseFloat(e.Rain) >= 5 && parseFloat(e.Rain) < 10) {
            arrStatus.push("Sedang");
          } else if (parseFloat(e.Rain) >= 10 && parseFloat(e.Rain) < 20) {
            arrStatus.push("Lebat");
          } else if (parseFloat(e.Rain) > 20) {
            arrStatus.push("Sangat Lebat");
          }
          arrTime.push(
            new Date(`${e.ReceivedDate} ${e.ReceivedTime}`).getTime()
          );
          siaga1.push(parseInt(item.context.content.siaga1));
          siaga2.push(parseInt(item.context.content.siaga2));
          siaga3.push(parseInt(item.context.content.siaga3));
        });

      this.setState({
        sensor: [
          { name_sensor: "Curah Hujan", unit: "mm" },
          { name_sensor: "Status", unit: "-" },
        ],
        iotData: [arrData, arrStatus],
        iotDataTimeUnix: arrTime,
        siaga1,
        siaga2,
        siaga3,
      });
    }
  }

  async getCilicis2023() {
    const { value, dataset: datasets } = this.state;
    const dataset = datasets.find((e) => e.dataset === value.dataset);
    const { nama_lokasi, id_tipe, siaga1, siaga2, siaga3, siaga4 } =
      dataset.properties;

    const d = await getCilicisByPos({
      nama_lokasi: nama_lokasi,
      startDate: this.state.startDate,
      finishDate: this.state.finishDate,
    });

    if (id_tipe === "PCH") {
      let pch_result = [];
      if (d.data_telemetryjakarta) {
        d.data_telemetryjakarta.forEach((element) => {
          if (parseFloat(element.Rain) > parseFloat(siaga3)) {
            pch_result.push({ ...element, status: "Ringan" });
          } else if (parseFloat(element.Rain) > parseFloat(siaga2)) {
            pch_result.push({ ...element, status: "Sedang" });
          } else if (parseFloat(element.Rain) > parseFloat(siaga1)) {
            pch_result.push({ ...element, status: "Lebat" });
          } else {
            pch_result.push({ ...element, status: "Cerah" });
          }
        });

        this.setState({
          cilicisPCH_2023: pch_result,
          siaga1: parseFloat(siaga1),
          siaga2: parseFloat(siaga2),
          siaga3: parseFloat(siaga3),
          siaga4: parseFloat(siaga4),
        });

        this.setState({
          csvData: pch_result.map((e) => ({
            Date: e.ReceivedDate,
            Time: e.ReceivedTime,
            "Rain (mm3)": e.Rain,
            Status: e.status,
          })),
        });
      } else {
        alert(`${d.message} - coba pilih sensor lain.`);
      }
    }
  }

  async getBrantas() {
    if (this.props.iot.brantasLastDataPCH.length > 0) {
      const listPos = this.props.iot.brantasLastDataPCH;
      let arrPos = [];
      listPos.forEach((element) => {
        arrPos.push({
          dataset: element.pos.name.toUpperCase(),
          key: element.pos.id,
        });
      });
      this.setState({ dataset: arrPos });
    }
  }

  componentDidMount() {
    const { layer_id, geo_layer_list } = this.props.layer;
    const geo_layer = geo_layer_list.find((l) => l.geo_layer._id === layer_id);

    const type_2 = geo_layer?.geo_layer?.type_2;

    if (type_2 === "pda" || type_2 === "pch") {
      this.setState({ cilicis: true });
      let dataset = [];

      const features = geo_layer?.geo_layer?.geojson_filtered?.features
        ? geo_layer?.geo_layer?.geojson_filtered?.features
        : [];
      features.forEach((d) => {
        dataset.push({
          key: d?.key,
          dataset: d?.properties?.nama_alat?.toUpperCase(),
          properties: d?.properties,
        });
      });
      this.setState({ dataset, value: dataset[0] });
    } else {
      this.setState({ brantas: true });
      this.getBrantas();
    }
  }

  // componentDidUpdate(prevProps) {
  //   if (this.state.cilicis) {
  //     if (
  //       prevProps.geo_layer?.geo_layer._id !==
  //       this.props.geo_layer?.geo_layer._id
  //     ) {
  //       this.getIoTData();
  //       this.getCilicis();
  //     }
  //   }

  //   if (this.state.brantas) {
  //     if (
  //       prevProps.iot.brantasLastDataPCH !== this.props.iot.brantasLastDataPCH
  //     ) {
  //       this.getBrantas();
  //     }
  //   }
  // }

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  handleChangeOptions = (event) => {
    const index = event.target.selectedIndex;
    const idDataset = event.target.childNodes[index].id;

    this.setState({ filterId: idDataset });
  };

  handleStarDate = (e) => {
    this.setState({ startDate: e.target.value });
  };
  handleFinishDate = (e) => {
    this.setState({ finishDate: e.target.value });
  };

  handleSubmit = () => {
    this.getCilicis2023();
    // this.getIoTData();
    // this.getCilicis();
    // const idx = this.state.dataset.findIndex(
    //   (x) => x.dataset === `${this.state.inputValue}`
    // );
    // this.props.propsFly(idx);
  };

  handleSubmitBrantas = () => {
    if (this.props.iot.brantasLastDataPCH.length > 0) {
      const listPos = this.props.iot.brantasLastDataPCH;
      const index = this.state.filterId;

      const data = listPos.find((x) => x.pos.id === parseInt(index));
      const pos = data.pos;
      let listDataBrantas = [];
      let idx = 0;
      pos.sensors.forEach(async (e) => {
        let sensor = "";
        switch (e.name) {
          case "WL":
            sensor = "awlr";
            break;
          case "FLOW":
            sensor = "flow";
            break;
          case "BATT":
            sensor = "batt";
            break;
          case "RAIN":
            sensor = "rain";
            break;
          case "HOUR":
            sensor = "hour";
            break;
          case "SOLAR":
            sensor = "solar";
            break;
          case "WIND":
            sensor = "wind";
            break;
          case "TEMP":
            sensor = "temp";
            break;
          case "RH":
            sensor = "rh";
            break;
          default:
            break;
        }
        // const detailData = await brantasGetDataByPos({
        //   pos: pos.slug,
        //   sensor: sensor,
        //   start: `${this.state.startDate}T00:00`,
        //   end: `${this.state.finishDate}T23:59`,
        // });
        // if (!detailData.msg) {
        //   listDataBrantas.push(detailData);
        // }
        if (sensor !== "" && sensor !== "cam") {
          const detailData = await brantasGetDataByPos({
            pos: pos.slug,
            sensor: sensor,
            start: `${this.state.startDate}T00:00`,
            end: `${this.state.finishDate}T23:59`,
          });
          if (!detailData.msg) {
            listDataBrantas.push(detailData);
          }
        }

        if (idx === pos.sensors.length - 1) {
          this.setState({ brantasData: listDataBrantas });
        }
        idx++;
      });
    }
  };

  changeTime(params) {
    const a = new Date(params);
    const jam = a.getHours() < 10 ? `0${a.getHours()}` : a.getHours();
    const menit = a.getMinutes() < 10 ? `0${a.getMinutes()}` : a.getMinutes();
    const detik = a.getSeconds() < 10 ? `0${a.getSeconds()}` : a.getSeconds();
    return `${jam}:${menit}:${detik}`;
  }

  changeDate(params) {
    const a = new Date(params);
    const bulan = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mei",
      "Jun",
      "Jul",
      "Agu",
      "Sep",
      "Okt",
      "Nov",
      "Des",
    ];
    const tanggal = a.getDate() < 0 ? `0${a.getDate()}` : a.getDate();
    return `${tanggal} ${bulan[a.getMonth()]} ${a.getFullYear()}`;
  }

  activateFullTable = () => {
    if (this.state.fullScreen) {
      const tableFull = document.getElementById("tableBar");
      tableFull.style.top = "0px";
      tableFull.style.height = "100%";
      tableFull.style.zIndex = "999";
      this.setState({ fullScreen: !this.state.fullScreen });
    } else {
      const tableFull = document.getElementById("tableBar");
      tableFull.style.top = "auto";
      tableFull.style.height = "400px";
      tableFull.style.zIndex = "1";
      this.setState({ fullScreen: !this.state.fullScreen });
    }
  };

  chooseInterval = (e) => {
    this.setState({ checkedInterval: e });
  };

  downloadData = () => {
    this.setState({ alert: false });
    if (this.state.inputValue === "" || this.state.brantasData.length === 0) {
      this.setState({ alert: true });
    } else {
      const masterData = this.state.brantasData;
      let headers = [{ label: "No", key: "INDEX" }],
        datas = [];
      masterData.forEach((element) => {
        headers.push(
          {
            label: `Tanggal`,
            key: `tanggal-${element.sensor.toUpperCase()}`,
          },
          {
            label: `${element.sensor.toUpperCase()}`,
            key: `${element.sensor.toUpperCase()}`,
          }
        );

        element.data.forEach((e, i) => {
          if (datas[i]) {
            datas[i][`tanggal-${element.sensor.toUpperCase()}`] = e.t;
            datas[i][`${element.sensor.toUpperCase()}`] = e.v;
          } else {
            datas.push({
              [`tanggal-${element.sensor.toUpperCase()}`]: e.t,
              [`${element.sensor.toUpperCase()}`]: e.v,
              INDEX: i + 1,
            });
          }
        });
      });
      this.setState({
        headerDownload: headers,
        dataDownload: datas,
        proses: false,
      });
    }
  };

  style = {
    center: { display: "flex", justifyContent: "center", alignItems: "center" },
    font: { color: "black" },
    input: {
      date: {
        borderTop: "none",
        borderRight: "none",
        borderLeft: "none",
        borderRadius: 0,
      },
    },
    btn: {
      outline: {
        color: "#757575",
        padding: "0.35rem",
        marginLeft: "0.1rem",
        marginRight: "0.1rem",
        cursor: "pointer",
      },
      active: {
        border: "solid 1.8px #2196f3",
        backgroundColor: "#2196f3",
        color: "#fff",
        padding: "0.35rem",
        marginLeft: "0.1rem",
        marginRight: "0.1rem",
        borderRadius: "0.3rem",
        cursor: "pointer",
      },
    },
  };

  // genrateDownload = () => {
  //   let dataDownload = this.state.iotData;
  //   let dataTime = this.state.iotDataTimeUnix;
  //   let downloadExcel = [
  //     dataTime?.map((dt) => {
  //       let tanggal =
  //         "tanggal :" + this.changeDate(dt) + " " + this.changeTime(dt);
  //       return tanggal;
  //     }),
  //     dataDownload[0]?.map((dt) => {
  //       let tinggiAir = "Curah Hujan :" + dt;
  //       return tinggiAir;
  //     }),
  //     dataDownload[1]?.map((dt) => {
  //       let status = "Status :" + dt;
  //       return status;
  //     }),
  //   ];
  //   this.setState({
  //     csvData: downloadExcel,
  //   });
  // };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { csvData, cilicisPCH_2023 } = this.state;
    // const tableRender = (
    //   <Grid container style={{ marginBottom: 25, paddingBottom: 20 }}>
    //     <Grid item sm={3} md={3} lg={2} xl={2}>
    //       <TableContainer>
    //         <Table size="small">
    //           <TableHead>
    //             <TableRow>
    //               <TableCell>
    //                 <span style={{ fontSize: "0.6rem" }}></span>
    //                 <br />
    //                 No.
    //               </TableCell>
    //               <TableCell>
    //                 <span style={{ fontSize: "0.6rem" }}></span>
    //                 <br />
    //               </TableCell>
    //             </TableRow>
    //           </TableHead>
    //           <TableBody>
    //             {this.state.sensor.map((e, idx) => (
    //               <TableRow hover={true} key={idx}>
    //                 <TableCell>{idx + 1}</TableCell>
    //                 <TableCell>
    //                   {e.name_sensor} ({e.unit})
    //                 </TableCell>
    //               </TableRow>
    //             ))}
    //           </TableBody>
    //         </Table>
    //       </TableContainer>
    //     </Grid>
    //     <Grid item sm={9} md={9} lg={10} xl={10}>
    //       <TableContainer>
    //         <Table size="small">
    //           <TableHead>
    //             <TableRow>
    //               {this.state.iotDataTimeUnix.map((d, idx) => (
    //                 <Tooltip
    //                   title={this.changeDate(d) + " " + this.changeTime(d)}
    //                   placement="top-start"
    //                   key={idx}
    //                 >
    //                   <TableCell>
    //                     <span style={{ fontSize: "0.6rem" }}>
    //                       {this.changeDate(d)}
    //                     </span>
    //                     <br />
    //                     <span>{this.changeTime(d)}</span>
    //                   </TableCell>
    //                 </Tooltip>
    //               ))}
    //             </TableRow>
    //           </TableHead>
    //           <TableBody>
    //             {this.state.iotData.map((e1, idx) => (
    //               <TableRow hover={true} key={idx}>
    //                 {e1.map((e2, idx2) => {
    //                   if (e2 === 0 || e2 === "Cerah") {
    //                     return <TableCell key={idx2}>{e2}</TableCell>;
    //                   } else if ((e2 > 0.1 && e2 < 5) || e2 === "Ringan") {
    //                     return (
    //                       <TableCell
    //                         key={idx2}
    //                         style={{ backgroundColor: "#d4edda" }}
    //                       >
    //                         {e2}
    //                       </TableCell>
    //                     );
    //                   } else if ((e2 >= 5 && e2 < 10) || e2 === "Sedang") {
    //                     return (
    //                       <TableCell
    //                         key={idx2}
    //                         style={{ backgroundColor: "#cce5ff" }}
    //                       >
    //                         {e2}
    //                       </TableCell>
    //                     );
    //                   } else if ((e2 >= 10 && e2 < 20) || e2 === "Lebat") {
    //                     return (
    //                       <TableCell
    //                         key={idx2}
    //                         style={{ backgroundColor: "#fff3cd" }}
    //                       >
    //                         {e2}
    //                       </TableCell>
    //                     );
    //                   } else if (e2 > 20 || e2 === "Sangat Lebat") {
    //                     return (
    //                       <TableCell
    //                         key={idx2}
    //                         style={{ backgroundColor: "#f8d7da" }}
    //                       >
    //                         {e2}
    //                       </TableCell>
    //                     );
    //                   } else {
    //                     return <TableCell key={idx2}>{e2}</TableCell>;
    //                   }
    //                 })}
    //               </TableRow>
    //             ))}
    //           </TableBody>
    //         </Table>
    //       </TableContainer>
    //     </Grid>
    //   </Grid>
    // );

    const tableRender2023 = (
      <div>
        {cilicisPCH_2023.length > 0 && (
          <table style={{ width: "100%", color: "gray" }}>
            <tbody>
              <tr>
                <th style={{ borderBottom: "solid 1px" }}>Tanggal</th>
                {cilicisPCH_2023.map((e, idx) => (
                  <th key={idx} style={{ borderBottom: "solid 1px" }}>
                    <small>{e.ReceivedDate}</small>
                    <br />
                    {e.ReceivedTime}
                  </th>
                ))}
              </tr>
              <tr>
                <th style={{ borderBottom: "solid 1px", whiteSpace: "nowrap" }}>
                  Rain (mm3)
                </th>
                {cilicisPCH_2023.map((e, idx) => (
                  <th
                    key={idx}
                    style={{
                      borderBottom: "solid 1px",
                    }}
                  >
                    {e.Rain}
                  </th>
                ))}
              </tr>
              <tr>
                <th style={{ borderBottom: "solid 1px" }}>Status</th>
                {cilicisPCH_2023.map((e, idx) => (
                  <th key={idx} style={{ borderBottom: "solid 1px" }}>
                    {e.status}
                  </th>
                ))}
              </tr>
            </tbody>
          </table>
        )}
      </div>
    );

    const options = {
      scales: {
        xAxes: [
          {
            type: "time",
            time: {
              displayFormats: {
                quarter: "MMM YYYY",
              },
            },
          },
        ],
      },
    };

    // const graphRender = (
    //   <Grid
    //     container
    //     style={{ marginBottom: 15, paddingBottom: 10, overflow: "auto" }}
    //     wrap="nowrap"
    //   >
    //     {this.state.sensor.map(
    //       (e, idx) =>
    //         // {
    //         e.name_sensor !== "Status" &&
    //         e.name_sensor !== "Debit" && (
    //           <Grid item key={idx} style={{ padding: 15 }} lg={10}>
    //             <ChartNew
    //               type="bar"
    //               data={{
    //                 labels: this.state.iotDataTimeUnix,
    //                 datasets: [
    //                   {
    //                     label: `${e.name_sensor} - ${e.unit}`,
    //                     borderColor: "rgba(54, 162, 235, 1)",
    //                     backgroundColor: "rgba(54, 162, 235, 0.8)",
    //                     data: this.state.iotData[idx],
    //                     fill: true,
    //                     borderWidth: 2,
    //                     radius: 3,
    //                     hoverRadius: 16,
    //                     hoverBorderWidth: 3,
    //                   },
    //                 ],
    //               }}
    //               options={options}
    //               width={600}
    //               height={150}
    //             />
    //           </Grid>
    //         )
    //       // }
    //     )}
    //   </Grid>
    // );

    const graphRender2023 = (
      <div>
        <ChartNew
          type="bar"
          options={options}
          width={600}
          height={150}
          data={{
            labels: cilicisPCH_2023.map(
              (e) => `${e.ReceivedDate} ${e.ReceivedTime}`
            ),
            datasets: [
              {
                label: `Rain (mm3)`,
                borderColor: "rgba(54, 162, 235, 1)",
                backgroundColor: "rgba(54, 162, 235, 0.8)",
                data: cilicisPCH_2023.map((e) => parseFloat(e.Rain)),
                fill: true,
                borderWidth: 2,
                radius: 3,
                hoverRadius: 16,
                hoverBorderWidth: 3,
              },
            ],
          }}
        />
      </div>
    );

    const graphBrantas = (
      <Grid
        container
        style={{ marginBottom: 15, paddingBottom: 10, overflow: "auto" }}
      >
        {this.state.brantasData.length > 0 &&
          this.state.brantasData.map((e, i) => {
            let labels = [],
              datas = [];
            e.data.forEach((e) => {
              labels.push(e.t);
              datas.push(e.v);
            });
            return (
              <ChartNew
                type="line"
                key={i}
                data={{
                  labels: labels,
                  datasets: [
                    {
                      label: `${e.sensor.toUpperCase()}`,
                      borderColor: "rgba(54, 162, 235, 1)",
                      data: datas,
                      fill: false,
                      borderWidth: 2,
                      radius: 3,
                      hoverRadius: 16,
                      hoverBorderWidth: 3,
                    },
                  ],
                }}
                options={options}
                width={600}
                height={150}
              />
            );
          })}
      </Grid>
    );

    const defaultRender = (
      <Grid container spacing={1} style={{ margin: 5, marginBottom: 0 }}>
        <Grid item style={this.style.center}>
          <section className="full_screen" onClick={this.activateFullTable}>
            <img alt="full_screen" src={icon_full_screen_active} width="20px" />
          </section>
        </Grid>
        <Grid item style={this.style.center}>
          <Typography style={this.style.font}>
            PILIH
            {this.state.brantas && " POS PEMANTAU"}
            {/* {this.state.cilicis &&
              this.props.geo_layer?.geo_layer.name.toUpperCase()} */}
          </Typography>
        </Grid>
        <Grid item style={this.style.center}>
          <Autocomplete
            style={{ width: 200 }}
            options={this.state.dataset}
            getOptionLabel={(option) => (option.dataset ? option.dataset : "")}
            renderInput={(params) => <TextField {...params} />}
            value={this.state.value}
            onChange={(event, newValue) => {
              newValue &&
                this.setState({ value: newValue, filterId: newValue.key });
            }}
            inputValue={this.state.inputValue}
            onInputChange={(event, newInputValue) => {
              this.setState({ inputValue: newInputValue });
            }}
          />
        </Grid>
        <Grid item style={this.style.center}>
          &bull;
        </Grid>
        <Grid item style={this.style.center}>
          <Tooltip title={`${dict?.["Select Start Date"]?.[language]}`}>
            <TodayIcon />
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title={`${dict?.["Select Start Date"]?.[language]}`}>
            <input
              type="date"
              style={this.style.input.date}
              onChange={this.handleStarDate}
              value={this.state.startDate}
            />
          </Tooltip>
        </Grid>
        <Grid item style={this.style.center}>
          <Tooltip title={`${dict?.["Select End Date"]?.[language]}`}>
            <TodayIcon />
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title={`${dict?.["Select End Date"]?.[language]}`}>
            <input
              type="date"
              style={this.style.input.date}
              onChange={this.handleFinishDate}
              value={this.state.finishDate}
            />
          </Tooltip>
        </Grid>
        <Grid item style={this.style.center}>
          {this.state.brantas ? (
            <button
              className="button background_blue"
              onClick={this.handleSubmitBrantas}
            >
              {dict?.["Search"]?.[language]}
            </button>
          ) : (
            <button
              className="button background_blue"
              onClick={this.handleSubmit}
            >
              {dict?.["Search"]?.[language]}
            </button>
          )}
        </Grid>
        <Grid item style={this.style.center}>
          &bull;
        </Grid>
        <Grid item style={this.style.center}>
          <Tooltip title="Refresh Data">
            <IconButton>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          {this.state.cilicis && (
            <>
              <Tooltip title="View Table">
                <IconButton onClick={() => this.setState({ tabs: "table" })}>
                  <ListAltIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="View Graph">
                <IconButton onClick={() => this.setState({ tabs: "graph" })}>
                  <TimelineIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
          {this.state.brantas && (
            <Tooltip title="Unduh Data">
              <IconButton
                onClick={() =>
                  this.setState({
                    modalDownload: true,
                    proses: true,
                    alert: false,
                  })
                }
              >
                <CloudDownloadIcon />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        <Grid item style={this.style.center}>
          <CSVLink
            filename={`PosCurahHujan.csv`}
            data={csvData}
            // headers={this.headers}
            // onClick={(e, done) => {
            //   this.genrateDownload({}, done);
            // }}
          >
            <button className="button background_blue">
              {dict?.["Download CSV"]?.[language]}
            </button>
          </CSVLink>
        </Grid>
        {/* <Grid item style={this.style.center}>
          &bull;
        </Grid> */}
        <Grid item style={this.style.center}>
          {/* <Tooltip title="Detail Data">
            <btn style={this.style.btn.outline}>d</btn>
          </Tooltip> */}
          {/* <Tooltip title="Hourly Data">
            <btn style={this.style.btn.active}>H</btn>
          </Tooltip> */}
          {/* <Tooltip title="Day Data">
            <btn style={this.style.btn.outline}>D</btn>
          </Tooltip> */}
        </Grid>
      </Grid>
    );

    const modalDownload = (
      <Dialog
        open={this.state.modalDownload}
        onClose={() => this.setState({ modalDownload: false })}
      >
        {/* <DialogTitle>Unduh Data</DialogTitle> */}
        <List>
          <ListItem>
            <p>POS: {this.state.inputValue}</p>
          </ListItem>
          <ListItem>
            <p>Tanggal Mulai: {this.state.startDate}</p>
          </ListItem>
          <ListItem>
            <p>Tanggal Akhir: {this.state.finishDate}</p>
          </ListItem>
          <ListItem style={{ paddingBottom: 0 }}>Interval Data:</ListItem>
          <ListItem>
            <div>
              <input
                style={{ width: "auto" }}
                type="radio"
                id="interval1"
                name="interval"
                value="1"
                checked={this.state.checkedInterval === 1 ? true : false}
                onChange={() => this.chooseInterval(1)}
              />
              <label for="interval1">Semua</label>
              <input
                style={{ width: "auto" }}
                type="radio"
                id="interval2"
                name="interval"
                value="2"
                checked={this.state.checkedInterval === 2 ? true : false}
                onChange={() => this.chooseInterval(2)}
                disabled
              />
              <label for="interval2">5 Menit</label>
              <input
                style={{ width: "auto" }}
                type="radio"
                id="interval3"
                name="interval"
                value="3"
                checked={this.state.checkedInterval === 3 ? true : false}
                onChange={() => this.chooseInterval(3)}
                disabled
              />
              <label for="interval3">1 Jam</label>
            </div>
          </ListItem>
          {this.state.proses ? (
            <ListItem>
              <Button
                variant="text"
                fullWidth
                style={{ textDecoration: "underline" }}
                onClick={() => this.downloadData()}
              >
                Proses Data
              </Button>
            </ListItem>
          ) : (
            <ListItem>
              <CSVLink
                data={this.state.dataDownload}
                headers={this.state.headerDownload}
                filename={`${this.state.inputValue}.csv`}
                style={{ textDecoration: "underline", color: "blue" }}
              >
                Unduh Data {this.state.inputValue}
              </CSVLink>
            </ListItem>
          )}
          {this.state.alert && (
            <ListItem>
              <Alert severity="info">
                Silahkan memilih POS terlebih dahulu dan klik Cari
              </Alert>
            </ListItem>
          )}
        </List>
      </Dialog>
    );
    return (
      <div>
        {defaultRender}
        {this.state.cilicis && this.state.tabs === "table" && tableRender2023}
        {this.state.cilicis && this.state.tabs === "graph" && graphRender2023}
        {this.state.brantas && graphBrantas}
        {this.state.brantas && modalDownload}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  geo_layer: state.layer.geo_layer,
  iot: state.iot,
  auth: state.auth,
  layer: state.layer,
});

export default connect(mapStateToProps, {})(IotTableBar);

// const dummyDataset = [ {
//   borderColor: [
//     'rgba(54, 162, 235, 1)',
//   ],
//   label: 'label dataset',
//   data: [ 1, 2, 3, 4, 5, 6, 7, 9, 10, 11 ],
// } ];
