const mode = "online"; // online | offline | ip

// const server_local = "http://localhost:4000";
const server_local = "http://localhost:4004";

const geoServer = "https://geoserver.mapid.io"; //https://geoserver.mapid.io || https://alphaserver.mapid.io
const uploadServer = "https://geoserver.mapid.io"; //https://geoserver.mapid.io || https://alphaserver.mapid.io

const geoServerIp = `http://${window.location.host}:4000`;
const iotServer = "https://api.mapid.io";
const iotServerIp = `http://${window.location.host}:4001`;
const alphaServer = "https://alphaserver.mapid.io";

const server_befa = "https://serverbefaupload.mapid.io";
const server_befa_upload = "https://serverbefaupload.mapid.io";
const domain = window.location.hostname;

if (domain === "befa.mapid.io" || domain === "befa-alpha.mapid.io") {
  module.exports = {
    geoServerBaseUrl: server_befa,
    iotServerBaseUrl: server_befa,
    uploadServerUrl: server_befa_upload,
    alphaServerUrl: alphaServer,
  };
} else {
  if (mode === "offline") {
    module.exports = {
      geoServerBaseUrl: server_local,
      iotServerBaseUrl: server_local,
      uploadServerUrl: server_local,
      alphaServerUrl: server_local,
    };
  } else if (mode === "ip") {
    module.exports = {
      geoServerBaseUrl: geoServerIp,
      iotServerBaseUrl: iotServerIp,
      alphaServerUrl: geoServerIp,
    };
  } else {
    module.exports = {
      geoServerBaseUrl: geoServer,
      iotServerBaseUrl: iotServer,
      uploadServerUrl: uploadServer,
      alphaServerUrl: alphaServer,
    };
  }
}
