import React, { Component } from "react";
import Tabs from "../reuseable/Tabs";
import dict from "../../Data/dict.json";
import PaymentTable from "./PaymentTable";
import QoutaPriceList from "./QoutaPriceList";
import RedeemButton from "./RedeemButton";
import BuyNow from "./BuyNow";
import PaymentInstruction from "../../Pages/dashboard/PaymentInstruction";
import { ArrowBack } from "@material-ui/icons";
import WaitForTransactionProcess from "./WaitForTransactionProcess";
import ErrorPayment from "./ErrorPayment";

const menu_tabs = ["license", "access", "redeem code"];

export default class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actived_tab: "license",
      page: "packages", // packages, bundle, payment, loading
      license_selected: "Personal",
      license_object: {},
      payment_id: "",
      error: {},
    };
  }
  async componentDidMount() {
    const payment = localStorage.getItem("payment");

    window.addEventListener("resize", this.updateMode);

    if (payment && payment !== "") {
      const body = {
        payment_type: "license_1",
        month: parseInt(payment), // ✅ Diberi key yang benar
        payment_methode: "midtrans",
      };

      let response = "";
      const current_url = window.location.href;
      if (current_url.includes("user_profile")) {
        response = await this.props.create_payment(body, "user_profile");
      } else {
        response = await this.props.create_payment(body);
      }

      if (response?.status === 200 && response?.id) {
        this.setState({ payment_id: response.id });
        this.set_page({ new_page: "payment" });
      } else {
        this.set_page({ new_page: "error" });
        this.set_error(response);
      }
    }
  }

  set_license_selected = (value) => {
    this.setState({
      license_selected: value,
    });
  };

  toggleModalBuyNow = () => {
    this.setState({ modal_buy_now: !this.state.modal_buy_now });
  };

  set_page = ({ license_object, new_page }) => {
    const list_of_pages = ["packages", "bundle", "payment", "loading", "error"];
    const { page } = this.state;

    this.setState({
      page: list_of_pages.includes(new_page) ? new_page : page,
      license_object: license_object || this.state.license_object, // Tetap gunakan jika null
    });
  };

  handle_tab = (value) => {
    this.setState({
      actived_tab: value,
      modal_buy_now: false,
    });
  };

  set_payment_id = (value) => {
    this.setState({
      payment_id: value,
    });
  };

  set_error = (value) => {
    this.setState({
      error: value,
    });
  };

  render() {
    // local storage
    const language = localStorage?.language || "ina";

    // state
    const {
      actived_tab,
      license_selected,
      page,
      license_object,
      payment_id,
      error,
    } = this.state;

    let content, title, description;
    if (actived_tab === "license") {
      if (page === "packages") {
        title = dict?.["Best license price for you"]?.[language];
        description =
          dict?.[
            "Get features at the best price on the market! Limited timeoffer to boost your productivity."
          ]?.[language];
        content = (
          <PaymentTable
            set_license_selected={this.set_license_selected}
            license_selected={license_selected}
            page={page}
            set_page={this.set_page}
          />
        );
      } else if (page === "bundle") {
        const text = dict?.["Bundle Package"]?.[language];
        if (language === "ina") {
          title = `${text} ${license_selected}`;
        } else {
          title = `${license_selected} ${text}`;
        }
        description = dict?.["Exclude VAT"]?.[language];

        content = (
          <BuyNow
            page={page}
            set_page={this.set_page}
            set_payment_id={this.set_payment_id}
            license_object={license_object}
            set_error={this.set_error}
          />
        );
      } else if (page === "payment") {
        title = dict?.["Payment"]?.[language];
        // description = dict?.["Get features at the best price on the market! Limited timeoffer to boost your productivity."]?.[language]

        content = <PaymentInstruction payment_id={payment_id || ""} />;
      } else if (page === "loading") {
        title = dict?.["Please Wait..."]?.[language];
        content = <WaitForTransactionProcess />;
      } else if (page === "error") {
        title = dict?.["Error"]?.[language];
        content = <ErrorPayment error={error} />;
      }
    } else if (actived_tab === "access") {
      title = dict?.["Best access quota price for you"]?.[language];
      description =
        dict?.[
          "Get features at the best price on the market! Limited timeoffer to boost your productivity."
        ]?.[language];
      content = <QoutaPriceList />;
    } else if (actived_tab === "redeem code") {
      title = "Redeem MAPID Code";
      description = dict?.["Enter your code to get discount."]?.[language];
      content = <RedeemButton />;
    }

    return (
      <main className="flex flex_col gap_30">
        <header className="flex justify_start gap_20">
          {page !== "packages" && (
            <div className="flex justify_start">
              <div
                onClick={() => {
                  if (page === "bundle") {
                    this.set_page({ new_page: "packages" });
                  } else if (page !== "packages" && actived_tab === "license") {
                    this.set_page({ new_page: "bundle" });
                  } else if (page !== "packages" && actived_tab === "access") {
                    this.set_page({ new_page: "packages" });
                  }
                }}
                className="pointer hover_bigger w_50 h_50 bg_darkBlue center_perfect text_white rounded_50"
              >
                <ArrowBack />
              </div>
            </div>
          )}

          <div>
            <label className="font_30 bold">{title}</label>
            <p>{description}</p>
          </div>
        </header>
        <section className="center_perfect gap_20 h_full">
          {page === "packages" && (
            <Tabs
              actived_tab={actived_tab}
              menu_tabs={menu_tabs}
              handle_tab={this.handle_tab}
            />
          )}
        </section>

        <footer style={{ minHeight: "300px" }}>{content}</footer>
      </main>
    );
  }
}
