/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Map } from "maplibre-gl";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

/*COMPONENT*/
import STATUS_GET_GENANGAN from "../trivial_components/STATUS_GET_GENANGAN";
import MAP_CONTROL_DRAW from "../libre_draw/MAP_CONTROL_DRAW";
import MAP_CONTROL_BASIC from "../libre_draw/MAP_CONTROL_BASIC";
import DRAW_GEO from "../libre_draw/DRAW_GEO";
import Legend from "../map_legend/Legend";
import POPUP_EDITOR from "../libre_popup/POPUP_EDITOR";
import LAYER_FEATURE_SELECTED from "./LAYER_FEATURE_SELECTED";
import LAYER_GEO from "./LAYER_GEO";
import MARKER_GEO from "./MARKER_GEO";
import LAYER_HEATMAP from "./LAYER_HEATMAP";
import LAYER_GPS from "./LAYER_GPS";
import LAYER_TOOLBOX from "./LAYER_TOOLBOX";
import LAYER_INSIGHT from "./LAYER_INSIGHT";
import LAYER_GENANGAN from "./LAYER_GENANGAN";
import SYMBOL_GEO from "./SYMBOL_GEO";
import M_S_LOADING from "../libre_marker_sini/M_S_LOADING";
import LAYER_S_CENTER from "../libre_layer_sini/LAYER_S_CENTER";
import LAYER_S_POI from "../libre_layer_sini/LAYER_S_POI";
import LAYER_S_POI_SURVEY_1 from "../libre_layer_sini/LAYER_S_POI_SURVEY_1";
import LAYER_S_DEMOGRAPHY_ONE from "../libre_layer_sini/LAYER_S_DEMOGRAPHY_ONE";
import LAYER_S_DEMOGRAPHY_NEIGHBOR from "../libre_layer_sini/LAYER_S_DEMOGRAPHY_NEIGHBOR";
import LAYER_S_ISOCHRONE from "../libre_layer_sini/LAYER_S_ISOCHRONE";
import LAYER_S_FLOOD_NEIGHBOR from "../libre_layer_sini/LAYER_S_FLOOD_NEIGHBOR";
import LAYER_S_FLOOD from "../libre_layer_sini/LAYER_S_FLOOD";
import LAYER_S_LANDSLIDE_NEIGHBOR from "../libre_layer_sini/LAYER_S_LANDSLIDE_NEIGHBOR";
import LAYER_S_LANDSLIDE from "../libre_layer_sini/LAYER_S_LANDSLIDE";
import LAYER_S_LANDVALUE from "../libre_layer_sini/LAYER_S_LANDVALUE";
import LAYER_S_LANDVALUE_NEIGHBOR from "../libre_layer_sini/LAYER_S_LANDVALUE_NEIGHBOR";
import LAYER_S_LANDZONE_NEIGHBOR from "../libre_layer_sini/LAYER_S_LANDZONE_NEIGHBOR";
import LAYER_S_LANDZONE from "../libre_layer_sini/LAYER_S_LANDZONE";
import LAYER_S_RDTR_NEIGHBOR from "../libre_layer_sini/LAYER_S_RDTR_NEIGHBOR";
import LAYER_S_RDTR_ONE from "../libre_layer_sini/LAYER_S_RDTR_ONE";
import LAYER_S_TSUNAMI_NEIGHBOR from "../libre_layer_sini/LAYER_S_TSUNAMI_NEIGHBOR";
import LAYER_S_TSUNAMI from "../libre_layer_sini/LAYER_S_TSUNAMI";
import LAYER_SEARCH from "./LAYER_SEARCH";
import LAYER_POLYGONS_GENANGAN from "./LAYER_POLYGONS_GENANGAN";
import LAYER_POLYGON_RAMBATAN from "./LAYER_POLYGON_RAMBATAN";
import LAYER_TITIK_DEM from "./LAYER_TITIK_DEM";
import LAYER_TOOLBOX_GRID from "./LAYER_TOOLBOX_GRID";
import LIBRE_MACHRUS from "../libre_latihan/LIBRE_MACHRUS";
import LIBRE_GLB_SAMPLE from "../libre_latihan/LIBRE_GLB_SAMPLE";
import LIBRE_GLB_TASIK from "../libre_latihan/LIBRE_GLB_TASIK";

/*REDUX*/
import { set_value_layer } from "../../App/actions/layerActions";
import { set_value_sini } from "../../App/actions/sini_v2";
import { get_features_by_bound } from "../../App/actions/get_features_by_bound";

/*PICTURE*/

/*FUNCTION*/

/*DATA*/

/*CONST*/
const basemap_url = process.env.REACT_APP_MAPID_BASEMAP;
const basemap_key = process.env.REACT_APP_BASEMAP_KEY;
const max_zoom = 14;
const delay_bounding_box = 500;

class MAP_COMPONENT extends Component {
  fetch_timeout = null; // Store timeout reference
  componentDidMount() {
    const { set_value_layer } = this.props;
    const map_object = new Map({
      container: "map_object",
      style: `${basemap_url}/styles/street-2d-building/style.json?key=${basemap_key}`,
      center: [118.72762419354456, -0.2055368192132505],
      zoom: 3.7,
      maxPitch: 85,
      projection: "globe",
      attributionControl: false,
    });
    map_object.addControl(
      new maplibregl.AttributionControl({
        customAttribution: "GEO",
        compact: false,
      })
    );
    set_value_layer({
      key: "map_object",
      value: map_object,
    });
    this.setup_map_listeners(map_object);
  }

  componentDidUpdate(prevProps) {
    const { layer } = this.props;
    const map_object = layer.map_object;
    const prev_map_object = prevProps.layer.map_object;
    if (map_object && prev_map_object) {
      const zoom_level = map_object.getZoom();
      const prev_zoom_level = prev_map_object.getZoom();
      if (zoom_level !== prev_zoom_level && zoom_level < max_zoom) {
        map_object.off("move");
        map_object.off("moveend");
      }
    }
  }

  componentWillUnmount() {
    const { set_value_layer, set_value_sini } = this.props;
    const map_object = this.props.layer.map_object;
    if (map_object) {
      map_object.off("zoom");
      map_object.off("move");
      map_object.off("moveend");
    }
    set_value_layer({ key: "map_object", value: null });
    set_value_sini({ key: "sini_v2_list", value: [] });
    set_value_sini({ key: "ai_parent_detail_list", value: [] });
    set_value_sini({ key: "ai_parent_list", value: [] });
  }

  setup_map_listeners = (map_object) => {
    const zoomHandler = () => {
      const zoom_level = map_object.getZoom();

      if (zoom_level < max_zoom) {
        map_object.off("move");
        map_object.off("moveend");
      } else {
        // Ensure duplicate listeners are not added
        map_object.off("move");
        map_object.off("moveend");
        map_object.on("move", () => {
          clearTimeout(this.fetch_timeout);
        });
        map_object.on("moveend", () => {
          clearTimeout(this.fetch_timeout);
          this.fetch_timeout = setTimeout(() => {
            if (zoom_level >= max_zoom) {
              this.on_get_features_by_bound();
            }
          }, delay_bounding_box);
        });
      }
    };
    // Attach zoom listener (only once)
    map_object.off("zoom", zoomHandler); // Ensure no duplicate listeners
    map_object.on("zoom", zoomHandler);
  };

  on_get_features_by_bound = () => {
    const { map_object, layer_id_bound, geo_layer_list } = this.props.layer;
    if (map_object && layer_id_bound?.length > 0) {
      const zoom_level = map_object.getZoom();
      if (zoom_level >= max_zoom) {
        const { geo_project } = this.props.project;
        const geo_layer = geo_layer_list.find(
          (item) => item?.geo_layer?._id === layer_id_bound[0]
        );
        const fields = geo_layer?.geo_layer?.fields || [];
        const bounds = map_object.getBounds();
        const params = {
          bounds,
          geo_layer_id: layer_id_bound[0],
          geo_project_id: geo_project._id,
          fields: fields,
        };
        this.props.get_features_by_bound(params);
      }
    }
  };

  render() {
    const {
      layer: {
        modal_edit_feature_properties,
        latitude_selected,
        longitude_selected,
        fields_selected,
        properties_selected,
        type_2_selected,
        feature_key_selected,
        geo_layer_selected,
        genangan_cm_selected,
      },
      is_editable,
    } = this.props;

    return (
      <>
        <STATUS_GET_GENANGAN />
        <main
          id="map_object"
          style={{
            width: "100vw",
            height: "100vh",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
        <MAP_CONTROL_DRAW />
        <MAP_CONTROL_BASIC />
        <DRAW_GEO />
        <MARKER_GEO />
        <SYMBOL_GEO />
        <LAYER_GEO />
        <LAYER_HEATMAP />
        <LAYER_TOOLBOX />
        <LAYER_GPS />
        <LAYER_INSIGHT />
        <LAYER_GENANGAN />
        <M_S_LOADING />
        <LAYER_S_CENTER />

        <LAYER_S_POI />
        <LAYER_S_ISOCHRONE />

        <LAYER_S_DEMOGRAPHY_ONE />
        <LAYER_S_DEMOGRAPHY_NEIGHBOR />
        <LAYER_S_LANDVALUE />
        <LAYER_S_LANDVALUE_NEIGHBOR />
        <LAYER_S_LANDZONE />
        <LAYER_S_LANDZONE_NEIGHBOR />
        <LAYER_S_RDTR_ONE />
        <LAYER_S_RDTR_NEIGHBOR />
        <LAYER_S_POI_SURVEY_1 />
        <LAYER_S_FLOOD />
        <LAYER_S_FLOOD_NEIGHBOR />
        <LAYER_S_LANDSLIDE />
        <LAYER_S_LANDSLIDE_NEIGHBOR />
        <LAYER_S_TSUNAMI />
        <LAYER_S_TSUNAMI_NEIGHBOR />
        <LAYER_FEATURE_SELECTED />
        <LAYER_TOOLBOX_GRID />
        <Legend />
        {modal_edit_feature_properties && (
          <POPUP_EDITOR
            latitude_selected={latitude_selected}
            longitude_selected={longitude_selected}
            fields_selected={fields_selected}
            properties_selected={properties_selected}
            type_2_selected={type_2_selected}
            feature_key_selected={feature_key_selected}
            genangan_cm_selected={genangan_cm_selected}
            geo_layer_selected={geo_layer_selected}
            is_editable={is_editable}
          />
        )}
        <LAYER_SEARCH />
        <LAYER_POLYGONS_GENANGAN />
        <LAYER_POLYGON_RAMBATAN />
        <LAYER_TITIK_DEM />
        <LIBRE_MACHRUS />
        <LIBRE_GLB_SAMPLE />
        <LIBRE_GLB_TASIK />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  project: state.project,
});

export default connect(mapStateToProps, {
  set_value_layer,
  set_value_sini,
  get_features_by_bound,
})(MAP_COMPONENT);
