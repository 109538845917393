//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component
import SpinnerWhite from "../common_spinner/SpinnerWhite";
import TextFieldGroupTight from "../common_input/TextFieldGroupTight";

//Redux function
import { userCheckout } from "../../App/actions/paymentActions";

//Picture Asset
import logo_mandiri from "../../Assets/png_jpg/logo_mandiri.png";

//General Function
import Bank_list from "../../Data/Bank_list.json";
import dict from "../../Data/dict.json";

class CheckOut extends Component {
  state = {
    modalBuyNow: false,
    errors: {},
    type_bank: "",
    no_bank: "",
    name_bank: "",
    payment_methode: "bank_transfer",
  };

  toggleBuyNow = () => {
    this.setState({ modalBuyNow: !this.state.modalBuyNow }, () => {
      this.props.toggleBuyNow();
    });
  };

  handleChangePaymentMethode = (payment_methode) => {
    this.setState({ payment_methode });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { type_bank, no_bank, name_bank, payment_methode } = this.state;
    let is_money_changes = true;
    if (type_bank === "Bank Mandiri") {
      is_money_changes = false;
    }
    const { payment } = this.props.payment;
    const payment_id = payment._id;
    const paymentData = {
      payment_id,
      payment_methode,
      type_bank,
      no_bank,
      name_bank,
      is_money_changes,
    };
    this.props.userCheckout(paymentData);
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { errors, type_bank, no_bank, name_bank, payment_methode } =
      this.state;
    const { loadingProcess, payment } = this.props.payment;
    const { geo_layer, amount_data_survey, per_insentif } = payment;
    const price_pure = amount_data_survey * per_insentif;
    const administration_price = parseInt((5 / 100) * price_pure);
    const price = price_pure + administration_price;
    const decimal_price = new Intl.NumberFormat("id-ID", {
      style: "decimal",
    }).format(price);

    const name = geo_layer?.name;

    const buttonContent = loadingProcess ? (
      <div className="button_block_tight">
        <SpinnerWhite
          width={30}
          unik="loading_item_list"
          marginTop="0px"
          center={true}
          border={0.1}
        />
      </div>
    ) : (
      <button className="button_block" type="submit">
        {dict["Pay"][language]}
      </button>
    );

    const content = (
      <div>
        <div style={{ marginBottom: "5px" }}>{dict["Payment"][language]}</div>
        <div className="container_grey">
          <div className="two-container">
            <div>
              {dict["Total price"][language]} <br /> <b>Rp {decimal_price}</b>
            </div>
            <div>
              <div>{dict["Paid survey publication"][language]}</div>
              <div>
                <b>{name}</b>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "15px", marginBottom: "5px" }}>
          {dict["Choose a payment method"][language]}
        </div>
        <div className="two_container_equal" style={{ marginBottom: "5px" }}>
          <div
            className="button_block_tight"
            id={
              payment_methode === "bank_transfer"
                ? "button_active"
                : "button_non_active"
            }
            onClick={this.handleChangePaymentMethode.bind(
              this,
              "bank_transfer"
            )}
          >
            <div>{dict["Bank transfer"][language]}</div>
            <div>
              <img src={logo_mandiri} height="30px" alt="logo_mandiri" />
            </div>
          </div>
          <div
            className="button_block_tight"
            id={
              payment_methode === "saldo"
                ? "button_active"
                : "button_non_active"
            }
            onClick={this.handleChangePaymentMethode.bind(this, "saldo")}
          >
            <div>{dict["Your Balance"][language]}</div>
            <div>Rp 0</div>
          </div>
        </div>
        <div style={{ minHeight: "420px" }}>
          {payment_methode === "bank_transfer" ? (
            <form noValidate autoComplete="off" onSubmit={this.onSubmit}>
              <div className="container_light" style={{ marginBottom: "5px" }}>
                {dict["Bank transfer"][language]}
                <hr />
                <TextFieldGroupTight
                  type="text"
                  placeholder={dict["Your Bank"][language]}
                  name="type_bank"
                  id="type_bank"
                  value={type_bank}
                  onChange={this.onChange}
                  error={errors.type_bank}
                  list="list_bank"
                />
                <datalist id="list_bank">
                  {Bank_list.map((bank_element, idx) => {
                    return <option value={bank_element} key={idx} />;
                  })}
                </datalist>
                <TextFieldGroupTight
                  type="number"
                  placeholder={dict["Account number"][language]}
                  name="no_bank"
                  id="no_bank"
                  value={no_bank}
                  onChange={this.onChange}
                  error={errors.no_bank}
                />
                <TextFieldGroupTight
                  type="text"
                  placeholder={dict["Account name"][language]}
                  name="name_bank"
                  id="name_bank"
                  value={name_bank}
                  onChange={this.onChange}
                  error={errors.name_bank}
                />
                <div className="container_grey">
                  <div>
                    •{" "}
                    {
                      dict[
                        "Enter the related information above according to the savings book"
                      ][language]
                    }
                  </div>
                </div>
              </div>
              {buttonContent}
            </form>
          ) : (
            <div>{dict["Your balance is not enough"][language]}</div>
          )}
        </div>
      </div>
    );
    return <div>{content}</div>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  payment: state.payment,
});

export default connect(mapStateToProps, { userCheckout })(CheckOut);
