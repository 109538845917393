const initial_state = {
  mapid_ai_update: 0,
  height: 500,
  user: {},
  user_step: 0, //0 || 1 || 2
  /*
  0: masukkan nomor hp
  1: masukkan kode otp
  2: masukan nama lengkap (jika belum)
  */
};

export default function mapid_ai_reducer(state = initial_state, action) {
  switch (action.type) {
    case "mapid_ai_update":
      return {
        ...state,
        mapid_ai_update: state.mapid_ai_update + 1,
      };
    case "set_value_mapid_ai":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case "push_value_mapid_ai":
      return {
        ...state,
        [action.payload.key]: [
          ...state[action.payload.key],
          action.payload.value,
        ],
      };
    case "push_value_many_mapid_ai":
      return {
        ...state,
        [action.payload.key]: [
          ...state[action.payload.key],
          [...action.payload.value],
        ],
      };
    default:
      return state;
  }
}
