import React from "react";
import { connect } from "react-redux";

import dict from "../../Data/dict.json";
const delay = 6000;
class MainCarousel extends React.Component {
  componentDidMount() { }
  render() {
    const { banner_array } = this.props.auth;
    return (
      <div>
        <Carousel banner_array={banner_array} />
      </div>
    );
  }
}
class Carousel extends React.Component {
  interval = null;
  constructor() {
    super();
    this.state = {
      currentIndex: 0,
      isTransitioning: false,
      goingLeft: false,
    };
  }
  componentDidMount() {
    window.addEventListener("keyup", this.onKeyUp);
    this.interval = setInterval(() => {
      this.showNextSet();
    }, delay);
  }
  componentWillUnmount() {
    window.removeEventListener("keyup", this.onKeyUp);
    clearInterval(this.interval);
  }
  showPrevSet = click => {
    const currentIndex =
      (this.state.currentIndex - 1 + this.props.banner_array.length) %
      this.props.banner_array.length;
    this.setState({ currentIndex });
    if (click) {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.showNextSet();
      }, delay);
    }
  };
  showNextSet = click => {
    const currentIndex =
      (this.state.currentIndex + 1) % this.props.banner_array.length;
    this.setState({ currentIndex });
    if (click) {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.showNextSet();
      }, delay);
    }
  };
  onKeyUp = e => {
    if (e.keyCode) {
      if (e.keyCode === 39) {
        this.showNextSet();
      } else if (e.keyCode === 37) {
        this.showPrevSet();
      }
    }
  };
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { banner_array } = this.props;
    const { currentIndex } = this.state;
    return (
      <main className="carousel_wrapper">
        <section className="carousel_container">
          {banner_array.map((e, index) => {
            let className = "carousel_image";
            const url_array = Object.values(e.url);
            if (index === currentIndex) className += " active";
            return (
              <a
                href={banner_array[currentIndex]?.link}
                target="_blank"
                rel="noopener noreferrer"
                key={`img-${index}`}
              >
                <img
                  src={e.url[language] ? e.url[language] : url_array[0]}
                  className={className}
                  key={`img-${index}`}
                  alt="MAPID GIS"
                />
              </a>
            );
          })}
          <button
            className="carousel_button_next noselect"
            onClick={this.showNextSet.bind(this, true)}
          />
          <button
            className="carousel_button_prev noselect"
            onClick={this.showPrevSet.bind(this, true)}
          />
        </section>
        <section className="text_header" style={{ marginTop: "10px" }}>
          {banner_array[currentIndex]?.name[language]}
        </section>
        <a
          href={banner_array[currentIndex]?.link}
          target="_blank"
          rel="noopener noreferrer"
          className="button_standard_tight"
          id="blue"
        >
          {dict["View more"][language]}
        </a>
      </main>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(MainCarousel);
