import axios from "axios";
// import his from "./history";
import imageCompression from "browser-image-compression";
import { geoServerBaseUrl } from "./baseUrl";

const SERVER_URL = geoServerBaseUrl;

/*GET METHODE*/

//get_price_2023
export const get_price_2023 = () => async (dispatch) => {
  try {
    dispatch(set_loading("get_price_2023"));

    const res = await axios.get(SERVER_URL + "/admins/get_price_2023");

    dispatch({
      type: "get_price_2023",
      payload: res.data,
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//Get all payment user
export const getUserPaymentList = () => async (dispatch) => {
  try {
    if (localStorage.token_mapid) {
      const config = {
        headers: {
          accesstoken: localStorage.token_mapid,
        },
      };
      dispatch(set_loading());
      const res = await axios.get(
        SERVER_URL + "/payments/all_user_payments",
        config
      );
      dispatch(clear_loading());
      dispatch({
        type: "GET_USER_PAYMENT_LIST",
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch(clear_loading());
  }
};

//Get payment detail
export const getUserPaymentDetail = (payment_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    dispatch(set_loading());
    const res = await axios.get(
      SERVER_URL + `/payments/payment_detail/${payment_id}`,
      config
    );

    dispatch(clear_loading());
    dispatch({
      type: "GET_USER_PAYMENT_DETAIL",
      payload: res.data,
    });
  } catch (error) {
    dispatch(clear_loading());
  }
};

//Get bank_data user (decrypt)
export const getUserBankList = () => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.token_mapid,
    },
  };
  dispatch(set_loading());
  axios
    .get(SERVER_URL + "/payments/get_bank_list", config)
    .then((res) => {
      dispatch(clear_loading());
      dispatch({
        type: "GET_USER_BANK_LIST",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clear_loading());
    });
};

//Check specialist availability
export const checkSpecialist = (code) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.token_mapid,
    },
  };
  dispatch(set_loading());
  axios
    .get(SERVER_URL + `/specialist/check_specialist/${code}`, config)
    .then((res) => {
      dispatch(clear_loading());
      dispatch({
        type: "CHECK_SPECIALIST",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clear_loading());
    });
};

//getSpecialistCode
export const getSpecialistCode = (id) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.token_mapid,
    },
  };
  dispatch(set_loading());
  axios
    .get(SERVER_URL + `/specialist/get_specialist_code/${id}`, config)
    .then((res) => {
      dispatch(clear_loading());
      dispatch({
        type: "SET_SPECIALIST_CODE",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clear_loading());
    });
};

/*POST METHODE*/

//update_bank
export const update_bank = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("update_bank"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    const res = await axios.post(
      SERVER_URL + "/payments/update_bank",
      body,
      config
    );

    dispatch({
      type: "update_bank",
      payload: res.data,
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//upload_resi_paid_form
export const upload_resi_paid_form = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("upload_resi_paid_form"));

    let { body_file, body_database } = body;

    const config_database = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const config_file = {
      headers: {
        accesstoken: localStorage.token_mapid,
        "Content-Type": "multipart/form-data",
      },
    };

    //STEP 1 upload file
    const file = body_file.file;
    const options_compression = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1000,
      useWebWorker: true,
    };
    const file_compressed = await imageCompression(file, options_compression);
    const file_name = body_file.file_name;
    // form data upload gambar
    let body_upload = new FormData();
    body_upload.append("original_file_name", `${file_name}`);
    body_upload.append("file", file_compressed);
    const result_upload = await axios.post(
      SERVER_URL + "/upload",
      body_upload,
      config_file
    );
    const url = result_upload.data.data.Location;

    //STEP 2 update databse
    body_database.picture_url = url;
    const res = await axios.post(
      SERVER_URL + "/layers/create_payment_form_new",
      body_database,
      config_database
    );

    dispatch({
      type: "create_payment_form_new",
      payload: res.data,
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//create_payment_form
/*
    const body = {
      geo_layer_id: layer_id,
      type_bank,
      no_bank,
      name_bank,
    };

    respon ={
      status: "Create payment paid form success",
      payment: payment,
      amounts_sum: amounts_sum,
    }
*/
export const create_payment_form = (item) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    dispatch(set_loading("create_payment_form"));
    const res = await axios.post(
      SERVER_URL + "/layers/create_payment_form",
      item,
      config
    );
    dispatch({
      type: "create_payment_form",
      payload: res.data,
    });
    dispatch({
      type: "SET_MESSAGE",
      payload: res.data,
    });
    dispatch(clear_loading());
  } catch (error) {
    dispatch(clear_loading());
  }
};

//create_payment_form_new
export const create_payment_form_new = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    dispatch(set_loading("create_payment_form_new"));
    const res = await axios.post(
      SERVER_URL + "/layers/create_payment_form_new",
      body,
      config
    );
    dispatch({
      type: "create_payment_form_new",
      payload: res.data,
    });
    dispatch(clear_loading());
  } catch (error) {
    dispatch(clear_loading());
  }
};

//confirm_payment_form
export const confirm_payment_form = (item) => async (dispatch) => {
  try {
    dispatch(set_loading("confirm_payment_form"));
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const config_header_upload = {
      headers: {
        accesstoken: localStorage.token_mapid,
        "Content-Type": "multipart/form-data",
      },
    };
    //Original file
    const { objParams, geo_layer_id, payment_id } = item;
    const file = objParams.file;
    //Compressed file small
    const options_compression = {
      maxSizeMB: 1.5,
      maxWidthOrHeight: 1500,
      useWebWorker: true,
    };
    const file_compressed = await imageCompression(file, options_compression);
    const file_name = objParams.file_name;
    // form data upload gambar
    const formData = new FormData();
    formData.append("original_file_name", `${file_name}`);
    formData.append("file", file_compressed);
    const result_upload = await axios.post(
      SERVER_URL + "/upload",
      formData,
      config_header_upload
    );
    const url = result_upload.data.data.Location;
    //Generate body & axios to API
    const body = {
      picture_url: url,
      geo_layer_id,
      payment_id,
    };
    await axios.post(
      SERVER_URL + "/layers/confirm_payment_form",
      body,
      config_header
    );
    dispatch(clear_loading());
    dispatch({
      type: "CONFIRM_PAYMENT_FORM",
      payload: body,
    });
  } catch (e) {
    dispatch(clear_loading());
  }
};

//create_payment
export const create_payment = (item, from) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    dispatch(set_loading());
    console.log(item);
    const res = await axios.post(
      SERVER_URL + "/payments/create_payment",
      item,
      config
    );
    dispatch(clear_loading());
    dispatch({
      type: "create_payment",
      payload: res.data,
    });

    // if (from === "user_profile") {
    //   his.push(`/payment/${res.data._id}`);
    // }
    return { id: res.data._id, status: res.status };
  } catch (error) {
    dispatch(clear_loading());
    return error;
  }
};

//user_action_qris
export const user_action_qris = (item) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    dispatch(set_loading());
    const res = await axios.post(
      SERVER_URL + "/payments/user_action_qris",
      item,
      config
    );
    dispatch(clear_loading());
    dispatch({
      type: "ACTION_PAYMENT",
      payload: res.data,
    });
    dispatch({
      type: "GET_USER_PAYMENT_DETAIL",
      payload: res.data,
    });
  } catch (error) {
    dispatch(clear_loading());
  }
};

//actionPayment
export const actionPayment = (item) => async (dispatch) => {
  const config_header_upload = {
    headers: {
      accesstoken: localStorage.token_mapid,
      "Content-Type": "multipart/form-data",
    },
  };
  dispatch(set_loading());
  const body = item.body;
  const status = body.status;
  if (status === "user_purchase") {
    //status === "user_purchase"
    const { objParams } = item;
    const file = objParams.file;
    const file_name = objParams.file_name;
    //Compressed file big
    const options_compression = {
      maxSizeMB: 1.5,
      maxWidthOrHeight: 1500,
      useWebWorker: true,
    };
    const file_compres = await imageCompression(file, options_compression);
    // form data upload gambar
    const formData = new FormData();
    formData.append("original_file_name", `${file_name}`);
    formData.append("file", file_compres);
    const result_upload = await axios.post(
      SERVER_URL + "/upload",
      formData,
      config_header_upload
    );
    const body = item.body;
    const picture_url = result_upload.data.data.Location;
    body.picture_url = picture_url;
    const header_token = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    axios
      .post(SERVER_URL + "/payments/user_action", body, header_token)
      .then((res) => {
        dispatch(clear_loading());
        dispatch({
          type: "ACTION_PAYMENT",
          payload: res.data,
        });
        dispatch({
          type: "GET_USER_PAYMENT_DETAIL",
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch(clear_loading());
      });
  } else {
    //status === "user_cancel"
    const body = item.body;
    const header_token = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    axios
      .post(SERVER_URL + "/payments/user_action", body, header_token)
      .then((res) => {
        dispatch(clear_loading());
        dispatch({
          type: "ACTION_PAYMENT",
          payload: res.data,
        });
        dispatch({
          type: "GET_USER_PAYMENT_DETAIL",
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch(clear_loading());
      });
  }
};

//userCheckout
export const userCheckout = (item) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.token_mapid,
    },
  };
  dispatch(set_loading());
  axios
    .post(SERVER_URL + "/payments/user_checkout", item, config)
    .then((res) => {
      dispatch(clear_loading());
      dispatch({
        type: "ACTION_PAYMENT",
        payload: res.data,
      });
      dispatch({
        type: "GET_USER_PAYMENT_DETAIL",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clear_loading());
    });
};

//surveyAction
export const surveyAction = (item) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.token_mapid,
    },
  };
  dispatch(set_loading());
  axios
    .post(SERVER_URL + "/payments/survey_action", item, config)
    .then((res) => {
      dispatch(clear_loading());
      dispatch({
        type: "SURVEY_ACTION",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clear_loading());
    });
};

//buy_coin
/*
body={
  quota: Number jumlah quota yang akan dibeli, hanya bisa bulat sesuai yang ada di database
}
*/
export const buy_coin = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("buy_coin"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(
      SERVER_URL + "/payments/buy_coin",
      body,
      config
    );
    // his.push(`?payment_id=${res.data._id}`);

    dispatch({
      type: "GET_USER_PAYMENT_DETAIL",
      payload: res.data,
    });
    dispatch(clear_loading());

    return { id: res.data._id, status: res.status };
  } catch (error) {
    dispatch(clear_loading());
    return error;
  }
};

//dummy_confirm_coin_will_be_delete_soon
export const dummy_confirm_coin_will_be_delete_soon =
  (body) => async (dispatch) => {
    try {
      const config = {
        headers: {
          accesstoken: localStorage.token_mapid,
        },
      };
      await axios.post(
        SERVER_URL + "/payments/dummy_confirm_coin_will_be_delete_soon",
        body,
        config
      );
    } catch (error) {
      dispatch(clear_loading());
    }
  };

/*NON API*/

export const set_loading = (loading_item) => {
  return {
    type: "set_loading_payment",
    payload: loading_item,
  };
};

export const clear_loading = () => {
  return {
    type: "clear_loading_payment",
  };
};

export const clearPayment = () => {
  return {
    type: "CLEAR_PAYMENT",
  };
};

export const setSpecialistCode = (code) => {
  return {
    type: "SET_SPECIALIST_CODE",
    payload: code,
  };
};

export const resetSpecialistCode = () => (dispatch) => {
  dispatch({
    type: "SET_SPECIALIST_CODE",
    payload: "",
  });
  dispatch({
    type: "CHECK_SPECIALIST",
    payload: false,
  });
};

export const clear_payment_object = () => {
  return {
    type: "clear_payment_object",
  };
};
