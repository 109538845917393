import React, { Component } from "react";
import icon_type_date from "../../Assets/svg/icon_type_date.svg";
import format_date_formal from "../../App/validation/format_date_formal";
import is_not_empty from "../../App/validation/is_not_empty";

export default class DateCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
    };
  }

  componentDidMount = () => {
    const { params } = this.props;
    this.setState({
      date: is_not_empty(params?.value) ? params?.value : "",
    });
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.params?.value !== prevProps?.params?.value) {
      this.setState({
        date: is_not_empty(this.props.params?.value)
          ? this.props.params?.value
          : "",
      });
    }
  };

  handle_submit = () => {
    let newParams = this.props.params;
    newParams.value = this.state.date;
    newParams.row.value = this.state.date;

    this.props.handleEditCellChange(
      this.props.editProperties,
      this.props.layer_id,
      newParams
    );
  };

  handle_change = (e) => {
    this.setState(
      {
        date: e.target?.value,
      },
      () => {
        this.handle_submit();
      }
    );
  };

  toggle_mode_edit = () => {
    const { is_mode_edit } = this.state;
    this.setState({
      is_mode_edit: !is_mode_edit,
    });
  };

  render() {
    const { params } = this.props;
    const { date } = this.state;
    const converted_date = date ? format_date_formal(date) : "";
    return (
      <main className="center_perfect w_full">
        <div className="w_full h_full flex justify_between">
          <span title="DD-MM-YYYY">{converted_date}</span>
          <button className="datepicker-native-button bg_grey rounded_5 pointer button_inactive">
            <img alt="date" src={icon_type_date} className="w_15 h_15" />
            <input
              id={`date-${params?.field}`}
              className="datepicker-native-button-input"
              value={date || ""}
              type="date"
              onChange={this.handle_change}
            />
          </button>
        </div>
      </main>
    );
  }
}
