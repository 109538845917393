const calculate_rdtr = ({ KDB, KLB, KDH, rdtr_input_area_m2 }) => {
  [KDB, KLB, KDH] = [KDB, KLB, KDH].map((value) =>
    isNaN(Number(value)) ? 0 : Number(value)
  );
  let rdtr_result_LDB = (KDB / 100) * rdtr_input_area_m2;
  let rdtr_result_LLB = KLB * rdtr_input_area_m2;
  let rdtr_result_JLB = rdtr_result_LLB / rdtr_result_LDB;
  let rdtr_result_LRH = (KDH / 100) * rdtr_input_area_m2;
  [rdtr_result_LDB, rdtr_result_LLB, rdtr_result_JLB, rdtr_result_LRH] = [
    rdtr_result_LDB,
    rdtr_result_LLB,
    rdtr_result_JLB,
    rdtr_result_LRH,
  ].map((value) => (isNaN(Number(value)) ? 0 : Number(value)));
  [rdtr_result_LDB, rdtr_result_LLB, rdtr_result_LRH, rdtr_result_JLB] = [
    rdtr_result_LDB,
    rdtr_result_LLB,
    rdtr_result_LRH,
    rdtr_result_JLB,
  ].map((value) => parseInt(value));
  return { rdtr_result_LDB, rdtr_result_LLB, rdtr_result_LRH, rdtr_result_JLB };
};

export default calculate_rdtr;
