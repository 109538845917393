import React, { Component } from 'react'

export default class Tabs extends Component {
  render() {

    const { menu_tabs, actived_tab, handle_tab } = this.props;

    return (
      <main className='center_perfect'>
        <div className='tabs_pill'>
          <div className='tab_slider' />
          {
            menu_tabs?.map((tab, index) => {
              const title = tab[0].toUpperCase() + tab.slice(1, tab.length);
              const style = actived_tab === tab ? "active" : "";
              return <button
                key={index}
                className={`${style} noselect`}
                onClick={() => handle_tab(tab)}
              >
                {title}
              </button>
            }
            )
          }
        </div>
      </main>
    )
  }
}
