const initialState = {
  loadingContent: false,
  versioning_iot: 0,
  versioning_local: 3.79,
  events: [],
};
export default function editorReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_VERSIONING_IOT":
      return {
        ...state,
        versioning_iot: action.payload,
      };
    case "GET_EVENTS":
      return {
        ...state,
        events: action.payload,
      };

    case "SET_LOADING_CONTENT":
      return {
        ...state,
        loadingContent: true,
      };

    case "CLEAR_LOADING":
      return {
        ...state,
        loadingProcess: false,
        loadingContent: false,
        loadingList: false,
      };
    default:
      return state;
  }
}
