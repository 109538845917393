import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      version="1.1"
      viewBox="0 0 6.879 6.879"
    >
      <g
        fillRule="evenodd"
        strokeLinecap="round"
        strokeOpacity="1"
        transform="translate(-75.497 -112.006)"
      >
        <circle
          cx="78.937"
          cy="115.445"
          r="3.44"
          fill="#1a649d"
          fillOpacity="1"
          stroke="none"
          strokeLinejoin="round"
          strokeMiterlimit="100"
          strokeWidth="0.185"
          paintOrder="stroke markers fill"
          stopColor="#000"
        ></circle>
        <path
          fill="none"
          stroke="#fff"
          strokeDasharray="none"
          strokeLinejoin="round"
          strokeMiterlimit="100"
          strokeWidth="0.397"
          d="M80.484 114.064a.808.808 0 00-1.134.014l-.6.604-.192.192-.845.848-.173 1.329 1.323-.175.844-.848.192-.192.6-.604a.816.816 0 000-1.153c0-.005-.012-.01-.014-.015z"
          paintOrder="markers fill stroke"
          stopColor="#000"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeWidth="0.397"
          d="M77.137 113.855h1.277"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeWidth="0.397"
          d="M77.137 114.401h.816"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeWidth="0.397"
          d="M77.137 114.947h.356"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
