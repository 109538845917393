/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";
import ShareLink from "../viewer_table/ShareLink";

/*REDUX FUNCTION*/
import { set_value_properties } from "../../App/actions/propertiesActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class MODAL_SHARE_LINK extends Component {
  state = {};

  toggle_share = () => {
    const { modal_share } = this.props.properties;
    this.props.set_value_properties({
      key: "modal_share",
      value: !modal_share,
    });
  };

  render() {
    //local storage

    //local state

    //global props
    const { modal_share } = this.props.properties;

    //content
    const modal_share_content = modal_share && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_share}
        onClose={this.toggle_share}
      >
        <div className="box-body">
          <ShareLink />
        </div>
      </Modal>
    );

    return <main>{modal_share_content}</main>;
  }
}

const mapStateToProps = (state) => ({
  properties: state.properties,
});

export default connect(mapStateToProps, { set_value_properties })(
  MODAL_SHARE_LINK
);
