/*LIBRARY*/
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import { ArrowBack } from "@material-ui/icons";

/*COMPONENT*/

/*REDUX*/
import { setLanguage } from "../../App/actions/authActions";

/*PICTURE*/

/*FUNCTION*/
import GeomapidVersion from "./GeomapidVersion";

/*DATA*/
// import dict from "../../Data/dict.json";
import domain_list from "../../Data/domain_list";

/*CONST*/

class NavigationLanding extends Component {
  state = {
    width: window.innerWidth,
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  onSetLanguage = () => {
    const language = localStorage?.language ? localStorage?.language : "ina";

    if (language === "ina") {
      this.props.setLanguage("eng");
    } else {
      this.props.setLanguage("ina");
    }
  };
  render() {
    // const language = localStorage?.language || "ina";
    const domain = this.props.auth.domain;
    const width = window.innerWidth;
    // const path = window.location.pathname;

    const content_3 = (
      <nav className="nav_login">
        <div className="nav_login_left">
          <Link to="/">
            <img src={domain_list?.[domain]?.logo} alt="mapid" />
          </Link>
        </div>

        {width > 800 ? (
          <div className="nav_login_right">
            <div>
              {/* {(path === "/forget-password" || path === "/register") && (
                <Link
                className="marginLeft_30 pointer center_perfect"
                to="/login"
                >
                  <ArrowBack style={{ width: "20px", height: "20px" }} />
                  <label className="marginLeft_10 pointer">
                    {dict["Back to Sign In"][language]}
                  </label>
                </Link>
              )} */}
            </div>
            {domain_list?.[domain]?.["tag_line_image"] && (
              <Link to="/">
                <img
                  src={domain_list?.[domain]?.["tag_line_image"]}
                  alt="Connected Future"
                />
              </Link>
            )}
          </div>
        ) : (
          <GeomapidVersion
            is_mobile={true}
            title={domain_list?.[domain]?.["short_title"]}
            subtitle={domain_list?.[domain]?.["sub_title"]}
          />
        )}
      </nav>
    );
    return <div>{content_3}</div>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setLanguage })(NavigationLanding);
