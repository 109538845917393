/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { get_price_2023 } from "../../App/actions/paymentActions";

/*PICTURE ASSET*/
import ICON_CHECKLIST from "../../Assets/jsx/ICON_CHECKLIST";

/*GENERAL*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class PaymentTable extends Component {
  componentDidMount() {
    this.props.get_price_2023();
  }

  // toggleBuyNow = (license_object, page) => {
  //   const { set_page } = this.props
  //   if (set_page) {
  //     set_page(page);

  //     if (license_object) {
  //       this.setState({ license_object });
  //     }
  //   }
  // };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state

    //global props
    const { set_page, license_selected, set_license_selected } = this.props;
    const license = this.props.license_reducer?.license_user_status;
    const is_license_academy =
      license?.active_days > 0 && license?.payment_type === "license_academy";

    let price_list = [];
    if (is_license_academy) {
      price_list = this.props.payment?.price_list;
    } else {
      price_list = this.props.payment?.price_list?.filter(
        (item) => item?.key !== "license_academy"
      );
    }

    //content

    const prices_render = price_list.map((e, idx) => {
      const { key, prices, is_hide_price } = e;

      const name = e?.name;

      const features = e?.features?.[language] || [];
      const feature_list = features.split("\n");
      const base_price = prices?.[0]?.price;
      const discount =
        (Number(prices?.[0]?.discount_percent) / 100) * base_price;
      const discounted_price = base_price - discount;

      const is_active = license_selected === name["eng"];
      return (
        <main
          className={`card_license ${is_active ? "active_card" : ""}`}
          key={idx}
          onClick={() => set_license_selected(name["eng"])}
        >
          <h1
            style={{ color: is_active ? "#006ABA" : "#ABABAB" }}
            className="font_16"
          >
            {name?.[language]}
          </h1>

          {!!discount && (
            <p>
              {key !== "license_4"
                ? `Rp ${new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(base_price)}`
                : "-"}
            </p>
          )}

          {!is_hide_price && (
            <section>
              <span className="flex align-end">
                <p
                  style={{ color: is_active ? "#006ABA" : "#ABABAB" }}
                  className="font_24 inline bold"
                >
                  Rp
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(discounted_price)}
                </p>
                <div
                  className="flex justify_center h_30"
                  style={{
                    alignItems: "end",
                  }}
                >
                  <label className="font_14 h_20">
                    {key !== "license_4"
                      ? `/${dict?.["month"]?.[language]}`
                      : "-"}
                  </label>
                </div>
              </span>
              {e?.discount_text?.[language] && (
                <p className="badge_pill">{e?.discount_text?.[language]}</p>
              )}
            </section>
          )}

          <div className="marginTop_20">
            {feature_list.map((feature, idx) => {
              return (
                <div
                  className="two_container_list"
                  style={{ marginBottom: "10px" }}
                  key={idx}
                >
                  <ICON_CHECKLIST
                    color={is_active ? "#006ABA" : "#ABABAB"}
                    size={20}
                  />
                  <div className="text_feature_pricing">{feature}</div>
                </div>
              );
            })}
          </div>

          {is_hide_price ? (
            <a
              href="https://wa.me/6281216450675"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact us
            </a>
          ) : (
            <button
              onClick={() => {
                console.log("neta", e);
                if (is_active && set_page) {
                  set_page({ license_object: e, new_page: "bundle" });
                }
              }}
            >
              {dict?.["Choose"]?.[language]}
            </button>
          )}
        </main>
      );
    });

    // const modal_buy_now_content = modal_buy_now && (
    //   <Modal
    //     modalSize="medium"
    //     id="modal"
    //     isOpen={modal_buy_now}
    //     onClose={this.toggleBuyNow}
    //   >
    //     <div className="box-body">
    //       <BuyNow license_object={license_object} />
    //     </div>
    //   </Modal>
    // );

    return (
      <main>
        <div
          style={{
            display: "flex",
            gap: "20px",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {prices_render}

          {/* {!modal_buy_now ? prices_render :
            <BuyNow
              toggleBuyNow={this.toggleBuyNow}
              license_object={license_object}
            />} */}
        </div>
        {/* {modal_buy_now_content} */}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  payment: state.payment,
  license_reducer: state.license_reducer,
});

export default connect(mapStateToProps, { get_price_2023 })(PaymentTable);
