/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

/*PERSONAL COMPONENT*/
import BI_MENU from "./BI_MENU";

/*REDUX FUNCTION*/
import { set_value_bi } from "../../App/actions/biActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class BI_MODE_BOX extends Component {
  state = {};

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const { bi_list, bi_selected } = this.props.bi;

    //content
    let list_content;
    if (bi_list.length === 0) {
      list_content = (
        <section
          className="container_folder background_white background_white outline_red"
          style={{ height: "37px", position: "relative" }}
        >
          <p className="center_child">{dict?.["Empty"]?.[language]}</p>
        </section>
      );
    } else {
      list_content = (
        <section>
          {bi_list.map((item, idx) => {
            return (
              <div
                className={`container_folder background_white background_white ${
                  item._id === bi_selected?._id
                    ? "outline_blue"
                    : "outline_grey"
                }`}
                key={idx}
              >
                <table className="full_width">
                  <tbody>
                    <tr>
                      <td className="text_left cursor_pointer">
                        <Link
                          to={`/bi/${item?.link}?mode=edit`}
                          className="text_medium max_two_lines"
                          style={{
                            marginTop: "auto",
                            marginBottom: "auto",
                            width: "100%",
                          }}
                          title={item?.name}
                        >
                          {item?.name}
                        </Link>
                      </td>
                      <td className="text_right" style={{ width: "30px" }}>
                        <BI_MENU id={item._id} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            );
          })}
        </section>
      );
    }

    return <main className="margin_bottom">{list_content}</main>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
});

export default connect(mapStateToProps, { set_value_bi })(BI_MODE_BOX);
