/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import Modal from "../common_modal/Modal";
import PARENT_TABLE_NESTED_EDIT from "../editor_table/PARENT_TABLE_NESTED_EDIT";

/*REDUX*/
import { set_value_layer } from "../../App/actions/layerActions";

/*PICTURE*/

/*FUNCTION*/
import {
  getChildrenFeaturesByColumnParentUuidAndRowParentUuid,
  getFeatureByKey,
  getGeoLayerById,
} from "../../App/reduxHelper/layer";

/*DATA*/

/*CONST*/

class TABLE_NESTED_CELL extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_table: false,
    };
  }

  toggle_table_close = () => {
    const { is_from_first_gen_table } = this.props;
    if (is_from_first_gen_table) {
      this.props.set_value_layer({
        key: "selected_parent_table_row_key",
        value: "",
      });
    }
    this.setState({ modal_table: false });
  };

  toggle_table = () => {
    const { is_from_first_gen_table, row, column } = this.props;
    this.setState({ modal_table: !this.state.modal_table }, () => {
      if (is_from_first_gen_table) {
        this.props.set_value_layer({
          key: "selected_parent_table_row_key",
          value: row?.key,
        });
      }
      const child_features = this.get_children_features(row, column);
      this.props.set_value_layer({
        key: "field_parent",
        value: column,
      });
      this.props.set_value_layer({
        key: "child_fields_shown",
        value: child_features,
      });
    });
  };

  get_children_features = (row, column) => {
    const { is_from_first_gen_table, layer, layer_id } = this.props;
    const { selected_parent_table_row_key } = layer;

    const geo_layer = getGeoLayerById({ layer, layer_id });
    const feature = getFeatureByKey({
      geo_layer,
      feature_key: selected_parent_table_row_key || row?.key,
    });

    let child_features = getChildrenFeaturesByColumnParentUuidAndRowParentUuid({
      feature,
      column_parent_uuid: column?.uuid,
      row_parent_uuid: is_from_first_gen_table ? row?.child_uuid : row?.key,
    });

    return child_features;
  };

  modal_table = () => {
    const { layer_id, row, column, is_from_first_gen_table } = this.props;
    const { selected_parent_table_row_key } = this.props.layer;

    const modal_table_content = this.state.modal_table && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={this.state.modal_table}
        onClose={this.toggle_table_close}
      >
        <div className="box-body">
          {/* <TABLE_NESTED_SIMPLE /> */}
          <PARENT_TABLE_NESTED_EDIT
            layer_id={layer_id}
            row={row}
            column={column}
            selected_parent_table_row_key={selected_parent_table_row_key}
            is_from_first_gen_table={is_from_first_gen_table}
          />
        </div>
      </Modal>
    );

    return modal_table_content;
  };

  render() {
    return (
      <section>
        <button
          className="button background_blue"
          onClick={() => this.toggle_table()}
        >
          Buka tabel
        </button>
        {this.modal_table()}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
});

export default connect(mapStateToProps, {
  set_value_layer,
})(TABLE_NESTED_CELL);
