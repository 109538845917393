import {
  Paragraph,
  Document,
  Packer,
  ImageRun,
  TextRun,
  AlignmentType,
  LevelFormat,
  TabStopType,
  UnderlineType,
  Table,
  TableRow,
  TableCell,
  VerticalAlign,
  WidthType,
} from "docx";
import { saveAs } from "file-saver";
import kop_citarum from "../../Assets/png_jpg/kop_citarum.png";
import formatDateFormal from "../../App/validation/format-date-formal";
const tab_b = 3200;
const tab = 3400;
const image_width = 420;
export const CitarumB = async (feature, index) => {
  try {
    const d = new Date();
    let year = d.getFullYear();
    const properties = feature?.properties;
    let jumlah_meninggal = !!properties?.["Jumlah_Korban_Meninggal"]
      ? ` ${properties?.["Jumlah_Korban_Meninggal"]} jiwa`
      : ` -`;
    let jumlah_luka = !!properties?.["Jumlah_Korban_Luka"]
      ? ` ${properties?.["Jumlah_Korban_Luka"]} jiwa`
      : ` -`;
    let jumlah_hilang = !!properties?.["Jumlah_Korban_Hilang"]
      ? ` ${properties?.["Jumlah_Korban_Hilang"]} Hilang, `
      : ` -`;
    let sumberdaya = properties?.["Sumberdaya_yang_Tersedia_Dilokasi_Bencana"];
    if (sumberdaya) {
      sumberdaya = sumberdaya.split("\n");
      sumberdaya = sumberdaya.map((s) => {
        return new Paragraph({
          style: "12",
          text: s,
          numbering: {
            reference: "poin_s",
            level: 0,
          },
        });
      });
    }
    let kerusakan = properties?.["Kerusakan"];
    if (kerusakan) {
      kerusakan = kerusakan.split("\n");
      kerusakan = kerusakan.join(", ");
    }
    let kendala = properties?.["KendalaHambatan"];
    if (kendala) {
      kendala = kendala.split("\n");
      kendala = kendala.map((text) => {
        return new Paragraph({
          style: "12",
          text: text,
          numbering: {
            reference: "poin_s",
            level: 0,
          },
        });
      });
    }
    let kebutuhan = properties?.["Kebutuhan_Mendesak"];
    if (kebutuhan) {
      kebutuhan = kebutuhan.split("\n");
      kebutuhan = kebutuhan.map((text) => {
        return new Paragraph({
          style: "12",
          text: text,
          numbering: {
            reference: "poin_s",
            level: 0,
          },
        });
      });
    }
    let dampak_pemukiman = properties?.["Dampak_Permukiman_Penduduk"]
      ? properties?.["Dampak_Permukiman_Penduduk"]
      : "-";
    let first_pemukiman;
    if (dampak_pemukiman) {
      dampak_pemukiman = dampak_pemukiman.split("\n");
      first_pemukiman = dampak_pemukiman.shift();
      dampak_pemukiman = dampak_pemukiman.map((text) => {
        return new Paragraph({
          style: "12",
          text: text,
          numbering: {
            reference: "poin_s",
            level: 0,
          },
        });
      });
    }
    let dampak_perkotaan = properties?.["Dampak_Perkotaan"]
      ? properties?.["Dampak_Perkotaan"]
      : "-";
    let first_perkotaan;
    if (dampak_perkotaan) {
      dampak_perkotaan = dampak_perkotaan.split("\n");
      first_perkotaan = dampak_perkotaan.shift();
      dampak_perkotaan = dampak_perkotaan.map((text) => {
        return new Paragraph({
          style: "12",
          text: text,
          numbering: {
            reference: "poin_s",
            level: 0,
          },
        });
      });
    }
    let dampak_industri = properties?.["Dampak_Kawasan_Industri"]
      ? properties?.["Dampak_Kawasan_Industri"]
      : "-";
    let first_industri;
    if (dampak_industri) {
      dampak_industri = dampak_industri.split("\n");
      first_industri = dampak_industri.shift();
      dampak_industri = dampak_industri.map((text) => {
        return new Paragraph({
          style: "12",
          text: text,
          numbering: {
            reference: "poin_s",
            level: 0,
          },
        });
      });
    }
    let dampak_sarana = properties?.["Dampak_Sarana_Prasarana"]
      ? properties?.["Dampak_Sarana_Prasarana"]
      : "-";
    let first_sarana;
    if (dampak_sarana) {
      dampak_sarana = dampak_sarana.split("\n");
      first_sarana = dampak_sarana.shift();
      dampak_sarana = dampak_sarana.map((text) => {
        return new Paragraph({
          style: "12",
          text: text,
          numbering: {
            reference: "poin_s",
            level: 0,
          },
        });
      });
    }
    let dampak_pertanian = properties?.["Dampak_Pertanian"]
      ? properties?.["Dampak_Pertanian"]
      : "-";
    let first_pertanian;
    if (dampak_pertanian) {
      dampak_pertanian = dampak_pertanian.split("\n");
      first_pertanian = dampak_pertanian.shift();
      dampak_pertanian = dampak_pertanian.map((text) => {
        return new Paragraph({
          style: "12",
          text: text,
          numbering: {
            reference: "poin_s",
            level: 0,
          },
        });
      });
    }
    let penanganan = properties?.["Penanganan_Darurat_yang_Telah_Dilakukan"];
    if (penanganan) {
      penanganan = penanganan.split("\n");
      penanganan = penanganan.map((text) => {
        return new Paragraph({
          style: "12",
          text: text,
          numbering: {
            reference: "poin_wide",
            level: 0,
          },
        });
      });
    }
    let rencana_planing = properties?.["Rencana_Planing_Yang_Ada"];
    if (rencana_planing) {
      rencana_planing = rencana_planing.split("\n");
      rencana_planing = rencana_planing.map((text) => {
        return new Paragraph({
          style: "12",
          text: text,
          numbering: {
            reference: "poin_wide",
            level: 0,
          },
        });
      });
    } else {
      rencana_planing = [
        new Paragraph({
          style: "12",
          text: "-",
        }),
      ];
    }
    let usulan_fisik = properties?.["Usulan_Fisik"];
    if (usulan_fisik) {
      usulan_fisik = usulan_fisik.split("\n");
      usulan_fisik = usulan_fisik.map((text) => {
        return new Paragraph({
          style: "12",
          text: text,
          numbering: {
            reference: "numbering_s",
            level: 2,
          },
        });
      });
    } else {
      usulan_fisik = [
        new Paragraph({
          style: "12",
          text: "-",
        }),
      ];
    }
    let usulan_pembiayaan = properties?.["Usulan_Pembiayaan"];
    if (usulan_pembiayaan) {
      usulan_pembiayaan = usulan_pembiayaan.split("\n");
      usulan_pembiayaan = usulan_pembiayaan.map((text) => {
        return new Paragraph({
          style: "12",
          text: text,
          numbering: {
            reference: "numbering_s",
            level: 2,
          },
        });
      });
    } else {
      usulan_pembiayaan = [
        new Paragraph({
          style: "12",
          text: "-",
        }),
      ];
    }
    const getImage = (url) => {
      return new Promise(async (resolve, reject) => {
        try {
          const r = await fetch(url);
          const blob = await r.blob();
          resolve(blob);
        } catch (error) {
          reject({ error: error, status: "Image fetch error nih" });
        }
      });
    };
    const kop_img = await getImage(kop_citarum);
    const image_1 = await getImage(properties?.["Dokumentasi_1"]);
    const image_2 = await getImage(properties?.["Dokumentasi_2"]);
    const image_3 = await getImage(properties?.["Dokumentasi_3"]);
    const getMeta = (url) => {
      return new Promise((resolve) => {
        const img = new Image();
        let width;
        let height;
        img.addEventListener("load", function (e) {
          width = this.naturalWidth;
          height = this.naturalHeight;
          //r=w/h
          resolve(width / height);
        });
        img.src = url;
      });
    };
    const d1 = await getMeta(properties?.["Dokumentasi_1"]);
    const d2 = await getMeta(properties?.["Dokumentasi_2"]);
    const d3 = await getMeta(properties?.["Dokumentasi_3"]);
    const form_b = new Document({
      creator: "BBWS CITARUM",
      description: "Laporan Banjir BBWS Citarum",
      title: "Laporan Banjir BBWS Citarum",
      styles: {
        paragraphStyles: [
          {
            id: "9",
            name: "9",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              font: "Calibri",
              size: 18,
            },
          },
          {
            id: "12",
            name: "12",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              font: "Calibri",
              size: 24,
            },
          },
          {
            id: "12_bold",
            name: "12_bold",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              font: "Calibri",
              size: 24,
              bold: true,
            },
          },
          {
            id: "judul_tengah",
            name: "Judul Tengah",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            paragraph: {
              alignment: AlignmentType.CENTER,
            },
            run: {
              font: "Calibri",
              size: 24,
            },
          },
          {
            id: "ttd",
            name: "ttd",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              font: "Calibri",
              size: 24,
            },
            paragraph: {
              alignment: AlignmentType.CENTER,
              indent: { left: 4000, hanging: 0 },
            },
          },
        ],
      },
      numbering: {
        config: [
          {
            reference: "numbering_s",
            levels: [
              {
                level: 0,
                format: LevelFormat.DECIMAL,
                text: "%1.",
                alignment: AlignmentType.START,
                style: {
                  run: {
                    font: "Calibri",
                    size: 24,
                    bold: true,
                  },
                  bold: true,
                  paragraph: {
                    indent: { left: 500, hanging: 500 },
                  },
                },
              },
              {
                level: 1,
                format: LevelFormat.LOWER_LETTER,
                text: "%2.",
                alignment: AlignmentType.START,
                style: {
                  run: {
                    font: "Calibri",
                    size: 24,
                  },
                  paragraph: {
                    indent: { left: 800, hanging: 300 },
                  },
                },
              },
              {
                level: 2,
                format: LevelFormat.DECIMAL,
                text: "%3.",
                alignment: AlignmentType.START,
                style: {
                  run: {
                    font: "Calibri",
                    size: 24,
                  },
                  paragraph: {
                    indent: { left: 1200, hanging: 300 },
                  },
                },
              },
            ],
          },
          {
            reference: "tembusan",
            levels: [
              {
                level: 0,
                format: LevelFormat.DECIMAL,
                text: "%1.",
                alignment: AlignmentType.START,
                style: {
                  run: {
                    font: "Calibri",
                    size: 18,
                  },
                  paragraph: {
                    indent: { left: 400, hanging: 200 },
                  },
                },
              },
            ],
          },
          {
            reference: "poin_s",
            levels: [
              {
                level: 0,
                format: LevelFormat.BULLET,
                text: "-",
                alignment: AlignmentType.LEFT,
                style: {
                  run: {
                    font: "Calibri",
                    size: 24,
                  },
                  paragraph: {
                    indent: { left: 3450, hanging: 150 },
                  },
                },
              },
            ],
          },
          {
            reference: "poin_wide",
            levels: [
              {
                level: 0,
                format: LevelFormat.BULLET,
                text: "-",
                alignment: AlignmentType.LEFT,
                style: {
                  run: {
                    font: "Calibri",
                    size: 24,
                  },
                  paragraph: {
                    indent: { left: 1200, hanging: 300 },
                  },
                },
              },
            ],
          },
        ],
      },
      sections: [
        {
          // properties: {
          //   page: {
          //     margin: {
          //       top: 0,
          //       bottom: -100,
          //     },
          //   },
          // },
          children: [
            new Paragraph({
              style: "12",
              children: [
                new ImageRun({
                  data: kop_img,
                  transformation: {
                    width: 597.015,
                    height: 100,
                  },
                }),
              ],
            }),
            new Paragraph({
              style: "12",
              children: [
                new TextRun({
                  text: `No.Surat.     /Kej-Bencana/${year}`,
                  break: 1,
                }),
                new TextRun("\t"),
                new TextRun("\t"),
                new TextRun({ text: "Form B", bold: true }),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: 7000,
                },
              ],
            }),
            new Paragraph({
              style: "12",
              children: [
                new TextRun({
                  text: "Kepada Yth,",
                  break: 2,
                }),
                new TextRun({
                  text: "Direktur Jenderal SDA Kemeterian PUPR",
                  break: 1,
                }),
                new TextRun({
                  text: "UP. SATGAS PB PUSAT ",
                  break: 1,
                }),
                new TextRun({
                  text: "Ditjend. SDA ",
                  break: 1,
                }),
                new TextRun({
                  text: "di",
                  break: 2,
                }),
                new TextRun({
                  text: "Jakarta",
                  underline: {
                    type: UnderlineType.SINGLE,
                    color: "000000",
                  },
                  break: 1,
                }),
              ],
            }),
            new Paragraph({
              style: "judul_tengah",
              children: [
                new TextRun({
                  text: "LAPORAN KEJADIAN BENCANA",
                  bold: true,
                  break: 2,
                }),
              ],
            }),
            new Paragraph({
              style: "12_bold",
              text: "BENCANA",
              numbering: {
                reference: "numbering_s",
                level: 0,
              },
            }),
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 1,
              },
              children: [
                new TextRun({ text: "Jenis Bencana" }),
                new TextRun("\t"),
                new TextRun(":"),
                new TextRun("\t"),
                new TextRun(`${properties?.["Jenis_Bencana"]}`),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_b,
                },
                {
                  type: TabStopType.LEFT,
                  position: tab,
                },
              ],
            }),
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 1,
              },
              children: [
                new TextRun({ text: "Waktu Kejadian" }),
                new TextRun("\t"),
                new TextRun(":"),
                new TextRun("\t"),
                new TextRun(
                  `${properties?.["Hari_Kejadian"]}, ${formatDateFormal(
                    properties?.["Tanggal_Kejadian"]
                  )} ${properties?.["Waktu_Kejadian"]}`
                ),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_b,
                },
                {
                  type: TabStopType.LEFT,
                  position: tab,
                },
              ],
            }),
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 1,
              },
              children: [
                new TextRun({ text: "Tempat Kejadian" }),
                new TextRun("\t"),
                new TextRun(":"),
                new TextRun("\t"),
                new TextRun(
                  `${properties?.["DesaKelurahan"]}, ${properties?.["Kecamatan"]},${properties?.["KabupatenKota"]}`
                ),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_b,
                },
                {
                  type: TabStopType.LEFT,
                  position: tab,
                },
              ],
            }),
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 1,
              },
              children: [
                new TextRun({ text: "Sungai" }),
                new TextRun("\t"),
                new TextRun(":"),
                new TextRun("\t"),
                new TextRun(`${properties?.["Tempat_Kejadian_Nama_Sungai"]}`),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_b,
                },
                {
                  type: TabStopType.LEFT,
                  position: tab,
                },
              ],
            }),
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 1,
              },
              children: [
                new TextRun({ text: "Curah Hujan (mm)" }),
                new TextRun("\t"),
                new TextRun(":"),
                new TextRun("\t"),
                new TextRun(properties?.["Nilai_PCH"]),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_b,
                },
                {
                  type: TabStopType.LEFT,
                  position: tab,
                },
              ],
            }),
            new Paragraph({
              style: "12",
              children: [
                new TextRun({
                  break: 0,
                }),
              ],
            }),
            new Paragraph({
              style: "12_bold",
              text: "KORBAN / KERUSAKAN YANG TELAH TERJADI",
              numbering: {
                reference: "numbering_s",
                level: 0,
              },
            }),
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 1,
              },
              children: [
                new TextRun({ text: "Pos Curah Hujan" }),
                new TextRun("\t"),
                new TextRun(":"),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_b,
                },
              ],
            }),
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({ text: "Hari / Tanggal", style: "12" }),
                      ],
                      verticalAlign: VerticalAlign.CENTER,
                      width: {
                        size: 3505,
                        type: WidthType.DXA,
                      },
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          text: `PCH ${properties?.["Pos_PCH"]}`,
                          style: "12",
                        }),
                      ],
                      verticalAlign: VerticalAlign.CENTER,
                      width: {
                        size: 3505,
                        type: WidthType.DXA,
                      },
                    }),
                  ],
                }),
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          text: properties?.["Observation_Time"],
                          style: "12",
                        }),
                      ],
                      verticalAlign: VerticalAlign.CENTER,
                      width: {
                        size: 3505,
                        type: WidthType.DXA,
                      },
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          text: `${properties?.["Nilai_PCH"]}`,
                          style: "12",
                        }),
                      ],
                      verticalAlign: VerticalAlign.CENTER,
                      width: {
                        size: 3505,
                        type: WidthType.DXA,
                      },
                    }),
                  ],
                }),
              ],
            }),
            new Paragraph({
              style: "12",
              children: [
                new TextRun({
                  break: 0,
                }),
              ],
            }),
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 1,
              },
              children: [
                new TextRun({ text: "Pos Duga Air" }),
                new TextRun("\t"),
                new TextRun(":"),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_b,
                },
              ],
            }),
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({ text: "Hari / Tanggal", style: "12" }),
                      ],
                      verticalAlign: VerticalAlign.CENTER,
                      width: {
                        size: 3505,
                        type: WidthType.DXA,
                      },
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          text: `PDA ${properties?.["Pos_PDA"]}`,
                          style: "12",
                        }),
                      ],
                      verticalAlign: VerticalAlign.CENTER,
                      width: {
                        size: 3505,
                        type: WidthType.DXA,
                      },
                    }),
                  ],
                }),
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          text: properties?.["Observation_Time"],
                          style: "12",
                        }),
                      ],
                      verticalAlign: VerticalAlign.CENTER,
                      width: {
                        size: 3505,
                        type: WidthType.DXA,
                      },
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          text: `${properties?.["Nilai_PDA"]}`,
                          style: "12",
                        }),
                      ],
                      verticalAlign: VerticalAlign.CENTER,
                      width: {
                        size: 3505,
                        type: WidthType.DXA,
                      },
                    }),
                  ],
                }),
              ],
            }),
            new Paragraph({
              style: "12",
              children: [
                new TextRun({
                  break: 0,
                }),
              ],
            }),
            new Paragraph({
              style: "12_bold",
              text: "DAMPAK BENCANA",
              numbering: {
                reference: "numbering_s",
                level: 0,
              },
            }),
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 1,
              },
              children: [
                new TextRun({ text: "Permukiman Penduduk" }),
                new TextRun("\t"),
                new TextRun(":"),
                new TextRun(
                  ` - ${first_pemukiman !== "-" ? first_pemukiman : ""}`
                ),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_b,
                },
                {
                  type: TabStopType.LEFT,
                  position: tab,
                },
              ],
            }),
            ...dampak_pemukiman,
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 1,
              },
              children: [
                new TextRun({ text: "Perkotaan" }),
                new TextRun("\t"),
                new TextRun(":"),
                new TextRun(
                  ` - ${first_perkotaan !== "-" ? first_perkotaan : ""}`
                ),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_b,
                },
                {
                  type: TabStopType.LEFT,
                  position: tab,
                },
              ],
            }),
            ...dampak_perkotaan,
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 1,
              },
              children: [
                new TextRun({ text: "Kawasan Industri" }),
                new TextRun("\t"),
                new TextRun(":"),
                new TextRun(
                  ` - ${first_industri !== "-" ? first_industri : ""}`
                ),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_b,
                },
                {
                  type: TabStopType.LEFT,
                  position: tab,
                },
              ],
            }),
            ...dampak_industri,
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 1,
              },
              children: [
                new TextRun({ text: "Sarana / prasarana" }),
                new TextRun("\t"),
                new TextRun(":"),
                new TextRun(` - ${first_sarana !== "-" ? first_sarana : ""}`),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_b,
                },
                {
                  type: TabStopType.LEFT,
                  position: tab,
                },
              ],
            }),
            ...dampak_sarana,
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 1,
              },
              children: [
                new TextRun({ text: "Pertanian" }),
                new TextRun("\t"),
                new TextRun(":"),
                new TextRun(
                  ` - ${first_pertanian !== "-" ? first_pertanian : ""}`
                ),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_b,
                },
                {
                  type: TabStopType.LEFT,
                  position: tab,
                },
              ],
            }),
            ...dampak_pertanian,
            new Paragraph({
              style: "12",
              children: [
                new TextRun({
                  break: 0,
                }),
              ],
            }),
            new Paragraph({
              style: "12_bold",
              text: "KORBAN JIWA DAN KERUGIAN HARTA BENDA :",
              numbering: {
                reference: "numbering_s",
                level: 0,
              },
            }),
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 1,
              },
              children: [new TextRun({ text: "Korban" })],
            }),
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 2,
              },
              children: [
                new TextRun({ text: "Meninggal" }),
                new TextRun("\t"),
                new TextRun(":"),
                new TextRun(jumlah_meninggal),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_b,
                },
                {
                  type: TabStopType.LEFT,
                  position: tab,
                },
              ],
            }),
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 2,
              },
              children: [
                new TextRun({ text: "Luka-luka" }),
                new TextRun("\t"),
                new TextRun(":"),
                new TextRun(jumlah_luka),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_b,
                },
                {
                  type: TabStopType.LEFT,
                  position: tab,
                },
              ],
            }),
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 2,
              },
              children: [
                new TextRun({ text: "Hilang" }),
                new TextRun("\t"),
                new TextRun(":"),
                new TextRun(jumlah_hilang),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_b,
                },
                {
                  type: TabStopType.LEFT,
                  position: tab,
                },
              ],
            }),
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 1,
              },
              children: [
                new TextRun({ text: "Kerugian" }),
                new TextRun("\t"),
                new TextRun(": "),
                new TextRun(properties?.["Kerugian"]),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_b,
                },
              ],
            }),
            new Paragraph({
              style: "12",
              children: [
                new TextRun({
                  break: 0,
                }),
              ],
            }),
            new Paragraph({
              style: "12_bold",
              text: `TINDAKAN DARURAT: `,
              numbering: {
                reference: "numbering_s",
                level: 0,
              },
            }),
            ...penanganan,
            new Paragraph({
              style: "12",
              children: [
                new TextRun({
                  break: 0,
                }),
              ],
            }),
            new Paragraph({
              style: "12_bold",
              text: `RENCANA PLANING YANG ADA: `,
              numbering: {
                reference: "numbering_s",
                level: 0,
              },
            }),
            ...rencana_planing,
            new Paragraph({
              style: "12",
              children: [
                new TextRun({
                  break: 0,
                }),
              ],
            }),
            new Paragraph({
              style: "12_bold",
              text: "USULAN PEMULIHAN FISIK",
              numbering: {
                reference: "numbering_s",
                level: 0,
              },
            }),
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 1,
              },
              children: [new TextRun({ text: "USULAN FISIK" })],
            }),
            ...usulan_fisik,
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 1,
              },
              children: [new TextRun({ text: "USULAN PEMBIAYAAN" })],
            }),
            ...usulan_pembiayaan,
            new Paragraph({
              style: "ttd",
              children: [
                new TextRun({
                  text: `Bandung, ${formatDateFormal(Date.now())}`,
                  bold: true,
                }),
              ],
            }),
            new Paragraph({
              style: "ttd",
              children: [
                new TextRun({
                  text: "Kepala Satuan Kerja Operasi Dan Pemeliharaan Sumber Daya Air Citarum",
                  bold: true,
                }),
              ],
            }),
            new Paragraph({
              style: "12",
              children: [
                new TextRun({
                  break: 4,
                }),
              ],
            }),
            new Paragraph({
              style: "ttd",
              children: [
                new TextRun({
                  text: "Joko Ahmad Salim, ST., MT",
                  bold: true,
                  underline: {
                    type: UnderlineType.SINGLE,
                    color: "000000",
                  },
                }),
              ],
            }),
            new Paragraph({
              style: "ttd",
              children: [
                new TextRun({
                  text: "NIP . 196808301999031005",
                }),
              ],
            }),
            new Paragraph({
              style: "9",
              children: [
                new TextRun({
                  text: "Tembusan disampaikan kepada Yth.:",
                }),
              ],
            }),
            new Paragraph({
              style: "9",
              text: "Sekretaris Direktur Jenderal Sumber Daya Air di Jakarta;",
              numbering: {
                reference: "tembusan",
                level: 0,
              },
            }),
            new Paragraph({
              style: "9",
              text: "Direktur Sungai dan Pantai Direktorat Jenderal Sumber Daya Air di Jakarta;",
              numbering: {
                reference: "tembusan",
                level: 0,
              },
            }),
            new Paragraph({
              style: "9",
              text: "Direktur Bina O&P Direktorat Sumber Daya Air di Jakarta;",
              numbering: {
                reference: "tembusan",
                level: 0,
              },
            }),
            new Paragraph({
              style: "9",
              text: "Direktur Bina Pembangunan Sumber Daya Air Direktorat Jenderal Sumber Daya Air di Jakarta;",
              numbering: {
                reference: "tembusan",
                level: 0,
              },
            }),
            new Paragraph({
              style: "9",
              text: "Direktur Pengembangan Jaringan Sumber Daya Air Direktorat Jenderal Sumber Daya Air di Jakarta;",
              numbering: {
                reference: "tembusan",
                level: 0,
              },
            }),
            new Paragraph({
              style: "9",
              text: "Kepala Balai Besar Wilayah Sungai Citarum di Bandung;",
              numbering: {
                reference: "tembusan",
                level: 0,
              },
            }),
            new Paragraph({
              style: "9",
              text: "Kepala Bidang Operasi dan Pemeliharaan, BBWS Citarum di Bandung;",
              numbering: {
                reference: "tembusan",
                level: 0,
              },
            }),
          ],
        },
        {
          children: [
            new Paragraph({
              style: "judul_tengah",
              children: [
                new TextRun({
                  text: `${properties?.["Jenis_Bencana"]} di ${properties?.["Tempat_Kejadian_Nama_Sungai"]}`,
                  bold: true,
                }),
                new TextRun({
                  text: `${properties?.["DesaKelurahan"]} ${properties?.["Kecamatan"]}`,
                  break: 1,
                  bold: true,
                }),
                new TextRun({
                  text: `${properties?.["KabupatenKota"]}`,
                  break: 1,
                  bold: true,
                  underline: {
                    type: UnderlineType.SINGLE,
                    color: "000000",
                  },
                }),
                new TextRun({
                  break: 4,
                }),
                new ImageRun({
                  data: image_1,
                  transformation: {
                    width: image_width,
                    height: image_width / d1,
                  },
                }),
                new TextRun({
                  break: 2,
                }),
                new ImageRun({
                  data: image_2,
                  transformation: {
                    width: image_width,
                    height: image_width / d2,
                  },
                }),
                new TextRun({
                  break: 2,
                }),
                new ImageRun({
                  data: image_3,
                  transformation: {
                    width: image_width,
                    height: image_width / d3,
                  },
                }),
              ],
            }),
          ],
        },
        {
          children: [
            new Paragraph({
              style: "judul_tengah",
              children: [
                new TextRun({
                  text: `${properties?.["Jenis_Bencana"]} di ${properties?.["Tempat_Kejadian_Nama_Sungai"]}`,
                  bold: true,
                }),
                new TextRun({
                  text: `${properties?.["DesaKelurahan"]} ${properties?.["Kecamatan"]}`,
                  break: 1,
                  bold: true,
                }),
                new TextRun({
                  text: `${properties?.["KabupatenKota"]}`,
                  break: 1,
                  bold: true,
                  underline: {
                    type: UnderlineType.SINGLE,
                    color: "000000",
                  },
                }),
                new TextRun({
                  break: 4,
                }),
                new ImageRun({
                  data: image_1,
                  transformation: {
                    width: image_width,
                    height: image_width / d1,
                  },
                }),
                new TextRun({
                  break: 2,
                }),
                new ImageRun({
                  data: image_2,
                  transformation: {
                    width: image_width,
                    height: image_width / d2,
                  },
                }),
                new TextRun({
                  break: 2,
                }),
                new ImageRun({
                  data: image_3,
                  transformation: {
                    width: image_width,
                    height: image_width / d3,
                  },
                }),
              ],
            }),
          ],
        },
      ],
    });
    Packer.toBlob(form_b).then((blob) => {
      saveAs(blob, `Form B Citarum Baris ke ${index + 1}.docx`);
    });
  } catch (e) {}
};
