import { getChildrenFeaturesByColumnParentUuidAndRowParentUuid } from "../../../../App/reduxHelper/layer";
import is_not_empty from "../../../../App/validation/is_not_empty";
import { dot_every_3_digits } from "../../../../App/validation/format_number";
import { createTd } from "../CreateTd";

const keys_that_use_table = {
  c10_setup_kumpulan_rumah_pdf: [
    "c10_pondasi_pdf",
    "c10_struktur_pdf",
    "c10_rangka_atap_pdf",
    "c10_penutup_atap_pdf",
    "c10_plafon_pdf",
    "c10_dinding_pdf",
    "c10_pintu_pdf",
    "c10_jendela_pdf",
    "c10_lantai_pdf",
    "c10_utilitas_pdf",
    "c10_kondisi_bangunan_pdf",
  ],
};

const c10_setup_child_bangunan_rumah_pdf = {
  c10_jumlah_ruangan: "Jumlah Ruangan",
  c10_garasi_car_port: "Garasi / Car Port",
  c10_ruang_tamu: "Ruang Tamu",
  c10_ruang_kantor: "Ruang Kantor",
  c10_kamar_tidur: "Kamar Tidur",
  c10_kamar_pembantu: "Kamar Pembantu",
  c10_kamar_mandi: "Kamar Mandi",
  c10_dapur: "Dapur",
  c10_gudang: "Gudang",
  c10_lainnya: "Lainnya",
  c10_luas_per_lantai_m2: "Luas per Lantai (m²)",
  c10_penyesuaian_peraturan_gsb_dan_ketinggian_lantai_bangunan_pdf:
    "Penyesuaian Peraturan (GSB dan Ketinggian Lantai Bangunan)",
};

const spesifikasi_bangunan = {
  c10_pondasi_pdf: "Pondasi",
  c10_struktur_pdf: "Struktur",
  c10_rangka_atap_pdf: "Rangka Atap",
  c10_penutup_atap_pdf: "Penutup Atap",
  c10_plafon_pdf: "Plafon",
  c10_dinding_pdf: "Dinding",
  c10_pintu_pdf: "Pintu",
  c10_jendela_pdf: "Jendela",
  c10_lantai_pdf: "Lantai",
  c10_utilitas_pdf: "Utilitas",
  c10_kondisi_bangunan_pdf: "Kondisi Bangunan",
};

export default function createC10SpecialTable({
  feature,
  parent_table_id,
  new_body_table_id,
  setup_table_id,
  setup_child_table_id,
  body,
}) {
  let final_result_luas_bangunan = 0;
  const parent_table = document.getElementById(parent_table_id);
  const tbl = document.createElement("tbody");
  tbl.setAttribute("id", new_body_table_id);

  const gen_2_rows = getChildrenFeaturesByColumnParentUuidAndRowParentUuid({
    feature,
    column_parent_uuid: body[setup_table_id],
  });

  const keys_that_needed_for_the_table = Object.keys(body).filter((key) =>
    keys_that_use_table[setup_table_id]?.includes(key)
  );

  gen_2_rows?.forEach((gen_2, index) => {
    let tr = null;
    let td = null;

    // sub judul tabel per rumah [index]
    tr = tbl.insertRow();
    tr.setAttribute("class", "tr_border_bottom_pdf tr_border_top_pdf");

    createTd({
      tr,
      text: `Rumah ${index + 1}`,
      tdClassName: "no_left_border",
      pClassName: "text_center",
      colspan: 2,
    });

    // tabel detail ruangan
    const children = getChildrenFeaturesByColumnParentUuidAndRowParentUuid({
      feature,
      column_parent_uuid: body[setup_child_table_id],
      row_parent_uuid: gen_2?.child_uuid,
    });

    const keys_that_needed_for_the_child_table = Object.keys(
      c10_setup_child_bangunan_rumah_pdf
    ).filter((key) => Object.keys(body)?.includes(key));

    const tbody = document.createElement("tbody");
    const thead = document.createElement("thead");
    const table = document.createElement("table");

    // header : jumalah ruangan, garasi dll
    tr = thead.insertRow();
    createTd({
      tr,
      text: "No.",
      tdClassName: "no_left_border",
      pClassName: "text_center",
    });
    Object.keys(c10_setup_child_bangunan_rumah_pdf).forEach((key) => {
      if (
        key !==
        "c10_penyesuaian_peraturan_gsb_dan_ketinggian_lantai_bangunan_pdf"
      ) {
        createTd({
          tr,
          text: c10_setup_child_bangunan_rumah_pdf?.[key],
          pClassName: "text_center",
        });
      }
    });

    // child table gen 3
    tr = tbl.insertRow();
    td = tr.insertCell();
    td.setAttribute("class", "no_padding");
    td.appendChild(table);
    td.setAttribute("colspan", 2);
    table.appendChild(thead);
    table.appendChild(tbody);
    table.setAttribute("class", "table_pdf no_margin side_border no_border");
    let total = 0;
    let penyesuaian_peraturan_gsb_dan_ketinggian_lantai_bangunan = 0;
    // baris
    children.forEach((child, index) => {
      // no.
      let child_tr = tbody.insertRow();
      createTd({
        tr: child_tr,
        text: `${index + 1}.`,
        pClassName: "text_center",
      });

      // value kolom
      keys_that_needed_for_the_child_table?.forEach((key) => {
        if (
          key !==
          "c10_penyesuaian_peraturan_gsb_dan_ketinggian_lantai_bangunan_pdf"
        ) {
          const td = child_tr.insertCell();
          let value = "-";
          if (is_not_empty(child?.properties?.[body[key]])) {
            if (!isNaN(value)) {
              value = new Intl.NumberFormat("id-ID", {
                style: "decimal",
              }).format(child?.properties?.[body[key]]);
            } else {
              value = child?.properties?.[body[key]];
            }
          }
          td.appendChild(document.createTextNode(value));
          if (value === "-" || !isNaN(value)) {
            td.setAttribute("class", "text_right");
          } else {
            td.setAttribute("class", "text_left");
          }
        } else {
          const _key =
            body?.[
              "c10_penyesuaian_peraturan_gsb_dan_ketinggian_lantai_bangunan_pdf"
            ];
          const nilai_penyesuaian = child?.properties?.[_key];
          if (nilai_penyesuaian || nilai_penyesuaian === 0) {
            penyesuaian_peraturan_gsb_dan_ketinggian_lantai_bangunan +=
              nilai_penyesuaian;
          }
        }
      });

      const value = child?.properties?.[body?.["c10_luas_per_lantai_m2"]];
      if ((value || value === 0) && !isNaN(value)) {
        td.setAttribute("class", "text_right");
        total += value;
      } else {
        td.setAttribute("class", "text_left");
      }
    });

    // Penyesuaian Peraturan (GSB & Ketinggian Lantai Bangunan)
    let child_tr = tbody.insertRow();
    createTd({
      tr: child_tr,
      text: "Penyesuaian Peraturan (GSB & Ketinggian Lantai Bangunan)",
      pClassName: "text_center",
      colspan: 11,
    });

    // kosongin
    // child_tr.insertCell();
    createTd({
      tr: child_tr,
      text:
        penyesuaian_peraturan_gsb_dan_ketinggian_lantai_bangunan /
        children?.length,
      pClassName: "text_right",
    });

    // TOTAL LUAS BANGUNAN (m²)
    child_tr = tbody.insertRow();

    createTd({
      tr: child_tr,
      text: "TOTAL LUAS BANGUNAN (m²)",
      pClassName: "text_center",
      colspan: 11,
    });

    const total_luas_bangunan =
      total -
      penyesuaian_peraturan_gsb_dan_ketinggian_lantai_bangunan /
        children?.length;

    if (index === 0) {
      final_result_luas_bangunan = total_luas_bangunan;
    }

    // Total valuenya
    createTd({
      tr: child_tr,
      text: total_luas_bangunan,
      pClassName: "text_right",
      colspan: 11,
    });

    // sub judul "SPESIFIKASI BANGUNAN"
    tr = tbl.insertRow();
    tr.setAttribute("class", "tr_border_bottom_pdf");

    createTd({
      tr,
      text: "SPESIFIKASI BANGUNAN",
      tdClassName: "w_150",
      pClassName: "subtitle_pdf",
    });

    keys_that_needed_for_the_table?.forEach((key) => {
      // key
      const tr = tbl.insertRow();
      createTd({
        tr,
        text: spesifikasi_bangunan?.[key],
        tdClassName: "w_150",
        pClassName: "subtitle_pdf",
      });

      // value
      const value = gen_2?.properties?.[body[key]];
      createTd({
        tr,
        text: `: ${!isNaN(value) ? dot_every_3_digits(value) : value}`,
      });
    });
  });

  if (parent_table) {
    parent_table.appendChild(tbl);
  }

  return final_result_luas_bangunan;
}
