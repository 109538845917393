import React, { Component } from "react";
import DropdownOrData from "../DropdownOrData";

export default class C6_LaporanHasilPemeriksaan extends Component {
  render() {
    const { mode } = this.props;
    return (
      <table className="table_pdf">
        <tbody>
          <tr className="tr_border_bottom_pdf">
            <td colSpan={4}>
              <p className="subtitle_pdf">III. LAPORAN HASIL PEMERIKSAAN</p>
            </td>
          </tr>
          <tr className="tr_border_bottom_pdf">
            <td colSpan={4}>
              <p className="subtitle_pdf center_perfect">OBJEK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Jenis Objek</p>
            </td>
            <td colSpan={3}>
              <DropdownOrData id="c6_jenis_object_pdf" {...this.props} />
            </td>
          </tr>
          <tr>
            <td>
              <p>Alamat Object Sesuai Fisik</p>
            </td>
            <td colSpan={mode === "preview" ? 3 : 1}>
              <DropdownOrData id="c6_alamat_sesuai_fisik_pdf" {...this.props} />
              {/* <p>
                :xxxxxxxxx, Kelurahan Pondok Jaya, Kecamatan Pondok Aren, Kota
                Tanggerang Selatan, Propinsi BANTEN
              </p> */}
            </td>
            {mode === "setup" && (
              <td>
                <DropdownOrData
                  placeholder="Pilih Kelurahan"
                  id="c6_kelurahan_object_sesuai_fisik_pdf"
                  {...this.props}
                  if_string_is_empty=""
                />
                <DropdownOrData
                  placeholder="Pilih Kecamatan"
                  id="c6_kecamatan_object_sesuai_fisik_pdf"
                  {...this.props}
                  if_string_is_empty=""
                />
                <DropdownOrData
                  placeholder="Pilih Kabupaten"
                  id="c6_kabupaten_object_sesuai_fisik_pdf"
                  {...this.props}
                  if_string_is_empty=""
                />
                <DropdownOrData
                  placeholder="Pilih Provinsi"
                  id="c6_provinsi_object_sesuai_fisik_pdf"
                  {...this.props}
                  if_string_is_empty=""
                />
              </td>
            )}
          </tr>
          <tr>
            <td>
              <p>Yang dijumpai</p>
            </td>
            <td>
              <DropdownOrData id="c6_yang_dijumpai_pdf" {...this.props} />
            </td>
            <td>
              <p>PIC Mobile Phone</p>
            </td>
            <td>
              <DropdownOrData id="c6_pic_mobile_phone_pdf" {...this.props} />
            </td>
          </tr>
          <tr>
            <td>
              <p>Status Objek</p>
            </td>
            <td>
              <DropdownOrData id="c6_status_objek_pdf" {...this.props} />
            </td>
            <td>
              <p>Dihuni Sejak </p>
            </td>
            <td>
              <DropdownOrData id="c6_dihuni_sejak_pdf" {...this.props} />
            </td>
          </tr>
          <tr>
            <td>
              <p>Dihuni oleh</p>
            </td>
            <td>
              <DropdownOrData id="c6_dihuni_oleh_pdf" {...this.props} />
            </td>
            <td>
              <p>Disewa Hingga</p>
            </td>
            <td>
              <DropdownOrData id="c6_disewa_hingga_pdf" {...this.props} />
            </td>
          </tr>
          <tr>
            <td>
              <p>Alasan Tinggal</p>
            </td>
            <td>
              <DropdownOrData id="c6_alasan_tinggal_pdf" {...this.props} />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
